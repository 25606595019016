import { colors } from 'constants/colors'

export const statusIcon = {
  small: {
    width: '16px',
    height: '16px',
  },
  smallActive: {
    width: '16px',
    height: '16px',
    color: colors.atlantis,
    mr: '10px',
  },
  smallInActive: {
    width: '16px',
    height: '16px',
    color: colors.black,
    opacity: 0.54,
    mr: '10px',
  },
}
