// USE THIS LINK TO GET THE COLOR NAME FROM ITS HEX CODE https://chir.ag/projects/name-that-color

// SORTED BY ALPHABET
export const colors = {
  amber: '#FFC105', // YELLOW
  alabaster: '#FBFBFB', // WHITE LIGHT
  astral: '#38719C', // BLUE
  alto: '#DDDDDD', // GRAY,
  atlantis: '#83CD3D', // LIGHT GREEN
  athensGray: '#F2F3F7', // LIGHT GRAY
  azureRadiance: '#007BE5', // BLUE
  bamboo: '#DC5C09', // DARK ORANGE
  bananaMania: '#FCECB7', // BANANA MANIA
  black: '#000000', // BLACK
  blackSqueeze: '#F4F8FB', // BLACK LIGHT
  blue: '#0000FF', // BLUE
  blueRibbon: '#3B37FF', // BLUE
  bridesmaid: '#FEF3EB', // LIGTH CREAM
  brightGray: '#383E47', // DARK GRAY
  boulder: '#757575', // GRAY
  brown: '#964B00', // BROWN
  bunker: '#0A0D14', // BLACK
  buttercup: '#F6BB16', // LIGHT ORANGE
  candlelight: '#FFDF1B', // YELLOW
  casablanca: '#F7C144', // LIGHT ORANGE
  candyCorn: '#FDEA60', // LIGHT YELLOW
  california: '#FD9E0F', // ORANGE
  carnation: '#F64E60', // RED
  catalinaBlue: '#043F79', // DARK BLUE
  celery: '#98C05C', // MIDTONE GREEN
  ceriseRed: '#D73964', // RED
  chambray: '#314F90', // DARK BLUE
  clementine: '#EF6C00', // ORANGE (PRIMARY)
  codDray: '#121212', // BLACK
  comet: '#5F627A', // GRAY
  concrete: '#F5F5F5', // LIGHT GRAY
  cornflowerBlue: '#6195ED', // LIGHT BLUE
  denim: '#1976D2', // BLUE
  deYork: '#81C784', // LIGHT GREEN
  dodgerBlue: '#2D99FF', // BLUE
  doveGray: '#717171', // GRAY
  ebonyClay: '#26293B', // BLACK
  everglade: '#1E4D2B', // DARK GREEN
  flesh: '#FFD1AB', // CREAM
  fern: '#67AC5B', // PALE GREEN
  fiord: '#494D6D', // DARK BLUE GRAY
  flamingo: '#ED6237', // RED ORANGE
  forestGreen: '#1F8B25', // GREEN
  fruitSalad: '#4CAF50', // GREEN
  gallery: '#EFEFEF', // LIGHT GRAY
  geyser: '#D8DDE3', // GRAY LIGHT
  goldenBell: '#E7860F', // ORANGE
  gray: '#7F7F7F', // GRAY
  havelockBlue: '#4496D6', // LIGHT BLUE
  jaffa: '#F29938', // ORANGE
  kashmirBlue : '#4B6496', //DARK BLUE
  lima: '#54D62C', // GREEN
  linkWater: '#DAE7F2', // LIGHT BLUE
  lilacBush: '#9575CD', // LIGHT VIOLET
  mariner: '#2378CE', // BLUE
  milanoRed: '#CB1B00', // RED
  mineShaft: '#212121', // LIGHT BLACK
  mirage: '#171928', // LIGHT BLACK
  mystic: '#E0E7ED', // LIGHT GRAY
  monza: '#CC0031', // RED
  mountainMeadow: '#1DCC6D', // SEA GREEN
  perfume: '#ADA6F9', // LIGHT VIOLET
  perwinkleGray: '#C0D7E8', // GRAY
  pictonBlue: '#4C9CEE', // LIGHT BLUE
  pocelain: '#F1F3F4', // LIGHT GRAY
  portage: '#9683F5', // LIGHT VIOLET
  raven: '#6F7687', // GRAY
  razzmatazz: '#D5004D', // RED
  redBerry: '#8B0000', // DARK RED,
  redRibbon: '#EB0523', // RED
  reject: '#F44336', // RED
  royalPurple: '#6040B0', // DARK PURPLE
  salmon: '#FA8461', // ORANGE
  sanMarino: '#4153AF', // DARK BLUE
  scarlet: '#E82809', // DARK ORANGE
  seashell: '#F1F1F1', // LIGTH GRAY
  shark : '#2B2B2C', // GRAY
  shakespeare: '#51BAD1', // LIGHT BLUE
  silver: '#CCCCCC', // LIGHT GRAY
  silverChalice: '#9D9D9D', // SILVER
  silverTree: '#59AE85', // GREEN
  spunPearl: '#A7A8BB', // LIGHT GRAY
  sunglo: '#E57373', // RED
  sunsetOrange: '#FF4842', // ORANGE-RED
  textDark: '#3F4254', //SEMI BLACK
  torchRed: '#EF0039', // RED
  tuna: '#373943', // DARK GRAY
  viking: '#51DCCC', // GREENISH BLUE
  vividViolet: '#9036AA', // VIOLET
  viridian: '#419488', // GREEN
  wattle: '#D1DA59', // LIGHT GREEN
  wildSand: '#F6F6F6', // LIGHT GRAY
  whiteLilac: '#FAFBFD', // WHITE GRAY
  zest: '#E98919', // DARK ORANGE

  // BOX SHADOWS
  boxShadow: 'rgba(145, 158, 171, 0.3)', // LIGHT
  boxShadowDark: 'rgba(145, 158, 171, 0.6)', // DARK

  // BORDER
  borderLightBlack: 'rgba(0, 0, 0, 0.1)', // LIGHT BLACK
}
