// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      width: 380,
    },
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px 24px',
  },
  titleText: {
    fontWeight: 600,
    fontSize: 18,
  },
  dialogContent: {
    height: 500,
    padding: 0,
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 50px',
    padding: '0px 24px',
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  searchInput: {
    width: '100%',
  },
  searchIcon: {
    color: colors.boulder,
    cursor: 'pointer',
  },
  list: {
    flex: 1,
    overflow: 'auto',
  },
  listItem: {
    padding: '4px 12px',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  cancelButton: {
    color: colors.raven,
    '&:hover': {
      backgroundColor: alpha(colors.raven, 0.1),
    },
  },
}))

export default useStyles
