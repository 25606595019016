// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  menuListCreate: {
    width: 280,
    padding: 0
  },
  menuItemCreate: {
    padding: '12px 8px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& .MuiListItemText-primary': {
      fontWeight: 600
    },
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  gridConversationList: {
    borderRight: `1px solid ${theme.palette.divider}`,
    maxHeight: '100%'
  },
  gridChatRoom: {
    maxHeight: '100%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  }
}))

export default useStyles