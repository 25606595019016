// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'

const CustomPaper = styled(({ className, ...props }) => (
  <Paper
    className={className} 
    {...props}
  />
))(({ theme }) => ({
  boxShadow: `0px 5px 10px ${colors.boxShadow}`,
}))

export default CustomPaper