import moment from 'moment'

export const adjustStructureDataKendo = (inputData, inputGMT = '+0800') => {
  return inputData.map(item => ({
    ...item,
    start: `${item.startTime}+0800`,
    end: `${item.endTime}+0800`,
    'EndTimezone': null,
    'RecurrenceRule': null,
    'RecurrenceID': null,
    'RecurrenceException': null,
    'isAllDay': false,
    'StartTimezone': null,
  }))
}

export const generateResourceData = (inputData, inputDevicesList) => {
  return [...new Set(inputData.map(item => item.deviceNo))]
    .map(deviceNo => {
      const findDeviceDetail = inputDevicesList.find(item => item.deviceNo === deviceNo)
      return {
        deviceNo,
        text: findDeviceDetail?.label || '[Tanpa Label]',
        value: deviceNo,
        deviceDetail: findDeviceDetail,
      }
    })
    .sort((a, b) => b.deviceNo - a.deviceNo)
}

export const generateFinalDataKendo = (inputDataRestructure) => {
  return inputDataRestructure.map(dataItem => ({
    id: dataItem.dispatchNo,
    start: moment(dataItem.start).toDate(), // OBJECT DATE
    startTimezone: null,
    end: moment(dataItem.end).toDate(), // OBJECT DATE
    endTimezone: null,
    isAllDay: dataItem.isAllDay,
    title: dataItem.label,
    description: dataItem.description,
    recurrenceRule: dataItem.RecurrenceRule,
    recurrenceId: dataItem.RecurrenceID,
    recurrenceExceptions: dataItem.RecurrenceException,
    deviceNo: dataItem.deviceNo,
    originData: dataItem
  }))
}