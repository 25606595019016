import { useState } from 'react'

// COMPONENTS
import ContentTabs from 'components/ContentTabs/ContentTabs'
import ConversationItem from '../ConversationItem/ConversationItem'

// MUIS
import List from '@mui/material/List'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './dialogDetailGroupUseStyles'

/**
 * TO DO
 * LIST MEMBER NOT YET FROM GROUP
 */
const TabListMember = (props) => {
  const { memberList } = props
  const classes= useStyles()

  const tabItems = [ 
    {
      label: 'SEMUA',
      value: 'allMember',
    },
    {
      label: 'ADMIN',
      value: 'adminMember',
    },
  ]

  const [selectedTab, setSelectedTab] = useState(tabItems[0].value)

  return (
    <Stack className={classes.parentListMember} overflow='hidden'>
      <ContentTabs
        disabledPaddingTabs
        tabItemList={tabItems}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        isExtraComponentAvailable={false}
        extraComponent={null}
        tabsHeight={52}
        classNameTab={classes.itemMemberTab}
      />

      <Stack flex={1} overflow='auto'>
        <List disablePadding>
          {memberList.filter((item) => {
            if (selectedTab === tabItems[0].value) return true
            else return item?.type !== 'member' && item.hasOwnProperty('type')
          }).map((item, index) => (
            <ConversationItem
              key={index+1}
              name={item.nickname}
              title={item.nickname}
              description={item.role}
              rightComponent={
                item.type ? <Typography variant='caption' color='text.secondary' textTransform='capitalize'>
                  {item.type}
                </Typography> : ''
              }
            />
          ))}
        </List>
      </Stack>
    </Stack>
  )
}

export default TabListMember