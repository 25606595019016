import { useContext, useState } from 'react'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// LIBRARY
import ReactStreetview from 'react-streetview'

// MUIS
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import List from '@mui/material/List'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Slide from '@mui/material/Slide'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconArrowBack from '@mui/icons-material/ArrowBack'
import IconHomeWork from '@mui/icons-material/HomeWork'
import IconMap from '@mui/icons-material/Map'
import IconSubjectOutlined from '@mui/icons-material/SubjectOutlined'
import IconTouchApp from '@mui/icons-material/TouchApp'

// STYLES
import useStyles from './panelMenuSearchDetailUseStyles'

const PanelMenuSearchDetail = () => {
  const { pageRef, isPanelSearchDetail, setIsPanelSearchDetail } = useContext(PageTrackingContext)

  // STATES
  const [isOpenDialogStreetView, setIsOpenDialogStreetView] = useState(false)

  const streetViewPanoramaOptions = {
    addressControl: false,
    disableDefaultUI: false,
    disableDoubleClickZoom: false,
    fullscreenControl: false,
    scrollwheel: true,
    showRoadLabels: false,
    pov: { heading: 100, pitch: 0 },
    zoom: 1,
    zoomControl: true,
  }
  
  const classes = useStyles()

  return (
    <>
      <Slide 
        direction='left' 
        in={isPanelSearchDetail?.type === 'location' || isPanelSearchDetail?.type === 'places'} 
        container={pageRef.current}
      >
        <Stack
          className={classes.panelSearchDetail}
          sx={{
            display: isPanelSearchDetail?.type === 'location' || isPanelSearchDetail?.type === 'places' ? 'flex' : 'none'
          }}
        >
          <Stack
            className={classes.searchDetailPanel}
            sx={{
              height: isPanelSearchDetail?.type === 'location' ? 400 : 600
            }}
          >
            <Button className={classes.buttomBackDetail} onClick={() => setIsPanelSearchDetail(false)}>
              <IconArrowBack />
              <Typography marginLeft='12px' variant='h6' fontWeight={600}>
                Informasi
              </Typography>
            </Button>

            {/* STREET VIEW */}
            {(isPanelSearchDetail?.lat && isPanelSearchDetail?.lng) && (
              <Stack className={classes.searchDetailView}>
                <Stack className={classes.backdropBlur} onClick={() => setIsOpenDialogStreetView(true)}>
                  <Stack direction='row' className={classes.buttonStreetView}>
                    <IconTouchApp className={classes.iconStreetView}/>
                    <Typography marginLeft='8px' variant='subtitle1'>Lihat jalan</Typography>
                  </Stack>
                </Stack>

                <ReactStreetview
                  key={isPanelSearchDetail?.lat || isPanelSearchDetail?.lng}
                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                  streetViewPanoramaOptions={{
                    ...streetViewPanoramaOptions,
                    position: { lat: isPanelSearchDetail?.lat, lng: isPanelSearchDetail?.lng },
                    disableDefaultUI: true,
                    zoomControl: false,
                  }}
                />
              </Stack>
            )}

            {/* LIST LOCATION */}
            {isPanelSearchDetail?.type === 'location' && (
              <Stack className={classes.searchDetailContent}>
                <Typography variant='subtitle1' fontWeight={500}>Lokasi</Typography>
                
                <List className={classes.listDetailPanel}>
                  <ListItemButton disableRipple>
                    <ListItemAvatar className='alignSelfBaseline'>
                      <Avatar variant='rounded' className={`${classes.avatarListDetail} noStyle`}>
                        <IconMap />
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                      primary='Lokasi'
                      secondary={isPanelSearchDetail?.address}
                    />
                  </ListItemButton>
                </List>
              </Stack>
            )}

            {/* LIST PLACES */}
            {isPanelSearchDetail?.type === 'places' && (
              <Stack className={classes.searchDetailContent}>
                <Typography variant='subtitle1' fontWeight={500}>Bagian Utama</Typography>

                <List className={classes.listDetailPanel}>
                  <ListItemButton disableRipple>
                    <ListItemAvatar>
                      <Avatar variant='rounded' className={classes.avatarListDetail}>
                        <IconHomeWork />
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                      primary='Label'
                      secondary={isPanelSearchDetail?.name}
                    />
                  </ListItemButton>

                  <ListItemButton disableRipple>
                    <ListItemAvatar className='alignSelfBaseline'>
                      <Avatar variant='rounded' className={`${classes.avatarListDetail} noStyle`}>
                        <IconMap />
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                      primary='Alamat'
                      secondary={isPanelSearchDetail?.address}
                    />
                  </ListItemButton>

                  <ListItemButton disableRipple>
                    <ListItemAvatar className='alignSelfBaseline'>
                      <Avatar variant='rounded' className={`${classes.avatarListDetail} noStyle`}>
                        <IconSubjectOutlined />
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                      primary='Keterangan'
                      secondary={isPanelSearchDetail?.description || '-'}
                    />
                  </ListItemButton>
                </List>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Slide>

      <Dialog
        onClose={() => setIsOpenDialogStreetView(false)}
        open={isOpenDialogStreetView}
        className={`${classes.dialogStreetView} no-zoom`}
      >
        <Stack className={classes.dialogStreetViewContent}>
          {(isPanelSearchDetail?.lat && isPanelSearchDetail?.lng) && (<ReactStreetview
            key={isPanelSearchDetail?.lat || isPanelSearchDetail?.lng}
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            streetViewPanoramaOptions={{
              ...streetViewPanoramaOptions,
              position: { lat: isPanelSearchDetail?.lat, lng: isPanelSearchDetail?.lng }
            }}
          />)}
        </Stack>
      </Dialog>
    </>
  )
}

export default PanelMenuSearchDetail