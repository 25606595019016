// UTILITIES
import { http } from 'utilities/http'

export const deletePatrol = async (id) => {
  try {
    const { data } = await http.delete(`/patrols/${id}`)
    return data
  }
  catch (error) {
    return {
      status: 'error', 
      rows: [],
    }
  }
}