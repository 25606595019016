// COLORS
import { colors } from 'constants/colors'

// MUIS
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 24,
  },
  contentRoot: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    padding: 0,
    position: 'relative'
  },
  video: {
    width: '100%',
    flex: '0 0 100%',
    backgroundColor: 'whitesmoke',
    '& .agora_video_player': {
      objectFit: 'contain !important',
    },
  },
  controlContainer: {
    padding: '12px 24px',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 99,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'linear-gradient(180deg, #1D1F2D -106.25%, rgba(29, 31, 45, 0) 121.87%)',
    boxShadow: '0px -1px 4px rgba(145, 158, 171, 0.1), 0px 2px 6px rgba(145, 158, 171, 0.07), 0px 2px 10px rgba(145, 158, 171, 0.05), 0px 7px 10px rgba(145, 158, 171, 0.08)'
  },
  profileText: {
    marginLeft: 12,
    color: '#FFFFFF',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px'
  },
  fullWidth: {
    width: '100%',
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
  userStreamer: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
  },
  detailControlIcon : {
    width : 24,
    height : 24,
    color: '#FFFFFF'
  },
  detailControlNotAudioIcon: {
    width : 24,
    height : 24,
    color: colors.carnation
  },
  avatarIcon: {
    height: '28px',
    width: '28px',
    fontSize: '16px',
    fontWeight: 600
  },
  headerContainer: {
    display: 'flex',
    padding: '14px 24px',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 99,
    top: 0,
    left: 0,
    right: 0,
    boxShadow: '0px -1px 4px rgba(145, 158, 171, 0.1), 0px 2px 6px rgba(145, 158, 171, 0.07), 0px 2px 10px rgba(145, 158, 171, 0.05), 0px 7px 10px rgba(145, 158, 171, 0.08)'
  },
  titleWrap : {
    display : 'flex',
    flexDirection : 'row',
    alignItems : 'center',
    flex: '1 1 auto'
  },
  title : {
    fontWeight : 700,
    fontSize : 16,
    color : 'white'
  },
  dotTitle : {
    width : 8,
    color : colors.scarlet,
    marginRight : 6,
  },
  videoCamIcon : {
    width : 24,
    color : 'white'
  }
}))

export default useStyles
