// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: alpha('#000000', 0.4),
    zIndex: theme.zIndex.modal - 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'absolute',
  },
  contentContainer: {
    height: '100%',
    backgroundColor: 'white',
    transition: 'width 0.2s ease-in !important',
    willChange: 'width',
  },
  dialogTitle: {
    padding: '22px 16px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    marginRight: 'auto',
    width: '100%',
    fontSize: 24
  },
  dialogContent: {
    padding: '0px 16px 20px',
    display: 'flex',
    overflowY: 'auto',
    background: 'linear-gradient(#fff 30%, rgba(255,255,255,0)), linear-gradient(rgba(255,255,255,0), #fff 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0,0,0,0.08), rgba(255,255,255,0)), radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,0.08), rgba(255,255,255,0)) 0 100%',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#fff',
    backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
    backgroundAttachment: 'local, local, scroll, scroll',
  },
  dialogContentDivider: {
    margin: '24px -16px',
  },
  dialogActions: {
    height: 72,
    padding: 16,
  },
  dialogActionsButton: {
    fontWeight: 600,
    textTransform: 'uppercase',
    width: 88,
  },
  dialogActionsCancelButton: {
    color: colors.raven,
    '&:hover': {
      backgroundColor: alpha(colors.raven, 0.1),
    },
  },
}))

export default useStyles