import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

// SERVICES
import { getDeviceStreaming } from 'services/DeviceService'

// AGORA
import AgoraRTC from 'agora-rtc-sdk-ng'

// CUSTOM COMPONENTS
import LoadingBox from 'components/LoadingBox/LoadingBox'

// MUIS
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

// STYLES
import useStyles from './liveRoomUseStyles'

// UTILS
import { getStringAvatar, getBackgroundAvatar } from 'utilities'

// MUIS ICON
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import VideocamIcon from '@mui/icons-material/Videocam'

const LiveRoom = () => {
  const classes = useStyles()
  const params = useParams()
  const currentUrl = '/devices/active-device-streaming'

  const [liveStreamingList, setLiveStreamingList] = useState([])
  const [isVideoPlay, setIsVideoPlay] = useState(true)
  const [isOnMute, setIsOnMute] = useState(false)
  const [isHaveAudio, setIsHaveAudio] = useState(false)

  /* AGORA CONFIG */
  const agoraAppId = process.env.REACT_APP_AGORA_APP_ID
  const randomId = Math.floor(Math.random() * 9999)
  const channel = params.deviceCode
  const agoraClientRTCRef = useRef(
    AgoraRTC.createClient({
      mode: 'rtc',
      codec: 'vp8',
    })
  )
  const videoElementRef = useRef()
  const videoTrackRef = useRef()
  const audioTrackRef = useRef()


  /* INIT AGORA LIVE STREAM */
  const initAgoraLiveStream = async () => {
    setIsVideoPlay(true)

    const rtcTokenResponse = await fetch(
      `${process.env.REACT_APP_API_MOBILE_URL}/devices/generate-agora-token?deviceCode=${channel}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: { deviceCode: channel },
      }
    )

    const rtcTokenResponseJson = await rtcTokenResponse.json()
    const rtcToken = rtcTokenResponseJson.token

    agoraClientRTCRef.current
      .join(agoraAppId, channel, rtcToken, randomId)

    agoraClientRTCRef.current.on('user-published', async (user, mediaType) => {
      if (mediaType === 'video') {
        agoraClientRTCRef.current.subscribe(user, 'video').then((track) => {
          videoTrackRef.current = track
          track.play(videoElementRef.current)
        })
      }
      if (mediaType === 'audio') {
        agoraClientRTCRef.current.subscribe(user, 'audio').then((track) => {
          track.play()
          audioTrackRef.current = track
          setIsHaveAudio(true)
        })
      }
    })

    /* CLOSE TAB WHEN VIDEO STREAM ENDED */
    agoraClientRTCRef.current.on('user-unpublished', async (user, mediaType) => {
      if (mediaType === 'audio') {
        setIsHaveAudio(false)
        audioTrackRef.current = null
      }

      if (mediaType === 'video') {
        window.close()
      }
    })

    setIsVideoPlay(false)
  }

  /* HANDLE BUTTON MUTE UNMUTE */
  const handleButtonMuteUnMute = () => {
    setIsOnMute(!isOnMute)
  }

  /* SIDE EFFECT INIT AGORA */
  useEffect(() => {
    initAgoraLiveStream()

    return async () => {
      videoTrackRef.current.close()
      audioTrackRef.current.close()
      await agoraClientRTCRef.current.leave()
      await agoraClientRTCRef.current.removeAllListeners()
    }
  }, [])

  useEffect(() => {
    const reloadData = () => {
      getDeviceStreaming(currentUrl, {
        page: 0,
        size: 1000,
      }).then((response) => {
        setLiveStreamingList(
          response.rows.filter(
            (item) => item.deviceCode === params.deviceCode
          )
        )
      })
    }
    reloadData()
  }, [params])

  /* SIDE EFFECT MUTE/UNMUTE */
  useEffect(() => {
    if(audioTrackRef.current) {
      if(isOnMute) audioTrackRef.current.stop()
      if(!isOnMute) audioTrackRef.current.play()
    }
  }, [isOnMute])

  return (
    <Box className={classes.pageRoot}>
      {/* CONTENT */}
      <LoadingBox isLoading={isVideoPlay} className={classes.contentRoot}>
        {/* HEADER STREAM */}
        {!isVideoPlay && (
          <Box className={classes.headerContainer}>
            <Box className={classes.titleWrap}>
              <FiberManualRecordIcon className={classes.dotTitle}/>
              <Typography className={classes.title}>
                SIARAN LANGSUNG
              </Typography>
            </Box>

            <VideocamIcon className={classes.videoCamIcon}/>
          </Box>
        )}

        {/* VIDEO */}
        <Box
          ref={(el) => (videoElementRef.current = el)}
          className={classes.video}
        />

        {/* STREAM CONTROL */}
        {!isVideoPlay && (
          <Box className={classes.controlContainer}>
            {/* AVATAR */}
            {liveStreamingList[0] && (
              <Box className={classes.userStreamer}>
                <Avatar
                  sx={{
                    backgroundColor: getBackgroundAvatar(liveStreamingList[0].label),
                  }}
                  className={classes.avatarIcon}
                >
                  {getStringAvatar(liveStreamingList[0].label)}
                </Avatar>

                {/* TEXT */}
                <Typography
                  variant='h6'
                  className={classes.profileText}
                >
                  {liveStreamingList[0].label}
                </Typography>
              </Box>
            )}

            {/* BUTTON MUTE/UNMUTE */}
            {isHaveAudio ? (<IconButton
              edge='end'
              disableRipple
              className={classes.buttonDetailVideo}
              onClick={handleButtonMuteUnMute}
            >
              {!isOnMute && <VolumeUpIcon className={classes.detailControlIcon} />}
              {isOnMute && <VolumeOffIcon className={classes.detailControlIcon} />}
            </IconButton>)
              : (
                <IconButton
                  edge='end'
                  disableRipple
                  className={classes.buttonDetailVideo}
                >
                  <VolumeOffIcon className={classes.detailControlNotAudioIcon} />
                </IconButton>
              )}
          </Box>
        )}
      </LoadingBox>
    </Box>
  )
}

export default LiveRoom
