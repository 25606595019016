export const convertEmailToAgoraId = (value) => value.replace('@', '.at.')

export const convertAgoraIdToUsername = value => value.split('.at.')[0]

export const setCurrentConversation = (data) => {
  localStorage.setItem('agoraCurrentConversation', JSON.stringify(data))
}
  
export const getCurrentConversation = () => JSON.parse(localStorage.getItem('agoraCurrentConversation'))
  
export const clearCurrentConversation = () => {
  localStorage.removeItem('agoraCurrentConversation')
}

export const parseMessageBody = (value) => {
  const result = JSON.parse(value)
  return result?.bodies ?? {}
}

export const splitChannel = (channelId) => channelId?.includes('@')
  ? channelId?.split('_')?.[1].split('@')?.[0] ?? ''
  : channelId

export const getUserNicknameFromFriends = (friendList, userId) => {
  return friendList.find(item => item?.username === userId)?.nickname ?? '-'
}

export const getUserRoleFromFriends = (friendList, userId) => {
  return friendList.find(item => item?.username === userId)?.role ?? '-'
}

export const getTextByTypeMessage = (typeMessage, message, from) => {
  const parseLocalStorage = JSON.parse(localStorage.getItem('user'))

  if (typeMessage === 'txt' && message) {
    if (message.includes('telah bergabung') && from) return convertEmailToAgoraId(parseLocalStorage?.email) === from
      ? 'Anda telah bergabung'
      : message
    else return message
  }
  else if (typeMessage === 'audio') return 'Mengirim audio'
  else if (typeMessage === 'img') return 'Mengirim gambar'
  else if (typeMessage === 'file') return 'Mengirim file'
}

export const getReturnMessageByType = (typeMessage, item) => {
  const parseLocalStorage = JSON.parse(localStorage.getItem('user'))

  if (typeMessage === 'txt' && item.msg) {
    if (item.msg.includes('telah bergabung')) return convertEmailToAgoraId(parseLocalStorage?.email) === item.from
      ? 'Anda telah bergabung'
      : item.msg
    else return item.msg
  }
  else if (typeMessage === 'audio') return item?.url
  else if (typeMessage === 'img') return item?.thumb
  else if (typeMessage === 'file') return {
    fileName: item?.ext?.fileName ?? '-',
    fileLength: item?.ext?.fileLength,
    url: item?.url
  }
  else return null
}

export const getRestructureReplyMsg = (typeMessage, item) => {
  if (typeMessage === 'txt') return item.msg
  else if (typeMessage === 'audio') return {
    url: item?.url,
    fileName: item?.ext?.fileName ?? '-',
    fileLength: item?.ext?.fileLength
  }
  else if (typeMessage === 'img') return {
    thumb: item?.thumb,
    fileName: item?.ext?.fileName ?? '-',
    fileLength: item?.ext?.fileLength
  }
  else if (typeMessage === 'file') return {
    url: item?.url,
    fileName: item?.ext?.fileName ?? '-',
    fileLength: item?.ext?.fileLength
  }
  else return null
}