// ASSETS
import PictureBrokenImage from 'assets/images/pictures/broken-image.svg'

// MUIS
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import CloseIcon from '@mui/icons-material/Close'
import LaunchIcon from '@mui/icons-material/Launch'

// STYLES
import useStyles from './dialogMediaPreviewUseStyles'

const DialogMediaPreview = (props) => {
  const { 
    isDialogMediaPreviewOpen, 
    setIsDialogMediaPreviewOpen,
  } = props
  
  const classes = useStyles()

  const handleOpenUrlImage = () => {
    if(isDialogMediaPreviewOpen) window.open(isDialogMediaPreviewOpen, '_blank', 'noopener, noreferrer')
  }

  const getImageTitle = () => {
    if(isDialogMediaPreviewOpen) {
      let splitUrlImage = isDialogMediaPreviewOpen.split('/')
      return splitUrlImage[splitUrlImage.length -1]
    }
    return 'Tidak Ada Data'
  }
  
  return (
    <Modal open={isDialogMediaPreviewOpen !== null} >
      <Stack 
        className={classes.dialogMediaPreviewRoot}
        direction='row'
      >
        {/* LEFT CONTENT */}
        <Stack maxWidth='1024px'>
          {/* IMAGE PREVIEW */}
          <Stack 
            width='100%'
            sx={{
              backgroundImage: isDialogMediaPreviewOpen ? `url(${isDialogMediaPreviewOpen})` : 'unset',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
          >
            <Box
              component='img'
              src={isDialogMediaPreviewOpen ? isDialogMediaPreviewOpen : PictureBrokenImage}
              alt=''
              className={classes.imagePreview}
              onError={() => setIsDialogMediaPreviewOpen(PictureBrokenImage)}
            />
          </Stack>

          {/* IMAGE FILE NAME */}
          <Stack
            width='100%'
            height='84px'
            padding='30px 24px'
            justifyContent='center'
            sx={{backgroundColor: 'white'}}
          >
            <Stack 
              direction='row' 
              alignItems='center' 
              spacing={0.5}
            >
              {/* TITLE */}
              <Typography className={classes.imageTitle}>
                {getImageTitle()}
              </Typography>

              {/* ICON */}
              <IconButton 
                disableRipple
                onClick={handleOpenUrlImage}
              >
                <LaunchIcon className={classes.launchIcon}/>
              </IconButton>
            </Stack>

          </Stack>
        </Stack>

        {/* RIGHT CONTENT */}
        <Stack
          width='48px'
          height='48px'
          borderRadius='0px 50px 50px 0px'
          justifyContent='center'
          alignItems='center'
          sx={{backgroundColor: '#0000008a'}}
        >
          <IconButton 
            disableRipple
            onClick={()=> setIsDialogMediaPreviewOpen(null)}
            className={classes.closeButton}
          >
            <CloseIcon/>
          </IconButton>
        </Stack>
      </Stack>
    </Modal>
  )
}

export default DialogMediaPreview