import { useContext, useEffect, useRef } from 'react'
import ReactDOMServer from 'react-dom/server'

// CONSTANTS
import { colors } from 'constants/colors'

// CONTEXTS
import { PageOverviewDashboardContext } from 'contexts/PageOverviewDashboardContext'

// LEAFLET
import L from 'leaflet'

// MUIS
import { alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// SERVICES
import { getIndonesianGeoJson } from 'services/GeoJsonServices'

// STYLES
import useStyles from './geofenceListUseStyles'

const GeofenceList = (props) => {
  const {
    dataEvidencesProvince, dataEvidencesDistrict,
    selectedViewMode, selectedProvince,
    provincesGeoJson, setProvincesGeoJson,
    viewModeList
  } = props

  const { map } = useContext(PageOverviewDashboardContext)
  const classes = useStyles()
  const geojsonRef = useRef(L.geoJSON())

  const getIndonesianProvincesGeoJson = async () => {
    const response = await getIndonesianGeoJson('indonesia')
    if(response?.status !== 'error') {
      setProvincesGeoJson(response)
    }
  }

  const getDistrictProvinceGeoJson = async (slugProvince) => {
    const response = await getIndonesianGeoJson(slugProvince)
    if(response?.status !== 'error') {
      return response
    }
  }

  const getColorGeofence = (level) => {
    if(level === 1) return colors.candlelight
    else if(level === 2) return colors.buttercup
    else if(level === 3) return colors.goldenBell
    else if(level === 4) return colors.bamboo
    else if(level === 5) return colors.milanoRed
  }

  const generateItemGeoJSON = (itemDetail, dataGeoJson) => {
    let findGeometry
    if (selectedViewMode === viewModeList[0]) {
      findGeometry = dataGeoJson.features.find(item => item.properties.slug.toLowerCase() === itemDetail.slug.toLowerCase())?.geometry
    } else if (selectedViewMode === viewModeList[1]) {
      findGeometry = dataGeoJson.features.find(item => item.properties.label.toLowerCase() === itemDetail.label.toLowerCase())?.geometry
    }

    return {
      'type': 'Feature',
      'geometry': {
        ...findGeometry
      },
      'properties': {
        ...itemDetail,
        color: getColorGeofence(itemDetail.level)
      },
    }
  }

  const generateGeofence = async () => {
    geojsonRef.current.clearLayers()
    let toGeoJsonFeaturesData = []
    let newMapBounds = []

    if (selectedViewMode === viewModeList[0]) {
      toGeoJsonFeaturesData = dataEvidencesProvince?.data?.map(item => generateItemGeoJSON(item, provincesGeoJson))
      newMapBounds = L.geoJson(provincesGeoJson).getBounds()
    } 
    else if (selectedViewMode === viewModeList[1] && Boolean(selectedProvince)) {
      const districtOfProvince = await getDistrictProvinceGeoJson(selectedProvince.slug)
      toGeoJsonFeaturesData = dataEvidencesDistrict?.data?.map(item => generateItemGeoJSON(item, districtOfProvince)) || []
      newMapBounds = L.geoJson(districtOfProvince).getBounds()
    }

    if(toGeoJsonFeaturesData?.length) {
      geojsonRef.current.addData(toGeoJsonFeaturesData).addTo(map)
      geojsonRef.current.setStyle((feature) => ({
        stroke: false,
        fillColor: feature.properties.color,
        fillOpacity: 0.72
      }))
      geojsonRef.current.bindPopup((layer) => {
        return ReactDOMServer.renderToString(
          <Stack className={classes.popupGeofence}>
            {/* HEADER */}
            <Stack className={classes.popupGeofenceHeader}>
              <Box
                className={classes.popupGeofenceCircleColor}
                style={{
                  borderColor: layer.feature.properties.color,
                  backgroundColor: alpha(layer.feature.properties.color, 0.72)
                }}
              />

              <Stack>
                <Typography
                  className={classes.popupGeofenceHeaderTitle}
                  variant='subtitle2'
                >
                  {layer.feature.properties.label}
                </Typography>

                <Typography
                  className={classes.popupGeofenceHeaderSubtitle}
                  variant='caption'
                >
                  Total {layer.feature.properties.value} Kejadian
                </Typography>
              </Stack>
            </Stack>

            <Divider />

            {/* CONTENT */}
            {/* <List className={classes.popupGeofenceListTags}>
              {layer.feature.properties.tagsEvidences.map((item, index) => (
                <ListItem key={index} className={classes.popupGeofenceListTagsItem} disablePadding>
                  <Typography className={classes.popupGeofenceListTagsDesc} variant='caption'>
                    {item?.label}
                  </Typography>

                  <Typography className={classes.popupGeofenceListTagsTotal} variant='caption'>
                    {item?.total}
                  </Typography>
                </ListItem>
              ))}
            </List> */}
          </Stack>
        )
      }, {
        className: 'customBindPopupClustering'
      })
    }

    map.flyToBounds(newMapBounds, { animate: true })
  }

  useEffect(() => {
    getIndonesianProvincesGeoJson()

    geojsonRef.current.on('popupopen', event => {
      geojsonRef.current.setStyle((feature) => ({
        fillOpacity: 0.3
      }))
      event.sourceTarget.setStyle({
        fillOpacity: 1,
      })
    })

    geojsonRef.current.on('popupclose', event => {
      geojsonRef.current.setStyle((feature) => ({
        fillOpacity: 0.72
      }))
    })
  }, [])

  useEffect(() => {
    if(map && (provincesGeoJson || selectedProvince)) {
      generateGeofence()
    }
  }, [
    map, provincesGeoJson, dataEvidencesProvince, dataEvidencesDistrict
  ])

  return null
}

export default GeofenceList