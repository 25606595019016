import React, { useState, useEffect, useContext } from 'react'

// COMPONENTS
import LiveStreamingItem from './LiveStreamingItem'

// MUIS
import Grid from '@mui/material/Grid'

// STYLES
import useStyles from './liveUseStyles'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// STORAGE
import { cleanUpAllDeviceStorage } from './liveStorage'

const Live = () => {
  const classes = useStyles()
  const { liveStreamingList } = useContext(AllPagesContext)

  const [listLive, setListLive] = useState([])
  const [isUpdate, setIsUpdate] = useState(0)

  useEffect(() => {
    if(liveStreamingList.length){
      setListLive(liveStreamingList.map((item, index)=>{
        return {
          idItem: index + 1,
          ...item,
          type : 'LIVESTREAMING'
        }
      }).sort((a, b) => b.idItem - a.idItem))
    }
  }, [isUpdate])

  /* SIDE EFFECT RE-RENDER LOOP */
  useEffect(() => {
    setListLive([])
    setIsUpdate(current => current + 1)
  }, [liveStreamingList])

  /* CLEAN UP FIRST MOUNT PAGE */
  useEffect(() => {
    cleanUpAllDeviceStorage()
  }, [])
  
  return (
    <>
      {/* LIVE LIST */}
      <Grid 
        container
        className={classes.liveGrid}
      >
        {listLive.map((item, index) => (
          <Grid
            key={index}
            item
            xs={3}
            className={classes.liveItem}
          >
            {/* LIVE STREAMING ITEM */}
            <LiveStreamingItem
              deviceCode={item.deviceCode}
              label={item.label}
              typeStream={item.type}
              idx={index}
            />
          </Grid>
        )
        )}
      </Grid>
    </>
  )
}

export default Live