// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  chart: {
    marginLeft: -12,
    marginBottom: -12,
    '& .apexcharts-tooltip': {
      left: '65% !important'
    }
  },
}))

export default useStyles