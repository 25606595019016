// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 24,
    position: 'relative',
  },
  contentContainer: {
    position: 'relative',
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  groupingRow: {
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  columnStatus: {
    font: 'inherit',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    textTransform: 'capitalize',
    '&::before': {
      content: '""',
      width: 10,
      height: 10,
      backgroundColor: colors.boulder,
      borderRadius: '50%',
      marginRight: 10,
    },
  },
  columnStatusRed: {
    '&::before': {
      backgroundColor: colors.carnation,
    },
  },
  columnStatusGreenOnline: {
    '&::before': {
      backgroundColor: colors.silverTree,
    },
  },
  columnStatusRedOffline: {
    '&::before': {
      backgroundColor: colors.carnation,
    },
  },
  columnStatusGreyPending: {
    '&::before': {
      backgroundColor: colors.raven,
    },
  },
  columnStatusYellow: {
    '&::before': {
      backgroundColor: colors.amber,
    },
  },
  normalText: {
    textTransform: 'capitalize',
  },
  columnActions: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    zIndex: 2,
  },
  topMainSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },
  titleMainSection: {
    fontSize: '18px',
    fontWeight: 600,
    letterSpacing: '1%',
    color: colors.black,
    lineHeight: '24.51px',
    margin: 0,
  },
  labelDataTitle: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16.34px',
    color: colors.black,
    opacity: 0.54,
  },
  labelData: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '19.07px',
    color: colors.black,
    textTransform: 'capitalize',
  },
  buttonText: {
    fontSize: '12px',
    lineHeight: '16.34px',
    letterSpacing: '-0.3px',
    fontWeight: 400,

    borderRadius: '2px',
    height: '24px',
    width: '62px',
  },
  buttonReject: {
    background: colors.reject,
    marginRight: '0.5rem',
    '&:hover': {
      background: colors.reject,
    },
  },
  buttonApprove: {
    background: colors.fruitSalad,
    '&:hover': {
      background: colors.fruitSalad,
      opacity: 0.8,
    },
  },
  iconMargin: {
    marginRight: '16px',
  },
  mapsSection: {
    height: 480,
    width: '100%',
    marginBottom: 20,
    position: 'relative',
    overflow: 'hidden',
  },
  mapContainer: {
    position: 'relative',
    zIndex: 1,
    '&.leaflet-container': {
      width: '100%',
      height: '100%',
    },
  },
}))

export default useStyles
