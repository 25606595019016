// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'

const CustomTextField = styled(({ className, ...props }) => (
  <TextField
    className={className} 
    {...props}
  />
))(({ theme }) => ({
  '& .MuiInputBase-input': {
    fontSize: 14,
  },
  '& .MuiInputLabel-root': {
    fontSize: 14,
    color: colors.boulder,
  },
}))

export default CustomTextField