import { useState, useContext, useEffect } from 'react'

// CONSTANTS
import { panelsInfoStatic } from './panelInformationIntegratedConstant'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// CUSTOM COMPONENTS
import CustomDataGridObjects from 'components/DataGridPanelObject/CustomDataGridObjects'
import CustomGroupingCell from 'components/DataGridPanelObject/GroupingCell'

// MUIS
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import Fade from '@mui/material/Fade'

// MUI ICONS
import IconHistory from '@mui/icons-material/History'

// MUI DATA GRID
import { useGridApiRef } from '@mui/x-data-grid-premium'

// STYLES
import useStyles from './tabObjectUseStyles'

// UTILS
import { filterObjectListBySearchPanelObject, handleCellClickPanelObject, handleColumnHeaderClickPanelObject, setRowClassByGroupObject, setSxCustomClassPanelObject } from 'utilities'
import { joinAllLayers } from '../Clusters/clustersUtils'
import getCheckboxColumn from 'components/DataGridTable/checkboxColumn'

const TabObject = () => {
  const classes = useStyles()
  const {
    objectList,
    setMapBounds, map, setObjectDetail, setIsPanelLeftShown, markerSettings,
    setIsHistoryDateRangeOpen, setSelectedObjectId, groupList, markerClustersLocationRef,
    selectionModel, setSelectionModel, objectSearch, setCurrentPanelTabLeft,
    markerClustersRef, objectSortFilter, referenceLocation, setHistoryPolylineList
  } = useContext(PageTrackingContext)

  // CONST & REF
  const dataGridApiRef = useGridApiRef()
  const columnList = [
    {
      field: 'label',
      headerName: '',
      sortable: false,
      renderCell: (params) => (
        <CustomGroupingCell
          {...params}
          contentWidth={336}
          itemTextWidth={241}
          onClickIconInfo={handleChildrenClick}
        />
      ),
    }
  ]
  const groupingColDef = {
    headerName: '',
    flex: 1,
    renderCell: (params) => (
      <CustomGroupingCell
        {...params}
        contentWidth={336}
        itemTextWidth={241}
        onClickIconInfo={handleChildrenClick}
      />
    ),
    renderHeader: (params) => null
  }

  const [sxCustom, setSxCustom] = useState()
  const [filteredObjectList, setFilteredObjectList] = useState([])

  const handleChildrenClick = (inputChildrenItem) => {
    const latitude = inputChildrenItem?.state?.gps?.location?.lat
    const longitude = inputChildrenItem?.state?.gps?.location?.lng

    // SET SELECTED OBJECT ID FOR FOLLOW MARKER
    setSelectedObjectId(inputChildrenItem.id)

    setObjectDetail(inputChildrenItem)
    setCurrentPanelTabLeft('Informasi Perangkat')
    setIsPanelLeftShown(panelsInfoStatic?.panelDeviceDetail)

    latitude && longitude && map.flyTo([parseFloat(latitude), parseFloat(longitude)], 16)
    updateMapBound(latitude, longitude)
  }

  const updateMapBound = (lat, lng) => {
    if (lat && lng) setMapBounds([[parseFloat(lat), parseFloat(lng)], [parseFloat(lat), parseFloat(lng)]])
  }

  const handleMouseEnterItem = event => {
    const itemObjectId = Number(event?.currentTarget?.dataset?.id)

    if (markerSettings?.isClusteringLocation) {
      const listLayers = joinAllLayers(markerClustersLocationRef)
      const findInstance = listLayers?.find(item => {
        if (item?.options?.objectData?.source) {
          return item?.options?.objectData?.source?.id === itemObjectId
        }
      })
      findInstance?.openTooltip()
      return
    }

    if (!markerClustersRef) return
    const findInstance = markerClustersRef?.current?.getLayers()?.find(item => {
      if (item?.options?.objectData?.source) {
        return item?.options?.objectData?.source?.id === itemObjectId
      }
    })
    findInstance?.openTooltip()
  }

  const handleMouseLeaveItem = event => {
    const itemObjectId = Number(event?.currentTarget?.dataset?.id)

    if (markerSettings?.isClusteringLocation) {
      const listLayers = joinAllLayers(markerClustersLocationRef)
      const findInstance = listLayers?.find(item => {
        if (item?.options?.objectData?.source) {
          return item?.options?.objectData?.source?.id === itemObjectId
        }
      })
      findInstance?.closeTooltip()
      return
    }

    if (!markerClustersRef) return
    const findInstance = markerClustersRef?.current?.getLayers()?.find(item => {
      if (item?.options?.objectData?.source) {
        return item?.options?.objectData?.source?.id === itemObjectId
      }
    })
    findInstance?.closeTooltip()
  }

  useEffect(() => {
    // HIDE OBJECT ARE HAVE A distance_to_reference null WHEN SORT DISTANCE ACTIVE
    let newObjectList
    if (objectSortFilter?.byDistance) {
      newObjectList = [...objectList].filter(item => item?.state?.distance_to_reference > 0 ? true : false)
    } else {
      newObjectList = [...objectList]
    }

    if(newObjectList.length && groupList) {
      // SET SX FOR COLORING BORDER
      setSxCustomClassPanelObject(groupList, setSxCustom)
    }

    if (objectSortFilter?.byName) {
      // SORT THE GROUP CHILDRENS
      newObjectList.sort((firstItem, secondItem) => {
        if (firstItem.label < secondItem.label) return -1
        else if (firstItem.label > secondItem.label) return 1
        else return 0
      })

      // THEN SORT THE GROUP PARENTS IF THE GROUP BY ROW FEATURE IS ACTIVE
      if (!objectSortFilter?.removeGrouping) {
        newObjectList.sort((firstItem, secondItem) => {
          if (firstItem.group.title < secondItem.group.title) return -1
          else if (firstItem.group.title > secondItem.group.title) return 1
          else return 0
        })
      }
    }

    if (objectSortFilter?.byStatus) {
      newObjectList.sort((firstItem, secondItem) => {
        if (firstItem?.state?.connection_status < secondItem?.state?.connection_status) return -1
        else if (firstItem?.state?.connection_status > secondItem?.state?.connection_status) return 1
        else return 0
      })
    }

    if (objectSortFilter?.byDistance && referenceLocation.length >= 2) {
      newObjectList.sort((firstItem, secondItem) => {
        return firstItem?.state?.distance_to_reference - secondItem?.state?.distance_to_reference
      })
    }

    // DEFAULT: SORT BY ID
    if (!objectSortFilter?.byName && !objectSortFilter?.byStatus && !objectSortFilter?.byDistance) {
      newObjectList.sort((firstItem, secondItem) => {
        if (firstItem.id < secondItem.id) return -1
        else if (firstItem.id > secondItem.id) return 1
        else return 0
      })
    }

    filterObjectListBySearchPanelObject(objectSearch, newObjectList, setFilteredObjectList)
  }, [objectList, groupList, objectSearch, objectSortFilter, referenceLocation])

  return (
    <Box className={classes.panelContent} sx={sxCustom}>
      {/* HISTORY BUTTON */}
      <Fade in={selectionModel.length === 1}>
        <Fab 
          color='primary'
          className={classes.historyFab}
          onClick={() => setIsHistoryDateRangeOpen(true)}
        >
          <IconHistory />
        </Fab>
      </Fade>

      <CustomDataGridObjects
        apiRef={dataGridApiRef}
        columns={objectSortFilter?.removeGrouping ? [getCheckboxColumn(dataGridApiRef), ...columnList] : [getCheckboxColumn(dataGridApiRef)]}
        selectionModel={selectionModel}
        rows={filteredObjectList}
        treeData={!objectSortFilter?.removeGrouping}
        getTreeDataPath={(row) => [ row.group.groupName, row.id ]}
        groupingColDef={groupingColDef}
        defaultGroupingExpansionDepth={-1}
        getRowClassName={(params) => setRowClassByGroupObject(params)}
        className={classes.objectDataGrid}
        onCellClick={(params, event, details) => {
          handleCellClickPanelObject(params, event, details, selectionModel, setSelectionModel , () => handleChildrenClick(params.row), setHistoryPolylineList)
        }}
        onColumnHeaderClick={(params, event, details) => handleColumnHeaderClickPanelObject(params, event, details, selectionModel, setSelectionModel, dataGridApiRef)}
        headerHeight={0}
        componentsProps={{
          row: {
            onMouseEnter: handleMouseEnterItem,
            onMouseLeave: handleMouseLeaveItem,
          }
        }}
      />
    </Box>
  )
}

export default TabObject