import { useContext, useEffect } from 'react'

// COMPONENTS
import EvidencesList from '../EvidencesList/EvidencesList'
import PanelChart from '../PanelChart/PanelChart'

// CONTEXTS
import { PageAnalyticsContext } from 'contexts/PageAnalyticsContext'

// CONSTANTS
import valuesPageAnalytics from 'constants/valuesPageAnalytics'

// MUIS
import Button from '@mui/material/Button'
// import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconDelete from '@mui/icons-material/Delete'
import IconMenuOpen from '@mui/icons-material/MenuOpen'

// PATH ICONS
import IconBarChart from 'assets/images/pathIcons/IconBarChart'
import IconDatabase from 'assets/images/pathIcons/IconDatabase'
// import IconEqualizerLine from 'assets/images/pathIcons/IconEqualizerLine'
import IconViewAgenda from 'assets/images/pathIcons/IconViewAgenda'

// SERVICES
import { getTagEvidencesListSearch } from 'services/TagEvidencesService'
import { gets as getDevicesListSearch } from 'services/DeviceService'

// STYLES
import useStyles from './panelRightUseStyles'

const PanelRight = () => {
  const classes = useStyles()
  const {
    viewType, setViewType, setDataDevicesList, viewContent,
    setViewContent, setDataTagsList, paramsFilter, setParamsFilter, dataEvidences,
    setIsPanelRightShown
  } = useContext(PageAnalyticsContext)

  const fetchDataSelectFilter = async () => {
    const responseDevices = await getDevicesListSearch({
      size: 10000,
      sort: 'label,asc'
    }, {
      description: '',
      deviceAppVersion: '',
      deviceCode: '',
      deviceLanguage: '',
      deviceModel: '',
      deviceOSVersion: '',
      groupName: '',
      groupNo: null,
      label: '',
      numberOfOfficer: null,
      numberOfVehicle: null,
      statuses: [
        'ONLINE', 'OFFLINE'
      ]
    })
    const responseTags = await getTagEvidencesListSearch({
      size: 2000,
      sort: 'tagName,asc'
    }, {
      globalSearch: '',
      groupName: '',
      tagName: ''
    })

    if(responseTags?.rows?.length) setDataTagsList(responseTags.rows)
    if(responseDevices?.rows?.length) setDataDevicesList(responseDevices.rows)
  }

  const calculateTotalFilter = () => {
    return paramsFilter.tagEvidences.length + paramsFilter.devices.length
  }

  useEffect(() => {
    fetchDataSelectFilter()
  }, [])

  return (
    <Stack className={classes.root}>
      <Stack alignItems='center' direction='row' paddingTop='8px' paddingBottom='8px' paddingLeft='16px'>
        {/* MINIMIZE ICON */}
        <IconButton 
          className={classes.iconToggle}
          onClick={() => setIsPanelRightShown(false)}
        >
          <IconMenuOpen/>
        </IconButton>

        <Typography className={classes.titleHeader} variant='h6'>Dashboard</Typography>
      </Stack>

      {/* HEADER */}
      <Stack className={classes.headerWrapper}>
        <Stack direction='row' flexWrap='nowrap' marginBottom='4px'>
          {/* SELECT TYPE DATA */}
          <FormControl className={classes.formControlTypeData}>
            <Select
              value={viewType}
              className={classes.selectTypeData}
              onChange={event => setViewType(event.target.value)}
              MenuProps={{
                PaperProps: {
                  className: classes.selectTypeDataMenu
                }
              }}
            >
              <MenuItem value='internal'>
                <Stack flexDirection='row' alignItems='center'>
                  <IconDatabase className={classes.iconTypeData} />
                  <Typography variant='subtitle2'>Internal</Typography>
                </Stack>
              </MenuItem>

              <MenuItem value='citizen'>
                <Stack flexDirection='row' alignItems='center'>
                  <IconDatabase className={classes.iconTypeData} />
                  <Typography variant='subtitle2'>Citizen</Typography>
                </Stack>
              </MenuItem>
            </Select>
          </FormControl>

          {/* <Divider className={classes.dividerFormControl} orientation='vertical' flexItem /> */}

          {/* BUTTON FILTER */}
          {/* <Button
            className={classes.buttonFilter}
            variant='outlined'
            onClick={() => setIsPanelFilterShown(true)}
          >
            <IconEqualizerLine className={classes.iconButtonFilter} fontSize='small' />
            <Typography variant='subtitle2'>Filter</Typography>

            {Boolean(calculateTotalFilter()) && <Stack className={classes.circleTotalFilter}>{calculateTotalFilter()}</Stack>}
          </Button> */}

          <Stack flex={1} direction='row' justifyContent='flex-end'>
            {/* VIEW LIST */}
            <IconButton
              className='marginRight8'
              color={viewContent === 'list' ? 'primary' : 'default'}
              onClick={() => setViewContent('list')}
            >
              <IconViewAgenda className={classes.iconViewData} />
            </IconButton>

            {/* VIEW CHART */}
            <IconButton
              color={viewContent === 'chart' ? 'primary' : 'default'}
              onClick={() => setViewContent('chart')}
            >
              <IconBarChart className={classes.iconViewData} />
            </IconButton>
          </Stack>
        </Stack>

        {calculateTotalFilter() ? <Stack direction='row' alignItems='center'>
          <Typography className={classes.textResultTotal} variant='subtitle2' flex={1}>
            Menampilkan {dataEvidences.slice(0, 100).length} hasil
          </Typography>

          <Button
            variant='text'
            startIcon={<IconDelete fontSize='small'/>}
            onClick={() => setParamsFilter(valuesPageAnalytics.defaultParamsFilter)}
          >
            Hapus Filter
          </Button>
        </Stack> : ''}
      </Stack>

      {/* CONTENT */}
      <Stack className={classes.content}>
        {viewContent === 'list' && <EvidencesList />}
        {viewContent === 'chart' && <PanelChart />}
      </Stack>
    </Stack>
  )
}

export default PanelRight