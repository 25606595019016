import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_LAT, DEFAULT_LONG } from '../constants/values'

export const trackingSlice = createSlice({
  name: 'TrackingStore',
  initialState: {
    selectedDevices: [],
    directionDevices: [],
    mapboxViewport: {
      width: '100%',
      height: '100%',
      latitude: DEFAULT_LAT,
      longitude: DEFAULT_LONG,
      zoom: 9
    }
  },
  reducers: {
    updateSelectedDevices: (state, action) => {
      state.selectedDevices = action.payload
    },
    updateDirectionDevices: (state, action) => {
      state.directionDevices = action.payload
    },
    updateViewport:  (state, action) => {
      state.mapboxViewport = action.payload
    },
  },
})

export const {
  updateSelectedDevices,
  updateDirectionDevices,
  updateViewport
} = trackingSlice.actions

export default trackingSlice.reducer
