import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// COMPONENTS
import CustomFormControl from 'components/Customs/CustomFormControl'
import CustomInputNew from 'components/Customs/CustomInputNew'
import CustomInputLabelNew from 'components/Customs/CustomInputLabelNew'
import DialogAddOrEdit from 'components/DialogAddOrEditNew/DialogAddOrEdit'
import DialogColorPicker from 'components/DialogColorPicker/DialogColorPicker'
import DialogIconPicker from 'components/DialogIconPicker/DialogIconPicker'
import SimpleBackdrop from 'components/Partials/SimpleBackdrop'

// CONSTANTS
import { basePaths } from 'constants/paths'
import { colors } from 'constants/colors'
import { iconListTagEvidences } from 'constants/icons'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconTextFields from '@mui/icons-material/TextFields'

// PATH ICONS
import IconImageAdd from 'assets/images/pathIcons/IconImageAdd'

// STYLES
import useStyles from './dialogAddOrEditSosMessageTypesUseStyles'

// SERVICES
import { postSosMessageTypes, putSosMessageTypes } from 'services/SettingsSosMessageTypesServices'

const DialogAddOrEditSosMessageTypes = (props) => {
  const {
    dialogAddEditSosMessageType,
    setDialogAddEditSosMessageType,
    pageRef,
    reloadData
  } = props

  const { setSnackbarObject } = useContext(AllPagesContext)

  const history = useHistory()
  const classes = useStyles()
  const location = useLocation()

  const defaultParamsObject = {
    iconId: '',
    sosMessageTypeColor: colors.flamingo,
    sosMessageTypeName: '',
  }

  const [isLoading, setIsLoading] = useState(false)
  const [isDialogColorShown, setIsDialogColorShown] = useState(false)
  const [isDialogIconShown, setIsDialogIconShown] = useState(false)
  const [paramsObject, setParamsObject] = useState(defaultParamsObject)

  const closeDialog = () => {
    setDialogAddEditSosMessageType(null)
    history.push(basePaths.settingsSosMessageType)
  }

  const showAddIcon = () => {
    if (paramsObject?.iconId) {
      const Icon = iconListTagEvidences[Number(paramsObject.iconId)-1]?.Icon
      return <Icon className={classes.iconAddedView} />
    } else return <IconImageAdd className={classes.iconAddImage} fontSize='large'/>
  }

  const handleParamsChange = (key, value) => {
    setParamsObject({
      ...paramsObject,
      [key]: value,
    })
  }

  const handleButtonSaveClick = async () => {
    setIsLoading(true)
    let response = {}

    if(!paramsObject?.sosMessageTypeName || !paramsObject?.iconId || !paramsObject?.sosMessageTypeColor) {
      setIsLoading(false)
      return setSnackbarObject({
        open: true,
        severity: 'warning',
        title: '',
        message: 'Semua field harus terisi',
      })
    }

    if(dialogAddEditSosMessageType?.mode === 'add') {
      response = await postSosMessageTypes({
        ...paramsObject,
        sosMessageTypeColor: paramsObject?.sosMessageTypeColor || colors.flamingo
      })
      
      if(response?.status === 'error') {
        setSnackbarObject({
          open: true,
          severity: 'error',
          title: '',
          message: 'Gagal Menambahkan Tipe Pesan SOS',
        })
      } else {
        setSnackbarObject({
          open: true,
          severity: 'success',
          title: '',
          message: 'Berhasil Menambahkan Tipe Pesan SOS',
        })
      }
    } else if(dialogAddEditSosMessageType?.mode === 'edit') {
      response = await putSosMessageTypes(
        dialogAddEditSosMessageType?.data?.sosMessageTypeNo,
        {
          ...paramsObject,
          sosMessageTypeColor: paramsObject?.sosMessageTypeColor || colors.flamingo
        }
      )

      if(response?.status === 'error') {
        setSnackbarObject({
          open: true,
          severity: 'error',
          title: '',
          message: 'Gagal Mengubah Tipe Pesan SOS',
        })
      } else {
        setSnackbarObject({
          open: true,
          severity: 'success',
          title: '',
          message: 'Berhasil Mengubah Tipe Pesan SOS',
        })
      }
    }
  
    setIsLoading(false)

    if (response?.status !== 'error') {
      reloadData()
      closeDialog()
    }
  }

  useEffect(() => {
    if (location.pathname === basePaths.settingsSosMessageType && (!location.search.includes('?mode=edit') && !location.search.includes('?mode=add'))) {
      closeDialog()
    }
  }, [location])

  useEffect(() => {
    if(dialogAddEditSosMessageType?.mode === 'edit') {
      setParamsObject(dialogAddEditSosMessageType?.data)
    } else {
      setParamsObject(defaultParamsObject)
    }
  }, [dialogAddEditSosMessageType])

  return (
    <>
      {/* DIALOG ADD OR EDIT */}
      <DialogAddOrEdit
        open={Boolean(dialogAddEditSosMessageType)}
        title='Jenis Pesan SOS'
        onCloseIconClick={() => history.push(basePaths.settingsSosMessageType)}
        containerRef={pageRef}
        contents={[
          <>
            {/* MAIN SECTION TEXT */}
            <Typography variant='h6' className={classes.sectionText}>
              Informasi
            </Typography>

            {/* ICON & COLOR */}
            <Stack className={`${classes.pockbaseStyles} ${classes.formControl}`} direction='row' flexWrap='nowrap' alignItems='center'>
              {/* CHANGE ICON */}
              <Stack className={classes.addIconWrap}>
                <IconButton
                  className={classes.btnAddImage}
                  disableRipple
                  onClick={() => setIsDialogIconShown(true)}
                >
                  {showAddIcon()}
                </IconButton>
              </Stack>

              <Typography variant='subtitle1' className={classes.pockbaseStylesLabel}>Icon</Typography>

              {/* CHANGE COLOR */}
              <Stack flex={1} direction='row' justifyContent='flex-end' paddingRight='8px'>
                <Box
                  className={classes.boxColor}
                  sx={{
                    backgroundColor: paramsObject?.sosMessageTypeColor || colors.flamingo
                  }}
                  onClick={() => setIsDialogColorShown(true)}
                ></Box>
              </Stack>
            </Stack>

            {/* NAME PESAN SOS */}
            <CustomFormControl variant='filled' className={classes.formControl}>
              <CustomInputLabelNew shrink={true}>
                <IconTextFields />
                <Typography variant='subtitle1'>Nama Pesan SOS</Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Masukkan Nama Pesan SOS'
                onChange={(event) => handleParamsChange('sosMessageTypeName', event.target.value)}
                value={paramsObject?.sosMessageTypeName}
              />
            </CustomFormControl>
          </>
        ]}
        onCancelButtonClick={() => history.push(basePaths.settingsSosMessageType)}
        onSaveButtonClick={() => handleButtonSaveClick()}
      />

      {/* LOADING */}
      <SimpleBackdrop isLoading={isLoading} />

      {/* DIALOG COLOR */}
      <DialogColorPicker
        isDialogColorShown={isDialogColorShown}
        setIsDialogColorShown={setIsDialogColorShown}
        selectedColor={paramsObject.sosMessageTypeColor}
        onSaveButtonClick={selectedColor => setParamsObject({
          ...paramsObject, sosMessageTypeColor: selectedColor
        })}
      />

      {/* DIALOG ICON */}
      <DialogIconPicker
        isDialogIconShown={isDialogIconShown}
        setIsDialogIconShown={setIsDialogIconShown}
        selectedIcon={paramsObject.iconId}
        onSaveButtonClick={selectedIcon => setParamsObject({
          ...paramsObject, iconId: selectedIcon
        })}
        listIcon={iconListTagEvidences}
      />
    </>
  )
}

export default DialogAddOrEditSosMessageTypes