import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { colors } from 'constants/colors'

const useStyles = makeStyles(theme => ({
  markerHistory: {
    border: `1px solid ${theme.palette.common.white}`,
    height: 32,
    width: 32,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: 16,
      color: theme.palette.common.white
    }
  },
  popupWrapper: {
    height: '100%',
    justifyContent: 'center'
  },
  popupRoot: {
    backgroundColor: theme.palette.common.white,
    padding: 16,
    borderRadius: 0,
    boxShadow: `2px 2px 8px ${alpha(theme.palette.common.black, 0.24)} !important`,
  },
  popupTitle: {
    color: theme.palette.text.secondary,
    marginBottom: 8,
    fontSize: '12px !important'
  },
  popupAddress: {
    marginBottom: 8,
    fontSize: '12px !important'
  },
  popupCoordinate: {
    marginBottom: 8,
    fontSize: '12px !important'
  },
  popupMarker: {
    marginBottom: 0,
    '& p': {
      margin: 0,
    },
    '& .leaflet-popup-tip-container': {
      display: 'none'
    },
    '& .leaflet-popup-content-wrapper': {
      borderRadius: 0,
      boxShadow: 'none',
      backgroundColor: 'transparent'
    },
    '& .leaflet-popup-content': {
      height: 180,
      margin: 0,
    }
  },
  popupLabelInfo: {
    color: colors.raven,
    width: 80,
    fontSize: '12px !important'
  },
  popupItemInfo: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0
    }
  },
  popupDetailInfo: {
    fontSize: '12px !important'
  }
}))

export default useStyles