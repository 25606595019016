// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconRealtime = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 10.599 21.7007 9.2725 21.1797 8.0625L19.6211 9.61914C19.7617 10.0641 19.8642 10.5267 19.9277 11H18V13H19.9316C19.4781 16.6111 16.6111 19.4781 13 19.9316V18H11V19.9316C7.38888 19.4781 4.52189 16.6111 4.06836 13H6V11H4.06836C4.52189 7.38888 7.38888 4.52189 11 4.06836V6H13V4.06641C13.9971 4.19572 14.9339 4.51309 15.7832 4.97461L17.252 3.50781C15.725 2.55881 13.93 2 12 2ZM19.293 4.29297L12 11.5859L8.70703 8.29297L7.29297 9.70703L12 14.4141L20.707 5.70703L19.293 4.29297Z' />
    </SvgIcon>
  )
}

export default IconRealtime