// MUI STYLES
import { makeStyles } from '@mui/styles'

// CONSTANT
import {colors} from 'constants/colors'

const useStyles = makeStyles((theme) => ({
  pageRoot : {
    width : '100%',
    height : '100%',
    display : 'flex',
    padding : 24,
    backgroundColor : colors.codDray
  },
  mainContainer : {
    display : 'flex',
    width : '100%',
    height : '100%',
    flexDirection : 'row',
    justifyContent : 'space-between'
  },
  contentLeft : {
    height : '100%',
  },
  contenRight : {
    backgroundColor : colors.shark,
    width : '20%',
    height : '100%',
    marginLeft : 20,
    borderRadius : 10,
  },
  header : {
    height : 80,
    width : '100%',
    display : 'flex',
    justifyContent : 'space-between'
  },
  listView : {
    width : '12%',
    height : '100%',
    backgroundColor : colors.shark,
    borderRadius : 10,
    padding : '0px 14px',
    display : 'flex',
    justifyContent : 'space-between',
    alignItems : 'center',
  },
  listViewTitle : {
    fontSize : 20,
    fontWeight : 600,
    color : theme.palette.default.main
  },
  arrowIconButton : {
    backgroundColor : '#D9D9D9',
    height : 28,
    borderRadius : 4,
    width : 28,
    '&:hover' : {
      backgroundColor : '#D9D9D9'
    }
  },
  arrowIcon : {
    height : 12,
  },
  roomName : {
    width : '88%',
    height : '100%',
    backgroundColor : colors.shark,
    display : 'flex',
    alignItems : 'center',
    marginLeft : 20,
    borderRadius : 10
  },
  logoCompany : {
    width : 45,
    margin : '0px 40px'
  },
  roomTitleContainer : {
    display : 'flex',
    flexDirection : 'column'
  },
  roomTitleWrapper : {
    display : 'flex'
  },
  arrowDropDown : {
    color : theme.palette.primary.main,
  },
  iconButtonArrowDropDown : {
    height : 5,
    width : 25,
    marginTop : 5,
  },
  roomTitle : {
    fontSize : 16,
    fontWeight : 600,
    color : theme.palette.default.main
  },
  timeDuration : {
    fontSize : 14,
    fontWeight : 400,
    color : 'rgba(255, 255, 255, 0.54)'
  },
  content : {
    height : '78%',
    width : '100%',
    display : 'flex',
    justifyContent : 'space-between',
    margin : '20px 0px'
  },
  videoPlayer : {
    width : '12%',
    height : '100%',
    display : 'flex',
    marginRight : 20,
    flexDirection : 'column',
    overflowY : 'scroll',
    '&::-webkit-scrollbar' : {
      display : 'none'
    }
  },
  mainScreen : {
    display : 'flex',
    justifyContent : 'center',
    height : '100%',
    backgroundColor : colors.shark,
    borderRadius : 10,
    position : 'relative'
  },
  mainScreenSize : {
    height : '100%',
    width : 431,
    objectFit : 'cover'
  },
  mainScreenInfo : {
    position : 'absolute',
    bottom : 24,
    display : 'flex',
  },
  micIconButon : {
    height : 28,
    width : 28,
    backgroundColor : colors.carnation,
    '&:hover' : {
      backgroundColor : colors.carnation,
    }
  },
  micIcon : {
    color : theme.palette.default.main,
    width :16
  },
  footer : {
    height : '80px',
    width : '100%',
    backgroundColor : colors.shark,
    borderRadius : 10,
    display : 'flex',
    justifyContent : 'center',
    alignItems : 'center'
  },
  mainScreenTitleContianer : {
    padding : '0px 40px',
    display : 'flex',
    alignItems : 'center',
    backgroundColor : 'rgba(0, 0, 0, 0.3)',
    borderRadius : 41,
    marginLeft : 8
  },
  mainScreenText : {
    fontSize : 14,
    fontWeight : 400,
    color : theme.palette.default.main
  },
  footerIconContainer : {
    display : 'flex',
  },
  footerIconButton : {
    width : 45,
    height : 45,
    backgroundColor : 'rgba(255, 255, 255, 0.1)',
    borderRadius : 10,
    marginRight : 20,
    '&:hover' : {
      backgroundColor : 'rgba(255, 255, 255, 0.1)'
    }
  },
  footerGroupIconButton : {
    width : 45,
    height : 45,
    backgroundColor : theme.palette.primary.main,
    borderRadius : 10,
    marginRight : 20,
    '&:hover' : {
      backgroundColor : theme.palette.primary.main
    }
  },
  footerCallIconButton : {
    width : 45,
    height : 45,
    backgroundColor : colors.carnation,
    borderRadius : 10,
    marginRight : 20,
    '&:hover' : {
      backgroundColor : colors.carnation,
    }
  },
  footerIconMic : {
    color : theme.palette.default.main
  },
  participant : {
    display : 'flex',
    justifyContent : 'space-between',
    alignItems : 'center',
    margin : '24px 16px'
  },
  didvider : {
    color : colors.black,
    borderBottom : 'solid 1px black'
  },
  searchBarContainer : {
    width : '100%',
    padding : '8px 16px'
  },
  menuSearchInput : {
    height : 40,
    width : '100%',
    borderRadius : 8,
    backgroundColor : 'rgba(255, 255, 255, 0.1)',
    color : theme.palette.default.main
  },
  searchIcon : {
    marginLeft : 5,
    color : theme.palette.default.main
  },
  listParticipantContiner : {
    marginTop : 20,
    padding : '0px 18px',
    height : '80%',
    overflowY : 'scroll',
    '&::-webkit-scrollbar' : {
      display : 'none'
    }
  },
  listParticipant : {
    marginBottom : 10,
    width : '100%',
    display : 'flex',
    justifyContent : 'space-between',
    alignItems : 'center'
  },
  parcticipantIcongroup : {
    display : 'flex'
  },
  participantIconButton : {
    width : 9,
    marginRight : 12
  },
  personRemoveIcon : {
    width : 9,
  },
  participantIcon : {
    color : theme.palette.default.main,
    height : 16,
  },
  participantName : {
    fontWeight : 400,
    fontSize : 12,
    color : theme.palette.default.main
  },
  localCLient : {
    fontWeight : 400,
    fontSize : 12,
    color : theme.palette.default.main,
  },
  localVideoPlayerPageRoot : {
    width : '100%',
    height : 160,
    display : 'flex',
    position : 'relative',
    marginBottom : 16,
  },
  localVideoPlayerContentContainer : {
    width : '100%',
    height : '100%',
    borderRadius : '10px !important',
    objectFit : 'cover',
    backgroundColor : colors.shark,
    '& .agora_video_player' : {
      borderRadius : 10,
    },
    '& div' : {
      borderRadius : 10,
      backgroundColor : `${colors.shark} !important`
    }
  },
  localVideoPlayerUserName : {
    position : 'absolute',
    display : 'flex',
    justifyContent : 'center',
    alignItems : 'center',
    width : '80%',
    left : '10%',
    bottom : 5,
    height : 25,
    borderRadius : 41,
    backgroundColor : 'rgba(0, 0, 0, 0.3)'
  },
  localVideoPlayerUserNameText : {
    color : theme.palette.default.main,
    fontSize : 12,
    fontWeight : 400,
  },
  dialogKickContainer : {
    height : 110,
    width : '100%',
    borderRadius : 10,
    backgroundColor : 'rgba(18, 18, 18, 1)',
    boxShadow : theme.shadows[3],
    padding : 12
  },
  dialogKickTitle : {
    fontSize : 14,
    fontWeight : 600,
    color : theme.palette.default.main,
    marginBottom : 4,
  },
  dialogKickValue : {
    fontSize : 12,
    fontWeight : 400,
    maxWidth : 250,
    color : theme.palette.default.main,
  },
  dialogKickCancelBtn : {
    marginLeft : '54%',
    fontSize : 12,
    color : theme.palette.default.main
  },
  dialogKickOkeBtn : {
    fontSize : 12,
    color : theme.palette.primary.main
  }
}))

export default useStyles
