// MUI ICONS
import IconRoom from '@mui/icons-material/Room'

export const drawerWidth = 256

export const values = {
  // DASHBOARD TYPE
  dashboardType: process.env.REACT_APP_DASHBOARD_TYPE,
  dashboardTypeDefault: 'DEFAULT',
  dashboardTypeOther: 'OTHER',

  // EVIDENCE
  evidenceTypeList: [ 'NEEDED_HELP', 'PENDING', 'RESOLVED' ],
  
  // GENERAL
  fontFamily: [ 'DIN Regular', 'sans-serif' ].join(','),
  initialSnackbarObject: {
    open: false,
    severity: 'success',
    title: '',
    message: '',
  },

  // MAP
  minMapZoom: 2,
  maxMapZoom: 20,
}

export const listItemIcon = {
  minWidth: '5px',
  padding: '7px 9px',
  marginTop: '0'
}

export const menuIconCst = {
  width: '20px',
  minWidth: '20px',
  marginRight: '15px'
}

export const btnGroupCst = {
  fontSize: '12px',
  textTransform: 'capitalize',
  lineHeight: '12px',
  paddingTop: '10px',
  paddingBottom: '10px'
}

export const customBtn = {
  paddingTop: '7px',
  paddingBottom: '7px',
  textTransform: 'capitalize'
}

export const dateFormatCst = 'dd/MM/yyyy hh:mm a'

export const colorsCst = ['#E2523E', '#D87972', '#D43A64', '#E26C92', '#9036AD', '#AF6DC3', '#6041AD', '#795BBA'
  , '#4052B4', '#7A87C7', '#4397F2', '#77B3EC', '#46A8F4', '#6BC3F3', '#51B9CE', '#70D1DC',
  '#419489', '#68B5A8', '#67AC5B', '#8FC586', '#99C057', '#B6D28D', '#D2DB59', '#E0E48B',
  '#FCE95E', '#FDF184', '#F6C043', '#F9D669', '#F29938', '#F5B660', '#EC6036', '#F08C6D',
  '#735748', '#9E887E', '#9E9E9E', '#E0E0E0', '#667C8A', '#93A4AD', '#010002', '#424242'
]

export const MAPBOX_TOKEN = 'pk.eyJ1IjoidmFsaWQtZmFyaXoiLCJhIjoiY2tkcmNkNDd1MDN3ajJ5cGFobGxuMGYxeSJ9.NMQwmCPAMRlWE7xeErNPBQ'
export const MAPBOX_STYLE = 'mapbox://styles/valid-fariz/ckxqwme4yrxih15nsc41lln3f'

export const menuCst = {
  dashboard: 'Dashboard',
  live: 'Live',
  conference: 'Conference',
  agentHelp: 'Agent Help',
  agentPlaybackVideo: 'Agent Playback Video',
  tracking: 'Tracking',
  dispatchPatrol: 'Penugasan - Giat',
  dispatchEvidence: 'Penugasan - Kejadian',
  patrol: 'Giat',
  evidence: 'Kejadian',
  androidDevices: 'Android Devices',
  vehicles: 'Vehicles',
  alert: 'Alert',
  dutyTypes: 'Duty Types',
  activityTypes: 'Activity Types',
  disturbances: 'Disturbances',
  cases: 'Cases',
  officers: 'Officers',
  groups: 'Groups',
  places: 'Places',
  gpsDevices: 'GPS Devices',
  users: 'Users',
  securityControl: 'Security Control',
  profile: 'Profile',
  role: 'Role',
}

export const actionCst = {
  fullAccess: 'Full Access',
  view: 'View',
  edit: 'Edit',
  create: 'Create',
  delete: 'Delete',
}

export const DEFAULT_LAT = -6.2
export const DEFAULT_LONG = 106.81

export const listCityWithLatLng = [
  {
    name: 'Lokasi Terkini',
    zoom: 14,
    icon: IconRoom,
  },
  {
    name: 'Jakarta',
    latLng: [-6.200000, 106.816666],
    zoom: 11,
  },
  {
    name: 'Tangerang',
    latLng: [-6.178306, 106.631889],
    zoom: 12,
  },
  {
    name: 'Depok',
    latLng: [-6.385589, 106.830711],
    zoom: 13,
  },
  {
    name: 'Bandung',
    latLng: [-6.914864, 107.608238],
    zoom: 13,
  },
  {
    name: 'Semarang',
    latLng: [-6.966667, 110.416664],
    zoom: 13,
  },
  {
    name: 'Yogyakarta',
    latLng: [-7.797068, 110.370529],
    zoom: 12,
  },
  {
    name: 'Surabaya',
    latLng: [-7.250445, 112.768845],
    zoom: 12,
  },
  {
    name: 'Denpasar',
    latLng: [-8.650000, 115.216667],
    zoom: 10,
  }
]