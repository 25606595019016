import React  from 'react'
import { Redirect } from 'react-router-dom'

// CONTEXTS
import { AuthLayoutContextProvider } from 'contexts/AuthLayoutContext'

// LAYOUTS
import AuthLayout from 'layouts/AuthLayout'

// UTILS
import { isAuthenticated } from 'utilities/auth'

const AuthRoute = (props) => {
  const { children } = props

  if(!isAuthenticated()) {
    return (
      <AuthLayoutContextProvider>
        <AuthLayout>
          {children}
        </AuthLayout>
      </AuthLayoutContextProvider>
    )
  } else {
    return(
      <Redirect to='/dashboard' />
    )
  }
}

export default AuthRoute