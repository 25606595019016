import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

// MUIS
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconArrowBack from '@mui/icons-material/ArrowBack'

// STYLES
import useStyles from './pageHeaderBackUseStyles'

const PageHeaderBack = (props) => {
  const {
    targetBackUrl,
    pageTitle,
  } = props

  const classes = useStyles()

  const history = useHistory()

  return (
    <Box className={classes.headerRoot}>
      {/* BACK ICON */}
      <IconButton onClick={() => history.push(targetBackUrl)}>
        <IconArrowBack/>
      </IconButton>

      {/* TITLE */}
      <Typography
        variant='h6'
        className={classes.title}
        onClick={() => history.push(targetBackUrl)}
      >
        {pageTitle}
      </Typography>
    </Box>
  )
}

PageHeaderBack.defaultProps = {
  targetBackUrl: '/',
  pageTitle: '',
}

PageHeaderBack.propTypes = {
  targetBackUrl: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
}

export default PageHeaderBack
