import { configureStore } from '@reduxjs/toolkit'
import mainReducer from './MainReducer'
import trackingReducer from './TrackingReducer'

export default configureStore({
  reducer: {
    mainStore: mainReducer,
    trackingStore: trackingReducer,
  },
})
