// MUI STYLES
import { makeStyles } from '@mui/styles'

// CONSTANTS
import { colors } from 'constants/colors'

const useStyles = makeStyles((theme) => ({
  dialogWrap: {
    padding: '60px',
    background: '#FFFFFF',
    boxShadow: '-1px 10px 10px rgba(0, 0, 0, 0.05), 1px 12px 30px rgba(0, 0, 0, 0.1), 0px 2px 5px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '538px'
  },
  dialogHeader: {
    padding: 0,
    textAlign: 'center'
  },
  dialogTitle: {
    fontSize: '20px',
    fontWeight: 600,
    marginBottom: 14
  },
  dialogDescription: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  dialogContent: {
    padding: 0,
    overflowY: 'unset'
  },
  iconAndFormControlContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    height: 65,
    width: '100%',
    flex: '0 0 65px',
    marginBottom: 8
  },
  iconFormControl: {
    marginRight: 12,
    marginBottom: 2,
    color: colors.raven,
  },
  formControl: {
    width: '100%',
  },
  dialogActions: {
    padding: 0,
    textAlign: 'center',
    marginTop: 40
  },
  btnChangePassword: {
    fontWeight: 600,
    fontSize: '14px',
    padding: '13px 20px',
    width: '100%',
    '&:disabled': {
      backgroundColor: '#f7b680',
      color: 'white'
    }
  }
}))

export default useStyles