import { useContext } from 'react'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// LIBRARY
import moment from 'moment'
import parseHTML from 'html-react-parser'

// MUIS
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconCheckCircleOutline from '@mui/icons-material/CheckCircleOutline'
import IconDateRange from '@mui/icons-material/DateRange'
import IconErrorOutline from '@mui/icons-material/ErrorOutline'
import IconKey from 'assets/images/pathIcons/IconKey'
import IconSubject from '@mui/icons-material/Subject'
import IconPhoneAndroid from '@mui/icons-material/PhoneAndroid'
import IconTextFields from '@mui/icons-material/TextFields'
import IconWysiwyg from '@mui/icons-material/Wysiwyg'

// PATH ICONS
import IconCircleAround from 'assets/images/pathIcons/IconCircleAround'
import IconMapPin from 'assets/images/pathIcons/IconMapPin'
import IconRealtime from 'assets/images/pathIcons/IconRealtime'

// STYLES
import useStyles from './tabDispatchDetailUseStyles'

// UTILS
import { getColorCategoryDispatch, getColorStatusDispatch, getTextCategoryDispatch, getTextStatusDispatch } from 'pages/DispatchEvidences/dispatchEvidencesUtils'

const TabDispatchDetail = () => {
  const classes = useStyles()
  const { isPanelLeftShown } = useContext(PageTrackingContext)

  const itemRows = [
    {
      Icon: IconKey,
      label: 'ID',
      value: (
        <Chip
          variant='outlined'
          className={classes.chipCustom}
          label={isPanelLeftShown?.data?.dispatchNo}
        />
      )
    },
    {
      Icon: IconCircleAround,
      label: 'Status',
      value: (
        <Chip
          variant='outlined'
          className={classes.chipCustom}
          label={getTextStatusDispatch(isPanelLeftShown?.data?.status?.toLowerCase())}
          sx={{
            '& .MuiChip-label': {
              color: getColorStatusDispatch(isPanelLeftShown?.data?.status?.toLowerCase()),
              fontWeight: 700,
            }
          }}
        />
      )
    },
    {
      Icon: IconPhoneAndroid,
      label: 'Perangkat',
      value: (
        <Chip
          variant='outlined'
          className={classes.chipCustom}
          icon={<IconPhoneAndroid fontSize='small' />}
          label={isPanelLeftShown?.data?.deviceLabel}
        />
      )
    },
    {
      Icon: IconWysiwyg,
      label: 'Kategori',
      value: (
        <Chip
          variant='outlined'
          className={classes.chipCustom}
          icon={isPanelLeftShown?.data?.category?.toLowerCase() === 'priority'
            ? <IconErrorOutline fontSize='small'/>
            : <IconCheckCircleOutline fontSize='small'/>
          }
          label={getTextCategoryDispatch(isPanelLeftShown?.data?.category?.toLowerCase())}
          sx={{
            color: getColorCategoryDispatch(isPanelLeftShown?.data?.category?.toLowerCase()),
            '& .MuiSvgIcon-root': {
              color: getColorCategoryDispatch(isPanelLeftShown?.data?.category?.toLowerCase()),
            }
          }}
        />
      )
    },
    {
      Icon: IconTextFields,
      label: 'Judul',
      value: <Typography variant='caption' color='black'>{isPanelLeftShown?.data?.label ?? '-'}</Typography>
    },
    {
      Icon: IconDateRange,
      label: 'Tanggal Mulai',
      value: <Typography variant='caption' color='black'>{moment(isPanelLeftShown?.data?.startTime).format('DD MMM yyyy - HH:mm')}</Typography>
    },
    {
      Icon: IconDateRange,
      label: 'Tanggal Akhir',
      value: <Typography variant='caption' color='black'>{moment(isPanelLeftShown?.data?.endTime).format('DD MMM yyyy - HH:mm')}</Typography>
    },
    {
      Icon: IconMapPin,
      label: 'Lokasi',
      value: <Typography variant='caption' color='black'>{isPanelLeftShown?.data?.address?.fullAddress}</Typography>
    },
    {
      Icon: IconRealtime,
      label: 'Aktual',
      value: isPanelLeftShown?.data?.acceptedTime ? (
        <>
          <Typography display='block' color='black' variant='caption'>{moment(isPanelLeftShown?.data?.acceptedTime).format('DD MMM yyyy - HH:mm')}</Typography>
          <Typography display='block' color='black' variant='caption'>{isPanelLeftShown?.data?.address?.fullAddress}</Typography>
        </>
      ) : '-'
    }
  ]

  return (
    <Stack className={classes.root}>
      {itemRows.map((item, index) => {
        const { Icon, label, value } = item
        return (
          <Stack className={classes.itemDetail} marginBottom='16px'>
            <Icon className={classes.labelIcon} fontSize='small' />

            <Stack paddingLeft='8px'>
              <Typography className={classes.labelTitle}>{label}</Typography>

              <Stack direction='column' justifyContent='center'>
                {value}
              </Stack>
            </Stack>
          </Stack>
        )
      })}

      {/* DESKRIPSI */}
      <Stack className={classes.itemDetail}>
        <IconSubject className={classes.labelIcon} />

        <Stack paddingLeft='8px' width='100%'>
          <Typography className={classes.labelTitle}>Deskripsi</Typography>

          {/* CONTENT */}
          <Stack className={classes.contentBoxDescription}>
            <Typography
              className={classes.titleBoxDescription}
              variant='h6'
            >{isPanelLeftShown?.data?.label}</Typography>

            {isPanelLeftShown?.data?.description && parseHTML(isPanelLeftShown?.data?.description)}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default TabDispatchDetail