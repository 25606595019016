// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconTextToSpeech = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M4.00012 22C3.45012 22 2.97929 21.8042 2.58762 21.4125C2.19596 21.0208 2.00012 20.55 2.00012 20V4C2.00012 3.45 2.19596 2.97917 2.58762 2.5875C2.97929 2.19583 3.45012 2 4.00012 2H13.0001L11.0001 4H4.00012V20H15.0001V17H17.0001V20C17.0001 20.55 16.8043 21.0208 16.4126 21.4125C16.021 21.8042 15.5501 22 15.0001 22H4.00012ZM6.00012 18V16H13.0001V18H6.00012ZM6.00012 15V13H11.0001V15H6.00012ZM15.0001 15L11.0001 11H8.00012V6H11.0001L15.0001 2V15ZM17.0001 11.95V5.05C17.6001 5.4 18.0835 5.875 18.4501 6.475C18.8168 7.075 19.0001 7.75 19.0001 8.5C19.0001 9.25 18.8168 9.925 18.4501 10.525C18.0835 11.125 17.6001 11.6 17.0001 11.95ZM17.0001 16.25V14.15C18.1668 13.7333 19.1251 13.0125 19.8751 11.9875C20.6251 10.9625 21.0001 9.8 21.0001 8.5C21.0001 7.2 20.6251 6.0375 19.8751 5.0125C19.1251 3.9875 18.1668 3.26667 17.0001 2.85V0.75C18.7335 1.2 20.1668 2.1375 21.3001 3.5625C22.4335 4.9875 23.0001 6.63333 23.0001 8.5C23.0001 10.3667 22.4335 12.0125 21.3001 13.4375C20.1668 14.8625 18.7335 15.8 17.0001 16.25Z'/>
    </SvgIcon>
  )
}

export default IconTextToSpeech