import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// COMPONENTS
import CustomDialogActions from 'components/Customs/CustomDialogActions'
import CustomDialogDelete from 'components/Customs/CustomDialogDelete'

// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './dialogColorPickerUseStyles'

const DialogColorPicker = (props) => {
  const { isDialogColorShown, setIsDialogColorShown, selectedColor, onSaveButtonClick } = props
  const classes = useStyles()

  const listColor = [
    colors.flamingo ,colors.zest, colors.brown, colors.everglade,
    colors.fern, colors.viridian, colors.catalinaBlue, colors.blue,
    colors.havelockBlue, colors.sanMarino, colors.royalPurple,
    colors.vividViolet, colors.ceriseRed, colors.redBerry
  ]

  // STATES
  const [currentColor, setCurrentColor] = useState('')

  const handleDialogClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setIsDialogColorShown(false)
    }
  }

  const handleButtonSaveClick = () => {
    onSaveButtonClick(currentColor)
    setIsDialogColorShown(false)
  }

  const handleButtonCancelClick = () => {
    setCurrentColor(selectedColor)
    setIsDialogColorShown(false)
  }

  useEffect(() => {
    setCurrentColor('')
    if(selectedColor) setCurrentColor(selectedColor)
  }, [selectedColor])

  return (
    <CustomDialogDelete
      open={isDialogColorShown}
      className={classes.dialogColor}
      onClose={handleDialogClose}
    >
      <Typography variant='subtitle1' className={classes.dialogTitle}>Tambah Warna</Typography>

      <Stack className={classes.dialogContent}>
        <Typography className={classes.textLabel}>Pilih Warna</Typography>

        <Stack direction='row' flexWrap='wrap'>
          {listColor.map((item, index) => (
            <Box
              key={index}
              className={`${classes.boxColor} ${currentColor === item && 'selected'}`}
              sx={{
                backgroundColor: item
              }}
              onClick={() => setCurrentColor(item)}
            />
          ))}
        </Stack>
      </Stack>

      <CustomDialogActions>
        <Button
          className={classes.btnCancel}
          onClick={handleButtonCancelClick}
        >BATAL</Button>

        <Button
          className={classes.btnSave}
          onClick={handleButtonSaveClick}
        >SIMPAN</Button>
      </CustomDialogActions>
    </CustomDialogDelete>
  )
}

DialogColorPicker.defaultProps = {
  isDialogColorShown: false,
  selectedColor: colors.flamingo,
  onSaveButtonClick: () => {}
}

DialogColorPicker.propTypes = {
  isDialogColorShown: PropTypes.bool.isRequired,
  setIsDialogColorShown: PropTypes.func.isRequired,
  selectedColor: PropTypes.string.isRequired,
  onSaveButtonClick: PropTypes.func.isRequired
}

export default DialogColorPicker