import React from 'react'
import { Redirect } from 'react-router-dom'

// UTILS
import { isAuthenticated } from 'utilities/auth'

// CONTEXTS
import { PrivateLayoutContextProvider } from 'contexts/PrivateLayoutContext'

// LAYOUTS
import PrivateLayout from 'layouts/PrivateLayout'

const PrivateRoute = (props) => {
  const {
    children,
    isYScrollable, 
    isWithDrawer,
    providerContext : ProviderContext = null
  } = props

  // CHECK IF PAGE HAVE PRIVATE CONTEXT
  const hasProviderContext = (children, ProviderContext) => {
    if(ProviderContext) {
      return(
        <ProviderContext>
          {children}
        </ProviderContext>
      )
    } else {
      return children
    }
  }

  if(isAuthenticated()) {
    return(
      <PrivateLayoutContextProvider>
        <PrivateLayout
          isYScrollable={isYScrollable}
          isWithDrawer={isWithDrawer}
        >
          {hasProviderContext(children, ProviderContext)}
        </PrivateLayout>
      </PrivateLayoutContextProvider>
    )
  } else {
    return(
      <Redirect to='/login' />
    )
  }
}

export default PrivateRoute