import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// CONSTANTS
import { basePaths } from 'constants/paths'

// REDUX
import { useDispatch } from 'react-redux'
import { showAlert } from 'store/MainReducer'

// MUIS
import {
  Box,
  Typography,
  IconButton,
} from '@mui/material'

// COMPONENTS
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'
import PageHeader from 'components/PageHeader/PageHeader'
import Flyout from 'components/Flyout/Flyout'
import LoadingBox from 'components/LoadingBox/LoadingBox'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import DialogAddOrEditSettingsPlaces from './DialogAddOrEditSettingsPlaces/DialogAddOrEditSettingsPlaces'
import SettingsPlacesDetail from './SettingsPlacesDetail'

// STYLES
import useStyles from './settingsPlacesUseStyles'

// MUI ICON
import IconDelete from '@mui/icons-material/Delete'
import IconEdit from '@mui/icons-material/Edit'

// import { doDeleteDevice } from '../../../services/DeviceService'

// SERVICES
import {getAllPlacesList, deletePlace} from 'services/PlacesService'

// RAMDA
import { isEmpty, reject } from 'ramda'

export default function SettingsPlaces() {
  const dispatch = useDispatch()

  const history = useHistory()
  const location = useLocation()

  const pageRef = useRef()
  const [dialogAddSettingsPlaces, setDialogAddSettingsPlaces] = useState(null)
  const [dialogType, setDialogType] = useState('')
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [size, setPageSize] = useState(100)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('name')
  const [informationDetail, setInformationDetail] = useState(
    'Pilih entri dari daftar'
  )
  const [search, setSearch] = useState('')
  const [dialogDeleteSettingsPlaces, setDialogDeleteSettingsPlaces] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [allPlaces, setAllPlaces] = useState([])
  const [placeDetail, setPlaceDetail] = useState(null)

  const groupByList = [
    {
      title: 'Don\'t Group',
      value: null,
    },
  ]
  const [selectedGroupBy, setSelectedGroupBy] = useState(groupByList[0])
  const [isFilterOn, setIsFilterOn] = useState(false)
  const [selectionModel, setSelectionModel] = useState([])

  const classes = useStyles()

  const [filters, setFilters] = useState({
    name : '',
    address : '',
  })


  const loadData = () => {
    const newFilters = reject(isEmpty, filters)

    let params = {
      page,
      size,
    }

    const bodyParams = {
      name: filters.name === '' ? null : filters.name,
      place_no: null,
      zone: {
        address: filters.address === '' ? null : filters.address,
        area: null,
        description: null,
        geofence_id: null,
        label: null,
        zone_no: null
      }
    }

    if (order) params.sort = `${orderBy},${order}`
    if (search) bodyParams.globalSearch = search

    setIsLoading(true)

    getAllPlacesList(params, bodyParams).then((data) => {
      setIsLoading(false)
      setAllPlaces(data.rows.map((item,index) => {
        return {
          ...item,
          id : index,
          address : item.zone.address
        }
      }))
    })
  }

  useEffect(() => {
    loadData()
  }, [ page, size, order, orderBy, filters, search ])

  useEffect(() => {
    if (selectionModel.length === 0) {
      setPlaceDetail(null)
    } else if (selectionModel.length === 1) {
      const deviceSelected = allPlaces.find(
        (item) => item.id === selectionModel[0]
      )
      setPlaceDetail(deviceSelected)
      if (!isFlyoutShown) setIsFlyoutShown(true)
    } else if (selectionModel.length > 1) {
      setPlaceDetail(null)
    }
  }, [selectionModel])


  const groupingColDef = {
    headerName: 'Label',
    renderCell: (params) => {
      if (!params.rowNode.isAutoGenerated) {
        let className = classes.columnStatus
        if (params.row.status) {
          if (params.row.status?.toUpperCase() === 'ONLINE')
            className = `${classes.columnStatus} ${classes.columnStatusGreenOnline}`
          else if (params.row.status?.toUpperCase() === 'OFFLINE')
            className = `${classes.columnStatus} ${classes.columnStatusRedOffline}`
          else if (params.row.status?.toUpperCase() === 'PENDING')
            className = `${classes.columnStatus} ${classes.columnStatusGreyPending}`
        }
        return (
          <Typography 
            className={className}
            noWrap
          >
            {params.row.status.toLowerCase()}
          </Typography>
        )
      }

      return (
        <Typography 
          variant='subtitle1' 
          className={classes.groupingRow}
          noWrap
        >
          {params.rowNode.groupingKey.toLowerCase()}
        </Typography>
      )
    },
  }
  const handleColumnsMenuItemClick = (inputItem, inputIndex) => {
    let tempSelectedColumnList = [...selectedColumnList]
    if (
      (selectedGroupBy.value && inputItem.field === 'label') ||
      (selectedGroupBy.value && inputItem.field === 'deviceCode')
    ) {
    } else {
      tempSelectedColumnList[inputIndex].hide =
        !tempSelectedColumnList[inputIndex].hide
    }
    setSelectedColumnList(tempSelectedColumnList)
  }
  useEffect(() => {
    if (!isFilterOn) setFilters(filters)
  }, [isFilterOn])

  useEffect(() => {
    if (selectedGroupBy.value) {
      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        if (item.field === selectedGroupBy.value || item.field === 'deviceCode')
          item.hide = true
      })
      setSelectedColumnList(tempSelectedColumnList)
    } else {
      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        if (item.field === 'label' || item.field === 'deviceCode')
          item.hide = false
      })
      setSelectedColumnList(tempSelectedColumnList)
    }
  }, [selectedGroupBy])

  const handleDeleteIconButtonClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()
    setDialogDeleteSettingsPlaces(inputParams)
  }

  const handleEditIconButtonClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()
    history.push(
      `${basePaths.settingsPlaces}?mode=edit&id=${inputParams.id}`, 
      {
        id: inputParams.id,
        row: inputParams.row,
      }
    )
  }

  const initialColumns = [
    {
      field: 'name',
      headerName: 'Nama Lokasi',
      flex: 1,
      minWidth: 150,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) =>
        params.value && (
          <Typography 
            variant='caption' 
            className={classes.normalText}
            noWrap
          >
            {params.value}
          </Typography>
        ),
    },
    {
      field: 'address',
      headerName: 'Alamat',
      flex: 1,
      minWidth: 175,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) =>
        params.value && (
          <Typography 
            variant='caption' 
            className={classes.normalText}
            noWrap
          >
            {params.value}
          </Typography>
        ),
    },
    {
      field: 'actions',
      headerName: '',
      width: 80,
      hide: false,
      sortable: false,
      disableColumnMenu: true,
      areFilterAndSortShown: false,
      renderCell: (params) => (
        <Box className={classes.columnActions}>
          {/* EDIT ICON */}
          <CustomTooltipBlack title='Ubah' placement='bottom'>
            <IconButton
              onClick={(event) => handleEditIconButtonClick(event, params)}
            >
              <IconEdit />
            </IconButton>
          </CustomTooltipBlack>

          {/* DELETE ICON */}
          <CustomTooltipBlack title='Hapus' placement='bottom'>
            <IconButton
              onClick={(event) => handleDeleteIconButtonClick(event, params)}
            >
              <IconDelete />
            </IconButton>
          </CustomTooltipBlack>
        </Box>
      ),
    },
  ]
  const [selectedColumnList, setSelectedColumnList] = useState(initialColumns)

  const [isFlyoutShown, setIsFlyoutShown] = useState(false)

  const flyoutWidth = 528
  const flyoutTitleMargin = 400 // TO MAKE THE TITLE VERTICALLY ALIGN WITH THE FLYOUT COMPONENT

  const handleDeleteButtonClick = async () => {
    setIsLoading(true)
    const result = await deletePlace(dialogDeleteSettingsPlaces.row.place_no)

    if (result.status !== 'error') {
      setIsLoading(false)
      dispatch(showAlert({ message: 'Sukses Menghapus Lokasi' }))
      setDialogDeleteSettingsPlaces({})
      loadData()
    }
  }

  useEffect(() => {
    if (location.pathname === basePaths.settingsPlaces && location.search === '?mode=add') {
      setDialogType('add')
      setDialogAddSettingsPlaces(true)
    }
    else if (location.pathname === basePaths.settingsPlaces && location.search.includes('?mode=edit')) {
      setDialogType('edit')
      setDialogAddSettingsPlaces(location?.state)
    }
  }, [location])

  return (
    <Box className={classes.pageRoot} ref={pageRef}>
      <PageHeader
        isAddButtonAvailable={true}
        title='Lokasi'
        search={search}
        setSearch={setSearch}
        onAddButtonIsClicked={() => history.push(`${basePaths.settingsPlaces}?mode=add`)}
        isFlyoutAvailable={true}
        isFlyoutShown={isFlyoutShown}
        flyoutTitle='Informasi'
        flyoutTitleMargin={`${flyoutTitleMargin}px`}
        onToggleFlyoutClick={() => setIsFlyoutShown((current) => !current)}
      />

      {/* MAIN CONTENT AND FLYOUT */}
      <Box
        className={classes.contentContainer}
        sx={{ paddingRight: isFlyoutShown ? `${flyoutWidth + 24}px` : 0 }}
      >
        <LoadingBox isLoading={isLoading}>
          <DataGridFilters
            columns={initialColumns}
            selectedColumnList={selectedColumnList}
            handleColumnsMenuItemClick={handleColumnsMenuItemClick}
            isFilterOn={isFilterOn}
            setIsFilterOn={setIsFilterOn}
            groupByList={groupByList}
            selectedGroupBy={selectedGroupBy}
            setSelectedGroupBy={setSelectedGroupBy}
          />

          <DataGridTable
            initialColumns={initialColumns}
            rows={allPlaces}
            total={total}
            page={page}
            pageSize={size}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            setPage={setPage}
            setPageSize={setPageSize}
            setFilters={setFilters}
            isFilterOn={isFilterOn}
            selectedColumnList={selectedColumnList}
            setSelectedColumnList={setSelectedColumnList}
            selectedGroupBy={selectedGroupBy}
            getTreeDataPath={(row) => [row.label, row.id]}
            groupingColDef={groupingColDef}
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
            isCheckboxPinned={true}
          />
        </LoadingBox>

        {/* FLYOUT */}
        <Flyout isFlyoutShown={isFlyoutShown} flyoutWidth={flyoutWidth}>
          {placeDetail ? (
            <SettingsPlacesDetail
              place={placeDetail}
              reloadData={loadData}
            />
          ) : (
            <Typography
              variant='subtitle2'
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                background: '#fff',
              })}
            >
              {informationDetail}
            </Typography>
          )}
        </Flyout>
      </Box>

      {/* DIALOG ADD PLACES */}
      <DialogAddOrEditSettingsPlaces
        dialogType={dialogType}
        dialogAddSettingsPlaces={dialogAddSettingsPlaces}
        setDialogAddSettingsPlaces={setDialogAddSettingsPlaces}
        reloadData={loadData}
        pageRef={pageRef}
      />

      {/* DIALOG DELETE PLACES */}
      <DialogConfirmation
        title='Hapus Tempat'
        caption='Apa Anda yakin ingin menghapus tempat ini?'
        dialogConfirmationObject={dialogDeleteSettingsPlaces}
        setDialogConfirmationObject={setDialogDeleteSettingsPlaces}
        cancelButtonText='Batal'
        continueButtonText='Hapus'
        onContinueButtonClick={handleDeleteButtonClick}
        onCancelButtonClick={() => setDialogDeleteSettingsPlaces({})}
      />
    </Box>
  )
}
