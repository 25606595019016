// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  itemDetail: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    marginBottom: 16,
    '&:last-child': {
      marginBottom: 0,
    }
  },
  itemDetailIcon: {
    color: colors.raven,
    fontSize: 14,
  },
  chipSOS: {
    backgroundColor: theme.palette.error.main,
    height: '24px !important',
    borderRadius: '32px',
    paddingTop: 5,
    '& .MuiChip-label': {
      color: theme.palette.common.white,
      paddingLeft: '10px !important',
      paddingRight: '10px !important',
      '& .MuiSvgIcon-root': {
        fontSize: 14,
      }
    }
  },
  evidenceItemTag: {
    width: 'fit-content',
    border: `1px solid ${colors.silver}`,
    backgroundColor: 'transparent',
    margin: 0,
    borderRadius: 4,
    padding: '4px 2px',
    '& .MuiChip-label': {
      padding: '4px 6px 4px 8px',
      fontWeight: 600,
      fontSize: 12,
    },
    '& .MuiChip-icon': {
      fontSize: 14,
    },
    marginBottom: 4,
    '&:last-child': {
      marginBottom: 0,
    }
  },
  rootContent: {
    flex: 1,
    //height: '100%',
    position: 'relative',
    background: `linear-gradient(${colors.blackSqueeze} 30%, rgba(255,255,255,0)), linear-gradient(rgba(255,255,255,0), ${colors.blackSqueeze} 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0,0,0,0.08), rgba(255,255,255,0)), radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,0.08), rgba(255,255,255,0)) 0 100%`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'transparent',
    backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
    backgroundAttachment: 'local, local, scroll, scroll',
  },
  rootAssignContent: {
    boxShadow: `0px -2px 8px 0px ${alpha(theme.palette.common.black, 0.16)}`,
    backgroundColor: colors.blackSqueeze,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9
  },
  buttonSendHelp: {
    fontWeight: 700,
    textTransform: 'uppercase',
    borderRadius: 2,
  },
}))

export default useStyles