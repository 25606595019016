// MUI STYLES
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  headerTabs: {
    direction: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: 'absolute',
    width: '100%',
    zIndex: 1,
  },
  headerTab: {
    fontWeight: 600,
    '&.MuiTab-root.Mui-selected': {
      color: theme.palette.text.primary,
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
    },
  },
  content: {
    marginTop: '50px',
    overflow: 'auto',
    flex: '1',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
  },
  informationListItem: {
    alignItems: 'unset',
    '& .MuiListItemIcon-root': {
      minWidth: 'unset',
      marginRight: 12,
      marginTop: 4,
    },
    '& .MuiListItemText-primary': {
      fontWeight: 600,
      color: theme.palette.text.secondary,
      fontSize: 12,
    },
    '& .MuiListItemText-secondary': {
      fontWeight: 400,
      color: theme.palette.text.primary,
    },
  },
  informationListItemChannel: {
    '& .MuiListItemIcon-root': {
      minWidth: 'unset',
      marginRight: 12,
    },
  },
}))

export default useStyles