import { useContext, useState } from 'react'
import moment from 'moment'

// COMPONENTS
import ConversationItem from '../ConversationItem/ConversationItem'

// CONSTANTS
import { colors } from 'constants/colors'

// CONTEXTS
import { PageChatContext } from 'contexts/PageChatContext'

// MUIS
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import List from '@mui/material/List'
import Stack from '@mui/material/Stack'
import OutlinedInput from '@mui/material/OutlinedInput'

// MUI ICONS
import IconSearch from '@mui/icons-material/Search'

// UTILS
import { getTextByTypeMessage, parseMessageBody, setCurrentConversation, splitChannel } from '../chatUtils'

const ConversationList = () => {
  const { conversationList, setRerender, currentConversation, fetchHistoryMessages } = useContext(PageChatContext)

  // STATES
  const [search, setSearch] = useState('')

  const handleItemConversationClick = (item) => {
    setCurrentConversation({
      ...item,
      chatType: item.channel_id.includes('conference') ? 'groupChat' : 'singleChat'
    })
    setRerender(current => current+1)
    fetchHistoryMessages()
  }

  return (
    <Stack flex={1} height='100%'>
      {/* HEADER */}
      <Stack padding='16px'>
        {/* SEARCH INPUT */}
        <FormControl variant='outlined'>
          <OutlinedInput
            type='text'
            placeholder='Pencarian'
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            startAdornment={
              <InputAdornment position='start'>
                <IconSearch />
              </InputAdornment>
            }
            sx={{
              height: 40,
              paddingLeft: '8px',
              backgroundColor: colors.whiteLilac
            }}
          />
        </FormControl>
      </Stack>

      <Stack overflow='auto'>
        <List>
          {conversationList
            ?.filter(item => 
              item?.user?.nickname?.toLowerCase()?.includes(search)
              || item?.group?.groupname?.includes(search)
            )
            ?.map((item, index) => {
              const bodies = parseMessageBody(item?.meta?.payload)
              
              return (
                <ConversationItem
                  key={index}
                  name={item?.user?.nickname ?? item?.group?.groupname}
                  title={item?.user?.nickname ?? item?.group?.groupname}
                  description={getTextByTypeMessage(
                    bodies?.[0].type,
                    bodies?.[0]?.msg,
                    splitChannel(item.meta.from)
                  )}
                  date={moment(item?.meta?.timestamp).fromNow()}
                  avatarSize='small'
                  onItemClick={() => handleItemConversationClick(item)}
                  selected={currentConversation?.channel_id === item?.channel_id}
                />
              )
            })}
        </List>
      </Stack>
    </Stack>
  )
}

export default ConversationList