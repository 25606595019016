import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// COMPONENTS
import DialogAddItem from './DialogAddItem/DialogAddItem'
import LoadingBox from 'components/LoadingBox/LoadingBox'
import PageHeader from 'components/PageHeader/PageHeader'
import TimelineScheduler from './TimelineScheduler/TimelineScheduler'

// CONSTANTS
import { basePaths } from 'constants/paths'

// MUIS
import Box from '@mui/material/Box'

// SERVICES
import { gets as getDevicesList } from 'services/DeviceService'
import { postDispatchSearch } from 'services/DispatchService'

// STYLES
import useStyles from './dispatchPatrolsUseStyles'

// UTILS
import { adjustStructureDataKendo } from './dispatchPatrolsUtils'

const DispatchPatrols = () => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const [search, setSearch] = useState('')
  const [dialogAddShown, setDialogAddShown] = useState(null)
  const [dataDispatchPatrols, setDataDispatchPatrols] = useState([])
  const [dataDevices, setDataDevices] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const getDataDevices = async () => {
    const response = await getDevicesList({ size: 10000 }, {})
    setDataDevices(response?.rows || [])
  }

  const reloadDataDispatchPatrols = async () => {
    setIsLoading(true)
    const response = await postDispatchSearch({ size: 10000 }, { dispatchType: 'PATROL'})
    const restructureData = adjustStructureDataKendo(response?.rows)
    setDataDispatchPatrols(restructureData || [])
    setIsLoading(false)
  }

  useEffect(() => {
    if (location.pathname === basePaths.dispatchPatrols && (location.search.includes('?mode=edit') || location.search.includes('?mode=add'))) {
      if(location.search.includes('&lat=') && location.search.includes('&lng=')) {
        setDialogAddShown({ mode: 'add' })
      } else {
        setDialogAddShown(location?.state || { mode: 'add' })
      }
    }
    else setDialogAddShown(false)
  }, [location])

  useEffect(() => {
    getDataDevices()
  }, [])

  useEffect(() => {
    if(dataDevices?.length) {
      reloadDataDispatchPatrols()
    }
  }, [dataDevices])

  return (
    <Box className={classes.pageRoot}>
      <PageHeader
        isAddButtonAvailable
        title='Penugasan Kegiatan'
        search={search}
        setSearch={setSearch}
        onAddButtonIsClicked={() => history.push(`${basePaths.dispatchPatrols}?mode=add`, { mode: 'add' })}
        isFlyoutAvailable={false}
        isFlyoutShown={false}
      />

      <LoadingBox isLoading={isLoading} isYOverFlow>
        <TimelineScheduler
          dataDispatchPatrols={dataDispatchPatrols}
          setDataDispatchPatrols={setDataDispatchPatrols}
          dataDevices={dataDevices}
          reloadDataDispatchPatrols={reloadDataDispatchPatrols}
        />
      </LoadingBox>

      <DialogAddItem
        dialogAddShown={dialogAddShown}
        reloadData={reloadDataDispatchPatrols}
      />
    </Box>
  )
}

export default DispatchPatrols