import React, { useEffect, useRef, useState } from 'react'

// MUIS
import { Box, Typography, List, IconButton, ListItemAvatar, Avatar, ListItemText, ListItem } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

// STYLES
import useStyles from './liveStreamingItemUseStyles'

// MUI ICON
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import VideocamIcon from '@mui/icons-material/Videocam'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import PauseIcon from '@mui/icons-material/Pause'

// UTILITIES
import { getBackgroundAvatar, getStringAvatar } from 'utilities'

// AGORA
import AgoraRTC from 'agora-rtc-sdk-ng'

// STORAGE
import { addDeviceToLocalStorage, cleanUpDeviceLocalStorage, getDetailDeviceLocalStorage, hasDeviceOnStorage, updateDeviceToLocalStorage } from './liveStorage'

const LiveStreamingItem = (props) => {
  const { deviceCode, label, typeStream, idx } = props
  const classes = useStyles()

  const [isLoadingVideo, setIsloadingVideo] = useState(true)
  const [isOnMute, setIsOnMute] = useState(false)
  const [isVideoPlay, setIsVideoPlay] = useState(false)
  const [isHaveAudio, setIsHaveAudio] = useState(false)

  /* AGORA CONFIG */
  const agoraAppId = process.env.REACT_APP_AGORA_APP_ID
  const channel = deviceCode
  const randomId = Math.floor(Math.random() * 9999)
  const agoraClientRTCRef = useRef(
    AgoraRTC.createClient({
      mode: 'rtc',
      codec: 'vp8',
    })
  )
  const videoElementRef = useRef()
  const videoTrackRef = useRef()
  const audioTrackRef = useRef()

  /* INIT AGORA LIVE STREAM */
  const initAgoraLiveStream = async () => {
    const rtcTokenResponse = await fetch(
      `${process.env.REACT_APP_API_MOBILE_URL}/devices/generate-agora-token?deviceCode=${channel}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: { deviceCode: channel },
      }
    )

    const rtcTokenResponseJson = await rtcTokenResponse.json()
    const rtcToken = rtcTokenResponseJson.token

    agoraClientRTCRef.current
      .join(agoraAppId, channel, rtcToken, randomId)
      .then(async () => {
        let remoteUser = agoraClientRTCRef.current.remoteUsers[0]
        if (remoteUser) {
          /* SET VIDEO REF */
          if (remoteUser.hasVideo) {
            agoraClientRTCRef.current
              .subscribe(remoteUser, 'video')
              .then((track) => {
                videoTrackRef.current = track
              })
          }

          /* SET AUDIO REF */
          if (remoteUser.hasAudio) {
            agoraClientRTCRef.current
              .subscribe(remoteUser, 'audio')
              .then((track) => {
                audioTrackRef.current = track
              })
          }
        }
        setIsloadingVideo(false)
      })

    agoraClientRTCRef.current.on('user-published', async (user, mediaType) => {
      if (mediaType === 'video') {
        agoraClientRTCRef.current.subscribe(user, 'video').then((track) => {
          videoTrackRef.current = track

          if(hasDeviceOnStorage(deviceCode)) {
            if(getDetailDeviceLocalStorage(deviceCode).played) {
              /* PLAY VIDEO LIVE STREAM */
              track.play(videoElementRef.current)

              setIsloadingVideo(false)
              setIsVideoPlay(true)
            }
          }
        })
      }
      if (mediaType === 'audio') {
        agoraClientRTCRef.current.subscribe(user, 'audio').then((track) => {
          audioTrackRef.current = track

          if(hasDeviceOnStorage(deviceCode)) {
            if(getDetailDeviceLocalStorage(deviceCode).played) {
              /* PLAY AUDIO LIVE STREAM */
              track.play()
            }
          }

          setIsHaveAudio(true)
        })
      }

      !hasDeviceOnStorage(deviceCode) && setIsloadingVideo(false)
    })

    /* CHECK IF REMOTE USER MUTED AUDIO */
    agoraClientRTCRef.current.on('user-unpublished', async (user, mediaType) => {
      if (mediaType === 'audio') {
        setIsHaveAudio(false)
        audioTrackRef.current = null
      }

      if(mediaType === 'video') {
        /* CLEAR DEVICE ON STORAGE */
        cleanUpDeviceLocalStorage(deviceCode)
      }
    })
  }

  /* HANDLE BUTTON PLAY VIDEO */
  const handleButtonPlayVideo = () => {
    /* ADD DEVICE TO LOCALSTORAGE */
    addDeviceToLocalStorage(deviceCode, true)

    /* PLAY VIDEO LIVE STREAM */
    videoTrackRef.current.play(videoElementRef.current)
    /* PLAY AUDIO LIVE STREAM */
    audioTrackRef.current && audioTrackRef.current.play()

    setIsloadingVideo(false)
    setIsVideoPlay(true)
  }

  /* HANDLE BUTTON PAUSE VIDEO */
  const handleButtonPauseVideo = () => {
    /* UPDATE DEVICE LOCALSTORAGE */
    updateDeviceToLocalStorage(deviceCode, false)

    /* PLAY VIDEO LIVE STREAM */
    videoTrackRef.current.stop()
    /* PLAY AUDIO LIVE STREAM */
    audioTrackRef.current && audioTrackRef.current.stop()

    setIsOnMute(false)
    setIsloadingVideo(false)
    setIsVideoPlay(false)
  }

  /* HANDLE BUTTON MUTE UNMUTE */
  const handleButtonMuteUnMute = () => {
    setIsOnMute(!isOnMute)
  }

  /* HANDLE BUTTON FULLSCREEN */
  const handleButtonFullscreen = () => {
    window.open(`/live/live/${deviceCode}`, '_blank')
  }

  /* SIDE EFFECT INIT AGORA */
  useEffect(() => {
    initAgoraLiveStream()

    return async () => {
      await agoraClientRTCRef.current.leave()
      await agoraClientRTCRef.current.removeAllListeners()
      //window.location.reload()
    }
  }, [])

  /* SIDE EFFECT MUTE/UNMUTE */
  useEffect(() => {
    if(audioTrackRef.current) {
      if(isOnMute) audioTrackRef.current.stop()
      if(!isOnMute) audioTrackRef.current.play()
    }
  }, [isOnMute])

  return (
    <Box className={classes.mainContainer}>
      {/* HEADER */}
      <Box className={classes.header}>
        {/* TITLE */}
        <Box className={classes.titleContainer}>
          <FiberManualRecordIcon className={classes.dotTitle}/>
          <Typography className={classes.title}>
            {typeStream === 'LIVESTREAMING' ? 'SIARAN LANGSUNG' : 'PLAYBACK'}
          </Typography>
        </Box>
        {/* ICON */}
        {typeStream === 'LIVESTREAMING' ? 
          <VideocamIcon className={classes.videoCamIcon}/> : 
          <OndemandVideoIcon className={classes.videoCamIcon}/>} 
      </Box>

      {/* CONTENT */}
      <Box className={classes.content}>
        <Box className={classes.contentVideo}>
          <div
            style={{ width: '100%', height: '100%' }}
            className={`classes-${idx}`} ref={(el) => (videoElementRef.current = el)}>
          </div>

          {/* BUTTON PLAY */}
          <LoadingButton
            loading={isLoadingVideo}
            className={classes.buttonPlay}
            disableRipple
          >
            {(!isLoadingVideo && !isVideoPlay) && (
              <PlayCircleIcon
                onClick={handleButtonPlayVideo}
                className={classes.butonPlayIcon}
              />
            )}
            {(!isLoadingVideo && isVideoPlay) && (
              <PauseIcon
                onClick={handleButtonPauseVideo}
                className={`${classes.butonPlayIcon} btn-action-pause`}
              />
            )}
          </LoadingButton>
        </Box>

        {/* LIST */}
        <Box className={classes.detailListWrap}>
          <List className={classes.listContainer}>
            <ListItem
              className={classes.listItem}
              secondaryAction={
                <>
                  {isVideoPlay && (<>
                    {isHaveAudio ? (<IconButton
                      edge='end'
                      disableRipple
                      className={classes.buttonDetailVideo}
                      onClick={handleButtonMuteUnMute}
                    >
                      {!isOnMute && <VolumeUpIcon className={classes.detailIcon} />}
                      {isOnMute && <VolumeOffIcon className={classes.detailIcon} />}
                    </IconButton>)
                      : (
                        <IconButton
                          className={classes.buttonDetailVideo}
                        >
                          <VolumeOffIcon className={classes.detailNotAudioIcon} />
                        </IconButton>
                      )}

                    <IconButton
                      edge='end'
                      disableRipple
                      className={classes.buttonDetailVideo}
                      onClick={handleButtonFullscreen}
                    >
                      <FullscreenIcon className={classes.detailIcon} />
                    </IconButton>
                  </>)}
                </>
              }
            >
              <ListItemAvatar className={classes.listItemAvatar}>
                <Avatar 
                  className={classes.avatarSize}
                  sx={{
                    backgroundColor: getBackgroundAvatar(
                      label
                    ),
                  }}>
                  {getStringAvatar(label)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText 
                className={classes.listItemText}
                primary={label}
                //secondary={data.duration ?? null}
              />
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(LiveStreamingItem)