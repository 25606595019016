// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
    overflowY: 'auto'
  },
  itemDetail: {
    flexDirection: 'row',
    flexWrap: 'nowrap'
  },
  labelTitle: {
    fontSize: 12,
    marginBottom: 6,
    color: colors.doveGray
  },
  labelIcon: {
    color: colors.raven,
    fontSize: 18
  },
  headerBoxDescription: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    border: `1px solid ${theme.palette.divider}`,
    borderBottom: 'none',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: alpha(theme.palette.common.black, 0.08),
    padding: '12px 8px',
    alignItems: 'center',
  },
  contentBoxDescription: {
    padding: '8px 16px',
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: alpha(colors.gallery, 0.48),
    borderRadius: 2,
    '& p': {
      margin: '4px 0 !important',
      fontSize: '12px !important',
    }
  },
  titleBoxDescription: {
    fontWeight: 700,
    marginBottom: 4,
    fontSize: 12,
  },
  chipCustom: {
    height: 24,
    borderRadius: 4,
    '& .MuiSvgIcon-root': {
      fontSize: 16,
    },
    '& .MuiChip-label': {
      fontSize: 12,
    }
  }
}))

export default useStyles