import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

// COMPONENTS
import LoadingBox from 'components/LoadingBox/LoadingBox'
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import DialogAddOrEditSettingsDisturbances from './DialogAddOrEditSettingsDisturbances/DialogAddOrEditSettingsDisturbances'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import PageHeader from 'components/PageHeader/PageHeader'

// CONSTANTS
import { basePaths } from 'constants/paths'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

// SERVICES
import { getSSDGroups } from 'services/MasterDataService'
import { deleteSSDGroup } from 'services/MasterDataService'

// ICONS
import IconDelete from '@mui/icons-material/Delete'
import IconEdit from '@mui/icons-material/Edit'

// STORES
import { showAlert } from 'store/MainReducer'

// STYLES
import useStyles from './settingsDisturbancesUseStyles'

const SettingsDisturbances = () => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const history = useHistory()
  const location = useLocation()

  const initialFilters = {
    groupName: '',
  }

  const pageRef = useRef()

  const groupByList = [
    {
      title: 'Disturbance Group',
      value: 'groupName',
    },
  ]

  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('')
  const [size, setPageSize] = useState(100)
  const [
    dispatchSettingsDisturbancesTableData,
    setSettingsDisturbancesTableData,
  ] = useState([])
  const [settingsDisturbancesData, setSettingsDisturbancesData] = useState([])
  const [filters, setFilters] = useState(initialFilters)
  const [selectedGroupBy, setSelectedGroupBy] = useState(groupByList[0])
  const [isFilterOn, setIsFilterOn] = useState(false)
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [ dialogAddOrEditSettingsDisturbances, setDialogAddOrEditSettingsDisturbances ] = useState(null)
  const [ dialogDeleteSettingsDisturbances, setDialogDeleteSettingsDisturbances ] = useState({})

  const [dialogType, setDialogType] = useState('')

  const [selectionModel, setSelectionModel] = useState([])
  const [groupNo, setGroupNo] = useState()

  const reloadData = () => {
    const params = {
      page,
      size,
    }

    if (order) params.sort = `${orderBy},${order}`
    if (search) params.query = search

    setIsLoading(true)

    getSSDGroups(params).then((response) => {
      setSettingsDisturbancesData(response.rows)
      if (response.rows?.length > 0) {
        let newRows = []
        response.rows?.map((row, i) => {
          if (row.subGroups?.length > 0) {
            row.subGroups?.map((subGroupData, j) => {
              newRows.push({
                id: `${row.groupNo}${subGroupData.subGroupNo}`,
                groupNo: row.groupNo,
                groupName: row.groupName,
                subGroupNo: subGroupData.subGroupNo,
                subGroup: subGroupData?.subGroupName,
              })
            })
          } 
          else {
            newRows.push({
              id: `${row.groupNo}${i}`,
              groupNo: row.groupNo,
              groupName: row.groupName,
              subGroup: '',
              subGroupNo: i,
            })
          }
        })
        setSettingsDisturbancesTableData(newRows)
      } else {
        setSettingsDisturbancesTableData(response.rows ?? [])
      }
      setTotal(response.totalElements)
      setIsLoading(false)
    })
  }

  const handleDeleteButtonClick = async () => {
    setIsLoading(true)
    const result = await deleteSSDGroup(
      dialogDeleteSettingsDisturbances.row.groupNo
        ? dialogDeleteSettingsDisturbances.row.groupNo
        : groupNo
    )

    if (result.status !== 'error') {
      setIsLoading(false)
      dispatch(showAlert({ message: 'Sukses Menghapus Gangguan' }))
      setDialogDeleteSettingsDisturbances({})
      reloadData()
    }
  }

  useEffect(() => {
    reloadData()
  }, [page, size, order, orderBy])

  const handleDeleteIconButtonClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()
    setDialogDeleteSettingsDisturbances(inputParams)
  }

  const handleEditIconButtonClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()
    history.push(
      `${basePaths.settingsDisturbances}?mode=edit&id=${inputParams.id}`, 
      {
        id: inputParams.id,
        row: inputParams.row,
      }
    )
  }

  const initialColumns = [
    {
      field: 'groupName',
      headerName: 'Social Security Disturbance Group',
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'actions',
      headerName: '',
      width: 120,
      hide: false,
      sortable: false,
      disableColumnMenu: true,
      areFilterAndSortShown: false,
      renderCell: (params) => (
        <Box className={classes.columnActions}>
          {/* EDIT ICON */}
          <CustomTooltipBlack title='Ubah' placement='bottom'>
            <IconButton
              onClick={(event) => handleEditIconButtonClick(event, params)}
            >
              <IconEdit />
            </IconButton>
          </CustomTooltipBlack>

          {/* DELETE ICON */}
          <CustomTooltipBlack title='Hapus' placement='bottom'>
            <IconButton
              onClick={(event) => handleDeleteIconButtonClick(event, params)}
            >
              <IconDelete />
            </IconButton>
          </CustomTooltipBlack>
        </Box>
      ),
    },
  ]

  const [selectedColumnList, setSelectedColumnList] = useState(initialColumns)

  const groupingColDef = {
    headerName: 'Social Security Disturbance Group',
    flex: 1,
    renderCell: (params) => {
      if (!params.rowNode.isAutoGenerated) {
        return params.row.subGroup?.toLowerCase()
      }

      return (
        <Typography 
          variant='subtitle1' 
          className={classes.groupingRow}
          noWrap
        >
          {params.rowNode.groupingKey?.toLowerCase()}
        </Typography>
      )
    },
  }

  const handleColumnsMenuItemClick = (inputItem, inputIndex) => {
    let tempSelectedColumnList = [...selectedColumnList]
    if (selectedGroupBy.value && inputItem.field === 'groupName') {
    } else {
      tempSelectedColumnList[inputIndex].hide =
        !tempSelectedColumnList[inputIndex].hide
    }
    setSelectedColumnList(tempSelectedColumnList)
  }

  useEffect(() => {
    if (!isFilterOn) setFilters(initialFilters)
  }, [isFilterOn])

  useEffect(() => {
    let tempSelectedColumnList = [...selectedColumnList]
    tempSelectedColumnList.forEach((item) => {
      if (item.field === selectedGroupBy.value || item.field === 'groupName')
        item.hide = true
    })
    setSelectedColumnList(tempSelectedColumnList)
  }, [selectedGroupBy])

  useEffect(() => {
    if (dialogDeleteSettingsDisturbances?.row?.groupNo) {
      let groupdata = settingsDisturbancesData?.filter(
        (datas) =>
          datas?.groupNo === dialogDeleteSettingsDisturbances?.row?.groupNo
      )
      setGroupNo(groupdata[0]?.groupNo ?? '')
    } else {
      let groupdata = settingsDisturbancesData?.filter(
        (datas) =>
          datas?.groupName ===
          dialogDeleteSettingsDisturbances?.rowNode?.groupingKey
      )
      setGroupNo(groupdata[0]?.groupNo ?? '')
    }
  }, [dialogDeleteSettingsDisturbances])

  useEffect(() => {
    if (location.pathname === basePaths.settingsDisturbances && location.search === '?mode=add') {
      setDialogType('add')
      setDialogAddOrEditSettingsDisturbances(true)
    }
    else if (location.pathname === basePaths.settingsDisturbances && location.search.includes('?mode=edit')) {
      setDialogType('edit')
      setDialogAddOrEditSettingsDisturbances(location?.state)
    }
  }, [location])

  return (
    <Box className={classes.pageRoot} ref={pageRef}>
      {/* HEADER */}
      <PageHeader
        isAddButtonAvailable={true}
        title='Gangguan'
        search={search}
        setSearch={setSearch}
        onAddButtonIsClicked={() => history.push(`${basePaths.settingsDisturbances}?mode=add`)}
      />
      {/* CONTENT */}
      <LoadingBox isLoading={isLoading}>
        <DataGridFilters
          columns={initialColumns}
          selectedColumnList={selectedColumnList}
          handleColumnsMenuItemClick={handleColumnsMenuItemClick}
          isFilterOn={isFilterOn}
          setIsFilterOn={setIsFilterOn}
          groupByList={groupByList}
          selectedGroupBy={selectedGroupBy}
          setSelectedGroupBy={setSelectedGroupBy}
        />

        <DataGridTable
          initialColumns={initialColumns}
          rows={dispatchSettingsDisturbancesTableData}
          total={total}
          page={page}
          pageSize={size}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setPage={setPage}
          setPageSize={setPageSize}
          setFilters={setFilters}
          isFilterOn={isFilterOn}
          selectedColumnList={selectedColumnList}
          setSelectedColumnList={setSelectedColumnList}
          selectedGroupBy={selectedGroupBy}
          getTreeDataPath={(row) => [row.groupName, row.id]}
          getRowId={(row) => row.id}
          groupingColDef={groupingColDef}
          selectionModel={selectionModel}
          setSelectionModel={setSelectionModel}
          isCheckboxPinned={true}
        />
      </LoadingBox>

      {/* DIALOG ADD / EDIT USERS */}
      <DialogAddOrEditSettingsDisturbances
        dialogType={dialogType}
        dialogAddOrEditSettingsDisturbances={
          dialogAddOrEditSettingsDisturbances
        }
        setDialogAddOrEditSettingsDisturbances={
          setDialogAddOrEditSettingsDisturbances
        }
        reloadData={reloadData}
        disturbanceData={settingsDisturbancesData}
        pageRef={pageRef}
      />

      {/* DIALOG DELETE DISTURBANCE */}
      <DialogConfirmation
        title='Hapus Gangguan'
        caption='Apa Anda yakin ingin menghapus gangguan ini?'
        dialogConfirmationObject={dialogDeleteSettingsDisturbances}
        setDialogConfirmationObject={setDialogDeleteSettingsDisturbances}
        cancelButtonText='Batal'
        continueButtonText='Hapus'
        onContinueButtonClick={handleDeleteButtonClick}
        onCancelButtonClick={() => setDialogDeleteSettingsDisturbances({})}
      />
    </Box>
  )
}

export default SettingsDisturbances
