// MUIS
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'

// MUI ICONS
import IconSearch from '@mui/icons-material/Search'

// STYLES
import useStyles from './dialogSelectDevicesUseStyles'

const DialogSelectDevices = (props) => {
  const {
    className, children, customHeader, isHaveButtonAction, isHaveSearch,
    onButtonSaveClick, onButtonCancelClick, onInputSearchChange
  } = props
  const classes = useStyles()

  return (
    <Stack className={`${classes.root} ${className}`}>
      {isHaveSearch && (<Stack className={classes.searchWrapper}>
        <FormControl variant='outlined'>
          <TextField
            InputProps={{
              startAdornment: <InputAdornment position='start'>
                <IconSearch />
              </InputAdornment>,
              placeholder: 'Pencarian'
            }}
            className={classes.searchTextfield}
            onChange={onInputSearchChange}
          />
        </FormControl>
      </Stack>)}

      {customHeader}

      <Stack className={classes.content}>
        {children}
      </Stack>

      {isHaveButtonAction && (<Stack className={classes.footer}>
        <Button onClick={onButtonCancelClick} variant='text' className={`${classes.buttonDialog} btnCancel`}>BATAL</Button>
        <Button onClick={onButtonSaveClick} variant='text' className={classes.buttonDialog}>PILIH</Button>
      </Stack>)}
    </Stack>
  )
}

export default DialogSelectDevices