import { http } from '../utilities/http'

export const viewMediaCount = async (params) => {
  try {
    return  await http.post('/blockchain/view', params)
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

export async function downloadMediaWithQR(mediaData) {
  http.get(`/blockchain/download-file-withQr/${mediaData.mediaNo}`, {
    responseType: 'blob',
    timeout: 30000
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', mediaData.fileName)
      document.body.appendChild(link)
      link.click()
    })
}