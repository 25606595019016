// UTILITIES
import { http } from 'utilities/http'

export const getAgentHelpList = async () => {
  try {
    const { data } = await http.get('agent-help/list')
    return data
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

export const putAgentHelpJoin = async (params) => {
  try {
    return  await http.put('agent-help/join', params)
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

export const putAgentHelpLeave = async (id,params) => {
  try {
    return  await http.put(`agent-help/${id}/leave`, params)
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}
