import { useContext, useState } from 'react'

// COMPONENTS
import CustomDialogDelete from 'components/Customs/CustomDialogDelete'
import ConversationItem from '../ConversationItem/ConversationItem'

// CONSTANTS
import { colors } from 'constants/colors'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { PageChatContext } from 'contexts/PageChatContext'

// MUIS
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import List from '@mui/material/List'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import OutlinedInput from '@mui/material/OutlinedInput'

// MUI ICONS
import IconClose from '@mui/icons-material/Close'
import IconSearch from '@mui/icons-material/Search'

// STYLES
import useStyles from './dialogNewChatUseStyles'

// UTILS
import { agoraSendMessage } from '../agoraUtils'
import { setCurrentConversation } from '../chatUtils'

const DialogNewChat = (props) => {
  const { isDialogNewChatShown, setIsDialogNewChatShown } = props
  const parseLocalStorage = JSON.parse(localStorage.getItem('user'))
  const { friendList, conn, conversationList } = useContext(PageChatContext)
  const { setSnackbarObject } = useContext(AllPagesContext)
  const classes = useStyles()

  // STATES
  const [search, setSearch] = useState('')

  const handleFriendItemClick = async (item) => {
    const isAlreadyJoined = conversationList?.find(itemConversation => itemConversation?.user?.nickname === item?.nickname)

    if (isAlreadyJoined) {
      setSnackbarObject({
        open: true,
        severity: 'warning',
        title: '',
        message: 'Anda telah tergabung didalam percakapan',
      })

      return
    }

    await agoraSendMessage(conn, {
      msg: `${parseLocalStorage?.userName} memulai percakapan`,
      to: item.username,
      chatType: 'singleChat',
      id: (Math.random() * 1000).toFixed(0).toString(),
      type: 'txt',
      ext: {
        reply: {}
      }
    })
    setCurrentConversation({
      meta: {},
      user: item,
      channel_id: `_${item?.username}@`,
      unread_num: 0,
      chatType: 'singleChat'
    })
    setIsDialogNewChatShown(false)
  }

  return (
    <CustomDialogDelete
      open={isDialogNewChatShown}
      className={classes.dialog}
      sx={{
        '& .MuiPaper-root': {
          height: '500px !important',
        },
      }}
      onClose={() => setIsDialogNewChatShown(false)}
    > 
      {/* HEADER */}
      <Stack className={classes.dialogHeader}>
        <Typography className={classes.dialogHeaderTitle}>
          Cari Pengguna
        </Typography>

        <IconButton onClick={() => setIsDialogNewChatShown(false)}>
          <IconClose />
        </IconButton>
      </Stack>

      {/* SEARCH */}
      <Stack padding='16px'>
        {/* SEARCH INPUT */}
        <FormControl variant='outlined'>
          <OutlinedInput
            type='text'
            placeholder='Pencarian'
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            startAdornment={
              <InputAdornment position='start'>
                <IconSearch />
              </InputAdornment>
            }
            sx={{
              height: 40,
              paddingLeft: '8px',
              backgroundColor: colors.whiteLilac
            }}
          />
        </FormControl>
      </Stack>

      <Stack overflow='auto' px='16px'>
        <List>
          {friendList
            ?.filter(item => item?.nickname?.toLowerCase()?.includes(search))
            ?.map((item, index) => (
              <ConversationItem
                key={index}
                name={item.nickname}
                title={item.nickname}
                description={item.role}
                avatarSize='large'
                onItemClick={() => handleFriendItemClick(item)}
              />
            ))}
        </List>
      </Stack>
    </CustomDialogDelete>
  )
}

export default DialogNewChat