import PropTypes from 'prop-types'

// CONSTANTS
import { colors } from 'constants/colors'
import mapList from 'constants/mapList'

// MUIS
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import Radio from '@mui/material/Radio'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconClose from '@mui/icons-material/Close'
import IconMap from '@mui/icons-material/Map'

// STYLES
import useStyles from './panelChangeMapUseStyles'

const themeList = [ 'dark', 'light' ]

const PanelChangeMap = (props) => {
  const { 
    handleCloseIconClick, 
    selectedMapObject, setSelectedMapObject,
    theme,
  } = props

  const classes = useStyles()

  return (
    <Stack 
      className={classes.mapMenu}
      sx={theme === themeList[0] 
        ? { backgroundColor: `${colors.bunker} !important`}
        : {}
      }
    >
      {/* MENU HEADER */}
      <Box className={classes.mapMenuHeader}>
        {/* ICON */}
        <IconMap className={classes.icon}/>

        {/* TITLE */}
        <Typography
          variant='h6'
          className={classes.mapMenuHeaderTitle}
        >
          Map
        </Typography>

        {/* CLOSE ICON */}
        <IconButton onClick={handleCloseIconClick}>
          <IconClose className={classes.icon}/>
        </IconButton>
      </Box>

      <Divider/>

      {/* MAP OPTION LIST */}
      <List className={classes.mapOptionList}>
        {mapList.mapOptionList.map((item, index) => (
          <ListItem
            key={index}
            className={classes.mapOptionItem}
          >
            <ListItemButton 
              className={classes.mapOptionItemButton}
              onClick={() => setSelectedMapObject(item)}
            >
              {/* RADIO */}
              <Radio
                checked={selectedMapObject.name === item.name}
                onChange={() => setSelectedMapObject(item)}
                value={item.name}
                name='radio-buttons'
              />

              {/* TEXT */}
              <Typography variant='body2'>
                {item.name}
              </Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  )
}

PanelChangeMap.defaultProps = {
  theme: themeList[1],
}

PanelChangeMap.propTypes = {
  theme: PropTypes.oneOf(themeList),
  handleCloseIconClick: PropTypes.func.isRequired, 
  selectedMapObject: PropTypes.object.isRequired, 
  setSelectedMapObject: PropTypes.func.isRequired,
}

export default PanelChangeMap