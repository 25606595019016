// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      borderRadius: 0,
      width: 400,
    },
  },
  dialogTitle: {
    fontWeight: 600, 
    padding: 16,
    fontSize: 16,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  dialogContent: {
    padding: '16px !important',
  },
  dialogActions: {
    gap: 8,
    boxShadow: '0px -2px 4px 0px #00000014',
  },
  actionButton: {
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  actionButtonGrey: {
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))

export default useStyles