// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  panelContainer: {
    backgroundColor: 'white',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.shadows[4],
    marginBottom: 10,
    position: 'relative',
  },
  panelHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '8px 16px',
  },
  panelNotifTotal: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
  },
  iconToggle: {
    width: 36,
    height: 36,
    marginLeft: 8,
  },
  panelTitle: {
    fontWeight: 600,
  },
  panelCaption:{
    opacity: 0.54,
    fontSize: 14
  },
  panelCaption2:{
    fontSize: 14,
    marginTop : 8
  },
  buttonSection:{
    padding: '12px 16px'
  },
  objectList: {
    flex: 1,
    overflow: 'auto',
  },
  objectItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  objectItemButton: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '12px 16px',
    borderRadius: 0,
    opacity: 1,
    borderLeftWidth: 4,
    borderLeftStyle: 'solid'
  },
  objectItemIcon: {
    '&.MuiListItemIcon-root': {
      minWidth: 'unset',
      marginRight: 20,
      marginLeft: 0,
    },
  },
  objectItemText: {
    fontSize: 12,
    fontWeight: 400,
  },
  boxShadows: {
    boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.1)'
  },
  iconSettings: {
    color: theme.palette.text.secondary
  },
  CustomDialogActionButton: {
    textTransform: 'capitalize',
    fontWeight: 400
  }
}))

export default useStyles