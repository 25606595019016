import { useState, useEffect, useContext } from 'react'

// COMPONENTS
import MenuAutocomplete from '../MenuAutocomplete/MenuAutocomplete'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'

// MUI ICONS
import IconGroups from '@mui/icons-material/Groups'

// PATH ICONS
import IconPoliceStation from 'assets/images/pathIcons/IconPoliceStation'

// SERVICES
import { 
  searchGroups, 
  updateStatus,
} from 'services/DeviceService'
import { getRoles } from 'services/UserService'

// STYLES
import useStyles from './dialogChangeGroupAndDepartmentUseStyles'

const DialogChangeGroupAndDepartment = (props) => {
  const {
    isDialogOpen, setIsDialogOpen,
    deviceDetail,
    reloadData,
  } = props

  const classes = useStyles()

  const { setSnackbarObject } = useContext(AllPagesContext)

  const [ groupList, setGroupList ] = useState([])
  const [ selectedGroup, setSelectedGroup ] = useState(null)
  const [ departmenList, setDepartmenList ] = useState([])
  const [ selectedDepartment, setSelectedDepartment ] = useState(null)

  const handleDialogClose = () => {
    const initialSelectedGroup = groupList.find(item => item.groupNo === deviceDetail.groupNo)
    const initialSelectedDepartment = departmenList.find(item => item.id === deviceDetail.roleNo)

    setSelectedGroup(deviceDetail?.groupNo ? initialSelectedGroup : null)
    setSelectedDepartment(deviceDetail?.roleNo ? initialSelectedDepartment : null)
    setIsDialogOpen(false)
  }

  const handleConfirmButtonClick = async () => {
    if (Boolean(selectedGroup) && Boolean(selectedDepartment)) {
      const resultUpdateDevice = await updateStatus(deviceDetail?.deviceNo, {
        expectedStatus: 'APPROVED',
        selectedGroup: selectedGroup?.groupNo,
        roleNo: selectedDepartment?.id,
      })

      if (resultUpdateDevice?.status !== 'error') {
        reloadData()
        
        setSnackbarObject({
          open: true,
          severity: 'success',
          title: '',
          message: 'Sukses menyetujui perangkat',
        })

        handleDialogClose()
      }
      else setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Gagal menyetujui perangkat',
      })
    }
    else setSnackbarObject({
      open: true,
      severity: 'error',
      title: '',
      message: 'Mohon untuk mengisi semua kolom',
    })
  }

  const getGroupList = async () => {
    const resultGroupList = await searchGroups({
      page: 0,
      size: 10000,
    }, {})

    if (resultGroupList.rows.length > 0) {
      const newGroupList = resultGroupList.rows.map(item => {
        return {
          ...item,
          label: item.groupName,
          isShown: true,
        }
      })

      setGroupList(newGroupList)
    }
  }

  const getDepartmenList = async () => {
    const resultDepartmenList = await getRoles({
      page: 0,
      size: 10000,
    })

    if (resultDepartmenList.length > 0) {
      const newDepartmenList = resultDepartmenList.map(item => {
        return {
          ...item,
          label: item.roleName,
          isShown: true,
        }
      })

      setDepartmenList(newDepartmenList)
    }
  }

  useEffect(() => {
    getGroupList()
    getDepartmenList()
  }, [])

  useEffect(() => {
    if (groupList.length > 0 && (typeof deviceDetail?.groupNo !== 'undefined' && deviceDetail?.groupNo !== null)) {
      const foundGroup = groupList.find(item => item.groupNo === deviceDetail.groupNo)
      setSelectedGroup(foundGroup)
    }
    else setSelectedGroup(null)

    if (departmenList.length > 0 && (typeof deviceDetail?.roleNo !== 'undefined' && deviceDetail?.roleNo !== null)) {
      const foundDepartment = departmenList.find(item => item.id === deviceDetail.roleNo)
      setSelectedDepartment(foundDepartment)
    }
    else setSelectedDepartment(null)
  }, [deviceDetail?.groupNo, deviceDetail?.roleNo, groupList, departmenList])

  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      className={classes.root}
    >
      {/* TITLE */}
      <DialogTitle className={classes.dialogTitle}>
        Pilih Kelompok dan Departemen
      </DialogTitle>

      {/* CONTENT */}
      <DialogContent className={classes.dialogContent}>
        {/* GROUP INPUT */}
        <Stack
          direction='row'
          spacing='8px'
          alignItems='center'
        >
          {/* ICON */}
          <IconGroups color='action'/>

          {/* GROUP FORM CONTROL AND MENU */}
          <MenuAutocomplete 
            label='Kelompok'
            isRequired={true}
            initialOptionList={groupList}
            selectedValue={selectedGroup} 
            setSelectedValue={setSelectedGroup}
          />
        </Stack>

        {/* DEPARTMENT INPUT */}
        <Stack
          direction='row'
          spacing='8px'
          alignItems='center'
          marginTop='16px'
        >
          {/* ICON */}
          <IconPoliceStation color='action'/>

          {/* DEPARTMENT FORM CONTROL AND MENU */}
          <MenuAutocomplete 
            label='Departemen'
            isRequired={true}
            initialOptionList={departmenList}
            selectedValue={selectedDepartment} 
            setSelectedValue={setSelectedDepartment}
          />
        </Stack>
      </DialogContent>

      {/* ACTIONS */}
      <DialogActions className={classes.dialogActions}>
        {/* CANCEL BUTTON */}
        <Button 
          onClick={handleDialogClose}
          className={`${classes.actionButton} ${classes.actionButtonGrey}`}
        >
          Batal
        </Button>

        {/* CONFIRM BUTTON */}
        <Button 
          onClick={handleConfirmButtonClick}
          className={classes.actionButton}
        >
          Terima
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogChangeGroupAndDepartment