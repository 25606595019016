import React, { createContext, useRef, useState } from 'react'

// CONSTANTS
import valuesPageDashboard from 'constants/valuesPageAnalytics'

export const PageAnalyticsContext = createContext()

const PageAnalyticsContextProvider = (props) => {
  // DATA
  const [ viewType, setViewType ] = useState('internal')
  const [ viewContent, setViewContent ] = useState('chart')
  const [ dataDevicesList, setDataDevicesList ] = useState([])
  const [ dataTagsList, setDataTagsList ] = useState([])
  const [ paramsFilter, setParamsFilter ] = useState(valuesPageDashboard.defaultParamsFilter)
  const [ selectedEvidenceItem, setSelectedEvidenceItem ] = useState(null)
  const [ dataEvidences, setDataEvidences ] = useState([])
  const [ isFetchingOnLoading, setIsFetchingOnLoading ] = useState(true)

  // MAP
  const [ map, setMap ] = useState({})
  const [ mapBounds, setMapBounds ] = useState([])
  const [ mapZoom, setMapZoom ] = useState(valuesPageDashboard.defaultMapZoom)

  // PANEL
  const [ isPanelFilterShown, setIsPanelFilterShown ] = useState(false)
  const [ isPanelRightShown, setIsPanelRightShown ] = useState(true)

  // REF
  const pageRef = useRef()

  return (
    <PageAnalyticsContext.Provider
      value={{
        // DATA
        viewType, setViewType,
        viewContent, setViewContent,
        dataDevicesList, setDataDevicesList,
        dataTagsList, setDataTagsList,
        paramsFilter, setParamsFilter,
        selectedEvidenceItem, setSelectedEvidenceItem,
        dataEvidences, setDataEvidences,
        isFetchingOnLoading, setIsFetchingOnLoading,
        // MAP
        map, setMap,
        mapBounds, setMapBounds,
        mapZoom, setMapZoom,
        // PANEL
        isPanelFilterShown, setIsPanelFilterShown,
        isPanelRightShown, setIsPanelRightShown,
        // REF
        pageRef
      }}
    >
      {props.children}
    </PageAnalyticsContext.Provider>
  )
}

export default PageAnalyticsContextProvider