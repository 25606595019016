import { useState, useEffect } from 'react'

// CUSTOM COMPONENTS
import CustomDialogActionButton from 'components/Customs/CustomDialogActionButton'
import CustomDialogActions from 'components/Customs/CustomDialogActions'
import CustomDialogContent from 'components/Customs/CustomDialogContent'
import CustomDialogTitle from 'components/Customs/CustomDialogTitle'
import CustomInput from 'components/Customs/CustomInput'

// MUIS
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconClear from '@mui/icons-material/Clear'
import IconSearch from '@mui/icons-material/Search'

// SERVICES
import { 
  postAssignChannelsToUser,
  postSearchChannels, 
} from 'services/PushToTalkServices'

// STYLES
import useStyles from './dialogAssignChannelsUseStyles'

const DialogAssignChannels = (props) => {
  const { 
    dialogAssignChannels, setDialogAssignChannels, 
    setMusReloadTableDevices,
  } = props

  const classes = useStyles()

  const [ search, setSearch ] = useState('')
  const [ channelList, setChannelList ] = useState([])

  const handleListItemClick = (item) => {
    const newChannelList = [...channelList].map((newChannelItem) => {
      if (item.id === newChannelItem.id) newChannelItem.isSelected = !newChannelItem.isSelected
      return { ...newChannelItem }
    })
    setChannelList(newChannelList)
  }

  const handleSearchChange = () => {
    const newChannelList = [...channelList].map((item) => {
      if (item.label.toLowerCase().includes(search.toLowerCase())) item.isShown = true
      else item.isShown = false
      return { ...item }
    })
    setChannelList(newChannelList)
  }

  const getChannelList = async () => {
    const resultChannelList = await postSearchChannels({ page: 0, size: 1000000 }, { groupName: search })
    
    if (resultChannelList.status === 200) {
      const newChannelList = resultChannelList.data.rows.map((item) => {
        return {
          ...item,
          id: item.groupId,
          label: `Kanal ${item.groupName}`,
          isShown: true,
          isSelected: false, 
        }
      })

      setChannelList(newChannelList)
    }
  }

  const handleSaveButtonClick = async () => {
    const resultAssignChannels = await postAssignChannelsToUser({
      userId: dialogAssignChannels.userId,
      groupIds: channelList.filter(item => item.isSelected).map(item => item.id),
    })
    
    if (resultAssignChannels.status === 200) {
      setMusReloadTableDevices(true)
      setDialogAssignChannels(null)
    }
  }

  useEffect(() => {
    getChannelList()
  }, [])

  useEffect(() => {
    handleSearchChange()
  }, [search])

  return (
    <Dialog
      open={Boolean(dialogAssignChannels)}
      onClose={() => setDialogAssignChannels(null)}
      className={classes.dialog}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle className={classes.dialogTitle}>
        {/* TITLE */}
        <Typography className={classes.titleText}>
          Tambah Kanal
        </Typography>
      </CustomDialogTitle>

      {/* DIALOG CONTENT */}
      <CustomDialogContent className={classes.dialogContent}>
        {/* SEARCH BOX */}
        <Box className={classes.searchBox}>
          {/* INPUT */}
          <CustomInput
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            className={classes.searchInput}
            placeholder='Pencarian Cepat'
            disableUnderline
          />

          {/* ICON */}
          <Box className={classes.searchIcon}>
            {search === '' ? <IconSearch/> : <IconClear onClick={() => setSearch('')}/>}
          </Box>
        </Box>

        {/* CHANNEL LIST */}
        <List 
          disablePadding
          className={classes.list}
        >
          {channelList.map((item, index) => (
            item.isShown &&
            <ListItemButton 
              key={index}
              className={classes.listItem}
              onClick={() => handleListItemClick(item)}
            >
              {/* RADIO */}
              <ListItemIcon>
                <Checkbox checked={item.isSelected}/>
              </ListItemIcon>

              {/* TEXT */}
              <ListItemText primary={item.label}/>
            </ListItemButton>
          ))}
        </List>
      </CustomDialogContent>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        {/* CANCEL BUTTON */}
        <CustomDialogActionButton 
          className={classes.cancelButton}
          color='default'
          onClick={() => setDialogAssignChannels(null)}
        >
          Batal
        </CustomDialogActionButton>

        {/* OK BUTTON */}
        <CustomDialogActionButton onClick={handleSaveButtonClick}>
          Simpan
        </CustomDialogActionButton>
      </CustomDialogActions>
    </Dialog>
  )
}

export default DialogAssignChannels