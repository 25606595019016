// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconPoliceBadge = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M22 4L20 2C18.85 2.64 17.4 3 16 3C14.6 3 13.14 2.63 12 2C10.86 2.63 9.4 3 8 3C6.6 3 5.15 2.64 4 2L2 4C2 4 4 6 4 8C4 10 2 14 2 16C2 20 12 22 12 22C12 22 22 20 22 16C22 14 20 10 20 8C20 6 22 4 22 4ZM15.05 16.45L11.97 14.59L8.9 16.45L9.72 12.95L7 10.61L10.58 10.3L11.97 7L13.37 10.29L16.95 10.6L14.23 12.94L15.05 16.45Z' />
    </SvgIcon>
  )
}

export default IconPoliceBadge