// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  panelContent: {
    overflowY: 'auto',
    height: '100%',
    position: 'relative'
  },
  objectDataGrid: {
    '& .MuiDataGrid-cell[data-colindex="1"]': {
      maxWidth: '420px !important',
      minWidth: '364px !important'
    },
  },
  historyFab: {
    position: 'absolute',
    right: 16,
    bottom: 16,
  },
}))

export default useStyles