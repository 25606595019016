// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  columnIcon: {
    color: colors.boulder,
    marginRight: 4,
    width: 16,
    height: 16,
  },
}))

export default useStyles