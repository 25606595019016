// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'
import valuesPageAnalytics from 'constants/valuesPageAnalytics'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    height: '100%',
    position: 'relative',
    flexDirection: 'row',
  },
  mapWrapper: {
    height: '100%',
    position: 'relative',
    flex: 1,
    '& .customBindTooltipClustering': {
      '&::before': {
        display: 'none'
      },
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 'none',
      '& p': {
        margin: 0,
      }
    }
  },
  backdropDashboard: {
    position: 'absolute',
    zIndex: theme.zIndex.fab
  },
}))

export default useStyles
