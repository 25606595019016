// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconEarthquake = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M16.3311 1.42071L9.55459 2.6516C9.37095 2.68468 9.23198 2.83689 9.21048 3.02218L7.74136 15.9531L13.3531 14.9869C13.411 14.977 13.4656 14.9737 13.5252 14.9737C13.5616 14.9737 13.5947 14.9703 13.6311 14.9737H13.6443C13.7055 14.9737 13.7634 14.9869 13.8296 15.0001L15.3914 11.4928L15.0075 15.2781L21.1487 16.7207L22.459 4.54424C22.4789 4.36557 22.3846 4.19847 22.2208 4.12071L16.5825 1.44718C16.5031 1.40913 16.4187 1.40417 16.3311 1.42071ZM12.109 5.31189L14.134 5.55013L13.8958 7.56189L11.8708 7.32365L12.109 5.31189ZM17.4693 5.93395L19.4943 6.17218L19.2561 8.19718L17.2311 7.95895L17.4693 5.93395ZM5.51783 5.94718C5.46985 5.9538 5.41691 5.96373 5.37224 5.98689L1.63989 7.89277C1.48272 7.97384 1.39504 8.14093 1.41489 8.3163L2.42077 16.8795L4.30018 16.5487L4.14136 15.1192L5.61048 14.9472L5.7693 16.2972L6.86783 16.1119L6.8943 15.8604L7.3443 11.9295V11.9163L7.50312 10.5663L7.17224 10.606L7.00018 9.16336L7.67518 9.08395L7.96636 6.4766L5.66342 5.96042C5.61544 5.94884 5.56581 5.94057 5.51783 5.94718ZM11.6987 8.66042L13.7237 8.89865L13.4855 10.9104L11.4605 10.6722L11.6987 8.66042ZM17.0722 9.28248L19.084 9.52071L18.8458 11.5457L16.834 11.3075L17.0722 9.28248ZM4.92224 9.38836L5.0943 10.8575L3.62518 11.0295L3.45312 9.56042L4.92224 9.38836ZM11.3017 11.9692L13.3267 12.2075L13.0884 14.2192L11.0634 13.981L11.3017 11.9692ZM5.27959 12.1545L5.45165 13.6237L3.98254 13.7957L3.81048 12.3266L5.27959 12.1545ZM16.6619 12.631L18.6869 12.8692L18.4487 14.8942L16.4237 14.656L16.6619 12.631ZM13.4987 15.8207L1.77224 17.8457C1.56875 17.8821 1.41489 18.0641 1.41489 18.2692V22.081C1.41489 22.3159 1.60515 22.5045 1.83842 22.5045H8.93254L12.8502 19.1163L11.5796 18.2692L13.6972 16.5751V15.8339L13.6708 15.8207C13.6476 15.8158 13.6261 15.8224 13.6046 15.8207C13.5699 15.8174 13.5351 15.8141 13.4987 15.8207ZM16.0531 16.3898L14.5443 18.2692L16.2384 18.6928L13.3664 22.5045H22.1678C22.4028 22.5045 22.5914 22.3159 22.5914 22.081V18.2692C22.5914 18.0724 22.4524 17.9053 22.2605 17.8589L16.0531 16.3898Z'/>
    </SvgIcon>
  )
}

export default IconEarthquake