// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { DataGridPremium } from '@mui/x-data-grid-premium'
import { styled } from '@mui/material/styles'

const CustomDataGridPremium = styled(({ className, componentsProps, ...props }) => (
  <DataGridPremium
    checkboxSelection
    //checkboxSelectionVisibleOnly
    rowHeight={48}
    headerHeight={48}
    pagination
    rowsPerPageOptions={[ 10, 25, 50, 100 ]}
    disableColumnMenu
    componentsProps={{
      ...componentsProps,
      pagination: {
        SelectProps: {
          MenuProps: {
            sx: {
              '& .MuiMenuItem-root': {
                fontSize: 12,
              },
              '@media only screen and (max-height: 820px)': {
                '& .MuiPaper-root': {
                  zoom: 1/0.85, // no zoom
                },
                '& .MuiList-root': {
                  zoom: 0.85, // zoom
                },
              }
            },
          },
        },
      },
    }}
    {...props}
    className={className}
    localeText={{
      noRowsLabel: 'data tidak ditemukan',
      noResultsOverlayLabel: 'hasil tidak ditemukan'
    }}
  />
))(({ theme }) => ({
  border: 'none',
  fontSize: 12,
  color: 'black',

  // HEADER
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-sortIcon': {
    color: colors.clementine,
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    padding: 0,
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 600,
  },
  '& .MuiDataGrid-pinnedColumnHeaders': {
    boxShadow: '12px 0 10px -10px #ffffff',
    backgroundColor: 'transparent',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeaderCheckbox': {
    backgroundColor: 'white',
  },

  // ROW
  '& .MuiDataGrid-row:hover': {
    backgroundColor: colors.concrete,
  },
  '& .MuiDataGrid-row.Mui-hovered': {
    backgroundColor: colors.concrete,
  },
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: colors.bridesmaid,
  },
  '& .MuiDataGrid-row.Mui-selected:hover': {
    backgroundColor: colors.concrete,
  },
  '& .MuiDataGrid-row.Mui-selected.Mui-hovered': {
    backgroundColor: colors.concrete,
  },

  // COLUMN
  '& .MuiDataGrid-pinnedColumns': {
    boxShadow: '12px 0 10px -10px #ffffff',
    backgroundColor: 'transparent',
  },
  '[data-field="actions"] > *': {
    display: 'none',
  },
  '.MuiDataGrid-row.Mui-hovered [data-field="actions"]': {
    backgroundColor: 'transparent',
    backgroundImage: `linear-gradient(to right, transparent 0, ${colors.concrete} 17%)`,
  },
  '.MuiDataGrid-row.Mui-hovered [data-field="actions"] > *': {
    display: 'flex',
  },
  ' .MuiDataGrid-pinnedColumns .MuiDataGrid-row.Mui-hovered': {
    backgroundColor: 'transparent',
  },
  ' .MuiDataGrid-pinnedColumns .MuiDataGrid-row.Mui-selected': {
    backgroundColor: 'transparent',
  },

  // CELL
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-cellCheckbox': {
    backgroundColor: 'white',
  },
  '& .MuiDataGrid-row.Mui-selected .MuiDataGrid-cellCheckbox': {
    backgroundColor: colors.bridesmaid,
  },
  '& .MuiDataGrid-row:hover .MuiDataGrid-cellCheckbox': {
    backgroundColor: colors.concrete,
  },
  '& .MuiDataGrid-row.Mui-hovered .MuiDataGrid-cellCheckbox': {
    backgroundColor: colors.concrete,
  },
  '& .MuiDataGrid-cellContent': {
    fontWeight: 400
  },

  // PAGINATION
  '& .MuiTablePagination-selectLabel': {
    fontSize: 12,
  },
  '& .MuiTablePagination-select': {
    fontSize: 12,
  },
  '& .MuiTablePagination-displayedRows': {
    fontSize: 12,
  },
  '& .MuiIconButton-root': {
    padding: 8,
  },
  '@media only screen and (max-height: 820px)': {
    '& .MuiToolbar-root': {
      zoom: 1/0.85, // no zoom
    },
    '& .MuiTablePagination-selectLabel': {
      zoom: 0.85,
    },
    '& .MuiTablePagination-displayedRows': {
      zoom: 0.85,
    },
    '& .MuiTablePagination-actions': {
      zoom: 0.85,
    },
    '& .MuiDataGrid-footerContainer .MuiInputBase-root': {
      zoom: 1/0.85,
    },
    '& .MuiDataGrid-footerContainer .MuiTablePagination-select': {
      zoom: 0.85,
      fontSize: 10,
    },
    '& .MuiDataGrid-footerContainer .MuiSelect-nativeInput': {
      zoom: 0.85,
    },
    '& .MuiDataGrid-footerContainer .MuiSvgIcon-root': {
      zoom: 0.85,
    }
  }
}))

export default CustomDataGridPremium