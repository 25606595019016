import { useEffect, useContext } from 'react'

// COMPONENTS
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import DialogEvidenceDetail from 'components/DialogEvidenceDetail/DialogEvidenceDetail'
import EvidencesGrid from './EvidencesGrid/EvidencesGrid'
import Filters from './Filters/Filters'
import FilterOptions from './FilterOptions/FilterOptions'
import Header from './Header/Header'
import SaveNewFilterDialog from './SaveNewFilterDialog/SaveNewFilterDialog'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { PageEvidencesContext } from 'contexts/PageEvidencesContext'

// DATE AND TIME
import { format } from 'date-fns'

// MUIS
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'

// SERVICES
import { 
  advanceSearchEvidences,
  deleteSavedFilterItemAdvanceSearch, 
} from 'services/EvidenceService'
import { getTagEvidencesList } from 'services/TagEvidencesService'

// STYLES
import useStyles from './evidencesUseStyles'

// UTILITIES
import { generateMediaUrlBySize } from 'utilities/media'

const Evidences = () => {
  const classes = useStyles()

  const theme = useTheme()

  const { setSnackbarObject } = useContext(AllPagesContext)
  const { 
    isLoading, setIsLoading,
    setMustReloadFilterOptionsData,
    setEvidenceList,
    selectedFilterList, setSelectedFilterList,
    evidencesDateRange,
    selectedSavedFilter, setSelectedSavedFilter,
    setTotalEvidences,
    pageEvidences,
    rowsPerPageEvidences,
    selectedEvidenceItem, setSelectedEvidenceItem,
    dialogDeleteSavedFilter, setDialogDeleteSavedFilter,
    search,
  } = useContext(PageEvidencesContext)

  const updateEvidenceListData = async () => {
    setIsLoading(true)

    const queryParameters = {
      page: pageEvidences,
      size: rowsPerPageEvidences,
    }

    const bodyParameters = {
      startDate: format(new Date(evidencesDateRange[0]), 'dd-MM-yyyy'),
      endDate: format(new Date(evidencesDateRange[1]), 'dd-MM-yyyy'),
      globalSearch: search,
      query: selectedFilterList.map((item) => {
        const { operator, ...rest } = item

        return {
          ...rest,
          op: operator,
        }
      }),
    }

    const resultAdvanceSearch = await advanceSearchEvidences(queryParameters, bodyParameters)

    const resultTagEvidenceList = await getTagEvidencesList({
      page: 0,
      size: 10000,
    })

    if (resultAdvanceSearch.status === 200) {
      if (resultAdvanceSearch?.status !== 'error' && resultTagEvidenceList?.status !== 'error') {
        // EVIDENCE LIST WITH UPDATED TAGS
        let newEvidenceList = resultAdvanceSearch.data.rows.map(evidenceItem => {
          const newTagList = evidenceItem.tags.map(evidenceTagItem => {
            const tagEvidenceObject = resultTagEvidenceList.rows.find(tagItem => tagItem.tagNo === evidenceTagItem.tagNo)
  
            return {
              ...evidenceTagItem,
              ...tagEvidenceObject,
            }
          })
  
          return {
            ...evidenceItem,
            tags: [...newTagList],
          }
        })

        // EVIDENCE LIST WITH UPDATED MEDIAS
        const finalEvidenceList = newEvidenceList.map(evidenceItem => {
          const newMediaList = evidenceItem.medias.map(mediaItem => {
            return generateMediaUrlBySize(mediaItem)
          })

          return {
            ...evidenceItem,
            medias: newMediaList,
          }
        })

        setTotalEvidences(resultAdvanceSearch.data.totalElements)
        setEvidenceList(finalEvidenceList)
      }
    } else {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Gagal Mengambil Data Kejadian',
      })
    }

    setIsLoading(false)
  }

  const handleDeleteSavedFilter = async () => {
    const resultDeleteSavedFilterItem = await deleteSavedFilterItemAdvanceSearch(dialogDeleteSavedFilter.filterNo)
    
    if (resultDeleteSavedFilterItem.status === 204) {
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Sukses Menghapus Filter',
      })

      setMustReloadFilterOptionsData(true)
      setDialogDeleteSavedFilter({})

      if (dialogDeleteSavedFilter.filterNo === selectedSavedFilter.filterNo) {
        setSelectedSavedFilter(null)
        setSelectedFilterList([])
      }
    }
    else setSnackbarObject({
      open: true,
      severity: 'success',
      title: '',
      message: 'Gagal Menghapus Filter',
    })
  }

  useEffect(() => {
    updateEvidenceListData()
  }, [
    selectedFilterList, 
    pageEvidences, 
    rowsPerPageEvidences, 
    evidencesDateRange,
    search,
  ])

  return (
    <Stack
      height='100%'
      padding='24px'
    >
      {/* PAGE CONTENT */}
      <Stack 
        flex={1}
        backgroundColor='white'
        boxShadow={theme.shadows[2]}
      >
        {/* HEADER */}
        <Header/>

        {/* FILTERS */}
        <Filters/>

        <Stack
          direction='row'
          flex={1}
        >
          {/* FILTERS OPTIONS */}
          <FilterOptions/>

          {/* GRID VIEW */}
          <EvidencesGrid/>
        </Stack>
      </Stack>

      {/* SAVE AS A NEW FILTER DIALOG */}
      <SaveNewFilterDialog/>

      {/* EVIDENCE DETAIL DIALOG */}
      <DialogEvidenceDetail
        isDialogOpen={Boolean(selectedEvidenceItem)} 
        handleDialogClose={() => setSelectedEvidenceItem(null)}
        evidenceObject={selectedEvidenceItem}
      />

      {/* BACKDROP */}
      <Backdrop 
        open={isLoading}
        className={classes.backdrop} 
      >
        <CircularProgress color='primary'/>
      </Backdrop>

      {/* DIALOG DELETE SAVED FILTER */}
      <DialogConfirmation
        title='Hapus Filter'
        caption='Apa Anda yakin ingin menghapus filter ini?'
        dialogConfirmationObject={dialogDeleteSavedFilter}
        setDialogConfirmationObject={setDialogDeleteSavedFilter}
        cancelButtonText='Batal'
        continueButtonText='Hapus'
        onContinueButtonClick={handleDeleteSavedFilter}
        onCancelButtonClick={() => setDialogDeleteSavedFilter({})}
      />
    </Stack>
  )
}

export default Evidences