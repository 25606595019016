// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  input: {
    height: 40,
    paddingLeft: 8,
    width: 392,
    maxWidth: 392,
  },
}))

export default useStyles
