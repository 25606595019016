// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  badge : {
    '& .MuiBadge-badge' : {
      top : -4,
      right : -8
    }
  }
}))

export default useStyles
