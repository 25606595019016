import React, { useState } from 'react'
import PropTypes from 'prop-types'

// CUSTOM COMPONENTS
import CustomInput from 'components/Customs/CustomInput'
import CustomMenuItem from 'components/Customs/CustomMenuItem'
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconArrowDropDown from '@mui/icons-material/ArrowDropDown'
import IconArrowDropUp from '@mui/icons-material/ArrowDropUp'
import IconFilterAlt from '@mui/icons-material/FilterAlt'
import IconSettings from '@mui/icons-material/Settings'

// STYLES
import useStyles from './dataGridFiltersUseStyles'

const DataGridFilters = (props) => {
  const { 
    columns,
    selectedColumnList,
    handleColumnsMenuItemClick,
    isFilterOn, 
    setIsFilterOn,
    groupByList,
    selectedGroupBy,
    setSelectedGroupBy,
  } = props

  const classes = useStyles()

  const [ groupByMenuAnchor, setGroupByMenuAnchor ] = useState(null)
  const [ columnsMenuAnchor, setColumnsMenuAnchor ] = useState(null)

  const handleGroupByMenuItemClick = (inputItem, inputIndex) => {
    setGroupByMenuAnchor(null)
    setSelectedGroupBy(inputItem)
  }

  return (
    <Box className={classes.root}>
      {/* GROUP BY TEXT */}
      <Typography
        variant='subtitle2'
        className={classes.groupByText}
      >
        Group By:
      </Typography>

      {/* GROUP BY INPUT */}
      <CustomInput
        type='text'
        name='selectedGroupBy'
        className={classes.groupByInput}
        value={selectedGroupBy.title}
        onChange={(event) => setSelectedGroupBy(event.target.value)}
        endAdornment={
          groupByMenuAnchor ?
            <IconArrowDropUp className={classes.groupByIcon}/> :
            <IconArrowDropDown className={classes.groupByIcon}/>
        }
        onClick={(event) => setGroupByMenuAnchor(event.currentTarget)}
      />

      {/* GROUP BY MENU */}
      <Menu
        anchorEl={groupByMenuAnchor}
        open={Boolean(groupByMenuAnchor)}
        onClose={() => setGroupByMenuAnchor(null)}
        className={classes.groupByMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {groupByList.map((item, index) => (
          <CustomMenuItem 
            key={index}
            onClick={() => handleGroupByMenuItemClick(item, index)}
            className={
              selectedGroupBy.title === item.title ?
                classes.groupByMenuItemSelected :
                ''
            }
          >
            {item.title}
          </CustomMenuItem>
        ))}
      </Menu>

      {/* FILTER ICON */}
      <CustomTooltipBlack 
        title='Filter' 
        placement='top'
      >
        <IconButton
          className={`${classes.filterIconButton}`}
          onClick={() => setIsFilterOn(current => !current)}
        >
          <IconFilterAlt color={isFilterOn ? 'primary' : ''}/>
        </IconButton>
      </CustomTooltipBlack>

      {/* CUSTOMIZE COLUMNS ICON */}
      <CustomTooltipBlack 
        title='Sesuaikan Kolom' 
        placement='top'
        className='no-zoom'
      >
        <IconButton 
          className={classes.settingsIconButton}
          onClick={(event) => setColumnsMenuAnchor(event.currentTarget)}
        >
          <IconSettings/>
        </IconButton>
      </CustomTooltipBlack>

      {/* CUSTOMIZE COLUMNS MENU */}
      <Menu
        anchorEl={columnsMenuAnchor}
        open={Boolean(columnsMenuAnchor)}
        onClose={() => setColumnsMenuAnchor(null)}
        className={`${classes.columnsMenuRoot} no-zoom`}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {/* TITLE */}
        <Typography
          variant='subtitle2'
          className={classes.columnsMenuTitle}
        >
          Kolom
        </Typography>

        {/* COLUMN ITEMS */}
        {columns.map((item, index) => (
          (item.field !== 'actions') &&
          <CustomMenuItem 
            key={index}
            className={`${classes.columnsMenuItem} zoom`}
            onClick={() => handleColumnsMenuItemClick(item, index)}
          >
            <Checkbox checked={!selectedColumnList[index].hide}/>
            <Typography
              variant='subtitle2'
              className={classes.columnsMenuText}
            >
              {item.headerName}
            </Typography>
          </CustomMenuItem>
        ))}
      </Menu>
    </Box>
  )
}

DataGridFilters.defaultProps = {
  columns: [],
  selectedColumnList: [],
  isFilterOn: false,
  groupByList: [],
  selectedGroupBy: {},
}

DataGridFilters.propTypes = {
  columns: PropTypes.array.isRequired,
  selectedColumnList: PropTypes.array.isRequired,
  handleColumnsMenuItemClick: PropTypes.func.isRequired,
  isFilterOn: PropTypes.bool.isRequired,
  setIsFilterOn: PropTypes.func.isRequired,
  groupByList: PropTypes.array.isRequired,
  selectedGroupBy: PropTypes.object.isRequired,
  setSelectedGroupBy: PropTypes.func.isRequired,
}

export default DataGridFilters
