// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: alpha('#ffffff', 0.8),
    zIndex: theme.zIndex.modal - 1,
  },
  contentContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainContent: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60%',
  },
  carouselContainer: {
    width: '100%',
    maxHeight: '100%',
  },
  carouselItem: {
    width: '100%',
    maxHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  carouselMedia: {
    // NOTE: THIS STYLE BREAKS THE RESPONSIVENESS IF WE CHANGE THE HEIGHT OF THE BROWSER
    height: 720,
    maxHeight: '100%',
    objectFit: 'contain',
  },
  iconActions: {
    height: 30,
    width: 30,
    color: colors.silverChalice,
    cursor: 'pointer',
  },
  contentCloseIcon: {
    position: 'absolute',
    top: 50,
    margin: '0px 15px',
  },
  paginationContainer: {
    width: '100%',
    height: 100,
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
  },
  paginationItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paginationTitle: {
    fontWeight: 700,
  },
  paginationIcon: {
    margin: '0px 15px',
  },
}))

export default useStyles