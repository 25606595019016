// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  circleWrapper: {
    backgroundColor: colors.california,
    borderRadius: '100%',
    width: 16,
    height: 16,
  },
  popupTitle: {
    margin: '0 !Important',
    fontSize: '12px !important',
    fontWeight: 600,
  },
  popupDescription: {
    margin: '4px 0 0 0 !important',
    fontSize: '12px !important'
  }
}))

export default useStyles
