// MUIS
import Input from '@mui/material/Input'
import { styled } from '@mui/material/styles'

const CustomInputNew = styled(({ className, ...props }) => (
  <Input className={className} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: 8,

  '& .MuiInput-input': {
    fontSize: '14px !important',
    padding: 0
  },
  '& input[type=number]': {
    MozAppearance: 'textfield !important'
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    WebkitAppearance: 'none !important',
    margin: 0
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    WebkitAppearance: 'none !important',
    margin: 0
  }
}))

export default CustomInputNew
