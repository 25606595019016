// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconDiversity = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M7.00007 3C4.80277 3 3.00007 4.80271 3.00007 7C3.00007 9.19729 4.80277 11 7.00007 11C9.19736 11 11.0001 9.19729 11.0001 7C11.0001 4.80271 9.19736 3 7.00007 3ZM15.0001 3C13.907 3 13.0001 3.90694 13.0001 5V9C13.0001 10.0931 13.907 11 15.0001 11H19.0001C20.0931 11 21.0001 10.0931 21.0001 9V5C21.0001 3.90694 20.0931 3 19.0001 3H15.0001ZM7.00007 5C8.11648 5 9.00007 5.88359 9.00007 7C9.00007 8.11641 8.11648 9 7.00007 9C5.88365 9 5.00007 8.11641 5.00007 7C5.00007 5.88359 5.88365 5 7.00007 5ZM15.0001 5H19.0001V9H15.0001V5ZM16.5274 12.7344C16.3071 12.728 16.0908 12.7946 15.9122 12.9238L12.6173 15.3184C12.4466 15.4424 12.3195 15.6173 12.2543 15.818C12.189 16.0187 12.1889 16.2348 12.254 16.4355L13.5137 20.3086C13.5789 20.5095 13.7061 20.6847 13.877 20.8089C14.0478 20.9331 14.2537 21 14.4649 21H18.5352C18.7465 21 18.9523 20.9331 19.1232 20.8089C19.294 20.6847 19.4212 20.5095 19.4864 20.3086L20.7462 16.4355C20.8112 16.2348 20.8111 16.0187 20.7458 15.818C20.6806 15.6173 20.5535 15.4424 20.3829 15.3184L17.088 12.9238C16.9245 12.8056 16.7291 12.7395 16.5274 12.7344ZM7.00788 13C6.82083 12.9985 6.63712 13.0495 6.47762 13.1473C6.31811 13.245 6.1892 13.3854 6.10554 13.5527L3.10554 19.5527C3.02932 19.7052 2.99334 19.8746 3.00101 20.0449C3.00869 20.2152 3.05976 20.3807 3.14938 20.5257C3.23901 20.6707 3.3642 20.7904 3.51309 20.8734C3.66197 20.9564 3.82961 21 4.00007 21H10.0001C10.1705 21 10.3382 20.9564 10.487 20.8734C10.6359 20.7904 10.7611 20.6707 10.8508 20.5257C10.9404 20.3807 10.9914 20.2152 10.9991 20.0449C11.0068 19.8746 10.9708 19.7052 10.8946 19.5527L7.8946 13.5527C7.81212 13.3878 7.68565 13.2489 7.52917 13.1514C7.37268 13.0538 7.19227 13.0014 7.00788 13ZM16.5001 14.9688L18.6192 16.5098L17.8087 19H15.1895L14.3809 16.5098L16.5001 14.9688ZM7.00007 16.2363L8.38288 19H5.61726L7.00007 16.2363Z' />
    </SvgIcon>
  )
}

export default IconDiversity