// MUI STYLES
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    zIndex: 3,
    backgroundColor: 'white',
    borderRadius: 4,
    width: 40,
    height: 40,
    display: 'flex',
    boxShadow: `0 0 10px ${alpha(theme.palette.common.black, 0.16)}`,
    pointerEvents: 'auto',
    cursor: 'pointer',
  },
}))

export default useStyles