import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

// COMPONENTS
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import DialogAddOrEditDutyType from './DialogAddOrEditDutyTypes/DialogAddOrEditDutyTypes'
import LoadingBox from 'components/LoadingBox/LoadingBox'
import PageHeader from 'components/PageHeader/PageHeader'

// CONSTANTS
import { basePaths } from 'constants/paths'
import { iconListDutyTypes } from 'constants/icons'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconDelete from '@mui/icons-material/Delete'
import IconEdit from '@mui/icons-material/Edit'

// PATH ICONS
import IconListView from 'assets/images/pathIcons/IconListView'

// RAMDA
import { isEmpty, reject } from 'ramda'

// SERVICES
import { deleteVehicleTypeList, searchVehicleType } from 'services/vehicleServices'

// STORES
import { showAlert } from 'store/MainReducer'

// STYLES
import useStyles from './dutyTypesUseStyles'

const DutyTypes = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const pageRef = useRef()

  const history = useHistory()
  const location = useLocation()

  const initialFilters = {
    vehicleTypeName : '',
  }

  const groupByList = [
    {
      title: 'Don\'t Group',
      value: null,
    },
  ]

  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('vehicleTypeName')
  const [size, setPageSize] = useState(100)
  const [dutyTypeTableData, setDutyTypeTableData] = useState([])
  const [filters, setFilters] = useState(initialFilters)
  const [selectedGroupBy, setSelectedGroupBy] = useState(groupByList[0])
  const [isFilterOn, setIsFilterOn] = useState(false)
  const [search, setSearch] = useState('')
  const [dialogAddEditDutyType, setDialogAddEditDutyType] = useState(null)
  const [selectionModel, setSelectionModel] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [dialogDeleteDutyTypes, setDialogDeleteDutyTypes] = useState({})

  const handleEditIconButtonClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()
    history.push(
      `${basePaths.settingsDutyTypes}?mode=edit&id=${inputParams.id}`, 
      {
        mode: 'edit',
        data: inputParams.row,
      }
    )
  }

  const handleDeleteButtonClick = async () => {
    setIsLoading(true)
    const response = await deleteVehicleTypeList(dialogDeleteDutyTypes.row.vehicleTypeNo)
    setIsLoading(false)
    
    if(response?.status === 'error') {
      dispatch(showAlert({ message: 'Gagal Menghapus Metode Patroli' }))
    } else {
      dispatch(showAlert({ message: 'Berhasil Menghapus Metode Patroli' }))
    }
    setDialogDeleteDutyTypes({})
    reloadData()
  }

  const initialColumns = [
    {
      field: 'vehicleTypeName',
      headerName: 'Metode Patroli',
      headerIcon: IconListView,
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: params => {
        const Icon = params.row.iconCode ? iconListDutyTypes[Number(params.row.iconCode)-1]?.Icon : null

        return (
          <Stack direction='row' alignItems='center'>
            {params.row.vehicleTypeColor && (
              <Box
                className={classes.boxColorColumn}
                sx={{
                  backgroundColor: params.row.vehicleTypeColor
                }}
              ></Box>
            )}

            {Icon && <Icon className={classes.boxIconColumn} />}

            <Typography className={classes.textVehicleTypeName} variant='body2'>{params.value}</Typography>
          </Stack>
        )
      }
    },
    {
      field: 'actions',
      headerName: '',
      width: 120,
      hide: false,
      areFilterAndSortShown: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box className={classes.columnActions}>
          {/* EDIT ICON */}
          <CustomTooltipBlack title='Ubah' placement='bottom'>
            <IconButton onClick={(event) => handleEditIconButtonClick(event, params)}>
              <IconEdit />
            </IconButton>
          </CustomTooltipBlack>

          {/* DELETE ICON */}
          <CustomTooltipBlack title='Hapus' placement='bottom'>
            <IconButton onClick={(event) => setDialogDeleteDutyTypes(params)}>
              <IconDelete />
            </IconButton>
          </CustomTooltipBlack>
        </Box>
      ),
    },
  ]

  const [selectedColumnList, setSelectedColumnList] = useState(initialColumns)

  // NOTE: PERHAPS, THIS COULD BE OPTIMIZED. SOURCE: DATA GRID TABLE COMPONENT
  const groupingColDef = {
    headerName: 'Status',
    renderCell: (params) => {
      return (
        <Typography 
          variant='subtitle1' 
          className={classes.groupingRow}
          noWrap
        >
          {params.rowNode.groupingKey.toLowerCase()}
        </Typography>
      )
    },
  }

  // NOTE: PERHAPS, THIS COULD BE OPTIMIZED. SOURCE: DATA GRID FILTERS COMPONENT
  const handleColumnsMenuItemClick = (inputItem, inputIndex) => {
    let tempSelectedColumnList = [...selectedColumnList]
    if (
      (selectedGroupBy.value && inputItem.field === 'deviceName') ||
      (selectedGroupBy.value && inputItem.field === 'dispatchDeviceStatus')
    ) {
    } else {
      tempSelectedColumnList[inputIndex].hide =
        !tempSelectedColumnList[inputIndex].hide
    }
    setSelectedColumnList(tempSelectedColumnList)
  }

  const reloadData = () => {
    const newFilters = reject(isEmpty, filters)
    let params = {
      page,
      size,
    }

    if (order) params.sort = `${orderBy},${order}`
    if (search) newFilters.globalSearch = search

    setIsLoading(true)

    searchVehicleType(params, newFilters).then((response) => {
      setDutyTypeTableData(response.rows.map(item => ({
        ...item,
        id: item.vehicleTypeNo
      })))
      setTotal(response.totalElements)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    reloadData()
  }, [page, size, order, orderBy, filters, search])

  useEffect(() => {
    if (!isFilterOn) setFilters(initialFilters)
  }, [isFilterOn])

  // NOTE: PERHAPS, THIS COULD BE OPTIMIZED. SOURCE: DATA GRID TABLE COMPONENT
  useEffect(() => {
    if (selectedGroupBy.value) {
      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        if (
          item.field === selectedGroupBy.value ||
          item.field === 'dispatchDeviceStatus'
        )
          item.hide = true
      })
      setSelectedColumnList(tempSelectedColumnList)
    } else {
      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        if (
          item.field === 'deviceName' ||
          item.field === 'dispatchDeviceStatus'
        )
          item.hide = false
      })
      setSelectedColumnList(tempSelectedColumnList)
    }
  }, [selectedGroupBy])

  useEffect(() => {
    if (location.pathname === basePaths.settingsDutyTypes && (location.search.includes('?mode=edit') || location.search.includes('?mode=add'))) {
      setDialogAddEditDutyType(location?.state || {})
    }
    else setDialogAddEditDutyType(false)
  }, [location])

  return (
    <Box className={classes.pageRoot} ref={pageRef}>
      {/* HEADER */}
      <PageHeader
        isAddButtonAvailable={true}
        onAddButtonIsClicked={() => history.push(`${basePaths.settingsDutyTypes}?mode=add`, { mode: 'add' })}
        title='Metode Patroli'
        search={search}
        setSearch={setSearch}
      />

      {/* CONTENT */}
      <LoadingBox isLoading={isLoading}>
        <DataGridFilters
          columns={initialColumns}
          selectedColumnList={selectedColumnList}
          handleColumnsMenuItemClick={handleColumnsMenuItemClick}
          isFilterOn={isFilterOn}
          setIsFilterOn={setIsFilterOn}
          groupByList={groupByList}
          selectedGroupBy={selectedGroupBy}
          setSelectedGroupBy={setSelectedGroupBy}
        />

        <DataGridTable
          initialColumns={initialColumns}
          rows={dutyTypeTableData}
          total={total}
          page={page}
          pageSize={size}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setPage={setPage}
          setPageSize={setPageSize}
          setFilters={setFilters}
          isFilterOn={isFilterOn}
          selectedColumnList={selectedColumnList}
          setSelectedColumnList={setSelectedColumnList}
          selectedGroupBy={selectedGroupBy}
          getTreeDataPath={(row) => [row.deviceName, row.id]}
          groupingColDef={groupingColDef}
          selectionModel={selectionModel} 
          setSelectionModel={setSelectionModel}
          isCheckboxPinned={true}
        />
      </LoadingBox>

      {/* DIALOG ADD EDIT DUTY TYPE */}
      <DialogAddOrEditDutyType
        dialogAddEditDutyType={dialogAddEditDutyType}
        setDialogAddEditDutyType={setDialogAddEditDutyType}
        pageRef={pageRef}
        reloadData={reloadData}
      />

      {/* DIALOG DELETE PATROL */}
      <DialogConfirmation
        title='Hapus Metode Patroli'
        caption='Apa Anda yakin ingin menghapus metode patroli ini?'
        dialogConfirmationObject={dialogDeleteDutyTypes}
        setDialogConfirmationObject={setDialogDeleteDutyTypes}
        cancelButtonText='Batal'
        continueButtonText='Hapus'
        onContinueButtonClick={handleDeleteButtonClick}
        onCancelButtonClick={() => setDialogDeleteDutyTypes({})}
      />
    </Box>
  )
}

export default DutyTypes
