// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'
import { alpha } from '@mui/material/styles'


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    height: '100%',
    position: 'relative',
    width: 420,
    borderRadius: 2,
  },
  headerWrapper: {
    padding: '6px 16px',
    flexDirection: 'row',
    alignItems: 'center',
    boxShadow: `0px 2px 4px ${alpha(theme.palette.common.black, 0.08)}`,
    position: 'relative',
    zIndex: 2
  },
  titleHeader: {
    fontWeight: 600,
    fontSize: 16,
    marginLeft: 8
  },
  content: {
    padding: 24,
    overflowY: 'auto',
    flex: 1,
  },
  boxFilter: {
    boxShadow: 'none',
    border: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
    borderRadius: '4px 4px 0 0'
  },
  boxFilterIcon: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 2,
    color: theme.palette.common.white,
    width: 24,
    height: 24,
  },
  titleFilter: {
    color: colors.raven,
    fontWeight: 400,
    paddingLeft: 8,
    flex: 1
  },
  formControlLabel: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    transform: 'unset',
    marginBottom: 16,
    zIndex: 0,
  },
  formControlTextLabel: {
    color: theme.palette.common.black,
    paddingLeft: 8,
    fontWeight: 400,
  },
  formControlIconLabel: {
    color: colors.spunPearl
  },
  formControlSelect: {
    marginLeft: 24,
    borderRadius: 2,
    '& .MuiSelect-select': {
      backgroundColor: colors.whiteLilac,
      padding: '8px',
      cursor: 'pointer',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
    },
    '&.haveDialogMenu': {
      '& .MuiSelect-icon': {
        '@media only screen and (max-height: 820px)': {
          zoom: 0.85,
        }
      },
      '& .MuiOutlinedInput-notchedOutline': {
        '@media only screen and (max-height: 820px)': {
          zoom: 0.85,
        }
      }
    }
  },
  formControlItemText: {
    '-webkit-text-fill-color': 'black !important',
    fontWeight: 600,
  },
  formControlItemIcon: {
    color: colors.raven,
    marginRight: 8,
  },
  dateRangeAndTimePickerDialog: {
    '& .MuiDialog-paper': {
      maxWidth: 'unset',
    },
    '& .MuiPaper-root': {
      borderRadius: 0,
    },
  },
  dividerFormControl: {
    margin: '16px -16px',
    borderColor: alpha(theme.palette.common.black, 0.23),
  },
  listMenuLocation: {
    width: '100%',
    padding: '4px 8px 8px 8px',
    '& .MuiListItemIcon-root': {
      minWidth: 36,
    }
  },
  formControlIconDangerCategory: {
    color: theme.palette.error.main,
    marginRight: 8,
  },
  formControlIconSuccessCategory: {
    color: theme.palette.success.main,
    marginRight: 8,
  },
  iconButtonAdd: {
    backgroundColor: theme.palette.primary.main,
    width: 22,
    height: 22,
    '& .MuiSvgIcon-root': {
      fontSize: 16,
      color: theme.palette.common.white,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    }
  },
  formControlIconInfo: {
    color: colors.spunPearl,
    marginRight: 8,
  },
  formControlTextInfo: {
    color: colors.spunPearl,
  },
  listMenuItemTagEvidences: {
    padding: '0 4px'
  },
  listMenuChipTagEvidences: {
    fontWeight: 600,
    height: 24,
    cursor: 'pointer',
    '& .MuiChip-label': {
      paddingLeft: 12,
    },
    '& .MuiSvgIcon-root': {
      marginLeft: 8,
      fontSize: 16,
    }
  },
  listMenuItemSelectedTagEvidences: {
    padding: '4px 0',
    '&:first-child': {
      paddingTop: 0,
    }
  },
  iconDelete: {
    color: colors.spunPearl
  },
  listMenuSelected: {
    paddingLeft: 24,
    paddingTop: 0,
  },
  listMenuItemDevices: {
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
    cursor: 'pointer',
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  listMenuTagEvidences: {
    width: '100%',
    padding: '4px 8px 8px 8px',
    cursor: 'pointer'
  },
  dialogMenuHeader: {
    boxShadow: `0px 2px 4px ${alpha(theme.palette.common.black, 0.16)}`,
  },
  dialogMenuTitleHeader: {
    padding: '16px 24px',
    fontWeight: 600,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
  },
  inputDevicesSearch: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  listMenuItemSelected: {
    backgroundColor: colors.whiteLilac,
    border: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
    borderRadius: 4,
    padding: 8,
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0
    }
  },
  iconSelectedDevices: {
    color: theme.palette.text.secondary,
  },
  textSelectedDevices: {
    flex: 1,
    fontWeight: 400,
    padding: '0 8px',
  },
  footer: {
    boxShadow: `0px -2px 4px ${alpha(theme.palette.common.black, 0.16)}`,
    flexDirection: 'row',
    padding: 16,
    justifyContent: 'flex-end',
    position: 'relative',
    zIndex: 2
  },
  buttonDialog: {
    fontWeight: 700,
    '&.btnCancel': {
      color: theme.palette.text.secondary,
    }
  },
  dialogMenu: {
    marginTop: 8,
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
      width: 316,
      '@media only screen and (max-height: 820px)': {
        width: 264,
      }
    },
    '& .MuiMenu-list': {
      padding: 0,
    }
  },
  dialogMenuBtnRight: {
    transform: 'translate(-30px, -26px) !important',

    '@media only screen and (max-height: 820px)': {
      transform: 'translate(-24px, -26px) !important',
    },
  },
  iconDeleteSelected: {
    fontSize: 16,
  },
  boxFilterHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.wildSand,
    padding: '16px 8px 16px 16px',
    borderRadius: '4px 4px 0 0',
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
  },
  formControlTypeData: {
    width: 88,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
    '& .MuiSelect-select': {
      padding: 0,
      '@media only screen and (max-height: 820px)': {
        zoom: 1/0.85,
      },
      '& .MuiTypography-root': {
        '@media only screen and (max-height: 820px)': {
          zoom: 0.85,
        },
      }
    },
    '& .MuiSvgIcon-root': {
      '@media only screen and (max-height: 820px)': {
        zoom: 0.85,
      },
    }
  },
  selectTypeDataMenu: {
    borderRadius: 2,
    '& .MuiList-root': {
      padding: 8,
    },
  },
  iconBtnBack: {
    color: colors.spunPearl
  }
}))

export default useStyles
