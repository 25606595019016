import React, { createContext, useState, useRef } from 'react'

// CONSTANTS
import mapList from 'constants/mapList'
import { panelsInfoStatic } from 'pages/Tracking/PanelUnifiedMapInformation/panelInformationIntegratedConstant'

// MOMENT
import moment from 'moment'

// UTILS
import { readReferenceLocationFromLocalStorage } from 'utilities/localStorage'

export const PageTrackingContext = createContext()

const getThisWeekDateAndTime = () => {
  return [moment().startOf('week').toDate(), moment().endOf('week').toDate()]
}

const PageTrackingContextProvider = (props) => {
  // BOOLEAN
  const [ isPanelLeftShown, setIsPanelLeftShown ] = useState(panelsInfoStatic.panelInformation)
  const [ isEvidencesPanelShown, setIsEvidencesPanelShown ] = useState(true)
  //const [ isEvidenceDetailPanelShown, setIsEvidenceDetailPanelShown ] = useState(false)
  //const [ isObjectDetailPanelShown, setIsObjectDetailPanelShown ] = useState(false)
  const [ isHistoryDateRangeShown, setIsHistoryDateRangeOpen ] = useState(false)
  const [ isMapMoving, setIsMapMoving ] = useState(true)
  const [ isFilterSearchExpanded, setIsFilterSearchExpanded ] = useState(false)
  const [ isLoadingPanelUnified, setIsLoadingPanelUnified ] = useState(true)
  const [ isDialogAddEvidenceShown, setIsDialogAddEvidenceShown ] = useState(false)
  const [ isSuccessCreateDispatch, setIsSuccessCreateDispatch ] = useState(false)
  
  // LIST
  const [ boundsBox, setBoundsBox ] = useState([])
  const [ mapBounds, setMapBounds ] = useState(null)
  const [ evidenceList, setEvidenceList ] = useState([])
  const [ objectList, setObjectList ] = useState([])
  const [ groupList, setGroupList ] = useState([])
  const [ objectMarkerList, setObjectMarkerList ] = useState([])
  const [ historyDateRange, setHistoryDataRange ] = useState(getThisWeekDateAndTime()) 
  const [ historyPolylineList, setHistoryPolylineList ] = useState([])
  const [ selectionModel, setSelectionModel ] = useState([])
  const [ selectionModelPlacesList, setSelectionModelPlacesList ] = useState([])
  const [ queryFilterEvidence, setQueryFilterEvidence ] = useState([])
  const [ tagList, setTagList ] = useState([])
  const [ dispatchEvidenceList, setDispatchEvidenceList ] = useState([])
  const [ userEvidenceList, setUserEvidenceList ] = useState([])
  const [ queryFilterDispatch, setQueryFilterDispatch ] = useState([])

  // NUMBER
  const [ zoom, setZoom ] = useState(5)
  const [ selectedObjectId, setSelectedObjectId ] = useState(null)
  
  // OBJECT
  const pageRef = useRef(null)
  const [ map, setMap ] = useState(null)
  const [ evidenceDetail, setEvidenceDetail ] = useState(null)
  const [ objectDetail, setObjectDetail ] = useState(null)
  const [ selectedMapObject, setSelectedMapObject ] = useState(mapList.mapOptionList[0])
  const [ isPanelSearchDetail, setIsPanelSearchDetail ] = useState(null)
  const [ evidenceDateRangeFilter, setEvidenceDateRangeFilter ] = useState({
    start: moment().startOf('week').toDate(),
    end: moment().endOf('week').toDate(),
    isActive: false,
  })
  const [ objectSortFilter, setObjectSortFilter ] = useState({
    removeGrouping: false,
    byName: false,
    byStatus: false,
    byDistance: false
  })
  const [ referenceLocation, setReferenceLocation ] = useState(readReferenceLocationFromLocalStorage()) // [lat, lng]
  const [ dispatchDateRangeFilter, setDispatchDateRangeFilter ] = useState({
    start: moment().startOf('week').toDate(),
    end: moment().endOf('week').toDate(),
    isActive: false,
  })
  const [ markerSettings, setMarkerSettings ] = useState({
    isClustering: true,
    isLabelShown: true,
    isClusteringLocation: false
  })

  // PANEL GEOFENCES
  const [ isShowPanelList, setIsShowPanelList ] = useState(false)
  const [ isShowPanelAddEdit, setIsShowPanelAddEdit ] = useState(false)
  const [ isShowPanelDetail, setIsShowPanelDetail ] = useState(false)
  const [ typePanelAddEdit, setTypePanelAddEdit ] = useState('add')
  const [ circleRadius, setCircleRadius ] = useState(150)
  const [ circleLatLng, setCircleLatLng ] = useState(null)
  const [ shapeCurrentRef, setShapeCurrentRef ] = useState()

  // REFS
  const markerClustersRef = useRef()
  const markerClustersLocationRef = useRef({})


  // STRING
  const [ evidenceSearch, setEvidenceSearch ] = useState('')
  const [ objectSearch, setObjectSearch ] = useState('')
  const [ dispatchSearch, setDispatchSearch ] = useState('')
  const [ currentPanelTabLeft, setCurrentPanelTabLeft ] = useState('Kejadian')
  
  // FUNCTION
  const panByPositive = () => {
    return map.panBy([300, 0], { duration: 0.5, animate: true })
  }
  const panByNegative = () => {
    return map.panBy([-300, 0], { duration: 0.5, animate: true })
  }

  return (
    <PageTrackingContext.Provider
      value={{
        // BOOLEAN
        isPanelLeftShown, setIsPanelLeftShown,
        isEvidencesPanelShown, setIsEvidencesPanelShown,
        //isEvidenceDetailPanelShown, setIsEvidenceDetailPanelShown,
        //isObjectDetailPanelShown, setIsObjectDetailPanelShown,
        isHistoryDateRangeShown, setIsHistoryDateRangeOpen,
        isMapMoving, setIsMapMoving,
        isFilterSearchExpanded, setIsFilterSearchExpanded,
        isLoadingPanelUnified, setIsLoadingPanelUnified,
        isDialogAddEvidenceShown, setIsDialogAddEvidenceShown,
        isSuccessCreateDispatch, setIsSuccessCreateDispatch,
        // LIST
        boundsBox, setBoundsBox,
        mapBounds, setMapBounds,
        evidenceList, setEvidenceList,
        objectList, setObjectList,
        objectMarkerList, setObjectMarkerList,
        historyDateRange, setHistoryDataRange,
        historyPolylineList, setHistoryPolylineList,
        groupList, setGroupList,
        selectionModel, setSelectionModel,
        selectionModelPlacesList, setSelectionModelPlacesList,
        queryFilterEvidence, setQueryFilterEvidence,
        tagList, setTagList,
        dispatchEvidenceList, setDispatchEvidenceList,
        userEvidenceList, setUserEvidenceList,
        queryFilterDispatch, setQueryFilterDispatch,
        // NUMBER
        zoom, setZoom,
        selectedObjectId, setSelectedObjectId,
        currentPanelTabLeft, setCurrentPanelTabLeft,
        // OBJECT
        pageRef,
        map, setMap,
        evidenceDetail, setEvidenceDetail,
        objectDetail, setObjectDetail,
        selectedMapObject, setSelectedMapObject,
        isPanelSearchDetail, setIsPanelSearchDetail,
        evidenceDateRangeFilter, setEvidenceDateRangeFilter,
        objectSortFilter, setObjectSortFilter,
        referenceLocation, setReferenceLocation,
        dispatchDateRangeFilter, setDispatchDateRangeFilter,
        markerSettings, setMarkerSettings,
        // REFS,
        markerClustersRef, markerClustersLocationRef,
        // STRING
        evidenceSearch, setEvidenceSearch,
        objectSearch, setObjectSearch,
        dispatchSearch, setDispatchSearch,
        // FUNCTION
        panByPositive, panByNegative,
        // PANEL GEOFENCES
        isShowPanelList, setIsShowPanelList,
        isShowPanelDetail, setIsShowPanelDetail,
        isShowPanelAddEdit, setIsShowPanelAddEdit,
        typePanelAddEdit, setTypePanelAddEdit,
        circleRadius, setCircleRadius,
        circleLatLng, setCircleLatLng,
        shapeCurrentRef, setShapeCurrentRef,
      }}
    >
      {props.children}
    </PageTrackingContext.Provider>
  )
}

export default PageTrackingContextProvider