import { http } from '../utilities/http'
import { stringify } from 'query-string'

export async function getAlerts(query, params = {}) {
  try {
    const {data} = await http.post(`/alerts/search?${stringify(query)}`, params)

    const newRows = data.rows.map((item, index) => {
      item.id = index
      item.message = ''
      return item
    })

    return {
      ...data,
      rows: newRows
    }
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export const updateAlerts = async (params) => {
  try {
    return  await http.put('/alerts/read', params)
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

export const addNewRule = async (params) => {
  try {
    return  await http.post('/rules', params)
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

export const getAllRules = async () => {
  try {
    const { data } = await http.get('/rules/list')
    return data
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

export const updateRule = async (id, params) => {
  try {
    return  await http.put(`/rules/${id}`, params)
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

export const deleteRule = async (id) => {
  try {
    return  await http.delete(`/rules/${id}`)
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

export const putSosStatusAlerts = async (alertNo, params) => {
  try {
    return  await http.put(`/alerts/${alertNo}/sos-status`, params)
  } catch (error) {
    return {
      status: 'error'
    }
  }
}