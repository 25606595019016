// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconDatabase = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M12 11C14.5 11 16.625 10.6083 18.375 9.825C20.125 9.04167 21 8.1 21 7C21 5.9 20.125 4.95833 18.375 4.175C16.625 3.39167 14.5 3 12 3C9.5 3 7.375 3.39167 5.625 4.175C3.875 4.95833 3 5.9 3 7C3 8.1 3.875 9.04167 5.625 9.825C7.375 10.6083 9.5 11 12 11ZM12 13.5C12.6833 13.5 13.5377 13.429 14.563 13.287C15.5883 13.145 16.5757 12.916 17.525 12.6C18.475 12.2833 19.2917 11.871 19.975 11.363C20.6583 10.855 21 10.234 21 9.5V12C21 12.7333 20.6583 13.3543 19.975 13.863C19.2917 14.3717 18.475 14.784 17.525 15.1C16.575 15.4167 15.5877 15.646 14.563 15.788C13.5383 15.93 12.684 16.0007 12 16C11.3167 16 10.4623 15.929 9.437 15.787C8.41167 15.645 7.42467 15.416 6.476 15.1C5.526 14.7833 4.70933 14.371 4.026 13.863C3.34267 13.355 3.00067 12.734 3 12V9.5C3 10.2333 3.34167 10.8543 4.025 11.363C4.70833 11.8717 5.525 12.284 6.475 12.6C7.425 12.9167 8.41267 13.146 9.438 13.288C10.4633 13.43 11.3173 13.5007 12 13.5ZM12 18.5C12.6833 18.5 13.5377 18.429 14.563 18.287C15.5883 18.145 16.5757 17.916 17.525 17.6C18.475 17.2833 19.2917 16.8707 19.975 16.362C20.6583 15.8533 21 15.2327 21 14.5V17C21 17.7333 20.6583 18.3543 19.975 18.863C19.2917 19.3717 18.475 19.784 17.525 20.1C16.575 20.4167 15.5877 20.646 14.563 20.788C13.5383 20.93 12.684 21.0007 12 21C11.3167 21 10.4623 20.929 9.437 20.787C8.41167 20.645 7.42467 20.416 6.476 20.1C5.526 19.7833 4.70933 19.3707 4.026 18.862C3.34267 18.3533 3.00067 17.7327 3 17V14.5C3 15.2333 3.34167 15.8543 4.025 16.363C4.70833 16.8717 5.525 17.284 6.475 17.6C7.425 17.9167 8.41267 18.146 9.438 18.288C10.4633 18.43 11.3173 18.5007 12 18.5Z'/>
    </SvgIcon>
  )
}

export default IconDatabase