// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  buttonFilterEvidence: {
    backgroundColor: colors.athensGray,
    border: `1px solid ${colors.alto}`,
    boxShadow: 'none',
    color: theme.palette.common.black,
    borderRadius: 2,
    fontSize: 12,
    padding: '4.5px 8px 4.5px 10px',
    '& .MuiButton-startIcon': {
      marginRight: 4,
      marginLeft: 0,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.text.secondary,
      fontSize: 16,
    },
    '&:hover': {
      backgroundColor: colors.athensGray,
      boxShadow: 'none'
    },
    '&.active': {
      backgroundColor: colors.linkWater,
      color: colors.astral,
      borderColor: colors.astral
    }
  },
  badgeFilter: {
    backgroundColor: colors.astral,
    borderRadius: '50%',
    height: 16,
    width: 16,
    color: theme.palette.common.white,
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 10,
    marginLeft: 4,
  },
  dialogFilterEvidence: {
    zIndex: theme.zIndex.fab
  },
  dialogFilterEvidenceBox: {
    backgroundColor: colors.athensGray,
    width: 388,
    padding: '16px 12px 8px 12px',
    boxShadow: `0px 0px 8px 0px ${alpha(theme.palette.common.black, 0.16)}`,
    borderRadius: 4,
  },
  placeholderSelect: {
    fontSize: 12,
    color: colors.spunPearl
  },
  menuSelectFilter: {
    padding: 4,
    width: 104,

  },
  itemSelectFilter: {
    padding: '4px 8px',
    borderRadius: 0,
    fontSize: 12,
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      color: theme.palette.text.secondary,
      fontSize: 16,
      marginRight: 4,
    },
    '&:hover': {
      backgroundColor: colors.linkWater
    },
    '&.Mui-selected': {
      backgroundColor: colors.linkWater
    }
  },
  selectFilter: {
    borderRadius: 2,
    '& .MuiSelect-select': {
      backgroundColor: theme.palette.common.white,
      fontSize: 12,
      padding: 8,
    },
    '& .MuiSelect-icon': {
      color: colors.spunPearl
    }
  },
  paperSelectFilter: {
    borderRadius: 2,
    marginTop: 4,
    boxShadow: `0px 5px 8px 0px ${alpha(theme.palette.common.black, 0.06)}`
  },
  btnAddCondition: {
    fontSize: 12,
    fontWeight: 600,
    width: 'fit-content',
    padding: 0,
    marginTop: 8,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiButton-startIcon': {
      marginRight: 4,
      marginLeft: 0,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 16,
    },
  },
  btnCancel: {
    fontWeight: 700,
    color: theme.palette.text.secondary,
    fontSize: 14,
    padding: '8px 16px'
  },
  btnSave: {
    fontWeight: 700,
    fontSize: 14,
    padding: '8px 16px'
  },
  autoCompleteInput: {
    '& .MuiOutlinedInput-root': {
      padding: 2,
      borderRadius: 2,
      backgroundColor: theme.palette.common.white,
      fontSize: 12,
      paddingRight: '8px!important',
    },
    '& .MuiButtonBase-root': {
      display: 'none'
    }
  },
  paperAutocomplete: {
    borderRadius: 2,
    marginTop: 4,
    padding: 4,
    boxShadow: `0px 5px 8px 0px ${alpha(theme.palette.common.black, 0.06)}`,
    '& .MuiAutocomplete-listbox': {
      padding: 0,
      maxHeight: 240,
    },
    '& .MuiAutocomplete-noOptions': {
      fontSize: 12,
    }
  },
  autocompleteItem: {
    height: 28,
    fontSize: 12,
    padding: '0 8px',
    '&:hover': {
      backgroundColor: colors.linkWater
    }
  },
  datePickerInput: {
    backgroundColor: theme.palette.common.white,
    '& .MuiSvgIcon-root': {
      fontSize: 16,
      color: colors.spunPearl
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 2,
    },
    '& .MuiOutlinedInput-input': {
      padding: '9.4px 0 9.4px 8px',
      fontSize: 12,
    },
    '& .MuiInputAdornment-root': {
      marginLeft: 0,
    }
  },
}))

export default useStyles