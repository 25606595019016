// MUI ICONS
import IconBookmark from '@mui/icons-material/Bookmark'
import IconMap from '@mui/icons-material/Map'
import IconPhoneAndroid from '@mui/icons-material/PhoneAndroid'

// PATH ICONS
import IconBoolean from 'assets/images/pathIcons/IconBoolean'
import IconDiversity from 'assets/images/pathIcons/IconDiversity'
import IconSos from 'assets/images/pathIcons/IconSos'
import IconText from 'assets/images/pathIcons/IconText'

export const savedFiltersText = 'Filter yang disimpan'

export const initialfilterOptionList = [
  {
    icon: IconSos,
    label: 'Laporan SOS',
    field: 'SOS_STATUS',
    children: [
      {
        icon: IconBoolean,
        label: 'SOS',
      },
    ],
  },
  {
    icon: IconBookmark,
    label: `${savedFiltersText} (0)`,
    caption: 'Belum ada filter yang disimpan',
    children: [
      // {
      //   icon: IconBookmark,
      //   label: 'Saved Filter 1',
      // },
    ],
  },
  {
    icon: IconDiversity,
    label: 'Jenis File',
    field: 'MEDIA',
    caption: '',
    children: [
      {
        icon: IconText,
        label: 'Foto',
      },
      {
        icon: IconText,
        label: 'Video',
      },
      {
        icon: IconText,
        label: 'Audio',
      },
    ],
  },
  {
    icon: IconDiversity,
    label: 'Tag Kejadian',
    field: 'TAG',
    caption: '5 nilai teratas',
    children: [
      // {
      //   icon: IconText,
      //   label: 'Kejadian 1',
      // },
    ],
  },
  {
    icon: IconMap,
    label: 'Lokasi',
    field: 'LOCATION',
    caption: '5 nilai teratas',
    children: [
      // {
      //   icon: IconText,
      //   label: 'Lokasi 1',
      // },
    ],
  },
  {
    icon: IconPhoneAndroid,
    label: 'Perangkat',
    field: 'DEVICE',
    caption: '5 nilai teratas',
    children: [
      // {
      //   icon: IconText,
      //   label: 'Perangkat 1',
      // },
    ],
  },
]