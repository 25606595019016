// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  tooltipClustering: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    padding: 8,
    width: 108
  },
  tooltipContent: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginTop: 4,
  },
  tooltipCircle: {
    height: 8,
    width: 8,
    borderRadius: '50%',
    marginRight: 4,
  },
  tooltipEvidenceText: {
    flex: 1,
  },
  tooltipEvidenceNumber: {
    fontWeight: 700,
  }
}))

export default useStyles