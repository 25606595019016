import { useState } from 'react'

// COMPONENTS
import CustomTabs from 'components/Customs/CustomTabs'
import CustomTab from 'components/Customs/CustomTab'
import TrendActivity from './TrendActivity'
import TrendEvidences from './TrendEvidences'

// MUIS
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'

// STYLES
import useStyles from './panelChartUseStyles'

const PanelChart = () => {
  const classes = useStyles()

  const listTabs = ['Tren Kejadian', 'Tren Keaktifan']

  const [selectedTab, setSelectedTab] = useState(listTabs[0])
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Stack className={classes.root}>
      {/* TABS */}
      <CustomTabs
        value={selectedTab}
        onChange={(event, newValue) => setSelectedTab(newValue)}
        className={classes.tabs}
      >
        {listTabs.map((value, index) => (
          <CustomTab
            key={index}
            className={classes.tabItem}
            value={value}
            label={value}
          />
        ))}
      </CustomTabs>

      <Divider />

      <Stack marginTop='16px' flex={1}>
        {selectedTab === listTabs[0]
          ? <TrendEvidences setIsLoading={setIsLoading} />
          : <TrendActivity />
        }
      </Stack>

      {/* LOADING */}
      {isLoading && <Stack className={classes.loadingContainer}>
        <CircularProgress className={classes.loading} />
      </Stack>}
    </Stack>
  )
}

export default PanelChart