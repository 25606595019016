import { useRef } from 'react'
import PropTypes from 'prop-types'

// MUIS
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

const CellChannels = (props) => {
  const { list } = props

  const wrapperRef = useRef()

  const calculateAndCutValue = () => {
    if(wrapperRef.current) {
      const totalMaxString = wrapperRef.current?.offsetWidth / 10
      const restructureVal = list?.map(item => item.replace(/\,/g, ';'))
      const shownData = restructureVal?.toString()?.substring(0, totalMaxString)?.split(',')?.filter(item => {
        const isValidName = list?.find(itemCheck => itemCheck.replace(/\,/g, ';') === item)
        return Boolean(isValidName)
      })

      const maxLimitValues = shownData.length

      return (
        <Stack 
          direction='row'
          spacing='4px'
          alignItems='center'
        >
          {/* CHIP LIST */}
          {shownData.map((item, index) => (
            <Chip
              key={index}
              label={item} 
              variant='outlined'
              size='small'
            />
          ))}

          {/* TOTAL OF THE REST TEXT */}
          {list.length > maxLimitValues && 
          <Typography variant='inherit'>
            +{list.length - maxLimitValues}
          </Typography>}
        </Stack>
      )
    } else return (<>
      {list.length >= 1
        ? list.map(item => item).toString().replace(/\,/g, ', ').replace(/\;/g, ',')
        : 'Default'}
    </>)
  }

  return (
    <Stack 
      ref={wrapperRef} 
      direction='row' 
      alignItems='center' 
      width='100%'
    >
      {calculateAndCutValue()}
    </Stack>
  )
}

CellChannels.defaultProps = {
  list: [],
}

CellChannels.propTypes = {
  list: PropTypes.array.isRequired,
}

export default CellChannels