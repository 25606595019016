// MUIS
import Stack from '@mui/material/Stack'

// STYLES
import useStyles from './markerEvidenceUseStyles'

const MarkerEvidence = (props) => {
  const { calculateSize } = props

  const classes = useStyles()

  return (
    <Stack
      className={classes.root}
      style={{
        height: `${calculateSize}px`,
        width: `${calculateSize}px`,
      }}
    ></Stack>
  )
}

export default MarkerEvidence