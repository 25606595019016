// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconHashtag = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M16.1081 11.0578L15.7634 12.9883H18.5557V15.9071H15.2233L14.373 20.4001H11.2934L12.1322 15.9071H10.2017L9.38584 20.4001H6.31771L7.14507 15.9071H4.55957V12.9883H7.70813L8.06436 11.0578H5.32947V8.07014H8.62742L9.45478 3.6001H12.5344L11.6841 8.07014H13.5916L14.4189 3.6001H17.4986L16.6597 8.07014H19.2912V11.0578H16.1081ZM10.7763 12.9883H12.6723L13.0285 11.0578H11.121L10.7763 12.9883Z' />
    </SvgIcon>
  )
}

export default IconHashtag