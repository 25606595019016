import React, { createContext, useState } from 'react'

// CONSTANTS
import { values } from 'constants/values'

// MUIS
import useMediaQuery from '@mui/material/useMediaQuery'

const AllPagesContext = createContext()

const AllPagesContextProvider = (props) => {
  // BREAKPOINT
  const isXsScreen = useMediaQuery((theme) => theme.breakpoints.only('xs'))
  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.only('sm'))
  const isMdScreen = useMediaQuery((theme) => theme.breakpoints.only('md'))
  const isLgScreen = useMediaQuery((theme) => theme.breakpoints.only('lg'))
  const isXlScreen = useMediaQuery((theme) => theme.breakpoints.only('xl'))

  let breakpointType
  isXsScreen && (breakpointType = 'xs')
  isSmScreen && (breakpointType = 'sm')
  isMdScreen && (breakpointType = 'md')
  isLgScreen && (breakpointType = 'lg')
  isXlScreen && (breakpointType = 'xl')

  console.log('breakpointType: ', breakpointType)

  // SNACKBAR
  const [ snackbarObject, setSnackbarObject ] = useState(values.initialSnackbarObject)

  // DASHBOARD
  const [isUpdateEvidenceList, setIsUpdateEvidenceList] = useState(true)

  // PAGE AGENT HELP
  const [agentHelpAllData, setAgentHelpAllData] = useState([])
  const [agentHelpNeedHelp, setAgentHelpNeedHelp] = useState([])
  const [agentHelpOnVideoCall, setAgentHelpOnVideoCall] = useState([])

  // PAGE LIVE STREAMING
  const [ liveStreamingList, setLiveStreamingList ] = useState([])

  // PAGE CONFERENCE
  const [ conferenceList, setConferenceList ] = useState([])

  // PAGE LIVE
  const [ badgeType, setBadgeType ] = useState (null)

  // MAP
  const [mapMarkerPieChart, setMapMarkerPieChart] = useState(false)
  
  // USER
  const [ userProfile, setUserProfile ] = useState(localStorage.getItem('user') 
    ? JSON.parse(localStorage.getItem('user')) 
    : {})

  return (
    <AllPagesContext.Provider
      value={{
        breakpointType,
        snackbarObject, setSnackbarObject,
        agentHelpAllData, setAgentHelpAllData,
        agentHelpNeedHelp, setAgentHelpNeedHelp,
        agentHelpOnVideoCall, setAgentHelpOnVideoCall,
        liveStreamingList, setLiveStreamingList,
        isUpdateEvidenceList, setIsUpdateEvidenceList,
        conferenceList, setConferenceList,
        badgeType, setBadgeType,
        mapMarkerPieChart, setMapMarkerPieChart,
        userProfile, setUserProfile,
      }}
    >
      {props['children']}
    </AllPagesContext.Provider>
  )
}

export { AllPagesContextProvider, AllPagesContext }