import { useState, useEffect } from 'react'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'
import CustomInput from 'components/Customs/CustomInput'

// MUIS
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconArrowDropDown from '@mui/icons-material/ArrowDropDown'
import IconArrowDropUp from '@mui/icons-material/ArrowDropUp'
import IconClear from '@mui/icons-material/Clear'
import IconSearch from '@mui/icons-material/Search'

// STYLES
import useStyles from './menuAutocompleteUseStyles'

const MenuAutocomplete = (props) => {
  const { 
    isWithLabel,
    label,
    isRequired,
    initialOptionList, 
    selectedValue, setSelectedValue,
    menuClassName,
    onMenuClose,
  } = props

  const classes = useStyles()

  const [ menuAnchorEl, setMenuAnchorEl ] = useState(null)
  const [ optionList, setOptionList ] = useState(initialOptionList)
  const [ search, setSearch ] = useState('')

  const handleOptionClick = (option) => {
    setSearch('')
    setSelectedValue(option)
    setMenuAnchorEl(null)
    onMenuClose && onMenuClose(option)
  }
  
  useEffect(() => {
    const newOptionList = initialOptionList.map(item => {
      if (search === '') item.isShown = true
      else {
        if (item.label.toLowerCase().includes(search.toLowerCase())) item.isShown = true
        else item.isShown = false
      }
      
      return {
        ...item,
      }
    })

    setOptionList(newOptionList)
  }, [search, initialOptionList])

  return (
    <>
      {/* FORM CONTROL */}
      <FormControl 
        fullWidth
        required={isRequired} 
        variant='standard'
      >
        {/* LABEL */}
        {isWithLabel &&
        <InputLabel shrink>
          {label}
        </InputLabel>}

        {/* INPUT */}
        <CustomInput
          type='text'
          endAdornment={
            <InputAdornment position='end'>
              {Boolean(menuAnchorEl) ? <IconArrowDropUp/> : <IconArrowDropDown/>}
            </InputAdornment>
          }
          onClick={(event) => setMenuAnchorEl(event.currentTarget)}
          value={selectedValue?.label ?? 'Belum diatur'}
          onChange={() => {}}
        />
      </FormControl>

      {/* MENU */}
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
        className={`${classes.menu} ${menuClassName}`}
      >
        {/* MENU TITLE */}
        <Typography
          fontWeight={600}
          padding='16px'
        >
          Pilih {label}
        </Typography>

        <Divider/>

        {/* SEARCH INPUT */}
        <CustomInput
          className={classes.searchInput}
          variant='standard'
          placeholder='Pencarian Cepat'
          type='text'
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          onClick={(event) => event.stopPropagation()}
          onKeyDown={(event) => event.stopPropagation()}
          disableUnderline
          fullWidth
          endAdornment={
            <CustomTooltipBlack
              title='Pencarian Cepat'
              placement='bottom'
            >
              <IconButton 
                size='small'
                onClick={() => search !== '' && setSearch('')}
              >
                {search === '' 
                  ? <IconSearch fontSize='small'/> 
                  : <IconClear fontSize='small'/>
                }
              </IconButton>
            </CustomTooltipBlack>
          }
        />

        <Divider/>

        {/* OPTION LIST */}
        <List disablePadding>
          {optionList.map((item, index) => (
            item.isShown &&
            <ListItemButton 
              key={index}
              onClick={() => handleOptionClick(item)}
            >
              <ListItemText 
                primary={item.label}
                className={selectedValue?.label === item.label 
                  ? classes.optionListItemActive 
                  : ''
                }
              />
            </ListItemButton>
          ))}
        </List>
      </Menu>
    </>
  )
}

export default MenuAutocomplete