// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 24,
    position: 'relative',
  },
  contentContainer: {
    position: 'relative',
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  groupingRow: {
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  columnStatus: {
    font: 'inherit',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    textTransform: 'none',
    '&::before': {
      content: '""',
      width: 10,
      height: 10,
      borderRadius: '50%',
      marginRight: 10,
    },
  },
  normalText: {
    textTransform: 'capitalize',
  },
  columnActions: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    zIndex: 2,
  },
}))

export default useStyles
