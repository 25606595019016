import { useContext } from 'react'

// COMPONENTS
import CarouselMediaWrapper from './CarouselMediaWrapper'

// CONTEXTS
import { PageEvidencesContext } from 'contexts/PageEvidencesContext'

// MUIS
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import TablePagination from '@mui/material/TablePagination'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './evidencesGridUseStyles'

const EvidencesGrid = () => {
  const classes = useStyles()

  const {
    evidenceList,
    totalEvidences,
    pageEvidences, setPageEvidences,
    rowsPerPageEvidences, setRowsPerPageEvidences,
  } = useContext(PageEvidencesContext)

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPageEvidences(parseInt(event.target.value, 10))
    setPageEvidences(0)
  }
  return (
    <Stack flex={1}>
      {/* EVIDENCES CONTAINER */}
      <Stack
        overflow='auto'
        flex={1}
        padding='16px'
      >
        {/* EVIDENCES GRID */}
        <Grid 
          columns={{ xs: 1, sm: 1, md: 2, lg: 4, xl: 4, xxl: 6, xxxl: 8 }}
          container
          spacing='16px'
          rowGap='16px'
          height={0}
        >
          {evidenceList.map((item, index) => (
            (item.medias?.length > 0 || item.sosStatus) &&
            <Grid
              key={index}
              item
              xs={1}
              className={classes.evidenceItem}
            >
              <CarouselMediaWrapper evidenceObject={item}/>
            </Grid>
          ))}
        </Grid>
      </Stack>
      
      {/* PAGINATION CONTAINER */}
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        {/* TOTAL TEXT */}
        <Typography 
          variant='caption'
          marginLeft='16px'
        >
          Total: {totalEvidences}
        </Typography>

        {/* PAGINATION */}
        <TablePagination
          component='div'
          count={totalEvidences}
          page={pageEvidences}
          onPageChange={(event, newPage) => setPageEvidences(newPage)}
          rowsPerPage={rowsPerPageEvidences}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={classes.pagination}
        />
      </Stack>
    </Stack>
  )
}

export default EvidencesGrid