import { useState, useEffect, useContext } from 'react'

// COMPONENTS
import { customClusterMarker } from 'components/MarkerClusterCircle/MarkerClusterCircle'
import MarkerEvidence from '../MarkerEvidence/MarkerEvidence'
import MarkerObject from '../MarkerObject/MarkerObject'
import ShowMenuLeaflet from 'components/ShowMenuLeaflet/ShowMenuLeaflet'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// LEAFLET
import L from 'leaflet'
import 'leaflet.markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

// STYLES
import useStylesMarkerEvidence from '../MarkerEvidence/markerEvidenceUseStyles'

// UTILS
import { filterDataByProvince, markersLabelShowHide, regionalClusters } from './clustersUtils'

const ClustersByLocation = () => {
  const {
    evidenceList, objectMarkerList, map, setObjectDetail, setIsPanelLeftShown,
    panByNegative, selectedObjectId, setSelectedObjectId, markerClustersLocationRef,
    dispatchEvidenceList, selectionModel, isPanelLeftShown, markerSettings
  } = useContext(PageTrackingContext)
  const classesMarkerEvidence = useStylesMarkerEvidence()

  // STATES
  const [clusterEvent, setClusterEvent] = useState()
  const [isShowMenu, setIsShowMenu] = useState(false)

  const markerClusterConfig = (inputColor) => {
    return L.markerClusterGroup({
      chunkedLoading: true,
      spiderfyDistanceMultiplier: 0,
      spiderfyOnMaxZoom: false,
      showCoverageOnHover: false,
      maxClusterRadius: 80,
      zoomToBoundsOnClick: false,
      iconCreateFunction: (cluster) => customClusterMarker(cluster, map, false, inputColor),
    })
  }

  const clearAllLayersMarker = () => {
    regionalClusters.forEach(itemProvince => {
      if (markerClustersLocationRef.current[itemProvince.province]) markerClustersLocationRef.current[itemProvince.province]?.clearLayers()
    })
  }

  const generateMakerByProvince = () => {
    const filteredObjectMarkerList = selectionModel?.length ? objectMarkerList.filter(item => {
      const findId = selectionModel.find(selectedId => selectedId === item.id)
      return Boolean(findId)
    }) : objectMarkerList

    const filterDataEvidences = filterDataByProvince(evidenceList, regionalClusters, 'evidences')
    const filterDataObjects = filterDataByProvince(filteredObjectMarkerList, regionalClusters, 'objects')
    const filterDataDispatches = filterDataByProvince(dispatchEvidenceList, regionalClusters, 'dispatches')

    let generateEvidences, generateObjects, generateDispatches

    // GENERATE EVIDENCES
    if (filterDataEvidences?.length) {
      // GENERATE INSTANCES MARKERS EVIDENCES
      generateEvidences = filterDataEvidences?.map(itemProvince => {
        const provinceMarkers = itemProvince?.data?.map(itemData => {
          return MarkerEvidence(
            itemData,
            setIsPanelLeftShown,
            classesMarkerEvidence,
            itemData.sosStatus ? 'SOS' : 'evidence',
            isPanelLeftShown?.data?.evidenceNo,
            itemProvince.color
          )
        })

        return {
          ...itemProvince,
          instanceMarkers: provinceMarkers
        }
      })
    }

    // GENERATE OBJECTS
    if (filterDataObjects?.length) {
      // GENERATE INSTANCES MARKERS EVIDENCES
      generateObjects = filterDataObjects?.map(itemProvince => {
        const provinceMarkers = itemProvince?.data?.map(itemData => {
          if (itemData?.state?.gps?.location?.lat && itemData?.state?.gps?.location?.lng) {
            return MarkerObject(
              itemData, setObjectDetail,
              setIsPanelLeftShown,
              panByNegative, map,
              selectedObjectId, setSelectedObjectId,
              classesMarkerEvidence, itemProvince.color
            )
          }
        })

        return {
          ...itemProvince,
          instanceMarkers: provinceMarkers
        }
      })
    }

    // GENERATE DISPATCHES
    if (filterDataEvidences?.length) {
      // GENERATE INSTANCES MARKERS DISPATCHES
      generateDispatches = filterDataDispatches?.map(itemProvince => {
        const provinceMarkers = itemProvince?.data?.map(itemData => {
          return MarkerEvidence(
            itemData,
            setIsPanelLeftShown,
            classesMarkerEvidence,
            'dispatch',
            null,
            itemProvince.color
          )
        })

        return {
          ...itemProvince,
          instanceMarkers: provinceMarkers
        }
      })
    }

    // CREATE INSTANCES CLUSTER BY PROVINCE
    if (regionalClusters?.length) {
      regionalClusters.forEach(itemProvince => {
        if (markerClustersLocationRef.current[itemProvince.province]) markerClustersLocationRef.current[itemProvince.province]?.clearLayers()
        else if (!markerClustersLocationRef.current[itemProvince.province]) {
          markerClustersLocationRef.current[itemProvince.province] = markerClusterConfig(itemProvince.color)
          markerClustersLocationRef.current[itemProvince.province].on('clusterclick', (event) => setClusterEvent({
            ...event,
            color: itemProvince.color,
            title: itemProvince.policeRegional
          }))

        }
      })
    }

    // ADD DATA INSTANCES MARKER TO MAP
    Object?.keys(markerClustersLocationRef.current)?.forEach(nameProvince => {
      const evidencesInstance = generateEvidences?.find(item => item.province === nameProvince)?.instanceMarkers
      const objectsInstance = generateObjects?.find(item => item.province === nameProvince)?.instanceMarkers
      const DispatchesInstance = generateDispatches?.find(item => item.province === nameProvince)?.instanceMarkers


      if (evidencesInstance) {
        markerClustersLocationRef.current[nameProvince].addLayers(evidencesInstance)
      }
      if (objectsInstance) {
        markerClustersLocationRef.current[nameProvince].addLayers(objectsInstance)
      }
      if (objectsInstance) {
        markerClustersLocationRef.current[nameProvince].addLayers(DispatchesInstance)
      }

      markerClustersLocationRef.current[nameProvince]?.addTo(map)
    })

    // HIDE LABELS
    regionalClusters.forEach(itemProvince => {
      if (markerClustersLocationRef.current[itemProvince.province]) markersLabelShowHide(markerClustersLocationRef.current[itemProvince.province], markerSettings)
    })
  }

  useEffect(() => {
    if (map && markerSettings.isClusteringLocation) generateMakerByProvince()
    else clearAllLayersMarker()
  }, [map, evidenceList, objectMarkerList, dispatchEvidenceList, markerSettings, selectionModel])

  useEffect(() => {
    if(clusterEvent) {
      let tempObject = []
      let tempEvidenceHelp = []
      let tempEvidencePending = []
      let tempEvidenceResolved = []
      let tempDispacthes = []

      // GET CHILD OF CLUSTER AND PUSH TO TEMP
      clusterEvent.layer.getAllChildMarkers().map(item => {
        if(item.options.objectData?.status === 'OBJECT') {
          tempObject.push({
            id: item.options.objectData.id,
            text: item.options.objectData.label,
            status: item.options.objectData.status,
            dataDetail: item.options.objectData
          })
        } else if(item.options.objectData?.status === 'NEEDED_HELP') {
          tempEvidenceHelp.push({
            id: item.options.objectData.evidenceNo,
            text: item.options.objectData.description,
            status: item.options.objectData.status,
            lat: parseFloat(item.options.objectData.latitude),
            lng: parseFloat(item.options.objectData.longitude),
          })
        } else if(item.options.objectData?.status === 'PENDING') {
          tempEvidencePending.push({
            id: item.options.objectData.evidenceNo,
            text: item.options.objectData.description,
            status: item.options.objectData.status,
            lat: parseFloat(item.options.objectData.latitude),
            lng: parseFloat(item.options.objectData.longitude),
          })
        } else if(item.options.objectData?.status === 'RESOLVED') {
          tempEvidenceResolved.push({
            id: item.options.objectData.evidenceNo,
            text: item.options.objectData.description,
            status: item.options.objectData.status,
            lat: parseFloat(item.options.objectData.latitude),
            lng: parseFloat(item.options.objectData.longitude),
          })
        } else if (item?.options?.objectData?.dispatchNo) {
          tempDispacthes.push({
            id: item.options.objectData.dispatchNo,
            text: item.options.objectData.label,
            status: 'DISPATCHES',
            lat: parseFloat(item.options.objectData.address.latitude),
            lng: parseFloat(item.options.objectData.address.longitude),
            data: item.options.objectData
          })
        }
      })

      setIsShowMenu({
        title: clusterEvent?.title,
        mouseEvent: clusterEvent,
        dataCluster: [...tempObject, ...tempEvidenceHelp, ...tempEvidencePending, ...tempEvidenceResolved, ...tempDispacthes]
      })
    }
  }, [clusterEvent])

  return (
    <>
      <ShowMenuLeaflet
        isShowMenu={isShowMenu}
        setIsShowMenu={setIsShowMenu}
        inPage='TRACKING'
        setIsPanelLeftShown={setIsPanelLeftShown}
        setObjectDetail={setObjectDetail}
        setIsObjectDetailPanelShown={setIsPanelLeftShown}
        map={map}
      />
    </>
  )
}

export default ClustersByLocation