// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'
import { alpha } from '@mui/material/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: 16,
    overflowY: 'auto',
    flex: 1,
    height: '100%',
    '& .customTooltipLineStack': {
      padding: '8px !important',
      borderRadius: '4px !important',
      border: 'none !important',
      maxWidth: '280px !important',
      '& div': {
        textWrap: 'wrap'
      } 
    }
  },
  cardHeader: {
    backgroundColor: colors.wildSand,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
    padding: '14px 16px',
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap'
  },
  cardChart: {
    border: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    boxShadow: `0 0 8px ${alpha(theme.palette.common.black, 0.08)}`
  },
  cardContent: {
    padding: '24px 16px',
  },
  tabs: {
    flex: 0,
    '& .MuiTabs-indicator': {
      height: 2,
    }
  },
  tabItem: {
    '&.MuiTab-root': {
      height: 48,
      fontSize: 14,
    },
    '&.MuiTab-root.Mui-selected': {
      fontWeight: 600,
    },
  },
  objectDataGrid: {
    '& .MuiDataGrid-columnHeaders': {
      borderLeft: '4px solid transparent !important'
    },
    '& .MuiDataGrid-cell[data-colindex="1"]': {
      minWidth: '360px !important'
    }
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 2,
    backgroundColor: alpha('#FFFFFF', 0.7),
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  loading: {
    width: '48px !important',
    height: '48px !important',
  },
}))

export default useStyles