import React, { useContext } from 'react'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'

// MUI ICONS
import IconList from '@mui/icons-material/List'

// STYLES
import useStyles from './panelToogleUseStyles'

const PanelToggle = (props) => {
  const {
    isPanelShown, 
    setIsPanelShown,
    title,
    direction,
    valueShown
  } = props

  const { pageRef } = useContext(PageTrackingContext)

  const classes = useStyles()

  return (
    <Slide
      direction={direction}
      in={!isPanelShown} 
      container={pageRef.current}
    >
      <Box className={classes.root}>
        <CustomTooltipBlack
          title={title}
          placement='bottom'
        >
          <IconButton onClick={() => setIsPanelShown(valueShown ? valueShown : true)} disableFocusRipple disableRipple>
            <IconList/>
          </IconButton>
        </CustomTooltipBlack>
      </Box>
    </Slide>
  )
}

PanelToggle.defaultProps = {
  direction: 'right'
}

export default PanelToggle