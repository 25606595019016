// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconTrafficCone = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M21.75 19.5H20.0334L14.3831 3.25781C14.2809 2.96352 14.0896 2.70836 13.8357 2.52776C13.5819 2.34716 13.2781 2.25008 12.9666 2.25H11.0334C10.722 2.25001 10.4183 2.34696 10.1645 2.52738C9.91064 2.7078 9.71926 2.96276 9.61687 3.25688L3.96656 19.5H2.25C2.05109 19.5 1.86032 19.579 1.71967 19.7197C1.57902 19.8603 1.5 20.0511 1.5 20.25C1.5 20.4489 1.57902 20.6397 1.71967 20.7803C1.86032 20.921 2.05109 21 2.25 21H21.75C21.9489 21 22.1397 20.921 22.2803 20.7803C22.421 20.6397 22.5 20.4489 22.5 20.25C22.5 20.0511 22.421 19.8603 22.2803 19.7197C22.1397 19.579 21.9489 19.5 21.75 19.5ZM8.94656 9.75H15.0534L16.6191 14.25H7.38094L8.94656 9.75Z' />
    </SvgIcon>
  )
}

export default IconTrafficCone