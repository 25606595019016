import React, { useState, useEffect, useContext, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

// COMPONENTS
import LoadingBox from 'components/LoadingBox/LoadingBox'
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import DialogAddOrEditVehicle from './DialogAddOrEditVehicle/DialogAddOrEditVehicle'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import PageHeader from 'components/PageHeader/PageHeader'
import SimpleBackdrop from 'components/Partials/SimpleBackdrop'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// CONSTANTS
import { iconListDutyTypes } from 'constants/icons'
import { basePaths } from 'constants/paths'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconDelete from '@mui/icons-material/Delete'
import IconEdit from '@mui/icons-material/Edit'

// RAMDA
import { isEmpty, reject } from 'ramda'

// SERVICES
import { getVehicleList, deleteVehicle } from 'services/vehicleServices'

// STORES
import { showAlert } from 'store/MainReducer'

// STYLES
import useStyles from './vehiclesUseStyles'

const Vehicles = () => {
  const classes = useStyles()

  const { setSnackbarObject } = useContext(AllPagesContext)

  const pageRef = useRef()

  const dispatch = useDispatch()

  const history = useHistory()
  const location = useLocation()

  const initialFilters = {
    /*status: '',*/
    vehicleName: '',
    deviceObjectId: '',
    plateNo: '',
    description: '',
    machineNumber: '',
  }

  const groupByList = [
    {
      title: 'Don\'t Group',
      value: null,
    },
    /*{
      title: 'By Status',
      value: 'status',
    },*/
  ]

  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState(null)
  const [orderBy, setOrderBy] = useState(null)
  const [size, setPageSize] = useState(5)
  const [vehiclesTableData, setVehiclesTableData] = useState([])
  const [dialogAddVehicle, setDialogAddVehicle] = useState(null)
  const [filters, setFilters] = useState(initialFilters)
  const [selectedGroupBy, setSelectedGroupBy] = useState(groupByList[0])
  const [isFilterOn, setIsFilterOn] = useState(false)
  const [search, setSearch] = useState('')
  const [dialogEditVehicle, setDialogEditVehicle] = useState(null)
  const [dialogDeleteVehicle, setDialogDeleteVehicle] = useState({})
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [selectionModel, setSelectionModel] = useState([])

  const handleEditIconButtonClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()
    history.push(
      `${basePaths.deviceVehicles}?mode=edit&id=${inputParams.id}`, 
      {
        id: inputParams.id,
        row: inputParams.row,
      }
    )
  }

  const handleDeleteIconButtonClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()
    setDialogDeleteVehicle(inputParams)
  }

  const initialColumns = [
    /*{
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 150,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => {
        let className = classes.columnStatus
        if (params.value === 'ONLINE')
          className = `${classes.columnStatus} ${classes.columnStatusGreen}`
        else if (params.value === 'OFFLINE')
          className = `${classes.columnStatus} ${classes.columnStatusRed}`

        return (
          params.value &&
          params.value !== 'Not Specified' && (
            <Typography className={className} noWrap>
              {params.value.toLowerCase()}
            </Typography>
          )
        )
      },
    },*/
    {
      field: 'vehicleName',
      headerName: 'Nama Kendaraan',
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'deviceObjectLabel',
      headerName: 'Objek',
      flex: 1,
      minWidth: 150,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'plateNo',
      headerName: 'Kendaraan',
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => {
        if (!Number.isInteger(params.id)) return null
        const Icon = params.row.vehicleTypeIconCode ? iconListDutyTypes[Number(params.row.vehicleTypeIconCode) - 1]?.Icon : null

        return (
          <Box className={classes.columnVehicle}>
            {/* VEHICLE ICON */}
            <Icon className={classes.columnVehicleIcon}/>

            <Box className={classes.columnWidth}>
              {/* PLATE NUMBER TEXT */}
              <Typography
                variant='subtitle2'
                className={classes.columnVehicleText}
                noWrap
              >
                {params.row.plateNo}
              </Typography>

              {/* VEHICLE OBJECT TEXT */}
              <Typography
                variant='subtitle2'
                className={classes.columnVehicleText}
                noWrap
              >
                {`${params.row.brand ? params.row.brand : '-'} // ${
                  params.row.model ? params.row.model : '-'
                } // ${
                  params.row.productionYear ? params.row.productionYear : '-'
                }`}
              </Typography>
            </Box>
          </Box>
        )
      },
    },
    {
      field: 'machineNumber',
      headerName: 'Nomor Pengenal',
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => (
        <Box className={classes.columnWidth}>
          {/* SKELETON NUMBER TEXT */}
          <Typography
            variant='subtitle2'
            className={classes.columnVehicleText}
            noWrap
          >
            {params.row.skeletonNumber}
          </Typography>

          {/* MACHINE NUMBER TEXT */}
          <Typography
            variant='subtitle2'
            className={classes.columnVehicleText}
            noWrap
          >
            {params.row.machineNumber}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'description',
      headerName: 'Keterangan',
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'roleName',
      headerName: 'Departemen',
      flex: 1,
      minWidth: 150,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'actions',
      headerName: '',
      width: 120,
      hide: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box className={classes.columnActions}>
          {/* EDIT ICON */}
          <CustomTooltipBlack title='Edit' placement='bottom'>
            <IconButton
              onClick={(event) => handleEditIconButtonClick(event, params)}
            >
              <IconEdit />
            </IconButton>
          </CustomTooltipBlack>

          {/* DELETE ICON */}
          <CustomTooltipBlack title='Hapus' placement='bottom'>
            <IconButton
              onClick={(event) => handleDeleteIconButtonClick(event, params)}
            >
              <IconDelete />
            </IconButton>
          </CustomTooltipBlack>
        </Box>
      ),
    },
  ]

  const [selectedColumnList, setSelectedColumnList] = useState(initialColumns)

  const groupingColDef = {
    headerName: 'Nama Kendaraan',
    renderCell: (params) => {
      if (!params.rowNode.isAutoGenerated) {
        return params.row.vehicleName
      }

      return (
        <Typography variant='subtitle1' className={classes.groupingRow} noWrap>
          {params.rowNode.groupingKey.toLowerCase()}
        </Typography>
      )
    },
  }

  const reloadData = () => {
    setIsTableLoading(true)
    const newFilters = reject(isEmpty, filters)
    let params = {
      /*page,
      size,*/
      ...newFilters,
    }

    if (order) params.sort = `${orderBy},${order}`
    if (search) params.globalSearch = search

    getVehicleList(params).then((response) => {
      setVehiclesTableData(response.rows)
      setTotal(response.totalElements)
      setIsTableLoading(false)
    })
  }

  const onDeleteButtonIsClicked = async () => {
    setIsDeleteLoading(true)
    const result = await deleteVehicle(dialogDeleteVehicle.id)
    setIsDeleteLoading(false)

    if (result.status !== 'error') {
      dispatch(showAlert({ message: 'Sukses Menghapus Item Kendaraan' }))
      setDialogDeleteVehicle({})
      reloadData()
    }
    else setSnackbarObject({
      open: true,
      severity: 'error',
      title: '',
      message: 'Gagal menghapus kendaraan',
    })
  }

  const handleColumnsMenuItemClick = (inputItem, inputIndex) => {
    let tempSelectedColumnList = [...selectedColumnList]
    if (
      /*(selectedGroupBy.value && inputItem.field === 'status') ||*/
      (selectedGroupBy.value && inputItem.field === 'vehicleName')
    ) {
    } else {
      tempSelectedColumnList[inputIndex].hide =
        !tempSelectedColumnList[inputIndex].hide
    }
    setSelectedColumnList(tempSelectedColumnList)
  }

  useEffect(() => {
    reloadData()
  }, [page, size, order, orderBy, filters, search])

  useEffect(() => {
    if (!isFilterOn) setFilters(initialFilters)
  }, [isFilterOn])

  useEffect(() => {
    if (location.pathname === basePaths.deviceVehicles && location.search === '?mode=add') {
      setDialogAddVehicle(true)
    }
    else if (location.pathname === basePaths.deviceVehicles && location.search.includes('?mode=edit')) {
      setDialogEditVehicle(location?.state)
    }
    else {
      setDialogAddVehicle(false)
      setDialogEditVehicle(false)
    }
  }, [location])

  return (
    <Box className={classes.pageRoot} ref={pageRef}>
      {/* HEADER */}
      <PageHeader
        isAddButtonAvailable={true}
        onAddButtonIsClicked={() => history.push(`${basePaths.deviceVehicles}?mode=add`)}
        title='Kendaraan'
        search={search}
        setSearch={setSearch}
      />

      {/* CONTENT */}
      <LoadingBox isLoading={isTableLoading}>
        <DataGridFilters
          columns={initialColumns}
          selectedColumnList={selectedColumnList}
          handleColumnsMenuItemClick={handleColumnsMenuItemClick}
          isFilterOn={isFilterOn}
          setIsFilterOn={setIsFilterOn}
          groupByList={groupByList}
          selectedGroupBy={selectedGroupBy}
          setSelectedGroupBy={setSelectedGroupBy}
        />

        <DataGridTable
          initialColumns={initialColumns}
          rows={vehiclesTableData}
          total={total}
          page={page}
          pageSize={size}
          pagination={false}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setPage={setPage}
          setPageSize={setPageSize}
          setFilters={setFilters}
          isFilterOn={isFilterOn}
          selectedColumnList={selectedColumnList}
          setSelectedColumnList={setSelectedColumnList}
          selectedGroupBy={selectedGroupBy}
          getTreeDataPath={(row) => [row.id]}
          groupingColDef={groupingColDef}
          selectionModel={selectionModel}
          setSelectionModel={setSelectionModel}
          hideFooter={true}
          isCheckboxPinned={true}
        />
      </LoadingBox>

      {/* DIALOG ADD VECHILE */}
      <DialogAddOrEditVehicle
        dialogType='add'
        dialogAddOrEditVehicle={dialogAddVehicle}
        setDialogAddOrEditVehicle={setDialogAddVehicle}
        reloadData={reloadData}
        pageRef={pageRef}
        vehiclesTableData={vehiclesTableData}
      />

      {/* DIALOG EDIT VECHILE */}
      {dialogEditVehicle && (
        <DialogAddOrEditVehicle
          dialogType='edit'
          dialogAddOrEditVehicle={dialogEditVehicle}
          setDialogAddOrEditVehicle={setDialogEditVehicle}
          reloadData={reloadData}
          pageRef={pageRef}
          vehiclesTableData={vehiclesTableData}
        />
      )}

      {/* DIALOG DELETE VEHICLE */}
      <DialogConfirmation
        title='Hapus Kendaraan'
        caption='Apa Anda yakin ingin menghapus vehicle ini?'
        dialogConfirmationObject={dialogDeleteVehicle}
        setDialogConfirmationObject={setDialogDeleteVehicle}
        cancelButtonText='Batal'
        continueButtonText='Hapus'
        onContinueButtonClick={onDeleteButtonIsClicked}
        onCancelButtonClick={() => setDialogDeleteVehicle({})}
      />

      {/* LOADING */}
      <SimpleBackdrop isLoading={isDeleteLoading} />
    </Box>
  )
}

export default Vehicles
