import { useState, useRef } from 'react'

// ASSETS
import PictureBrokenAudio from 'assets/images/pictures/broken-audio.svg'

// MUIS
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

// PATH ICONS
import IconMusicNotePlay from 'assets/images/pathIcons/IconMusicNotePlay'

// STYLES
import useStyles from './dialogEvidenceDetailUseStyles'

const MediaAudio = (props) => {
  const { mediaItem } = props

  const classes = useStyles()

  const audioRef = useRef()

  const [ source, setSource ] = useState(mediaItem.mediaUrl)

  return (
    <Stack
      position='relative'
      height='100%'
      width='100%'
      justifyContent='center'
      alignItems='center'
    >
      {/* PLAY ICON */}
      {source &&
      <IconMusicNotePlay className={classes.mediaItemAudio}/>}
      
      {/* AUDIO */}
      <Box
        ref={audioRef}
        component={source ? 'audio' : 'img'}
        src={source ? source : PictureBrokenAudio}
        autoPlay={false}
        controls={false}
        alt=''
        onError={() => setSource(PictureBrokenAudio)}
        className={source ? classes.mediaItemAudio : classes.mediaItemBrokenIcon}
      />
    </Stack>
  )
}

export default MediaAudio