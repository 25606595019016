import { useContext } from 'react'

// COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// MUIS
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'

// MUI ICONS
import IconCircle from '@mui/icons-material/Circle'
import IconList from '@mui/icons-material/List'
import IconRadar from '@mui/icons-material/Radar'
import IconSortByAlpha from '@mui/icons-material/SortByAlpha'

// STYLES
import useStyles from './filterObjectUseStyles'

const FilterObject = () => {
  const classes = useStyles()
  const { objectSortFilter, setObjectSortFilter, referenceLocation } = useContext(PageTrackingContext)

  const handleFilterButton = (key) => {
    setObjectSortFilter({
      ...objectSortFilter,
      [key]: !objectSortFilter[key]
    })
  }

  return (
    <Stack direction='row'>
      <CustomTooltipBlack title='Lepas Pengelompokkan' placement='top'>
        <IconButton
          onClick={() => handleFilterButton('removeGrouping')}
          size='small' className={classes.btnIconFilter}
          color={objectSortFilter?.removeGrouping ? 'primary' : 'default'}
        >
          <IconList fontSize='small' />
        </IconButton>
      </CustomTooltipBlack>

      <CustomTooltipBlack title='Berdasarkan Nama' placement='top'>
        <IconButton
          onClick={() => handleFilterButton('byName')}
          size='small' className={classes.btnIconFilter}
          color={objectSortFilter?.byName ? 'primary' : 'default'}
        >
          <IconSortByAlpha fontSize='small' />
        </IconButton>
      </CustomTooltipBlack>

      <CustomTooltipBlack title='Berdasarkan Status' placement='top'>
        <IconButton
          onClick={() => handleFilterButton('byStatus')}
          size='small' className={classes.btnIconFilter}
          color={objectSortFilter?.byStatus ? 'primary' : 'default'}
        >
          <IconCircle fontSize='small' />
        </IconButton>
      </CustomTooltipBlack>

      <CustomTooltipBlack title='Berdasarkan Jarak' placement='top'>
        <IconButton
          onClick={() => handleFilterButton('byDistance')}
          size='small' className={`${classes.btnIconFilter} lastChildIcon`}
          color={objectSortFilter?.byDistance ? 'primary' : 'default'}
          disabled={!referenceLocation?.length}
        >
          <IconRadar fontSize='small' />
        </IconButton>
      </CustomTooltipBlack>
    </Stack>
  )
}

export default FilterObject