import React, { useState, useEffect, useContext } from 'react'

// COMPONENTS
import ContentTabs from 'components/ContentTabs/ContentTabs'
import VideoCallItem from 'components/VideoCallItem/VideoCallItem'

// MUIS
import Grid from '@mui/material/Grid'

// STYLES
import useStyles from './agentHelpUseStyles'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

const AgentHelp = () => {
  const classes = useStyles()

  const {agentHelpAllData, agentHelpNeedHelp, agentHelpOnVideoCall,} = useContext(AllPagesContext)

  const tabItemList = [
    {
      label: 'Semua Permintaan',
      value: 'All Requests',
    },
    {
      label: 'Butuh Bantuan',
      value: 'Need Help',
    },
    {
      label: 'Panggilan Video',
      value: 'On Video Call',
    },
  ]

  const [selectedTab, setSelectedTab] = useState(tabItemList[0].value)
  const [updatedTabList, setUpdatedTabList] = useState([])

  const getSelectedList = () => {
    if (selectedTab === 'All Requests') return agentHelpAllData
    else if (selectedTab === 'Need Help') return agentHelpNeedHelp
    else if (selectedTab === 'On Video Call') return agentHelpOnVideoCall
  }

  const handleJoinButtonClick = (inputItem, inputIndex) => {
    const newWindow = window.open(
      `${process.env.REACT_APP_DEPLOYED_URL}/live/agent-help/id=${inputItem.channelId}`,
      '_blank',
      'noopener,noreferrer'
    )
    if (newWindow) newWindow.opener = null
  }

  useEffect(() => {
    const videoLengthList = [
      agentHelpAllData.length,
      agentHelpNeedHelp.length,
      agentHelpOnVideoCall.length,
    ]
    const newTabList = tabItemList.map((item, index) => {
      return {
        ...item,
        label: `${item.label} (${videoLengthList[index]})`,
      }
    })
    setUpdatedTabList(newTabList)
  }, [agentHelpAllData, agentHelpNeedHelp, agentHelpOnVideoCall])

  return (
    <>
      {/* CONTENT TABS */}
      <ContentTabs
        tabItemList={updatedTabList}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />

      {/* AGENT LIST */}
      <Grid container className={classes.agentGrid}>
        {getSelectedList().map((item, index) => (
          <Grid
            key={index}
            item
            xs={3}
            className={classes.agentItemPadding}
          >
            <VideoCallItem
              data={item}
              onJoinButtonClick={() =>
                handleJoinButtonClick(item, index)
              }
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default AgentHelp
