import { useState } from 'react'
import PropTypes from 'prop-types'

// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import CardMedia from '@mui/material/CardMedia'
import IconButton from '@mui/material/IconButton'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconMoreVert from '@mui/icons-material/MoreVert'
import IconFileDownload from '@mui/icons-material/FileDownload'

// STYLES
import useStyles from './chatRoomUseStyles'

// UTILS
import { formatBytes, getBackgroundAvatar } from 'utilities'

const BubbleChat = (props) => {
  const {
    isOwner, name, message, typeMessage, time, onReplyClick,
    isReply, replyTo, replyMessage, replyTypeMessage
  } = props
  const classes = useStyles()

  // STATES
  const [menuActionEl, setMenuActionEl] = useState(null)

  const getElementByTypeMessage = (type, inputMessage, inputIsReply) => {
    if (type === 'txt') {
      return <Typography variant='body2' color={(isOwner && !inputIsReply) ? 'white' : 'black'}>
        {inputMessage}
      </Typography>
    } else if (type === 'img') {
      return <CardMedia
        component='img'
        src={inputIsReply ? inputMessage?.thumb : inputMessage}
      />
    } else if (type === 'audio') {
      return <CardMedia
        component='audio'
        src={inputIsReply ? inputMessage?.url : inputMessage}
        controls
      />
    } else if (type === 'file') {
      return <Stack direction='row' alignItems='center'>
        <IconButton href={inputMessage?.url} target='_blank'>
          <IconFileDownload
            sx={{
              color: (isOwner && !inputIsReply) ? 'white' : 'black'
            }}
          />
        </IconButton>

        <Stack pl='8px'>
          <Typography
            variant='body2' color={(isOwner && !inputIsReply) ? 'white' : 'black'}
          >
            {inputMessage?.fileName}
          </Typography>

          <Typography
            variant='body2' color={(isOwner && !inputIsReply) ? 'white' : 'black'}
          >
            {formatBytes(inputMessage?.fileLength)}
          </Typography>
        </Stack>
      </Stack>
    }
  }

  return (
    <>
      <Stack
        direction={isOwner ? 'row-reverse' : 'row'}
        flexWrap='nowrap'
        justifyContent='flex-start'
      >
        {/* AVATAR */}
        {!isOwner && <ListItemAvatar
          sx={{
            backgroundColor: getBackgroundAvatar(name),
          }}
          className={classes.bubbleChatAvatar}
        >
          <Typography fontWeight={600} variant='caption' color='white'>
            {name?.toUpperCase()?.split(' ')?.[0]?.[0]}
            {name?.toUpperCase()?.split(' ')?.[1]?.[0]}
          </Typography>
        </ListItemAvatar>}

        <Stack
          width='auto'
          maxWidth='320px'
          alignItems={isOwner ? 'flex-end' : 'flex-start'}
        >
          {isReply && <>
            {/* REPLY TITLE */}
            <Typography variant='caption'>
              Membalas {replyTo}
            </Typography>

            {/* REPLY BUBBLE */}
            <Stack
              className={classes.bubbleChatBox}
              backgroundColor={colors.mystic}
              width={replyTypeMessage === 'audio' ? '260px !important' : 'fit-content !important'}
              mb='2px'
            >
              {getElementByTypeMessage(replyTypeMessage, replyMessage, true)}
            </Stack>
          </>}

          {/* MESSAGE BUBBLE */}
          <Stack
            className={classes.bubbleChatBox}
            backgroundColor={isOwner ? colors.azureRadiance : colors.athensGray}
            width={typeMessage === 'audio' ? '260px !important' : 'fit-content !important'}
          >
            <Typography fontWeight={600} variant='subtitle2' color={isOwner ? 'white' : 'black'}>
              {name}
            </Typography>

            {getElementByTypeMessage(typeMessage, message)}

            <Typography textAlign='right' color={isOwner ? 'white' : 'text.secondary'} variant='caption'>
              {time}
            </Typography>
          </Stack>
        </Stack>

        <Stack pl={!isOwner && '4px'} pr={isOwner && '4px'}>
          <IconButton size='small' onClick={event => setMenuActionEl(event.currentTarget)}>
            <IconMoreVert fontSize='small' />
          </IconButton>
        </Stack>
      </Stack>

      {/* MENU ACTION */}
      <Menu
        anchorEl={menuActionEl}
        open={Boolean(menuActionEl)}
        onClose={() => setMenuActionEl(null)}
      >
        <MenuItem onClick={() => {
          onReplyClick && onReplyClick()
          setMenuActionEl(null)
        }}>Reply</MenuItem>
      </Menu>
    </>
  )
}

BubbleChat.propTypes = {
  isOwner: PropTypes.bool,
  name: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  typeMessage: PropTypes.oneOf(['txt', 'img', 'audio', 'file']),
  isReply: PropTypes.bool,
  replyTo: PropTypes.string,
  replyMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  replyTypeMessage: PropTypes.oneOf(['txt', 'img', 'audio', 'file']),
  time: PropTypes.string,
  onReplyClick: PropTypes.func
}

export default BubbleChat