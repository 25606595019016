import { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// CONSTANTS
import { basePaths } from 'constants/paths'

// COMPONENTS
import CustomFormControl from 'components/Customs/CustomFormControl'
import CustomInputNew from 'components/Customs/CustomInputNew'
import CustomInputLabelNew from 'components/Customs/CustomInputLabelNew'
import CustomMenuItem from 'components/Customs/CustomMenuItem'
import CustomInput from 'components/Customs/CustomInput'
import DialogAddOrEdit from 'components/DialogAddOrEditNew/DialogAddOrEdit'
import SimpleBackdrop from 'components/Partials/SimpleBackdrop'
import Snackbar from 'components/Snackbar/Snackbar'

// MUIS
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconNumbers from '@mui/icons-material/Numbers'
import IconTextFormat from '@mui/icons-material/TextFormat'
import IconPhoneIphone from '@mui/icons-material/PhoneIphone'
import IconEmail from '@mui/icons-material/Email'
import IconPoliceBadgeOutlined from 'assets/images/pathIcons/IconPoliceBadgeOutlined'
import IconPoliceStation from 'assets/images/pathIcons/IconPoliceStation'
import IconPassword from '@mui/icons-material/Password'
import IconPoliceMan from 'assets/images/pathIcons/IconPoliceMan'
import IconListView from 'assets/images/pathIcons/IconListView'
import IconPoliceBadge from 'assets/images/pathIcons/IconPoliceBadge'
import IconRemoveRedEye from '@mui/icons-material/RemoveRedEye'
import IconVisibilityOff from '@mui/icons-material/VisibilityOff'
import IconDraw from '@mui/icons-material/Draw'
import IconAddCircle from '@mui/icons-material/AddCircle'
import IconEdit from '@mui/icons-material/Edit'
import IconArrowDropDown from '@mui/icons-material/ArrowDropDown'
import IconSearch from '@mui/icons-material/Search'

// LIBRARY
import SignatureCanvas from 'react-signature-canvas'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'

// SERVICES
import { createUser, getProfileAll, getUser, updateUser } from 'services/UserService'
import { getOfficerDepartmentList } from 'services/settingsOfficersServices'
import { postMediaPresignedUrl } from 'services/MediaService'

// STYLES
import useStyles from './dialogAddEditSettingsUsersUseStyles'

const DialogAddEditSettingsUsersNew = (props) => {
  const { pageRef, dialogAddOrEditSettingsUsers, setDialogAddOrEditSettingsUsers, reloadData } = props
  const classes = useStyles()
  const signatureRef = useRef()
  const history = useHistory()
  const { snackbarObject, setSnackbarObject } = useContext(AllPagesContext)

  const initInformation = {
    userRegistrationNumber: '',
    userName: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    email: '',
    officerName: '',
    officerCode: '',
    officerRank: '',
    profileId: 1,
    roleId: 0,
    signature: {
      contentType: '',
      path: '',
      fileName: '',
      fileSize: 0
    }
  }

  const [isShowPassword, setIsShowPassword] = useState(false)
  const [IsShowConfirmPassword, setIsShowConfirmPassword] = useState(false)
  const [isShowCanvas, setIsShowCanvas] = useState(false)
  const [isModeEditCanvas, setIsModeEditCanvas] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [dataSignature, setDataSignature] = useState('')
  const [departmentList, setDepartmentList] = useState([])
  const [profiles, setProfiles] = useState([])
  const [departmentMenuAnchor, setDepartmentMenuAnchor] = useState(null)
  const [searchDepartment, setSearchDepartment] = useState('')
  // FIELD DATA
  const [informationParams, setInformationParams] = useState(initInformation)
  const [currentProfile, setCurrentProfile] = useState('Admin')
  const [currentDepartment, setCurrentDepartment] = useState('')

  const dataURLtoFileObject = (dataurl, filename) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n)
    while(n--){
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, {type:mime})
  }

  const handleSaveSignature = async () => {
    setIsLoading(true)
    const fileObject = dataURLtoFileObject(signatureRef.current?.toDataURL(), `signature-${uuid()}.png`)

    const response = await postMediaPresignedUrl({
      fileName: fileObject.name,
      objectFolder: 'ASSETS'
    }, fileObject)

    setDataSignature(signatureRef.current?.toDataURL())
    setInformationParams({
      ...informationParams,
      signature: {
        contentType: fileObject.type,
        path: response?.data?.path,
        fileName: fileObject.name,
        fileSize: fileObject.size
      }
    })
    setIsShowCanvas(false)
    setIsModeEditCanvas(false)
    setIsLoading(false)
  }

  const handleCancelSignature = () => {
    signatureRef.current?.clear()
    setIsShowCanvas(false)
    setIsModeEditCanvas(false)
  }

  const handleUpdateSignature = () => {
    setIsShowCanvas(true)
    setIsModeEditCanvas(true)
  }

  const fetchUserDetail = async () => {
    setIsLoading(true)
    const response = await getUser(dialogAddOrEditSettingsUsers?.data?.row?.id)

    setInformationParams({
      userRegistrationNumber: response?.userRegistrationNumber,
      userName: response?.userName,
      phoneNumber: response?.phoneNumber,
      email: response?.email,
      officerName: response?.officerName,
      officerCode: response?.officerCode,
      officerRank: response?.officerRank,
      profileId: response?.profile?.id,
      roleId: response?.role?.id,
      signature: {
        contentType: response?.signatureMedia?.fileType,
        path: response?.signatureMedia?.path,
        fileName: response?.signatureMedia?.fileName,
        fileSize: response?.signatureMedia?.fileSize
      }
    })
    setDataSignature(response?.signatureMedia?.mediaUrl)
    setCurrentDepartment(response?.role?.roleName)
    setCurrentProfile(response?.profile?.profileName)
    setIsLoading(false)
  }

  const firstInitFetch = async () => {
    const responseDepartment = await getOfficerDepartmentList()
    const responseProfiles = await getProfileAll()

    setDepartmentList(responseDepartment)
    setProfiles(responseProfiles)
  }

  const handleInformationParamsChange = (event) => {
    setInformationParams({
      ...informationParams,
      [event.target.name]: event.target.value,
    })
  }

  const handleProfilesChange = (event) => {
    const profileDetail = profiles?.find(item => item.profileName === event.target.value)
    setCurrentProfile(profileDetail?.profileName)
    setInformationParams({
      ...informationParams,
      profileId: profileDetail?.id
    })
  }

  const handleMenuDepartmentItemClicked = (item) => {
    setInformationParams(current => {
      return {
        ...current,
        roleId: item.id
      }
    })
    setCurrentDepartment(item.name)
    setDepartmentMenuAnchor(null)
  }

  const resetAllState = () => {
    setInformationParams(initInformation)
    setCurrentDepartment('')
    setCurrentProfile('')
    setDataSignature('')
  }

  const handleClose = () => {
    resetAllState()
    setDialogAddOrEditSettingsUsers(false)
    history.push(basePaths.settingsUsers)
  }

  const handleSaveClick = async () => {
    setIsLoading(true)
    let isValid = true
    let snackbar = { severity: '', message: '' }

    Object.keys(informationParams)?.forEach(key => {
      if (key === 'signature') {
        Object.keys(informationParams[key]).forEach(keySignature => {
          if (!informationParams[key][keySignature]) isValid = false
        })
      }
      else {
        if (!informationParams.profileId.toString()) isValid = false
        // if (!informationParams[key]) isValid = false 
      }
    })

    // ADD
    if (dialogAddOrEditSettingsUsers?.type === 'add') {
      if (isValid) {
        if (informationParams.password === informationParams.confirmPassword) {
          const response = await createUser(informationParams)
          if (response?.status >= 200 && response?.status <= 299) {
            reloadData()
            snackbar = { severity: 'success', message: 'Sukses menambahkan pengguna' }
            handleClose()
          } else {
            snackbar = { severity: 'error', message: response?.message ?? 'Gagal menambahkan pengguna' }
          }
        } else {
          snackbar = { severity: 'error', message: 'Password dan konfirmasi password harus sama' }
        }
      } else {
        snackbar = { severity: 'error', message: 'Semua form harus terisi' }
      }
    }

    // EDIT
    if (dialogAddOrEditSettingsUsers?.type === 'edit') {
      if (isValid)  {
        const response = await updateUser(dialogAddOrEditSettingsUsers?.data?.row?.id, informationParams)
        if (response?.status >= 200 && response?.status <= 299) {
          reloadData()
          snackbar = { severity: 'success', message: 'Sukses mengubah pengguna' }
          handleClose()
        } else {
          snackbar = { severity: 'error', message: response?.message ?? 'Gagal mengubah pengguna' }
        }
      } else {
        snackbar = { severity: 'error', message: 'Semua form harus terisi' }
      }
    }

    setSnackbarObject({
      open: true,
      severity: snackbar.severity,
      title: '',
      message: snackbar.message,
    })
    setIsLoading(false)
  }

  useEffect(() => {
    firstInitFetch()
  }, [])

  useEffect(() => {
    if (dialogAddOrEditSettingsUsers?.type === 'edit') fetchUserDetail()
  }, [dialogAddOrEditSettingsUsers])

  return (
    <>
      {/* DIALOG ADD OR EDIT */}
      <DialogAddOrEdit
        open={Boolean(dialogAddOrEditSettingsUsers)}
        title='Penugasan'
        onCloseIconClick={() => handleClose()}
        isExpandButtonAvailable={false}
        containerRef={pageRef}
        contents={[
          <Stack>
            <Typography variant='h6' fontWeight={600} marginBottom='16px'>Informasi Utama</Typography>

            {/* NOMOR REGISTRASI POKOK */}
            <CustomFormControl className={classes.formControl} variant='filled'>
              <CustomInputLabelNew shrink={true}>
                <IconNumbers />
                <Typography variant='subtitle1'>Nomor Registrasi Pokok</Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                name='userRegistrationNumber'
                placeholder='Masukkan Nomor Registrasi Pokok'
                onChange={handleInformationParamsChange}
                value={informationParams?.userRegistrationNumber}
              />
            </CustomFormControl>

            {/* NAMA */}
            <CustomFormControl className={classes.formControl} variant='filled'>
              <CustomInputLabelNew shrink={true}>
                <IconTextFormat />
                <Typography variant='subtitle1'>Nama</Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Masukkan Nama'
                onChange={handleInformationParamsChange}
                name='userName'
                value={informationParams?.userName}

              />
            </CustomFormControl>

            {/* NOMOR TELEPON */}
            <CustomFormControl className={classes.formControl} variant='filled'>
              <CustomInputLabelNew shrink={true}>
                <IconPhoneIphone />
                <Typography variant='subtitle1'>Nomor Telepon</Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Masukkan Nomor Telepon'
                onChange={handleInformationParamsChange}
                name='phoneNumber'
                value={informationParams?.phoneNumber}
              />
            </CustomFormControl>

            {/* EMAIL */}
            <CustomFormControl className={classes.formControl} variant='filled'>
              <CustomInputLabelNew shrink={true}>
                <IconEmail />
                <Typography variant='subtitle1'>Email</Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Email'
                onChange={handleInformationParamsChange}
                name='email'
                value={informationParams?.email}
              />
            </CustomFormControl>

            {/* AKUN */}
            <CustomFormControl variant='filled' className={`${classes.formControl} ${classes.selectAccount}`}>
              <CustomInputLabelNew shrink={true}>
                <IconPoliceBadge />
                <Typography variant='subtitle1'>Akun</Typography>
              </CustomInputLabelNew>

              <Select
                disableUnderline
                MenuProps={{
                  PaperProps: {
                    className: classes.selectAccountPaper
                  }
                }}
                onChange={handleProfilesChange}
                name='account'
                value={currentProfile}
              >
                {profiles?.map((item, index) => (
                  <MenuItem
                    key={index}
                    className={classes.categoryItem}
                    value={item.profileName}
                  >{item.profileName}</MenuItem>
                ))}
              </Select>
            </CustomFormControl>

            {/* DEPARTEMEN */}
            <CustomFormControl 
              variant='filled' 
              className={classes.formControl}
            >
              <CustomInputLabelNew shrink={true}>
                <IconPoliceStation />
                <Typography variant='subtitle1'>
                  Departemen
                </Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Pilih Departemen'
                readOnly
                type='text'
                name='department'
                value={currentDepartment}
                onChange={event => setCurrentDepartment(event.target.value)}
                endAdornment={<IconArrowDropDown className={`${classes.dropDownIcon} zoom`}/>}
                onClick={(event) => setDepartmentMenuAnchor(event.currentTarget)}
                className={`${classes.inputDepartment} no-zoom`}
              />
            </CustomFormControl>
            
            {/* DEPARTMENT MENU */}
            <Menu
              anchorEl={departmentMenuAnchor}
              open={Boolean(departmentMenuAnchor)}
              onClose={() => setDepartmentMenuAnchor(null)}
              className={`${classes.menu} no-zoom`}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Stack className='zoom'>
                {/* SEARCH INPUT */}
                <Box className={classes.menuSearch}>
                  <CustomInput
                    type='text'
                    placeholder='Search'
                    className={classes.menuSearchInput}
                    value={searchDepartment}
                    onChange={(event) => setSearchDepartment(event.target.value)}
                    startAdornment={<IconSearch className={classes.menuSearchIcon}/>}
                    onClick={(event) => event.stopPropagation()}
                    onKeyDown={(event) => event.stopPropagation()}
                  />
                </Box>

                {/* DEPARTMENT OPTION LIST */}
                <Box className={classes.menuItemContainer}>
                  {departmentList.filter(item => item.name.toLowerCase().includes(searchDepartment)).map((item, index) => (
                    <CustomMenuItem
                      key={index}
                      className={
                        currentDepartment === item.name
                          ? `${classes.menuItem} ${classes.menuItemSelected}`
                          : classes.menuItem
                      }
                      onClick={() => handleMenuDepartmentItemClicked(item)}
                    >
                      {item.name}
                    </CustomMenuItem>
                  ))}
                </Box>
              </Stack>
            </Menu>

            {/* PASSWORD */}
            {dialogAddOrEditSettingsUsers?.type === 'add' && <CustomFormControl className={classes.formControl} variant='filled'>
              <CustomInputLabelNew shrink={true}>
                <IconPassword />
                <Typography variant='subtitle1'>Kata Sandi</Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Masukkan Kata Sandi'
                type={isShowPassword ? 'text' : 'password'}
                onChange={handleInformationParamsChange}
                name='password'
                value={informationParams?.password}
              />

              <IconButton
                size='small'
                className={classes.btnShowHidePass}
                onClick={() => setIsShowPassword(!isShowPassword)}
              >
                {!isShowPassword && <IconRemoveRedEye fontSize='small' className='textSecondary'/>}

                {isShowPassword && <IconVisibilityOff fontSize='small' className='textSecondary'/>}
              </IconButton>
            </CustomFormControl>}

            {/* CONFIRM PASSWORD */}
            {dialogAddOrEditSettingsUsers?.type === 'add' &&  <CustomFormControl className={classes.formControl} variant='filled'>
              <CustomInputLabelNew shrink={true}>
                <IconPassword />
                <Typography variant='subtitle1'>Konfirmasi Kata Sandi</Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Konfirmasi Kata Sandi'
                type={IsShowConfirmPassword ? 'text' : 'password'}
                onChange={handleInformationParamsChange}
                name='confirmPassword'
                value={informationParams?.confirmPassword}
              />

              <IconButton
                size='small'
                className={classes.btnShowHidePass}
                onClick={() => setIsShowConfirmPassword(!IsShowConfirmPassword)}
              >
                {!IsShowConfirmPassword && <IconRemoveRedEye fontSize='small' className='textSecondary'/>}

                {IsShowConfirmPassword && <IconVisibilityOff fontSize='small' className='textSecondary'/>}
              </IconButton>
            </CustomFormControl>}
          </Stack>,
          <Stack>
            <Typography variant='h6' fontWeight={600} marginBottom='16px'>Informasi Petugas</Typography>

            {/* NAMA PETUGAS */}
            <CustomFormControl className={classes.formControl} variant='filled'>
              <CustomInputLabelNew shrink={true}>
                <IconPoliceMan />
                <Typography variant='subtitle1'>Nama Petugas</Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Masukkan Nama Petugas'
                onChange={handleInformationParamsChange}
                name='officerName'
                value={informationParams?.officerName}
              />
            </CustomFormControl>

            {/* PANGKAT */}
            <CustomFormControl className={classes.formControl} variant='filled'>
              <CustomInputLabelNew shrink={true}>
                <IconPoliceBadgeOutlined />
                <Typography variant='subtitle1'>Pangkat</Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Pilih Pangkat'
                onChange={handleInformationParamsChange}
                name='officerRank'
                value={informationParams?.officerRank}
              />
            </CustomFormControl>

            {/* NRP */}
            <CustomFormControl className={classes.formControl} variant='filled'>
              <CustomInputLabelNew shrink={true}>
                <IconListView />
                <Typography variant='subtitle1'>NRP</Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Masukkan NRP'
                onChange={handleInformationParamsChange}
                name='officerCode'
                type='number'
                value={informationParams?.officerCode}
              />
            </CustomFormControl>

            {/* SIGNATURE */}
            <CustomFormControl className={classes.formControl} variant='filled'>
              <CustomInputLabelNew shrink={true}>
                <IconDraw />
                <Typography variant='subtitle1'>Tanda Tangan</Typography>
              </CustomInputLabelNew>

              {/* BTN TAMBAHKAN */}
              {(!isShowCanvas && !dataSignature) && <Button className={classes.btnRight} onClick={() => {
                setIsShowCanvas(true)
                setIsModeEditCanvas(true)
              }}>
                <IconAddCircle fontSize='small'/> Tambahkan
              </Button>}

              {(!isModeEditCanvas && dataSignature) && <Button className={classes.btnRight} onClick={handleUpdateSignature}>
                <IconEdit fontSize='small'/> Update
              </Button>}

              {(!isShowCanvas && !dataSignature) && <Stack paddingTop='40px'></Stack>}

              {/* CANVAS SIGNATURE */}
              {isShowCanvas && <Stack padding='40px 4px 4px 4px' className='no-zoom'>
                <Stack className={`${classes.signatureWrapper} zoom`}>
                  <Button
                    onClick={() => signatureRef.current?.clear()}
                    className={classes.btnClear}
                  >CLEAR</Button>

                  <SignatureCanvas
                    minWidth={1}
                    maxWidth={1}
                    canvasProps={{
                      width: 420, height: 200, className: 'no-zoom'
                    }}
                    ref={(ref) => signatureRef.current = ref}
                  />
                </Stack>
              </Stack>}

              {/* ACTION BUTTON */}
              {isModeEditCanvas && <Stack padding='8px 16px' flexDirection='row' justifyContent='flex-end'>
                <Button onClick={handleCancelSignature} className={classes.btnCancel}>CANCEL</Button>
                <Button onClick={() => handleSaveSignature()} className={classes.btnSave}>SAVE</Button>
              </Stack>}

              {(dataSignature && !isModeEditCanvas) && <Stack padding='40px 4px 4px 4px'>
                <Box
                  component='img'
                  src={dataSignature}
                  className={classes.previewImage}
                />
              </Stack>}
            </CustomFormControl>
          </Stack>
        ]}
        onCancelButtonClick={() => handleClose()}
        onSaveButtonClick={() => handleSaveClick()}
      />

      {/* SNACKBAR */}
      <Snackbar
        open={snackbarObject.open}
        setToast={setSnackbarObject}
        severity={snackbarObject.severity}
        title={snackbarObject.title}
        message={snackbarObject.message}
      />

      {/* LOADING */}
      <SimpleBackdrop isLoading={isLoading} />
    </>
  )
}

export default DialogAddEditSettingsUsersNew