// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconCrossbar = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M12.0003 1.41174C9.65108 1.41174 7.73858 3.32424 7.73858 5.67351C7.73858 7.05825 8.40531 8.28252 9.4327 9.06174H2.25917C1.76781 9.06174 1.41211 9.41744 1.41211 9.9088V13.7206C1.41211 14.2119 1.76781 14.5676 2.25917 14.5676H21.7415C22.2329 14.5676 22.5886 14.2119 22.5886 13.7206V9.9088C22.5886 9.41744 22.2329 9.06174 21.7415 9.06174H14.568C15.5954 8.28252 16.2621 7.05825 16.2621 5.67351C16.2621 3.32424 14.3496 1.41174 12.0003 1.41174ZM9.45917 4.82645H14.5415V6.52057H9.45917V4.82645ZM2.25917 9.9088H4.31064L2.25917 13.3632V9.9088ZM7.83123 9.9088H10.2401L7.95035 13.7206H5.55476L7.83123 9.9088ZM13.7606 9.9088H16.1695L13.8798 13.7206H11.4842L13.7606 9.9088ZM19.6901 9.9088H21.7415V10.5706C21.7266 10.5871 21.7134 10.5904 21.7018 10.6103L19.8489 13.7206H17.4136L19.6901 9.9088ZM3.10623 14.9912V22.1912C3.10623 22.4261 3.29648 22.6147 3.52976 22.6147H5.6474C5.88068 22.6147 6.07093 22.4261 6.07093 22.1912V14.9912H3.10623ZM17.9298 14.9912V22.1912C17.9298 22.4261 18.1184 22.6147 18.3533 22.6147H20.4709C20.7059 22.6147 20.8945 22.4261 20.8945 22.1912V14.9912H17.9298ZM6.91799 17.5323V20.0735H17.0827V17.5323H6.91799Z'/>
    </SvgIcon>
  )
}

export default IconCrossbar