// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconJeepWrangler = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M5.82324 4C5.01044 4 4.32778 4.60843 4.23418 5.41562L3.48887 11.8547L2.48418 12.3578C1.94178 12.629 1.5998 13.1835 1.5998 13.7891V20C1.5998 20.884 2.3158 21.6 3.1998 21.6C4.0838 21.6 4.7998 20.884 4.7998 20V18.825C6.01553 18.9622 8.58611 19.2 12.0139 19.2C15.4373 19.2 17.99 18.9623 19.1998 18.825V20C19.1998 20.884 19.9158 21.6 20.7998 21.6C21.6838 21.6 22.3998 20.884 22.3998 20V13.7891C22.3998 13.1835 22.0578 12.629 21.5154 12.3578L20.5107 11.8562L19.7654 5.41719C19.6718 4.60919 18.9892 4 18.1764 4H5.82324ZM5.82324 5.6H18.1764L18.6389 9.6H5.36074L5.82324 5.6ZM1.5998 7.2C1.1582 7.2 0.799805 7.5584 0.799805 8V8.8C0.799805 9.2416 1.1582 9.6 1.5998 9.6H2.3998C2.8414 9.6 3.1998 9.2416 3.1998 8.8V8C3.1998 7.5584 2.8414 7.2 2.3998 7.2H1.5998ZM21.5998 7.2C21.1582 7.2 20.7998 7.5584 20.7998 8V8.8C20.7998 9.2416 21.1582 9.6 21.5998 9.6H22.3998C22.8414 9.6 23.1998 9.2416 23.1998 8.8V8C23.1998 7.5584 22.8414 7.2 22.3998 7.2H21.5998ZM5.9998 12C6.6622 12 7.1998 12.5376 7.1998 13.2C7.1998 13.8624 6.6622 14.4 5.9998 14.4C5.3374 14.4 4.7998 13.8624 4.7998 13.2C4.7998 12.5376 5.3374 12 5.9998 12ZM9.5998 12H14.3998C14.8414 12 15.1998 12.3584 15.1998 12.8V15.2C15.1998 15.6416 14.8414 16 14.3998 16H9.5998C9.1582 16 8.7998 15.6416 8.7998 15.2V12.8C8.7998 12.3584 9.1582 12 9.5998 12ZM17.9998 12C18.6622 12 19.1998 12.5376 19.1998 13.2C19.1998 13.8624 18.6622 14.4 17.9998 14.4C17.3374 14.4 16.7998 13.8624 16.7998 13.2C16.7998 12.5376 17.3374 12 17.9998 12Z' />
    </SvgIcon>
  )
}

export default IconJeepWrangler