import React from 'react'
import ReactDOMServer from 'react-dom/server'

// LEAFLET
import L from 'leaflet'

// MUIS
import Stack from '@mui/material/Stack'

// CHART
import { PieChart } from 'react-minimal-pie-chart'

// UTILITIES
import { getColorMarkerByTypeName } from 'utilities/colors'

export const customClusterMarker = (inputCluster, mapContext, isPieChart = true, inputColor) => {
  let tempObject = []
  let tempEvidenceHelp = []
  let tempEvidencePending = []
  let tempEvidenceResolved = []
  let tempDispatches = []
  let dataPie = []

  // GET CHILD OF CLUSTER AND PUSH TO TEMP
  inputCluster.getAllChildMarkers().map(item => {
    if(item.options.objectData?.status === 'OBJECT') {
      tempObject.push({
        id: item.options.objectData.id,
        label: item.options.objectData.label,
        status: item.options.objectData.status,
        dataDetail: item.options.objectData
      })
    } else if(item.options.objectData?.status === 'NEEDED_HELP') {
      tempEvidenceHelp.push({
        id: item.options.objectData.evidenceNo,
        status: item.options.objectData.status
      })
    } else if(item.options.objectData?.status === 'PENDING') {
      tempEvidencePending.push({
        id: item.options.objectData.evidenceNo,
        status: item.options.objectData.status
      })
    } else if(item.options.objectData?.status === 'RESOLVED') {
      tempEvidenceResolved.push({
        id: item.options.objectData.evidenceNo,
        status: item.options.objectData.status
      })
    } else if(item.options.objectData?.type === 'DISPATCHES') {
      tempDispatches.push({
        id: item.options.objectData.dispatchNo,
        status: item.options.objectData.status
      })
    }
  })

  // SET SIZE PIE CHART MARKER
  const getSizePieChart = () => {
    if(mapContext?.getZoom() <= 10) return 40
    else if(mapContext?.getZoom() <= 18) return 60
    else return 50
  }

  // GET GROUP
  const uniqueColor = [...new Set(tempObject.map(item => item.dataDetail.group.groupColor))]
  const uniqueName = [...new Set(tempObject.map(item => item.dataDetail.group.groupName))]
  const uniqueNo = [...new Set(tempObject.map(item => item.dataDetail.group.groupNo))]

  // FILTER GROUP BY ID GROUP
  if(tempObject.length) {
    uniqueNo.map((item, index) => {
      if(item) dataPie.push({
        title: uniqueName[index],
        value: tempObject.filter(itemObject => itemObject.dataDetail.groupNo === item).length,
        color: uniqueColor[index]
      })
    })
    
    // DEFAULT IS 0 = MAIN GROUP
    const totalMainGroup = tempObject.filter(itemObject => itemObject.dataDetail.groupNo === 0 || itemObject.dataDetail.group.groupNo === null).length
    totalMainGroup >= 1 && dataPie.push({
      title: 'Main Group',
      value: totalMainGroup,
      color: getColorMarkerByTypeName('OBJECT')
    })
  }
  if(tempEvidenceHelp.length) dataPie.push({
    title: 'butuh bantuan',
    value: tempEvidenceHelp.length,
    color: getColorMarkerByTypeName('NEEDED_HELP')
  })
  if(tempEvidencePending.length) dataPie.push({
    title: 'dalam proses',
    value: tempEvidencePending.length,
    color: getColorMarkerByTypeName('PENDING')
  })
  if(tempEvidenceResolved.length) dataPie.push({
    title: 'selesai',
    value: tempEvidenceResolved.length,
    color: getColorMarkerByTypeName('RESOLVED')
  })
  if(tempDispatches.length) dataPie.push({
    title: 'Penugasan',
    value: tempDispatches.length,
    color: getColorMarkerByTypeName('DISPATCHES')
  })

  const getCurrentNoClusterMarker = () => {
    if (inputCluster.getAllChildMarkers().find(item => item.options?.type === 'object')) {
      return uniqueColor[0]
    } else if (inputCluster.getAllChildMarkers().find(item => item.options?.type === 'evidence')) {
      return getColorMarkerByTypeName(inputCluster.getAllChildMarkers()[0]?.options?.objectData?.status)
    }
  }
   
  if (isPieChart) return L.divIcon({
    className: 'marker-cluster-custom',
    html: ReactDOMServer.renderToString(
      <PieChart
        data={dataPie}
        radius={getSizePieChart()}
        style={{
          height: getSizePieChart(),
          width: getSizePieChart(),
          transform: 'translate(-50%, -50%)'
        }}
        label={({ dataEntry }) => dataEntry.value}
        lineWidth={56}
        labelPosition={100 - 60 / 2}
        labelStyle={{
          fill: '#fff',
          fontWeight: 800,
          fontSize: 14
        }}
        viewBoxSize={[getSizePieChart() * 2, getSizePieChart() * 2]}
        center={[getSizePieChart(), getSizePieChart()]}
      />
    ),
  })
  else return L.divIcon({
    className: 'noClusterPie',
    html: ReactDOMServer.renderToString(
      <Stack
        style={{
          width: '24px',
          height: '24px',
          borderRadius: '100%',
          backgroundColor: inputColor ? inputColor : getCurrentNoClusterMarker(),
          color: 'white',
          fontSize: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 600
        }}
      >
        {inputCluster?.getAllChildMarkers()?.length}
      </Stack>
    )
  })
}