import React, { useState, useContext, useEffect } from 'react'

// CUSTOM COMPONENTS
import ContentTabs from 'components/ContentTabs/ContentTabs'
import CustomInput from 'components/Customs/CustomInput'
import CustomInputLabel from 'components/Customs/CustomInputLabel'
import CustomDialogActionButton from 'components/Customs/CustomDialogActionButton'
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'
import CustomTextField from 'components/Customs/CustomTextField'
import Snackbar from 'components/Snackbar/Snackbar'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import PanelObjects from '../PanelObjects'

// MUIS
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'

// MUI ICONS
import IconFormatColorText from '@mui/icons-material/FormatColorText'
import IconWidgets from '@mui/icons-material/Widgets'
import IconNotes from '@mui/icons-material/Notes'
import IconInfo from '@mui/icons-material/InfoOutlined'
import CloseIcon from '@mui/icons-material/Close'
import PlaceIcon from '@mui/icons-material/Place'

// STYLES
import useStyles from './panelRulesSettingUseStyles'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { PageAlertRulesContext } from 'contexts/PageAlertRulesContext'

// SERVICE
import { updateRule } from 'services/AlertService'

const PanelRulesSetting = (props) => {
  const classes = useStyles()
  const { 
    pageRef,
  } = props

  const tabItems = [ 
    {
      label: 'Nama dan jenis',
      value: 'nameAndType',
    },
    {
      label: 'Pengaturan',
      value: 'settings',
    },
  ]

  // CONTEXT
  const { snackbarObject, setSnackbarObject } = useContext(AllPagesContext)
  const {
    selectionModel, geofencesList, selectedRulesItem,
    setSelectionModel, setSelectedRulesItem, setIsRefetchingRules
  } = useContext(PageAlertRulesContext)

  // GEOFENCES TYPE
  const geofenceType = [{
    id: 1,
    label: 'Geofence Visits',
    value: 'INOUTZONE'
  }]

  // INIT PARAMS
  const initialDialogParams = {
    name: '',
    type: '',
    labelGeofence: '',
    description: '',
    zoneIds : []
  }

  // STATES
  const [selectedTab, setSelectedTab] = useState(tabItems[0].value)
  const [dialogParams, setDialogParams] = useState(initialDialogParams)
  const [dialogCancelShown, setDialogCancelShown ] = useState({})

  /* HANDLE PARAMS */
  const handleDialogParamsChange = (event) => {
    setDialogParams({
      ...dialogParams,
      [event.target.name]: event.target.value,
    })
  }

  /* HANDLE SAVE BUTTON */
  const handleSaveButton = async () => {
    const params = {
      description : dialogParams.description,
      name : dialogParams.name,
      objectIds : selectionModel,
      type : dialogParams.type,
      zoneIds : dialogParams.zoneIds,
    }

    const result = await updateRule(selectedRulesItem.ruleNo, params)
    setIsRefetchingRules(true)
    if(result.status !== 'error') {
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Sukses melakukan perubahan',
      })
    }
  }
  
  /* HANDLE CANCEL BUTTON */
  const handleCancelButton = () => {
    setDialogCancelShown(false)
  }

  /* HANDLE BUANG BUTTON */
  const handleBuangButton = () => {
    setDialogCancelShown({})
    setSelectedRulesItem(false)
    setSelectionModel([])
    setDialogParams(initialDialogParams)
  }

  /* SIDE EFFECT SET DEFAULT VALUE */
  useEffect(() => {
    if(Boolean(selectedRulesItem)) {
      const findTypeDetail = geofenceType.find(item => item.value === selectedRulesItem.type)

      setDialogParams({
        ...dialogParams,
        name: selectedRulesItem.name,
        type: findTypeDetail?.value,
        labelGeofence: findTypeDetail?.label,
        description: selectedRulesItem.description,
        zoneIds : selectedRulesItem.zoneIds
      })
    } else {
      setSelectionModel([])
      setDialogParams(initialDialogParams)
    }
  }, [selectedRulesItem])

  return (
    <Slide 
      direction='right' 
      in={true} 
      container={pageRef.current}
    >
      <Stack className={classes.panelContainer}>
        {/* HEADER */}
        <Stack direction='row' alignItems='center' className={classes.panelHeader}>
          {/* PANEL TITLE */}
          <Typography
            variant='h6'
            className={classes.panelTitle}
          >
            Pengaturan Ketentuan
          </Typography>
        </Stack>

        <Divider/>

        {/* CONTENT */}
        <Stack flex='1' direction='row' className={classes.ruleSettingsBox}>
          {/* PANEL OBJECTS */}
          <PanelObjects />

          <Box className={classes.settingTabs}>
            {/* CONTENT TABS */}
            <ContentTabs
              tabItemList={tabItems}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              isExtraComponentAvailable={false}
              extraComponent={null}
              tabsHeight={57}
              classNameTab={classes.ruleSettingTab}
            />

            {/* NAME & TYPE TABS */}
            {selectedTab === 'nameAndType' && (
              <Box className={classes.contentTabs}>
                <Typography variant='h6' className={classes.panelTitle}>
                  Nama dan jenis
                </Typography>

                {/* GEOFENCES TYPE */}
                <Box className={`${classes.iconAndFormControlContainer} no-zoom`}>
                  <IconWidgets className={classes.iconFormControl}/>
                  <FormControl variant='standard' className={classes.formControl}>
                    <Autocomplete
                      disablePortal
                      options={geofenceType.map(item => item.label)}
                      className={classes.autocomplete}
                      getOptionLabel={(option) => option}
                      value={dialogParams.labelGeofence}
                      onChange={(event, value, reason, details) => {
                        const findTypeDetail = geofenceType.find(item => item.value === value)

                        setDialogParams({
                          ...dialogParams,
                          labelGeofence: value,
                          type: findTypeDetail.value
                        })
                      }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          variant='standard'
                          label='Jenis'
                        />
                      )}
                    />
                  </FormControl>
                </Box>

                {/* NAME */}
                <Box className={`${classes.iconAndFormControlContainer} no-zoom`}>
                  <IconFormatColorText className={classes.iconFormControl}/>
                  <FormControl 
                    variant='standard' 
                    className={classes.formControl}
                  >
                    <CustomInputLabel>
                      Nama
                    </CustomInputLabel>

                    <CustomInput
                      type='text'
                      name='name'
                      value={dialogParams.name}
                      onChange={handleDialogParamsChange}
                    />
                  </FormControl>
                </Box>

                {/* DESCRIPTION */}
                <Box className={`${classes.iconAndFormControlContainer} no-zoom`}>
                  <IconNotes className={classes.iconFormControl}/>
                  <FormControl 
                    variant='standard' 
                    className={classes.formControl}
                  >
                    <CustomInputLabel>
                      Keterangan
                    </CustomInputLabel>
                    <CustomInput
                      type='text'
                      name='description'
                      value={dialogParams.description}
                      onChange={handleDialogParamsChange}
                    />
                  </FormControl>
                </Box>
              </Box>
            )}
            {/* CHOOSE GEOFENCES TABS */}
            {selectedTab === 'settings' && (
              <Box className={classes.contentTabs}>
                <Box className={classes.titleRow}>
                  <Typography variant='h6' className={classes.panelTitle}>
                    Geofence
                  </Typography>

                  <CustomTooltipBlack
                    title='Isi bagian, jika aturan hanya perlu bekerja di dalam/di luar geofence yang dipilih'
                    placement='top'
                  >
                    <IconButton
                      size='small'
                      sx={{ ml: 1 }}
                    >
                      <IconInfo className={classes.iconInfo} fontSize='small' />
                    </IconButton>
                  </CustomTooltipBlack>
                </Box>

                {/* CHOOSE GEOFENCES CONTROL */}
                <Box className={classes.geofenceRow}>
                  <PlaceIcon className={classes.iconFormControl}/>
                  
                  <FormControl variant='standard' className={`${classes.formControl} no-zoom`}>
                    <Autocomplete
                      disablePortal
                      multiple
                      limitTags={2}
                      options={geofencesList ? geofencesList.rows.map(item => item.place_no) : []}
                      className={classes.autocomplete}
                      getOptionLabel={(option) => geofencesList.rows.find(item => item.place_no === option).name}
                      value={dialogParams.zoneIds}
                      onChange={(event, value, reason, details) => {
                        setDialogParams({
                          ...dialogParams,
                          zoneIds: value,
                        })
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            label={geofencesList.rows.find(item => item.place_no === option).name}
                            {...getTagProps({ index })}
                            deleteIcon={<CloseIcon />}
                            className={classes.chipCustomMenu}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          variant='standard'
                          label='Geofence'
                        />
                      )}
                    />
                  </FormControl>

                  <CustomTooltipBlack
                    title='Buat/ubah geofence di bagian pelacakan'
                    placement='top'
                  >
                    <IconButton
                      size='small'
                      sx={{ ml: 1 }}
                    >
                      <IconInfo className={classes.iconInfo} fontSize='small' />
                    </IconButton>
                  </CustomTooltipBlack>
                </Box>
              </Box>
            )}
          </Box>
        </Stack>

        {/* FOOTER */}
        <Stack alignItems='center' direction='row' justifyContent='space-between' flex='0 0 56px' className={classes.footer}>
          <Typography variant='subtitle2' className={classes.selectedInfo}>
            {selectionModel.length > 0 && (
              `Terpilih: ${selectionModel.length} Objek`
            )}
          </Typography>

          <Box>
            {/* CANCEL BUTTON */}
            <CustomDialogActionButton onClick={handleCancelButton} className={classes.customCancel}>
              Batal
            </CustomDialogActionButton>

            {/* SAVE BUTTON */}
            <CustomDialogActionButton 
              onClick={handleSaveButton} 
              className={classes.customSave} 
              disabled={!Boolean(selectedRulesItem)}
            >
              Simpan
            </CustomDialogActionButton>
          </Box>
        </Stack>

        {/* SNACKBAR */}
        <Snackbar
          open={snackbarObject.open}
          setToast={setSnackbarObject}
          severity={snackbarObject.severity}
          title={snackbarObject.title}
          message={snackbarObject.message}
        />

        {/* DIALOG CANCEL */}
        <DialogConfirmation
          title='Membuang Perubahan?'
          caption='Buang perubahan yang belum disimpan atau lanjutkan pengeditan?'
          dialogConfirmationObject={dialogCancelShown}
          setDialogConfirmationObject={setDialogCancelShown}
          cancelButtonText='Buang'
          continueButtonText='Ubah'
          onContinueButtonClick={() => setDialogCancelShown({})}
          onCancelButtonClick={handleBuangButton}
        />
      </Stack>

    </Slide>
  )
}

export default PanelRulesSetting