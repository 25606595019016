// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import Stack from '@mui/material/Stack'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip 
    {...props} 
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.boulder,
    color: 'white',
    fontSize: 14,
    borderRadius: 0,
  },
  '@media only screen and (max-height: 820px)': {
    [`& .${tooltipClasses.tooltip}`]: {
      zoom: 0.85,
    },
  }
}))

const CustomTooltipBlack = (props) => {
  return (
    <Stack
      className='no-zoom'
      sx={{
        '@media only screen and (max-height: 820px)': {
          '& .MuiIconButton-root .MuiSvgIcon-root': {
            zoom: 0.85,
          }
        }
      }}
    >
      <StyledTooltip className='no-zoom' {...props} children={<span>{props.children}</span>} />
    </Stack>
  )
}

export default CustomTooltipBlack