// MUI ICONS
import IconDirectionsBoat from '@mui/icons-material/DirectionsBoat'
import IconDirectionsCar from '@mui/icons-material/DirectionsCar'
import IconDirectionsWalk from '@mui/icons-material/DirectionsWalk'
import IconMotorbikeTwo from 'assets/images/pathIcons/IconMotorbikeTwo'
import IconPedalBike from '@mui/icons-material/PedalBike'
import IconAmbulance from 'assets/images/pathIcons/IconAmbulance'
import IconFiretruck from 'assets/images/pathIcons/IconFiretruck'
import IconJeepWrangler from 'assets/images/pathIcons/IconJeepWrangler'
import IconSemiTruckSideView from 'assets/images/pathIcons/IconSemiTruckSideView'
import IconTowTruck from 'assets/images/pathIcons/IconTowTruck'
import IconWaterCannon from 'assets/images/pathIcons/IconWaterCannon'
import IconYacht from 'assets/images/pathIcons/IconYacht'
import IconPoliceCar from 'assets/images/pathIcons/IconPoliceCar'
import IconSetting from 'assets/images/pathIcons/IconSetting'
import IconGuard from 'assets/images/pathIcons/IconGuard'
import IconEscort from 'assets/images/pathIcons/IconEscort'
import IconPatrol from 'assets/images/pathIcons/IconPatrol'
import IconTpkp from 'assets/images/pathIcons/IconTpkp'
import IconBansar from 'assets/images/pathIcons/IconBansar'
import IconDalmas from 'assets/images/pathIcons/IconDalmas'
import IconNegotiation from 'assets/images/pathIcons/IconNegotiation'
import IconTripping from 'assets/images/pathIcons/IconTripping'
import IconCrossbar from 'assets/images/pathIcons/IconCrossbar'
import IconLandslide from 'assets/images/pathIcons/IconLandslide'
import IconCrashCar from 'assets/images/pathIcons/IconCrashCar'
import IconTrafficLight from 'assets/images/pathIcons/IconTrafficLight'
import IconTrafficJam from 'assets/images/pathIcons/IconTrafficJam'
import IconCarAccident from 'assets/images/pathIcons/IconCarAccident'
import IconWildFire from 'assets/images/pathIcons/IconWildFire'
import IconFire from 'assets/images/pathIcons/IconFire'
import IconFlood from 'assets/images/pathIcons/IconFlood'
import IconFraud from 'assets/images/pathIcons/IconFraud'
import IconDrown from 'assets/images/pathIcons/IconDrown'
import IconEarthquake from 'assets/images/pathIcons/IconEarthquake'
import IconThunderStorm from 'assets/images/pathIcons/IconThunderStorm'
import IconRoadClosure from 'assets/images/pathIcons/IconRoadClosure'
import IconRoadRepair from 'assets/images/pathIcons/IconRoadRepair'
import IconSexualHarassment from 'assets/images/pathIcons/IconSexualHarassment'
import IconMurder from 'assets/images/pathIcons/IconMurder'
import IconRobbery from 'assets/images/pathIcons/IconRobbery'
import IconFighting from 'assets/images/pathIcons/IconFighting'
import IconTheft from 'assets/images/pathIcons/IconTheft'
import IconDrugs from 'assets/images/pathIcons/IconDrugs'
import IconCannabisAbuse from 'assets/images/pathIcons/IconCannabisAbuse'
import IconBodyBanishment from 'assets/images/pathIcons/IconBodyBanishment'
import IconPunch from 'assets/images/pathIcons/IconPunch'
import IconStabbing from 'assets/images/pathIcons/IconStabbing'
import IconGambling from 'assets/images/pathIcons/IconGambling'
import IconCorruption from 'assets/images/pathIcons/IconCorruption'
import IconEviction from 'assets/images/pathIcons/IconEviction'
import IconRadiationCrime from 'assets/images/pathIcons/IconRadiationCrime'
import IconIllegalTransplant from 'assets/images/pathIcons/IconIllegalTransplant'
import IconGratification from 'assets/images/pathIcons/IconGratification'
import IconMoney from 'assets/images/pathIcons/IconMoney'
import IconShield from 'assets/images/pathIcons/IconShield'
import IconKidnapping from 'assets/images/pathIcons/IconKidnapping'
import IconDemonstration from 'assets/images/pathIcons/IconDemonstration'
import IconHighWay from 'assets/images/pathIcons/IconHighway'
import IconOffice from 'assets/images/pathIcons/IconOffice'
import IconHome from 'assets/images/pathIcons/IconHome'
import IconPoliceStation from 'assets/images/pathIcons/IconPoliceStation'
import IconTrafficCone from 'assets/images/pathIcons/IconTrafficCone'
import IconTrafficBarrier from 'assets/images/pathIcons/IconTrafficBarrier'
import IconStation from 'assets/images/pathIcons/IconStation'

export const iconListDutyTypes = [
  {
    id: 1,
    label: 'IconDirectionsCar',
    Icon: IconDirectionsCar,
  },
  {
    id: 2,
    label: 'IconMotorbikeTwo',
    Icon: IconMotorbikeTwo,
  },
  {
    id: 3,
    label: 'IconPedalBike',
    Icon: IconPedalBike,
  },
  {
    id: 4,
    label: 'IconDirectionsWalk',
    Icon: IconDirectionsWalk,
  },
  {
    id: 5,
    label: 'IconDirectionsBoat',
    Icon: IconDirectionsBoat,
  },
  {
    id: 6,
    label: 'IconAmbulance',
    Icon: IconAmbulance,
  },
  {
    id: 7,
    label: 'IconFiretruck',
    Icon: IconFiretruck,
  },
  {
    id: 8,
    label: 'IconJeepWrangler',
    Icon: IconJeepWrangler,
  },
  {
    id: 9,
    label: 'IconSemiTruckSideView',
    Icon: IconSemiTruckSideView,
  },
  {
    id: 10,
    label: 'IconTowTruck',
    Icon: IconTowTruck,
  },
  {
    id: 11,
    label: 'IconWaterCannon',
    Icon: IconWaterCannon,
  },
  {
    id: 12,
    label: 'IconYacht',
    Icon: IconYacht,
  },
  {
    id: 13,
    label: 'IconPoliceCar',
    Icon: IconPoliceCar,
  }
]

export const iconListActivityTypes = [
  {
    id: 1,
    label: 'IconSetting',
    Icon: IconSetting
  },
  {
    id: 2,
    label: 'IconGuard',
    Icon: IconGuard
  },
  {
    id: 3,
    label: 'IconEscort',
    Icon: IconEscort
  },
  {
    id: 4,
    label: 'IconPatrol',
    Icon: IconPatrol
  },
  {
    id: 5,
    label: 'IconTpkp',
    Icon: IconTpkp
  },
  {
    id: 6,
    label: 'IconBansar',
    Icon: IconBansar
  },
  {
    id: 7,
    label: 'IconDalmas',
    Icon: IconDalmas
  },
  {
    id: 8,
    label: 'IconNegotiation',
    Icon: IconNegotiation
  },
  {
    id: 9,
    label: 'IconTripping',
    Icon: IconTripping
  }
]

export const iconListTagEvidences = [
  {
    id: 1,
    label: 'IconCrossbar',
    Icon: IconCrossbar
  },
  {
    id: 2,
    label: 'IconLandslide',
    Icon: IconLandslide
  },
  {
    id: 3,
    label: 'IconCrashCar',
    Icon: IconCrashCar
  },
  {
    id: 4,
    label: 'IconTrafficLight',
    Icon: IconTrafficLight
  },
  {
    id: 5,
    label: 'IconMurder',
    Icon: IconMurder
  },
  {
    id: 6,
    label: 'IconTrafficJam',
    Icon: IconTrafficJam
  },
  {
    id: 7,
    label: 'IconCarAccident',
    Icon: IconCarAccident
  },
  {
    id: 8,
    label: 'IconWildFire',
    Icon: IconWildFire
  },
  {
    id: 9,
    label: 'IconFire',
    Icon: IconFire
  },
  {
    id: 10,
    label: 'IconDemonstration',
    Icon: IconDemonstration
  },
  {
    id: 11,
    label: 'IconFlood',
    Icon: IconFlood
  },
  {
    id: 12,
    label: 'IconFraud',
    Icon: IconFraud
  },
  {
    id: 13,
    label: 'IconDrown',
    Icon: IconDrown
  },
  {
    id: 14,
    label: 'IconEarthquake',
    Icon: IconEarthquake
  },
  {
    id: 15,
    label: 'IconRobbery',
    Icon: IconRobbery
  },
  {
    id: 16,
    label: 'IconThunderStorm',
    Icon: IconThunderStorm
  },
  {
    id: 17,
    label: 'IconRoadClosure',
    Icon: IconRoadClosure
  },
  {
    id: 18,
    label: 'IconRoadRepair',
    Icon: IconRoadRepair
  },
  {
    id: 19,
    label: 'IconSexualHarassment',
    Icon: IconSexualHarassment
  },
  {
    id: 20,
    label: 'IconFighting',
    Icon: IconFighting
  },
  {
    id: 21,
    label: 'IconTheft',
    Icon: IconTheft
  },
  {
    id: 22,
    label: 'IconDrugs',
    Icon: IconDrugs
  },
  {
    id: 23,
    label: 'IconCannabisAbuse',
    Icon: IconCannabisAbuse
  },
  {
    id: 24,
    label: 'IconBodyBanishment',
    Icon: IconBodyBanishment
  },
  {
    id: 25,
    label: 'IconPunch',
    Icon: IconPunch
  },
  {
    id: 26,
    label: 'IconStabbing',
    Icon: IconStabbing
  },
  {
    id: 27,
    label: 'IconGambling',
    Icon: IconGambling
  },
  {
    id: 28,
    label: 'IconCorruption',
    Icon: IconCorruption
  },
  {
    id: 29,
    label: 'IconEviction',
    Icon: IconEviction
  },
  {
    id: 30,
    label: 'IconRadiationCrime',
    Icon: IconRadiationCrime
  },
  {
    id: 31,
    label: 'IconIllegalTransplant',
    Icon: IconIllegalTransplant
  },
  {
    id: 32,
    label: 'IconGratification',
    Icon: IconGratification
  },
  {
    id: 33,
    label: 'IconMoney',
    Icon: IconMoney
  },
  {
    id: 34,
    label: 'IconShield',
    Icon: IconShield
  },
  {
    id: 35,
    label: 'IconKidnapping',
    Icon: IconKidnapping
  },
]

export const iconListTagLocation = [
  {
    id: 1,
    label: 'IconHighWay',
    Icon: IconHighWay
  },
  {
    id: 2,
    label: 'IconOffice',
    Icon: IconOffice
  },
  {
    id: 3,
    label: 'IconHome',
    Icon: IconHome
  },
  {
    id: 4,
    label: 'IconPoliceStation',
    Icon: IconPoliceStation
  },
  {
    id: 5,
    label: 'IconTrafficLight',
    Icon: IconTrafficLight
  },
  {
    id: 6,
    label: 'IconTrafficCone',
    Icon: IconTrafficCone
  },
  {
    id: 7,
    label: 'IconTrafficBarrier',
    Icon: IconTrafficBarrier
  },
  {
    id: 8,
    label: 'IconStation',
    Icon: IconStation
  },
]