// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconSprint = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M4.93912 2.16016C4.65102 2.16019 4.37473 2.27465 4.17101 2.47836C3.9673 2.68208 3.85284 2.95837 3.85281 3.24647V13.0233C3.85077 13.1673 3.87737 13.3102 3.93104 13.4438C3.98472 13.5773 4.06442 13.6989 4.16549 13.8014C4.26657 13.904 4.38701 13.9854 4.51982 14.0409C4.65263 14.0965 4.79516 14.1251 4.93912 14.1251C5.08309 14.1251 5.22562 14.0965 5.35843 14.0409C5.49124 13.9854 5.61168 13.904 5.71275 13.8014C5.81383 13.6989 5.89352 13.5773 5.9472 13.4438C6.00088 13.3102 6.02747 13.1673 6.02544 13.0233V4.33279H20.1475V18.4549C20.1475 19.0687 19.675 19.5412 19.0612 19.5412C18.4616 19.5412 17.9749 19.0535 17.9749 18.4549V17.3686C17.9749 16.7689 17.4882 16.2823 16.8886 16.2823H2.76649C2.16685 16.2823 1.68018 16.7689 1.68018 17.3686V18.4549C1.68018 20.2549 3.1391 21.7138 4.93912 21.7138H19.0612C20.8482 21.7138 22.3202 20.2418 22.3202 18.4549V3.24647C22.3201 2.95837 22.2057 2.68208 22.002 2.47836C21.7983 2.27465 21.522 2.16019 21.2339 2.16016H4.93912ZM8.74123 7.59173C8.4414 7.59173 8.19807 7.83507 8.19807 8.13489V9.22121C8.19807 9.52103 8.4414 9.76437 8.74123 9.76437H9.82754C10.1274 9.76437 10.3707 9.52103 10.3707 9.22121V8.13489C10.3707 7.83507 10.1274 7.59173 9.82754 7.59173H8.74123ZM13.6296 7.59173C13.03 7.59173 12.5433 8.0784 12.5433 8.67805C12.5433 9.2777 13.03 9.76437 13.6296 9.76437H16.8886C17.4882 9.76437 17.9749 9.2777 17.9749 8.67805C17.9749 8.0784 17.4882 7.59173 16.8886 7.59173H13.6296ZM8.74123 11.937C8.4414 11.937 8.19807 12.1803 8.19807 12.4802V13.5665C8.19807 13.8663 8.4414 14.1096 8.74123 14.1096H9.82754C10.1274 14.1096 10.3707 13.8663 10.3707 13.5665V12.4802C10.3707 12.1803 10.1274 11.937 9.82754 11.937H8.74123ZM13.6296 11.937C13.03 11.937 12.5433 12.4237 12.5433 13.0233C12.5433 13.623 13.03 14.1096 13.6296 14.1096H16.8886C17.4882 14.1096 17.9749 13.623 17.9749 13.0233C17.9749 12.4237 17.4882 11.937 16.8886 11.937H13.6296Z'/>
    </SvgIcon>
  )
}

export default IconSprint