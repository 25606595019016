// MUIS
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './dataGridHeaderCellUseStyles'

const DataGridHeaderCell = (props) => {
  const {
    icon,
    headerName,
  } = props

  const classes = useStyles()

  const Icon = icon

  return (
    <>
      {/* ICON */}
      <Icon className={classes.columnIcon}/>

      {/* HEADER NAME */}
      <Typography
        variant='caption'
        fontWeight={600}
      >
        {headerName}
      </Typography>
    </>
  )
}

export default DataGridHeaderCell