import { useRef, useState, useEffect, useContext } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

// COMPONENTS
import CustomDialogActions from 'components/Customs/CustomDialogActions'
import CustomDialogDelete from 'components/Customs/CustomDialogDelete'
import CustomFormControl from 'components/Customs/CustomFormControl'
import CustomInputNew from 'components/Customs/CustomInputNew'
import CustomInputLabelNew from 'components/Customs/CustomInputLabelNew'
import DateRangeAndTimePicker from 'components/DateRangeAndTimePicker/DateRangeAndTimePicker'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import DialogSelectDevices from '../DialogSelectDevices/DialogSelectDevices'
import InputLocationNew from 'components/InputLocation/InputLocationNew'

// CONSTANTS
import { basePaths } from 'constants/paths'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import Dialog from '@mui/material/Dialog'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import OutlinedInput from '@mui/material/OutlinedInput'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconAddCircle from '@mui/icons-material/AddCircle'
import IconArrowDropDown from '@mui/icons-material/ArrowDropDown'
import IconClose from '@mui/icons-material/Close'
import IconDateRange from '@mui/icons-material/DateRange'
import IconDelete from '@mui/icons-material/Delete'
import IconInfo from '@mui/icons-material/Info'
import IconMap from '@mui/icons-material/Map'
import IconPhoneAndroid from '@mui/icons-material/PhoneAndroid'
import IconPlace from '@mui/icons-material/Place'
import IconSearch from '@mui/icons-material/Search'
import IconTextFields from '@mui/icons-material/TextFields'

// SERVICES
import { getDeviceList } from 'services/DeviceService'
import { deleteDispatch, postDispatchCreate, putDispatchUpdate } from 'services/DispatchService'

// STYLES
import useStyles from './dialogAddItemUseStyles'

// UTILS
import { getConnectionStatusColor } from 'utilities'

const DialogAddItem = (props) => {
  const { dialogAddShown, reloadData } = props
  const classes = useStyles()
  const parentDevicesRef = useRef()
  const history = useHistory()
  const { setSnackbarObject } = useContext(AllPagesContext)

  const defaultParamsObject = {
    label: '',
    startTime: moment().startOf('day').toISOString(),
    endTime: moment().endOf('day').toISOString(),
    deviceNos: [],
    address: {
      fullAddress: '',
      latitude: 0,
      longitude: 0,
    },
    dispatchType: 'PATROL',
    category: 'NORMAL'
  }

  const [isDateTimeRangeOpen, setIsDateTimeRangeOpen] = useState(false)
  const [paramsObject, setParamsObject] = useState(defaultParamsObject)
  const [circle, setCircle] = useState(null)
  const [circleRadius, setCircleRadius] = useState(50)
  const [dispatchLocation, setDispatchLocation] = useState('')
  const [isExpandMapShown, setIsExpandMapShown] = useState(false)
  const [devicesAnchorRef, setDevicesAnchorRef] = useState(null)
  const [searchDevices, setSearchDevices] = useState('')
  const [dataDevicesList, setDataDevicesList] = useState([])
  const [selectedDevices, setSelectedDevices] = useState([])
  const [dialogDeletePatrol, setDialogDeletePatrol] = useState({})

  const fetchDevicesList = async () => {
    const responseDevices = await getDeviceList({ limit: 10000 })

    if(responseDevices?.data?.length) setDataDevicesList(responseDevices?.data?.map((item) => {
      item.id = item.deviceNo
      return item
    }))
  }

  const handleDialogClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      history.push(basePaths.dispatchPatrols, { mode: null })
    }
  }

  const handleParamsChange = (key, value) => {
    return setParamsObject({
      ...paramsObject,
      [key]: value
    })
  }

  const handleButtonSaveClick = async () => {
    let tempParamsObject = {...paramsObject}
    tempParamsObject.startTime = moment(paramsObject.startTime).toISOString()
    tempParamsObject.endTime = moment(paramsObject.endTime).toISOString()
    tempParamsObject.address.fullAddress = dispatchLocation
    tempParamsObject.deviceNos = paramsObject.deviceNos.map(item => item.deviceNo)

    // VALIDATION ALL FIELD MUST FILLED
    let isFoundEmpty = false
    Object.keys(tempParamsObject).forEach(keyParent => {
      if(keyParent === 'address') {
        Object.keys(tempParamsObject[keyParent]).forEach(keyAddress => {
          if(!tempParamsObject[keyParent][keyAddress]) {
            isFoundEmpty = true
          }
        })
      } else if(keyParent === 'deviceNos') {
        if(!tempParamsObject[keyParent].length) {
          isFoundEmpty = true
        }
      } else {
        if(!tempParamsObject[keyParent]) {
          isFoundEmpty = true
        }
      }
    })

    if(isFoundEmpty) {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Semua Field Harus Terisi',
      })
      return
    }

    tempParamsObject.description = ''

    if(dialogAddShown?.mode === 'add') {
      const response = await postDispatchCreate(tempParamsObject)

      if(response?.status === 'error') {
        setSnackbarObject({
          open: true,
          severity: 'error',
          title: '',
          message: 'Gagal Membuat Penugasan Kegiatan',
        })
      } else {
        setSnackbarObject({
          open: true,
          severity: 'success',
          title: '',
          message: 'Berhasil Membuat Penugasan Kegiatan',
        })
      }
    } else if (dialogAddShown?.mode === 'edit') {
      const response = await putDispatchUpdate(paramsObject.dispatchNo, tempParamsObject)

      if(response?.status === 'error') {
        setSnackbarObject({
          open: true,
          severity: 'error',
          title: '',
          message: 'Gagal Mengubah Penugasan Kegiatan',
        })
      } else {
        setSnackbarObject({
          open: true,
          severity: 'success',
          title: '',
          message: 'Berhasil Mengubah Penugasan Kegiatan',
        })
      }
    }

    reloadData()
    handleDialogClose()
  }

  const handleSelectDateRangeButtonClick = (inputNewDateRange) => {
    setParamsObject({
      ...paramsObject,
      startTime: inputNewDateRange[0],
      endTime: inputNewDateRange[1]
    })
    setIsDateTimeRangeOpen(false)
  }

  const handleMenuClose = (inputSetSearch, inputSetAnchor) => {
    inputSetSearch('')
    inputSetAnchor(null)
  }

  const checkIsCheckedDevices = (inputDeviceNo) => {
    const findDevices = selectedDevices?.find(item => item?.deviceNo === inputDeviceNo)
    return Boolean(findDevices)
  }

  const handleCheckedDevices = (inputItem) => {
    let tempParamsDevices = [...selectedDevices]
    if(!checkIsCheckedDevices(inputItem?.deviceNo)) {
      tempParamsDevices.push(inputItem)
    } else {
      tempParamsDevices = tempParamsDevices.filter(item => item.deviceNo !== inputItem.deviceNo)
    }
    setSelectedDevices([...tempParamsDevices])
  }

  const handleDeleteSelectedDevices = (inputItem) => {
    let tempParamsDevices = paramsObject?.deviceNos.filter(item => item.deviceNo !== inputItem.deviceNo)
    setSelectedDevices(tempParamsDevices)
    setParamsObject({
      ...paramsObject,
      deviceNos: [...tempParamsDevices]
    })
  }

  const handleInitEdit = async () => {
    if(dialogAddShown?.mode === 'edit' && dataDevicesList.length) {
      let itemDetail = dialogAddShown?.data
      const deviceDetail = dataDevicesList?.find(item => item.id === itemDetail?.deviceNo)
      itemDetail.deviceNos = [deviceDetail]

      setSelectedDevices([deviceDetail])
      setParamsObject(itemDetail)
      setDispatchLocation(itemDetail?.address?.fullAddress)
      setSearchDevices('')
    } else {
      setParamsObject(defaultParamsObject)
      setSelectedDevices([])
      setSearchDevices('')
      setDispatchLocation('')
    }
  }

  const handleDeleteDispatchPatrol = async (dispatchNo) => {
    const response = await deleteDispatch(dispatchNo)

    if(response?.status !== 'error') {
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Berhasil Menghapus Penugasan Kegiatan',
      })
    }

    reloadData()
    setDialogDeletePatrol({})
    handleDialogClose()
  }

  useEffect(() => {
    fetchDevicesList()
  }, [])

  useEffect(() => {
    handleInitEdit()
  }, [dialogAddShown, dataDevicesList])

  return (
    <>
      <CustomDialogDelete
        open={dialogAddShown}
        className={classes.dialogAdd}
        sx={isExpandMapShown ? {
          '& .MuiPaper-root': {
            height: '100% !important',
          },
        } : {}}
        onClose={handleDialogClose}
      >
        <Stack className={classes.dialogHeader}>
          <Typography className={classes.dialogHeaderTitle}>Penugasan</Typography>

          <IconButton
            onClick={() => history.push(basePaths.dispatchPatrols, { mode: null })}
          >
            <IconClose />
          </IconButton>
        </Stack>

        <Stack className={classes.dialogContent}>
          <Stack marginBottom='16px'>
            {/* NAME PENUGASAN */}
            <CustomFormControl variant='filled' className={classes.formControl}>
              <CustomInputLabelNew shrink={true}>
                <IconTextFields />
                <Typography variant='subtitle1'>Nama Penugasan</Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Masukkan Nama Penugasan'
                onChange={event => handleParamsChange('label', event.target.value)}
                value={paramsObject.label}
              />
            </CustomFormControl>
          </Stack>

          <Stack marginBottom='16px' direction='row'>
            <Stack width='50%' paddingRight='10px'>
              {/* START DATE */}
              <CustomFormControl variant='filled' className={`${classes.formControl} formControlDatePicker`}>
                <CustomInputLabelNew shrink={true}>
                  <IconDateRange />
                  <Typography variant='subtitle1'>Mulai</Typography>
                </CustomInputLabelNew>

                <Stack className={classes.dateTimePickerWrapper} direction='row'>
                  <Stack className={classes.boxPreviewDatePicker} onClick={() => setIsDateTimeRangeOpen(true)}>
                    <Stack direction='row' alignItems='center' width='148px'>
                      <Typography flex={1} variant='subtitle2'>{moment(paramsObject.startTime).format('DD MMM yyyy')}</Typography>

                      <IconArrowDropDown />
                    </Stack>

                    <Stack direction='row' alignItems='center' paddingLeft='16px' flex={1}>
                      <Typography flex={1} variant='subtitle2'>{moment(paramsObject.startTime).format('HH:mm')}</Typography>

                      <IconArrowDropDown />
                    </Stack>
                  </Stack>
                </Stack>
              </CustomFormControl>
            </Stack>

            <Stack width='50%' paddingLeft='10px'>
              {/* END DATE */}
              <CustomFormControl variant='filled' className={`${classes.formControl} formControlDatePicker`}>
                <CustomInputLabelNew shrink={true}>
                  <IconDateRange />
                  <Typography variant='subtitle1'>Selesai</Typography>
                </CustomInputLabelNew>

                <Stack className={classes.dateTimePickerWrapper} direction='row'>
                  <Stack className={classes.boxPreviewDatePicker} onClick={() => setIsDateTimeRangeOpen(true)}>
                    <Stack direction='row' alignItems='center' width='148px'>
                      <Typography flex={1} variant='subtitle2'>{moment(paramsObject.endTime).format('DD MMM yyyy')}</Typography>

                      <IconArrowDropDown />
                    </Stack>

                    <Stack direction='row' alignItems='center' paddingLeft='16px' flex={1}>
                      <Typography flex={1} variant='subtitle2'>{moment(paramsObject.endTime).format('HH:mm')}</Typography>

                      <IconArrowDropDown />
                    </Stack>
                  </Stack>
                </Stack>
              </CustomFormControl>
            </Stack>
          </Stack>

          <Stack marginBottom='16px'>
            {/* LOCATION INPUT */}
            <CustomFormControl variant='filled' className={classes.formControl}>
              <CustomInputLabelNew shrink={true}>
                <IconPlace />
                <Typography variant='subtitle1'>Lokasi</Typography>
              </CustomInputLabelNew>

              <Stack className={classes.buttonExpandWrapper}>
                <Button
                  variant='text'
                  startIcon={<IconMap />}
                  disableRipple
                  className={classes.buttonExpandMap}
                  onClick={() => setIsExpandMapShown(!isExpandMapShown)}
                >
                  Lihat Peta
                </Button>
              </Stack>

              <InputLocationNew
                location={dispatchLocation}
                setLocation={setDispatchLocation}
                circle={circle}
                setCircle={setCircle}
                radius={circleRadius}
                setRadius={setCircleRadius}
                customPlaceholder='Masukkan Lokasi Penugasan'
                getLatLng={latLng => setParamsObject({
                  ...paramsObject,
                  address: {
                    latitude: latLng.lat,
                    longitude: latLng.lng,
                  }
                })}
                latitudeProp={paramsObject?.address?.latitude}
                longitudeProp={paramsObject?.address?.longitude}
                isButtonExpandMapShown={false}
                isExpandMapShown={isExpandMapShown}
                suggestContainerWidth='520px'
                isRadiusInputShown={false}
              />
            </CustomFormControl>
          </Stack>

          {/* ADD DEVICES */}
          <CustomFormControl variant='filled' className={classes.formControl}>
            <CustomInputLabelNew shrink={true}>
              <IconPhoneAndroid />
              <Typography variant='subtitle1'>Perangkat</Typography>
            </CustomInputLabelNew>

            <Stack className={classes.buttonExpandWrapper}>
              {dialogAddShown?.mode !== 'edit' && <Button
                variant='text'
                startIcon={<IconAddCircle />}
                disableRipple
                className={classes.buttonExpandMap}
                onClick={(event) => setDevicesAnchorRef(devicesAnchorRef ? null : parentDevicesRef.current)}
              >
                Tambahkan
              </Button>}
            </Stack>

            <Stack paddingTop='40px' ref={parentDevicesRef}>
              {paramsObject.deviceNos?.length ? <Stack direction='row' flexWrap='wrap' className={classes.listSelectedDevices}>
                {paramsObject.deviceNos.map((item, index) => (
                  <Chip
                    key={index}
                    className={classes.chipSelectedDevice}
                    label={item?.label}
                    onDelete={dialogAddShown?.mode === 'add' ? () => handleDeleteSelectedDevices(item) : null}
                    disabled={dialogAddShown?.mode === 'edit'}
                  />
                ))}
              </Stack> : ''}
            </Stack>

            {/* DIALOG ADD DEVICES */}
            <Menu
              open={Boolean(devicesAnchorRef)}
              anchorEl={devicesAnchorRef}
              className={`${classes.dialogMenu} ${classes.dialogMenuOnCenter} no-zoom`}
              onClose={() => {
                setSelectedDevices([...paramsObject.devices])
                handleMenuClose(setSearchDevices, setDevicesAnchorRef)
              }}
              transformOrigin={{
                horizontal: 'left',
                vertical: 'top'
              }}
            >
              <DialogSelectDevices
                className='zoom'
                isHaveButtonAction
                onButtonSaveClick={() => {
                  setParamsObject({
                    ...paramsObject,
                    deviceNos: [...selectedDevices]
                  })
                  handleMenuClose(setSearchDevices, setDevicesAnchorRef)
                }}
                onButtonCancelClick={() => {
                  setSelectedDevices([...paramsObject.deviceNos])
                  handleMenuClose(setSearchDevices, setDevicesAnchorRef)
                }}
                customHeader={(
                  <Stack className={classes.dialogMenuHeader}>
                    <Typography variant='subtitle1' className={classes.dialogMenuTitleHeader}>Pilih Perangkat</Typography>
                      
                    <FormControl>
                      <OutlinedInput
                        className={classes.inputDevicesSearch}
                        placeholder='Pencarian'
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconSearch />
                          </InputAdornment>
                        }
                        onChange={event => setSearchDevices(event.target.value)}
                      />
                    </FormControl>
                  </Stack>
                )}
              >
                <List>
                  {dataDevicesList?.filter(item => item.label.toLowerCase().includes(searchDevices.toLowerCase()))?.map(item => {
                    return (
                      <ListItem
                        className={classes.listMenuItemDevices}
                        key={item.id}
                        onClick={() => handleCheckedDevices(item)}
                      >
                        <Checkbox checked={checkIsCheckedDevices(item.deviceNo)}/>
                        <ListItemText className={classes.listItemTextDevice} primary={item.label}/>

                        <Typography
                          variant='caption'
                          sx={{
                            color: getConnectionStatusColor(item.status.toLowerCase())
                          }}
                        >{item.status.toLowerCase()}</Typography>
                      </ListItem>
                    )
                  })}
                </List>
              </DialogSelectDevices>
            </Menu>
          </CustomFormControl>

          {dialogAddShown?.mode === 'edit' && <Alert className={classes.alertDangerBox} severity='error' icon={<IconInfo color='error'/>}>
            <Typography variant='caption' color='text.secondary'>
              Mengubah daftar perangkat saat ini tidak tersedia.
            </Typography>
          </Alert>}
        </Stack>

        <CustomDialogActions>
          {dialogAddShown?.mode === 'edit' && (
            <>
              <Button
                className={classes.btnDelete}
                onClick={() => setDialogDeletePatrol(true)}
                startIcon={<IconDelete />}
              >HAPUS PENUGASAN</Button>

              <Button
                className={classes.btnCancel}
                onClick={() => handleDialogClose()}
              >BATAL</Button>
            </>
          )}

          <Button
            className={classes.btnSave}
            onClick={() => handleButtonSaveClick()}
          >SIMPAN</Button>
        </CustomDialogActions>
      </CustomDialogDelete>

      {/* DIALOG DATE RANGE PICKER */}
      <Dialog
        open={isDateTimeRangeOpen}
        onClose={() => setIsDateTimeRangeOpen(false)} 
        className={classes.dateRangeAndTimePickerDialog}
      >
        <DateRangeAndTimePicker
          value={[paramsObject.startTime, paramsObject.endTime]}
          handleSelectButtonClick={handleSelectDateRangeButtonClick}
          handleCancelButtonClick={() => setIsDateTimeRangeOpen(false)}
        />
      </Dialog>

      {/* DIALOG DELETE EVIDENCES */}
      <DialogConfirmation
        title='Hapus Penugasan Kegiatan'
        caption='Apa Anda yakin ingin menghapus penugasan kegiatan ini?'
        dialogConfirmationObject={dialogDeletePatrol}
        setDialogConfirmationObject={setDialogDeletePatrol}
        cancelButtonText='Batal'
        continueButtonText='Hapus'
        onContinueButtonClick={() => handleDeleteDispatchPatrol(paramsObject.dispatchNo)}
        onCancelButtonClick={() => setDialogDeletePatrol({})}
      />
    </>
  )
}

export default DialogAddItem