// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 24,
    position: 'relative',
  },
  groupingRow: {
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  columnActions: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  normalText: {
    textTransform: 'capitalize',
  },
}))

export default useStyles
