// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  dialogMediaPreviewRoot: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1072,
  },
  imagePreview: {
    height: 530,
    width: 1024,
    objectFit: 'contain',
    backdropFilter: 'blur(15px)'
  },
  launchIcon: {
    color: '#717171',
    width: '30px',
    height: '30px',
    marginTop: 5
  },
  closeButton: {
    color: 'white'
  },
  imageTitle: {
    fontSize: 20,
    fontWeight: 400,
  },
}))

export default useStyles