// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  dialogAdd: {
    '& .MuiPaper-root': {
      width: 580,
      padding: 0,
      height: 540,
    }
  },
  dialogHeader: {
    padding: '20px 24px',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    boxShadow: `0px 5px 8px ${alpha(theme.palette.common.black, 0.15)}`
  },
  dialogHeaderTitle: {
    fontSize: 20,
    fontWeight: 600,
    flex: 1,
  },
  dialogContent: {
    flex: 1,
    padding: '20px 24px',
    overflowY: 'auto',
    background: 'linear-gradient(#fff 30%, rgba(255,255,255,0)), linear-gradient(rgba(255,255,255,0), #fff 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0,0,0,0.08), rgba(255,255,255,0)), radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,0.08), rgba(255,255,255,0)) 0 100%',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#fff',
    backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
    backgroundAttachment: 'local, local, scroll, scroll',
  },
  formControl: {
    borderRadius: '2px !important',
    '& .formControlDatePicker': {
      cursor: 'pointer'
    },
    '&:last-child': {
      marginBottom: 0,
    }
  },
  dateTimePickerWrapper: {
    padding: '40px 4px 4px 4px'
  },
  boxPreviewDatePicker: {
    backgroundColor: theme.palette.common.white,
    alignItems: 'center',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    flex: 1,
    padding: 8,
    height: 40,
    borderRadius: 2,
    cursor: 'pointer'
  },
  btnSave: {
    fontWeight: 700,
  },
  btnCancel: {
    color: colors.raven,
    fontWeight: 700,
    marginRight: 12,
    '&:hover': {
      backgroundColor: alpha(colors.raven, 0.1),
    }
  },
  btnDelete: {
    color: theme.palette.error.main,
    fontWeight: 700,
    marginRight: 'auto',
    marginLeft: -8,
  },
  dateRangeAndTimePickerDialog: {
    '& .MuiDialog-paper': {
      maxWidth: 'unset',
    },
    '& .MuiPaper-root': {
      borderRadius: 0,
    },
  },
  buttonExpandWrapper: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  buttonExpandMap: {
    fontWeight: 600,
    fontSize: 13,
    '& .MuiButton-startIcon': {
      marginRight: 6,
    }
  },
  dialogMenu: {
    marginTop: 8,
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
      width: 368,
      '@media only screen and (max-height: 820px)': {
        width: 312,
      }
    },
    '& .MuiMenu-list': {
      padding: 0,
    }
  },
  dialogMenuOnCenter: {
    transform: 'translate(84px, -244px) !important',

    '@media only screen and (max-height: 820px)': {
      transform: 'translate(-8px, -120px) !important',
    },
  },
  dialogMenuHeader: {
    boxShadow: `0px 2px 4px ${alpha(theme.palette.common.black, 0.16)}`,
  },
  dialogMenuTitleHeader: {
    padding: '16px 24px',
    fontWeight: 600,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
  },
  inputDevicesSearch: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  listMenuItemDevices: {
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
    cursor: 'pointer',
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  listSelectedDevices: {
    backgroundColor: theme.palette.common.white,
    margin: '0 4px 4px 4px',
    borderRadius: 2,
    padding: 4,
  },
  chipSelectedDevice: {
    backgroundColor: colors.raven,
    color: theme.palette.common.white,
    height: 28,
    margin: 4,
    '& .MuiChip-deleteIcon': {
      color: alpha(theme.palette.common.white, 0.64),
      '&:hover': {
        color: alpha(theme.palette.common.white, 0.64)
      }
    },
    '& .MuiChip-label': {
      fontWeight: 500,
    },
    '&.Mui-disabled': {
      opacity: 0.52
    }
  },
  listItemTextDevice: {
    '& .MuiListItemText-primary': {
      fontWeight: 600,
    }
  },
  alertDangerBox: {
    width: 'auto !important',
    borderRadius: 2,
    marginTop: 8,
    alignItems: 'center',
    paddingLeft: '8px !important',
    '& .MuiAlert-icon': {
      marginRight: 8,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 20,
    }
  }
}))

export default useStyles