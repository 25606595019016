// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'hidden',
    '& .leaflet-draw-section': {
      display: 'none'
    },
    '& .leaflet-edit-resize': {
      display: 'none !important'
    }
  },
  mapContainer: {
    position: 'relative',
    zIndex: 1,
    '&.leaflet-container': {
      width: '100%',
      height: '100%',
    },
  },
  panelContainer: {
    position: 'absolute',
    zIndex: 2,
    backgroundColor: 'transparent',
    pointerEvents: 'none',
  },
  panelLeft: {
    left: 24,
    top: 24,
    bottom: 24,
    width: 420,
    display: 'flex',
    flexDirection: 'column',
  },
  dateRangeAndTimePickerDialog: {
    '& .MuiDialog-paper': {
      maxWidth: 'unset',
    },
  },
}))

export default useStyles