import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// COMPONENTS
import CustomFormControl from 'components/Customs/CustomFormControl'
import CustomInput from 'components/Customs/CustomInput'
import CustomInputNew from 'components/Customs/CustomInputNew'
import CustomInputLabelNew from 'components/Customs/CustomInputLabelNew'
import CustomMenuItem from 'components/Customs/CustomMenuItem'
import DialogAddOrEdit from 'components/DialogAddOrEditNew/DialogAddOrEdit'
import SimpleBackdrop from 'components/Partials/SimpleBackdrop'

// CONSTANTS
import { basePaths } from 'constants/paths'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconArrowDropDown from '@mui/icons-material/ArrowDropDown'
import IconSearch from '@mui/icons-material/Search'

// PATH ICONS
import IconListView from 'assets/images/pathIcons/IconListView'
import IconPoliceMan from 'assets/images/pathIcons/IconPoliceMan'
import IconPoliceBadgeOutlined from 'assets/images/pathIcons/IconPoliceBadgeOutlined'
import IconPoliceStation from 'assets/images/pathIcons/IconPoliceStation'

// SERVICES
import { 
  getOfficerDepartmentList, 
  postCreateNewOfficer, 
  putEditOfficer,
} from 'services/settingsOfficersServices'

// STYLES
import useStyles from './dialogAddOrEditSettingsOfficerUseStyles'

const DialogAddOrEditDispatchOfficer = (props) => {
  const {
    dialogType,
    dialogAddOrEditOfficer,
    setDialogAddOrEditOfficer,
    reloadData,
    pageRef,
  } = props

  const classes = useStyles()
  const { setSnackbarObject } = useContext(AllPagesContext)

  const history = useHistory()
  const location = useLocation()

  let initialInformation = {
    officerName: '',
    officerRank: '',
    officerCode: '',
    department: '',
    officerNo: '',
    roleNo: '',
  }
  if (dialogType === 'edit') {
    initialInformation = {
      officerName: dialogAddOrEditOfficer.row.officerName,
      officerCode: dialogAddOrEditOfficer.row.officerCode,
      officerRank: dialogAddOrEditOfficer.row.officerRank,
      department: dialogAddOrEditOfficer.row.roleName,
      officerNo: dialogAddOrEditOfficer.row.officerNo,
      roleNo: '',
    }
  }

  const [informationParams, setInformationParams] = useState(initialInformation)
  const [departmentList, setDepartmentList] = useState([])
  const [departmentMenuAnchor, setDepartmentMenuAnchor] = useState(null)
  const [searchDepartment, setSearchDepartment] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const handleInformationParamsChange = (event) => {
    setInformationParams({
      ...informationParams,
      [event.target.name]: event.target.value,
    })
  }

  const handleMenuItemClicked = (item) => {
    setInformationParams({
      ...informationParams,
      department: item.name,
      roleNo: departmentList.find(department => department.name === item.name)?.id,
    })
    setDepartmentMenuAnchor(null)
  }

  const loadDepartmentList = async () => {
    const resultDepartmentList = await getOfficerDepartmentList()

    if (resultDepartmentList.status !== 'error') {
      const newDepartmentList = resultDepartmentList.map(item => {
        return {
          ...item,
          isShown: true,
        }
      })
      setDepartmentList(newDepartmentList)
    }
  }

  const closeDialog = () => {
    setDialogAddOrEditOfficer(null)
    setInformationParams(initialInformation)
  }

  const handleSaveBtn = async () => {
    setIsLoading(true)

    if (informationParams.officerCode.length < 8 || informationParams.officerCode.length > 8) {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'NRP harus 8 angka',
      })
      setIsLoading(false)
      return
    }

    let resultOfficer
    if (dialogType === 'add') resultOfficer = await postCreateNewOfficer(informationParams)
    else if (dialogType === 'edit') resultOfficer = await putEditOfficer(dialogAddOrEditOfficer.row.id, informationParams)

    if (resultOfficer.status !== 'error') {
      let message
      if (dialogType === 'add') message = 'Sukses Menambahkan Petugas'
      else if (dialogType === 'edit') message = 'Sukses Memperbarui Petugas'

      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message,
      })
      closeDialog()
      reloadData()
      history.push(basePaths.settingsOfficers)
    }

    setIsLoading(false)
  }

  const updateDepartmentListBySearch = () => {
    const newDepartmentList = departmentList.map(item => {
      if (searchDepartment === '') item.isShown = true
      else {
        if (item.name !== '' && item.name.toLowerCase().includes(searchDepartment.toLowerCase())) item.isShown = true
        else item.isShown = false
      }

      return item
    })
    setDepartmentList(newDepartmentList)
  }
  
  useEffect(() => {
    loadDepartmentList()
  }, [])

  useEffect(() => {
    updateDepartmentListBySearch()
  }, [searchDepartment])

  useEffect(() => {
    if (!departmentMenuAnchor) setSearchDepartment('')
  }, [departmentMenuAnchor])

  useEffect(() => {
    if (dialogType === 'edit' && Boolean(dialogAddOrEditOfficer) && departmentList.length > 0) {
      setInformationParams(current => {
        return {
          ...current,
          roleNo: departmentList.find(item => item.name === dialogAddOrEditOfficer.row.roleName)?.id,
        }
      })
    }
  }, [dialogType, dialogAddOrEditOfficer, departmentList])

  useEffect(() => {
    if (
      location.pathname === basePaths.settingsOfficers && 
      location.search !== '?mode=add' &&
      !location.search.includes('?mode=edit')
    ) {
      closeDialog()
    }
  }, [location])

  return (
    <>
      {/* DIALOG ADD OR EDIT */}
      <DialogAddOrEdit
        open={Boolean(dialogAddOrEditOfficer)}
        title='Petugas'
        onCloseIconClick={() => history.push(basePaths.settingsOfficers)}
        containerRef={pageRef}
        contents={[
          <>
            {/* MAIN SECTION TEXT */}
            <Typography variant='h6' className={classes.sectionText}>
              Informasi
            </Typography>

            {/* OFFICER NAME */}
            <CustomFormControl 
              variant='filled' 
              className={classes.formControl}
            >
              <CustomInputLabelNew shrink={true}>
                <IconPoliceMan />
                <Typography variant='subtitle1'>Nama Petugas</Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                placeholder='Masukkan Petugas'
                type='text'
                name='officerName'
                value={informationParams.officerName}
                onChange={handleInformationParamsChange}
                disableUnderline
              />
            </CustomFormControl>

            {/* OFFICER RANK */}
            <CustomFormControl 
              variant='filled' 
              className={classes.formControl}
            >
              <CustomInputLabelNew shrink={true}>
                <IconPoliceBadgeOutlined />
                <Typography variant='subtitle1'>Pangkat</Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                placeholder='Masukkan Pangkat'
                type='text'
                name='officerRank'
                value={informationParams.officerRank}
                onChange={handleInformationParamsChange}
                disableUnderline
              />
            </CustomFormControl>

            {/* OFFICER CODE */}
            <CustomFormControl 
              variant='filled' 
              className={classes.formControl}
            >
              <CustomInputLabelNew shrink={true}>
                <IconListView />
                <Typography variant='subtitle1'>NRP</Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                placeholder='Masukkan NRP'
                type='number'
                name='officerCode'
                value={informationParams.officerCode}
                onChange={handleInformationParamsChange}
                disableUnderline
              />
            </CustomFormControl>

            {/* DEPARTMENT */}
            <CustomFormControl 
              variant='filled' 
              className={classes.formControl}
            >
              <CustomInputLabelNew shrink={true}>
                <IconPoliceStation />
                <Typography variant='subtitle1'>Departemen</Typography>
              </CustomInputLabelNew>
                
              <CustomInputNew
                placeholder='Pilih Departemen'
                readOnly
                type='text'
                name='department'
                value={informationParams.department}
                onChange={handleInformationParamsChange}
                endAdornment={<IconArrowDropDown className={classes.dropDownIcon}/>}
                onClick={(event) => setDepartmentMenuAnchor(event.currentTarget)}
                disableUnderline
              />
            </CustomFormControl>

            {/* DEPARTMENT MENU */}
            <Menu
              anchorEl={departmentMenuAnchor}
              open={Boolean(departmentMenuAnchor)}
              onClose={() => setDepartmentMenuAnchor(null)}
              className={classes.menu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {/* SEARCH INPUT */}
              <Box className={classes.menuSearch}>
                <CustomInput
                  type='text'
                  placeholder='Search'
                  className={classes.menuSearchInput}
                  value={searchDepartment}
                  onChange={(event) => setSearchDepartment(event.target.value)}
                  startAdornment={<IconSearch className={classes.menuSearchIcon}/>}
                  onClick={(event) => event.stopPropagation()}
                />
              </Box>

              {/* DEPARTMENT OPTION LIST */}
              <Box className={classes.menuItemContainer}>
                {departmentList.map((item, index) => (
                  item.isShown &&
                    <CustomMenuItem
                      key={index}
                      className={
                        informationParams.department === item.name
                          ? `${classes.menuItem} ${classes.menuItemSelected}`
                          : classes.menuItem
                      }
                      onClick={() => handleMenuItemClicked(item)}
                    >
                      {item.name}
                    </CustomMenuItem>
                ))}
              </Box>
            </Menu>
          </>
        ]}
        onCancelButtonClick={() => history.push(basePaths.settingsOfficers)}
        onSaveButtonClick={handleSaveBtn}
      />

      {/* LOADING */}
      <SimpleBackdrop isLoading={isLoading} />
    </>
  )
}

export default DialogAddOrEditDispatchOfficer
