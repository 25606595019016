import { useState, useContext } from 'react' 

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { PageEvidencesContext } from 'contexts/PageEvidencesContext'

// MUIS
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'

// MUI ICONS
import IconClose from '@mui/icons-material/Close'

// SERVICES
import { saveAsNewFilterAdvanceSearch } from 'services/EvidenceService'

// STYLES
import useStyles from './saveNewFilterDialogUseStyles'

const SaveNewFilterDialog = () => {
  const classes = useStyles()
  
  const { setSnackbarObject } = useContext(AllPagesContext)
  const { 
    isDialogSaveNewFilterOpen, setIsDialogNewFilterOpen, 
    setIsCreateNewFilterButtonShown,
    setIsSaveExistingFilterButtonShown,
    setMustReloadFilterOptionsData,
    selectedFilterList,
    setSelectedSavedFilter,
  } = useContext(PageEvidencesContext)

  const initialNewFilterObject = {
    name: '',
    description: '',
  }

  const [ newFilterObject, setNewFilterObject ] = useState(initialNewFilterObject)

  const handleNewFilterObjectChange = (event) => {
    setNewFilterObject(current => {
      return {
        ...current,
        [event.target.name]: event.target.value,
      }
    })
  }

  const handleSaveButtonClick = async () => {
    const resultSaveAsNewFilter = await saveAsNewFilterAdvanceSearch({
      ...newFilterObject,
      jsonFilter: { query: selectedFilterList.map(item => {
        return {
          ...item,
          op: item.operator,
        }
      }) },
    })
    
    if (resultSaveAsNewFilter.status !== 'error') {
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Sukses Membuat Filter Baru',
      })

      setIsDialogNewFilterOpen(false)

      setSelectedSavedFilter({
        filterNo: resultSaveAsNewFilter.data.filterNo,
        name: resultSaveAsNewFilter.data.name,
        description: resultSaveAsNewFilter.data.description,
      })

      setMustReloadFilterOptionsData(true)

      setIsCreateNewFilterButtonShown(false)
      setIsSaveExistingFilterButtonShown(false)
    }
    else {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Gagal dalam Membuat Filter Baru',
      })
    }    
  }

  return (
    <Dialog
      onClose={() => setIsDialogNewFilterOpen(false)}
      open={isDialogSaveNewFilterOpen}
    >
      {/* TITLE */}
      <DialogTitle
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        width={500}
      >
        {/* TEXT */}
        Simpan Sebagai Filter Baru

        {/* CLOSE ICON BUTTON */}
        <IconButton
          onClick={() => setIsDialogNewFilterOpen(false)}
        >
          <IconClose/>
        </IconButton>
      </DialogTitle>

      {/* CONTENT */}
      <DialogContent>
        {/* NAME INPUT */}
        <FormControl 
          fullWidth 
          required
          className={classes.formControl}
        >
          {/* LABEL */}
          <InputLabel 
            shrink
            className={classes.inputLabel}
          >
            Nama
          </InputLabel>

          {/* INPUT */}
          <OutlinedInput
            name='name'
            value={newFilterObject.name}
            onChange={handleNewFilterObjectChange}
            className={classes.input}
          />
        </FormControl>

        {/* DESCRIPTION INPUT */}
        <FormControl 
          fullWidth 
          className={classes.formControl}
        >
          {/* LABEL */}
          <InputLabel 
            shrink
            className={classes.inputLabel}
          >
            Deskripsi
          </InputLabel>

          {/* INPUT */}
          <OutlinedInput
            name='description'
            value={newFilterObject.description}
            onChange={handleNewFilterObjectChange}
            className={classes.input}
          />
        </FormControl>
      </DialogContent>

      {/* ACTIONS */}
      <DialogActions className={classes.dialogActions}>
        {/* CANCEL BUTTON */}
        <Button 
          className={`${classes.actionButton} ${classes.actionButtonCancel}`}
          onClick={() => setIsDialogNewFilterOpen(false)}
        >
          Batal
        </Button>

        {/* SAVE BUTTON */}
        <Button 
          className={classes.actionButton}
          onClick={handleSaveButtonClick}
        >
          Simpan
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SaveNewFilterDialog