import React, { useRef, useState } from 'react'

// STYLES
import useStyles from './changePasswordUseStyles'

// MUIS
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

// ASSETS
import iconProtect from 'assets/images/icons/change-password-protect.svg'
import DialogEditPassword from './DialogEditPassword/DialogEditPassword'


const ChangePassword = () => {
  const classes = useStyles()
  const pageRef = useRef()

  // STATE
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box className={classes.pageRoot} ref={pageRef}>
      {/* HEADER */}
      <Typography className={classes.pageTitle} variant='h6'>
        Kata Sandi
      </Typography>

      {/* CONTENT */}
      <Box className={classes.pageContent}>
        {/* CONTENT HEADER */}
        <Box className={classes.contentHeader}>
          {/* CONTENT TITLE */}
          <Typography className={classes.pageTitle} variant='h6'>
            Kata Sandi
          </Typography>

          {/* CONTENT DESCRIPTION */}
          <Typography className={classes.contentDescription} variant='body2'>
            Halaman ini akan memungkinkan Anda untuk mengubah kata sandi yang sudah terdaftar ke kata sandi terbaru.
          </Typography>
        </Box>

        {/* CONTENT BODY */}
        <Box className={classes.contentBody}>
          <Box>
            <Box
              component='img'
              src={iconProtect}
            />

            <Button
              className={classes.btnChangePassword}
              variant='contained'
              color='primary'
              disableElevation
              onClick={() => setIsOpen(true)}
            >
              Ubah Kata Sandi
            </Button>
          </Box>
        </Box>
      </Box>

      <DialogEditPassword
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </Box>
  )
}

export default ChangePassword