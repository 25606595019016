import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

// COMPONENTS
import LoadingBox from 'components/LoadingBox/LoadingBox'
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import DialogAddOrEditActivityType from './DialogAddOrEditActivityTypes/DialogAddOrEditActivityTypes'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import PageHeader from 'components/PageHeader/PageHeader'

// CONSTANTS
import { basePaths } from 'constants/paths'
import { iconListActivityTypes } from 'constants/icons'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconDelete from '@mui/icons-material/Delete'
import IconEdit from '@mui/icons-material/Edit'

// PATH ICONS
import IconTodoList from 'assets/images/pathIcons/IconTodoList'

// RAMDA
import { isEmpty, reject } from 'ramda'

// SERVICES
import { searchActivityType } from 'services/DispatchService'
import { deleteActivityType } from 'services/MasterDataService'

// STORES
import { showAlert } from 'store/MainReducer'

// STYLES
import useStyles from './activityTypesUseStyles'

const ActivityTypes = () => {
  const classes = useStyles()

  const pageRef = useRef()

  const history = useHistory()
  const location = useLocation()

  const dispatch = useDispatch()

  const initialFilters = {
    activityTypeName : '',
  }

  const groupByList = [
    {
      title: 'Don\'t Group',
      value: null,
    },
  ]

  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('activityTypeName')
  const [size, setPageSize] = useState(100)
  const [activityTypeTableData, setActivityTypeTableData] = useState([])
  const [filters, setFilters] = useState(initialFilters)
  const [selectedGroupBy, setSelectedGroupBy] = useState(groupByList[0])
  const [isFilterOn, setIsFilterOn] = useState(false)
  const [search, setSearch] = useState('')
  const [dialogAddActivityType, setDialogAddActivityType] = useState(null)
  const [dialogDeleteActivityType, setDialogDeleteActivityType] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [selectionModel, setSelectionModel] = useState([])

  const handleEditIconButtonClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()
    history.push(
      `${basePaths.settingsActivityTypes}?mode=edit&id=${inputParams.id}`, 
      {
        mode: 'edit',
        data: inputParams.row,
      }
    )
  }
  
  const initialColumns = [
    {
      field: 'activityTypeName',
      headerName: 'Nama Jenis Kegiatan',
      headerIcon: IconTodoList,
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: params => {
        const Icon = params.row.iconCode ? iconListActivityTypes[Number(params.row.iconCode)-1]?.Icon : null

        return (
          <Stack direction='row' alignItems='center'>
            {params.row.activityTypeColor && (
              <Box
                className={classes.boxColorColumn}
                sx={{
                  backgroundColor: params.row.activityTypeColor
                }}
              ></Box>
            )}

            {Icon && <Icon className={classes.boxIconColumn} />}

            <Typography className={classes.textActivityTypeName} variant='body2'>{params.value}</Typography>
          </Stack>
        )
      }
    },
    {
      field: 'actions',
      headerName: '',
      width: 120,
      hide: false,
      areFilterAndSortShown: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box className={classes.columnActions}>
          {/* EDIT ICON */}
          <CustomTooltipBlack title='Ubah' placement='bottom'>
            <IconButton onClick={event => handleEditIconButtonClick(event, params)}>
              <IconEdit />
            </IconButton>
          </CustomTooltipBlack>

          {/* DELETE ICON */}
          <CustomTooltipBlack title='Hapus' placement='bottom'>
            <IconButton
              onClick={(event) => handleDeleteIconButtonClick(event, params)}
            >
              <IconDelete />
            </IconButton>
          </CustomTooltipBlack>
        </Box>
      ),
    },
  ]

  const [selectedColumnList, setSelectedColumnList] = useState(initialColumns)

  // NOTE: PERHAPS, THIS COULD BE OPTIMIZED. SOURCE: DATA GRID TABLE COMPONENT
  const groupingColDef = {
    headerName: 'Nama Jenis Kegiatan',
    renderCell: (params) => {
      return (
        <Typography 
          variant='subtitle1' 
          className={classes.groupingRow}
          noWrap
        >
          {params.rowNode.groupingKey.toLowerCase()}
        </Typography>
      )
    },
  }

  // NOTE: PERHAPS, THIS COULD BE OPTIMIZED. SOURCE: DATA GRID FILTERS COMPONENT
  const handleColumnsMenuItemClick = (inputItem, inputIndex) => {
    let tempSelectedColumnList = [...selectedColumnList]
    if (selectedGroupBy.value && inputItem.field === 'activityTypeName') {
    } else {
      tempSelectedColumnList[inputIndex].hide =
        !tempSelectedColumnList[inputIndex].hide
    }
    setSelectedColumnList(tempSelectedColumnList)
  }

  const handleDeleteIconButtonClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()
    setDialogDeleteActivityType(inputParams)
  }

  const reloadData = () => {
    const newFilters = reject(isEmpty, filters)
    const params = {
      page,
      size,
    }

    if (order) params.sort = `${orderBy},${order}`
    if (search) newFilters.globalSearch = search

    setIsLoading(true)

    searchActivityType(params, newFilters).then((response) => {
      setActivityTypeTableData(response.rows)
      setTotal(response.totalElements)
      setIsLoading(false)
    })
  }

  const handleDeleteButtonClick = async () => {
    setIsLoading(true)
    const result = await deleteActivityType(
      dialogDeleteActivityType.row.activityTypeNo
    )

    if (result.status !== 'error') {
      setIsLoading(false)
      dispatch(showAlert({ message: 'Sukses Menghapus Jenis Kegiatan' }))
      setDialogDeleteActivityType({})
      reloadData()
    }
  }

  useEffect(() => {
    reloadData()
  }, [page, size, order, orderBy, filters, search])

  useEffect(() => {
    if (!isFilterOn) setFilters(initialFilters)
  }, [isFilterOn])

  // NOTE: PERHAPS, THIS COULD BE OPTIMIZED. SOURCE: DATA GRID TABLE COMPONENT
  useEffect(() => {
    if (selectedGroupBy.value) {
      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        if (
          item.field === selectedGroupBy.value ||
          item.field === 'dispatchDeviceStatus'
        )
          item.hide = true
      })
      setSelectedColumnList(tempSelectedColumnList)
    } else {
      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        if (
          item.field === 'deviceName' ||
          item.field === 'dispatchDeviceStatus'
        )
          item.hide = false
      })
      setSelectedColumnList(tempSelectedColumnList)
    }
  }, [selectedGroupBy])

  useEffect(() => {
    if (location.pathname === basePaths.settingsActivityTypes && (location.search.includes('?mode=edit') || location.search.includes('?mode=add'))) {
      setDialogAddActivityType(location?.state)
    }
    else setDialogAddActivityType(false)
  }, [location])

  return (
    <Box className={classes.pageRoot} ref={pageRef}>
      {/* HEADER */}
      <PageHeader
        isAddButtonAvailable={true}
        onAddButtonIsClicked={() => history.push(`${basePaths.settingsActivityTypes}?mode=add`, {
          mode: 'add'
        })}
        title='Jenis Kegiatan'
        search={search}
        setSearch={setSearch}
      />

      {/* CONTENT */}
      <LoadingBox isLoading={isLoading}>
        <DataGridFilters
          columns={initialColumns}
          selectedColumnList={selectedColumnList}
          handleColumnsMenuItemClick={handleColumnsMenuItemClick}
          isFilterOn={isFilterOn}
          setIsFilterOn={setIsFilterOn}
          groupByList={groupByList}
          selectedGroupBy={selectedGroupBy}
          setSelectedGroupBy={setSelectedGroupBy}
        />

        <DataGridTable
          initialColumns={initialColumns}
          rows={activityTypeTableData}
          total={total}
          page={page}
          pageSize={size}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setPage={setPage}
          setPageSize={setPageSize}
          setFilters={setFilters}
          isFilterOn={isFilterOn}
          selectedColumnList={selectedColumnList}
          setSelectedColumnList={setSelectedColumnList}
          selectedGroupBy={selectedGroupBy}
          getTreeDataPath={(row) => [row.activityTypeName, row.activityTypeNo]}
          getRowId={(row) => row.activityTypeNo}
          groupingColDef={groupingColDef}
          selectionModel={selectionModel} 
          setSelectionModel={setSelectionModel}
          isCheckboxPinned={true}
        />
      </LoadingBox>

      {/* DIALOG ADD EDIT ACTIVITY TYPE */}
      <DialogAddOrEditActivityType
        dialogAddEditActivityType={dialogAddActivityType}
        setDialogAddEditActivityType={setDialogAddActivityType}
        pageRef={pageRef}
        reloadData={reloadData}
      />

      {/* DIALOG DELETE ACTIVITY TYPE */}
      <DialogConfirmation
        title='Hapus Jenis Kegiatan'
        caption='Apa Anda yakin ingin menghapus jenis kegiatan ini?'
        dialogConfirmationObject={dialogDeleteActivityType}
        setDialogConfirmationObject={setDialogDeleteActivityType}
        cancelButtonText='Batal'
        continueButtonText='Hapus'
        onContinueButtonClick={handleDeleteButtonClick}
        onCancelButtonClick={() => setDialogDeleteActivityType({})}
      />
    </Box>
  )
}

export default ActivityTypes
