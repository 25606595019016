import React, { useState } from 'react'
import moment from 'moment'

// COMPONENTS
import CustomItem from './CustomItem'

// KENDO UI
import { Scheduler, TimelineView } from '@progress/kendo-react-scheduler'
import '@progress/kendo-date-math/tz/Asia/Jakarta' // GMT +7
import '@progress/kendo-date-math/tz/Asia/Makassar' // GMT +8
import '@progress/kendo-date-math/tz/Asia/Jayapura' // GMT +9
import '@progress/kendo-theme-default/dist/all.css'

// MUIS
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconArrowBack from '@mui/icons-material/ArrowBack'
import IconArrowForward from '@mui/icons-material/ArrowForward'

// STYLES
import useStyles from './timelineSchedulerUseStyles'

// UTILS
import { generateFinalDataKendo, generateResourceData } from '../dispatchPatrolsUtils'


const TimelineScheduler = (props) => {
  const { setDataDispatchPatrols, dataDispatchPatrols, dataDevices, reloadDataDispatchPatrols } = props
  const classes = useStyles()

  const [timelineView, setTimelineView] = useState('today')
  const [timelineViewOn, setTimelineViewOn] = useState('hour')
  const [timelineStartDate, setTimelineStartDate] = useState(moment().startOf('day').toDate())

  const CustomDateHeaderCell = (props, format) => {
    return <Stack className={props.className} style={props.style} textAlign='center'>
      <Typography color='text.secondary' variant='caption' fontWeight={600}>
        {moment(props.date._localDate).format(format)}
      </Typography>
    </Stack>
  }

  const CustomTimeHeaderCell = (props) => {
    return <Stack className={props.className} style={props.style} textAlign='center'>
      <Typography color='text.secondary' variant='caption' fontWeight={600}>
        {moment(props.date._localDate).format(props.format)}
      </Typography>
    </Stack>
  }

  const getSchedulerProps = () => {
    if(timelineView === 'today') {
      return {
        columnWidth: 100,
        slotDuration: 0,
        slotDivisions: 1,
        numberOfDays: 1,
        timeHeaderCell: (props) => {
          return <CustomTimeHeaderCell {...props} format='HH:mm'/>
        },
        dateHeaderCell: props => CustomDateHeaderCell(props, 'dddd DD')
      }
    } else if(timelineView === 'oneWeek' && timelineViewOn === 'hour') {
      return {
        columnWidth: 40,
        slotDuration: 160,
        slotDivisions: 1,
        numberOfDays: 7,
        timeHeaderCell: (props) => {
          return <CustomTimeHeaderCell {...props} format='HH:mm'/>
        },
        dateHeaderCell: props => CustomDateHeaderCell(props, 'dddd DD')
      }
    } else if(timelineView === 'oneWeek' && timelineViewOn === 'day') {
      return {
        columnWidth: 100,
        slotDuration: 720,
        slotDivisions: 1,
        numberOfDays: 7,
        timeHeaderCell: (props) => {
          return <React.Fragment />
        },
        dateHeaderCell: props => CustomDateHeaderCell(props, 'dddd DD')
      }
    } else if(timelineView === 'oneMonth' && timelineViewOn === 'day') {
      return {
        columnWidth: 100,
        slotDuration: 1440,
        slotDivisions: 1,
        numberOfDays: moment(timelineStartDate, 'YYYY-MM').daysInMonth(),
        timeHeaderCell: (props) => {
          return <React.Fragment />
        },
        dateHeaderCell: props => CustomDateHeaderCell(props, 'ddd DD')
      }
    }
    // } else if(timelineView === 'oneMonth' && timelineViewOn === 'week') {
    //   return {
    //     slotDuration: 1440,
    //     slotDivisions: 1,
    //     numberOfDays: moment(timelineStartDate, 'YYYY-MM').daysInMonth(),
    //     timeHeaderCell: (props) => {
    //       return <React.Fragment />
    //     },
    //     dateHeaderCell: props => CustomDateHeaderCell(props, 'dddd DD')
    //   }
    // }
  }

  const getStartDate = (inputType) => {
    if(inputType === 'today') {
      return moment().startOf('day').toDate()
    } else if(inputType === 'oneWeek') {
      return moment().startOf('week').toDate()
    } else if(inputType === 'oneMonth') {
      return moment().startOf('month').toDate()
    }
  }

  const handleSelectTimelineView = inputValue => {
    if(inputValue === 'today') {
      setTimelineView('today')
      setTimelineViewOn('hour')
      setTimelineStartDate(getStartDate('today'))
    } else if(inputValue === 'oneWeek') {
      setTimelineView('oneWeek')
      setTimelineViewOn('hour')
      setTimelineStartDate(getStartDate('oneWeek'))
    } else if(inputValue === 'oneMonth') {
      setTimelineView('oneMonth')
      setTimelineViewOn('day')
      setTimelineStartDate(getStartDate('oneMonth'))
    }
  }

  const handleNextDate = () => {
    if(timelineView === 'today') {
      setTimelineStartDate(moment(timelineStartDate, 'YYYY/MM/DD').add(1, 'days').toDate())
    } else if(timelineView === 'oneWeek') {
      setTimelineStartDate(moment(timelineStartDate, 'YYYY/MM/DD').add(1, 'weeks').toDate())
    } else if(timelineView === 'oneMonth') {
      setTimelineStartDate(moment(timelineStartDate, 'YYYY/MM/DD').add(1, 'months').toDate())
    }
  }

  const handlePrevDate = () => {
    if(timelineView === 'today') {
      setTimelineStartDate(moment(timelineStartDate, 'YYYY/MM/DD').subtract(1, 'days').toDate())
    } else if(timelineView === 'oneWeek') {
      setTimelineStartDate(moment(timelineStartDate, 'YYYY/MM/DD').subtract(1, 'weeks').toDate())
    } else if(timelineView === 'oneMonth') {
      setTimelineStartDate(moment(timelineStartDate, 'YYYY/MM/DD').subtract(1, 'months').toDate())
    }
  }

  const getTitleDate = () => {
    if(timelineView === 'today') {
      return moment(timelineStartDate).format('DD MMM YYYY')
    } else if(timelineView === 'oneWeek') {
      return `${moment(timelineStartDate).format('DD')} - ${moment(timelineStartDate, 'YYYY/MM/DD').add(6, 'days').format('DD MMM YYYY')}`
    } else if(timelineView === 'oneMonth') {
      return moment(timelineStartDate).format('MMM YYYY')
    }
  }

  return (
    <Stack overflow='auto'>
      <Stack direction='row' padding='16px' flexWrap='nowrap'>
        <Stack width='35%' direction='row' alignItems='center'>
          <Typography variant='subtitle2' className={classes.labelView}>Tampilan</Typography>

          {/* VIEW TIMELINE */}
          <FormControl className={classes.formControlSelectTimeline} variant='filled'>
            <Select
              value={timelineView}
              onChange={event => handleSelectTimelineView(event.target.value)}
              className={classes.selectTimelineView}
              MenuProps={{
                PaperProps: {
                  className: `${classes.selectTimelineMenu} no-zoom`
                },
              }}
            >
              <MenuItem value='today' className='zoom'>1 Hari</MenuItem>
              <MenuItem value='oneWeek' className='zoom'>1 Minggu</MenuItem>
              <MenuItem value='oneMonth' className='zoom'>1 Bulan</MenuItem>
            </Select>
          </FormControl>

          <Typography variant='subtitle2' className={classes.labelView}>
            {timelineView !== 'today' ? 'dalam' : 'dalam Jam'}
          </Typography>

          {/* VIEW TIMELINE ON */}
          {timelineView !== 'today' && <FormControl className={classes.formControlSelectTimeline} variant='filled'>
            <Select
              value={timelineViewOn}
              onChange={event => setTimelineViewOn(event.target.value)}
              className={classes.selectTimelineView}
              MenuProps={{
                PaperProps: {
                  className: `${classes.selectTimelineMenu} no-zoom`
                },
              }}
            >
              {timelineView === 'oneWeek' && <MenuItem value='hour' className='zoom'>Jam</MenuItem>}
              <MenuItem value='day' className='zoom'>Harian</MenuItem>
              {/* {timelineView === 'oneMonth' && <MenuItem value='week' className='zoom'>Mingguan</MenuItem>} */}
            </Select>
          </FormControl>}
        </Stack>

        <Stack direction='row' flex={1} alignItems='center' justifyContent='center'>
          <IconButton onClick={() => handlePrevDate()}>
            <IconArrowBack />
          </IconButton>

          <Typography variant='h6' className={classes.textCurrentDate}>
            {getTitleDate()}
          </Typography>

          <IconButton onClick={() => handleNextDate()}>
            <IconArrowForward />
          </IconButton>
        </Stack>

        <Stack width='35%' alignItems='flex-end'></Stack>
      </Stack>

      {/* SCHEDULER */}
      <Stack flex={1} overflow='auto'>
        {dataDispatchPatrols?.length ? <Scheduler
          data={generateFinalDataKendo(dataDispatchPatrols)}
          defaultDate={timelineStartDate}
          date={timelineStartDate}
          group={{
            resources: ['Devices'],
            orientation: 'vertical'
          }}
          resources={[
            {
              name: 'Devices',
              data: generateResourceData(dataDispatchPatrols, dataDevices),
              field: 'deviceNo',
              valueField: 'value',
              textField: 'text',
              colorField: 'color',
            },
          ]}
          header={(props) => <React.Fragment />}
          footer={(props) => <React.Fragment />}
          timezone='Asia/Makassar'
          className={classes.schedulerTimeline}
          item={props => <CustomItem
            {...props}
            reloadData={reloadDataDispatchPatrols}
            setDataDispatchPatrols={setDataDispatchPatrols}
            dataDispatchPatrols={dataDispatchPatrols}
          />}
          height='100%'
          editable={{
            add: false,
            remove: false,
            edit: false,
            select: true,
            resize: true,
          }}
        >
          <TimelineView
            showWorkHours={false}
            workDayStart='00:00'
            workDayEnd='23:00'
            {...getSchedulerProps()}
          />
        </Scheduler> : ''}
      </Stack>
    </Stack>
  )
}

export default TimelineScheduler