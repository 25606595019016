export const generateMediaUrlBySize = (mediaObject) => {
  if (mediaObject?.mediaType === 'IMAGE') {
    return {
      ...mediaObject,
      mediaUrlSmall: mediaObject?.mediaType === 'IMAGE' 
        ? mediaObject?.mediaUrl?.replace('unsigned', 'insecure/rs:fit:220:220/dpr:2/wm:0.5:soea:0:0:0.2')
        : mediaObject?.mediaUrl,
      mediaUrlMedium: mediaObject?.mediaType === 'IMAGE' 
        ? mediaObject?.mediaUrl?.replace('unsigned', 'insecure/rs:fit:460:440/dpr:2/wm:0.5:soea:0:0:0.2')
        : mediaObject?.mediaUrl,
      mediaUrlFullHeight: mediaObject?.mediaType === 'IMAGE' 
        ? mediaObject?.mediaUrl?.replace('unsigned', 'insecure/rs:fit:1920:1920/dpr:2/wm:0.5:soea:0:0:0.2')
        : mediaObject?.mediaUrl,
    }
  }
  else return { ...mediaObject }
  
}