import { useState, useContext } from 'react'

// COMPONENTS
import CarouselMedias from 'components/CarouselMedias/CarouselMedias'

// CONTEXTS
import { PageEvidencesContext } from 'contexts/PageEvidencesContext'

const CarouselMediaWrapper = (props) => {
  const { evidenceObject } = props

  const { setSelectedEvidenceItem } = useContext(PageEvidencesContext)

  const [ activeIndex, setActiveIndex ] = useState(0)

  return (
    <CarouselMedias
      evidenceObject={evidenceObject}
      indicators={false}
      variant='small'
      onItemClick={setSelectedEvidenceItem}
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
    />
  )
}

export default CarouselMediaWrapper