// CONSTANTS
import { colors } from 'constants/colors'

export const getEvidenceStatusColor = (inputStatus) => {
  if(inputStatus === 'RESOLVED') return colors.mountainMeadow
  else if(inputStatus === 'NEEDED_HELP' || inputStatus === 'CANCELLED') return colors.torchRed
  else if(inputStatus === 'PENDING' || inputStatus === 'REVIEWED') return colors.california
  else return colors.boulder 
}

// GET COLOR MARKER BY TYPE
export const getColorMarkerByTypeName = (inputType) => {
  const typeList = [ 'NEEDED_HELP', 'PENDING', 'RESOLVED', 'HISTORY', 'OBJECT', 'DISPATCH', 'DISPATCHES' ]
  if (
    inputType === typeList[0] || 
    inputType === typeList[1] || 
    inputType === typeList[2]
  ) return getEvidenceStatusColor(inputType)
  else if (inputType === typeList[3]) return colors.denim
  else if (inputType === typeList[4]) return colors.clementine
  else if (inputType === typeList[5] || inputType === typeList[6]) return colors.blueRibbon
}