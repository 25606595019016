// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 0,
    position: 'relative',
  },
  customTab: {
    '&.MuiTab-root': {
      height: 60,
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.text.secondary,
    },
    '&.MuiTab-root.Mui-selected': {
      color: '#000000'
    }
  },
  contentContainer: {
    position: 'relative',
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: 24,
    marginTop: 0,
  },
}))

export default useStyles