import { useState, useEffect, useContext } from 'react'

// COMPONENTS
import AddFilterMenu from '../AddFilterMenu/AddFilterMenu'

// CONSTANTS
import { colors } from 'constants/colors'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { PageEvidencesContext } from 'contexts/PageEvidencesContext'

// MUIS
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

// MUI ICONS
import IconAddCircle from '@mui/icons-material/AddCircle'
import IconClear from '@mui/icons-material/Clear'
import IconDelete from '@mui/icons-material/Delete'
import IconFilterList from '@mui/icons-material/FilterList'
import IconMoreVert from '@mui/icons-material/MoreVert'
import IconSave from '@mui/icons-material/Save'
import IconSaveAs from '@mui/icons-material/SaveAs'

// SERVICES
import { editSavedFilterItemAdvanceSearch } from 'services/EvidenceService'

// STYLES
import useStyles from './filtersUseStyles'

const Filters = () => {
  const theme = useTheme()
  
  const classes = useStyles()

  const { setSnackbarObject } = useContext(AllPagesContext)
  const { 
    setIsDialogNewFilterOpen, 
    isCreateNewFilterButtonShown, setIsCreateNewFilterButtonShown,
    isSaveExistingFilterButtonShown, setIsSaveExistingFilterButtonShown,
    setMustReloadFilterOptionsData,
    selectedFilterList, setSelectedFilterList,
    selectedSavedFilter, setSelectedSavedFilter,
  } = useContext(PageEvidencesContext)

  const [ moreAncholEl, setMoreAnchorEl ] = useState(null)
  const [ addFilterAnchorEl, setAddFilterAnchorEl ] = useState(null)

  const handleDeleteSelectedFilter = (index) => {
    setSelectedFilterList(current => {
      return [...current].filter((_, currentIndex) => currentIndex !== index)
    })

    setIsCreateNewFilterButtonShown(true)
    setIsSaveExistingFilterButtonShown(true)
  }

  const handleDeleteAllFilters = () => {
    setSelectedFilterList([])
    setMoreAnchorEl(null)
  }

  const handleSaveExistingFilterItemButtonClick = async () => {
    const resultSaveExistingFilterItem = await editSavedFilterItemAdvanceSearch(
      selectedSavedFilter.filterNo,
      {
        ...selectedSavedFilter,
        jsonFilter: { query: selectedFilterList.map(item => {
          return {
            ...item,
            op: item.operator,
          }
        })},
      },
    )

    if (resultSaveExistingFilterItem.status === 200) {
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Sukses Mengubah Filter',
      })

      setMustReloadFilterOptionsData(true)

      setIsCreateNewFilterButtonShown(false)
      setIsSaveExistingFilterButtonShown(false)
    }
    else {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Gagal Mengubah Filter',
      })
    }
  }

  useEffect(() => {
    if (selectedFilterList.length === 0) {
      setSelectedSavedFilter(null)
      setIsSaveExistingFilterButtonShown(false)
      setIsCreateNewFilterButtonShown(false)
    }
  }, [selectedFilterList])
  
  return (
    <Stack
      padding='8px 16px'
      spacing='4px'
      borderBottom={`1px solid ${theme.palette.divider}`}
    >
      {/* TITLE AND ACTIONS */}
      <Stack
        direction='row'
        alignItems='center'
        spacing='4px'
      >
        {/* FILTER ICON */}
        <IconFilterList className={classes.filterIcon}/>

        {/* FILTER NAME */}
        <Typography
          variant='body2'
          fontWeight={600}
          color='text.secondary'
          marginRight='8px !important'
        >
          Filter: {selectedSavedFilter?.name ?? '[Filter tanpa nama]'} 
        </Typography>

        {/* SAVE THE UPDATES BUTTON */}
        {isSaveExistingFilterButtonShown &&
        <Button
          startIcon={<IconSave/>}
          className={classes.button}
          onClick={() => handleSaveExistingFilterItemButtonClick()}
        >
          Simpan Perubahan
        </Button>}

        {/* SAVE AS NEW BUTTON */}
        {isCreateNewFilterButtonShown &&
        <Button
          startIcon={<IconSaveAs/>}
          className={classes.button}
          onClick={() => setIsDialogNewFilterOpen(true)}
        >
          Simpan sebagai Filter Baru
        </Button>}

        {/* ADD FILTER BUTTON */}
        <Button
          startIcon={<IconAddCircle/>}
          className={classes.button}
          onClick={(event) => setAddFilterAnchorEl(event.currentTarget)}
        >
          Tambah Filter
        </Button>
        
        {/* MORE ICON */}
        <IconButton 
          className={classes.moreIcon}
          size='small'
          onClick={(event) => setMoreAnchorEl(event.currentTarget)}
        >
          <IconMoreVert fontSize='small'/>
        </IconButton>

        {/* CLEAR FILTER MENU */}
        <Menu
          anchorEl={moreAncholEl}
          open={Boolean(moreAncholEl)}
          onClose={() => setMoreAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          className={`neutralize-zoom-menu ${classes.clearFilterMenu}`}
        >
          <MenuItem onClick={handleDeleteAllFilters}>
            {/* ICON */}
            <ListItemIcon className={classes.clearFilterMenuItemIcon}>
              <IconDelete fontSize='small'/>
            </ListItemIcon>

            {/* TEXT */}
            <ListItemText primary='Bersihkan Filter'/>
          </MenuItem>
        </Menu>
      </Stack>

      {/* FILTER LIST */}
      <Grid
        container
        alignItems='center'
        gap='8px'
      >
        {/* LIST */}
        {selectedFilterList.map((item, index) => (
          <Grid
            key={index}
            item
          >
            <Chip
              key={index}
              variant='outlined'
              className={classes.filterListItem}
              label={
                <Stack
                  direction='row'
                  alignItems='center'
                  spacing='4px'
                >
                  {item.field === 'SOS_STATUS' ? (
                    // FIELD AND VALUE
                    <Typography variant='body2'>
                      SOS: {item.operator === 'IS' ? 'true' : 'false'}
                    </Typography>
                  ) : (
                    <>
                      {/* OPERATOR */}
                      <Typography 
                        variant='body2'
                        color={item.operator.includes('NOT') ? colors.redRibbon : colors.denim}
                      >
                        {item.operator}
                      </Typography>
      
                      {/* FIELD AND VALUE */}
                      <Typography variant='body2'>
                        {item.fieldLabel}{!item.operator.includes('EMPTY') ? `: ${item.value}` : ''}
                      </Typography>
                    </>
                  )}
                </Stack>
              }
              deleteIcon={
                <IconButton size='small'>
                  <IconClear fontSize='small'/>
                </IconButton>
              }
              onDelete={() => handleDeleteSelectedFilter(index)}
            />
          </Grid>
        ))}
      </Grid>

      {/* ADD FILTER MENU */}
      <AddFilterMenu
        addFilterAnchorEl={addFilterAnchorEl}
        setAddFilterAnchorEl={setAddFilterAnchorEl}
      />
    </Stack>
  )
}

export default Filters