import { useState } from 'react'

// ASSETS
import PictureBrokenImage from 'assets/images/pictures/broken-image.svg'

// MUIS
import Box from '@mui/material/Box'

// STYLES
import useStyles from './dialogEvidenceDetailUseStyles'

const MediaImage = (props) => {
  const { mediaItem } = props

  const classes = useStyles()

  const [ source, setSource ] = useState(mediaItem.mediaUrl)

  return (
    <Box
      component='img'
      src={source ? source : PictureBrokenImage}
      alt=''
      onError={() => setSource(PictureBrokenImage)}
      className={source ? classes.mediaItemVisual : classes.mediaItemBrokenIcon}
    />
  )
}

export default MediaImage