import { useState, useEffect } from 'react'

// ASSETS
import PictureBrokenImage from 'assets/images/pictures/broken-image.svg'

// COMPONENT
import DialogMediaPreview from '../DialogMediaPreview/DialogMediaPreview'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

// STYLES
import useStyles from './detailTabInformationUseStyles'

const RenderValuePhoto = (props) => {
  const {
    photoUrl,
    dateAndTime,
    location,
    isWithBorder,
  } = props

  const classes = useStyles()

  const theme = useTheme()

  const [ source, setSource ] = useState(photoUrl)
  const [ isDialogMediaPreviewOpen, setIsDialogMediaPreviewOpen ] = useState(null)
  
  useEffect(() => {
    setSource(photoUrl)
  }, [photoUrl])

  return (
    <Stack 
      direction='row'
      alignItems='center'
      marginTop='4px'
      spacing='8px'
    >
      {/* PHOTO */}
      <CustomTooltipBlack
        title={source ? '' : 'Gambar tidak dapat dimuat'} 
        placement='top'
      >
        <Box
          component='img'
          src={source ? source : PictureBrokenImage}
          alt=''
          className={classes.informationValuePhoto}
          border={isWithBorder ? `1px solid ${theme.palette.divider}` : 'none'}
          onError={() => setSource(PictureBrokenImage)}
          onClick={() => source && setIsDialogMediaPreviewOpen(source)}
        />
      </CustomTooltipBlack>

      {/* TEXTS */}
      <Stack>
        {/* DATE AND TIME TEXT */}
        <Typography 
          variant='body2'
          color='text.primary'
          fontWeight={400}
        >
          {dateAndTime}
        </Typography>

        {/* LOCATION TEXT */}
        <Typography 
          variant='caption'
          fontWeight={400}
        >
          {location}
        </Typography>
      </Stack>

      {/* DIALOG MEDIA PREVIEW */}
      <DialogMediaPreview 
        isDialogMediaPreviewOpen={isDialogMediaPreviewOpen}
        setIsDialogMediaPreviewOpen={setIsDialogMediaPreviewOpen}
      />
    </Stack>
  )
}

export default RenderValuePhoto