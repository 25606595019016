import {  useRef } from 'react'

// COMPONENTS
import PanelMenu from '../PanelMenu/PanelMenu'
// import PanelEvidences from '../PanelEvidences/PanelEvidences'
// import PanelToggle from '../PanelToogle/PanelToogle'

// CONTEXTS
// import { PageTrackingContext } from 'contexts/PageTrackingContext'

// MUIS
import Stack from '@mui/material/Stack'

// STYLES
import useStyles from './panelRightUseStyles'

const PanelRight = () => {
  const classes = useStyles()
  // const {
  //   isEvidencesPanelShown, setIsEvidencesPanelShown,
  //   isEvidenceDetailPanelShown, isObjectDetailPanelShown
  // } = useContext(PageTrackingContext)

  const panelEvidencesRef = useRef()

  return (
    <Stack className={classes.root} direction='row' ref={panelEvidencesRef}>
      <Stack position='relative' height='100%'>
        {/* MENU PANEL */}
        <PanelMenu />
      </Stack>

      {/* {(!isEvidenceDetailPanelShown && !isObjectDetailPanelShown) && <Stack
        className={classes.panelEvidenceWrapper}
        sx={{
          width: isEvidencesPanelShown ? '340px' : '64px'
        }}
      >
        <PanelToggle
          isPanelShown={isEvidencesPanelShown}
          setIsPanelShown={setIsEvidencesPanelShown}
          title='Kejadian'
          direction='left'
        />

        <PanelEvidences />
      </Stack>} */}
    </Stack>
  )
}

export default PanelRight