import React, { useState, useEffect } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

// DATA
import { dateRangeList, timeOptionList } from './dateRangeAndTimePickerData'

// MUIS
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

// MUI ICONS
import IconClose from '@mui/icons-material/Close'

// MUI DATE PICKERS
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import idLocale from 'date-fns/locale/id'

// STYLES
import useStyles from './dateRangeAndTimePickerUseStyles'

const DateRangeAndTimePicker = (props) => {
  const {
    value,
    dateFormat,
    timeFormat,
    isWithTimePicker,
    handleSelectButtonClick,
    handleCancelButtonClick,
    cancelButtonText,
    saveButtonText,
    CancelIcon,
    updateValueTempDate,
    onAfterResetToDefault,
    handleDefaultButtonClick
  } = props

  const classes = useStyles()

  const theme = useTheme()

  const initialStartDateInput =
    value && value[0] ? moment(value[0]).format(dateFormat) : ''
  const initialStartTimeInput =
    value && value[0] ? moment(value[0]).format(timeFormat) : ''
  const initialEndDateInput =
    value && value[0] ? moment(value[1]).format(dateFormat) : ''
  const initialEndTimeInput =
    value && value[0] ? moment(value[1]).format(timeFormat) : ''

  const [tempValue, setTempValue] = useState(value ? value : [null, null])
  const [startDateInput, setStartDateInput] = useState(initialStartDateInput)
  const [startTimeInput, setStartTimeInput] = useState(initialStartTimeInput)
  const [endDateInput, setEndDateInput] = useState(initialEndDateInput)
  const [endTimeInput, setEndTimeInput] = useState(initialEndTimeInput)
  const [selectedDateRangeItem, setSelectedDateRangeItem] = useState(null)
  const [countDays, setCountDays] = useState(0)
  const [key, setKey] = useState(0)

  const getSelectedDays = () => {
    if (countDays) {
      if (countDays === 1) return 'Terpilih 1 hari'
      else return `Terpilih ${countDays} hari`
    } else return 'Terpilih 0 hari'
  }

  const handleDateRangePickerChange = (inputNewValue) => {
    setTempValue(inputNewValue)

    setStartDateInput(moment(inputNewValue[0]).format(dateFormat))
    setStartTimeInput(moment(inputNewValue[0]).format(timeFormat))
    setEndDateInput(moment(inputNewValue[1]).format(dateFormat))
    setEndTimeInput(moment(inputNewValue[1]).format(timeFormat))
  }

  const handleDateRangeItemClick = (inputItem, inputIndex) => {
    let startDate
    let endDate

    if (inputItem === 'Kemarin') {
      startDate = moment().subtract(1, 'days').startOf('days').toDate()
      endDate = moment().subtract(1, 'days').endOf('days').toDate()
    } else if (inputItem === 'Minggu lalu') {
      startDate = moment().subtract(1, 'weeks').startOf('week').toDate()
      endDate = moment().subtract(1, 'weeks').endOf('week').toDate()
    } else if (inputItem === 'Bulan lalu') {
      startDate = moment().subtract(1, 'months').startOf('months').toDate()
      endDate = moment().subtract(1, 'months').endOf('months').toDate()
    } else if (inputItem === 'Hari ini') {
      startDate = moment().startOf('day').toDate()
      endDate = moment().endOf('day').toDate()
    } else if (inputItem === 'Minggu ini') {
      startDate = moment().startOf('week').toDate()
      endDate = moment().endOf('week').toDate()
    } else if (inputItem === 'Bulan ini') {
      startDate = moment().startOf('month').toDate()
      endDate = moment().endOf('month').toDate()
    } else if (inputItem === 'Besok') {
      startDate = moment().add(1, 'days').startOf('days').toDate()
      endDate = moment().add(1, 'days').endOf('days').toDate()
    } else if (inputItem === 'Minggu depan') {
      startDate = moment().add(1, 'weeks').startOf('weeks').toDate()
      endDate = moment().add(1, 'weeks').endOf('weeks').toDate()
    } else if (inputItem === 'Bulan depan') {
      startDate = moment().add(1, 'months').startOf('months').toDate()
      endDate = moment().add(1, 'months').endOf('months').toDate()
    }

    setSelectedDateRangeItem(inputItem)
    setTempValue([startDate, endDate])

    setStartDateInput(moment(startDate).format(dateFormat))
    setStartTimeInput(moment(startDate).format(timeFormat))
    setEndDateInput(moment(endDate).format(dateFormat))
    setEndTimeInput(moment(endDate).format(timeFormat))

    setKey((current) => current + 1)
  }

  const onStartDateInputChange = (inputValue) => {
    setSelectedDateRangeItem(null)
    setStartDateInput(inputValue)

    if (moment(inputValue, dateFormat, true).isValid()) {
      setTempValue((current) => [moment(inputValue).toDate(), current[1]])
    }

    setKey((current) => current + 1)
  }

  const onStartTimeInputChange = (inputValue) => {
    setSelectedDateRangeItem(null)
    setStartTimeInput(inputValue)

    const inputValueInMoment = moment(inputValue, timeFormat)

    if (moment(inputValue, timeFormat, true).isValid()) {
      setTempValue((current) => [
        moment(current[0])
          .set({
            hour: inputValueInMoment.get('hour'),
            minute: inputValueInMoment.get('minute'),
            second: inputValueInMoment.get('second')
          })
          .toDate(),
        current[1]
      ])
    }

    setKey((current) => current + 1)
  }

  const onEndDateInputChange = (inputValue) => {
    setSelectedDateRangeItem(null)
    setEndDateInput(inputValue)

    if (moment(inputValue, dateFormat, true).isValid()) {
      setTempValue((current) => [current[0], moment(inputValue).toDate()])
    }

    setKey((current) => current + 1)
  }

  const onEndTimeInputChange = (inputValue) => {
    setSelectedDateRangeItem(null)
    setEndTimeInput(inputValue)

    const inputValueInMoment = moment(inputValue, timeFormat)

    if (moment(inputValue, timeFormat, true).isValid()) {
      setTempValue((current) => [
        current[0],
        moment(current[1])
          .set({
            hour: inputValueInMoment.get('hour'),
            minute: inputValueInMoment.get('minute'),
            second: inputValueInMoment.get('second')
          })
          .toDate()
      ])
    }

    setKey((current) => current + 1)
  }

  useEffect(() => {
    setCountDays(moment(tempValue[1]).diff(tempValue[0], 'days') + 1)
  }, [
    tempValue[0],
    tempValue[1],
    startDateInput,
    startTimeInput,
    endDateInput,
    endTimeInput
  ])

  // RESET TO DEFAULT CURRENT VALUE
  useEffect(() => {
    if (updateValueTempDate?.length) {
      setTempValue(updateValueTempDate)
      setStartDateInput(moment(updateValueTempDate[0]).format(dateFormat))
      setStartTimeInput(moment(updateValueTempDate[0]).format(timeFormat))
      setEndDateInput(moment(updateValueTempDate[1]).format(dateFormat))
      setEndTimeInput(moment(updateValueTempDate[1]).format(timeFormat))
      onAfterResetToDefault && onAfterResetToDefault()
    }
  }, [updateValueTempDate])

  return (
    <Stack>
      {/* HEADER */}
      <Stack className={classes.header}>
        {/* TITLE */}
        <Typography fontWeight={600}>
          Periode Tanggal
        </Typography>

        {/* CLOSE ICON */}
        <IconButton
          size='small' 
          onClick={handleCancelButtonClick}
        >
          <IconClose fontSize='small'/>
        </IconButton>
      </Stack>

      {/* CONTENT */}
      <Stack
        direction='row'
        width='fit-content'
      >
        {/* LEFT PANEL */}
        <Stack className={classes.leftPanelContainer}>
          {dateRangeList.map((dateRangeType, dateRangeTypeIndex) => (
            <React.Fragment key={dateRangeTypeIndex}>
              <List>
                {dateRangeType.map((item, index) => (
                  <ListItemButton
                    key={index}
                    onClick={() => handleDateRangeItemClick(item, index)}
                    className={classes.leftPanelItemButton}
                    sx={{ borderLeftColor: selectedDateRangeItem === item 
                      ? `${theme.palette.primary.main} !important` 
                      : 'transparent'
                    }}
                  >
                    <ListItemText
                      primary={item}
                      className={classes.leftPanelItemText}
                    />
                  </ListItemButton>
                ))}
              </List>

              {dateRangeTypeIndex !== dateRangeList.length - 1 && <Divider/>}
            </React.Fragment>
          ))}
        </Stack>

        {/* RIGHT PANEL */}
        <Stack padding='8px 16px'>
          {/* TITLE */}
          <Typography 
            variant='subtitle2'
            fontWeight={600}
            color='text.secondary'
          >
            Rentang tanggal:
          </Typography>

          {/* DATE AND TIME PICKERS */}
          {isWithTimePicker && (
            <Stack 
              direction='row'
              className={classes.dateAndTimeInputContainer}
              spacing='24px'
            >
              {/* START DATE */}
              <Input
                className={classes.dateAndTimeInput}
                value={startDateInput}
                onChange={(event) => onStartDateInputChange(event.target.value)}
              />

              {/* START TIME */}
              <FormControl
                variant='standard'
                className={classes.dateAndTimeSelect}
              >
                <Select
                  value={startTimeInput}
                  onChange={(event) => onStartTimeInputChange(event.target.value)}
                  MenuProps={{ className: classes.selectMenuTime }}
                >
                  {timeOptionList.map((item, index) => (
                    <MenuItem key={index} value={item.time}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* START AND END DATE DIVIDER */}
              <Stack flex={1}>
                <Box className={classes.startAndEndDivider}/>
              </Stack>

              {/* END DATE */}
              <Input
                className={classes.dateAndTimeInput}
                value={endDateInput}
                onChange={(event) => onEndDateInputChange(event.target.value)}
              />

              {/* END TIME */}
              <FormControl
                variant='standard'
                className={classes.dateAndTimeSelect}
              >
                <Select
                  value={endTimeInput}
                  onChange={(event) => onEndTimeInputChange(event.target.value)}
                  MenuProps={{
                    className: classes.selectMenuTime
                  }}
                >
                  {timeOptionList.map((item, index) => (
                    <MenuItem key={index} value={item.time}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          )}

          {/* CALENDAR */}
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={idLocale}>
            <StaticDateRangePicker
              key={key}
              displayStaticWrapperAs='desktop'
              className={classes.dateRangePicker}
              value={tempValue}
              onChange={(newValue) => handleDateRangePickerChange(newValue)}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </>
              )}
            />
          </LocalizationProvider>

          {/* ACTIONS */}
          <Stack
            direction='row'
            alignItems='center'
          >
            {/* COUNT DAYS */}
            <Typography 
              variant='caption'
              marginRight='auto'
              color='text.secondary'
              fontWeight={600}
            >
              {getSelectedDays()}
            </Typography>

            {/* CANCEL BUTTON */}
            <Button
              className={`${classes.actionButton} ${classes.actionButtonCancel}`}
              onClick={handleDefaultButtonClick ? handleDefaultButtonClick : handleCancelButtonClick}
              startIcon={CancelIcon && CancelIcon}
            >
              {cancelButtonText}
            </Button>

            {/* SELECT BUTTON */}
            <Button
              className={classes.actionButton}
              onClick={() => handleSelectButtonClick(tempValue)}
            >
              {saveButtonText}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default DateRangeAndTimePicker

DateRangeAndTimePicker.propTypes = {
  value: PropTypes.array.isRequired,
  dateFormat: PropTypes.string,
  timeFormat: PropTypes.string,
  isWithTimePicker: PropTypes.bool,
  handleSelectButtonClick: PropTypes.func.isRequired,
  handleCancelButtonClick: PropTypes.func.isRequired,
  cancelButtonText: PropTypes.string,
  saveButtonText: PropTypes.string,
  CancelIcon: PropTypes.element,
  updateValueTempDate: PropTypes.array,
  onAfterResetToDefault: PropTypes.func,
  handleDefaultButtonClick: PropTypes.func
}

DateRangeAndTimePicker.defaultProps = {
  dateFormat: 'MM/DD/YYYY',
  timeFormat: 'HH:mm:ss',
  isWithTimePicker: true,
  cancelButtonText: 'Batal',
  saveButtonText: 'Simpan'
}
