import React, { useEffect, useRef, useState, useContext } from 'react'

// STYLES
import useStyles from './liveUseStyles'

// COMPONENTS
import ContentTabs from 'components/ContentTabs/ContentTabs'
import PageHeader from 'components/PageHeader/PageHeader'
import VideoPlayback from './VideoPlayback/VideoPlayback'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { PageChatContextProvider } from 'contexts/PageChatContext'

// MUIS
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'

// MUI ICONS
import IconSettings from '@mui/icons-material/Settings'

// COMPONENTS TAB
import LiveMain from './Live/Live'
import AgentHelp from './AgentHelp/AgentHelp'
import Conference from './Conference/Conference'
import Chat from 'pages/Chat/Chat'

// ROUTER DOM
import { useLocation } from 'react-router-dom'

// SERVICES
import { postConferenceCreate } from 'services/ConferenceService'

// UTILS
import { isAccessMenu } from 'utilities/auth'
import { capitalizeEachWord, guidGenerator } from 'utilities'

function useQuery() {
  const { search } = useLocation()
  
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const Live = () => {
  const classes = useStyles()
  const pageRef = useRef()

  const urlQuery = useQuery()

  const { badgeType, agentHelpNeedHelp, conferenceList, liveStreamingList } = useContext(AllPagesContext)
  
  const tabItemList = [ 
    {
      label: 'SIARAN LANGSUNG',
      value: 'live_now',
      access: isAccessMenu('Live'),
      hasBadge : false

    },
    {
      label: 'BANTUAN AGEN',
      value: 'agent_help',
      access: isAccessMenu('Agent Help'),
      hasBadge : false
    },
    {
      label: 'RAPAT',
      value: 'conference',
      access: true,
      hasBadge : false
    },
    {
      label: 'PEMUTARAN VIDEO',
      value: 'video_playback',
      access: isAccessMenu('Agent Playback Video')
    },
    {
      label: 'OBROLAN DAN PANGGILAN',
      value: 'chatAndCall',
      access: true
    }
  ]

  const [ selectedTab, setSelectedTab ] = useState(urlQuery.get('tab') || tabItemList.find(item => item.access).value)
  const [searchPlayback, setSearchPlayback] = useState('')
  const [updatedTabList,setUpdatedTabList] = useState(tabItemList)
  const [buttonAddEl, setButtonAddEl] = useState(null)

  /* GET SELECTED TAB ACCESS STATUS */
  const selectedTabAccess = (selected) => {
    const getAccess = tabItemList.find(item => item.value === selected)
    return getAccess.access
  }

  // CREATE CONFERENCE
  const createConferenceVideo = async () => {
    const response = await postConferenceCreate({
      channelId: guidGenerator(),
      type: 'VIDEO_CONFERENCE',
      participantLimit: 100
    })

    const bodyParams = {
      active: response.data.active,
      channelId: response.data.channelId ,
      hostUid:response.data.hostUid,
      id: response.data.id,
      type: response.data.type,
      totalParticipant: 0,
    }

    localStorage.setItem('ownerRoomDetail', JSON.stringify(bodyParams))
    localStorage.setItem('isUserJoinRoomMeet', JSON.stringify(true))
    localStorage.setItem('meetRoomClosed', JSON.stringify(false))

    if(response?.status !== 'error') {
      const newWindow = window.open(
        `${window.location.origin}/live/conference?id=${response.data.channelId}&typeUser=owner`,
        '_blank',
        'noopener,noreferrer'
      )
      if (newWindow) newWindow.opener = null
    }
  }

  /* SIDE EFFECT GET QUERY TAB */
  useEffect(() => {
    urlQuery.get('tab') && setSelectedTab(urlQuery.get('tab'))
  }, [urlQuery])

  useEffect(() => {
    if(selectedTab) {
      window.history.replaceState(null, 'tes', `/live?tab=${selectedTab}`)
    }
  }, [selectedTab])

  /* GET CURRENT COMPONENT TAB */
  const currentSelectedComponent = () => {
    if(selectedTab === 'live_now' && selectedTabAccess('live_now')) return <LiveMain />
    else if(selectedTab === 'agent_help' && selectedTabAccess('agent_help')) return <AgentHelp />
    else if(selectedTab === 'conference'/* && selectedTabAccess('conference') */) return <Conference />
    else if(selectedTab === 'video_playback' && selectedTabAccess('video_playback')) return <VideoPlayback />
    else if(selectedTab === 'chatAndCall') return <PageChatContextProvider>
      <Chat
        buttonAddEl={buttonAddEl}
        setButtonAddEl={setButtonAddEl}
      />
    </PageChatContextProvider>
  }
  
  // SET BADGE STATUS TRUE (isSeen = FALSE)
  useEffect(() => {
    if(badgeType?.messageType === 'CONFERENCE CREATED') {
      let newTabList = updatedTabList.map((item) => {
        if(item.label === 'RAPAT' && selectedTab !== 'conference') item.hasBadge = true
        return item
      }) 
      setUpdatedTabList(newTabList)
    } else if(badgeType?.type === 1) {
      let newTabList = updatedTabList.map((item) => {
        if(item.label === 'SIARAN LANGSUNG' && selectedTab !== 'live_now') item.hasBadge = true
        return item
      }) 
      setUpdatedTabList(newTabList)
    } else if (badgeType?.messageType === 'AGENT HELP CREATED') {
      let newTabList = updatedTabList.map((item) => {
        if(item.label === 'BANTUAN AGEN' && selectedTab !== 'agent_help') item.hasBadge = true
        return item
      }) 
      setUpdatedTabList(newTabList)
    }
  }, [badgeType])
 
  // SET BADGE STATUS FALSE (isSeen = TRUE)
  useEffect(() => {
    if(selectedTab === 'conference') {
      let newTabList = updatedTabList.map((item) => {
        if(item.label === 'RAPAT' && selectedTab === 'conference') item.hasBadge = false
        return item
      }) 
      setUpdatedTabList(newTabList)
    } else if(selectedTab === 'live_now') {
      let newTabList = updatedTabList.map((item) => {
        if(item.label === 'SIARAN LANGSUNG' && selectedTab === 'live_now') item.hasBadge = false
        return item
      }) 
      setUpdatedTabList(newTabList)
    } else if(selectedTab === 'agent_help') {
      let newTabList = updatedTabList.map((item) => {
        if(item.label === 'BANTUAN AGEN' && selectedTab === 'agent_help') item.hasBadge = false
        return item
      }) 
      setUpdatedTabList(newTabList)
    }
  }, [selectedTab])

  // HANDLE BADGE ALL LIVE IF LIVE.LENGTH > 0 AFTER USER LOGIN
  useEffect(() => {
    // AGENT HELP
    if(agentHelpNeedHelp.length > 0) {
      let newTabList = updatedTabList.map((item) => {
        if(item.label === 'BANTUAN AGEN' && selectedTab !== 'agent_help') item.hasBadge = true
        return item
      }) 
      setUpdatedTabList(newTabList)
    } else if(conferenceList.length > 0) {
      let newTabList = updatedTabList.map((item) => {
        if(item.label === 'RAPAT' && selectedTab !== 'conference') item.hasBadge = true
        return item
      }) 
      setUpdatedTabList(newTabList)
    } else if(liveStreamingList.length > 0) {
      let newTabList = updatedTabList.map((item) => {
        if(item.label === 'SIARAN LANGSUNG' && selectedTab !== 'live_now') item.hasBadge = true
        return item
      }) 
      setUpdatedTabList(newTabList)
    }
  }, [])

  // HANDLE BADGE WHILE LIVE.LENGTH === 0
  useEffect(() => {
    if(agentHelpNeedHelp.length === 0) {
      let newTabList = updatedTabList.map((item) => {
        if(item.label === 'BANTUAN AGEN') item.hasBadge = false
        return item
      }) 
      setUpdatedTabList(newTabList)
    } 
    if (conferenceList.length === 0) {
      let newTabList = updatedTabList.map((item) => {
        if(item.label === 'RAPAT') item.hasBadge = false
        return item
      }) 
      setUpdatedTabList(newTabList)
    } 
    if (liveStreamingList.length === 0) {
      let newTabList = updatedTabList.map((item) => {
        if(item.label === 'SIARAN LANGSUNG') item.hasBadge = false
        return item
      }) 
      setUpdatedTabList(newTabList)
    }
  }, [agentHelpNeedHelp.length, conferenceList.length, liveStreamingList.length])
  
  return (
    <Stack
      className={classes.pageRoot}
      ref={pageRef}
    >
      {/* TABS */}
      <ContentTabs
        tabItemList={updatedTabList.filter(item => item.access)}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        isExtraComponentAvailable={false}
        extraComponent={null}
        disabledPaddingTabs
        classNameTab={classes.customTab}
        tabsHeight={59}
      />

      {/* HEADER */}
      <Stack className={classes.headerWrapper} direction='row' alignItems='center'>
        <PageHeader
          isAddButtonAvailable={(selectedTab === 'conference' || selectedTab === 'chatAndCall')}
          title={capitalizeEachWord(updatedTabList.find(item => item.value === selectedTab).label)}
          isSearchAvailable={selectedTab === 'video_playback'}
          setSearch={setSearchPlayback}
          search={searchPlayback}
          onAddButtonIsClicked={(event) => {
            if (selectedTab === 'chatAndCall') setButtonAddEl(event.currentTarget)
            else createConferenceVideo()
          }}
        />

        <Stack alignItems='flex-end' pb='18px' flex={1}>
          <IconButton>
            <IconSettings />
          </IconButton>
        </Stack>
      </Stack>
      

      {/* CONTENT */}
      <Stack className={classes.pageContent}>
        {currentSelectedComponent()}
      </Stack>
    </Stack>
  )
}

export default Live