import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// COMPONENTS
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import DialogAddEditTagLocation from './DialogAddEditSettingsTagLocation/DialogAddEditSettingsTagLocation'
import LoadingBox from 'components/LoadingBox/LoadingBox'
import PageHeader from 'components/PageHeader/PageHeader'
// CONSTANTS
import { basePaths } from 'constants/paths'
import { iconListTagLocation } from 'constants/icons'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// DISPATCH
import { showAlert } from 'store/MainReducer'

// MUIS
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconDelete from '@mui/icons-material/Delete'
import IconEdit from '@mui/icons-material/Edit'

// PATH ICONS
import IconTodoList from 'assets/images/pathIcons/IconTodoList'

// SERVICES
import { deleteLocationTags, getLocationTagsList } from 'services/SettingsTagLocation'

// STYLES
import useStyles from './settingsTagLocationUseStyles'

const SettingsTagLocation = () => {
  const classes = useStyles()

  const pageRef = useRef()
  const dispatch = useDispatch()

  const history = useHistory()
  const location = useLocation()

  const initialFilters = {
    locationTagName : '',
  }

  const groupByList = [
    {
      title: 'Don\'t Group',
      value: null,
    },
  ]

  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('activityTypeName')
  const [size, setPageSize] = useState(100)
  const [tagLocationTableData, setTagLocationTableData] = useState([])
  const [filters, setFilters] = useState(initialFilters)
  const [selectedGroupBy, setSelectedGroupBy] = useState(groupByList[0])
  const [isFilterOn, setIsFilterOn] = useState(false)
  const [search, setSearch] = useState('')
  const [dialogAddTagLocation, setDialogAddTagLocation] = useState(null)
  const [dialogDeleteTagLocation, setDialogDeleteTagLocation] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [selectionModel, setSelectionModel] = useState([])

  const handleEditIconButtonClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()
    history.push(
      `${basePaths.settingsTagLocation}?mode=edit&id=${inputParams.id}`, 
      {
        mode: 'edit',
        data: {
          ...inputParams.row,
          iconCode: inputParams.row.iconId.toString()
        },
      }
    )
  }
  
  const initialColumns = [
    {
      field: 'locationTagName',
      headerName: 'Tag Lokasi',
      headerIcon: IconTodoList,
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: false,
      renderCell: params => {
        const Icon = params.row.iconId ? iconListTagLocation[Number(params.row.iconId)-1]?.Icon : null

        return (
          <Stack direction='row' alignItems='center'>
            {params.row.locationTagColor && (
              <Box
                className={classes.boxColorColumn}
                sx={{
                  backgroundColor: params.row.locationTagColor
                }}
              ></Box>
            )}

            {Icon && <Icon className={classes.boxIconColumn} />}

            <Typography className={classes.textTagLocationName} variant='body2'>{params.value}</Typography>
          </Stack>
        )
      }
    },
    {
      field: 'actions',
      headerName: '',
      width: 120,
      hide: false,
      areFilterAndSortShown: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box className={classes.columnActions}>
          {/* EDIT ICON */}
          <CustomTooltipBlack title='Ubah' placement='bottom'>
            <IconButton onClick={event => handleEditIconButtonClick(event, params)}>
              <IconEdit />
            </IconButton>
          </CustomTooltipBlack>

          {/* DELETE ICON */}
          <CustomTooltipBlack title='Hapus' placement='bottom'>
            <IconButton
              onClick={(event) => handleDeleteIconButtonClick(event, params)}
            >
              <IconDelete />
            </IconButton>
          </CustomTooltipBlack>
        </Box>
      ),
    },
  ]

  const [selectedColumnList, setSelectedColumnList] = useState(initialColumns)

  // NOTE: PERHAPS, THIS COULD BE OPTIMIZED. SOURCE: DATA GRID TABLE COMPONENT
  const groupingColDef = {
    headerName: 'Tag Lokasi',
    renderCell: (params) => {
      return (
        <Typography 
          variant='subtitle1' 
          className={classes.groupingRow}
          noWrap
        >
          {params.rowNode.groupingKey.toLowerCase()}
        </Typography>
      )
    },
  }

  // NOTE: PERHAPS, THIS COULD BE OPTIMIZED. SOURCE: DATA GRID FILTERS COMPONENT
  const handleColumnsMenuItemClick = (inputItem, inputIndex) => {
    let tempSelectedColumnList = [...selectedColumnList]
    if (selectedGroupBy.value && inputItem.field === 'locationTagName') {
    } else {
      tempSelectedColumnList[inputIndex].hide =
        !tempSelectedColumnList[inputIndex].hide
    }
    setSelectedColumnList(tempSelectedColumnList)
  }

  const handleDeleteIconButtonClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()
    setDialogDeleteTagLocation(inputParams)
  }

  const reloadData = () => {
    const params = {
      page,
      size,
      query: search
    }

    setIsLoading(true)

    getLocationTagsList(params).then((response) => {
      setTagLocationTableData(response.rows.map(item => ({...item, id: item.locationTagNo})))
      setTotal(response.totalElements)
      setIsLoading(false)
    })
  }

  const handleDeleteButtonClick = async () => {
    setIsLoading(true)
    const response = await deleteLocationTags(dialogDeleteTagLocation.row.id)

    if(response?.status !== 'error') {
      reloadData()
      dispatch(showAlert({ message: 'Berhasil Menghapus Tag Lokasi' }))
    } else {
      setIsLoading(false)
      dispatch(showAlert({ message: 'Gagal Menghapus Tag Lokasi' }))
    }

    setDialogDeleteTagLocation({})
  }

  useEffect(() => {
    reloadData()
  }, [page, size, order, orderBy, filters, search])

  useEffect(() => {
    if (!isFilterOn) setFilters(initialFilters)
  }, [isFilterOn])

  useEffect(() => {
    if (location.pathname === basePaths.settingsTagLocation && (location.search.includes('?mode=edit') || location.search.includes('?mode=add'))) {
      setDialogAddTagLocation(location?.state)
    }
    else setDialogAddTagLocation(false)
  }, [location])

  return (
    <Box className={classes.pageRoot} ref={pageRef}>
      {/* HEADER */}
      <PageHeader
        isAddButtonAvailable={true}
        onAddButtonIsClicked={() => history.push(`${basePaths.settingsTagLocation}?mode=add`, {
          mode: 'add'
        })}
        title='Tag Lokasi'
        search={search}
        setSearch={setSearch}
      />

      {/* CONTENT */}
      <LoadingBox isLoading={isLoading}>
        <DataGridFilters
          columns={initialColumns}
          selectedColumnList={selectedColumnList}
          handleColumnsMenuItemClick={handleColumnsMenuItemClick}
          isFilterOn={isFilterOn}
          setIsFilterOn={setIsFilterOn}
          groupByList={groupByList}
          selectedGroupBy={selectedGroupBy}
          setSelectedGroupBy={setSelectedGroupBy}
        />

        <DataGridTable
          initialColumns={initialColumns}
          rows={tagLocationTableData}
          total={total}
          page={page}
          pageSize={size}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setPage={setPage}
          setPageSize={setPageSize}
          setFilters={setFilters}
          isFilterOn={isFilterOn}
          selectedColumnList={selectedColumnList}
          setSelectedColumnList={setSelectedColumnList}
          selectedGroupBy={selectedGroupBy}
          getTreeDataPath={(row) => [row.locationTagName, row.locationTagNo]}
          getRowId={(row) => row.id}
          groupingColDef={groupingColDef}
          selectionModel={selectionModel} 
          setSelectionModel={setSelectionModel}
          isCheckboxPinned={true}
        />
      </LoadingBox>

      <DialogAddEditTagLocation
        dialogAddEditTagLocation={dialogAddTagLocation}
        setDialogAddEditTagLocation={setDialogAddTagLocation}
        pageRef={pageRef}
        reloadData={reloadData}
      />

      {/* DIALOG DELETE ACTIVITY TYPE */}
      <DialogConfirmation
        title='Hapus Tag Lokasi'
        caption='Apa Anda yakin ingin menghapus tag lokasi ini?'
        dialogConfirmationObject={dialogDeleteTagLocation}
        setDialogConfirmationObject={setDialogDeleteTagLocation}
        cancelButtonText='Batal'
        continueButtonText='Hapus'
        onContinueButtonClick={handleDeleteButtonClick}
        onCancelButtonClick={() => setDialogDeleteTagLocation({})}
      />
    </Box>
  )
}

export default SettingsTagLocation
