import React from 'react'
import ReactDOMServer from 'react-dom/server'

// CONSTANTS
import { colors } from 'constants/colors'
import { panelsInfoStatic } from '../PanelUnifiedMapInformation/panelInformationIntegratedConstant'

// LEAFLET
import L from 'leaflet'
import 'leaflet.motion/dist/leaflet.motion'

// MUIS
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconDirectionsCar from '@mui/icons-material/DirectionsCar'
import IconLocationOn from '@mui/icons-material/LocationOn'
import IconPerson from '@mui/icons-material/Person'
import IconPhoneAndroid from '@mui/icons-material/PhoneAndroid'
import IconTwoWheeler from '@mui/icons-material/TwoWheeler'

// SERVICES
import { getAddressByLatLong } from 'services/TrackingServices'

let tempArrTrail = {}
const maxArr = 60 // 1 = 5 SECONDS

const removeAllPolylines = (selectedObjectId, mapContext) => {
  const objectKeys = Object.keys(tempArrTrail)
  objectKeys?.forEach(keyName => {
    // DONT REMOVE POLYLINES OF SELECTED OBJECT
    if(selectedObjectId === Number(keyName)) return

    tempArrTrail[keyName].forEach(itemClass => {
      mapContext.removeLayer(itemClass)
    })
  })
}

const addAndRemovePolyline = (objectId, polylineClasses, mapContext) => {
  const findObjectId = Object.keys(tempArrTrail).find(item => Number(item) === objectId)

  // OBJECT FOUND
  if(Boolean(findObjectId)) {
    tempArrTrail[objectId].push(polylineClasses)

    if(tempArrTrail[objectId].length === maxArr) {
      mapContext.removeLayer(tempArrTrail[objectId][0])
      tempArrTrail[objectId].shift()
    }
  } else {
    // CREATE OBJECT
    tempArrTrail[objectId] = [polylineClasses]
  }
}

const MarkerObject = (
  objectData, setObjectDetail,
  setIsObjectDetailPanelShown,
  panByNegative, map,
  selectedObjectId, setSelectedObjectId,
  classes, markerColor
) => {
  const backgroundColor = objectData.group.groupColor ? objectData.group.groupColor : colors.clementine
  const controller = new AbortController()

  let icon
  if (objectData.type === 'KENDARAAN R2') icon = (<IconTwoWheeler style={{
    height: '16px !important',
    width: '16px !important'
  }} />)
  else if (objectData.type === 'KENDARAAN R4') icon = (<IconDirectionsCar style={{
    height: '16px !important',
    width: '16px !important'
  }}/>)
  else if (objectData.type === 'DEVICE') icon = (<IconPhoneAndroid style={{
    height: '16px !important',
    width: '16px !important'
  }}/>)
  else icon = (<IconPerson style={{
    height: '16px !important',
    width: '16px !important'
  }}/>)

  const customDivIcon = L.divIcon({
    className: 'custom-icon',
    html: ReactDOMServer.renderToString(
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          width: 'fit-content',
        }}
        className='zoom'
      >
        {/* ICON */}
        <Box
          style={{
            height: 24,
            width: 24,
            color: 'white',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: markerColor ? markerColor : backgroundColor
          }}
        >
          {icon}
        </Box>

        {/* TEXT */}
        <Typography
          className='labelMarkerObject'
          variant='caption'
          style={{
            color: 'white',
            whiteSpace: 'nowrap',
            marginLeft: '8px !important',
            padding: '2px 8px',
            textAlign: 'center',
            opacity: 0.85,
            filter: 'drop-shadow(0px 0px 3px rgba(0,0,0,0.2))',
            fontWeight: 600,
            backgroundColor: markerColor ? markerColor : backgroundColor
          }}
        >
          {objectData?.label || objectData?.deviceVehicleLable || '[Tidak Ada Label]'}
        </Typography>
      </Box>
    ),
  })

  // INSTANCE MARKER
  const instance = L.marker([
    parseFloat(objectData.state.gps.location.lat),
    parseFloat(objectData.state.gps.location.lng)
  ], {
    icon: customDivIcon,
    objectData: {...objectData, status: 'OBJECT'},
    type: 'object'
  })

  // LEAFLET MOTION
  let instancePolyline = null
  if(objectData.latestPositions.length >= 1 && selectedObjectId === objectData.id) {
    // CHECK PREV LAT LNG AND NEXT LAT LNG NOT SAME
    if(
      objectData.latestPositions[objectData.latestPositions.length - 1]?.lat !== objectData.state?.gps?.location?.lat
      && objectData.latestPositions[objectData.latestPositions.length - 1]?.lng !== objectData.state?.gps?.location?.lng
    ) {
      // CHECK LAT LNG NOT UNDEFINED
      if(objectData.latestPositions[objectData.latestPositions.length - 1]?.lat
        && objectData.latestPositions[objectData.latestPositions.length - 1]?.lng
        && objectData.state?.gps?.location?.lat
        && objectData.state?.gps?.location?.lng
      ) {
        instancePolyline = new L.motion.polyline(
          [
            [
              parseFloat(objectData.latestPositions[objectData.latestPositions.length - 1].lat),
              parseFloat(objectData.latestPositions[objectData.latestPositions.length - 1].lng),
            ],
            [
              parseFloat(objectData.state.gps.location.lat),
              parseFloat(objectData.state.gps.location.lng)
            ]
          ],
          null,
          {
            auto: true,
            duration: 5000,
          },
          {
            showMarker: true,
            icon: customDivIcon,
            removeOnEnd: true
          }
        )
  
        selectedObjectId === objectData.id && addAndRemovePolyline(objectData.id, instancePolyline, map)
        map.addLayer(instancePolyline)
      }
    }
  }

  /* MAP FOLLOW MARKER */
  selectedObjectId === objectData.id && map.setView([
    parseFloat(objectData.state.gps.location.lat),
    parseFloat(objectData.state.gps.location.lng)
  ], map.getZoom(), { animate: true, duration: 4 })

  // HANDLE MARKER CLICK
  instance.on('click', (event) => {
    L.DomEvent.stopPropagation(event)

    // SET SELECTED OBJECT ID FOR FOLLOW MARKER
    setSelectedObjectId(objectData.id)

    setObjectDetail(objectData)
    setIsObjectDetailPanelShown(panelsInfoStatic?.panelDeviceDetail)
    panByNegative()
  })

  const getAddress = async event => {
    const resultAddress = await getAddressByLatLong({
      lat: parseFloat(objectData?.state?.gps?.location?.lat),
      lng: parseFloat(objectData?.state?.gps?.location?.lng),
    }, controller.signal)

    event.tooltip.setContent(ReactDOMServer.renderToString(
      <>
        <Stack className={`${classes.itemTooltip} zoom`}>
          <IconPhoneAndroid className={classes.itemTooltipIcon} />
  
          <Typography className={classes.itemTooltipText}>
            {objectData?.label || objectData?.deviceVehicleLable || '[Tidak Ada Label]'}
          </Typography>
  
          <Chip className={classes.chipCar} variant='filled' label={<IconDirectionsCar />} />
        </Stack>
  
        <Stack className={`${classes.itemTooltip} description zoom`}>
          <IconLocationOn className={classes.itemTooltipIcon} />
  
          <Typography className={`${classes.itemTooltipText} description`}>
            {resultAddress?.value || 'Tidak ditemukan'}
          </Typography>
        </Stack>
      </>
    ))
  }

  instance.on('tooltipopen tooltipclose', event => {
    if (event.type === 'tooltipopen') {
      getAddress(event)
      
    } else if (event.type === 'tooltipclose') {
      controller.abort()
    }
  })

  // REMOVE ALL POLYLINES
  removeAllPolylines(selectedObjectId, map)

  instance.bindTooltip(ReactDOMServer.renderToString(
    <>
      <Stack className={`${classes.itemTooltip} zoom`}>
        <IconPhoneAndroid className={classes.itemTooltipIcon} />

        <Typography className={classes.itemTooltipText}>
          {objectData?.label || objectData?.deviceVehicleLable || '[Tidak Ada Label]'}
        </Typography>

        <Chip className={classes.chipCar} variant='filled' label={<IconDirectionsCar />} />
      </Stack>

      <Stack className={`${classes.itemTooltip} description zoom`}>
        <IconLocationOn className={classes.itemTooltipIcon} />

        <Typography className={`${classes.itemTooltipText} description`}>
          Sedang mencari...
        </Typography>
      </Stack>
    </>
  ), {
    direction: 'top',
    className: 'tooltip-marker-clear tooltip-object',
    offset: L.point(0, -28)
  })

  instance.on('mouseover', event => {
    const itemElement = event.target._map._container.parentElement?.querySelector(`[data-id="${objectData.id}"]`)

    if (itemElement) {
      itemElement.style.backgroundColor = colors.linkWater
    }
  })

  instance.on('mouseout', event => {
    const itemElement = event.target._map._container.parentElement?.querySelector(`[data-id="${objectData.id}"]`)

    if (itemElement) {
      itemElement.style.backgroundColor = 'transparent'
    }
  })

  // MARKER NOT ON MOVING
  if(!instancePolyline) return instance
  else return null // MARKER ON MOVING
}

export default MarkerObject