import React, { useState, useEffect } from 'react'

// COMPONENTS
import InformationEditText from '../InformationEditText/InformationEditText'

// CUSTOM COMPONENTS
import CustomInput from 'components/Customs/CustomInput'
import CustomMenuItem from 'components/Customs/CustomMenuItem'
import CustomPaper from 'components/Customs/CustomPaper'
import CustomTextField from 'components/Customs/CustomTextField'

// MUIS
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import Menu from '@mui/material/Menu'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconArrowDropDown from '@mui/icons-material/ArrowDropDown'
// import IconClear from '@mui/icons-material/Clear'
import IconSearch from '@mui/icons-material/Search'

// SERVICES
import { 
  getSSDGroups,
  getSSDSubGroups, 
  getCrimeTypes,
} from 'services/DispatchService'

// STYLES
import useStyels from './informationSectionUseStyles'

// UTILS
import { dateFormat } from 'utilities/index'

const InformationSection = (props) => {
  const { evidenceDetailObject } = props

  const initialInformation = {
    description: evidenceDetailObject.description,
    ssdGroupName: evidenceDetailObject.ssdGroupName,
    subGroupNames: evidenceDetailObject.subGroupNames,
    crimeTypeNames: evidenceDetailObject.crimeTypeNames,
  }
  
  const classes = useStyels()

  const [ informationParams, setInformationParams ] = useState(initialInformation)
  const [ ssdGroupNameMenuAnchor, setSsdGroupNameMenuAnchor ] = useState(null)
  const [ searchSsdGroupName, setSearchSsdGroupName ] = useState('')
  const [ ssdGroupNameList, setSsdGroupNameList ] = useState([])
  const [ subGroupNameMenuAnchor, setSubGroupNameMenuAnchor ] = useState(null)
  const [ searchSubGroupName, setSearchSubGroupName ] = useState('')
  const [ subGroupNameList, setSubGroupNameList ] = useState([])
  const [ crimeTypeMenuAnchor, setCrimeTypeMenuAnchor ] = useState(null)
  const [ searchCrimeType, setSearchCrimeType ] = useState('')
  const [ crimeTypeList, setCrimeTypeList ] = useState([])

  let fileType = ''
  if(evidenceDetailObject.medias && evidenceDetailObject.medias.length > 0) {
    const firstMedia = evidenceDetailObject.medias[0]
    
    if(firstMedia.mediaType === 'IMAGE') fileType = 'Photo'
  }

  const handleInformationParamsChange = (event) => {
    setInformationParams({
      ...informationParams,
      [event.target.name]: event.target.value,
    })
  }

  const handleSsdGroupMenuItemClick = (item, index) => {
    setInformationParams({
      ...informationParams,
      ssdGroupName: item.groupName,
    })
    setSsdGroupNameMenuAnchor(null)
  }

  const handleSubGroupMenuItemClick = (inputItem, index) => {
    let newData = [...informationParams.subGroupNames]
    if(newData.includes(inputItem)) newData = newData.filter(item => item.subGroupName !== inputItem.subGroupName)
    else newData.push(inputItem)

    setInformationParams({
      ...informationParams,
      subGroupNames: newData,
    })
    setSubGroupNameMenuAnchor(null)
  }

  const handleCrimeTypeMenuItemClick = (inputItem, index) => {
    let newData = [...informationParams.crimeTypeNames]
    if(newData.includes(inputItem)) newData = newData.filter(item => item.crimeTypeName !== inputItem.crimeTypeName)
    else newData.push(inputItem)

    setInformationParams({
      ...informationParams,
      crimeTypeNames: newData,
    })
    setCrimeTypeMenuAnchor(null)
  }

  const loadSsdGroupListData = async () => {
    const result = await getSSDGroups({
      page: 0, 
      size: 100,
      query: searchSsdGroupName,
    })
    return result
  }

  const loadSubGroupListData = async () => {
    const result = await getSSDSubGroups({
      page: 0, 
      size: 100,
      query: searchSubGroupName,
    })
    return result
  }

  const loadCrimeTypeListData = async () => {
    const result = await getCrimeTypes({
      page: 0, 
      size: 100,
      query: searchCrimeType,
    })
    return result
  }

  useEffect(() => {
    const fetching = async () => {
      const newSsdGroupListData = await loadSsdGroupListData()
      setSsdGroupNameList(newSsdGroupListData.rows)
  
      const newSubGroupListData = await loadSubGroupListData()
      setSubGroupNameList(newSubGroupListData.rows)
      
      let selectedSubGroupNameList = []
      newSubGroupListData.rows.forEach(resultItem => {
        informationParams.subGroupNames.forEach(subGroupNameItem => {
          if(resultItem.subGroupName === subGroupNameItem) {
            selectedSubGroupNameList.push(resultItem)
          }
        })
      })
      selectedSubGroupNameList = [...new Set(selectedSubGroupNameList)]
  
      const newCrimeTypeListData = await loadCrimeTypeListData()
      setCrimeTypeList(newCrimeTypeListData.rows)
      
      let selectedCrimeTypeNameList = []
      newCrimeTypeListData.rows.forEach(resultItem => {
        informationParams?.crimeTypeNames?.forEach(crimeTypeNameItem => {
          if(resultItem.crimeTypeName === crimeTypeNameItem) {
            selectedCrimeTypeNameList.push(resultItem)
          }
        })
      })
      selectedCrimeTypeNameList = [...new Set(selectedCrimeTypeNameList)]
  
      setInformationParams({
        ...informationParams,
        subGroupNames: selectedSubGroupNameList,
        crimeTypeNames: selectedCrimeTypeNameList,
      })
    }

    fetching()
  }, [searchSsdGroupName, searchSubGroupName, searchCrimeType])

  useEffect(() => {
    if(!ssdGroupNameMenuAnchor) setSearchSsdGroupName('')
  }, [ssdGroupNameMenuAnchor])

  useEffect(() => {
    if(!subGroupNameMenuAnchor) setSearchSubGroupName('')
  }, [subGroupNameMenuAnchor])

  useEffect(() => {
    if(!crimeTypeMenuAnchor) setSearchCrimeType('')
  }, [crimeTypeMenuAnchor])

  return (
    <CustomPaper className={classes.informationRoot}>
      {/* INFORMATION TITLE */}
      <Typography
        variant='h6'
        className={classes.title}
      >
        Informasi
      </Typography>

      <Divider/>

      {/* TITLE SECTION */}
      <InformationEditText
        label='Judul'
        editable
        initialValue={informationParams.description}
        inputName={'description'}
        informationParams={informationParams}
        setInformationParams={setInformationParams}
      />

      <Divider/>

      {/* NEEDED HELP SECTION */}
      <InformationEditText
        label='Butuh Bantuan'
        initialValue={evidenceDetailObject.neededHelp ? 'Bantu' : 'Aman'}
      />

      <Divider/>

      {/* FILE TYPE SECTION */}
      <InformationEditText
        label='Jenis File'
        initialValue={fileType}
      />

      <Divider/>

      {/* CREATED DATE SECTION */}
      <InformationEditText
        label='Tanggal Dibuat'
        initialValue={dateFormat(evidenceDetailObject.createdOn)}
      />

      <Divider/>

      {/* MOBILE DEVICE SECTION */}
      <InformationEditText
        label='Perangkat Seluler'
        initialValue={evidenceDetailObject.deviceName}
      />

      <Divider/>

      {/* LOCATION SECTION */}
      <InformationEditText
        label='Lokasi Kejadian'
        initialValue={evidenceDetailObject.address}
      />

      <Divider/>

      {/* DISTURBANCE GROUP SECTION */}
      <Box className={classes.informationEditTextRoot}>
        {/* LABEL */}
        <Typography
          variant='subtitle2'
          className={classes.label}
        >
          Golongan Gangguan
        </Typography>

        {/* INPUT */}
        <FormControl 
          variant='standard' 
          className={classes.formControl}
        >
          <CustomInput
            readOnly
            type='text'
            name='ssdGroupName'
            value={informationParams.ssdGroupName}
            onChange={handleInformationParamsChange}
            endAdornment={<IconArrowDropDown className={classes.dropDownIcon}/>}
            onClick={(event) => setSsdGroupNameMenuAnchor(event.currentTarget)}
          />
        </FormControl>

        {/* DISTURBANCE MENU */}
        <Menu
          anchorEl={ssdGroupNameMenuAnchor}
          open={Boolean(ssdGroupNameMenuAnchor)}
          onClose={() => setSsdGroupNameMenuAnchor(null)}
          className={`${classes.menu} no-zoom`}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          sx={{
            '& .MuiPaper-root': {
              maxWidth: 320,
            }
          }}
        >
          {/* SEARCH INPUT */}
          <Box className={`${classes.menuSearch} zoom`}>
            <CustomInput
              type='text'
              placeholder='Search'
              className={classes.menuSearchInput}
              value={searchSsdGroupName}
              onChange={(event) => setSearchSsdGroupName(event.target.value)}
              startAdornment={<IconSearch className={classes.menuSearchIcon}/>}
              onClick={(event) => event.stopPropagation()}
            />
          </Box>

          {/* DISTURBANCE ITEM OPTION LIST */}
          <Box className={`${classes.menuItemContainer} zoom`}>
            {ssdGroupNameList?.map((item, index) => (
              <CustomMenuItem 
                key={index}
                className={
                  informationParams.ssdGroupName === item.groupName ?
                    `${classes.menuItem} ${classes.menuItemSelected} zoom` :
                    `${classes.menuItem} zoom`
                }
                onClick={() => handleSsdGroupMenuItemClick(item, index)}
              >
                <Typography variant='subtitle2' noWrap>{item.groupName}</Typography>
              </CustomMenuItem>
            ))}
          </Box>
        </Menu>
      </Box>

      {/* SUB DISTURBANCE SECTION */}
      <Box className={classes.informationEditTextRoot}>
        {/* LABEL */}
        <Typography
          variant='subtitle2'
          className={classes.label}
        >
          Kelompok Gangguan
        </Typography>

        {/* INPUT */}
        <Autocomplete
          readOnly={Boolean(subGroupNameMenuAnchor) ? true : false}
          multiple
          limitTags={1}
          className={classes.autocomplete}
          options={subGroupNameList}
          getOptionLabel={option => option.subGroupName}
          value={informationParams.subGroupNames}
          onChange={(event, value, reason, details) => {
            setInformationParams({
              ...informationParams,
              subGroupNames: value,
            })
          }}
          renderInput={(params) => (
            <CustomTextField 
              {...params}
              variant='standard'
              onClick={(event) => setSubGroupNameMenuAnchor(event.currentTarget)}
            />
          )}
          // clearIcon={
          //   <IconClear
          //     fontSize='small'
          //     onClick={(event) => event.stopPropagation()}
          //   />
          // }
        />

        {/* SUB DISTURBANCE MENU */}
        <Menu
          anchorEl={subGroupNameMenuAnchor}
          open={Boolean(subGroupNameMenuAnchor)}
          onClose={() => setSubGroupNameMenuAnchor(null)}
          className={`${classes.menu} no-zoom`}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          sx={{
            '& .MuiPaper-root': {
              maxWidth: 320,
            }
          }}
        >
          {/* SEARCH INPUT */}
          <Box className={`${classes.menuSearch} zoom`}>
            <CustomInput
              type='text'
              placeholder='Search'
              className={classes.menuSearchInput}
              value={searchSubGroupName}
              onChange={(event) => setSearchSubGroupName(event.target.value)}
              startAdornment={<IconSearch className={classes.menuSearchIcon}/>}
              onClick={(event) => event.stopPropagation()}
            />
          </Box>

          {/* SUB DISTURBANCE ITEM OPTION LIST */}
          <Box className={`${classes.menuItemContainer} zoom`}>
            {subGroupNameList?.map((item, index) => (
              <CustomMenuItem 
                key={index}
                className={
                  Boolean(informationParams?.subGroupNames?.find(subGroupInfo => subGroupInfo.subGroupName === item.subGroupName)) ?
                    `${classes.menuItem} ${classes.menuItemSelected} zoom` :
                    `${classes.menuItem} zoom`
                }
                onClick={() => handleSubGroupMenuItemClick(item, index)}
              >
                <Typography variant='subtitle2' noWrap>{item.subGroupName}</Typography>
              </CustomMenuItem>
            ))}
          </Box>
        </Menu>
      </Box>

      {/* CRIME TYPE SECTION */}
      <Box className={classes.informationEditTextRoot}>
        {/* LABEL */}
        <Typography
          variant='subtitle2'
          className={classes.label}
        >
          Jenis Kejahatan
        </Typography>

        {/* INPUT */}
        <Autocomplete
          readOnly={Boolean(crimeTypeMenuAnchor) ? true : false}
          multiple
          limitTags={1}
          className={classes.autocomplete}
          options={crimeTypeList}
          getOptionLabel={option => option.crimeTypeName}
          value={informationParams.crimeTypeNames}
          onChange={(event, value, reason, details) => {
            setInformationParams({
              ...informationParams,
              crimeTypeNames: value,
            })
          }}
          renderInput={(params) => (
            <CustomTextField 
              {...params}
              variant='standard'
              onClick={(event) => setCrimeTypeMenuAnchor(event.currentTarget)}
            />
          )}
          // clearIcon={
          //   <IconClear
          //     fontSize='small'
          //     onClick={(event) => event.stopPropagation()}
          //   />
          // }
        />

        {/* CRIME TYPE MENU */}
        <Menu
          anchorEl={crimeTypeMenuAnchor}
          open={Boolean(crimeTypeMenuAnchor)}
          onClose={() => setCrimeTypeMenuAnchor(null)}
          className={`${classes.menu} no-zoom`}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          sx={{
            '& .MuiPaper-root': {
              maxWidth: 320,
            }
          }}
        >
          {/* SEARCH INPUT */}
          <Box className={`${classes.menuSearch} zoom`}>
            <CustomInput
              type='text'
              placeholder='Search'
              className={classes.menuSearchInput}
              value={searchCrimeType}
              onChange={(event) => setSearchCrimeType(event.target.value)}
              startAdornment={<IconSearch className={classes.menuSearchIcon}/>}
              onClick={(event) => event.stopPropagation()}
            />
          </Box>

          {/* DISTURBANCE ITEM OPTION LIST */}
          <Box className={`${classes.menuItemContainer} zoom`}>
            {crimeTypeList?.map((item, index) => (
              <CustomMenuItem 
                key={index}
                className={
                  Boolean(informationParams.crimeTypeNames && informationParams.crimeTypeNames.find(crimeTypeInfo => crimeTypeInfo.crimeTypeName === item.crimeTypeName)) ?
                    `${classes.menuItem} ${classes.menuItemSelected} zoom` :
                    `${classes.menuItem} zoom`
                }
                onClick={() => handleCrimeTypeMenuItemClick(item, index)}
              >
                <Typography variant='subtitle2' noWrap>{item.crimeTypeName}</Typography>
              </CustomMenuItem>
            ))}
          </Box>
        </Menu>
      </Box>
    </CustomPaper>
  )
}

export default InformationSection
