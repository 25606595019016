import { useState, useEffect, useContext } from 'react'

// COMPONENTS
import { customClusterMarker } from 'components/MarkerClusterCircle/MarkerClusterCircle'
import MarkerEvidence from '../MarkerEvidence/MarkerEvidence'
import MarkerObject from '../MarkerObject/MarkerObject'
import ShowMenuLeaflet from 'components/ShowMenuLeaflet/ShowMenuLeaflet'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// LEAFLET
import L from 'leaflet'
import 'leaflet.markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

// STYLES
import useStylesMarkerEvidence from '../MarkerEvidence/markerEvidenceUseStyles'

// UTILS
import { markersLabelShowHide } from './clustersUtils'

const Clusters = () => {
  const {
    evidenceList,
    objectMarkerList,
    map,
    setObjectDetail,
    setIsPanelLeftShown,
    panByNegative,
    selectedObjectId, setSelectedObjectId,
    markerClustersRef,
    dispatchEvidenceList,
    selectionModel,
    isPanelLeftShown,
    markerSettings
  } = useContext(PageTrackingContext)
  const classesMarkerEvidence = useStylesMarkerEvidence()

  // STATES
  const [clusterEvent, setClusterEvent] = useState()
  const [isShowMenu, setIsShowMenu] = useState(false)
  const [isRefUpdated, setIsRefUpdated] = useState(false)

  // SIDE EFFECT SET markerClustersRef
  useEffect(() => {
    if(map && !markerSettings.isClusteringLocation) {
      if (markerClustersRef.current) markerClustersRef.current.clearLayers()

      markerClustersRef.current = L.markerClusterGroup({
        chunkedLoading: true,
        spiderfyDistanceMultiplier: 0,
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: false,
        maxClusterRadius: 40,
        iconCreateFunction: (cluster) => customClusterMarker(cluster, map),
        zoomToBoundsOnClick: false,
        disableClusteringAtZoom: markerSettings.isClustering ? null : 1
      })

      markerClustersRef.current.on('clusterclick', (event) => setClusterEvent(event))
      setIsRefUpdated(true)
    } else {
      markerClustersRef.current && markerClustersRef.current.clearLayers()
    }
  }, [map, markerSettings])

  // SIDE EFFECT CLUSTER
  useEffect(() => {
    if(map && objectMarkerList.length && markerClustersRef.current && !markerSettings.isClusteringLocation) {
      // CLEAR LAYERS
      markerClustersRef.current.clearLayers()
      // TEMP MARKER
      let tempMarkersObject = []

      // SHOW SELECTED MARKER ONLY
      if (selectionModel?.length) {
        selectionModel?.forEach(objectId => {
          const findItem = objectMarkerList.find(item => item.id === objectId)

          if(findItem?.state?.gps?.location?.lat && findItem?.state?.gps?.location?.lng) {
            const createMarker = MarkerObject(
              findItem, setObjectDetail,
              setIsPanelLeftShown,
              panByNegative, map,
              selectedObjectId, setSelectedObjectId,
              classesMarkerEvidence
            )
            createMarker && tempMarkersObject.push(createMarker)
          }
        })
      } else {
        objectMarkerList.forEach(item => {
          if(item.state?.gps?.location?.lat && item.state?.gps?.location?.lng) {
            const createMarker = MarkerObject(
              item, setObjectDetail,
              setIsPanelLeftShown,
              panByNegative, map,
              selectedObjectId, setSelectedObjectId,
              classesMarkerEvidence
            )
            createMarker && tempMarkersObject.push(createMarker)
          }
        })

        // CREATE INSTANCE EVIDENCES
        evidenceList.forEach(item => {
          if(item.latitude && item.longitude) {
            const createMarker = MarkerEvidence(
              item,
              setIsPanelLeftShown,
              classesMarkerEvidence,
              item.sosStatus ? 'SOS' : 'evidence',
              isPanelLeftShown?.data?.evidenceNo
            )
            tempMarkersObject.push(createMarker)
          }
        })

        // CREATE INSTANCE DISPATCHES
        dispatchEvidenceList.forEach(item => {
          if(item.address.latitude && item.address.longitude) {
            const createMarker = MarkerEvidence(
              item,
              setIsPanelLeftShown,
              classesMarkerEvidence,
              'dispatch'
            )
            tempMarkersObject.push(createMarker)
          }
        })
      }

      markerClustersRef.current.addLayers(tempMarkersObject)
      map.addLayer(markerClustersRef.current)

      markersLabelShowHide(markerClustersRef.current, markerSettings)
    }

    if (isRefUpdated) setIsRefUpdated(false)
  }, [map, isRefUpdated, objectMarkerList, evidenceList, selectionModel, dispatchEvidenceList, isPanelLeftShown])

  useEffect(() => {
    if(clusterEvent) {
      let tempObject = []
      let tempEvidenceHelp = []
      let tempEvidencePending = []
      let tempEvidenceResolved = []
      let tempDispacthes = []

      // GET CHILD OF CLUSTER AND PUSH TO TEMP
      clusterEvent.layer.getAllChildMarkers().map(item => {
        if(item.options.objectData?.status === 'OBJECT') {
          tempObject.push({
            id: item.options.objectData.id,
            text: item.options.objectData.label,
            status: item.options.objectData.status,
            dataDetail: item.options.objectData
          })
        } else if(item.options.objectData?.status === 'NEEDED_HELP') {
          tempEvidenceHelp.push({
            id: item.options.objectData.evidenceNo,
            text: item.options.objectData.description,
            status: item.options.objectData.status,
            lat: parseFloat(item.options.objectData.latitude),
            lng: parseFloat(item.options.objectData.longitude),
          })
        } else if(item.options.objectData?.status === 'PENDING') {
          tempEvidencePending.push({
            id: item.options.objectData.evidenceNo,
            text: item.options.objectData.description,
            status: item.options.objectData.status,
            lat: parseFloat(item.options.objectData.latitude),
            lng: parseFloat(item.options.objectData.longitude),
          })
        } else if(item.options.objectData?.status === 'RESOLVED') {
          tempEvidenceResolved.push({
            id: item.options.objectData.evidenceNo,
            text: item.options.objectData.description,
            status: item.options.objectData.status,
            lat: parseFloat(item.options.objectData.latitude),
            lng: parseFloat(item.options.objectData.longitude),
          })
        } else if (item?.options?.objectData?.dispatchNo) {
          tempDispacthes.push({
            id: item.options.objectData.dispatchNo,
            text: item.options.objectData.label,
            status: 'DISPATCHES',
            lat: parseFloat(item?.options.objectData.address.latitude),
            lng: parseFloat(item.options.objectData.address.longitude),
            data: item.options.objectData
          })
        }
      })

      setIsShowMenu({
        mouseEvent: clusterEvent,
        dataCluster: [...tempObject, ...tempEvidenceHelp, ...tempEvidencePending, ...tempEvidenceResolved, ...tempDispacthes]
      })
    }
  }, [clusterEvent])

  return (
    <>
      <ShowMenuLeaflet
        isShowMenu={isShowMenu}
        setIsShowMenu={setIsShowMenu}
        inPage='TRACKING'
        setIsPanelLeftShown={setIsPanelLeftShown}
        setObjectDetail={setObjectDetail}
        setIsObjectDetailPanelShown={setIsPanelLeftShown}
        map={map}
      />
    </>
  )
}

export default Clusters