// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  iconAndFormControlContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    height: 65,
    width: '100%',
    flex: '0 0 65px',
  },
  radiusWrapper: {
    padding: '76px 4px 0 4px',
  },
  iconAndFormControlContainerNew: {
    display: 'flex',
    alignItems: 'flex-end',
    height: 'auto',
    width: '100%',
    flex: '0 0 65px',
    '& .MuiInput-root': {
      margin: '4px'
    }
  },
  iconFormControl: {
    marginRight: 12,
    marginBottom: 2,
    color: colors.raven,
  },
  formControl: {
    width: '100%',
  },
  formControlLocationAdorment: {
    cursor: 'pointer',
  },
  locationSuggestionsContainer: {
    backgroundColor: 'white',
    padding: '10px 0px',
    position: 'absolute',
    zIndex: theme.zIndex.snackbar,
    boxShadow: theme.shadows[3],
  },
  locationSuggestionsItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.athensGray,
    },
  },
  locationSuggestionsContainerDark: {
    backgroundColor: colors.mirage,
    width: 372,
    padding: '10px 0px',
    position: 'absolute',
    zIndex: theme.zIndex.snackbar,
    boxShadow: theme.shadows[3],
  },
  locationSuggestionsItemDark: {
    cursor: 'pointer',
    color: 'white',
    '&:hover': {
      backgroundColor: colors.ebonyClay,
    },
  },
  iconRadiusDropDown: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.25s ease-in-out',
  },
  iconRadiusDropUp: {
    transform: 'rotate(180deg)',
    transition: 'transform 0.25s ease-in-out',
  },
  patrolLocationRadiusMenu: {
    zIndex: theme.zIndex.snackbar,
  },
  locationRadius: {
    width: '50%',
    '& .MuiInput-root': {
      backgroundColor: theme.palette.common.white,
      padding: 4,
      marginLeft: 0
    }
  },
  locationSlider: {
    width: '50%',
    marginBottom: 2,
    marginLeft: 20,
    marginTop: 30,
    '& .MuiSlider-valueLabelOpen': {
      paddingLeft: 6,
      paddingRight: 6,
    },
    '& .MuiSlider-thumbColorPrimary': {
      height: 8,
      width: 8,
    }
  },
  inputCustomNew: {
    width: '100%',
    margin: '0 8px',
    padding: '0 8px',
    '& .MuiInput-input': {
      padding: '8px 0 !important'
    }
  },
  googleMapWrap: {
    padding: '4px 4px'
  },
  labelRadius: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      marginRight: 8,
    }
  },
  iconMap: {
    color: colors.california
  }
}))

export default useStyles