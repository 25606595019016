// UTILITIES
import { http } from 'utilities/http'

export const postFcmNotificationToken = async (bodyParams) => {
  try {
    const response = await http.post('/fcm-notification/token', bodyParams)
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}