// MUIS
import InputLabel from '@mui/material/InputLabel'
import { styled } from '@mui/material/styles'

const CustomInputLabel = styled(({ className, ...props }) => (
  <InputLabel
    className={className} 
    {...props}
  />
))(({ theme }) => ({
  fontSize: 14,
}))

export default CustomInputLabel