// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 0,
    position: 'relative',
  },
  customTab: {
    '&.MuiTab-root': {
      height: 60,
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.text.secondary,
    },
    '&.MuiTab-root.Mui-selected': {
      color: '#000000'
    }
  },
  headerWrapper: {
    margin: '24px 24px 0 24px'
  },
  pageContent: {
    flex: '1 1 auto',
    backgroundColor: 'white',
    boxShadow: theme.shadows[2],
    margin: '0 24px 24px 24px',
    position: 'relative',
    overflow: 'hidden'
  },
}))

export default useStyles