import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { setupInterceptors } from './http'

export default function InjectAxiosInterceptors () {
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    setupInterceptors(history, location)
  }, [history])

  // not rendering anything
  return null
}
