// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  popupWrapper: {
    height: '100%',
    justifyContent: 'center'
  },
  popupRoot: {
    backgroundColor: theme.palette.common.white,
    padding: 16,
    borderRadius: 0,
    boxShadow: `2px 2px 8px ${alpha(theme.palette.common.black, 0.24)} !important`,
  },
  popupTitle: {
    color: theme.palette.text.secondary,
    marginBottom: 8,
    fontSize: '12px !important'
  },
  popupAddress: {
    marginBottom: 8,
    fontSize: '12px !important'
  },
  popupCoordinate: {
    marginBottom: 8,
    fontSize: '12px !important'
  },
  popupButton: {
    backgroundColor: theme.palette.primary.main,
    width: 'fit-content',
    color: `${theme.palette.common.white} !important`,
    fontSize: '12px !important',
    padding: '8px 16px',
    fontWeight: 600,
    borderRadius: 2,
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.4s',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  popupMarker: {
    marginBottom: 0,
    '& p': {
      margin: 0,
    },
    '& .leaflet-popup-tip-container': {
      display: 'none'
    },
    '& .leaflet-popup-content-wrapper': {
      borderRadius: 0,
      boxShadow: 'none',
      backgroundColor: 'transparent'
    },
    '& .leaflet-popup-content': {
      height: 180,
      margin: 0,
    }
  }
}))

export default useStyles