// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '50px',
    overflow: 'auto',
    flex: '1',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
  },
  informationListItem: {
    alignItems: 'unset',
    '& .MuiListItemIcon-root': {
      minWidth: 'unset',
      marginRight: 12,
      marginTop: 4,
    },
    '& .MuiListItemText-primary': {
      fontWeight: 600,
      color: theme.palette.text.secondary,
      fontSize: 12,
    },
    '& .MuiListItemText-secondary': {
      fontWeight: 400,
      color: theme.palette.text.primary,
    },
  },
  informationValueWorkOrderNumber: {
    color: colors.mariner,
    borderColor: colors.mariner,
    height: 24,
    backgroundColor: alpha(colors.mariner, 0.16),
    marginTop: 4,
  },
  informationValueDevice: {
    color: 'white',
    height: 24,
    backgroundColor: colors.raven,
    marginTop: 4,
    '& .MuiChip-label': {
      paddingLeft: 8,
    },
  },
  informationValueDeviceIcon: {
    height: 16,
    color: 'white !important',
  },
  informationValueChip: {
    height: 24,
    marginTop: 4,
  },
  informationValueOfficer: {
    width: 'fit-content',
    height: 24,
    borderColor: colors.denim,
  },
  informationValueOfficerAvatar: {
    height: '16px !important',
    width: '16px !important',
    backgroundColor: colors.denim,
    color: 'white !important',
    fontSize: '10px !important',
  },
  informationValueShift: {
    height: 24,
    marginTop: 4,
  },
  informationValueShiftIcon: {
    marginRight: 8,
    height: 16,
  },
  informationValuePhoto: {
    height: 100,
    width: 100,
    minWidth: 100,
    borderRadius: 4,
    objectFit: 'cover',
    cursor: 'pointer'
  },
  informationValueBrokenImageIcon: {
    color: colors.aluminium,
    width: 36,
    height: 36,
  },
}))

export default useStyles