// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  informationEditTextRoot: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '8px 16px',
    // minHeight: 68,
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    color: colors.gray,
    fontSize: 12,
    marginBottom: 4,
    marginRight: 'auto',
  },
  iconAction: {
    height: 18,
    width: 18,
    cursor: 'pointer',
    marginLeft: 16,
  },
  iconActionEdit: {
    color: colors.silver,
    marginLeft: 0,
  },
  text: {
    display: 'flex',
    color: colors.gray,
    fontSize: 14,
    fontWeight: 600,
  },
  textField: {
    width: '100%',
    marginBottom: 8,
  },
}))

export default useStyles
