// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  dialogHeaderTitle: {
    fontWeight: 600,
    marginBottom: 16,
  },
  formControl: {
    marginBottom: 16,
    borderRadius: '2px !important',
    '& .formControlDatePicker': {
      cursor: 'pointer'
    },
    '&:last-child': {
      marginBottom: 0,
    }
  },
  pockbaseStyles: {
    backgroundColor: '#e4e9ec',
    transition: 'background-color .4s',
    padding: '4px 0 4px 12px',
    borderRadius: 2,
    '& .MuiInput-input': {
      padding: '12px 16px'
    },
    '&:focus-within': {
      backgroundColor: '#D7DDE1'
    }
  },
  pockbaseStylesIcon: {
    fontSize: 16,
    color: theme.palette.text.secondary,
  },
  pockbaseStylesLabel: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginLeft: 8,
    fontWeight: 500,
  },
  editorWrapper: {
    padding: '40px 3px 3px 3px',
    '& .tox-tinymce': {
      borderRadius: 0,
    }
  },
  typeMenuItem: {
    fontSize: 14,
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: 16,
      marginRight: 12,
    }
  },
  inputDeviceWrapper: {
    '& .titleInputDevice': {
      fontSize: 20,
      fontWeight: 500,
    }
  },
  dateTimePickerWrapper: {
    padding: '40px 4px 4px 4px'
  },
  detailDateIcon: {
    fontSize: 16,
    color: theme.palette.text.secondary
  },
  detailDateTitle: {
    fontSize: 12,
    color: theme.palette.text.primary,
    paddingLeft: 8,
    fontWeight: 600,
  },
  detailDateDesc: {
    fontSize: 10,
    color: theme.palette.text.secondary,
    paddingLeft: 8,
    marginTop: 4,
  },
  dateRangeAndTimePickerDialog: {
    '& .MuiDialog-paper': {
      maxWidth: 'unset',
    },
    '& .MuiPaper-root': {
      borderRadius: 0,
    },
  },
  selectTypeTask: {
    '& .MuiSelect-select': {
      backgroundColor: theme.palette.common.white,
      padding: '12px 8px',
      borderRadius: 2,
    },
    '& .MuiInputBase-root': {
      padding: '40px 4px 4px 4px',
      borderRadius: 2,
    },
    '& .MuiSvgIcon-root': {
      top: 'calc(50% - -0.24em) !important',
    }
  },
  customMenuAdd: {
    zIndex: theme.zIndex.modal - 1,
    transform: 'translate(168px, -26px) !important',
    '& .MuiPaper-root': {
      width: 'calc(496px - 16px - 16px) !important',
    },
    '& .MuiMenu-list': {
      padding: 0,
    },
    '@media only screen and (max-height: 820px)': {
      transform: 'translate(108px, -26px) !important',
      '& .MuiPaper-root': {
        width: 'calc(420px - 16px - 16px) !important',
        maxWidth: '420px !important'
      }
    }
  },
  customMenuAddFullWidth: {
    transform: 'translate(140px, -26px) !important',
    '& .MuiPaper-root': {
      width: 'calc(436px - 16px - 16px) !important',
    },
    '@media only screen and (max-height: 820px)': {
      transform: 'translate(-42px, -26px) !important',
      '& .MuiPaper-root': {
        width: 'calc(374px - 16px - 16px) !important',
      },
    }
  },
  typeTaskPaper: {
    borderRadius: 0,
  },
  boxPreviewDatePicker: {
    backgroundColor: theme.palette.common.white,
    alignItems: 'center',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    flex: 1,
    padding: '8px 12px',
    height: 40,
    borderRadius: 2,
  },
}))

export default useStyles