import React, { createContext, useState } from 'react'

export const PageAlertRulesContext = createContext()

const PageAlertRulesContextProvider = (props) => {
  const [objectList, setObjectList] = useState([])
  const [selectionModel, setSelectionModel] = useState([])
  const [geofencesList, setGeofencesList] = useState(false)
  const [rulesList, setRulesList] = useState([])
  const [selectedRulesItem, setSelectedRulesItem] = useState(false)
  const [isRefetchingRules, setIsRefetchingRules] = useState(true)

  return (
    <PageAlertRulesContext.Provider
      value={{
        objectList, setObjectList,
        selectionModel, setSelectionModel,
        geofencesList, setGeofencesList,
        rulesList, setRulesList,
        selectedRulesItem, setSelectedRulesItem,
        isRefetchingRules, setIsRefetchingRules
      }}
    >
      {props.children}
    </PageAlertRulesContext.Provider>
  )
}

export default PageAlertRulesContextProvider