// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconSetting = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M12.0157 14.808C13.5577 14.808 14.8077 13.558 14.8077 12.016C14.8077 10.474 13.5577 9.22394 12.0157 9.22394C10.4737 9.22394 9.22363 10.474 9.22363 12.016C9.22363 13.558 10.4737 14.808 12.0157 14.808Z' />
      <path d='M20.2357 10.6118L18.7695 10.4714C18.5979 9.69151 18.286 8.974 17.8804 8.31889L18.8319 7.18024C19.1283 6.83709 19.0971 6.33795 18.7851 6.026L18.0208 5.2617C17.7088 4.94974 17.1941 4.93414 16.8666 5.2149L15.7279 6.16638C15.0728 5.74523 14.3397 5.44887 13.5754 5.2617L13.4194 3.7799C13.3726 3.32756 12.9983 3 12.5615 3H11.4853C11.0329 3 10.6742 3.34315 10.6274 3.7799L10.487 5.2617C9.70711 5.43328 8.9896 5.74523 8.33449 6.15078L7.19584 5.19931C6.85269 4.91854 6.35355 4.93414 6.04159 5.2461L5.2773 6.0104C4.96534 6.32236 4.94974 6.83709 5.2305 7.16464L6.18198 8.30329C5.76083 8.95841 5.46447 9.67591 5.2773 10.4558L3.7799 10.6118C3.32756 10.6586 3 11.0329 3 11.4697V12.5459C3 12.9983 3.34315 13.357 3.7799 13.4038L5.2617 13.5442C5.43328 14.3241 5.74523 15.0416 6.15078 15.6967L5.19931 16.8354C4.91854 17.1785 4.93414 17.6776 5.2461 17.9896L6.0104 18.7539C6.32236 19.0659 6.83709 19.0815 7.16464 18.8007L8.30329 17.8492C8.95841 18.2704 9.67591 18.5667 10.4558 18.7539L10.5806 20.2201C10.6274 20.6724 11.0017 21 11.4385 21H12.5147C12.9671 21 13.3414 20.6568 13.3726 20.2201L13.513 18.7539C14.2929 18.5823 15.0104 18.2704 15.6655 17.8648L16.8042 18.8163C17.1473 19.0971 17.6464 19.0815 17.9584 18.7695L18.7227 18.0052C19.0347 17.6932 19.0659 17.1785 18.7695 16.851L17.818 15.7123C18.2392 15.0572 18.5355 14.3397 18.7227 13.5598L20.1889 13.435C20.6412 13.3882 20.9688 13.0139 20.9688 12.5771V11.5009C21.0156 11.0329 20.6724 10.6586 20.2357 10.6118ZM12.0156 16.0087C9.80069 16.0087 8.02253 14.2149 8.02253 12C8.02253 9.80069 9.80069 8.00693 12.0156 8.00693C14.2149 8.00693 16.0087 9.80069 16.0087 12C16.0087 14.2149 14.2149 16.0087 12.0156 16.0087Z' />
    </SvgIcon>
  )
}

export default IconSetting