import React from 'react'

// MUIS
import Input from '@mui/material/Input'
import { styled } from '@mui/material/styles'

const CustomInput = styled(({ className, ...props }) => (
  <Input
    className={className} 
    {...props}
  />
))(({ theme }) => ({
  '& .MuiInput-input': {
    fontSize: 14,
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
}))

const ForwardRefInput = React.forwardRef((props, ref) => {
  return (<CustomInput {...props}/>)
})

export default ForwardRefInput