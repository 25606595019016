// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconIllegalTransplant = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M11.8098 1.41174C9.87661 1.41174 8.27076 3.07515 8.27076 5.00829C8.27076 6.34261 9.09437 7.89991 9.50797 8.59045C9.18608 8.9591 8.73111 9.69819 8.73111 11.0793C8.73111 12.2769 9.18608 13.2372 9.50797 13.6975C9.18608 14.1579 8.73111 15.1308 8.73111 16.6036C8.73111 19.8728 11.3962 20.7468 12.9607 20.7468C14.4334 20.7468 19.3193 20.0562 19.3193 11.0793C19.3193 4.45083 15.4459 1.41174 11.8098 1.41174ZM7.89671 7.43956C6.51564 8.13009 4.58789 9.73775 4.58789 13.4674C4.58789 14.6183 4.77491 15.78 5.00509 16.9776C5.23527 18.4504 5.50861 20.103 5.50861 22.1279C5.50861 22.4048 5.69203 22.5882 5.96896 22.5882H7.8104C8.08733 22.5882 8.27076 22.4048 8.27076 22.1279C8.27076 21.2539 8.13229 20.2828 8.04058 19.4089C7.94886 18.5349 7.8104 17.6196 7.8104 16.7906V16.6036C7.8104 15.3142 8.13588 14.2964 8.45778 13.6975C8.13588 13.0538 7.8104 12.1834 7.8104 11.0793C7.8104 9.92837 8.09273 9.09936 8.41462 8.54729C8.23119 8.2254 8.03518 7.85316 7.89671 7.43956Z'/>
    </SvgIcon>
  )
}

export default IconIllegalTransplant