// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'
import { alpha } from '@mui/material/styles'

const useStyles = makeStyles((theme) => ({
  panelSearchDetail: {
    position: 'relative',
    zIndex: 3,
    backgroundColor: theme.palette.common.white,
    width: 380,
    boxShadow: theme.shadows[4],
    borderRadius: 4,
  },
  buttomBackDetail: {
    position: 'absolute',
    zIndex: 3,
    top: 8,
    left: 16,
    color: theme.palette.common.white,
    fontWeight: 500,
  },
  searchDetailContent: {
    padding: '12px 16px'
  },
  listDetailPanel: {
    '&.MuiList-root': {
      width: '100% !important',
    },
    '& .MuiListItemIcon-root': {
      minWidth: 48,
    },
    '& .MuiListItemButton-root': {
      padding: '4px 0',
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
  },
  searchDetailPanel: {
    //height: 400,
    position: 'relative'
  },
  searchDetailView: {
    height: 200,
    position: 'relative',
  },
  backdropBlur: {
    backgroundColor: alpha(theme.palette.common.white, 0.2),
    backdropFilter: 'blur(3px)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  avatarListDetail: {
    backgroundColor: colors.california,
    '&.noStyle': {
      backgroundColor: 'transparent',
      color: theme.palette.text.secondary,
    }
  },
  buttonStreetView: {
    color: alpha(theme.palette.common.white, 0.72),
  },
  iconStreetView: {
    fontSize: 28,
  },
  dialogStreetView: {
    '& .MuiPaper-root': {
      maxWidth: '100vw',
    }
  },
  dialogStreetViewContent: {
    width: '80vw',
    height: '60vh',
    position: 'relative'
  }
}))

export default useStyles