import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// CONSTANTS
import getCheckboxColumn from './checkboxColumn'

// CUSTOM COMPONENTS
import CustomDataGrid from 'components/Customs/CustomDataGrid'
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'
import FilterTextField from 'components/FilterTextField/FilterTextField'

// LODASH
import lodash from 'lodash'

// MUIS
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { 
  GRID_CHECKBOX_SELECTION_COL_DEF,
  useGridApiRef, 
} from '@mui/x-data-grid-premium'

// MUI ICONS
import IconArrowUpward from '@mui/icons-material/ArrowUpward'
import IconSort from '@mui/icons-material/Sort'

// STYLES
import useStyles from './dataGridTableUseStyles'

const DataGridTable = (props) => {
  const {
    initialColumns,
    rows,
    total,
    page,
    pageSize,
    setOrder,
    setOrderBy,
    setPage,
    setPageSize,
    setFilters,
    isFilterOn,
    selectedColumnList,
    setSelectedColumnList,
    selectedGroupBy,
    getTreeDataPath,
    groupingColDef,
    selectionModel, 
    setSelectionModel,
    isCheckboxPinned,
    ...otherProps
  } = props

  const classes = useStyles()

  const dataGridApiRef = useGridApiRef()

  const [sortModel, setSortModel] = useState([])

  const getSortIcon = (field) => {
    const currentSortModel = dataGridApiRef.current.getSortModel()

    let selectedIcon = <IconSort className={classes.columnUnsortedIconAsc} />
    if (currentSortModel[0]) {
      if (currentSortModel[0].field === field) {
        if (currentSortModel[0].sort === 'asc') {
          selectedIcon = (
            <IconArrowUpward
              className={`${classes.columnUnsortedIconAsc} ${classes.columnSortedIconAsc}`}
            />
          )
        } else if (currentSortModel[0].sort === 'desc') {
          selectedIcon = (
            <IconArrowUpward
              className={`${classes.columnUnsortedIconAsc} ${classes.columnSortedIconDesc}`}
            />
          )
        }
      } else {
        if (currentSortModel[0].sort === 'asc') {
          selectedIcon = (
            <IconSort
              className={`${classes.columnUnsortedIconAsc} ${classes.columnUnsortedIconAsc}`}
            />
          )
        } else if (currentSortModel[0].sort === 'desc') {
          selectedIcon = (
            <IconSort
              className={`${classes.columnUnsortedIconAsc} ${classes.columnUnsortedIconDesc}`}
            />
          )
        }
      }
    }
    return selectedIcon
  }

  const handleSortIconClick = (field) => {
    const currentSortModel = dataGridApiRef.current.getSortModel()

    let newSortModel = []
    if (currentSortModel[0]) {
      if (currentSortModel[0].field === field) {
        if (currentSortModel[0].sort === 'asc') {
          newSortModel = [
            {
              field,
              sort: 'desc',
            },
          ]
        } else if (currentSortModel[0].sort === 'desc') {
          newSortModel = []
        }
      } else {
        newSortModel = [
          {
            field,
            sort: 'asc',
          },
        ]
      }
    } else if (!currentSortModel[0]) {
      newSortModel = [
        {
          field,
          sort: 'asc',
        },
      ]
    }

    handleSortModelChange(newSortModel)
  }

  const defaultHeaderColumnRenderer = (inputItem) => {
    return (
      <>
        {/* ICON */}
        {inputItem.headerIcon &&
        <inputItem.headerIcon className={classes.columnIcon}/>}

        {/* HEADER NAME */}
        <Typography
          variant='caption'
          fontWeight={600}
        >
          {inputItem.headerName}
        </Typography>
      </>
    )
  }

  const filterOnColumns = initialColumns.map((item) => {
    if (item.field !== 'actions' && item.areFilterAndSortShown) {
      return {
        renderHeader: (params) => (
          <>
            {/* ICON */}
            {item.headerIcon &&
            <item.headerIcon className={classes.columnIcon}/>}

            {/* DEVICE TEXT FIELD */}
            <FilterTextField
              className={classes.columnFilter}
              variant='standard'
              label={item.headerName}
              type='text'
              name={item.field}
              updateFilters={setFilters}
              onClick={(event) => event.stopPropagation()}
            />

            {/* SORT ICON */}
            <CustomTooltipBlack title='Urutkan berdasarkan field ini' placement='bottom'>
              <IconButton
                size='small'
                onClick={() => handleSortIconClick(item.field)}
              >
                {getSortIcon(item.field)}
              </IconButton>
            </CustomTooltipBlack>
          </>
        ),
      }
    }
    else if (item.field !== 'actions') {
      return { renderHeader: (params) => defaultHeaderColumnRenderer(item) }
    }

    return { renderHeader: null }
  })

  const filterOffColumns = initialColumns.map((item) => {
    if (item.field !== 'actions') {
      return { renderHeader: (params) => defaultHeaderColumnRenderer(item) }
    }

    return { renderHeader: null }
  })

  const handleChangeRowsPerPage = (newPageSize) => {
    setPageSize(newPageSize)
    setPage(0)
  }

  const handleSortModelChange = (model, details) => {
    setOrder(model[0] ? model[0].sort : null)
    setOrderBy(model[0] ? model[0].field : null)
    setSortModel(model)
  }

  const handleCellClick = (inputParams, inputEvent, inputDetails) => {
    // CELL IS CLICKED
    if (Number.isInteger(inputParams.id) && inputParams.field !== '__check__') {
      setSelectionModel([ inputParams.id ])
    }
    // CHECKBOX IS CLICKED
    else if (Number.isInteger(inputParams.id) && inputParams.field === '__check__') {
      if(selectionModel.length === 0) setSelectionModel([ inputParams.id ])
      else {
        let newSelectionModel = [...selectionModel]

        if(selectionModel.includes(inputParams.id)) newSelectionModel = newSelectionModel.filter(item => item !== inputParams.id)
        else {
          newSelectionModel.push(inputParams.id)
          newSelectionModel.sort()
        }

        setSelectionModel(newSelectionModel)
      }
    }
    // PARENT CHECKBOX IS CLICKED
    else if (!Number.isInteger(inputParams.id) && inputParams.field === '__check__') {
      const intersection = lodash.intersection([...selectionModel], inputParams.rowNode.children)
      
      // PARENT CHECKBOX IS UNCHEKED OR INDETERMINATE
      if (intersection.length < inputParams.rowNode.children.length) {
        // UNCHECK ALL ITEMS INSIDE A GROUP
        const newSelectionModel = lodash.union([...selectionModel], inputParams.rowNode.children)
        setSelectionModel(newSelectionModel)
      }
      // PARENT CHECKBOX IS CHEKED
      else if (intersection.length === inputParams.rowNode.children.length) {
        // CHECK ALL ITEMS INSIDE A GROUP
        const newSelectionModel = [...selectionModel].filter(item => !inputParams.rowNode.children.includes(item))
        setSelectionModel(newSelectionModel)
      }
    }
  }

  const handleColumnHeaderClick = (inputParams, inputEvent, inputDetails) => {
    // CHECKBOX IS CLICKED
    if(inputParams.field === '__check__') {
      if(selectionModel.length === 0) {
        const newSelectionModel = rows.map(item => item.id)
        setSelectionModel(newSelectionModel)
      }
      else setSelectionModel([]) 
    }
    // NON CHECKBOX IS CLICKED
    else return
  }

  useEffect(() => {
    if (isFilterOn) {
      setSelectedColumnList((current) => {
        return current.map((item, index) => {
          return {
            ...item,
            renderHeader: filterOnColumns[index].renderHeader
              ? filterOnColumns[index].renderHeader
              : null,
            sortable: false,
          }
        })
      })
    } else {
      setSelectedColumnList((current) => {
        return current.map((item, index) => {
          item.renderHeader = filterOffColumns[index].renderHeader
            ? filterOffColumns[index].renderHeader
            : null
          if (item.field !== 'actions') item.sortable = item.areFilterAndSortShown ? true : false
          return { ...item }
        })
      })
    }
  }, [isFilterOn])

  return (
    <Box className={classes.root}>
      <CustomDataGrid
        // BASE
        columns={[ getCheckboxColumn(dataGridApiRef), ...selectedColumnList ]}
        rows={rows}
        headerHeight={isFilterOn ? 72 : 49}
        // PAGINATION
        page={page}
        pageSize={pageSize}
        onPageSizeChange={handleChangeRowsPerPage}
        onPageChange={(page, details) => setPage(page)}
        paginationMode='server'
        rowCount={total}
        // SORT
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        apiRef={dataGridApiRef}
        // GROUP BY ROW
        treeData={selectedGroupBy.value ? true : false}
        getTreeDataPath={getTreeDataPath}
        groupingColDef={groupingColDef}
        defaultGroupingExpansionDepth={-1}
        // SELECTION
        selectionModel={selectionModel}
        onCellClick={(params, event, details) => handleCellClick(params, event, details)}
        onColumnHeaderClick={(params, event, details) => handleColumnHeaderClick(params, event, details)}
        // SETTINGS
        initialState={{
          pinnedColumns: {
            right: ['actions'],
            left: isCheckboxPinned ? [GRID_CHECKBOX_SELECTION_COL_DEF.field] : null,
          },
        }}
        {...otherProps}
      />
    </Box>
  )
}

DataGridTable.defaultProps = {
  initialColumns: [],
  rows: [],
  total: 0,
  page: 0,
  pageSize: 0,
  isFilterOn: false,
  selectedColumnList: [],
  selectedGroupBy: {},
  groupingColDef: {},
  selectionModel: [],
  isCheckboxPinned: false,
}

DataGridTable.propTypes = {
  initialColumns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  setOrder: PropTypes.func.isRequired,
  setOrderBy: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  isFilterOn: PropTypes.bool.isRequired,
  selectedColumnList: PropTypes.array.isRequired,
  selectedGroupBy: PropTypes.object.isRequired,
  getTreeDataPath: PropTypes.func.isRequired,
  groupingColDef: PropTypes.object.isRequired,
  selectionModel: PropTypes.array.isRequired,
  setSelectionModel: PropTypes.func.isRequired,
  isCheckboxPinned: PropTypes.bool.isRequired,
}

export default DataGridTable
