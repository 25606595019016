// MUIS
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

const PopupMarkerPoint = (props) => {
  const { item, classes } = props

  return (
    <Stack className={classes.popupWrapper}>
      <Stack className={classes.popupRoot}>
        <Typography variant='caption' className={classes.popupTitle}>
          Titik Referensi
        </Typography>
  
        <Typography variant='caption' className={classes.popupAddress}>
          {item?.address}
        </Typography>
  
        <Typography variant='caption' className={classes.popupCoordinate}>{parseFloat(item?.lat)}, {parseFloat(item?.lng)}</Typography>
  
        <Button
          variant='contained'
          className={classes.popupButton}
          href={`./tracking?lat=${parseFloat(item?.lat)}&lng=${parseFloat(item?.lng)}&address=${item.address}`}
        >BUAT PENUGASAN</Button>
      </Stack>
    </Stack>
  )
}

export default PopupMarkerPoint