// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  filterIcon: {
    marginRight: 4,
    fontSize: 16,
  },
  button: {
    fontWeight: 700,
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: alpha(theme.palette.text.secondary, 0.08),
    },
  },
  moreIcon: {
    marginLeft: 'auto !important',
  },
  clearFilterMenu: {
    '@media only screen and (max-height: 820px)': {
      '& .MuiPaper-root': {
        transform: 'translate(-20px, -25px) !important',
      },
    },
  },
  clearFilterMenuItemIcon: {
    marginRight: 8,
    '&.MuiListItemIcon-root': {
      minWidth: 'unset',
    },
  },
  filterListItem: {
    borderRadius: 4,
    height: 36,
  },
}))

export default useStyles
