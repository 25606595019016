// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconTrafficLight = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M8.98816 2.11768C7.82015 2.11768 6.87052 3.06731 6.87052 4.23532V19.4824C6.87052 20.6504 7.82015 21.6 8.98816 21.6H14.9176C16.0856 21.6 17.0352 20.6504 17.0352 19.4824V4.23532C17.0352 3.06731 16.0856 2.11768 14.9176 2.11768H8.98816ZM4.71316 4.23532C4.46335 4.23532 4.23173 4.33955 4.07787 4.5265C3.93063 4.70518 3.87438 4.93679 3.91904 5.16179L3.93228 5.24121C4.45507 6.5879 5.62971 7.82374 6.02346 8.21915V4.23532H4.71316ZM11.9529 4.23532C13.1225 4.23532 14.0705 5.1833 14.0705 6.35297C14.0705 7.52264 13.1225 8.47062 11.9529 8.47062C10.7832 8.47062 9.83522 7.52264 9.83522 6.35297C9.83522 5.1833 10.7832 4.23532 11.9529 4.23532ZM17.8823 4.23532V8.21915C18.276 7.82374 19.4507 6.58293 19.9735 5.24121C20.0628 5.01621 20.0281 4.77797 19.894 4.57944C19.7501 4.36768 19.5036 4.23532 19.2323 4.23532H17.8823ZM4.67346 9.7412C4.40213 9.7412 4.15563 9.87356 4.01169 10.0853C3.87768 10.2839 3.84294 10.5271 3.93228 10.7471C4.45507 12.0921 5.62971 13.3296 6.02346 13.725V9.7412H4.67346ZM11.9529 9.7412C13.1225 9.7412 14.0705 10.6892 14.0705 11.8589C14.0705 13.0285 13.1225 13.9765 11.9529 13.9765C10.7832 13.9765 9.83522 13.0285 9.83522 11.8589C9.83522 10.6892 10.7832 9.7412 11.9529 9.7412ZM17.8823 9.7412V13.725C18.276 13.3296 19.4507 12.0888 19.9735 10.7471C20.0628 10.5221 20.0281 10.2839 19.894 10.0853C19.7501 9.87356 19.5036 9.7412 19.2323 9.7412H17.8823ZM4.67346 15.2471C4.40213 15.2471 4.15563 15.3794 4.01169 15.5912C3.87768 15.7897 3.84294 16.0329 3.93228 16.253C4.45507 17.598 5.62971 18.8355 6.02346 19.2309V15.2471H4.67346ZM11.9529 15.2471C13.1225 15.2471 14.0705 16.1951 14.0705 17.3647C14.0705 18.5344 13.1225 19.4824 11.9529 19.4824C10.7832 19.4824 9.83522 18.5344 9.83522 17.3647C9.83522 16.1951 10.7832 15.2471 11.9529 15.2471ZM17.8823 15.2471V19.2309C18.276 18.8355 19.4507 17.5947 19.9735 16.253C20.0628 16.028 20.0281 15.7897 19.894 15.5912C19.7501 15.3794 19.5036 15.2471 19.2323 15.2471H17.8823Z'/>
    </SvgIcon>
  )
}

export default IconTrafficLight