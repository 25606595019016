// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: 20,
  },
  inputLabel: {
    left: 0,
    marginLeft: -12,
    fontWeight: '600 !important',
    fontSize: 18,
    color: theme.palette.text.primary,
  },
  input: {
    height: 40,
    position: 'relative',
    'label + &': {
      marginTop: 20,
    },
  },
  dialogActions: {
    padding: 16,
  },
  actionButton: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  actionButtonCancel: {
    marginLeft: 'auto',
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: alpha(theme.palette.text.secondary, 0.04),
    },
  },
}))

export default useStyles