import { useState, useRef } from 'react'

// ASSETS
import PictureBrokenVideo from 'assets/images/pictures/broken-video.svg'

// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { alpha } from '@mui/material/styles'

// MUI ICONS
import IconPauseCircleFilledOutlined from '@mui/icons-material/PauseCircleFilledOutlined'
import IconPlayCircleFilledOutlined from '@mui/icons-material/PlayCircleFilledOutlined'

// STYLES
import useStyles from './carouselMediasUseStyles'

// UTILITIES
import { convertSecondToHMS } from 'utilities'

// VIDEO THUMBNAIL
import VideoThumbnail from 'react-video-thumbnail'

const CarouselMediaVideo = (props) => {
  const { 
    carouselItem, 
    variantObject,
    variant,
  } = props

  const classes = useStyles()

  const videoRef = useRef()

  const [ source, setSource ] = useState(carouselItem.mediaUrl)
  const [ isPlaying, setIsPlaying ] = useState(false)
  const [ duration, setDuration ] = useState(0)
  const [ thumbnailImage, setThumbnailImage ] = useState(null)

  const handleButtonClick = (event, command) => {
    event.stopPropagation()

    if (command === 'play') {
      setIsPlaying(true)
      videoRef.current.play()
    }
    else if (command === 'pause') {
      setIsPlaying(false)
      videoRef.current.pause()
    }
  }

  const handleLoadMetaData = () => {
    if (videoRef?.current) setDuration(videoRef.current.duration)
  }

  return (
    <Stack
      position='relative'
      height='100%'
      width='100%'
      justifyContent='center'
      alignItems='center'
    >
      {/* ACTION BUTTON */}
      {source && (isPlaying ? (
        <IconPauseCircleFilledOutlined
          onClick={event => handleButtonClick(event, 'pause')}
          className={`${classes.carouselItemButton} ${variantObject[variant].playButtonClassName}`}
        />
      ) : (
        <IconPlayCircleFilledOutlined
          onClick={event => handleButtonClick(event, 'play')}
          className={`${classes.carouselItemButton} ${variantObject[variant].playButtonClassName}`}
        />
      ))}

      {/* INFORMATION */}
      <Stack
        position='absolute'
        bottom={0}
        left={0}
        right={0}
        backgroundColor={alpha(colors.ebonyClay, 0.5)}
        padding={variant === 'small' ? '8px' : '16px'}
        zIndex={4}
      >
        {/* FILE NAME */}
        <Typography
          variant='body2'
          color='white'
          noWrap
        >
          Video {carouselItem.fileName}
        </Typography>

        {/* DURATION */}
        <Typography
          variant='caption'
          color='white'
          noWrap
        >
          {convertSecondToHMS(duration)}
        </Typography>
      </Stack>

      {/* BLURRED BACKGROUND */}
      <Box
        key={thumbnailImage}
        sx={{ backgroundImage: `url(${thumbnailImage})` }}
        className={classes.carouselBlurredBackground}
      />

      {/* VIDEO THUMBNAIL GENERATOR */}
      {videoRef?.current &&
      <VideoThumbnail
        videoUrl={carouselItem.mediaUrl}
        thumbnailHandler={(thumbnail) => setThumbnailImage(thumbnail)}
        width={videoRef.current.videoWidth}
        height={videoRef.current.videoHeight}
        className={classes.displayNone}
      />}

      {/* VIDEO */}
      <Box
        ref={videoRef}
        component={source ? 'video' : 'img'}
        src={source ? source : PictureBrokenVideo}
        autoPlay={false}
        controls={false}
        alt=''
        className={classes.carouselItemVisual}
        onLoadedMetadata={handleLoadMetaData}
        onEnded={() => setIsPlaying(false)}
        onError={() => setSource(PictureBrokenVideo)}
      />
    </Stack>
  )
}

export default CarouselMediaVideo