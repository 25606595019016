// COMPONENTS
import RenderValuePhoto from './RenderValuePhoto'

// MUIS
import Avatar from '@mui/material/Avatar'
import Chip from '@mui/material/Chip'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { alpha } from '@mui/material/styles'

// MUI ICONS
import IconAlarm from '@mui/icons-material/Alarm'
import IconBedtime from '@mui/icons-material/Bedtime'
import IconLightMode from '@mui/icons-material/LightMode'
import IconPhoneAndroid from '@mui/icons-material/PhoneAndroid'
import IconTimelapse from '@mui/icons-material/Timelapse'

// PATH ICONS
import IconAutoGraph from 'assets/images/pathIcons/IconAutoGraph'
import IconDayAndNight from 'assets/images/pathIcons/IconDayAndNight'
import IconDiversity from 'assets/images/pathIcons/IconDiversity'
import IconPoliceCar from 'assets/images/pathIcons/IconPoliceCar'
import IconPoliceMan from 'assets/images/pathIcons/IconPoliceMan'
import IconSprint from 'assets/images/pathIcons/IconSprint'

// STYLES
import useStyles from './detailTabInformationUseStyles'

// UTILITIES
import { 
  capitalizeEachWord, 
  dateFormat,
  getStringAvatar,
} from 'utilities'

const DetailTabInformation = (props) => {
  const { 
    rowItem,
    informationDetail, 
  } = props

  const classes = useStyles()
  
  const informationList = rowItem ? [
    {
      icon: IconDiversity,
      label: 'Jenis Kegiatan',
      reference: informationDetail?.activityTypeName,
      renderValue: (
        informationDetail?.activityTypeName && informationDetail?.activityType?.Icon &&
        <Chip
          icon={<informationDetail.activityType.Icon sx={{ color: `${informationDetail?.activityType?.activityTypeColor} !important` }}/>}
          label={informationDetail?.activityTypeName.toLowerCase()} 
          variant='outlined'
          size='small'
          className={classes.informationValueChip}
          sx={{ 
            color: informationDetail?.activityType?.activityTypeColor, 
            borderColor: informationDetail?.activityType?.activityTypeColor,
            backgroundColor: alpha(informationDetail?.activityType?.activityTypeColor, 0.08),
          }}
        />
      ),
    },
    {
      icon: IconSprint,
      label: 'Sprin',
      reference: informationDetail?.workOrderNumber,
      renderValue: (
        <Chip
          label={informationDetail?.workOrderNumber} 
          variant='outlined'
          className={classes.informationValueWorkOrderNumber}
        />
      ),
    },
    {
      icon: IconPhoneAndroid,
      label: 'Perangkat',
      reference: informationDetail?.deviceName,
      renderValue: (
        <Chip
          icon={<IconPhoneAndroid className={classes.informationValueDeviceIcon}/>}
          label={informationDetail?.deviceName} 
          variant='filled'
          className={classes.informationValueDevice}
        />
      ),
    },
    {
      icon: IconPoliceCar,
      label: 'Metode Patroli',
      reference: informationDetail?.vehicleTypeName,
      renderValue: (
        informationDetail?.vehicleTypeName && informationDetail?.vehicleType?.Icon &&
        <Chip
          icon={<informationDetail.vehicleType.Icon sx={{ color: `${informationDetail?.vehicleType?.vehicleTypeColor} !important` }}/>}
          label={informationDetail?.vehicleTypeName ? capitalizeEachWord(informationDetail?.vehicleTypeName) : '-'}
          variant='outlined'
          className={classes.informationValueChip}
          size='small'
        />
      ),
    },
    {
      icon: IconPoliceCar,
      label: 'Kendaraan',
      reference: informationDetail?.vehicleNames,
      renderValue: (
        informationDetail?.vehicleNames && informationDetail?.vehicleType?.Icon &&
        <Chip
          icon={<informationDetail.vehicleType.Icon sx={{ color: `${informationDetail?.vehicleType?.vehicleTypeColor} !important` }}/>}
          label={informationDetail?.vehicleNames ? capitalizeEachWord(informationDetail?.vehicleNames) : '-'}
          variant='outlined'
          className={classes.informationValueChip}
          size='small'
        />
      ),
    },
    {
      icon: IconPoliceMan,
      label: 'Petugas',
      reference: informationDetail?.officerList,
      renderValue: (
        informationDetail?.officerList.length > 0 ? (
          <Stack 
            spacing='4px'
            marginTop='4px'
          >
            {informationDetail?.officerList?.map((item, index) => (
              <Chip
                key={index}
                avatar={
                  <Avatar className={classes.informationValueOfficerAvatar}>
                    {getStringAvatar(item)}
                  </Avatar>
                }
                label={capitalizeEachWord(item)} 
                variant='outlined'
                className={classes.informationValueOfficer}
              />
            ))}
          </Stack>
        ) : 'Tidak ada data'
      ),
    },
    {
      icon: IconTimelapse,
      label: 'Durasi',
      reference: informationDetail?.duration,
      renderValue: (
        <Typography variant='body2'>
          {informationDetail?.duration}
        </Typography>
      ),
    },
    {
      icon: IconDayAndNight,
      label: 'Shift Patroli',
      reference: informationDetail?.shiftObject,
      renderValue: (
        informationDetail?.shiftObject ? (
          <Chip
            icon={informationDetail?.shiftObject?.label === 'Day' ? (
              <IconLightMode 
                className={classes.informationValueShiftIcon}
                sx={{ color: `${informationDetail.shiftObject.color} !important` }}
              />
            ) : (
              <IconBedtime 
                className={classes.informationValueShiftIcon}
                sx={{ color: `${informationDetail.shiftObject.color} !important` }}
              />
            )}
            label={informationDetail.shiftObject.label === 'Day' ? 'Pagi' : 'Malam'} 
            variant='outlined'
            className={classes.informationValueShift}
          /> 
        ) : 'Tidak ada data'
      ),
    },
    {
      icon: IconAlarm,
      label: 'Clock In',
      reference: informationDetail?.clockedInTime,
      renderValue: (
        <RenderValuePhoto
          photoUrl={informationDetail?.clockedInUrl}
          dateAndTime={dateFormat(informationDetail?.clockedInTime)}
          location={informationDetail?.locationStart}
          isWithBorder={false}
        />
      ),
    },
    {
      icon: IconAlarm,
      label: 'Clock Out',
      reference: informationDetail?.clockedOutTime,
      renderValue: (
        <RenderValuePhoto
          photoUrl={informationDetail?.clockedOutUrl}
          dateAndTime={dateFormat(informationDetail?.clockedOutTime)}
          location={informationDetail?.locationEnd}
          isWithBorder={false}
        />
      ),
    },
    {
      icon: IconAutoGraph,
      label: 'Signature',
      reference: informationDetail?.signatureUrl,
      renderValue: (
        <RenderValuePhoto
          photoUrl={informationDetail?.signatureUrl}
          dateAndTime=''
          location=''
          isWithBorder={true}
        />
      ),
    },
  ] : []

  return (
    <List className={classes.root}>
      {informationList.length > 0 &&
      informationList.map((item, index) => (
        <ListItem
          key={index}
          disablePadding
          className={classes.informationListItem}
        >
          {/* ICON */}
          <ListItemIcon>
            <item.icon/>
          </ListItemIcon>

          {/* TEXT */}
          <ListItemText
            primary={item.label}
            secondary={item.reference ? item.renderValue : 'Tidak ada data'}
          />
        </ListItem>
      ))}
    </List>
  )
}

export default DetailTabInformation