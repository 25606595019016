// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconFiretruck = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M17.1682 2.00314C17.1021 2.01816 17.036 2.0422 16.9759 2.07525L2.43273 8.85405C2.1653 8.98326 1.99703 9.25369 2.00004 9.55116V12.6281C2.00304 12.8865 2.13525 13.1269 2.3516 13.2681C2.57095 13.4093 2.84438 13.4303 3.08176 13.3252L4.30771 12.7483V14.3107H2.00004V18.9261H4.37983C4.53307 17.619 5.69292 16.6184 7 16.6184C8.30709 16.6184 9.46693 17.619 9.62018 18.9261H15.149C15.3022 17.619 16.4621 16.6184 17.7692 16.6184C19.1544 16.6184 20.2361 17.619 20.3893 18.9261H21.2307C21.6153 18.9261 21.9999 18.6196 21.9999 18.1569V14.5511C21.9999 14.3979 22.0059 14.2236 21.9278 14.0704L20.8461 10.7771C20.6147 10.1611 20.0708 9.69539 19.3797 9.69539H12C11.6154 9.69539 11.2307 10.08 11.2307 10.4646V14.3107H7.38462V11.33L20.0047 5.46465C20.2421 5.35648 20.4074 5.13713 20.4464 4.87872C20.4855 4.62331 20.3923 4.3649 20.197 4.19062L17.7932 2.17141C17.6369 2.04521 17.4386 1.98511 17.2403 2.00314C17.2163 2.00314 17.1922 2.00314 17.1682 2.00314ZM17.1682 3.68582L18.2499 4.59927L3.53849 11.4261V10.056L17.1682 3.68582ZM12.7692 11.2338H15.8461V14.3107H12.7692V11.2338ZM17.3845 11.2338H19.548C19.7794 11.2338 19.9266 11.3931 20.0047 11.5463L20.8461 14.3107H17.3845V11.2338ZM7 17.3876C5.93931 17.3876 5.07694 18.25 5.07694 19.3107C5.07694 20.3714 5.93931 21.2338 7 21.2338C8.06069 21.2338 8.92307 20.3714 8.92307 19.3107C8.92307 18.25 8.06069 17.3876 7 17.3876ZM17.7692 17.3876C16.7085 17.3876 15.8461 18.25 15.8461 19.3107C15.8461 20.3714 16.7085 21.2338 17.7692 21.2338C18.8298 21.2338 19.6922 20.3714 19.6922 19.3107C19.6922 18.25 18.8298 17.3876 17.7692 17.3876Z' />
    </SvgIcon>
  )
}

export default IconFiretruck