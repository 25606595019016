import React, { useEffect, useState } from 'react'

// COMPONENTS
import DialogAddOrEdit from 'components/DialogAddOrEditNew/DialogAddOrEdit'
import CustomInput from 'components/Customs/CustomInput'
import CustomInputLabel from 'components/Customs/CustomInputLabel'
import SimpleBackdrop from 'components/Partials/SimpleBackdrop'

// MUIS
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'

// ICONS
import TextFormatIcon from '@mui/icons-material/TextFormat'

// STYLES
import useStyles from './dialogEditLabelUseStyles'

// CONTEXT
import { useDispatch } from 'react-redux'
import { showAlert } from 'store/MainReducer'

// SERVICES
import { putEditDevice } from 'services/DeviceService'

const DialogEditLabel = (props) => {
  const {
    dialogEditLabel,
    setDialogEditLabel,
    reloadData,
    pageRef,
  } = props

  const dispatch = useDispatch()
  const classes = useStyles()

  /* INIT PARAMS */
  let initialDialogParams = {
    label: '',
  }

  // STATE
  const [dialogParams, setDialogParams] = useState(initialDialogParams)
  const [isLoading, setIsLoading] = useState(false)

  /* HANDLE INPUT */
  const handleDialogParamsChange = (event) => {
    setDialogParams({
      ...dialogParams,
      [event.target.name]: event.target.value,
    })
  }

  /* HANDLE BUTTON SAVE */
  const onSaveButtonIsClicked = async () => {
    setIsLoading(true)

    await putEditDevice(dialogEditLabel.row.id, {
      label: dialogParams.label
    })

    dispatch(showAlert({ message: 'Sukses Mengubah Aplikasi Seluler' }))

    setIsLoading(false)
    setDialogEditLabel(null)
    reloadData()
  }

  /* SIDE EFFECT SET DEFAULT LABEL */
  useEffect(() => {
    if(Boolean(dialogEditLabel)) setDialogParams({ label: dialogEditLabel.row.label })
  }, [dialogEditLabel])

  return (
    <>
      {/* DIALOG ADD OR EDIT */}
      <DialogAddOrEdit
        open={Boolean(dialogEditLabel)}
        title='Ubah Aplikasi Seluler'
        onCloseIconClick={() => setDialogEditLabel(false)}
        containerRef={pageRef}
        contents={[
          <>
            {/* MAIN SECTION TEXT */}
            <Typography variant='h6' className={classes.sectionText}>
              Informasi Utama
            </Typography>
        
            {/* LABEL NAME */}
            <Box className={classes.iconAndFormControlContainer}>
              <TextFormatIcon className={classes.iconFormControl} />
              <FormControl variant='standard' className={classes.formControl}>
                <CustomInputLabel>Label</CustomInputLabel>
                <CustomInput
                  type='text'
                  name='label'
                  value={dialogParams.label}
                  onChange={handleDialogParamsChange}
                />
              </FormControl>
            </Box>
          </>
        ]}
        onCancelButtonClick={() => setDialogEditLabel(false)}
        onSaveButtonClick={onSaveButtonIsClicked}
      />

      {/* LOADING */}
      <SimpleBackdrop isLoading={isLoading} />
    </>
  )
}

export default DialogEditLabel