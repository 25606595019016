// ASSETS
import PictureLiveItem from 'assets/images/pictures/live-item.png'

// COLORS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  liveGrid: {
    padding: 12,
    overflowY: 'auto',
    flex: 1,
  },
  liveItem: {
    padding: 12,
  },
  liveItemPreview: {
    borderRadius: 4,
    height: 192,
    position: 'relative',
    backgroundImage: `url(${PictureLiveItem})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  liveItemBackdrop: {
    position: 'absolute',
    backgroundColor: alpha('#000000', 0.2),
  },
  liveItemJoinButton: {
    position: 'absolute',
    zIndex: 2,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    backgroundColor: alpha('#000000', 0.5),
    '&:hover': {
      backgroundColor: alpha('#000000', 0.4),
    },
  },
  liveItemLiveText: {
    width: 'fit-content',
    backgroundColor: colors.scarlet,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 8px',
    borderRadius: 2,
    position: 'absolute',
    bottom: 12,
    right: 12,
    '&::before': {
      content: '""',
      width: 8,
      height: 8,
      backgroundColor: 'white !important',
      borderRadius: '50%',
      marginRight: 8,
    },
  },
  liveItemProfile: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
  },
  liveItemAvatar: {
    width: 32,
    height: 32,
    marginRight: 12,
    fontSize: 14,
  },
}))

export default useStyles
