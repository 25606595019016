// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconFilterAttach = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M15.0001 17H18.0001V14H20.0001V17H23.0001V19H20.0001V22H18.0001V19H15.0001V17ZM13.0001 19.88C13.0401 20.18 12.9401 20.5 12.7201 20.71C12.3201 21.1 11.6901 21.1 11.3001 20.71L7.29013 16.7C7.18107 16.5934 7.09814 16.463 7.04783 16.319C6.99752 16.175 6.9812 16.0213 7.00013 15.87V10.75L2.21013 4.62C2.04774 4.41153 1.97446 4.14726 2.00632 3.88493C2.03817 3.6226 2.17257 3.38355 2.38013 3.22C2.57013 3.08 2.78013 3 3.00013 3H17.0001C17.2201 3 17.4301 3.08 17.6201 3.22C17.8277 3.38355 17.9621 3.6226 17.9939 3.88493C18.0258 4.14726 17.9525 4.41153 17.7901 4.62L13.0001 10.75V19.88ZM5.04013 5L9.00013 10.07V15.58L11.0001 17.58V10.05L14.9601 5H5.04013Z'/>
    </SvgIcon>
  )
}

export default IconFilterAttach