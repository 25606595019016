import React, { useEffect, useRef, useState } from 'react'

// CONSTANTS
import { panelsInfoStatic } from 'pages/Tracking/PanelUnifiedMapInformation/panelInformationIntegratedConstant'

// STYLES
import useStyles from './showMenuLeafletUseStyles'

// MUIS
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import ListItemButton from '@mui/material/ListItemButton'

// MUI ICONS
import IconClose from '@mui/icons-material/Close'
import IconDirectionsCar from '@mui/icons-material/DirectionsCar'

// CONTEXT
import { useLeafletContext } from '@react-leaflet/core'

// SERVICES
import { getEvidence } from 'services/EvidenceService'

// UTILS
import { getColorMarkerByTypeName } from 'utilities/colors'
import { generateMediaUrlBySize } from 'utilities/media'

const ShowMenuLeaflet = (props) => {
  const {
    isShowMenu,
    setIsShowMenu,
    inPage,
    setIsPanelLeftShown,
    setObjectDetail,
    setIsObjectDetailPanelShown,
    map
  } = props

  const classes = useStyles()

  // REFS
  const mapContext = useRef(useLeafletContext())
  const mapSize = useRef()
  const menuWrapRef = useRef(null)

  // STATES
  const [point, setPoint] = useState({
    x: 0,
    y: 0
  })
  const [listData, setListData] = useState([])

  // HANDLE ITEM PANEL MARKER PIE CLICKED
  const handleEvidenceListClick = (inputData) => {
    const fetchDetail = async () => {
      if (inPage === 'TRACKING' && inputData?.status === 'DISPATCHES') {
        setIsPanelLeftShown({
          ...panelsInfoStatic.panelDispatchDetail,
          data: inputData.data
        })
      } else if(inPage === 'TRACKING' && inputData?.status !== 'OBJECT') {
        const response = await getEvidence(inputData.id)
        // HANDLE CLICK EVIDENCE FOR PAGE TRACKING
        setIsPanelLeftShown({
          ...panelsInfoStatic.panelEvidenceDetail,
          data: {
            ...response,
            medias: response?.medias?.map(mediaItem => {
              return generateMediaUrlBySize(mediaItem)
            })
          },
        })
        map?.flyTo({ lat: inputData.lat, lng: inputData.lng }, 18)
      } else if(inPage === 'TRACKING' && inputData?.status === 'OBJECT') {
        // HANDLE CLICK OBJECT FOR PAGE TRACKING
        setObjectDetail(inputData.dataDetail)
        map?.flyTo(inputData.dataDetail.state.gps.location, 18)
        setIsObjectDetailPanelShown(panelsInfoStatic?.panelDeviceDetail)
      }
    }

    fetchDetail()
  }

  // GET PANEL ITEM TEXT
  const getItemPanelText = (item) => {
    if(inPage === 'TRACKING') return item.text || '[Tidak Ada Label]'
  }

  // SET LEFT POSITION PANEL FROM PIE MARKER
  const setLeftPositionPanel = () => {
    if(mapContext.current.map.getZoom() <= 10) return 16
    else return 16
  }

  useEffect(() => {
    if(mapContext.current.map && Boolean(isShowMenu)) {
      mapSize.current = mapContext.current.map.getSize()

      // DISABLED SCROLL ZOOM
      mapContext.current.map.scrollWheelZoom.disable()

      // DISABLED PANEL WHEN MAP EVENT ON
      mapContext.current.map.on('click dragstart zoom', () => {
        setIsShowMenu(false)
      })

      mapContext.current.map.on('resize', (event) => {
        mapSize.current = event.newSize
      }, [])

      // CHECK MENU HEIGHT
      const menuPointYisOverFlow = (pointY, menuWrapHeight, mapSize) => {
        if (pointY > (mapSize.y - menuWrapHeight)) return pointY - menuWrapHeight
        else return pointY
      }

      const pointMarker = isShowMenu.mouseEvent.containerPoint
      const menuWrapWidth = menuWrapRef.current ? Number(menuWrapRef.current.offsetWidth) : 0
      const menuWrapHeight = menuWrapRef.current ? Number(menuWrapRef.current.offsetHeight) : 0

      // CHECK IF CLICK POSITION OVERFLOW X AND Y
      if(mapSize.current && pointMarker.x > (mapSize.current.x - menuWrapWidth)) {
        // CHECK MAX POINT X
        const calculationX = pointMarker.x === mapSize.current.x
          ? (pointMarker.x - menuWrapWidth) - 20
          : pointMarker.x - menuWrapWidth

        setPoint({
          y: menuPointYisOverFlow(pointMarker.y, menuWrapHeight, mapSize.current),
          x: calculationX
        })
      } else {
        mapSize.current && setPoint({
          y: menuPointYisOverFlow(pointMarker.y, menuWrapHeight, mapSize.current),
          x: pointMarker.x
        })
      }

      if(inPage === 'TRACKING') setListData(isShowMenu.dataCluster
        .sort((a, b) => a.status.localeCompare(b.status)))
    } else {
      // ENABLE SCROLL ZOOM
      mapContext.current.map.scrollWheelZoom.enable()
      setListData([])
    }
  }, [isShowMenu])

  // MAP CONTEXT NOT FOUND
  if(!mapContext.current) return null

  return (
    <Box
      ref={menuWrapRef}
      sx={{
        display: Boolean(isShowMenu) ? 'block' : 'none',
        position: 'absolute',
        zIndex: 9999,
        top: `${point.y}px`,
        left: `${point.x + setLeftPositionPanel()}px`,
      }}
    >
      <List className={`${classes.listPanelPie} zoom`}>
        <Stack>
          <Stack direction='row' flexWrap='nowrap' alignItems='center'>
            {isShowMenu?.mouseEvent?.color && <Box marginRight='4px' borderRadius='100%' width='16px' height='16px' backgroundColor={isShowMenu?.mouseEvent?.color}></Box>}
            <Typography variant='caption' fontWeight={600}>{isShowMenu?.title || 'Menu'} ({listData?.length || 0})</Typography>
          </Stack>

          <IconButton size='small' onClick={() => setIsShowMenu(null)} className={classes.btnCloseMenu}>
            <IconClose fontSize='small' className={classes.iconClose} />
          </IconButton>
        </Stack>

        <Divider className={classes.dividerTitle}/>

        {(inPage === 'TRACKING' && listData.length) && listData.map((item, index) => (
          <ListItemButton
            disableRipple
            key={index}
            className={classes.itemPanelPie}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleEvidenceListClick(item)
            }}
          >
            <Stack
              className={classes.boxIconWrap}
              sx={{
                backgroundColor: isShowMenu?.mouseEvent?.color ? isShowMenu?.mouseEvent?.color : getColorMarkerByTypeName(item.status)
              }}
            >
              <IconDirectionsCar className={classes.boxIcon} />
            </Stack>

            <Typography variant='body2' className={classes.titleItemPie}>
              {getItemPanelText(item)}
            </Typography>
          </ListItemButton>
        ))}
      </List>
    </Box>
  )
}

export default ShowMenuLeaflet