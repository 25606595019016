import { stringify } from 'query-string'

// UTILITIES
import { http } from 'utilities/http'

export const getOfficersList = async (bodyParams) => {
  try {
    const { data } = await http.get('/officers', bodyParams)

    const newRows = data.rows.map((item) => {
      return {
        ...item,
        id: item.officerNo,
      }
    })

    return {
      ...data,
      rows: newRows,
    }
  } 
  catch (error) {
    return {
      status: 'error', 
      rows: [],
    }
  }
}

export const getSearchOfficerList = async (params, bodyParams) => {
  try {
    const { data } = await http.post(`/officers/search?${stringify(params)}`, bodyParams)
    
    const newRows = data.rows.map((item) => {
      return {
        ...item,
        id: item.officerNo,
      }
    })

    return {
      ...data,
      rows: newRows,
    }
  } 
  catch (error) {
    return {
      status: 'error', 
      rows: [],
    }
  }
}

export const getOfficerDepartmentList = async (params) => {
  try {
    const { data } = await http.get('/officers/departments/list', params)
    return data
  } 
  catch (error) {
    return {
      status: 'error', 
      rows: [],
    }
  }
}

export const postCreateNewOfficer = async (bodyParams) => {
  try {
    return await http.post('/officers', bodyParams)
  } 
  catch (e) {
    return {
      status: 'error', 
      rows: [],
    }
  }
}

export const putEditOfficer = async (id, bodyParams) => {
  try {
    return await http.put(`/officers/${id}`, bodyParams)
  } 
  catch (e) {
    return {
      status: 'error', 
      rows: [],
    }
  }
}

export const deleteOfficer = async (id) => {
  try {
    return await http.delete(`/officers/${id}`)
  } 
  catch (e) {
    return {
      status: 'error', 
      rows: [],
    }
  }
}