import React, { useEffect, useState } from 'react'
import moment from 'moment'

// STYLES
import useStyles from './videoPlaybackUseStyles'

// MUIS
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'

// UTILS
import { isAccessMenu } from 'utilities/auth'

// COMPONENTS
import ContentTabs from 'components/ContentTabs/ContentTabs'
import DatePickerSort from './DatePickerSort'
import DateRangeAndTimePicker from 'components/DateRangeAndTimePicker/DateRangeAndTimePicker'
import VideoCallItem from 'components/VideoCallItem/VideoCallItem'

// SERVICES
import { getListPlayback } from 'services/playbackService'


const VideoPlayback = () => {
  const classes = useStyles()

  const tabItemList = [ 
    {
      label: 'Siaran Langsung',
      value: 'LIVE_STREAMING',
      access: isAccessMenu('Live')
    },
    {
      label: 'Bantuan Agen',
      value: 'AGENT_HELP',
      access: isAccessMenu('Agent Help')
    },
    {
      label: 'Rapat',
      value: 'VIDEO_CONFERENCE',
      access: true
    }
  ]

  /* STATE */
  const [listPlayback, setListPlayback] = useState([])
  const [selectedTab, setSelectedTab] = useState(tabItemList[0].value)
  const [isPlaybackDateRangeShown, setIsPlaybackDateRangeOpen] = useState(false)
  const [playbackDateRange, setPlaybackDataRange] = useState([
    moment().startOf('week').toDate(), moment().endOf('week').toDate()
  ])

  /* HANDLE SELECTED RANGE DATE */
  const handleSelectDateRangeButtonClick = async (inputNewDateRange) => {
    setPlaybackDataRange(inputNewDateRange)
    setIsPlaybackDateRangeOpen(false)
  }

  /* FETCH LIST */
  const fetchListPlayback = async () => {
    const gets = await getListPlayback({
      from: moment(playbackDateRange[0]).format('YYYY-MM-DD HH:mm:ss'),
      to: moment(playbackDateRange[1]).format('YYYY-MM-DD HH:mm:ss'),
      type: selectedTab
    }, {
      page: 0,
      size: 1000,
    })
    setListPlayback(gets.rows)
  }

  useEffect(() => {
    if(playbackDateRange.length == 2 && selectedTab) {
      fetchListPlayback()
    }
  }, [selectedTab, playbackDateRange])

  return (
    <>
      <Stack className={classes.stackPlayback}>
        {/* TABS */}
        <Box className={classes.tabsWrapper}>
          <ContentTabs
            tabItemList={tabItemList.filter(item => item.access)}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            isExtraComponentAvailable={true}
            extraComponent={<DatePickerSort onClick={setIsPlaybackDateRangeOpen} dateRangeValue={playbackDateRange} />}
            disabledPaddingTabs
            classNameTab={classes.customTab}
            tabsHeight={59}
          />
        </Box>


        {/* LIVE LIST */}
        <Box className={classes.liveList}>
          <Grid
            container
            className={classes.liveGrid}
            spacing={2}
          >
            {/* ITEM */}
            {listPlayback.filter(item => item.status !== 'RECORDING').map((item, index) => (
              <Grid item xs={3} key={index}>
                <VideoCallItem
                  type='playback'
                  data={{
                    deviceLabel: item.device.label,
                    date: moment(item.createdOn).format('DD/MM/YYYY hh:mm A')
                  }}
                  onJoinButtonClick={() => window.open(item.media.mediaUrl, '_blank')}
                />
              </Grid>
            )
            )}
          </Grid>
        </Box>
      </Stack>

      {/* DIALOG DATE RANGE PICKER */}
      <Dialog
        open={isPlaybackDateRangeShown}
        onClose={() => setIsPlaybackDateRangeOpen(false)} 
        className={classes.dateRangeAndTimePickerDialog}
      >
        <DateRangeAndTimePicker
          value={playbackDateRange}
          handleSelectButtonClick={handleSelectDateRangeButtonClick}
          handleCancelButtonClick={() => setIsPlaybackDateRangeOpen(false)}
        />
      </Dialog>
    </>
  )
}

export default VideoPlayback