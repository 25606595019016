import { useState, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'

// CONTEXTS
import { AuthLayoutContext } from 'contexts/AuthLayoutContext'

// MUIS
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Alert } from '@mui/material'
import { InputAdornment } from '@mui/material'
import { Lock, Password, RemoveRedEye, VisibilityOff } from '@mui/icons-material'
import { IconButton } from '@mui/material'

// ASSETS
import checklistIcon from 'assets/images/icons/checklist.svg'

// OTHERS
import { useFormik } from 'formik'
import { object, string } from 'yup'

// SERVICES
import { updateResetPassword } from 'services/UserService'

// UTILITIES
import { getCompanyLogo, getCompanyTitle } from 'utilities'

const theme = createTheme({
  palette: {
    primary: {
      main: '#EF6C00',
      darker: '#ed7512',
    },
    default: {
      main: '#FFF',
      darker: '#FFF',
    },
    action: {
      disabledBackground: 'rgb(239, 108, 0, 0.4)',
      disabled: '#FFFFFF',
    },
    typography: {
      fontFamily: '"Open Sans", "Helvetica Neue", "Helvetica", Arial, sans-serif',
    },
  },
  components: {
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: 14,
        },
      },
    },
    MuiInput: {
      defaultProps: {
        sx: {
          fontSize: 14,
        },
      },
    },
  },
})

export default function ForgotPassword() {
  const history = useHistory()
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
  const getQuery = useParams()

  const { setIsLoading } = useContext(AuthLayoutContext)

  const { handleSubmit, errors, handleChange, values } = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: object({
      password: string().required('Wajib mengisi kata sandi baru'),
      confirmPassword: string().required('Wajib mengisi konfirmasi kata sandi'),
    }),
    onSubmit: async (values) => {
      setIsLoading(true)

      const result = await updateResetPassword({
        confirmPassword: values.confirmPassword,
        password: values.password,
        token: getQuery.resetPasswordToken.split('=')[1]
      })
      
      if (result?.status === 'error') {
        setIsError(true)
      } else {
        setIsError(false)
        setIsSuccess(true)
      }

      setIsLoading(false)
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <Container
        component='main'
        maxWidth='sm'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          minHeight: '100vh',
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '1px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '30px',
            }}
          >
            <img
              src={getCompanyLogo('dark')}
              alt='logo'
              style={{ width: 57 }}
            />
            <Typography
              component='h1'
              variant='h5'
              sx={{
                marginTop: '13px',
                marginLeft: '10px',
                color: '#3F4254',
                fontWeight: 600,
              }}
            >
              {getCompanyTitle()}
            </Typography>
          </Box>

          {isError && (
            <Alert
              severity='error'
              icon={false}
              sx={{ marginBottom: '1rem', width: '100%' }}
            >
              Tolong periksa kembali password atau token anda.
            </Alert>
          )}
          {
            isSuccess ? (
              <Box style={{
                borderRadius: '4px',
                boxShadow:
                '-1px 10px 10px rgba(0, 0, 0, 0.05), 1px 12px 30px rgba(0, 0, 0, 0.1), 0px 2px 5px rgba(0, 0, 0, 0.1)',
                padding: '30px',
                width: '80%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
                <img src={checklistIcon} width='100px' height='100px' />
                <Typography sx={{ fontWeight: 600, fontSize: 20, lineHeight: '27.24px', textAlign: 'center', margin: '24px 0' }}>
                  Kata sandi Anda telah<br/>berhasil diubah
                </Typography>
                <Button
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3, mb: 2, textTransform: 'none', height: '45px' }}
                  onClick={() => history.push('/login')}
                >
                  Kembali Ke Halaman Masuk
                </Button>
              </Box>
            ) : (
              <form
                onSubmit={handleSubmit}
                style={{
                  borderRadius: '4px',
                  boxShadow:
                  '-1px 10px 10px rgba(0, 0, 0, 0.05), 1px 12px 30px rgba(0, 0, 0, 0.1), 0px 2px 5px rgba(0, 0, 0, 0.1)',
                  padding: '30px',
                  width: '80%',
                }}
              >
                <Typography
                  component='h5'
                  variant='h5'
                  sx={{
                    marginBottom: '10px',
                    textAlign: 'center',
                    color: theme.palette.text.primary,
                    fontWeight: '600',
                    fontSize: '20px',
                    lineHeight: '27.24px',
                  }}
                >
                  Atur Ulang Kata Sandi
                </Typography>
                <Typography
                  component='p'
                  variant='p'
                  sx={{
                    marginBottom: '25px',
                    textAlign: 'center',
                    color: theme.palette.text.secondary,
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '19.07px',
                  }}
                >
                  Silahkan masukkan kata sandi baru Anda pada formulir dibawah ini.
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <Lock
                    sx={{
                      color: '#0000008A',
                      mr: 2,
                      mt: !errors.password ? 3 : 0,
                      alignSelf: errors.password ? 'center' : '',
                    }}
                  />
                  <TextField
                    error={!!errors.password}
                    helperText={errors.password}
                    margin='normal'
                    required
                    fullWidth
                    name='password'
                    label='Kata Sandi Baru'
                    type={showPassword ? 'text' : 'password'}
                    id='password'
                    autoComplete='current-password'
                    onChange={handleChange}
                    variant='standard'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <VisibilityOff /> : <RemoveRedEye />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <Password
                    sx={{
                      color: '#0000008A',
                      mr: 2,
                      mt: !errors.confirmPassword ? 3 : 0,
                      alignSelf: errors.confirmPassword ? 'center' : '',
                    }}
                  />
                  <TextField
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                    margin='normal'
                    required
                    fullWidth
                    name='confirmPassword'
                    label='Konfirmasi Kata Sandi'
                    type={showPasswordConfirm ? 'text' : 'password'}
                    id='confirmPassword'
                    autoComplete='current-password'
                    onChange={handleChange}
                    variant='standard'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                          >
                            {showPasswordConfirm ? <VisibilityOff /> : <RemoveRedEye />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3, mb: 2, textTransform: 'none', height: '45px' }}
                  disabled={!values.password || !values.confirmPassword || values.password !== values.confirmPassword}
                >
                  Simpan Kata Sandi Baru
                </Button>
              </form>
            )
          }
        </Box>
      </Container>
    </ThemeProvider>
  )
}
