// MUI ICONS
import IconChatBubble from '@mui/icons-material/ChatBubble'
import IconGroupAdd from '@mui/icons-material/GroupAdd'

export const createMenuList = (onNewChatClick, onGroupChatClick) => [
  {
    icon: IconChatBubble,
    label: 'Percakapan Baru',
    onClick: onNewChatClick
  },
  {
    icon: IconGroupAdd,
    label: 'Obrolan Grup',
    onClick: onGroupChatClick
  }
]