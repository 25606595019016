// MUIS
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  headerRoot: {
    height: 44,
    marginBottom: 24,
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginLeft: 8,
    fontWeight: 600,
    cursor: 'pointer',
  },
}))

export default useStyles