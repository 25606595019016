// MUIS
import Tabs from '@mui/material/Tabs'
import { styled } from '@mui/material/styles'

const CustomTabs = styled(({ className, ...props }) => (
  <Tabs
    className={className} 
    {...props}
  />
))(({ theme }) => ({
  height: '100%',
  '& .MuiTabs-indicator': {
    height: 4,
  },
}))

export default CustomTabs