import React, { useEffect, useContext, useRef, useState } from 'react'
import { format } from 'date-fns'

// COMPONENTS
import Cluster from './Clusters/Clusters'
import DateRangeAndTimePicker from 'components/DateRangeAndTimePicker/DateRangeAndTimePicker'
import DialogAddDispatchEvidence from './DialogAddDispatchEvidence/DialogAddDispatchEvidence'
import GeofencesList from './GeofencesList/GeofencesList'
import MapChildrenZoom from 'components/PanelZoom/MapChildrenZoom'
// import PanelEvidenceDetail from './PanelEvidenceDetail/PanelEvidenceDetail'
import PanelRight from './PanelRight/PanelRight'
// import PanelObjectDetail from './PanelObjectDetail/PanelObjectDetail'
// import PanelObjects from './PanelObjects/PanelObjects'
import PanelToggle from './PanelToogle/PanelToogle'
import PanelUnifiedMapInformation from './PanelUnifiedMapInformation/PanelUnifiedMapInformation'
import PolylineHistory from './PolylineHistory/PolylineHistory'
// import PanelGeofences from './PanelGeofences/PanelGeofences'

// CONSTANTS
import { colors } from 'constants/colors'
import { panelsInfoStatic } from './PanelUnifiedMapInformation/panelInformationIntegratedConstant'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { PageTrackingContext } from 'contexts/PageTrackingContext'
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'

// LEAFLET
import { 
  FeatureGroup,
  MapContainer, 
  TileLayer, 
} from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw'

// MOMENT
import moment from 'moment'

// MUIS
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'

// SERVICES
import { advanceSearchEvidences } from 'services/EvidenceService'
import { getTagEvidencesList } from 'services/TagEvidencesService'
import { getTrackerList, postTrackerHistory, getStateHashMap } from 'services/TrackingServices'
import { getGroups, gets } from 'services/DeviceService'
import { advanceSearchDispatch } from 'services/DispatchService'

// STYLES
import useStyles from './trackingUseStyles'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'

// UTILS
import { generateMediaUrlBySize } from 'utilities/media'
import { getDistanceFromTwoLocations } from 'utilities'
import ClustersByLocation from './Clusters/ClustersByLocation'

const Tracking = () => {
  const classes = useStyles()
  const parseLocalStorage = JSON.parse(localStorage.getItem('user'))

  const { setSnackbarObject } = useContext(AllPagesContext)
  const {
    // BOOLEAN
    isPanelLeftShown, setIsPanelLeftShown,
    isEvidenceDetailPanelShown, 
    isObjectDetailPanelShown, 
    isHistoryDateRangeShown, setIsHistoryDateRangeOpen,
    setIsLoadingPanelUnified,
    // LIST
    setBoundsBox,
    mapBounds, setMapBounds,
    setEvidenceList,
    objectList, setObjectList,
    setObjectMarkerList,
    historyDateRange, setHistoryDataRange,
    setHistoryPolylineList,
    setGroupList, setTagList,
    queryFilterEvidence,
    evidenceDateRangeFilter,
    setDispatchEvidenceList,
    objectSortFilter,
    setObjectSortFilter,
    dispatchDateRangeFilter,
    queryFilterDispatch,
    // NUMBER
    zoom, setZoom, setSelectedObjectId,
    // OBJECT
    pageRef,
    map, setMap,
    selectedMapObject,
    referenceLocation,
    // STRING
    evidenceSearch, 
    dispatchSearch,
    // PANEL GEOFENCES
    isShowPanelAddEdit, typePanelAddEdit,
    circleRadius, setCircleRadius,
    setCircleLatLng,
    shapeCurrentRef, setShapeCurrentRef,
    selectionModel,
  } = useContext(PageTrackingContext)
  const { isDrawerExpanded } = useContext(PrivateLayoutContext)

  /* WEBSOCKET */
  const url = `${process.env.REACT_APP_WEBSOCKET_URL}/voc/voc-stream-websocket`
  const [socketState, setSocketState] = useState([])

  // leaflet draw context
  const leafletDrawRef = useRef(null)
  //const shapeCurrentRef = useRef(null)

  const minimalZoom = 2
  const maximalZoom = 20

  // const evidenceDateFormat = 'DD-MM-yyyy'
  // const evidenceStartDate = moment().endOf('day').format(evidenceDateFormat)
  // const evidenceEndDate = moment().subtract(2, 'days').startOf('days').format(evidenceDateFormat)

  const loadEvidenceListData = async () => {
    setIsLoadingPanelUnified(true)
    let filters = {
      startDate: format(new Date(evidenceDateRangeFilter.start), 'dd-MM-yyyy'),
      endDate: format(new Date(evidenceDateRangeFilter.end), 'dd-MM-yyyy'),
      globalSearch: evidenceSearch,
      query: queryFilterEvidence
        ?.filter(item => {
          if(item.op === 'IS_EMPTY' || item.op === 'IS_NOT_EMPTY') return true
          else if (item.field && item.op && item.value) return true
          else return false
        })
        ?.map(item => {
          if (item.field === 'SOS_STATUS') {
            return {
              ...item,
              value: item.value === 'true' ? true : false
            }
          } else if (item.field === 'DEVICE' || item.field === 'TAG') {
            return {
              ...item,
              value: item.value.label
            }
          } else {
            return item
          }
        })
    }

    const response = await advanceSearchEvidences({
      page: 0,
      size: 10,
    }, filters)
    const newEvidenceList = response?.data?.rows?.map(item => {
      const latitude = item.latitude.includes(',') ? parseFloat(item.latitude.replace(',', '.')) : item.latitude
      const longitude = item.longitude.includes(',') ? parseFloat(item.longitude.replace(',', '.')) : item.longitude

      const newMediaList = item?.medias?.map(mediaItem => {
        return generateMediaUrlBySize(mediaItem)
      })

      return {
        ...item,
        latitude,
        longitude,
        medias: newMediaList
      }
    })

    setEvidenceList(newEvidenceList || [])
    setIsLoadingPanelUnified(false)
  }

  const loadDispatchEvidenceData = async () => {
    setIsLoadingPanelUnified(true)
    let filters = {
      startDate: format(new Date(dispatchDateRangeFilter.start), 'dd-MM-yyyy'),
      endDate: format(new Date(dispatchDateRangeFilter.end), 'dd-MM-yyyy'),
      globalSearch: dispatchSearch,
      dispatchType: 'EVIDENCE',
      query: queryFilterDispatch?.filter(item => {
        if (item.field && item.op && item.value) return true
        else return false
      })
        ?.map(item => {
          if (item.field === 'DEVICE') {
            return {
              ...item,
              value: item.value.label
            }
          } else {
            return item
          }
        })
    }

    const responseDeviceSearch = await gets({ size: 10000 }, {})
    const responseDispatch = await advanceSearchDispatch({
      size: 2000,
      sort: 'dispatchNo,DESC'
    }, filters)

    if (responseDispatch?.status !== 'error') {
      const newTableData = responseDispatch?.rows.map(dispatchItem => {
        const deviceObject = responseDeviceSearch.rows.find(deviceItem => dispatchItem.deviceNo === deviceItem.deviceNo)
        
        let label = '[Tidak Ada Label]'
        if (deviceObject) label = deviceObject.label

        return {
          ...dispatchItem,
          id: dispatchItem.dispatchNo,
          deviceLabel: label,
          type: 'DISPATCHES'
        }
      })
      setDispatchEvidenceList(newTableData)
    }
    setIsLoadingPanelUnified(false)
  }

  const integrateObjectDataNew = (
    inputTrackerList,
    inputStateList,
    inputGroupList,
  ) => {
    let output = inputTrackerList.map(trackerItem => {
      const stateObject = inputStateList.find(stateItem => {
        if (stateItem.source_id === trackerItem.source.id) {
          return {
            ...stateItem,
            distance_to_reference: null
          }
        }
      })

      let groupObject = inputGroupList.find((groupItem) => groupItem.groupNo === trackerItem.groupNo)

      if (!groupObject) groupObject = {
        groupNo: 0,
        groupName: 'Main Group',
        groupColor: colors.clementine,
      }

      let tempLatestPositions = []

      // IF HAVE STATE OBJECT THEN PUSH TO latestPositions
      if(stateObject) {
        tempLatestPositions.push({
          lat: stateObject?.gps?.location?.lat,
          lng: stateObject?.gps?.location?.lng
        })
      }

      if (stateObject) stateObject.distance_to_reference = null
      if (stateObject?.gps?.location?.lat && stateObject?.gps?.location?.lng) {
        if (referenceLocation.length > 0) {
          stateObject.distance_to_reference = getDistanceFromTwoLocations(
            referenceLocation,
            [ stateObject?.gps?.location?.lat, stateObject?.gps?.location?.lng ],
          )
        }
      }

      return {
        ...trackerItem,
        isSelected: false,
        isShown: true,
        state: stateObject ? { ...stateObject} : {
          distance_to_reference: null
        },
        latestPositions: tempLatestPositions,
        group: { ...groupObject },
      }
    })

    output = output.filter((item) => item)
    output.sort((a, b) => a.group.groupName.localeCompare(b.group.groupName))

    return output
  }

  const updateObjectDistanceToNull = () => {
    let tempObjectList = [...objectList]
    tempObjectList.map(item => {
      let tempItem = item
      if (item.state?.gps?.location?.lat && item.state?.gps?.location?.lng) {
        if (referenceLocation.length > 0) {
          tempItem.state.distance_to_reference = getDistanceFromTwoLocations(
            referenceLocation,
            [ tempItem?.state?.gps?.location?.lat, tempItem?.state?.gps?.location?.lng ],
          )
        }
      }
    })
    setObjectList(tempObjectList)
  }

  const loadDataFirstTime = async () => {
    await loadEvidenceListData()
    await loadDispatchEvidenceData()

    const responseTagList = await getTagEvidencesList({
      size: 10000,
      sort: 'tagName,ASC'   
    })
    setTagList(responseTagList.rows.map(item => ({...item, label: item.tagName ?? `[Tidak ada label ${item.id}]`, id: item.tagNo })))

    const responseTrackerList = await getTrackerList({ ignorePagination: true })
    const responseGroupList = await getGroups({ page: 0, size: 100 }, {})
    const responseStateHashMap = await getStateHashMap()
    const tempStateHashmap = Object.values(responseStateHashMap.states)

    const integrateState = integrateObjectDataNew(
      responseTrackerList,
      tempStateHashmap,
      responseGroupList.rows,
    )

    setObjectList(integrateState)
    setGroupList(responseGroupList)
    setObjectMarkerList(integrateState)
  }

  const handleSelectDateRangeButtonClick = async (inputNewDateRange) => {
    if (moment(inputNewDateRange[1]).diff(inputNewDateRange[0], 'days') + 1 <= 60 && selectionModel?.length) {
      setHistoryDataRange(inputNewDateRange)
      setIsHistoryDateRangeOpen(false)

      const resultTrackerHistory = await postTrackerHistory({
        from: moment(inputNewDateRange[0]).format('yyyy-MM-DD HH:mm:ss'),
        to: moment(inputNewDateRange[1]).format('yyyy-MM-DD HH:mm:ss'),
        tracker_id: selectionModel[0],
      })

      if (resultTrackerHistory?.success) {
        setHistoryPolylineList(resultTrackerHistory)
        resultTrackerHistory?.list?.length > 0 && setMapBounds(resultTrackerHistory?.list?.map(item => [ item.lat, item.lng ]))
      }
    }
    else {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Rentang tanggal maksimal 60 hari',
      })
    }
  }

  // check radius
  const checkRadiusCircle = (radius) => {
    if(radius > 1500) {
      return setCircleRadius(Math.ceil(1500))
    }
    
    if(radius < 50) {
      return setCircleRadius(Math.ceil(150))
    } 
    
    if(radius >= 50 && radius <= 1500) {
      return setCircleRadius(Math.ceil(radius))
    }
  }

  const updateObjectListFromSocket = async (listState) => {
    const tempStateHashmap = listState

    const newObjectList = objectList.map((objectItem) => {
      const stateObject = tempStateHashmap.find((stateItem) => {
        const sourceIdState = Number(Object.keys(stateItem)[0])
        if (sourceIdState === objectItem.source.id) return true
      })

      let tempLatestPositions = objectItem.latestPositions.length ? objectItem.latestPositions : []

      // PUSH PREV LAT & LNG TO latestPositions
      if(objectItem.latestPositions.length) {
        tempLatestPositions.push({
          lat: objectItem?.state?.gps?.location?.lat,
          lng: objectItem?.state?.gps?.location?.lng,
        })
      }

      return {
        ...objectItem,
        latestPositions: tempLatestPositions,
        state: stateObject
          ? Object.values(stateObject)[0]
          : objectItem.state,
      }
    })

    setObjectMarkerList(newObjectList.sort((a, b) => a.group.groupName.localeCompare(b.group.groupName)))
    setObjectList(newObjectList)
  }

  /* SIDE EFFECT WEBSOCKET */
  useEffect(() => {
    const ws = new WebSocket(`${url}?email=${parseLocalStorage?.email}`)

    ws.onopen = () => {
      console.log('WS Tracking Connected')
    }

    // TEMP DATA STATE SOCKET
    let dataState = []

    // ACTION INTERVAL
    const flush = () => {
      // SET TEMP TO STATE
      setSocketState(dataState)

      // CLEAR TEMP
      dataState = []
    }
    let timer = setInterval(flush, 5000)

    ws.onmessage = (e) => {
      const message = JSON.parse(e.data)

      // PUSH ITEM STATE TO TEMP
      message?.states && dataState.push(message.states)
    }

    return () => {
      clearInterval(timer)
      if (ws) {
        ws.close()
      }
    }
  }, [])

  /* SIDE EFFECT socketState */
  useEffect(() => {
    if(objectList.length && socketState.length) {
      updateObjectListFromSocket(socketState)
    }
  }, [socketState])

  useEffect(() => {
    if (map) {
      map.setZoom(zoom)

      const newBoundsBox = map.getBounds()
      setBoundsBox([
        newBoundsBox.getSouthWest().lng,
        newBoundsBox.getSouthWest().lat,
        newBoundsBox.getNorthEast().lng,
        newBoundsBox.getNorthEast().lat,
      ])
    }
  }, [zoom])

  useEffect(() => {
    mapBounds && map.fitBounds(mapBounds)
  }, [mapBounds])

  useEffect(() => {
    loadDataFirstTime()
  }, [])

  useEffect(() => {
    let timer = setTimeout(() => {
      map && map.invalidateSize()
    }, 300)

    return () => clearTimeout(timer)
  }, [map, isDrawerExpanded])

  /* SIDE EFFECT LEAFLET DRAW */
  useEffect(() => {
    // draw circle
    if(leafletDrawRef.current && isShowPanelAddEdit && typePanelAddEdit === 'add') leafletDrawRef.current._toolbars.draw._modes.circle.handler.enable()
    if(leafletDrawRef.current && !isShowPanelAddEdit) leafletDrawRef.current._toolbars.draw._modes.circle.handler.disable()

    // edit circle
    if(leafletDrawRef.current && isShowPanelAddEdit && typePanelAddEdit === 'edit') leafletDrawRef.current._toolbars.edit._modes.edit.handler.enable()
    if(leafletDrawRef.current && !isShowPanelAddEdit) leafletDrawRef.current._toolbars.edit._modes.edit.handler.disable()

    // set radius
    if(leafletDrawRef.current && map) {
      // set radius after create (once)
      map.on('draw:created', (event) => {
        // set current shape context
        setShapeCurrentRef(event.layer)
        // set shape lat lng
        setCircleLatLng(event.layer.getLatLng())
        // check radius size
        checkRadiusCircle(event.layer.getRadius())
      })

      // trigger when shape resize
      map.on('draw:editresize', (event) => {
        setShapeCurrentRef(event.layer)

        if(event.layer.getRadius() >= 1500) {
          event.layer.setRadius(1500)
          setCircleRadius(1500)
        }else if(event.layer.getRadius() <= 50) {
          setCircleRadius(150)
          event.layer.setRadius(150)
        } else {
          event.layer.setRadius(Math.ceil(event.layer.getRadius()))
          setCircleRadius(Math.ceil(event.layer.getRadius()))
        }
      })

      // trigger when shape move
      map.on('draw:editmove', (event) => {
        // set shape lat lng
        setCircleLatLng(event.layer.getLatLng())
      })
    }
  }, [leafletDrawRef, isShowPanelAddEdit])

  // side effect set radius to shape circle
  useEffect(() => {
    if(circleRadius && shapeCurrentRef) {
      shapeCurrentRef.setRadius(circleRadius) // set current circle radius
    }
  }, [circleRadius])

  // SIDE EFFECT map context
  useEffect(() => {
    if(map) {
      // STOP FOLLOW MARKER WHEN MAP CLICKED
      map.on('click', () => {
        // click action
        setSelectedObjectId(null)
      })

      // STOP FOLLOW MARKER WHEN MAP DRAG
      map.on('dragstart', () => {
        // click action
        setSelectedObjectId(null)
      })
    }
  }, [map])

  // STOP FOLLOW MARKER WHEN UNSELECTED ITEM OR ITEM HAVE MORE THAN 2 SELECTED
  useEffect(() => {
    if(selectionModel.length === 0 || selectionModel.length >= 2) {
      setSelectedObjectId(null)
    }
  }, [selectionModel])

  useEffect(() => {
    loadEvidenceListData()
  }, [evidenceSearch, queryFilterEvidence, evidenceDateRangeFilter])

  useEffect(() => {
    loadDispatchEvidenceData()
  }, [dispatchDateRangeFilter, queryFilterDispatch, dispatchSearch])

  useEffect(() => {
    if (referenceLocation.length >= 2) updateObjectDistanceToNull()
    else if (referenceLocation <= 0) setObjectSortFilter({
      ...objectSortFilter,
      byDistance: false
    })
  }, [referenceLocation])

  return (
    <Box className={classes.pageRoot} ref={pageRef}>
      {/* MAP */}
      <MapContainer
        center={[-0.7893, 113.9213]}
        className={`${classes.mapContainer} no-zoom`}
        zoomControl={false}
        zoom={zoom}
        whenReady={(mapObject) => setMap(mapObject.target)}
      >
        {/* BASE MAP */}
        <TileLayer
          attribution={selectedMapObject.attribution}
          url={selectedMapObject.url}
          subdomains={selectedMapObject.subdomains}
          minZoom={minimalZoom}
          maxZoom={maximalZoom}
          key={selectedMapObject.name}
        />

        {/* MAP CHILDREN ZOOM */}
        <MapChildrenZoom
          zoom={zoom}
          setZoom={setZoom}
        />

        {/* CLUSTERS */}
        <Cluster/>
        <ClustersByLocation />

        {/* HISTORY POLYLINE */}
        <PolylineHistory/>

        {/* GEOFENCES LIST */}
        <GeofencesList />

        <FeatureGroup>
          <EditControl
            position='topright'
            draw={{
              rectangle: false,
              marker: false,
              polyline: false,
              polygon: false,
              circlemarker: false
            }}
            edit={{
              circle: true
            }}
            onMounted={(event) => {
              leafletDrawRef.current = event // set leaflet draw context
            }}
            onDrawStop={(event) => {
              // after draw stop then enable edit mode
              leafletDrawRef.current && leafletDrawRef.current._toolbars.edit._modes.edit.handler.enable()
            }}
          />
        </FeatureGroup>
      </MapContainer>

      {/* PANEL LEFT */}
      {(!isEvidenceDetailPanelShown && !isObjectDetailPanelShown) && <Box className={`${classes.panelContainer} ${classes.panelLeft}`}>
        {/* PANEL LEFT TOGGLE */}
        <PanelToggle
          isPanelShown={isPanelLeftShown}
          setIsPanelShown={setIsPanelLeftShown}
          title='Informasi Peta Terpadu'
          valueShown={panelsInfoStatic?.panelInformation}
        />

        {/* PANEL LEFT */}
        {/* <PanelObjects/> */}
        <PanelUnifiedMapInformation />
      </Box>}

      {/* EVIDENCE DETAIL PANEL */}
      {/* {isEvidenceDetailPanelShown && 
      <Box className={`${classes.panelContainer} ${classes.panelLeft}`}>
        <PanelEvidenceDetail/>
      </Box>} */}

      {/* OBJECT DETAIL PANEL */}
      {/* {isObjectDetailPanelShown && 
      <Box className={`${classes.panelContainer} ${classes.panelLeft}`}>
        <PanelObjectDetail/>
      </Box>} */}

      {/* PANEL RIGHT */}
      <PanelRight />

      {/* DIALOG DATE RANGE PICKER */}
      <Dialog
        open={isHistoryDateRangeShown}
        onClose={() => setIsHistoryDateRangeOpen(false)} 
        className={classes.dateRangeAndTimePickerDialog}
      >
        <DateRangeAndTimePicker
          value={historyDateRange}
          handleSelectButtonClick={handleSelectDateRangeButtonClick}
          handleCancelButtonClick={() => setIsHistoryDateRangeOpen(false)}
        />
      </Dialog>

      {/* GEOFENCES PANEL 
      <PanelGeofences />
      */}

      {/* DIALOG ADD DISPATCH EVIDENCE */}
      <DialogAddDispatchEvidence />
    </Box>
  )
}

export default Tracking
