import React, { Fragment, useContext } from 'react'
import PropTypes from 'prop-types'

// CONTEXTS
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'

// MUIS
import Backdrop from '@mui/material/Backdrop'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconClose from '@mui/icons-material/Close'
import IconCloseFullscreen from '@mui/icons-material/CloseFullscreen'
import IconOpenInFull from '@mui/icons-material/OpenInFull'

// STYLES
import useStyles from './dialogAddOrEditUseStyles'

const DialogAddOrEdit = (props) => {
  const {
    open, title, isExpandButtonAvailable, onCloseIconClick, containerRef, className, contents,
    onCancelButtonClick, onSaveButtonClick, disableButtonSave
  } = props

  const classes = useStyles()
  const { isDialogAddOrEditExpanded, setIsDialogAddOrEditExpanded } = useContext(PrivateLayoutContext)

  let SelectedExpandOrCollapseIcon = IconOpenInFull
  if (isDialogAddOrEditExpanded) SelectedExpandOrCollapseIcon = IconCloseFullscreen

  return (
    <Backdrop
      open={open}
      className={classes.root}
    >
      <Slide 
        direction='right' 
        in={open} 
        container={containerRef.current}
      >
        <Stack
          width='100%'
          height='100%'
        >
          <Stack 
            className={`${classes.contentContainer} ${className}`}
            sx={{
              width: isDialogAddOrEditExpanded ? '100%' : 500,
            }}
          >
            {/* HEADER */}
            <DialogTitle className={classes.dialogTitle}>
              {/* TITLE */}
              {title}

              <Stack flexDirection='row' flexWrap='nowrap' flex={1} justifyContent='flex-end'>
                {/* EXPAND/COLLAPSE ICON */}
                {isExpandButtonAvailable &&
                <IconButton onClick={() => setIsDialogAddOrEditExpanded(current => !current)}>
                  <SelectedExpandOrCollapseIcon/>
                </IconButton>}

                {/* CLOSE ICON */}
                <IconButton onClick={onCloseIconClick}>
                  <IconClose/>
                </IconButton>
              </Stack>
            </DialogTitle>
          
            {/* CONTENT */}
            <DialogContent 
              className={classes.dialogContent}
              sx={{ flexDirection: isDialogAddOrEditExpanded ? 'row' : 'column' }}
            >
              {/* FIRST CONTENT */}
              <Stack sx={isDialogAddOrEditExpanded ? { width: 404 } : {}}>
                {contents[0]}
              </Stack>
            
              {/* REMAINING CONTENTS */}
              {contents.length > 1 &&
              <Stack
                flex='1'
                alignItems='center'
              >
                <Stack sx={isDialogAddOrEditExpanded ? { width: 404 } : { width: '100%' }}>
                  {[...contents.slice(1)].map((item, index) => (
                    <Fragment key={index}>
                      {/* DIVIDER */}
                      {!isDialogAddOrEditExpanded &&
                      <Divider className={classes.dialogContentDivider}/>}

                      {/* CONTENT */}
                      {item}
                    </Fragment>
                  ))}
                </Stack>
              </Stack>}
            </DialogContent>

            {/* ACTIONS */}
            <DialogActions className={classes.dialogActions}>
              {/* CANCEL BUTTON */}
              <Button
                color='default'
                onClick={onCancelButtonClick}
                className={`${classes.dialogActionsButton} ${classes.dialogActionsCancelButton}`}
              >
                Batal
              </Button>

              {/* SAVE BUTTON */}
              <Button 
                className={classes.dialogActionsButton}
                onClick={onSaveButtonClick}
                disabled={disableButtonSave}
              >
                Simpan
              </Button>
            </DialogActions>
          </Stack>
        </Stack>
      </Slide>
    </Backdrop>
  )
}

DialogAddOrEdit.defaultProps = {
  open: false,
  title: '',
  isExpandButtonAvailable: false,
  onCloseIconClick: () => {},
  className: '',
  contents: [],
  onCancelButtonClick: () => {},
  onSaveButtonClick: () => {},
  disableButtonSave: false
}

DialogAddOrEdit.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  isExpandButtonAvailable: PropTypes.bool.isRequired,
  onCloseIconClick: PropTypes.func.isRequired,
  containerRef: PropTypes.oneOfType([
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  className: PropTypes.string.isRequired,
  contents: PropTypes.array.isRequired,
  onCancelButtonClick: PropTypes.func.isRequired,
  onSaveButtonClick: PropTypes.func.isRequired,
  disableButtonSave: PropTypes.bool
}

export default DialogAddOrEdit