import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { colors } from 'constants/colors'

const menuWidth = 500 - 20 - 20 // 20px is from the horizontal padding of the Dialog Add a New Vehicle component

const useStyles = makeStyles((theme) => ({
  selectAccount: {
    '& .MuiSelect-select': {
      backgroundColor: theme.palette.common.white,
      padding: '12px 8px',
      borderRadius: 2,
      '& .MuiSvgIcon-root': {
        fontSize: 20,
      }
    },
    '& .MuiInputBase-root': {
      padding: '40px 4px 4px 4px',
      borderRadius: 2,
    },
    '& .MuiSvgIcon-root': {
      top: 'calc(50% - -0.24em) !important',
    }
  },
  selectAccountPaper: {
    borderRadius: 2,
    '& .MuiList-root': {
      padding: 4,
    }
  },
  selectAccountItem: {
    paddingLeft: 12,
    fontSize: 14,
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: 20,
      marginRight: 8,
      color: theme.palette.text.secondary
    },
    '&:hover': {
      backgroundColor: colors.geyser
    },
    '&.Mui-selected': {
      backgroundColor: colors.geyser
    }
  },
  formControl: {
    position: 'relative',
    marginBottom: 16
  },
  btnShowHidePass: {
    position: 'absolute',
    bottom: 6,
    right: 6
  },
  btnRight: {
    width: 'fit-content',
    position: 'absolute',
    top: 4,
    right: 4,
    '& .MuiSvgIcon-root': {
      marginRight: 4
    }
  },
  signatureWrapper: {
    backgroundColor: theme.palette.common.white,
    position: 'relative'
  },
  btnSave: {
    fontWeight: 600,
  },
  btnCancel: {
    fontWeight: 600,
    color: theme.palette.text.secondary
  },
  btnClear: {
    fontWeight: 600,
    color: theme.palette.text.secondary,
    position: 'absolute',
    top: 4,
    right: 12,
  },
  previewImage: {
    backgroundColor: theme.palette.common.white
  },
  dropDownIcon: {
    color: colors.boulder,
  },
  menu: {
    '& .MuiPaper-root': {
      width: menuWidth,
      maxHeight: 500,
    },
    '& .MuiMenu-list': {
      padding: 0,
    },
  },
  '@media only screen and (max-height: 820px)': {
    inputDepartment: {
      '& .MuiInput-input': {
        zoom: 0.85,
      },
    },
    menu: {
      '& .MuiPaper-root': {
        maxWidth: 360,
      }
    }
  },
  menuSearch: {
    padding: 16,
  },
  menuSearchInput: {
    width: '100%',
    fontWeight: 600,
    paddingBottom: 4,
    '&::-webkit-input-placeholder': {
      color: colors.boulder,
    },
  },
  menuSearchIcon: {
    marginRight: 4,
  },
  menuItemContainer: {
    width: '100%',
    overflow: 'auto',
    maxHeight: 'calc(500px - 65px)', // 65 is from the height of the search Input container component
  },
  menuItem: {
    height: 48,
    paddingLeft: 20,
  },
  menuItemSelected: {
    backgroundColor: alpha(colors.clementine, 0.1),
  },
}))

export default useStyles