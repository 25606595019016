import { http } from '../utilities/http'

export async function getStatisticData() {
  try {
    const { data } = await http.get('/dashboards/statistic',)

    return data

  } catch (e) {
    return {'status': 'error', rows: []}
  }
}


export async function getPatrolLineChart(body) {
  try {
    const date = new Date()
    body.timeOffset = -date.getTimezoneOffset()/60
    const { data } = await http.post('/dashboards/patrol-line-chart', body)

    return data

  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function getEvidenceBarChart(body) {
  try {
    const date = new Date()
    body.timeOffset = -date.getTimezoneOffset()/60
    const { data } = await http.post('/dashboards/evidence-bar-chart', body)

    return data

  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function getEvidenceRoundChart(body) {
  try {
    const date = new Date()
    body.timeOffset = -date.getTimezoneOffset()/60
    const { data } = await http.post('/dashboards/evidence-round-chart-simple', body)

    return data

  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function getEvidenceStatsChart(body) {
  try {
    const { data } = await http.post('/dashboards/evidence/stats', body)

    return data

  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function getEvidenceCitizenStatsChart(body) {
  try {
    const { data } = await http.post('/dashboards/evidence/citizen/stats', body)

    return data

  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function getPatrolStatsChart(body) {
  try {
    const { data } = await http.post('/dashboards/patrol/stats', body)

    return data

  } catch (e) {
    return {'status': 'error'}
  }
}

export async function downloadEvidenceReport(body, fileName) {
  http.post('/dashboards/evidence/report', body, {
    responseType: 'blob',
    timeout: 30000
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    })
}