import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// COMPONENTS
import CustomFormControl from 'components/Customs/CustomFormControl'
import CustomInputNew from 'components/Customs/CustomInputNew'
import CustomInputLabelNew from 'components/Customs/CustomInputLabelNew'
import DialogAddOrEdit from 'components/DialogAddOrEditNew/DialogAddOrEdit'
import DialogColorPicker from 'components/DialogColorPicker/DialogColorPicker'
import DialogIconPicker from 'components/DialogIconPicker/DialogIconPicker'
import SimpleBackdrop from 'components/Partials/SimpleBackdrop'

// CONSTANTS
import { basePaths } from 'constants/paths'
import { colors } from 'constants/colors'
import { iconListTagLocation } from 'constants/icons'

// DISPATCH
import { showAlert } from 'store/MainReducer'

// MUIS
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconTextFields from '@mui/icons-material/TextFields'

// PATH ICONS
import IconImageAdd from 'assets/images/pathIcons/IconImageAdd'

// SERVICES
import { postLocationTags, putLocationTags } from 'services/SettingsTagLocation'

// STYLES
import useStyles from './dialogAddEditSettingsTagLocationUseStyles'

const DialogAddEditTagLocation = (props) => {
  const {
    dialogAddEditTagLocation,
    setDialogAddEditTagLocation,
    pageRef,
    reloadData,
  } = props

  const classes = useStyles()
  const dispatch = useDispatch()

  const history = useHistory()

  const defaultParamsObject = {
    iconCode: '',
    locationTagColor: '',
    locationTagName: '',
  }

  const [isLoading, setIsLoading] = useState(false)
  const [isDialogColorShown, setIsDialogColorShown] = useState(false)
  const [isDialogIconShown, setIsDialogIconShown] = useState(false)
  const [paramsObject, setParamsObject] = useState(defaultParamsObject)

  const closeDialog = () => {
    setDialogAddEditTagLocation(null)
    history.push(basePaths.settingsTagLocation)
  }

  const showAddIcon = () => {
    if (paramsObject?.iconCode) {
      const Icon = iconListTagLocation[Number(paramsObject.iconCode)-1]?.Icon
      return <Icon className={classes.iconAddedView} />
    } else return <IconImageAdd className={classes.iconAddImage} fontSize='large'/>
  }

  const handleParamsChange = (key, value) => {
    setParamsObject({
      ...paramsObject,
      [key]: value,
    })
  }

  const handleButtonSaveClick = async () => {
    if(dialogAddEditTagLocation?.mode === 'add') {
      const response = await postLocationTags({
        ...paramsObject,
        locationTagColor: paramsObject.locationTagColor || colors.flamingo,
        iconId: paramsObject.iconCode || 0
      })
      
      if(response?.status === 'error') {
        dispatch(showAlert({ message: 'Gagal Menambahkan Tag Lokasi' }))
      } else {
        dispatch(showAlert({ message: 'Berhasil Menambahkan Tag Lokasi' }))
      }
    } else if(dialogAddEditTagLocation?.mode === 'edit') {
      const response = await putLocationTags(
        dialogAddEditTagLocation.data.locationTagNo,
        {
          ...paramsObject,
          locationTagColor: paramsObject.locationTagColor || colors.flamingo,
          iconId: paramsObject.iconCode || 0
        }
      )
      
      if(response?.status === 'error') {
        dispatch(showAlert({ message: 'Gagal Mengubah Tag Lokasi' }))
      } else {
        dispatch(showAlert({ message: 'Berhasil Mengubah Tag Lokasi' }))
      }
    }

    reloadData()
    setIsLoading(false)
    closeDialog()
  }

  useEffect(() => {
    if(dialogAddEditTagLocation?.mode === 'edit') {
      setParamsObject(dialogAddEditTagLocation?.data)
    } else {
      setParamsObject(defaultParamsObject)
    }
  }, [dialogAddEditTagLocation])

  return (
    <>
      {/* DIALOG ADD OR EDIT */}
      <DialogAddOrEdit
        open={Boolean(dialogAddEditTagLocation)}
        title='Tag Lokasi'
        onCloseIconClick={() => history.push(basePaths.settingsTagLocation)}
        containerRef={pageRef}
        contents={[
          <>
            {/* MAIN SECTION TEXT */}
            <Typography variant='h6' className={classes.sectionText}>
              Informasi
            </Typography>

            {/* ICON & COLOR */}
            <Stack className={`${classes.pockbaseStyles} ${classes.formControl}`} direction='row' flexWrap='nowrap' alignItems='center'>
              {/* CHANGE ICON */}
              <Stack className={classes.addIconWrap}>
                <IconButton
                  className={classes.btnAddImage}
                  disableRipple
                  onClick={() => setIsDialogIconShown(true)}
                >
                  {showAddIcon()}
                </IconButton>
              </Stack>

              <Typography variant='subtitle1' className={classes.pockbaseStylesLabel}>Icon</Typography>

              {/* CHANGE COLOR */}
              <Stack flex={1} direction='row' justifyContent='flex-end' paddingRight='8px'>
                <Box
                  className={classes.boxColor}
                  sx={{
                    backgroundColor: paramsObject?.locationTagColor || colors.flamingo
                  }}
                  onClick={() => setIsDialogColorShown(true)}
                ></Box>
              </Stack>
            </Stack>

            {/* NAME JENIS KEGIATAN */}
            <CustomFormControl variant='filled' className={classes.formControl}>
              <CustomInputLabelNew shrink={true}>
                <IconTextFields />
                <Typography variant='subtitle1'>Nama Tag Lokasi</Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Masukkan Nama Tag Lokasi'
                onChange={(event) => handleParamsChange('locationTagName', event.target.value)}
                value={paramsObject?.locationTagName}
              />
            </CustomFormControl>
          </>
        ]}
        onCancelButtonClick={() => history.push(basePaths.settingsTagLocation)}
        onSaveButtonClick={() => handleButtonSaveClick()}
      />

      {/* LOADING */}
      <SimpleBackdrop isLoading={isLoading} />

      {/* DIALOG COLOR */}
      <DialogColorPicker
        isDialogColorShown={isDialogColorShown}
        setIsDialogColorShown={setIsDialogColorShown}
        selectedColor={paramsObject.locationTagColor}
        onSaveButtonClick={selectedColor => setParamsObject({
          ...paramsObject, locationTagColor: selectedColor
        })}
      />

      {/* DIALOG ICON */}
      <DialogIconPicker
        isDialogIconShown={isDialogIconShown}
        setIsDialogIconShown={setIsDialogIconShown}
        selectedIcon={paramsObject.iconCode}
        onSaveButtonClick={selectedIcon => setParamsObject({
          ...paramsObject, iconCode: selectedIcon
        })}
        listIcon={iconListTagLocation}
      />
    </>
  )
}

export default DialogAddEditTagLocation
