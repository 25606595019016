// CONSTANTS
import { values } from 'constants/values'

// MUIS
import { createTheme } from '@mui/material'

// LOCALE
import * as locales from '@mui/material/locale'

const customThemePrivate = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
      xxxl: 2500,
    },
  },
  components: {
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: 14,
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        sx: {
          fontSize: 14,
        },
      },
    },
    MuiListItemText: {
      defaultProps: {
        sx: {
          '& .MuiTypography-root': {
            fontSize: 14,
          },
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          fontSize: 14,
        },
      },
    },
  },
  palette: {
    background: {
      default: '#EBEBEB',
    },
    primary: {
      main: '#EF6C00',
    },
    default: {
      main: '#FFF',
    },
    defaultSub: {
      main: '#A7A8BB',
    },
    iconDefault: {
      main: '#6F7687',
    },
  },
  typography: {
    fontFamily: values.fontFamily,
    button: {
      textTransform: 'none',
    },
  },
}, locales['idID'])

export default customThemePrivate