import ReactDOMServer from 'react-dom/server'

// COMPONENTS
import CustomTooltip from './CustomTooltip'

// CONSTANTS
import { values } from 'constants/values'

export const getChartOptions = (
  theme,
  categories,
) => {
  return {
    chart: {
      fontFamily: values.fontFamily,
      foreColor: theme.palette.text.primary,
      height: 180,
      toolbar: {
        show: false,
      },
      type: 'bar',
      zoom: {
        enabled: false,
      },
      events: {
        dataPointMouseEnter: (event, chartContext, config) => {
          const tooltip = chartContext.el.querySelector('.apexcharts-tooltip')
          const seriesIndex = config.seriesIndex
          if (seriesIndex !== -1) {
            tooltip.style.transform = 'translateX(-65%)'
          }
        },
      }
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      colors: [ theme.palette.primary.main ],
      opacity: 1,
    },
    grid: {
      show: true,
      borderColor: theme.palette.divider,
      strokeDashArray: 4,
      padding: {
        top: -10,
        right: -10,
        bottom: -10,
        left: 0,
      },  
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: '25%',
        borderRadiusApplication: 'end',
        endingShape: 'rounded',
        horizontal: false,
        distributed: true,
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        return ReactDOMServer.renderToString(
          <CustomTooltip
            series={series}
            dataPointIndex={dataPointIndex}
            categories={categories}
            theme={theme}
          />
        )
      },
    },
    xaxis: {
      categories,
      labels: {
        show: false,
      },
    },
    yaxis: {
      forceNiceScale: true,
      labels: {
        show: true,
      },
    },
  }
}