// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'
import { alpha } from '@mui/material/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
    borderRadius: 4,
    boxShadow: `0px 0px 8px ${alpha(theme.palette.common.black, 0.16)}`,
    border: `1px solid ${theme.palette.divider}`
  },
  evidenceInformation: {
    color: theme.palette.common.black,
    '& .MuiListItemIcon-root': {
      minWidth: 'unset',
      marginRight: 8,
      color: theme.palette.common.black,
    },
  },
  evidenceItemTag: {
    width: 'fit-content',
    margin: 0,
    '& .MuiChip-label': {
      paddingLeft: 8,
      fontWeight: 600,
    },
    '&.chipMore': {
      border: `1px solid ${alpha(theme.palette.common.black, 0.16)}`,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    }
  },
  evidenceItemVoiceNote: {
    backgroundColor: colors.pocelain,
    borderRadius: 8,
    marginTop: 4,
  },
  headerChip: {
    width: 'fit-content',
    paddingLeft: 4,
    paddingRight: 4,
    borderRadius: 4,
    '& .MuiChip-label': {
      padding: '2px 8px',
    },
    '&.needHelp': {
      marginRight: 8,
      color: theme.palette.error.main,
      backgroundColor: alpha(theme.palette.error.main, 0.08),
      '& .MuiSvgIcon-root': {
        color: theme.palette.error.main,
      }
    }
  },
  linkId: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    cursor: 'pointer',
  },
  textUploaded1: {
    color: colors.raven
  },
  textUploaded2: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginLeft: 4,
  },
  iconUploadBy: {
    color: colors.raven,
    fontSize: 16,
    marginRight: 2,
  },
  textareaDescription: {
    marginTop: 8,
    backgroundColor: colors.athensGray,
    borderRadius: 4,
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important'
    },
    '& .MuiOutlinedInput-input:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important'
    }
  },
  buttonSave: {
    color: colors.deYork,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}))

export default useStyles