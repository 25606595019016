/* GET ALL DEVICE STORAGE */
export const getAllDeviceLocalStorage = () => {
  return localStorage.getItem('playedLive') ? JSON.parse(localStorage.getItem('playedLive')) : []
}

/* CHECK IF DEVICE ALREADY ADDED TO STORAGE */
export const hasDeviceOnStorage = (deviceCode) => {
  const parseStorage = getAllDeviceLocalStorage()

  if(parseStorage.length) {
    const findIt = parseStorage.find(item => item.deviceCode === deviceCode)
    return Boolean(findIt)
  }

  return false
}

/* ADD DEVICE TO STORAGE */
export const addDeviceToLocalStorage = (deviceCode, played) => {
  const parseStorage = getAllDeviceLocalStorage()
  
  if(!hasDeviceOnStorage(deviceCode)) {
    const temp = [
      ...parseStorage,
      {
        deviceCode,
        played,
      }
    ]

    localStorage.setItem('playedLive', JSON.stringify(temp))
  }
}

/* GET DETAIL DEVICE STORAGE */
export const getDetailDeviceLocalStorage = (deviceCode) => {
  return getAllDeviceLocalStorage().find(item => item.deviceCode === deviceCode)
}

/* UPDATE DETAIL DEVICE */
export const updateDeviceToLocalStorage = (deviceCode, played) => {
  const parseStorage = localStorage.getItem('playedLive') ? JSON.parse(localStorage.getItem('playedLive')) : []
    
  if(hasDeviceOnStorage(deviceCode)) {
    const temp = parseStorage.map(item => {
      if(item.deviceCode === deviceCode) {
        return {
          ...item,
          played: played
        }
      } else return item
    })
  
    localStorage.setItem('playedLive', JSON.stringify(temp))
  }
}

/* CLEANUP DETAIL DEVICE */
export const cleanUpDeviceLocalStorage = (deviceCode) => {
  const parseStorage = getAllDeviceLocalStorage()

  const temp = parseStorage.filter(item => item.deviceCode !== deviceCode)
  localStorage.setItem('playedLive', JSON.stringify(temp))
}

/* CLEANUP ALL STORAGE */
export const cleanUpAllDeviceStorage = () => {
  localStorage.setItem('playedLive', [])
}