import { useState, useEffect, useContext } from 'react'

// CONSTANTS
import { fieldList } from '../evidencesConstants'
import { 
  initialfilterOptionList, 
  savedFiltersText,
} from './filterOptionsData'

// CONTEXTS
import { PageEvidencesContext } from 'contexts/PageEvidencesContext'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

// MUI ICONS
import IconClose from '@mui/icons-material/Close'
import IconKeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'

// PATH ICONS
import IconBookmark from '@mui/icons-material/Bookmark'
import IconDatabase from 'assets/images/pathIcons/IconDatabase'
import IconFilterAttach from 'assets/images/pathIcons/IconFilterAttach'
import IconFilterDetach from 'assets/images/pathIcons/IconFilterDetach'
import IconText from 'assets/images/pathIcons/IconText'

// SERVICES
import { 
  getEvidenceStatistics, 
  getSavedFilterListAdvanceSearch,
} from 'services/EvidenceService'

// STYLES
import useStyles from './filterOptionsUseStyles'

// UTILITIES
import { capitalizeEachWord } from 'utilities'

const FilterOptions = () => {
  const classes = useStyles()

  const theme = useTheme()

  const { 
    setIsCreateNewFilterButtonShown,
    setIsSaveExistingFilterButtonShown,
    mustReloadFilterOptionsData, setMustReloadFilterOptionsData,
    setIsLoading,
    dataSourceList,
    setSelectedFilterList, 
    selectedSavedFilter, setSelectedSavedFilter,
    setDialogDeleteSavedFilter,
    selectedDataSource, setSelectedDataSource,
    addFilterItemToSelectedFilterList,
  } = useContext(PageEvidencesContext)

  const [ filterOptionList, setfilterOptionList ] = useState(initialfilterOptionList)
  const [ isRendered, setIsRendered ] = useState(false)

  const mapListIntoNewOptionList = (list) => {
    return list.map(item => {
      return {
        ...item,
        label: capitalizeEachWord(item.value),
        icon: IconText,
      }
    })
  }

  const updateFilterOptionsData = async () => {
    // NOTE: DO NOT UDPATE THE LOADING STATE WHEN THE COMPONENT IS RENDERED FIRST TIME
    isRendered && setIsLoading(true)

    const resultSavedFiltes = await getSavedFilterListAdvanceSearch()

    const resultEvidenceStatistics = await getEvidenceStatistics({
      address: true,
      device: true,
      limit: 5,
      media: true,
      tag: true,
    })

    if (resultEvidenceStatistics.status === 200 && resultSavedFiltes.status === 200) {
      const newFilterOptionList = [...filterOptionList].map((parentItem, parentIndex) => {
        // SAVED FILTERS
        if (parentItem.label.includes(savedFiltersText)) {
          parentItem.label = `Filter yang disimpan (${resultSavedFiltes.data.rows.length})`

          parentItem.children = resultSavedFiltes.data.rows.map((savedFilterItem) => {
            return {
              label: savedFilterItem.name,
              icon: IconBookmark,
              ...savedFilterItem,
            }
          })
        }
        // MEDIAS
        else if (parentItem.label === 'Jenis File') {
          parentItem.children = resultEvidenceStatistics.data.medias.map((mediaItem) => {
            let label = capitalizeEachWord(mediaItem.value)
            if (mediaItem.value === 'image') label = 'Foto'
            // if (mediaItem.value.toLowerCase() === 'image') label = 'Foto'

            return {
              ...mediaItem,
              label,
              icon: IconText,
            }
          })
        }
        // EVIDENCE TAGS
        else if (parentItem.label === 'Tag Kejadian') {
          parentItem.children = mapListIntoNewOptionList(resultEvidenceStatistics.data.tags)
        }
        // LOCATIONS
        else if (parentItem.label === 'Lokasi') {
          parentItem.children = mapListIntoNewOptionList(resultEvidenceStatistics.data.addresses)
        }
        // DEVICES
        else if (parentItem.label === 'Perangkat') {
          parentItem.children = mapListIntoNewOptionList(resultEvidenceStatistics.data.devices)
        }

        return parentItem
      })

      setfilterOptionList(newFilterOptionList)
    }

    setMustReloadFilterOptionsData(false)
    isRendered && setIsLoading(false)
  }

  const handleFilterOptionIconButtonClick = (field, fieldLabel, operator, value) => {
    addFilterItemToSelectedFilterList(field, fieldLabel, operator, value)

    setIsCreateNewFilterButtonShown(true)
    setIsSaveExistingFilterButtonShown(selectedSavedFilter?.filterNo ? true : false)
  }

  const handleSavedFilterItemClick = (childrenItem) => {
    setSelectedFilterList(childrenItem?.jsonFilter?.query?.map(filterItem => {
      return {
        ...filterItem,
        operator: filterItem.op,
        fieldLabel: fieldList.find(item => item.value === filterItem.field).label,
      }
    }))

    setSelectedSavedFilter({
      filterNo: childrenItem.filterNo,
      name: childrenItem.name,
      description: childrenItem.description,
    })

    setIsCreateNewFilterButtonShown(false)
    setIsSaveExistingFilterButtonShown(false)
  }

  const handleDeleteSavedFilterItemButtonClick = (event, childrenItem) => {  
    event.stopPropagation()

    setDialogDeleteSavedFilter(childrenItem)
  }
  
  useEffect(() => {
    mustReloadFilterOptionsData && updateFilterOptionsData()
  }, [mustReloadFilterOptionsData])

  useEffect(() => {
    setIsRendered(true)
  }, [isRendered])

  return (
    <Stack
      maxWidth='260px'
      minWidth='260px'
      borderRight={`1px solid ${theme.palette.divider}`}
    >
      <Stack 
        flex={1} 
        overflow='auto' 
        padding='16px !important'
      >
        <Stack height={0}>
          {/* FILTER OPTION LIST */}
          {filterOptionList.map((parentItem, parentIndex) => (
            <List 
              key={parentIndex}
              disablePadding
              sx={{ paddingBottom: parentIndex === filterOptionList.length - 1 ? '8px' : 0}}
            >
              {/* LIST LABEL */}
              <ListItem className={classes.parentListItem}>
                {/* ICON */}
                <ListItemIcon>
                  <parentItem.icon/>
                </ListItemIcon>

                {/* TEXT */}
                <ListItemText primary={parentItem.label}/>
              </ListItem>

              {/* CAPTION */}
              {(!parentItem.label.includes(savedFiltersText) || (parentItem.label.includes(savedFiltersText) && parentItem.children.length === 0)) &&
              <Typography 
                variant='caption'
                color='text.secondary'
              >
                {parentItem.caption}
              </Typography>}

              {/* LIST ITEM LABELS */}
              <List>
                {parentItem.children.map((childrenItem, childrenIndex) => (
                  <ListItemButton 
                    key={childrenIndex}
                    className={classes.childrenListItem}
                    disableRipple={!parentItem.label.includes(savedFiltersText) ? true : false}
                    sx={!parentItem.label.includes(savedFiltersText) ? { cursor: 'default !important' } : {}}
                    onClick={() => parentItem.label.includes(savedFiltersText) && handleSavedFilterItemClick(childrenItem)}
                  >
                    {/* ICON */}
                    <ListItemIcon>
                      <childrenItem.icon fontSize='small'/>
                    </ListItemIcon>

                    {/* TEXT */}
                    <ListItemText primary={childrenItem.label}/>

                    {/* ACTIONS */}
                    {parentItem.label.includes(savedFiltersText) ? (
                      <Stack
                        className='childrenListItemActions'
                        minWidth='30px'
                        marginLeft='4px'
                      >
                        <CustomTooltipBlack
                          title='Hapus item'
                          placement='top'
                        >
                          <IconButton 
                            size='small' 
                            className='childrenListItemIconButton'
                            onClick={(event) => handleDeleteSavedFilterItemButtonClick(event, childrenItem)}
                          >
                            <IconClose fontSize='small'/>
                          </IconButton>
                        </CustomTooltipBlack>
                      </Stack>
                    ) : (
                      <Stack
                        paddingLeft='4px'
                        direction='row'
                        borderLeft={`1px solid ${theme.palette.divider}`}
                        className='childrenListItemActions'
                        minWidth='65px'
                        marginLeft='4px'
                      >
                        {/* ATTACH ICON BUTTON */}
                        <CustomTooltipBlack
                          title='Saring nilai'
                          placement='top'
                        >
                          <IconButton 
                            size='small' 
                            className='childrenListItemIconButton'
                            onClick={() => handleFilterOptionIconButtonClick(
                              parentItem.field, 
                              parentItem.field === 'SOS_STATUS' ? 'SOS' : parentItem.label, 
                              'IS', 
                              parentItem.field === 'SOS_STATUS' ? true : childrenItem.label
                            )}
                          >
                            <IconFilterAttach fontSize='small'/>
                          </IconButton>
                        </CustomTooltipBlack>

                        {/* DETACH ICON BUTTON */}
                        <CustomTooltipBlack
                          title='Saring nilai yang dikecualikan'
                          placement='top'
                        >
                          <IconButton 
                            size='small' 
                            className='childrenListItemIconButton'
                            onClick={() => handleFilterOptionIconButtonClick(
                              parentItem.field, 
                              parentItem.field === 'SOS_STATUS' ? 'SOS' : parentItem.label, 
                              'IS_NOT', 
                              parentItem.field === 'SOS_STATUS' ? true : childrenItem.label
                            )}
                          >
                            <IconFilterDetach fontSize='small'/>
                          </IconButton>
                        </CustomTooltipBlack>
                      </Stack>
                    )}
                  </ListItemButton>
                ))}
              </List>
            </List>
          ))}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default FilterOptions