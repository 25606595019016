import React, { useState, useRef, useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// COMPONENTS
import PageHeader from 'components/PageHeader/PageHeader'

// CONSTANTS
import { basePaths } from 'constants/paths'

// CUSTOM COMPONENTS
import PanelRules from './PanelRules'
import PanelRulesSetting from './PanelRulesSetting'
import DialogAddAlertRule from './DialogAddAlertRule/DialogAddAlertRule'

// MUIS
import Box from '@mui/material/Box'

// STYLES
import useStyles from './alertRuleUseStyles'

// CONTEXT
import { PageAlertRulesContext } from 'contexts/PageAlertRulesContext'

const AlertRules = () => {
  const classes = useStyles()
  const pageRef = useRef()

  const history = useHistory()
  const location = useLocation()

  // CONTEXT
  const { setSelectionModel, setSelectedRulesItem } = useContext(PageAlertRulesContext)

  // STATES
  const [dialogAddAlertRule, setDialogAddAlertRule] = useState(false)

  useEffect(() => {
    if (location.pathname === basePaths.alertRules && location.search === '?mode=add') {
      setDialogAddAlertRule(true)
      setSelectionModel([])
      setSelectedRulesItem(false)
    }
  }, [location])

  return (
    <Box 
      className={classes.pageRoot}
      ref={pageRef}
    >
      {/* HEADER */}
      <PageHeader
        isAddButtonAvailable={true}
        onAddButtonIsClicked={() => history.push(`${basePaths.alertRules}?mode=add`)}
        title='Ketentuan Peringatan'
        isSearchAvailable={false}
      />

      <Box className={classes.panelContainer}>
        <Box className={classes.panelLeft}>
          <PanelRules
            pageRef={pageRef}
          />
        </Box>

        <Box className={classes.panelRight}>
          <PanelRulesSetting
            pageRef={pageRef}
          />
        </Box>
      </Box>

      {/* DIALOG ADD ALERT RULE */}
      <DialogAddAlertRule
        setDialogAddAlertRule={setDialogAddAlertRule}
        dialogAddAlertRule={dialogAddAlertRule}
      />
    </Box>
  )
}

export default AlertRules