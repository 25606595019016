// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    fontWeight: 600,
    marginBottom: 16,
  },
  formControl: {
    marginBottom: 16,
    borderRadius: '2px !important',
    '& .formControlDatePicker': {
      cursor: 'pointer'
    },
    '&:last-child': {
      marginBottom: 0,
    }
  },
  menuDeviceList: {
    zIndex: theme.zIndex.modal - 1,
    '& .MuiPaper-root': {
      width: 'calc(500px - 20px - 20px)', // 20px is from the horizontal padding of the container component
    },
    '& .MuiMenu-list': {
      padding: 0,
    },
  },
  menuDeviceListSearch: {
    padding: '12px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  menuDeviceListDeviceList: {
    height: 360,
    maxHeight: 360,
    overflowY: 'auto',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  menuDeviceListSelectedOption: {
    backgroundColor: alpha(theme.palette.primary.main, 0.16),
  },
  menuDeviceListButton: {
    textTransform: 'uppercase',
  },
  menuDeviceListButtonCancel: {
    color: theme.palette.text.secondary,
  },
}))

export default useStyles