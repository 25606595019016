import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

// COMPONENTS
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DataGridHeaderCell from 'components/DataGridHeaderCell/DataGridHeaderCell'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import Flyout from 'components/Flyout/Flyout'
import LoadingBox from 'components/LoadingBox/LoadingBox'
import PageHeader from 'components/PageHeader/PageHeader'
import PatrolDetail from './PatrolDetail/PatrolDetail'
import SimpleBackdrop from 'components/Partials/SimpleBackdrop'

// CONSTANTS
import { colors } from 'constants/colors'
import { 
  iconListActivityTypes, 
  iconListDutyTypes,
} from 'constants/icons'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { alpha } from '@mui/material/styles'

// MUI ICONS
import IconAccessTime from '@mui/icons-material/AccessTime'
import IconBedtime from '@mui/icons-material/Bedtime'
import IconDelete from '@mui/icons-material/Delete'
import IconLightMode from '@mui/icons-material/LightMode'
import IconPhoneAndroid from '@mui/icons-material/PhoneAndroid'

// PATH ICONS
import IconDayAndNight from 'assets/images/pathIcons/IconDayAndNight'
import IconDiversity from 'assets/images/pathIcons/IconDiversity'
import IconPoliceCar from 'assets/images/pathIcons/IconPoliceCar'
import IconPoliceMan from 'assets/images/pathIcons/IconPoliceMan'
import IconSprint from 'assets/images/pathIcons/IconSprint'

// RAMDA
import { isEmpty, reject } from 'ramda'

// SERVICES
import { 
  getActivityType,
  getPatrolies, 
} from 'services/DispatchService'
import { deletePatrol } from 'services/patrolServices'
import { getVehicleTypeList } from 'services/vehicleServices'

// STORES
import { showAlert } from 'store/MainReducer'

// STYLES
import useStyles from './patrolsUseStyles'

// UTILS
import { 
  capitalizeEachWord, 
  dateFormat,
  getStringAvatar, 
} from 'utilities'

const Patrols = () => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const initialFilters = {
    activityTypeName: '',
    workOrderNumber: '',
    deviceName: '',
    vehicleNames: '',
    officerNames: '',
    clockedInTime: '',
    clockedOutTime: '',
    duration: '',
    shift: '',
  }

  const groupByList = [
    {
      title: 'Don\'t Group',
      value: null,
    },
    {
      title: 'By Jenis Patroli',
      value: 'activityTypeName',
    },
  ]

  const flyoutWidth = 528
  const flyoutTitleMargin = 392 // TO MAKE THE TITLE VERTICALLY ALIGN WITH THE FLYOUT COMPONENT

  const [ total, setTotal ] = useState(0)
  const [ page, setPage ] = useState(0)
  const [ order, setOrder ] = useState('desc')
  const [ orderBy, setOrderBy ] = useState('created_on')
  const [ size, setPageSize ] = useState(100)
  const [ patrolTableData, setPatrolTableData ] = useState([])
  const [ filters, setFilters ] = useState(initialFilters)
  const [ selectedGroupBy, setSelectedGroupBy ] = useState(groupByList[0])
  const [ isFilterOn, setIsFilterOn ] = useState(false)
  const [ search, setSearch ] = useState('')
  const [ dialogDeletePatrol, setDialogDeletePatrol ] = useState({})
  const [ isTableLoading, setIsTableLoading ] = useState(false)
  const [ isDeleteLoading, setIsDeleteLoading ] = useState(false)
  const [ selectionModel, setSelectionModel ] = useState([])
  const [ isFlyoutShown, setIsFlyoutShown ] = useState(false)
  const [ patrolDetail, setPatrolDetail ] = useState(null)

  const handleEditIconButtonClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()
    setDialogDeletePatrol(inputParams)
  }

  const initialColumns = [
    {
      field: 'activityTypeName',
      headerName: 'Jenis Kegiatan',
      headerIcon: IconDiversity,
      areFilterAndSortShown: true,
      flex: 1,
      minWidth: 150,
      hide: false,
      renderCell: (params) => (
        params.value &&
        <Chip
          icon={<params.row.activityType.Icon sx={{ color: `${params.row.activityType.activityTypeColor} !important` }}/>}
          label={params.value.toLowerCase()} 
          variant='outlined'
          size='small'
          className={classes.columnActivityType}
          sx={{ 
            color: params.row.activityType.activityTypeColor, 
            borderColor: params.row.activityType.activityTypeColor,
            backgroundColor: alpha(params.row.activityType.activityTypeColor, 0.08),
          }}
        />
      ),
    },
    {
      field: 'workOrderNumber',
      headerName: 'Sprin',
      headerIcon: IconSprint,
      areFilterAndSortShown: true,
      flex: 1,
      minWidth: 150,
      hide: false,
      renderCell: (params) => (
        params.value &&
        <Chip
          label={params.value} 
          variant='outlined'
          size='small'
          className={classes.columnSprin}
        />
      ),
    },
    {
      field: 'deviceName',
      headerName: 'Perangkat',
      headerIcon: IconPhoneAndroid,
      areFilterAndSortShown: true,
      flex: 1,
      minWidth: 150,
      hide: false,
      renderCell: (params) => (
        params.value &&
        <Chip
          icon={<IconPhoneAndroid className={classes.columnDeviceIcon}/>}
          label={params.value} 
          variant='filled'
          size='small'
          className={classes.columnDevice}
        />
      ),
    },
    {
      field: 'vehicleTypeName',
      headerName: 'Metode',
      headerIcon: IconPoliceCar,
      areFilterAndSortShown: true,
      flex: 1,
      minWidth: 200,
      hide: false,
      renderCell: (params) => (
        params.value &&
        <Chip
          icon={<params.row.vehicleType.Icon sx={{ color: `${params.row.vehicleType.vehicleTypeColor} !important` }}/>}
          label={capitalizeEachWord(params.value)} 
          variant='outlined'
          size='small'
        />
      )
    },
    {
      field: 'clockedInTime',
      headerName: 'Mulai Giat',
      headerIcon: IconAccessTime,
      areFilterAndSortShown: true,
      flex: 1,
      minWidth: 200,
      hide: false,
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'clockedOutTime',
      headerName: 'Selesai Giat',
      headerIcon: IconAccessTime,
      areFilterAndSortShown: true,
      flex: 1,
      minWidth: 200,
      hide: false,
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'shift',
      headerName: 'Shift',
      headerIcon: IconDayAndNight,
      areFilterAndSortShown: false,
      flex: 1,
      minWidth: 150,
      hide: false,
      renderCell: (params) => {
        let selectedLabel, SelectedIcon, selectedColor
        
        if(params.value === '08.00 WIB s/d 20.00 WIB') {
          selectedLabel = 'Pagi'
          SelectedIcon = IconLightMode
          selectedColor = colors.amber
        }
        else if(params.value === '20.00 WIB s/d 08.00 WIB') {
          selectedLabel = 'Malam'
          SelectedIcon = IconBedtime
          selectedColor = colors.lilacBush
        }

        if (SelectedIcon && selectedColor) return (
          <Chip
            icon={<SelectedIcon sx={{ color: `${selectedColor} !important` }}/>}
            size='small'
            label={selectedLabel} 
            variant='outlined'
          />
        )
        else return null
      }
    },
    {
      field: 'officerNames',
      headerName: 'Petugas',
      headerIcon: IconPoliceMan,
      areFilterAndSortShown: false,
      flex: 1,
      minWidth: 250,
      hide: false,
      renderCell: (params) => (
        params.value?.length > 0 &&
        <Chip
          avatar={
            <Avatar className={classes.columnOfficerAvatar}>
              {getStringAvatar(params.value[0])}
            </Avatar>
          }
          size='small'
          label={capitalizeEachWord(params.value[0])} 
          variant='outlined'
          className={classes.columnOfficer}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      width: 120,
      hide: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box className={classes.columnActions}>
          {/* DELETE ICON */}
          <CustomTooltipBlack
            title='Hapus'
            placement='bottom'
          >
            <IconButton onClick={(event) => handleEditIconButtonClick(event, params)}>
              <IconDelete/>
            </IconButton>
          </CustomTooltipBlack>
        </Box>
      ),
    },
  ]

  const [ selectedColumnList, setSelectedColumnList ] = useState(initialColumns)

  const groupingColDef = {
    headerName: 'Sprin',
    renderHeader: (params) => (
      <DataGridHeaderCell
        icon={IconSprint}
        headerName='Sprin'
      />
    ),
    renderCell: (params) => {
      if (!params.rowNode.isAutoGenerated) {
        return (
          <Chip
            label={params.row?.workOrderNumber} 
            variant='outlined'
            size='small'
            className={classes.columnSprin}
          />
        )
      }

      return (
        <Typography 
          variant='subtitle1' 
          className={classes.groupingRow}
          noWrap
        >
          {params.rowNode.groupingKey.toLowerCase()}
        </Typography>
      )
    },
  }

  const handleColumnsMenuItemClick = (inputItem, inputIndex) => {
    let tempSelectedColumnList = [...selectedColumnList]
    // TO DO: CHANGE THIS CONDITION LATER
    if(
      (selectedGroupBy.value && inputItem.field === 'activityTypeName') ||
      (selectedGroupBy.value && inputItem.field === 'workOrderNumber')
    ) {}
    else {
      tempSelectedColumnList[inputIndex].hide = !tempSelectedColumnList[inputIndex].hide
    }
    setSelectedColumnList(tempSelectedColumnList)
  }
  
  const reloadData = async () => {
    const newFilters = reject(isEmpty, filters)
    let params = {
      page,
      size,
    }

    if (order) params.sort = `${orderBy},${order}`
    if (search) newFilters.globalSearch = search
    if(order === null ) params.sort = 'created_on,desc'

    setIsTableLoading(true)

    const resultPatrolsListData = await getPatrolies(params, newFilters)
    const resultActivityTypeListData = await getActivityType()
    const resultVehicleTypeListData = await getVehicleTypeList()

    if (
      resultPatrolsListData.status !== 'error' 
      && resultActivityTypeListData.status !== 'error'
      && resultVehicleTypeListData.status !== 'error'
    ) {
      const newPatrolTableData = resultPatrolsListData.rows.map(patrolItem => {
        const activityTypeObject = resultActivityTypeListData.rows.find(activityTypeItem => patrolItem?.activityTypeNo === activityTypeItem?.activityTypeNo)
        const activityTypeIconObject = iconListActivityTypes.find(iconItem => iconItem?.id === Number(activityTypeObject?.iconCode))

        const vehicleTypeObject = resultVehicleTypeListData.rows.find(vehicleTypeItem => vehicleTypeItem?.vehicleTypeNo === patrolItem?.vehicleTypeNo)
        const vehicleTypeIconObject = iconListDutyTypes.find(iconItem => iconItem?.id === Number(vehicleTypeObject?.iconCode))

        return {
          ...patrolItem,
          activityType: { 
            ...activityTypeObject, 
            ...activityTypeIconObject,
          },
          vehicleType: { 
            ...vehicleTypeObject,
            ...vehicleTypeIconObject, 
          },
        }
      })

      setPatrolTableData(newPatrolTableData)
      setTotal(resultPatrolsListData.totalElements)
    }

    setIsTableLoading(false)
  }

  const handleDeleteButtonClick = async () => {
    setIsDeleteLoading(true)
    const result = await deletePatrol(dialogDeletePatrol.row.patrolNo)
    setIsDeleteLoading(false)

    if(result.status !== 'error') {
      dispatch(showAlert({ message: 'Sukses Menghapus Item Giat' }))
      setDialogDeletePatrol({})
      reloadData()
    }
  }

  useEffect(() => {
    reloadData()
  }, [page, size, order, orderBy, filters, search])

  useEffect(() => {
    if(!isFilterOn) setFilters(initialFilters)
  }, [isFilterOn])

  useEffect(() => {
    if (selectionModel.length === 0) {
      setPatrolDetail(null)
    }
    else if (selectionModel.length === 1) {
      const selectedReportPatrol = patrolTableData.find(item => item.id === selectionModel[0])
      setPatrolDetail(selectedReportPatrol)
      setIsFlyoutShown(true)
    }
    else if (selectionModel.length > 1) {
      setPatrolDetail(null)
    }
  }, [selectionModel])

  useEffect(() => {
    if (selectedGroupBy.value) {
      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        if (
          item.field === selectedGroupBy.value ||
          item.field === 'workOrderNumber'
        ) {
          item.hide = true
        }
      })
      setSelectedColumnList(tempSelectedColumnList)
    } else {
      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        if (item.field === 'activityTypeName' || item.field === 'workOrderNumber') {
          item.hide = false
        }
      })
      setSelectedColumnList(tempSelectedColumnList)
    }
  }, [selectedGroupBy])

  return (
    <Box className={classes.pageRoot}>
      {/* HEADER */}
      <PageHeader
        isAddButtonAvailable={false}
        title='Kegiatan'
        search={search}
        setSearch={setSearch}
        isFlyoutAvailable={true}
        isFlyoutShown={isFlyoutShown}
        flyoutTitle='Detail Giat'
        flyoutTitleMargin={`${flyoutTitleMargin}px`}
        onToggleFlyoutClick={() => setIsFlyoutShown((current) => !current)}
      />

      {/* MAIN CONTENT AND FLYOUT */}
      <Box
        className={classes.contentContainer}
        sx={{
          paddingRight: isFlyoutShown ? `${flyoutWidth + 24}px` : 0,
        }}
      >
        {/* CONTENT */}
        <LoadingBox isLoading={isTableLoading}>
          <DataGridFilters
            columns={initialColumns}
            selectedColumnList={selectedColumnList}
            handleColumnsMenuItemClick={handleColumnsMenuItemClick}
            isFilterOn={isFilterOn}
            setIsFilterOn={setIsFilterOn}
            groupByList={groupByList}
            selectedGroupBy={selectedGroupBy}
            setSelectedGroupBy={setSelectedGroupBy}
          />

          <DataGridTable
            initialColumns={initialColumns}
            rows={patrolTableData}
            total={total}
            page={page}
            pageSize={size}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            setPage={setPage}
            setPageSize={setPageSize}
            setFilters={setFilters}
            isFilterOn={isFilterOn}
            selectedColumnList={selectedColumnList}
            setSelectedColumnList={setSelectedColumnList}
            selectedGroupBy={selectedGroupBy}
            getTreeDataPath={(row) => [ row.activityTypeName, row.id ]}
            groupingColDef={groupingColDef}
            selectionModel={selectionModel} 
            setSelectionModel={setSelectionModel}
            isCheckboxPinned={true}
          />
        </LoadingBox>

        {/* REPORT PATROL DETAIL */}
        <Flyout
          isFlyoutShown={isFlyoutShown} 
          flyoutWidth={flyoutWidth}
        >
          {/* INFORMATION TEXT */}
          {patrolDetail ? (
            <PatrolDetail rowItem={patrolDetail}/>
          ) : (
            <Typography
              variant='subtitle2'
              color='text.secondary'
            >
              {selectionModel.length === 0 && 'Pilih entri dari daftar'}
              {selectionModel.length > 1 && `${selectionModel.length} catatan entri yang dipilih`}
            </Typography>
          )}
        </Flyout>
      </Box>

      {/* DIALOG DELETE PATROL */}
      <DialogConfirmation
        title='Hapus Giat'
        caption='Apa Anda yakin ingin menghapus giat ini?'
        dialogConfirmationObject={dialogDeletePatrol}
        setDialogConfirmationObject={setDialogDeletePatrol}
        cancelButtonText='Batal'
        continueButtonText='Hapus'
        onContinueButtonClick={handleDeleteButtonClick}
        onCancelButtonClick={() => setDialogDeletePatrol({})}
      />

      {/* LOADING */}
      <SimpleBackdrop isLoading={isDeleteLoading}/>
    </Box>
  )
}

export default Patrols