// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'
import { alpha } from '@mui/material/styles'


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    boxShadow: `0px 4px 16px ${alpha(theme.palette.common.black, 0.16)}`,
    borderRadius: 2,
  },
  searchWrapper: {
    padding: '8px 8px 0 8px',
    width: '100%'
  },
  searchTextfield: {
    backgroundColor: colors.whiteLilac,
    '& .MuiOutlinedInput-root': {
      paddingLeft: 12,
      borderRadius: 2,
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px 0'
    }
  },
  content: {
    maxHeight: 364,
    overflowY: 'auto',
  },
  footer: {
    boxShadow: `0px -2px 4px ${alpha(theme.palette.common.black, 0.16)}`,
    flexDirection: 'row',
    padding: '8px 24px',
    justifyContent: 'flex-end'
  },
  buttonDialog: {
    fontWeight: 700,
    '&.btnCancel': {
      color: theme.palette.text.secondary,
      fontWeight: 600
    }
  }
}))

export default useStyles
