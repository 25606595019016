// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  iconClose: {
    marginRight: 16,
    cursor: 'pointer',
  },
  titleText: {
    fontWeight: 600,
    fontSize: 24,
  },
  sectionText: {
    marginBottom: 8,
    fontWeight: 600,
  },
  patrolStatusContainer: {
    marginTop: 28,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  patrolStatusToggleButtonGroup: {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
  },
  patrolStatusToggleButton: {
    fontSize: 12,
    height: 32,
    fontWeight: 500,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      fontWeight: 600,
      border: 'none',
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.main,
      filter: 'brightness(0.96)',
    },
  },
  iconAndFormControlContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    height: 65,
    width: '100%',
    flex: '0 0 65px',
  },
  iconFormControl: {
    marginRight: 12,
    marginBottom: 2,
    color: colors.raven,
  },
  formControl: {
    width: '100%',
  },
  blankSpace: {
    marginTop: '40px',
    width: '100%',
  },
  cancelButton: {
    color: colors.raven,
    '&:hover': {
      backgroundColor: alpha(colors.raven, 0.1),
    },
  },

  basicTitle: {
    color: colors.textDark,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.15 px',
  },
  basicSubtitle: {
    color: colors.black,
    opacity: 0.54,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '17.16px',
    letterSpacing: '0.15 px',
  },
  menu: {
    transform: 'translate(-16px, -16px)',
    '& .MuiPaper-root': {
      width: 'calc(500px - 48px - 48px)',
      maxHeight: 500,
    },
    '& .MuiMenu-list': {
      padding: 0,
    },
  },
  menuSearch: {
    padding: 16,
  },
  menuSearchInput: {
    width: '100%',
    fontWeight: 600,
    paddingBottom: 4,
    '&::-webkit-input-placeholder': {
      color: colors.boulder,
    },
  },
  menuSearchIcon: {
    marginRight: 4,
  },
  menuItemContainer: {
    width: '100%',
    overflow: 'auto',
    maxHeight: 'calc(500px - 65px)', // 65 is from the height of the search Input container component
  },
  menuItem: {
    height: 48,
    paddingLeft: 20,
  },
  menuItemSelected: {
    backgroundColor: alpha(colors.clementine, 0.1),
  },
  '@media only screen and (max-height: 820px)': {
    inputInduk: {
      '& .MuiInput-input': {
        zoom: 0.85,
      },
      '& .MuiInputLabel-root': {
        zoom: 1/0.85,
      }
    },
    menu: {
      '& .MuiPaper-root': {
        maxWidth: 360,
      }
    }
  }
}))

export default useStyles
