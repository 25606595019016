import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// COMPONENTS
import CustomDialogActions from 'components/Customs/CustomDialogActions'
import CustomDialogDelete from 'components/Customs/CustomDialogDelete'

// MUIS
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './dialogIconPickerUseStyles'

const DialogIconPicker = (props) => {
  const {
    isDialogIconShown, setIsDialogIconShown,
    selectedIcon, onSaveButtonClick, listIcon
  } = props
  const classes = useStyles()


  // STATES
  const [currentIcon, setCurrentIcon] = useState(selectedIcon)
  
  const handleDialogClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setIsDialogIconShown(false)
    }
  }

  const handleButtonSaveClick = () => {
    onSaveButtonClick(currentIcon)
    setIsDialogIconShown(false)
  }

  const handleButtonCancelClick = () => {
    setCurrentIcon(selectedIcon)
    setIsDialogIconShown(false)
  }

  useEffect(() => {
    setCurrentIcon('')
    if(selectedIcon) setCurrentIcon(selectedIcon)
  }, [selectedIcon])

  return (
    <CustomDialogDelete
      open={isDialogIconShown}
      className={classes.dialogIcon}
      onClose={handleDialogClose}
    >
      <Typography variant='subtitle1' className={classes.dialogTitle}>Tambah Icon</Typography>

      <Stack className={classes.dialogContent}>
        <Typography className={classes.textLabel}>Pilih Gambar</Typography>

        <Stack direction='row' flexWrap='wrap'>
          {listIcon.map(item => (
            <Stack
              key={item.id}
              className={`${classes.boxIcon} ${currentIcon === item.id.toString() && 'selected'}`}
              onClick={() => setCurrentIcon(item.id.toString())}
            >
              <item.Icon className={classes.iconActivityTypes} />
            </Stack>
          ))}
        </Stack>
      </Stack>

      <CustomDialogActions>
        <Button
          className={classes.btnCancel}
          onClick={handleButtonCancelClick}
        >BATAL</Button>

        <Button
          className={classes.btnSave}
          onClick={handleButtonSaveClick}
        >SIMPAN</Button>
      </CustomDialogActions>
    </CustomDialogDelete>
  )
}

DialogIconPicker.defaultProps = {
  isDialogIconShown: false,
  selectedIcon: '1',
  onSaveButtonClick: () => {},
  listIcon: [],
}

DialogIconPicker.propTypes = {
  isDialogIconShown: PropTypes.bool.isRequired,
  setIsDialogIconShown: PropTypes.func.isRequired,
  selectedIcon: PropTypes.string.isRequired,
  onSaveButtonClick: PropTypes.func.isRequired,
  listIcon: PropTypes.array.isRequired,
}

export default DialogIconPicker