// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconListView = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M18 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V4C20 2.9 19.1 2 18 2ZM10 16.5C10 16.776 9.776 17 9.5 17H8.5C8.224 17 8 16.776 8 16.5V15.5C8 15.224 8.224 15 8.5 15H9.5C9.776 15 10 15.224 10 15.5V16.5ZM10 12.5C10 12.776 9.776 13 9.5 13H8.5C8.224 13 8 12.776 8 12.5V11.5C8 11.224 8.224 11 8.5 11H9.5C9.776 11 10 11.224 10 11.5V12.5ZM10 8.5C10 8.776 9.776 9 9.5 9H8.5C8.224 9 8 8.776 8 8.5V7.5C8 7.224 8.224 7 8.5 7H9.5C9.776 7 10 7.224 10 7.5V8.5ZM15 17H13C12.448 17 12 16.552 12 16C12 15.448 12.448 15 13 15H15C15.552 15 16 15.448 16 16C16 16.552 15.552 17 15 17ZM15 13H13C12.448 13 12 12.552 12 12C12 11.448 12.448 11 13 11H15C15.552 11 16 11.448 16 12C16 12.552 15.552 13 15 13ZM15 9H13C12.448 9 12 8.552 12 8C12 7.448 12.448 7 13 7H15C15.552 7 16 7.448 16 8C16 8.552 15.552 9 15 9Z' />
    </SvgIcon>
  )
}

export default IconListView