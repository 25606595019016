import React, { useState, useEffect, useRef, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'

// COMPONENTS
import PanelNotification from './PanelNotification'
import MapChildrenZoom from 'components/PanelZoom/MapChildrenZoom'
import PanelZoom from 'components/PanelZoom/PanelZoom'
import ListMarkers from './ListMarkers/ListMarkers'

// CONTEXT
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'
import { AllPagesContext } from 'contexts/AllPagesContext'

// DATA
import { mapOptionList } from './alertData'

// LEAFLET
import { MapContainer, TileLayer } from 'react-leaflet'

// MUIS
import Box from '@mui/material/Box'

// STYLES
import useStyles from './alertUseStyles'
import 'leaflet/dist/leaflet.css'

// SERVICES
import { getAlerts, putSosStatusAlerts } from 'services/AlertService'
import { getAllRules } from 'services/AlertService'


const Alert = () => {
  const classes = useStyles()
  const { isDrawerExpanded } = useContext(PrivateLayoutContext)
  const { setSnackbarObject } = useContext(AllPagesContext)
  const history = useHistory()

  const pageRef = useRef(null)

  const minimalZoom = 2
  const maximalZoom = 20

  const selectedMapObject = mapOptionList[0]
  const location = useLocation()

  const [map, setMap] = useState(null)
  const [zoom, setZoom] = useState(5)
  const [notificationList, setNotificationList] = useState([])
  const [ruleList, setRuleList] = useState([])
  const [paramsStatus, setParamsStatus] = useState({})

  const loadAllRules = () => {
    getAllRules().then((response)=>{
      setRuleList(response)
    })
  }

  const loadDataFirstTime = async () => {

    const query = {
      page: 0,
      size: 100
    }

    getAlerts(query).then((response) => {
      setNotificationList(response.rows)
    })
  }

  const updateSosStatus = async () => {
    if (Object.keys(paramsStatus)?.length) {
      if (isNaN(Number(paramsStatus.alertNo))) {
        return setSnackbarObject({
          open: true,
          severity: 'warning',
          title: '',
          message: 'Alert no tidak valid',
        })
      }

      const status = paramsStatus?.status === 'accept' ? 'ACCEPTED' : 'REJECTED'
      const response = await putSosStatusAlerts(Number(paramsStatus.alertNo), { status })
      
      if (response?.status !== 'error') {
        await loadDataFirstTime()
        return setSnackbarObject({
          open: true,
          severity: 'success',
          title: '',
          message: `Berhasil ${status === 'ACCEPTED' ? 'menerima'  : 'menolak'} SOS`,
        })
      } else {
        return setSnackbarObject({
          open: true,
          severity: 'error',
          title: '',
          message: 'Terjadi kesalahan, ulangi kembali',
        })
      }
    }
  }

  useEffect(() => {
    if (map) {
      map.setZoom(zoom)
    }
  }, [zoom])

  useEffect(() => {
    loadDataFirstTime()
    loadAllRules()
  }, [])

  useEffect(() => {
    const updateListAlert = setTimeout(() => {
      history.push('/alert')
      loadDataFirstTime()
    }, process.env.REACT_APP_API_REQUEST_INTERVAL * 1000)

    return () => {
      clearTimeout(updateListAlert)
    }
  }, [notificationList])

  useEffect(() => {
    let timer = setTimeout(() => {
      map && map.invalidateSize()
    }, 300)

    return () => clearTimeout(timer)
  }, [map, isDrawerExpanded])

  useEffect(() => {
    if(location?.search) {
      const values = queryString.parse(location?.search)
      setParamsStatus(values)
    }
  }, [location])

  useEffect(() => {
    updateSosStatus()
  }, [paramsStatus])

  useEffect(() => {
    if (map) {
      map.on('click', () => {
        setParamsStatus({})
      })
    }
  }, [map])
  
  return (
    <Box className={classes.pageRoot} ref={pageRef}>
      {/* MAP */}
      <MapContainer
        center={[-0.7893, 113.9213]}
        className={classes.mapContainer}
        zoomControl={false}
        zoom={zoom}
        whenReady={(mapObject) => setMap(mapObject.target)}
      >
        {/* BASE MAP */}
        <TileLayer
          attribution={selectedMapObject.attribution}
          url={selectedMapObject.url}
          subdomains={selectedMapObject.subdomains}
          minZoom={minimalZoom}
          maxZoom={maximalZoom}
          key={selectedMapObject.name}
        />

        {/* MAP CHILDREN ZOOM */}
        <MapChildrenZoom
          zoom={zoom}
          setZoom={setZoom}
        />

        {/* LIST MARKERS */}
        <ListMarkers
          notificationList={notificationList}
          map={map}
          paramsStatus={paramsStatus}
        />
      </MapContainer>

      <Box className={`${classes.panelContainer} ${classes.panelLeft}`}>
        <PanelNotification
          pageRef={pageRef}
          isNotificationPanelShown
          notificationList={notificationList}
          reloadData={loadDataFirstTime}
          ruleList={ruleList}
        />
      </Box>

      {/* ZOOM PANEL */}
      <PanelZoom
        setZoom={setZoom} 
        theme='LIGHT'
        className={classes.panelZoomContainer}
      />
    </Box>
  )
}

export default Alert
