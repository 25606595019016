export const basePaths = {
  alertRules: '/alert/rule',
  dispatch: '/penugasan',
  dispatchPatrols: '/penugasan-kegiatan',
  dispatchEvidences: '/penugasan-kejadian',
  deviceVehicles: '/devices-vehicles',
  pushToTalks: '/push-to-talks',
  settingsDutyTypes: '/settings-duty-types',
  settingsActivityTypes: '/settings-activity-types',
  settingsDisturbances: '/settings-disturbances',
  settingsCases: '/settings-cases',
  settingsOfficers: '/settings-officers',
  settingsGroups: '/settings-groups',
  settingsPlaces: '/settings-places',
  settingsUsers: '/settings-users',
  settingsTagEvidences: '/settings-tag-evidences',
  settingsTagLocation: '/settings-tag-location',
  settingsSosMessageType: '/settings-sos-message-types'
}