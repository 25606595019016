import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// CUSTOM COMPONENTS
import CustomDialogActionButton from 'components/Customs/CustomDialogActionButton'
import CustomInput from 'components/Customs/CustomInput'
import LoadingBox from 'components/LoadingBox/LoadingBox'

// MUIS
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import Menu from '@mui/material/Menu'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconAddCircle from '@mui/icons-material/AddCircle'
import IconClear from '@mui/icons-material/Clear'
import IconClose from '@mui/icons-material/Close'
import IconPhoneAndroid from '@mui/icons-material/PhoneAndroid'
import IconSearch from '@mui/icons-material/Search'

// SERVICES
import { getDeviceList } from 'services/DeviceService'
import { getTrackerList, getStateHashMap } from 'services/TrackingServices'

// STYLES
import useStyles from './inputDeviceListUseStyles'

// UTILS
import { getConnectionStatusColor, getConnectionStatusText } from 'utilities'

const InputDeviceList = (props) => {
  const {
    title,
    buttonText,
    selectedDeviceList,
    setSelectedDeviceList,
    classNameMenuAdd,
    isWithDistance,
    isDarkTheme,
    type,
    disabledInput,
    defaultDeviceNos
  } = props

  const classes = useStyles()

  const [deviceList, setDeviceList] = useState([])
  const [addMenuAnchor, setAddMenuAnchor] = useState(null)
  const [selectedTab, setSelectedTab] = useState(0)
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isDefaultValueAdded, setIsDefaultValueAdded] = useState(false)
  
  const handleAvailableDeviceItemClick = (inputItem, inputIndex) => {
    if(selectedTab === 0 ){
      const newDeviceList = [...deviceList.sort((a,b)=>(a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))]
      newDeviceList[inputIndex].isSelected = !newDeviceList[inputIndex].isSelected
      setDeviceList(newDeviceList)
    } else if (selectedTab === 1){
      const newDeviceList = [...deviceList.sort((a,b)=> a.distance - b.distance)]
      newDeviceList[inputIndex].isSelected = !newDeviceList[inputIndex].isSelected
      setDeviceList(newDeviceList)
    }
  }

  const handleSaveButtonClick = () => {
    const newSelectedDevice = deviceList.filter((item) => item.isSelected)
    setSelectedDeviceList(newSelectedDevice)
    handleClose()
  }

  const handleUnselectIconButtonClick = (inputItem, inputIndex) => {
    let newSelectedDeviceList = [...selectedDeviceList]
    newSelectedDeviceList = newSelectedDeviceList.filter(
      (selectedDeviceItem) => inputItem.label !== selectedDeviceItem.label || inputItem.trackerLabel !== selectedDeviceItem.trackerLabel
    )
    setSelectedDeviceList(newSelectedDeviceList)
  }

  const sortDeviceList = (data)=> {
    if(selectedTab === 0){
      return data.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
    } else if (selectedTab === 1){
      return data.sort((a,b)=> a.distance - b.distance)
    }
  }

  const integrateObjectData = (
    inputAvailableDeviceToAssign,
    inputTrackerList,
    inputStateHashMap
  ) => {
    let newAvailableDeviceToAssign = inputAvailableDeviceToAssign.map(deviceItem => {
      inputTrackerList.forEach(trackerItem =>{
        if(deviceItem.trackerId === trackerItem.id) deviceItem.sourceId = trackerItem.source.id
      })
      return deviceItem
    })

    newAvailableDeviceToAssign = newAvailableDeviceToAssign.map(deviceItem => {
      const stateObject = inputStateHashMap.find(stateItem => {
        if(stateItem.source_id === deviceItem.sourceId) return stateItem
      })
      return {
        ...deviceItem,
        connection_status : stateObject?.connection_status ?? '-'
      }
    })

    return newAvailableDeviceToAssign
  }
  
  const reloadData = async () => {
    setIsLoading(true)
    const resultStateHashMap = await getStateHashMap()
    const responseTrackerList = await getTrackerList({ ignorePagination: true })
    const responseAvailableDeviceToAssign = await getDeviceList({ limit: 10000 })
    const restructureAvailableDeviceToAssign = responseAvailableDeviceToAssign?.data?.map((item) => {
      item.id = item.deviceNo
      return item
    })

    const tempStateHashMap = Object.values(resultStateHashMap.states)
    
    if (type === 'patrol' || type === 'evidence') {
      const newAvailableDeviceToAssign = restructureAvailableDeviceToAssign.map(deviceItem => {
        const matchDevice = selectedDeviceList.find(selectedItem => deviceItem.label === selectedItem.label)
        if (matchDevice) deviceItem.isSelected = matchDevice.isSelected
        else deviceItem.isSelected = false

        if (deviceItem.label.toLowerCase().includes(search.toLowerCase())) deviceItem.isShown = true
        else deviceItem.isShown = false

        return deviceItem
      })

      setDeviceList(integrateObjectData(newAvailableDeviceToAssign, responseTrackerList, tempStateHashMap))
    }
    else if (type === 'trackerList') {
      const newDeviceList = responseTrackerList.map(deviceItem => {
        const matchDevice = selectedDeviceList.find(selectedItem => deviceItem.label === selectedItem.trackerLabel || deviceItem.label === selectedItem.label)
        if (matchDevice) deviceItem.isSelected = true
        else deviceItem.isSelected = false

        if (deviceItem.label && deviceItem.label.toLowerCase().includes(search.toLowerCase())) deviceItem.isShown = true
        else deviceItem.isShown = false

        return deviceItem
      })
      setDeviceList(newDeviceList)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    setSelectedDeviceList([])
  }, [])

  useEffect(() => {
    let newDeviceList = [...deviceList]

    newDeviceList.forEach((item) => {
      if (item.label && item.label.toLowerCase().includes(search.toLowerCase())) item.isShown = true
      else item.isShown = false
    })

    setDeviceList(newDeviceList)
  }, [search])

  useEffect(() => {
    reloadData()
  }, [addMenuAnchor])

  const handleClose = () => {
    setAddMenuAnchor(null)
  }

  useEffect(() => {
    if (deviceList?.length && defaultDeviceNos?.length && !isDefaultValueAdded) {
      setIsLoading(true)
      const defaultSelected = defaultDeviceNos.map(deviceNo => {
        const findDevice = deviceList.find(item => item.deviceNo === deviceNo)
        return {
          ...findDevice,
          isSelected: true
        }
      })
      setSelectedDeviceList(defaultSelected)
      setIsDefaultValueAdded(true)
      setIsLoading(false)
    }
  }, [defaultDeviceNos, deviceList])

  return (
    <Stack className='no-zoom'>
      {/* TITLE TEXT */}
      {!isDarkTheme && 
      <Typography variant='body2' className={`${classes.titleText} titleInputDevice zoom`}>
        {title}
      </Typography>}

      {/* SELECTED DEVICE LIST */}
      <List className='zoom' disablePadding>
        {selectedDeviceList.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            className={classes.selectedDeviceItem}
          >
            <Stack direction='row' alignItems='center'>
              <IconPhoneAndroid className={classes.textSecondary} fontSize='large' />

              <Stack paddingLeft='8px'>
                {/* NAME */}
                <Typography variant='body2'>
                  {item.label ? item.label : item.trackerLabel ?? '[Tanpa Label]'}
                </Typography>

                {/* STATUS */}
                <Typography
                  variant='caption'
                  display='block'
                  lineHeight={1.2}
                  sx={{
                    color: getConnectionStatusColor(item.connection_status),
                  }}
                  textTransform='capitalize'
                >
                  {getConnectionStatusText(item.connection_status)}
                </Typography>
              </Stack>
            </Stack>

            {/* UNSELECT ICON BUTTON */}
            {!disabledInput && <IconButton
              size='small'
              onClick={() => handleUnselectIconButtonClick(item, index)}
            >
              <IconClose 
                fontSize='small' 
                className={isDarkTheme ? classes.buttonAddDeviceDark : ''} 
              />
            </IconButton>}
          </ListItem>
        ))}
      </List>

      {/* ADD DEVICE BUTTON */}
      {!disabledInput && <Button
        variant='text'
        startIcon={<IconAddCircle fontSize='small' color='primary' className={`${isDarkTheme ? classes.textDark : ''} no-zoom`} />}
        className={ `${classes.buttonAddDevice} ${isDarkTheme ? classes.buttonAddDeviceDark : ''} zoom`}
        onClick={(event) => setAddMenuAnchor(event.currentTarget)}
        disabled={isLoading}
      >
        <Typography fontWeight={600} variant='caption'>{buttonText}</Typography>
      </Button>}

      {/* ADD DEVICE LIST MENU */}
      <Menu
        anchorEl={addMenuAnchor}
        open={Boolean(addMenuAnchor)}
        onClose={handleClose}
        className={`${classes.menuAdd} ${classNameMenuAdd}`}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Stack className='zoom'>
          <LoadingBox isLoading={isLoading}>
            {/* TITLE */}
            <Stack
              padding='12px 20px'
              borderBottom='1px solid #0000001f'
            >
              <Typography
                variant='subtitle1'
                className={classes.menuAddTitle}
              >
                Pilih Perangkat
              </Typography>
            </Stack>

            {/* TABS */}
            {isWithDistance && 
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => setSelectedTab(newValue)}
              indicatorColor='primary'
              textColor='inherit'
              variant='fullWidth'
              className={classes.menuTab}
            >
              <Tab label='Nama Perangkat' />
              <Tab label='Jarak' />
            </Tabs>}

            {/* SEARCH BOX */}
            <Box className={classes.menuSearchBox}>
              {/* INPUT */}
              <CustomInput
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                className={classes.menuSearchInput}
                placeholder='Pencarian Cepat'
                disableUnderline
                onClick={(event) => event.stopPropagation()}
                onKeyDown={(event) => event.stopPropagation()}
              />

              {/* ICON */}
              {search === '' 
                ? <IconSearch className={classes.menuSearchIcon} /> 
                : <IconClear
                  className={classes.menuSearchIcon}
                  onClick={() => setSearch('')}
                />
              }
            </Box>

            {/* DEVICE LIST */}
            <List
              className={classes.menuAvailableDeviceList}
              disablePadding
            >
              {sortDeviceList([...deviceList]).map((item, index) =>
                item.isShown && 
                <ListItemButton
                  key={index}
                  className={`${classes.availableDeviceItem} no-zoom`}
                  onClick={() => handleAvailableDeviceItemClick(item, index)}
                >
                  {/* CHECKBOX */}
                  <Checkbox
                    checked={item.isSelected}
                    className={classes.availableDeviceCheckbox}
                  />

                  {/* DEVICE DATA */}
                  <Box className={classes.availableDeviceData}>
                    {/* NAME */}
                    <Typography variant='body2' fontWeight={600}>
                      {item.label ? item.label : item.trackerLabel ?? '[Tidak ada label]'}
                    </Typography>

                    {isWithDistance && 
                    <Box className={classes.availableDeviceStatus}>
                      {/* STATUS */}
                      <Typography
                        variant='caption'
                        sx={{
                          color: getConnectionStatusColor(item.connection_status)
                        }}
                      >
                        {item.connection_status ? getConnectionStatusText(item.connection_status) : ''}
                      </Typography>

                      {/* DISTANCE */}
                      <Typography
                        variant='caption'
                        className={classes.availableDeviceDistance}
                      >
                        {item.distance >= 0 ? `${item.distance} km` : '- km'}
                      </Typography>
                    </Box>
                    }
                  </Box>
                </ListItemButton>
              )}
            </List>

            {/* ACTION BUTTONS */}
            <Box className={classes.menuActionsBox}>
              {/* CANCEL BUTTON */}
              <CustomDialogActionButton
                className={classes.cancelButton}
                color='default'
                onClick={handleClose}
              >
                BATAL
              </CustomDialogActionButton>

              {/* OK BUTTON */}
              <CustomDialogActionButton className={classes.chooseButton} onClick={handleSaveButtonClick}>
                PILIH
              </CustomDialogActionButton>
            </Box>
          </LoadingBox>
        </Stack>
      </Menu>
    </Stack>
  )
}

InputDeviceList.defaultProps = {
  title: '',
  buttonText: '',
  selectedDeviceList: [],
  classNameMenuAdd: '',
  isWithDistance: true,
  isDarkTheme: false,
  type : '',
  disabledInput: false,
}

InputDeviceList.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  selectedDeviceList: PropTypes.array.isRequired,
  setSelectedDeviceList: PropTypes.func.isRequired,
  classNameMenuAdd: PropTypes.string,
  isWithDistance: PropTypes.bool.isRequired,
  isDarkTheme: PropTypes.bool,
  type : PropTypes.oneOf([ 'trackerList', 'patrol', 'evidence' ]).isRequired,
  disabledInput: PropTypes.bool,
  defaultDeviceNos: PropTypes.array
}

export default InputDeviceList
