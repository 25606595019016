import React, { useState } from 'react'

// COMPONENTS
import BackdropDetailMedia from '../BackdropDetailMedia/BackdropDetailMedia'
import DialogChangeStatus from '../DialogChangeStatus/DialogChangeStatus'

// CUSTOM COMPONENTS
import CustomMenuItem from 'components/Customs/CustomMenuItem'
import CustomPaper from 'components/Customs/CustomPaper'
import CustomSelectOutlined from 'components/Customs/CustomSelectOutlined'

// MUIS
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Masonry from '@mui/lab/Masonry'
import Menu from '@mui/material/Menu'
import Typography from '@mui/material/Typography'
import CardMedia from '@mui/material/CardMedia'

// MUI ICONS
import IconBorderColor from '@mui/icons-material/BorderColor'
import IconChat from '@mui/icons-material/Chat'
import IconDelete from '@mui/icons-material/Delete'
import IconMoreVert from '@mui/icons-material/MoreVert'

// STYLES
import useStyles from './mediaSectionUseStyles'

// USEHOOKS
import { useElementSize } from 'usehooks-ts'

// UTILS
import { 
  getStringAvatar, 
  getBackgroundAvatar,
  capitalizeEachWord
} from 'utilities/index'

// MOMENT
import moment from 'moment'

// CONSTAN
import { colors } from 'constants/colors'

const MediaSection = (props) => {
  const { 
    evidenceDetailObject,
    reloadData, 
  } = props
  
  const classes = useStyles()

  const [ masonryRef, { width: masonryWidth } ] = useElementSize()

  const statusList = [
    {
      text: 'Tanpa Status',
      value: 'NO_STATUS'
    },
    {
      text: 'Tidak Diverifikasi',
      value: 'UNVERIFIED'
    },
    {
      text: 'Diverifikasi',
      value: 'VERIFIED'
    },
    {
      text: 'Proses Verifikasi',
      value: 'ON_VERIFICATION'
    },
  ]

  const initialSelectedStatus = statusList.find(item => item.value === evidenceDetailObject.reviewStatus)

  const [ selectedStatus, setSelectedStatus ] = useState(initialSelectedStatus.value)
  const [ moreMenuAnchor, setMoreMenuAnchor ] = useState(null)
  const [ dialogChangeStatus, setDialogChangeStatus ] = useState(null)
  const [ backdropDetailMedia, setBackdropDetailMedia ] = useState(null)

  const calculateMasonryColumns = (inputWidth) => {
    if(inputWidth <= 250 ) return 1
    else {
      return Math.round(inputWidth / 250)
    }
  }

  const handleStatusChange = (event) => {
    setDialogChangeStatus({
      oldStatus: selectedStatus,
      newStatus: event.target.value, 
      id: evidenceDetailObject.evidenceNo,
    })
  }

  const handleMediaPlayer = (item) => {
    if (item.mediaType === 'IMAGE') return 'img'
    else if (item.mediaType === 'VIDEO' && item.fileType !== 'video/3gp') return 'video'
    else if (item.mediaType === 'AUDIO') return 'audio'
  }

  return (
    <CustomPaper className={classes.mediaRoot}>
      {/* MEDIA HEADER */}
      <Box className={classes.mediaHeader}>
        {/* MEDIA TITLE */}
        <Typography
          variant='h6'
          className={classes.mediaTitle}
        >
          Media
        </Typography>

        {/* STATUS SELECT */}
        <FormControl>
          <CustomSelectOutlined
            value={selectedStatus}
            onChange={handleStatusChange}
            className={classes.mediaSelect}
          >
            {statusList.map((item, index) => (
              <CustomMenuItem
                key={index} 
                value={item.value}
              >
                {item.text}
              </CustomMenuItem>
            ))}
          </CustomSelectOutlined>
        </FormControl>
      </Box>

      <Divider/>

      {/* MEDIA MASONRY */}
      <Box
        className={classes.masonryContainer}
        ref={masonryRef}
      >
        {(evidenceDetailObject && evidenceDetailObject.medias) &&
        <Masonry
          columns={calculateMasonryColumns(masonryWidth)}
          spacing={2}
          className={classes.masonry}
        >
          {evidenceDetailObject.medias.map((item, index) => (
            <CustomPaper
              key={index}
              className={classes.masonryItem}
            >
              {/* MASONRY HEADER */}
              <ListItem 
                disablePadding
              >
                {/* AVATAR */}
                <ListItemAvatar className={classes.masonryAvatarContainer}>
                  <Avatar 
                    className={classes.masonryAvatar}
                    sx={{ backgroundColor: getBackgroundAvatar(evidenceDetailObject.deviceName) }}
                  >
                    {getStringAvatar(evidenceDetailObject.deviceName)}
                  </Avatar>
                </ListItemAvatar>

                {/* TEXTS */}
                <ListItemText
                  // DEVICE NAME
                  primary={evidenceDetailObject.deviceName}
                  // CREATED DATE
                  secondary={moment(item.createdOn).format('DD/MM/YYYY hh:mm a')}
                />

                {/* MORE ICON */}
                <IconMoreVert 
                  className={classes.masonryMoreIcon}
                  onClick={(event) => setMoreMenuAnchor(event.currentTarget)}
                />

                {/* MORE OPTIONS MENU */}
                <Menu
                  anchorEl={moreMenuAnchor}
                  open={Boolean(moreMenuAnchor)}
                  onClose={() => setMoreMenuAnchor(null)}
                  className={classes.moreMenuRoot}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  {/* DOWNLOAD MENU ITEM */}
                  <CustomMenuItem className={classes.moreMenuItem}>
                    Download
                  </CustomMenuItem>

                  {/* PRINT MENU ITEM */}
                  <CustomMenuItem className={classes.moreMenuItem}>
                    Print
                  </CustomMenuItem>
                </Menu>
              </ListItem>

              {/* EVIDENCE STATE STATUS */}
              <Typography 
                className={classes.evidenceStatus}
                sx={{color : item.evidenceState === 'SUBMITTED' ? colors.carnation : colors.fruitSalad}}
              >
                {item.evidenceState === 'SUBMITTED' ? 'Laporan' : 'Penyelesaian'}
              </Typography>

              {/* MANSORY MEDIA */}
              <CardMedia
                component={handleMediaPlayer(item)}
                src={item.mediaUrl}
                autoPlay
                controls
                alt=''
                className={classes.carouselMedia}
                onClick={(event) => {
                  event.stopPropagation()
                  setBackdropDetailMedia(evidenceDetailObject)
                }}
              />

              {/* CAPTION TEXT */}
              <Typography
                variant='subtitle2'
                className={classes.masonryCaption}
              >
                Tidak ada keterangan
              </Typography>

              {/* MASONRY ACTIONS */}
              <Box className={classes.masonryActions}>
                {/* COMMENT ICON */}
                <IconChat className={classes.masonryActionIcon}/>

                {/* EDIT ICON */}
                <IconBorderColor className={classes.masonryActionIcon}/>

                {/* DELETE ICON */}
                <IconDelete className={`${classes.masonryActionIcon} ${classes.masonryActionLast}`}/>
              </Box>
            </CustomPaper>
          ))}
        </Masonry>}
      </Box>

      {/* DIALOG CHANGE STATUS */}
      {dialogChangeStatus &&
      <DialogChangeStatus
        dialogChangeStatus={dialogChangeStatus}
        setDialogChangeStatus={setDialogChangeStatus}
        setSelectedStatus={setSelectedStatus}
        reloadData={reloadData}
      />}

      {/* BACKDROP DETAIL MEDIA */}
      {backdropDetailMedia &&
      <BackdropDetailMedia
        backdropDetailMedia={backdropDetailMedia}
        setBackdropDetailMedia={setBackdropDetailMedia}
      />}
    </CustomPaper>
  )
}

export default MediaSection
