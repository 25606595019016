// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconMotorbikeTwo = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M3.97659 11.5322C5.92978 11.5322 7.55738 12.948 7.88995 14.807H9.31097C8.96602 12.1708 6.70542 10.1287 3.97659 10.1287V8.72515H11.4419C11.7315 7.90826 12.5119 7.32162 13.4269 7.32162H17.0631L16.6803 5.91809H14.1754V4.51465H17.7523L19.6707 11.5487C19.787 11.5384 19.9044 11.5322 20.0233 11.5322C22.2161 11.5322 24 13.3161 24 15.5088C24 17.7015 22.2161 19.4854 20.0234 19.4854C17.8307 19.4854 16.0468 17.7015 16.0468 15.5088C16.0468 13.9267 16.9757 12.5577 18.3166 11.9178L17.7586 9.8718L14.312 16.2106H7.89C7.55747 18.0696 5.92983 19.4854 3.97664 19.4854C1.78392 19.4854 4.72069e-05 17.7015 4.72069e-05 15.5088C3.31876e-07 13.3161 1.78392 11.5322 3.97659 11.5322ZM17.4503 15.5088C17.4503 16.9276 18.6046 18.0819 20.0234 18.0819C21.4422 18.0819 22.5965 16.9276 22.5965 15.5088C22.5965 14.0987 21.4562 12.9503 20.0492 12.9363L20.7005 15.3242L19.3464 15.6935L18.6953 13.3059C17.9497 13.757 17.4503 14.5757 17.4503 15.5088ZM3.97659 18.0819C5.15213 18.0819 6.14531 17.2893 6.45164 16.2106H3.27483V14.807H6.45164C6.14536 13.7283 5.15213 12.9357 3.97659 12.9357C2.55778 12.9357 1.40348 14.09 1.40348 15.5088C1.40348 16.9276 2.55778 18.0819 3.97659 18.0819Z' />
    </SvgIcon>
  )
}

export default IconMotorbikeTwo