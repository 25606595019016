import React, { createContext, useState, useRef } from 'react'

// CONSTANTS
import mapList from 'constants/mapList'

export const PageOverviewDashboardContext = createContext()

const PageOverviewDashboardContextProvider = (props) => {
  // NUMBER
  const [ zoom, setZoom ] = useState(5)

  // OBJECT
  const pageRef = useRef(null)
  const [ map, setMap ] = useState(null)
  const [ selectedMapObject, setSelectedMapObject ] = useState(mapList.mapOptionList[0])

  return (
    <PageOverviewDashboardContext.Provider
      value={{
        // NUMBER
        zoom, setZoom,
        // OBJECT
        pageRef,
        map, setMap,
        selectedMapObject, setSelectedMapObject,
      }}
    >
      {props.children}
    </PageOverviewDashboardContext.Provider>
  )
}

export default PageOverviewDashboardContextProvider