import React from 'react'

// COMPONENTS
import Drawer from 'components/Drawer/Drawer'

// CONSTANTS
import customPrivateTheme from 'constants/customThemePrivate'

// MUIS
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'

// STYLES
import './privateLayout.css'
import '../App.css'
import '../index.css'

export default function PrivateLayout(props) {
  const { 
    children, 
    isYScrollable,
    isWithDrawer, 
  } = props

  return (
    <ThemeProvider theme={customPrivateTheme}>
      <Box
        className='no-zoom'
        sx={{ display: 'flex', width: '100%', height: '100vh' }}
      >
        <CssBaseline />

        {/* DRAWER */}
        {isWithDrawer && <Drawer/>}

        {/* CONTENT */}
        <Box
          className='zoom'
          component='main'
          sx={{ flex: 1, overflow: isYScrollable ? 'auto' : 'hidden' }}
        >
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  )
}
