import React, { useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import InjectAxiosInterceptors from './utilities/InjectAxiosInterceptors'
// import { isNilOrEmpty } from 'ramda-extension'
import { Alert, Snackbar } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  hideAlert,
  // onChangeDevicesStatus,
  // onChangePositions,
} from './store/MainReducer'

// ROUTES
import privateRouteList from 'routes/privateRouteList'
import PrivateRoute from 'components/Routes/PrivateRoute'
import AuthRoute from 'components/Routes/AuthRoute'
import authRouteList from 'routes/authRouteList'

export default function AppRouter() {
  // const gpsURL = 'wss://gps.voc.dev/api/socket'
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState()
  const dispatch = useDispatch()
  const alert = useSelector((state) => state.mainStore.alert)

  // useEffect(() => {
  //   const wsGps = new WebSocket(gpsURL)

  //   wsGps.onopen = () => {
  //     console.log('WSGps Connected')
  //   }

  //   wsGps.onmessage = (e) => {
  //     const data = JSON.parse(e.data)
  //     console.log('message: ', data)
  //     // Change position
  //     if (data.positions) {
  //       dispatch(onChangePositions(data.positions))
  //     }

  //     // Change status
  //     if (data.devices) {
  //       dispatch(onChangeDevicesStatus(data.devices))
  //     }
  //   }

  //   return () => {
  //     if (wsGps) {
  //       wsGps.close()
  //     }
  //   }
  // }, [])

  const handleClose = () => {
    setOpen(false)
  }

  const handleCloseAlert = () => {
    dispatch(hideAlert({}))
  }

  const notificationStyle = {
    width: '100%',
    backgroundColor: '#F54E60',
    fontWeight: '600',
    fontFamily: '"Open Sans","Helvetica Neue","Helvetica",Arial,sans-serif',
  }

  const alertSuccess = {
    width: '100%',
    backgroundColor: '#4CAF50',
    fontWeight: '600',
    fontFamily: '"Open Sans","Helvetica Neue","Helvetica",Arial,sans-serif',
  }

  const getComponent = (matchProps, item) => {
    const { component : Component } = item

    if(item.routeType === 'private') {
      return (
        <PrivateRoute
          isYScrollable={item.isYScrollable}
          isWithDrawer={item.isWithDrawer}
          providerContext={item.providerContext}
        >
          <Component {...matchProps} />
        </PrivateRoute>
      )
    } else if (item.routeType === 'auth') {
      return (
        <AuthRoute>
          <Component {...matchProps} />
        </AuthRoute>
      )
    }
  }

  return (
    <Router>
      <InjectAxiosInterceptors />

      <Switch>
        {/* LIST ROUTE */}
        {[...authRouteList, ...privateRouteList].map((item, index) => (
          <Route
            key={index}
            exact={item.isExact}
            path={item.path}
            render={matchProps => getComponent(matchProps, item)}
          />
        ))}
      </Switch>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleClose}
          severity='error'
          sx={notificationStyle}
          variant='filled'
        >
          <div>{message}</div>
        </Alert>
      </Snackbar>
      
      <Snackbar
        open={alert?.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity='success'
          sx={alertSuccess}
          variant='filled'
        >
          <div>{alert?.message}</div>
        </Alert>
      </Snackbar>
    </Router>
  )
}
