// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconText = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M10.999 21C9.83907 21 8.91465 20.7558 8.2257 20.2674C7.53675 19.7791 7.19227 18.8983 7.19227 17.625C7.19227 17.4157 7.20535 17.189 7.23151 16.9448C7.25768 16.6919 7.29692 16.4302 7.34925 16.1599L8.85361 9.10901H6.86523L7.24459 7.18605L9.80855 6.08721L11.5353 3H14.0731L13.4321 6.11337H17.1341L16.4801 9.10901H12.7911L11.2867 16.1599C11.2606 16.2907 11.2388 16.4215 11.2213 16.5523C11.2126 16.6744 11.2083 16.7747 11.2083 16.8532C11.2083 17.202 11.3129 17.4506 11.5222 17.5988C11.7315 17.7471 12.0106 17.8212 12.3594 17.8212C12.717 17.8212 13.0571 17.782 13.3798 17.7035C13.7112 17.6163 14.0774 17.5029 14.4786 17.3634V20.3067C14.06 20.5073 13.5542 20.673 12.9612 20.8038C12.3769 20.9346 11.7228 21 10.999 21Z' />
    </SvgIcon>
  )
}

export default IconText