// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  dialogColor: {
    '& .MuiPaper-root': {
      width: 248,
      padding: 0,
    }
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: 14,
    padding: 16,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  dialogContent: {
    padding: '16px 12px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    minHeight: 248,
  },
  boxColor: {
    borderWidth: 1.4,
    borderStyle: 'solid',
    borderColor: 'transparent',
    height: 24,
    width: 24,
    borderRadius: 2,
    margin: '0 4px 8px 4px',
    cursor: 'pointer',
    '&.selected': {
      borderColor: theme.palette.common.black,
    }
  },
  textLabel: {
    color: theme.palette.text.secondary,
    fontWeight: 600,
    fontSize: 12,
    marginBottom: 16,
    paddingLeft: 4,
  },
  btnCancel: {
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  btnSave: {
    fontWeight: 600,
  }
}))

export default useStyles
