// MUIS
import FormControl from '@mui/material/FormControl'
import { styled } from '@mui/material/styles'

const CustomFormControl = styled(({ className, ...props }) => (
  <FormControl className={className} {...props} />
))(({ theme }) => ({
  backgroundColor: '#e4e9ec !important',
  transition: 'background-color .4s',
  borderRadius: '4px !important',
  '&:focus-within': {
    backgroundColor: '#D7DDE1 !important'
  },
  '& .MuiInput-root': {
    margin: '40px 4px 4px 4px',
    borderRadius: 2,
  },
  '& .MuiSelect-select': {
    fontSize: 14,
    paddingLeft: 14,
    paddingTop: 32,
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: 16,
      marginLeft: 2,
      marginRight: 12,
    }
  }
}))

export default CustomFormControl
