// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  dialogIcon: {
    '& .MuiPaper-root': {
      width: 264,
      padding: 0,
    }
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: 14,
    padding: 16,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  dialogContent: {
    padding: '18px 12px 10px 12px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    minHeight: 248,
  },
  textLabel: {
    color: theme.palette.text.secondary,
    fontWeight: 600,
    fontSize: 12,
    marginBottom: 18,
    paddingLeft: 4,
  },
  btnCancel: {
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  btnSave: {
    fontWeight: 600,
  },
  boxIcon: {
    cursor: 'pointer',
    height: 40,
    width: 40,
    border: `1px solid ${theme.palette.divider}`,
    margin: '0 4px 8px 4px',
    alignItems: 'center',
    justifyContent: 'center',
    '&.selected': {
      borderColor: theme.palette.common.black,
    }
  },
  iconActivityTypes: {
    color: theme.palette.text.secondary,
  }
}))

export default useStyles
