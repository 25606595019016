// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconImageAdd = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M18 15V18H15V20H18V23H20V20H23V18H20V15H18ZM13.3 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3H19C20.1 3 21 3.9 21 5V13.3C20.4 13.1 19.7 13 19 13C17.9 13 16.8 13.3 15.9 13.9L14.5 12L11 16.5L8.5 13.5L5 18H13.1C13 18.3 13 18.7 13 19C13 19.7 13.1 20.4 13.3 21Z' />
    </SvgIcon>
  )
}

export default IconImageAdd