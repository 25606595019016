// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  popupGeofence: {
    padding: 8, 
  },
  popupGeofenceHeader: {
    marginBottom: 8,
    display: 'flex !important',
    flexDirection: 'row !important',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  popupGeofenceHeaderTitle: {
    fontWeight: 600,
    margin: '0 0 2px 0',
    fontSize: '14px !important',
    color: `${theme.palette.common.black} !important`,
    width: 184,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  popupGeofenceHeaderSubtitle: {
    fontSize: 10,
    display: 'block',
    color: `${theme.palette.common.black} !important`
  },
  popupGeofenceCircleColor: {
    height: 16,
    width: 16,
    borderRadius: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    marginRight: 8,
  },
  popupGeofenceListTags: {
    padding: 0,
    marginTop: 8,
  },
  popupGeofenceListTagsItem: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0,
    }
  },
  popupGeofenceListTagsDesc: {
    flex: 1,
    display: 'block',
    fontSize: '12px !important',
    color: `${theme.palette.common.black} !important`
  },
  popupGeofenceListTagsTotal: {
    flex: 0,
    display: 'block',
    paddingLeft: 8,
    fontSize: '12px !important',
    color: `${theme.palette.common.black} !important`
  }
}))

export default useStyles