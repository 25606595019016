import { useState, useContext, useEffect } from 'react'
import moment from 'moment'

// COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'
import GeofenceList from './GeofenceList/GeofenceList'
import MapChildrenZoom from 'components/PanelZoom/MapChildrenZoom'
import PanelChangeMap from 'components/PanelChangeMap/PanelChangeMap'
import PanelFilters from './PanelFilters/PanelFilters'
import PanelInfo from './PanelInfo/PanelInfo'
import PanelZoom from 'components/PanelZoom/PanelZoom'
import SimpleBackdrop from 'components/Partials/SimpleBackdrop'

// CONTEXTS
import { PageOverviewDashboardContext } from 'contexts/PageOverviewDashboardContext'

// LEAFLET
import { 
  MapContainer, 
  TileLayer, 
} from 'react-leaflet'

// MUIS
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Fade from '@mui/material/Fade'
import Stack from '@mui/material/Stack'

// MUI ICONS
import IconMapOutlined from '@mui/icons-material/MapOutlined'

// SERVICES
import { getEvidenceStatsChart } from 'services/DashboardService'
import { getRole } from 'services/UserService'

// STYLES
import useStyles from './overviewDashboardUseStyles'

const OverviewDashboard = () => {
  const {
    zoom, setZoom,
    pageRef,
    setMap,
    selectedMapObject, setSelectedMapObject,
  } = useContext(PageOverviewDashboardContext)

  const classes = useStyles()

  const minimalZoom = 2
  const maximalZoom = 20

  const viewModeList = [ 'Provinsi', 'Kota/Kab' ]

  const [ isPanelChangeMapOpen, setIsPanelChangeMapOpen ] = useState(false)
  const [ isPanelFiltersOpen, setIsPanelFiltersOpen ] = useState(false)
  const [ selectedViewMode, setSelectedViewMode ] = useState(viewModeList[0])
  const [ selectedProvince, setSelectedProvince ] = useState(null)
  const [ selectedDateRange, setSelectedDateRange ] = useState([
    moment().startOf('week').toDate(), moment().endOf('week').toDate()
  ])
  const [ dataEvidencesProvince, setDataEvidencesProvince ] = useState(null)
  const [ dataEvidencesDistrict, setDataEvidencesDistrict ] = useState(null)
  const [provincesGeoJson, setProvincesGeoJson] = useState(null)
  const [ userGMT, setUserGMT ] = useState(null)
  const [ isLoadingFetching, setIsLoadingFetching ] = useState(true)

  const getUserRoleData = async () => {
    const parseLocalStorage = JSON.parse(localStorage.getItem('user'))
    const data = await getRole(parseLocalStorage?.role.id || 32)
    setUserGMT(Number(data?.timeZone?.replace('GMT+', '')))
  }

  const fetchDataEvidencesProvince = async () => {
    setIsLoadingFetching(true)

    const response = await getEvidenceStatsChart({
      chartType: 'GEOCHART',
      statsName: 'INTENSITY',
      slug: 'indonesia',
      from: moment(selectedDateRange[0]).format('yyyy-MM-DD HH:mm:ss').toString(),
      to: moment(selectedDateRange[1]).format('yyyy-MM-DD HH:mm:ss').toString(),
      timezoneOffset: userGMT
    })
    setDataEvidencesProvince(response)
    setIsLoadingFetching(false)
  }

  const fetchDataEvidencesDistrict = async () => {
    setIsLoadingFetching(true)

    const response = await getEvidenceStatsChart({
      chartType: 'GEOCHART',
      statsName: 'INTENSITY',
      slug: selectedProvince.slug,
      from: moment(selectedDateRange[0]).format('yyyy-MM-DD HH:mm:ss').toString(),
      to: moment(selectedDateRange[1]).format('yyyy-MM-DD HH:mm:ss').toString(),
      timezoneOffset: userGMT
    })
    setDataEvidencesDistrict(response)
    setIsLoadingFetching(false)

  }

  const getLegendListInfo = () => {
    if(selectedViewMode === viewModeList[0] && Boolean(dataEvidencesProvince)) {
      return dataEvidencesProvince.options.colors.map(item => `${item.min} - ${item.max}`)
    } else if (selectedViewMode === viewModeList[1] && Boolean(dataEvidencesDistrict)) {
      return dataEvidencesDistrict.options.colors.map(item => `${item.min} - ${item.max}`)
    } else return []
  }

  useEffect(() => {
    getUserRoleData()
  }, [])

  useEffect(() => {
    if(selectedViewMode === viewModeList[0] && userGMT) {
      fetchDataEvidencesProvince()
    } else if (selectedViewMode === viewModeList[1] && Boolean(selectedProvince) && userGMT) {
      fetchDataEvidencesDistrict()
    }
  }, [selectedDateRange, selectedViewMode, selectedProvince, userGMT])

  return (
    <Stack
      height='100%'
      width='100%'
      flex='1'
      position='relative'
      ref={pageRef}
    >
      {/* MAP */}
      <MapContainer
        center={[-0.7893, 113.9213]}
        className={`${classes.mapContainer} no-zoom`}
        zoomControl={false}
        zoom={zoom}
        whenReady={(mapObject) => setMap(mapObject.target)}
      >
        {/* BASE MAP */}
        <TileLayer
          attribution={selectedMapObject.attribution}
          url={selectedMapObject.url}
          subdomains={selectedMapObject.subdomains}
          minZoom={minimalZoom}
          maxZoom={maximalZoom}
          key={selectedMapObject.name}
        />

        {/* MAP CHILDREN ZOOM */}
        <MapChildrenZoom
          zoom={zoom}
          setZoom={setZoom}
        />

        {/* GEOFENCE LIST */}
        <GeofenceList
          dataEvidencesProvince={dataEvidencesProvince}
          dataEvidencesDistrict={dataEvidencesDistrict}
          selectedProvince={selectedProvince}
          selectedViewMode={selectedViewMode}
          provincesGeoJson={provincesGeoJson}
          setProvincesGeoJson={setProvincesGeoJson}
          viewModeList={viewModeList}
        />
      </MapContainer>

      {/* MAP BUTTON */}
      <ButtonGroup 
        className={classes.mapButtonContainer}
        variant='contained'
      >
        <CustomTooltipBlack
          title='Peta'
          placement='bottom'
        >
          <Button 
            className={`${classes.mapButton} zoom`}
            variant='text'
            onClick={() => setIsPanelChangeMapOpen(current => !current)}
          >
            <IconMapOutlined sx={{ color: isPanelChangeMapOpen ? 'primary' : 'action.active' }}/>
          </Button>
        </CustomTooltipBlack>
      </ButtonGroup>

      {/* CHANGE MAP PANEL */}
      <Stack className={classes.panelChangeMapContainer}>
        <Fade in={isPanelChangeMapOpen}>
          <Stack>
            <PanelChangeMap 
              handleCloseIconClick={() => setIsPanelChangeMapOpen(false)}
              selectedMapObject={selectedMapObject}
              setSelectedMapObject={setSelectedMapObject}
              theme='light'
            />
          </Stack>
        </Fade>
      </Stack>

      {/* ZOOM PANEL */}
      <PanelZoom
        setZoom={setZoom} 
        theme='LIGHT'
        direction='horizontal'
        className={classes.panelZoom}
      />

      {/* LEFT PANEL */}
      {isPanelFiltersOpen ?
        // FILTERS PANEL
        <PanelFilters 
          setIsPanelFiltersOpen={setIsPanelFiltersOpen}
          viewModeList={viewModeList}
          selectedViewMode={selectedViewMode}
          setSelectedViewMode={setSelectedViewMode}
          selectedProvince={selectedProvince}
          setSelectedProvince={setSelectedProvince}
          selectedDateRange={selectedDateRange}
          setSelectedDateRange={setSelectedDateRange}
          provincesGeoJson={provincesGeoJson}
        /> :
        // INFO PANEL
        <PanelInfo
          setIsPanelFiltersOpen={setIsPanelFiltersOpen}
          selectedDateRange={selectedDateRange}
          legendList={getLegendListInfo()}
          viewModeList={viewModeList}
          selectedViewMode={selectedViewMode}
          selectedProvince={selectedProvince}
          userGMT={userGMT}
        />}

      <SimpleBackdrop isLoading={isLoadingFetching} />
    </Stack>
  )
}

export default OverviewDashboard