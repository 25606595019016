import { stringify } from 'query-string'

// UTILITIES
import { http } from 'utilities/http'

export const getTagEvidencesList = async (queryParams) => {
  try {
    const response = await http.get(`/tags?${stringify(queryParams)}`)
    return response.data
  } catch (error) {
    return {
      status: 'error', 
      rows: [],
    }
  }
}

export const getTagEvidencesListSearch = async (queryParams, bodyParams) => {
  try {
    const response = await http.post(`/tags/search?${stringify(queryParams)}`, bodyParams)
    return response.data
  } catch (error) {
    return {
      status: 'error', 
      rows: [],
    }
  }
}

export const postTagEvidencesCreate = async (bodyParams) => {
  try {
    const response = await http.post('/tags', bodyParams)
    return response.data
  } catch (error) {
    return {
      status: 'error',
    }
  }
}

export const putTagEvidencesUpdate = async (bodyParams) => {
  try {
    const response = await http.put('/tags', bodyParams)
    return response.data
  } catch (error) {
    return {
      status: 'error',
    }
  }
}

export const deleteTagEvidencesUpdate = async (inputId) => {
  try {
    const response = await http.delete(`/tags/${inputId}`)
    return response.data
  } catch (error) {
    return {
      status: 'error',
    }
  }
}