// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconFilterDetach = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M14.7301 20.83L17.5801 18L14.7301 15.17L16.1501 13.76L19.0001 16.57L21.8001 13.76L23.2201 15.17L20.4101 18L23.2201 20.83L21.8001 22.24L19.0001 19.4L16.1501 22.24L14.7301 20.83ZM13.0001 19.88C13.0401 20.18 12.9401 20.5 12.7101 20.71C12.6176 20.8027 12.5077 20.8762 12.3868 20.9264C12.2658 20.9766 12.1361 21.0024 12.0051 21.0024C11.8742 21.0024 11.7445 20.9766 11.6235 20.9264C11.5025 20.8762 11.3926 20.8027 11.3001 20.71L7.29013 16.7C7.18107 16.5934 7.09814 16.463 7.04783 16.319C6.99752 16.175 6.9812 16.0213 7.00013 15.87V10.75L2.21013 4.62C2.04774 4.41153 1.97446 4.14726 2.00632 3.88493C2.03817 3.6226 2.17257 3.38355 2.38013 3.22C2.57013 3.08 2.78013 3 3.00013 3H17.0001C17.2201 3 17.4301 3.08 17.6201 3.22C17.8277 3.38355 17.9621 3.6226 17.9939 3.88493C18.0258 4.14726 17.9525 4.41153 17.7901 4.62L13.0001 10.75V19.88ZM5.04013 5L9.00013 10.06V15.58L11.0001 17.58V10.05L14.9601 5H5.04013Z'/>
    </SvgIcon>
  )
}

export default IconFilterDetach