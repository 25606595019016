import { useState, useEffect } from 'react'

// COMPONENTS
import TabInformation from './TabInformation'

// MUIS
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useTheme } from '@mui/material/styles'

// SERVICES
import { getDevice } from 'services/DeviceService'

// STYLES
import useStyles from './mobileAppDetailUseStyles'

// UTILITIES
import { getConnectionStatusText } from 'utilities'

const MobileAppDetail = (props) => {
  const { 
    initialDeviceDetail,
    deviceId, 
    reloadData,
    closeFlyout,
  } = props

  const classes = useStyles()

  const tabList = [
    {
      value: 0,
      label: 'Informasi',
    },
  ]

  const [ selectedTab, setSelectedTab ] = useState(tabList[0].value)
  const [ deviceDetail, setDeviceDetail ] = useState(null)

  const theme = useTheme()

  const getDeviceDetailInfo = async () => {
    const resultDeviceDetail = await getDevice(deviceId)
    
    if (resultDeviceDetail) setDeviceDetail({
      ...resultDeviceDetail,
      status: initialDeviceDetail?.state?.connection_status,
      connection_status: getConnectionStatusText(initialDeviceDetail?.state?.connection_status),
      deviceOSVersion: initialDeviceDetail?.deviceOSVersion,
    })
  }

  useEffect(() => {
    (deviceId || deviceId === 0) && getDeviceDetailInfo()
  }, [deviceId, reloadData])

  return (
    <Stack 
      position='relative'
      maxHeight='100%'
    >
      {/* HEADER */}
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        borderBottom={`1px solid ${theme.palette.divider}`}
        position='absolute'
        width='100%'
        zIndex={1}
      >
        {/* TABS */}
        <Tabs 
          value={selectedTab} 
          onChange={(event, newValue) => setSelectedTab(newValue)}
        >
          {tabList.map((item, index) => (
            <Tab 
              key={index}
              label={item.label}
              className={classes.headerTab}
            />
          ))}
        </Tabs>
      </Stack>

      {/* EVIDENCE LIST */}
      {selectedTab === 0 &&
      <TabInformation 
        deviceDetail={deviceDetail}
        reloadData={reloadData}
        closeFlyout={closeFlyout}
      />}
    </Stack>
  )
}

export default MobileAppDetail