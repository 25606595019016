import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// MUIS
import Fab from '@mui/material/Fab'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconAdd from '@mui/icons-material/Add'
import IconBattery50 from '@mui/icons-material/Battery50'
import IconCircle from '@mui/icons-material/Circle'
import IconInfo from '@mui/icons-material/Info'
import IconLocationOn from '@mui/icons-material/LocationOn'

// SERVICES
import { getAddressByLatLong } from 'services/TrackingServices'
import { getEvidences } from 'services/EvidenceService'
import { getRole } from 'services/UserService'
import { gets } from 'services/DeviceService'

// STYLES
import useStyles from './tabObjectDetailUseStyles'

// UTILS
import { getConnectionStatusColor, getConnectionStatusText } from 'utilities'

const TabObjectDetail = () => {
  const classes = useStyles()
  const {
    objectList, objectDetail, setObjectDetail,
    setUserEvidenceList, setIsDialogAddEvidenceShown
  } = useContext(PageTrackingContext)

  const [infoAddress, setInfoAddress] = useState('Alamat tidak ditemukan')
  const [userTimeZone, setUserTimeZone] = useState('GMT+07:00')
  const [userLastTime, setUserLastTime] = useState('')
  const [originalTime, setOriginalTime] = useState(null)
  const [deviceNo, setDeviceNo] = useState(0)

  const infoModel = objectDetail.source?.model ? objectDetail.source?.model : '-'
  const infoOnlineStatus = objectDetail.state?.connection_status ? getConnectionStatusText(objectDetail.state?.connection_status) : 'Status online tidak ditemukan'
  const infoBatteryLevel = objectDetail.state?.battery_level ? `${objectDetail.state?.battery_level}%` : 'null%' 
  const infoLatitude = objectDetail.state?.gps?.location?.lat ? objectDetail.state?.gps?.location?.lat : null
  const infoLongitude = objectDetail.state?.gps?.location?.lng ? objectDetail.state?.gps?.location?.lng : null
  const infoAltitude = objectDetail.state?.gps?.alt ? objectDetail.state?.gps?.alt : null

  /**
   * CALCULATE LAST TIME BY GMT
   * @param object objectDetailParam
   * @param string gmtOffsetParam - user gmt
   * */
  const calculateRelativeTime = (objectDetailParam, gmtOffsetParam) => {
    /**
     * convert last current time to milisecond
     * then if it gmt+7 (- 3600000), when it gmt+9 (+ 3600000)
     */
    let convertToMili = new Date(objectDetailParam.state.last_update).getTime()
    setOriginalTime(moment(convertToMili).format('DD/MM/YYYY hh:mm a'))

    return moment.utc(new Date(objectDetailParam.state.last_update).getTime()).fromNow()
  }

  const getLatitudeLongitudeAndAltitudeText = (inputLatitude, inputLongitude, inputAltitude) => {
    if (inputLatitude && inputLongitude && inputAltitude)
      return `${inputLatitude}, ${inputLongitude} (ketinggian ${inputAltitude} meters)`
    else if (inputLatitude && inputLongitude && !inputAltitude)
      return `${inputLatitude}, ${inputLongitude} (ketinggian tidak ditemukan)`
    else return 'Lokasi dan ketinggian tidak ditemukan'
  }

  const loadAddressData = async () => {
    if (infoLatitude && infoLongitude) {
      const resultAddress = await getAddressByLatLong({
        lat: infoLatitude,
        lng: infoLongitude,
      })
      setInfoAddress(resultAddress.value)
    } else setInfoAddress('Alamat tidak ditemukan')
  }

  const loadUserEvidenceData = async () => {
    const response = await getEvidences({
      size: 2000,
    }, {
      deviceName: objectDetail?.deviceVehicleLable ?? objectDetail?.label
    })

    if (response?.status !== 'error') {
      setUserEvidenceList(response.rows)
    } else {
      setUserEvidenceList([])
    }
  }

  const findDeviceNoFromObject = async () => {
    const response = await gets({ size: 8000 })
    const findId = response.rows.find(item => item?.deviceObjectId === objectDetail.id)
    setDeviceNo(findId ? findId?.deviceNo : null)
  }

  useEffect(() => {
    loadAddressData()
    loadUserEvidenceData()
    findDeviceNoFromObject()
  }, [objectDetail])

  /* GET USER TIMEZONE */
  useEffect(() => {
    const getUserRoleData = async () => {
      const parseLocalStorage = JSON.parse(localStorage.getItem('user'))
      const data = await getRole(parseLocalStorage?.role.id || 32)
      setUserTimeZone(data.timeZone)
    }

    getUserRoleData()
  }, [])

  /* SET USER LAST TIME UPDATE */
  useEffect(() => {
    if (objectDetail.state?.last_update && objectDetail.state?.last_update !== '' && userTimeZone) {
      const lastCurrentTimes = objectDetail.state?.last_update
        ? calculateRelativeTime(objectDetail, userTimeZone)
        : 'Tidak ada pembaruan'

      setUserLastTime(lastCurrentTimes)
    }
    else setUserLastTime('Tidak ada pembaruan')
  }, [objectDetail, userTimeZone])

  // UPDATE OBJECT DETAIL EVERYTIME THE WEBSOCKET UPDATED
  useEffect(() => {
    if (objectDetail) {
      const newObjectDetail = objectList.find(item => item.id === objectDetail.id)
      if (newObjectDetail) setObjectDetail(newObjectDetail)
    }
  }, [objectList])

  return (
    <>
      <Stack padding='16px' position='relative'>
        {/* MODEL */}
        <Stack className={classes.itemDetail} marginBottom='12px'>
          <IconInfo className={classes.labelIcon} fontSize='small' />

          <Stack paddingLeft='4px'>
            <Typography variant='caption' className={classes.labelTitle}>Model: {infoModel}</Typography>

            <Typography className={classes.textDesc} variant='caption'>ID: {objectDetail.source?.id}</Typography>
          </Stack>
        </Stack>

        {/* STATUS */}
        <Stack className={classes.itemDetail} marginBottom='12px'>
          <IconCircle
            className={classes.iconCircleStatus}
            sx={{ color: getConnectionStatusColor(infoOnlineStatus) }}
          />

          <Stack paddingLeft='4px'>
            <Typography variant='caption' className={classes.labelTitle}>{infoOnlineStatus}</Typography>

            <Stack direction='row' alignItems='center'>
              <Typography className={classes.textDesc} variant='caption'>Berhenti {userLastTime}</Typography>

              <CustomTooltipBlack
                title={originalTime || '-'} 
                arrow
                placement='top'
              >
                <IconInfo className={classes.iconInfoDate}/>
              </CustomTooltipBlack>
            </Stack>
          </Stack>
        </Stack>

        {/* BATTERY */}
        <Stack className={classes.itemDetail} marginBottom='12px'>
          <IconBattery50 fontSize='small' className={classes.detailListIcon} />

          <Stack paddingLeft='4px'>
            <Typography variant='caption' className={classes.labelTitle}>Level Baterai: {infoBatteryLevel}</Typography>

            <Stack direction='row' alignItems='center'>
              <Typography className={classes.textDesc} variant='caption'>Berhenti {userLastTime}</Typography>

              <CustomTooltipBlack
                title={originalTime || '-'} 
                arrow
                placement='top'
              >
                <IconInfo className={classes.iconInfoDate}/>
              </CustomTooltipBlack>
            </Stack>
          </Stack>
        </Stack>

        {/* LOCATION */}
        <Stack className={classes.itemDetail} marginBottom='12px'>
          <IconLocationOn className={classes.labelIcon} fontSize='small' />

          <Stack paddingLeft='4px'>
            <Typography marginBottom='4px' variant='caption' className={classes.labelTitle}>{infoAddress}</Typography>

            <Typography marginBottom='4px' className={classes.textDesc} variant='caption'>
              {getLatitudeLongitudeAndAltitudeText(
                infoLatitude,
                infoLongitude,
                infoAltitude
              )}
            </Typography>
          
            <Typography marginBottom='4px' className={classes.textDesc} variant='caption'>
              Kecepatan: {objectDetail.state?.gps?.speed ||
              objectDetail.state?.gps?.speed === 0
                ? `${Number(objectDetail.state?.gps?.speed).toFixed(0)} km/h`
                : 'Kecepatan tidak ditemukan'}
            </Typography>

            <Stack direction='row' alignItems='center'>
              <Typography className={classes.textDesc} variant='caption'>Diperbarui {userLastTime}</Typography>

              <CustomTooltipBlack
                title={originalTime || '-'} 
                arrow
                placement='top'
              >
                <IconInfo className={classes.iconInfoDate}/>
              </CustomTooltipBlack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <CustomTooltipBlack
        title='Tambah Penugasan'
        placement='top'
      >
        <Fab
          color='primary'
          className={classes.addDispatch}
          onClick={() => setIsDialogAddEvidenceShown({
            deviceNos: deviceNo ? [deviceNo] : [],
            address: {
              fullAddress: infoAddress,
              latitude: parseFloat(infoLatitude),
              longitude: parseFloat(infoLongitude),
            }
          })}
        >
          <IconAdd />
        </Fab>
      </CustomTooltipBlack>
    </>
  )
}

export default TabObjectDetail