// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconRadiationCrime = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M12.0003 2.11768C10.4084 2.11768 8.90204 2.47256 7.54767 3.10099C7.07347 3.3217 6.89064 3.90317 7.15123 4.3561L10.2345 9.69681C10.7565 9.39456 11.3541 9.20862 12.0003 9.20862C12.6464 9.20862 13.244 9.39544 13.7661 9.69681L16.8493 4.35437C17.1108 3.90232 16.9279 3.32081 16.4546 3.10099C15.0994 2.47256 13.5922 2.11768 12.0003 2.11768ZM12.0003 10.9813C11.0208 10.9813 10.2275 11.7746 10.2275 12.7541C10.2275 13.7335 11.0208 14.5268 12.0003 14.5268C12.9797 14.5268 13.773 13.7335 13.773 12.7541C13.773 11.7746 12.9797 10.9813 12.0003 10.9813ZM2.30218 12.7541C1.77479 12.7541 1.36883 13.2109 1.41581 13.7357C1.55054 15.2194 1.99446 16.6975 2.78864 18.0723C3.58283 19.447 4.64017 20.5711 5.85804 21.4291C6.28881 21.7322 6.88798 21.6085 7.15123 21.1521L10.2362 15.8114C9.17611 15.198 8.45481 14.0659 8.45481 12.7541H2.30218ZM15.5458 12.7541C15.5458 14.0659 14.8253 15.198 13.7661 15.8114L16.8493 21.1521C17.1126 21.6085 17.7118 21.7322 18.1425 21.4291C19.3604 20.5711 20.4177 19.447 21.2119 18.0723C22.0061 16.6975 22.4509 15.2194 22.5848 13.7357C22.6326 13.2109 22.2249 12.7541 21.6984 12.7541H15.5458Z'/>
    </SvgIcon>
  )
}

export default IconRadiationCrime