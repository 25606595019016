import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

// COMPONENTS
import DialogAddOrEdit from 'components/DialogAddOrEditNew/DialogAddOrEdit'
import SimpleBackdrop from 'components/Partials/SimpleBackdrop'

// CONSTANTS
import { colorsCst } from 'constants/values'
import { basePaths } from 'constants/paths'

// CUSTOM COMPONENTS
import CustomInput from 'components/Customs/CustomInput'
import CustomInputLabel from 'components/Customs/CustomInputLabel'

// MUIS
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
import Popover from '@mui/material/Popover'

// MUI ICONS
import IconFormatColorText from '@mui/icons-material/FormatColorText'

// STORES
import { showAlert } from 'store/MainReducer'
import { isEmpty } from 'ramda'
import { toast } from 'utilities'

// SERVICES
import { createGroup, updateGroup } from 'services/MasterDataService'

// STYLES
import useStyles from './dialogAddOrEditGroupsUseStyles'

const DialogAddOrEditGroups = (props) => {
  const {
    dialogType,
    dialogAddOrEditGroups,
    setDialogAddOrEditGroups,
    pageRef,
    reloadData,
  } = props

  const classes = useStyles()
  const dispatch = useDispatch()

  const history = useHistory()
  const location = useLocation()

  const [groupName, setGroupName] = useState('')
  const [groupColor, setGroupColor] = useState('#000')
  const [isLoading, setIsLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDialogParamsChange = (event) => {
    setGroupName(event.target.value)
  }

  const openPicker = Boolean(anchorEl)
  const id = openPicker ? 'simple-popover' : undefined

  const pickerStyle = {
    width: '18px',
    height: '18px',
    cursor: 'pointer',
  }

  const onSelectColor = (color) => {
    setGroupColor(color)
    handleClose()
  }

  const closeDialog = () => {
    setGroupName('')
    setGroupColor('#000')
    setDialogAddOrEditGroups(false)
  }

  const handleSaveButtonClick = async () => {
    if (isEmpty(groupName)) {
      toast({ title: 'Silahkan masukan nama kelompok', icon: 'error' }).then(() => {})
      return
    }

    setIsLoading(true)

    const params = {
      groupName,
      groupColor,
    }

    if(dialogType === 'add') {
      const resultGroups = await createGroup(params)
      if (resultGroups.status !== 'error') dispatch(showAlert({ message: 'Sukses Membuat Kelompok' }))
    } else if(dialogType === 'edit') {
      const resultGroups = await updateGroup(dialogAddOrEditGroups?.row?.groupNo, {
        groupNo: dialogAddOrEditGroups?.row?.groupNo,
        ...params
      })
      if (resultGroups.status !== 'error') dispatch(showAlert({ message: 'Sukses Mengubah Nama Kelompok' }))
    }

    setIsLoading(false)
    closeDialog()
    reloadData()
  }

  // SIDE EFFECT EDIT MODE
  useEffect(() => {
    if(dialogType === 'edit' && Boolean(dialogAddOrEditGroups)) {
      setGroupColor(dialogAddOrEditGroups?.row?.groupColor)
      setGroupName(dialogAddOrEditGroups?.row?.groupName)
    }
  }, [dialogType, dialogAddOrEditGroups])

  useEffect(() => {
    if (
      location.pathname === basePaths.settingsGroups && 
      location.search !== '?mode=add' &&
      !location.search.includes('?mode=edit')
    ) {
      closeDialog()
    }
  }, [location])

  let selectedTitle = ''
  if (dialogType === 'add') selectedTitle = 'Tambah Kelompok'
  else if (dialogType === 'edit') selectedTitle = 'Ubah Kelompok'

  return (
    <>
      {/* DIALOG ADD OR EDIT */}
      <DialogAddOrEdit
        open={Boolean(dialogAddOrEditGroups)}
        title={selectedTitle}
        onCloseIconClick={() => history.push(basePaths.settingsGroups)}
        containerRef={pageRef}
        contents={[
          <>
            {/* MAIN SECTION TEXT */}
            <Typography variant='h6' className={classes.sectionText}>
              Informasi Utama
            </Typography>

            {/* LABEL INPUT */}
            <Box className={classes.iconAndFormControlContainer}>
              <IconFormatColorText className={classes.iconFormControl} />
              <FormControl variant='standard' className={classes.formControl}>
                <CustomInputLabel>Nama Kelompok</CustomInputLabel>
                <CustomInput
                  type='text'
                  name='label'
                  value={groupName}
                  onChange={handleDialogParamsChange}
                  endAdornment={
                    <InputAdornment position='end'>
                      <Box
                        style={{ ...pickerStyle, backgroundColor: groupColor }}
                        onClick={handleClick}
                      />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
          </>
        ]}
        onCancelButtonClick={() => history.push(basePaths.settingsGroups)}
        onSaveButtonClick={handleSaveButtonClick}
      />

      {/* COLOR PICKER POPOVER */}
      <Popover
        id={id}
        open={openPicker}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          horizontal: 'right',
        }}
        sx={{
          mt: 1,
        }}
      >
        <Box className={'color-wrap'}>
          {colorsCst.map((item, index) => {
            return (
              <Box
                key={index}
                className={'item-color'}
                onClick={() => onSelectColor(item)}
                style={{ backgroundColor: item }}
              />
            )
          })}
        </Box>
      </Popover>

      {/* LOADING */}
      <SimpleBackdrop isLoading={isLoading} />
    </>
  )
}

export default DialogAddOrEditGroups
