// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  iconClose: {
    marginRight: 16,
    cursor: 'pointer',
  },
  titleText: {
    fontWeight: 600,
    fontSize: 24,
  },
  sectionText: {
    marginBottom: 8,
    fontWeight: 600,
  },
  evidenceStatusContainer: {
    marginTop: 28,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  evidenceStatusToggleButtonGroup: {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
  },
  evidenceStatusToggleButton: {
    fontSize: 12,
    height: 32,
    fontWeight: 500,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      fontWeight: 600,
      border: 'none',
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.main,
      filter: 'brightness(0.96)',
    },
  },
  iconAndFormControlContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    height: 65,
    width: '100%',
    flex: '0 0 65px',
  },
  iconFormControl: {
    marginRight: 12,
    marginBottom: 2,
    color: colors.raven,
  },
  formControl: {
    width: '100%',
  },
  dropDownIcon: {
    color: colors.boulder,
  },
  menu: {
    transform: 'translate(-16px, -16px)',
    '& .MuiPaper-root': {
      width: 'calc(500px - 48px - 48px)',
      maxHeight: 500,
    },
    '& .MuiMenu-list': {
      padding: 0,
    },
  },
  menuSearch: {
    padding: 16,
  },
  menuSearchInput: {
    width: '100%',
    fontWeight: 600,
    paddingBottom: 4,
    '&::-webkit-input-placeholder': {
      color: colors.boulder,
    },
  },
  menuSearchIcon: {
    marginRight: 4,
  },
  menuItemContainer: {
    width: '100%',
    overflow: 'auto',
    maxHeight: 'calc(500px - 65px)', // 65 is from the height of the search Input container component
  },
  menuItem: {
    height: 48,
    paddingLeft: 20,
  },
  menuItemSelected: {
    backgroundColor: alpha(colors.clementine, 0.1),
  },
  formControlEvidenceLocationAdorment: {
    cursor: 'pointer',
  },
  evidenceLocationSuggestionsItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.athensGray,
    },
  },
  iconRadiusDropDown: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.25s ease-in-out',
  },
  iconRadiusDropUp: {
    transform: 'rotate(180deg)',
    transition: 'transform 0.25s ease-in-out',
  },
  partrolLocationRadiusMenu: {
    '& .MuiMenu-list': {
      width: 192,
    },
  },
  evidenceLocationRadius: {
    width: '50%',
  },
  evidenceLocationSlider: {
    width: '50%',
    marginBottom: -10,
    marginLeft: 20,
    marginTop: 30,
  },
  deviceText: {
    marginTop: 40,
    marginBottom: 12,
  },
  deviceButton: {
    width: 'fit-content',
  },
  cancelButton: {
    color: colors.raven,
    '&:hover': {
      backgroundColor: alpha(colors.raven, 0.1),
    },
  },
  autocomplete: {
    width: '100%',
    fontSize: 12,
    '& .MuiChip-root': {
      backgroundColor: alpha(colors.clementine, 0.1),
      color: colors.clementine,
      fontSize: 12,
      fontWeight: 600,
      textTransform: 'capitalize',
      lineHeight: '24px',
    },
    '& .MuiInputLabel-root': {
      fontSize: 14,
    },
    '& .MuiChip-deleteIcon': {
      color: 'unset',
      height: '20px',
      width: '20px',
      '&:hover': {
        color: 'unset',
      },
    },
  },
}))

export default useStyles
