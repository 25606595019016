export const regionalClusters = [
  {
    province: 'bali',
    color: '#ED6237',
    policeRegional: 'POLDA BALI',
  },
  {
    province: 'banten',
    color: '#E98919',
    policeRegional: 'POLDA BANTEN',
  },
  {
    province: 'yogyakarta',
    color: '#964B00',
    policeRegional: 'POLDA DI YOGYAKARTA',
  },
  {
    province: 'jawabarat',
    color: '#1E4D2B',
    policeRegional: 'POLDA JABAR',
  },
  {
    province: 'jawatengah',
    color: '#67AC5B',
    policeRegional: 'POLDA JATENG',
  },
  {
    province: 'jawatimur',
    color: '#419488',
    policeRegional: 'POLDA JATIM',
  },
  {
    province: 'kalimantanbarat',
    color: '#043F79',
    policeRegional: 'POLDA KALBAR',
  },
  {
    province: 'kalimantanselatan',
    color: '#0000FF',
    policeRegional: 'POLDA KALSEL',
  },
  {
    province: 'kalimantanutara',
    color: '#4496D6',
    policeRegional: 'POLDA KALTARA',
  },
  {
    province: 'kalimantantengah',
    color: '#4153AF',
    policeRegional: 'POLDA KALTENG',
  },
  {
    province: 'kalimantantimur',
    color: '#6040B0',
    policeRegional: 'POLDA KALTIM',
  },
  {
    province: 'nusatenggarabarat',
    color: '#D73964',
    policeRegional: 'POLDA NTB',
  },
  {
    province: 'jakartaraya',
    color: '#8B0000',
    policeRegional: 'POLDA METRO JAYA',
  },
  {
    province: 'other',
    color: '#878085',
    policeRegional: 'LAINNYA',
  }
]

export const findRegionalBySlug = (slug) => regionalClusters.find(item => item.province === slug)
export const findRegionalByPoliceRegional = (policeRegional) => regionalClusters.find(item => item.policeRegional === policeRegional)

export const markersLabelShowHide = (markerClustersRef, markerSettings) => {
  if (!markerClustersRef) return
  markerClustersRef?._map
    ?.getContainer()
    ?.getElementsByClassName('labelMarkerObject')
    ?.forEach(element => {
      element.style.display = markerSettings?.isLabelShown ? 'block' : 'none'
    })
}

export const filterDataIsHaveSlug = (inputData, type) => {
  let filtered
  if (type === 'evidences') filtered = inputData.filter(itemEvidence =>Object.keys(itemEvidence).includes('slug'))
  else if (type === 'objects') filtered = inputData.filter(itemObject => Object.keys(itemObject?.state?.gps).includes('slug'))
  else if (type === 'dispatches') filtered = inputData.filter(itemDisptach => Object.keys(itemDisptach?.address).includes('slug'))

  return filtered
}

export const filterDataByProvince = (inputData, listProvince, type) => {
  let filtered
  const filteredDataSlug = filterDataIsHaveSlug(inputData, type)

  if (type === 'evidences') filtered = listProvince.map(itemProvince => {
    if (itemProvince.province === 'other') {
      return {
        ...itemProvince,
        data: filteredDataSlug.filter(itemEvidence => {
          const splitSlug = itemEvidence?.slug?.toLowerCase()?.split('-')
          const result = Boolean(listProvince.find(itemFindProvince => itemFindProvince.province === splitSlug[1]))
          return !result
        })
      }
    }

    return {
      ...itemProvince,
      data: filteredDataSlug.filter(itemEvidence => itemEvidence?.slug?.split('-')[1]?.toLowerCase()?.includes(itemProvince.province))
    }
  })
  else if (type === 'objects') filtered = listProvince?.map(itemProvince => {
    if (itemProvince.province === 'other') {
      return {
        ...itemProvince,
        data: filteredDataSlug
          .filter(itemObject => itemObject.state.gps.location?.lat && itemObject.state.gps.location?.lng ? true : false)
          .filter(itemObject => {
            const splitSlug = itemObject?.state?.gps?.slug?.toLowerCase()?.split('-')
            const result = Boolean(listProvince.find(itemFindProvince => itemFindProvince.province === splitSlug[1]))
            return !result
          })
      }
    }

    return {
      ...itemProvince,
      data: filteredDataSlug.filter(itemObject => itemObject.state.gps.location?.lat && itemObject.state.gps.location?.lng ? true : false).filter(itemObject => itemObject?.state?.gps?.slug?.split('-')[1]?.toLowerCase()?.includes(itemProvince.province))
    }
  })
  else if (type === 'dispatches') filtered = listProvince?.map(itemProvince => {
    if (itemProvince.province === 'other') {
      return {
        ...itemProvince,
        data: filteredDataSlug
          .filter(itemDispatch => itemDispatch.address?.latitude && itemDispatch.address?.longitude ? true : false)
          .filter(itemDispatch => {
            const splitSlug = itemDispatch?.address?.slug?.toLowerCase()?.split('-')
            const result = Boolean(listProvince.find(itemFindProvince => itemFindProvince.province === splitSlug[1]))
            return !result
          })
      }
    }

    return {
      ...itemProvince,
      data: filteredDataSlug.filter(itemDispatch => itemDispatch.address?.latitude && itemDispatch.address?.longitude ? true : false).filter(itemDispatch => itemDispatch?.address?.slug?.split('-')[1]?.toLowerCase()?.includes(itemProvince.province))
    }
  })

  return filtered
}

export const joinAllLayers = (clusterRef) => {
  let listLayers = []
  regionalClusters.forEach(itemProvince => {
    if (clusterRef.current[itemProvince.province]?.getLayers()?.length) {
      listLayers = [...listLayers, ...clusterRef.current[itemProvince.province]?.getLayers()]
    }
  })
  return listLayers
}