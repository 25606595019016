import moment from 'moment'

const valuesPageAnalytics = {
  minZoom: 2,
  maxZoom: 20,
  panelRightWidth: 480, // px
  defaultMapCenter: [ -0.7893, 113.9213 ],
  defaultMapZoom: 5,
  defaultParamsFilter: {
    date: [moment().startOf('day').toISOString(), moment().endOf('day').toISOString()],
    location: {
      latLng: [],
      name: 'Seluruh Wilayah',
      type: 'global'
    },
    // isNeedHelp: true,
    // isCompleted: true,
    tagEvidences: [],
    devices: []
  }
}

export default valuesPageAnalytics