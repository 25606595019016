// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  drawerRoot: {
    '& .MuiDrawer-paper': {
      backgroundColor: colors.brightGray,
      color: 'white',
      '&::-webkit-scrollbar-thumb': {
        width: 5,
        height: 5,
        backgroundColor: alpha('#FFFFFF', 0.24),
      },
      '&::-webkit-scrollbar-track': {
        width: 5,
        height: 5,
        backgroundColor: colors.brightGray,
      },
    },
  },
  drawerHeader: {
    display: 'flex',
    padding: 0,
    justifyContent: 'center',
    position: 'relative',
  },
  toggleIconOnOpen: {
    position: 'absolute',
    left: 18,
    transform: 'rotateY(0deg)',
    transition: 'transform 0.25s ease-in-out',
  },
  toggleIconOnClose: {
    position: 'absolute',
    left: 18,
    transform: 'rotateY(180deg)',
    transition: 'transform 0.25s ease-in-out',
  },
  companyLogoImage: {
    height: 28,
    marginRight: 10,
  },
  companyLogoText: {
    fontWeight: 600,
  },
  hidden: {
    display: 'none',
  },
  listItemHovered: {
    '&:hover': {
      backgroundColor: 'slategray',
    },
  },
  listItem: {
    position: 'relative',
    height: 48,
    paddingLeft: 24,
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
    '& .MuiListItemText-root': {
      color: 'white',
    },
  },
  listItemSelected: {
    backgroundColor: colors.clementine,
  },
  listItemBadge: {
    '& .MuiBadge-badge': {
      backgroundColor: colors.redRibbon,
      right: -4,
      top: 4,
      color: 'white',
    },
  },
  listItemChildren: {
    paddingLeft: 56,
  },
  extraList: {
    height: 'fit-content',
    width: 175,
    backgroundColor: colors.ebonyClay,
    zIndex: 2,
  },
}))

export default useStyles