// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  conversationItem: {
    padding: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  conversationAvatar: {
    minWidth: 'auto',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 8
  },
  conversationText: {
    margin: 0,
    '& .MuiTypography-root': {
      fontSize: 12
    }
  }
}))

export default useStyles