// MUI STYLES
import { makeStyles } from '@mui/styles'

// CONSTANT
import {colors} from 'constants/colors'

const useStyles = makeStyles((theme) => ({
  pageRoot : {
    width : '100%',
    height : 160,
    display : 'flex',
    position : 'relative',
    marginBottom : 16,
  },
  contentContainer : {
    width : '100%',
    height : '100%',
    borderRadius : '10px !important',
    objectFit : 'cover',
    backgroundColor : colors.shark,
    '& .agora_video_player' : {
      borderRadius : 10,
    },
    '& div' : {
      borderRadius : 10,
      backgroundColor : `${colors.shark} !important`
    }
  },
  userName : {
    position : 'absolute',
    display : 'flex',
    justifyContent : 'center',
    alignItems : 'center',
    width : '80%',
    left : '10%',
    bottom : 5,
    height : 25,
    borderRadius : 41,
    backgroundColor : 'rgba(0, 0, 0, 0.3)'
  },
  userNameText : {
    color : theme.palette.default.main,
    fontSize : 12,
    fontWeight : 400,
  }
}))

export default useStyles
