import React, { useContext } from 'react'

// CONSTANTS
import { iconListTagEvidences } from 'constants/icons'
import { panelsInfoStatic } from './panelInformationIntegratedConstant'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// MUIS
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

// MUI ICONS
import IconLocationOn from '@mui/icons-material/LocationOn'
import IconPhoneAndroid from '@mui/icons-material/PhoneAndroid'
import IconSchedule from '@mui/icons-material/Schedule'
import IconSos from '@mui/icons-material/Sos'

// PATH ICONS
import IconTagLabel from 'assets/images/pathIcons/IconTagLabel'
import IconSprint from 'assets/images/pathIcons/IconSprint'

// STYLES
import useStyles from './tabEvidencesUseStyles'

// UTILS
import { dateFormat } from 'utilities'
import { getEvidenceStatusColor } from 'utilities/colors'
import { joinAllLayers } from '../Clusters/clustersUtils'

const TabEvidences = (props) => {
  const { evidenceList, currentPanel } = props
  const classes = useStyles()
  const theme = useTheme()
  const { setIsPanelLeftShown, map, markerClustersRef, markerSettings, markerClustersLocationRef } = useContext(PageTrackingContext)

  const getTagIcon = (iconObject) => {
    const SelectedIconObject = iconListTagEvidences.find(item => item.id === iconObject.iconId) 
    let SelectedIcon = SelectedIconObject.Icon
        
    return (
      <SelectedIcon
        fontSize='small'
        sx={{ color: `${iconObject.tagColor} !important` }}
      />
    )
  }

  const handleItemClick = (inputItem) => {
    setIsPanelLeftShown({
      ...panelsInfoStatic.panelEvidenceDetail,
      data: inputItem,
      currentPanel,
    })
    map.flyTo({lat: parseFloat(inputItem.latitude), lng: parseFloat(inputItem.longitude)}, 14)
  }

  const handleItemTooltip = (inputItem, isOn) => {
    if (markerSettings?.isClusteringLocation) {
      const listLayers = joinAllLayers(markerClustersLocationRef)
      const findInstance = listLayers.find(item => item.options.objectData.evidenceNo === inputItem.evidenceNo)
    
      if(findInstance && isOn === 'over') findInstance?.openTooltip()
      else if(findInstance && isOn === 'leave') findInstance?.closeTooltip()
      return
    }

    if (!markerClustersRef.current) return null
    const listLayers = markerClustersRef.current.getLayers()
    const findInstance = listLayers.find(item => item.options.objectData.evidenceNo === inputItem.evidenceNo)
    
    if(findInstance && isOn === 'over') findInstance?.openTooltip()
    else if(findInstance && isOn === 'leave') findInstance?.closeTooltip()
  }

  return (
    <>
      <Divider />
        
      <Stack overflow='hidden'>
        <Stack className={classes.root}>
          {evidenceList?.length ? evidenceList.map((item, index) => (
            <React.Fragment key={index}>
              <Stack
                dataid={`${item.evidenceNo}`}
                className={classes.itemDetailWrapper}
                sx={{
                  borderColor: item.sosStatus ? theme.palette.error.main : getEvidenceStatusColor(item.status)
                }}
                onClick={() => handleItemClick(item)}
                onMouseOver={() => handleItemTooltip(item, 'over')}
                onMouseLeave={() => handleItemTooltip(item, 'leave')}
              >
                {/* DEVICE & SOS */}
                <Stack className={classes.itemDetail} alignItems='center'>
                  <IconPhoneAndroid className={classes.itemDetailIcon}/>
                  <Typography color='black' fontWeight={600} variant='caption' flex={1} paddingLeft='4px'>{item.deviceName}</Typography>
                  {item.sosStatus && <Chip className={classes.chipSOS} variant='filled' label={<IconSos />} />}
                </Stack>

                {/* LOCATION */}
                <Stack className={classes.itemDetail}>
                  <IconLocationOn className={classes.itemDetailIcon}/>
                  <Typography color='black' variant='caption' flex={1} paddingLeft='4px' marginTop='-2px'>
                    {item.address}
                  </Typography>
                </Stack>

                {/* TIME */}
                <Stack className={classes.itemDetail} alignItems='center'>
                  <IconSchedule className={classes.itemDetailIcon}/>
                  <Typography color='black' variant='caption' flex={1} paddingLeft='4px'>
                    {dateFormat(item.createdOn, 'dd-MM-yyy, hh:mm a')}
                  </Typography>
                </Stack>

                {/* DESCRIPTION */}
                {!item.sosStatus && (
                  <>
                    <Stack className={classes.itemDetail}>
                      <IconSprint className={classes.itemDetailIcon}/>

                      <Stack paddingLeft='4px' marginTop='-2px'>
                        <Typography variant='caption' className='textSecondary'>URAIAN SINGKAT</Typography>

                        <Typography color='black' variant='caption'>
                          {item.description ? item.description : '-'}
                        </Typography>
                      </Stack>
                    </Stack>

                    {/* TAGS */}
                    <Stack className={classes.itemDetail}>
                      <IconTagLabel className={classes.itemDetailIcon}/>
                      <Stack paddingLeft='4px'>
                        <Typography variant='caption' className='textSecondary'>TAG</Typography>

                        {item?.tags?.length ? item.tags.map((itemTag, indexTag) => (
                          <Chip
                            key={indexTag}
                            icon={getTagIcon(itemTag)}
                            size='small'
                            label={itemTag.tagName} 
                            variant='filled'
                            className={classes.evidenceItemTag}
                            sx={{ 
                              color: itemTag.tagColor,
                              border: `1px solid ${itemTag.tagColor}`, 
                            }}
                          />
                        )) : '-'}
                      </Stack>
                    </Stack>
                  </>
                )}
              </Stack>

              <Divider />
            </React.Fragment>
          )) : <Stack alignItems='center' padding='16px'>
            <Typography variant='caption'>Data tidak ditemukan</Typography>
          </Stack>}
        </Stack>
      </Stack>
    </>
  )
}

export default TabEvidences