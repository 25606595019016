// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  informationRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: 372,
    height: '100%',
    overflow: 'auto',
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    margin: '16px 16px',
    height: 43,
    display: 'flex',
    alignItems: 'center',
  },
  informationEditTextRoot: {
    padding: '8px 16px',
    minHeight: 68,
  },
  label: {
    color: colors.gray,
    fontSize: 12,
    marginBottom: 4,
    marginRight: 'auto',
  },
  text: {
    color: colors.gray,
    fontSize: 14,
    fontWeight: 600,
  },
  formControl: {
    width: '100%',
  },
  dropDownIcon: {
    color: colors.boulder,
  },
  menu: {
    '& .MuiPaper-root': {
      width: 350,
      maxHeight: 500,
    },
    '& .MuiMenu-list': {
      padding: 0,
    },
  },
  menuSearch: {
    padding: 16,
  },
  menuSearchInput: {
    width: '100%',
    fontWeight: 600,
    paddingBottom: 4,
    '&::-webkit-input-placeholder': {
      color: colors.boulder,
    },
  },
  menuSearchIcon: {
    marginRight: 4,
  },
  menuItemContainer: {
    width: '100%',
    overflow: 'auto',
    maxHeight: 'calc(500px - 65px)', // 65 is from the height of the search Input container component
  },
  menuItem: {
    height: 48,
    paddingLeft: 20,
  },
  menuItemSelected: {
    backgroundColor: alpha(colors.clementine, 0.1),
  },
  autocomplete: {
    width: '100%',
    '& .MuiChip-root': {
      backgroundColor: alpha(colors.clementine, 0.1),
      color: colors.clementine,
    },
    '& .MuiChip-deleteIcon': {
      color: 'unset',
      '&:hover': {
        color: 'unset',
      },
    },
  },
}))

export default useStyles