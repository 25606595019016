import { useContext } from 'react'

// CONTEXTS
import { AuthLayoutContext } from 'contexts/AuthLayoutContext'

// MUIS
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

const AuthLayout = (props) => {
  const { children } = props

  const { isLoading } = useContext(AuthLayoutContext)

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color='primary'/>
      </Backdrop>
      
      {children}
    </>
  )
}

export default AuthLayout