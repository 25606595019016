// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.codDray,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 40,
    paddingBottom: 20,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  lobbyLogoCompany: {
    width: 56,
    marginBottom: 40,
  },
  lobbyWebCam: {
    width: 600,
    marginBottom: 40,
    borderRadius: 2,
    objectFit: 'cover',
    '& .agora_video_player': {
      objectFit: 'contain !important',
      position : 'relative !important',
    },
  },
  lobbyActionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionIconButton: {
    color: 'white',
    marginRight: 8,
    '&:hover': {
      backgroundColor: alpha('#FFFFFF', 0.2),
    },
  },
  lobbyActionButton: {
    marginLeft: 8,
  },
  roomVideoContainer: {
    position: 'relative',
    backgroundColor: colors.mineShaft,
    borderRadius: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  roomSomeoneVideoContainer: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 80,
    width: '100%',
    position: 'absolute',
  },
  roomSomeoneName: {
    color: 'white',
    position: 'absolute',
    bottom: 20,
    left: 20,
  },
  roomUserVideoContainer: {
    width: 240,
    height: 132,
    position: 'absolute',
    top: 16,
    right: 16,
    border: `1px solid ${alpha('#FFFFFF', 0.2)}`,
  },
  video: {
    height: '100%',
    maxHeight: '100%',
    borderRadius: 2,
    objectFit: 'cover',
    '& .agora_video_player': {
      objectFit: 'contain !important',
      position : 'relative !important',
    },
  },
  roomUserIconMic: {
    color: 'white',
    position: 'absolute',
    bottom: 8,
    left: 8,
  },
  roomUserActionsContainer: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    padding: '8px 16px',
    marginTop: 20,
    backgroundColor: colors.mineShaft,
    borderRadius: 56,
    position: 'absolute',
    bottom: 0,
  },
  roomActionsDivider: {
    width: 1,
    height: '100%',
    backgroundColor: alpha('#FFFFFF', 0.2),
  },
  roomActionsButton: {
    backgroundColor: colors.carnation,
    borderRadius: 36,
    marginLeft: 16,
    '&:hover': {
      backgroundColor: colors.carnation,
      filter: 'brightness(0.9)',
    },
  },
}))

export default useStyles
