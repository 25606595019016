// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconShield = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M12.1174 1.41174C11.9749 1.41174 11.83 1.43674 11.69 1.48675L3.92932 4.38695C3.68931 4.46696 3.5293 4.70713 3.5293 4.94714V7.96728C3.5293 13.9877 6.62951 19.4279 11.8299 22.5081C11.9299 22.5681 12.0299 22.5882 12.1299 22.5882C12.2299 22.5882 12.3499 22.5681 12.4299 22.5081C17.6303 19.4279 20.7305 13.9877 20.7305 7.96728V4.94714C20.7305 4.68713 20.5705 4.46696 20.3305 4.38695L12.5299 1.48675C12.3999 1.43674 12.2599 1.41174 12.1174 1.41174ZM12.1225 2.71183C12.265 2.71183 12.4098 2.73684 12.5498 2.78684L19.5304 5.3671V7.96728C19.5304 13.4477 16.7702 18.3879 12.1299 21.2881C7.48957 18.3879 4.72938 13.4477 4.72938 7.96728V5.3671L11.7099 2.78684C11.84 2.73684 11.98 2.71183 12.1225 2.71183ZM12.7807 4.83346C12.4362 4.79901 12.1299 5.05934 12.1299 5.42687V17.868C12.1299 18.368 12.7499 18.6479 13.13 18.2879C16.0502 15.6877 17.7303 11.9876 17.7303 7.96728V7.04729C17.7303 6.78727 17.5703 6.5671 17.3303 6.4871L12.9299 4.86706C12.8799 4.84956 12.8299 4.83839 12.7807 4.83346Z'/>
    </SvgIcon>
  )
}

export default IconShield