// MUI STYLES
import { makeStyles } from '@mui/styles'
import { colors } from 'constants/colors'

const useStyles = makeStyles((theme) => ({
  itemDetail: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  labelIcon: {
    color: colors.raven,
    fontSize: 16,
    marginTop: 2
  },
  labelTitle: {
    fontSize: 12,
    marginBottom: 4,
    color: theme.palette.common.black
  },
  iconInfoDate: {
    color: colors.boulder,
    opacity: 0.5,
    fontSize: '12px !important',
    marginLeft: 4,
    marginTop: 1,
    cursor: 'pointer'
  },
  textDesc: {
    color: colors.raven
  },
  detailListIcon: {
    color: colors.atlantis,
    fontSize: 18,
  },
  iconCircleStatus: {
    fontSize: 16,
    marginTop: 2
  },
  addDispatch: {
    position: 'absolute',
    right: 16,
    bottom: 16,
  },
}))

export default useStyles