// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconBansar = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M12 4.92999C10.2196 4.92999 8.84096 6.22073 7.66071 7.3608C7.07059 7.93083 6.51311 8.48329 6.03348 8.86749C5.55385 9.2517 5.16211 9.42999 4.92857 9.42999C3.88393 9.42999 3 10.2863 3 11.3586C3 12.1019 3.43192 12.5765 3.78348 13.0059C3.97684 13.2419 4.15011 13.4353 4.28571 13.5684V16.1398L4.48661 16.3206C4.48661 16.3206 7.35686 19.0728 12 19.0728C16.6431 19.0728 19.5134 16.3206 19.5134 16.3206L19.7143 16.1398V13.5684C19.8499 13.4353 20.0232 13.2419 20.2165 13.0059C20.5681 12.5765 21 12.1019 21 11.3586C21 10.2989 20.1261 9.42999 19.0714 9.42999C18.8429 9.42999 18.4461 9.2517 17.9665 8.86749C17.4869 8.48329 16.9294 7.93083 16.3393 7.3608C15.1565 6.22073 13.7804 4.92999 12 4.92999ZM12 6.21571C13.1124 6.21571 14.3052 7.17497 15.4554 8.2849C16.0304 8.83987 16.5904 9.41241 17.1629 9.87196C17.7355 10.3315 18.3407 10.7157 19.0714 10.7157C19.428 10.7157 19.7143 10.9995 19.7143 11.3586C19.7143 11.3259 19.5033 11.8256 19.2121 12.1822C19.0011 12.4384 18.8931 12.5438 18.7902 12.6443H5.20982C5.10686 12.5438 4.99888 12.4384 4.78795 12.1822C4.49665 11.8256 4.28571 11.3259 4.28571 11.3586C4.28571 11.0095 4.60212 10.7157 4.92857 10.7157C5.66183 10.7157 6.262 10.3315 6.83705 9.87196C7.41211 9.41241 7.96959 8.83987 8.54464 8.2849C9.69224 7.17497 10.8876 6.21571 12 6.21571ZM10.7143 8.78714V10.7157L12 11.3586L13.2857 10.7157V8.78714H10.7143ZM5.57143 13.93H18.4286V15.2157H5.57143V13.93ZM6.95759 16.5014H17.0424C15.9526 17.1167 14.2651 17.7871 12 17.7871C9.73493 17.7871 8.04743 17.1167 6.95759 16.5014Z' />
    </SvgIcon>
  )
}

export default IconBansar