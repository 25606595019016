import { useContext, useState } from 'react'

// COMPONENTS
import DateRangeAndTimePicker from 'components/DateRangeAndTimePicker/DateRangeAndTimePicker'

// CONSTANTS
import { colors } from 'constants/colors'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// DATE AND TIME
import { format } from 'date-fns'
import moment from 'moment'

// MUIS
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Dialog from '@mui/material/Dialog'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import OutlinedInput from '@mui/material/OutlinedInput'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

// MUI ICONS
import IconArrowBack from '@mui/icons-material/ArrowBack'
import IconDateRange from '@mui/icons-material/DateRange'

// STYLES
import usePageStyles from '../overviewDashboardUseStyles'
import useStyles from './panelFiltersUseStyles'

const PanelFilters = (props) => {
  const { 
    setIsPanelFiltersOpen, 
    viewModeList, provincesGeoJson,
    selectedViewMode, setSelectedViewMode,
    selectedProvince, setSelectedProvince,
    selectedDateRange, setSelectedDateRange
  } = props

  const { setSnackbarObject } = useContext(AllPagesContext)

  const pageClasses = usePageStyles()
  const classes = useStyles()
  const theme = useTheme()

  const [ inputProvince, setInputProvince ] = useState('')
  const [ tempSelectedProvince, setTempSelectedProvince ] = useState(selectedProvince)
  const [ tempSelectedViewMode, setTempSelectedViewMode ] = useState(selectedViewMode)
  const [ tempSelectedDateRange, setTempSelectedDateRange ] = useState(selectedDateRange)
  const [ isDateRangeShown, setIsDateRangeShown ] = useState(false)

  const handleBtnApplyClick = () => {
    setSelectedViewMode(tempSelectedViewMode)
    setSelectedProvince(tempSelectedProvince)
    setSelectedDateRange(tempSelectedDateRange)
    setIsPanelFiltersOpen(false)
  }

  const handleCancelApplyClick = () => {
    setSelectedViewMode(selectedViewMode)
    setSelectedProvince(selectedProvince)
    setIsPanelFiltersOpen(false)
  }

  const handleSelectDateRangeButtonClick = (newDateRange) => {
    if (moment(newDateRange[1]).diff(newDateRange[0], 'days') + 1 <= 60) {
      setTempSelectedDateRange(newDateRange)
      setIsDateRangeShown(false)
    } else setSnackbarObject({
      open: true,
      severity: 'error',
      title: '',
      message: 'Rentang tanggal maksimal 60 hari',
    })
  }

  const formatDateRangeAndTimeText = (dateRangeAndTime) => {
    const formatedDateRange = `${format(new Date(dateRangeAndTime[0]), 'MM/dd/yyyy'
    )} - ${format(new Date(dateRangeAndTime[1]), 'MM/dd/yyyy')}`
    return formatedDateRange
  }

  return (
    <>
      <Stack className={pageClasses.panelLeft}>
        {/* HEADER */}
        <Stack
          direction='row'
          className={pageClasses.panelLeftHeader}
          borderRadius='4px 4px 0px 0px'
        >
          {/* BACK ICON */}
          <IconButton
            size='small'
            onClick={() => setIsPanelFiltersOpen(false)}
          >
            <IconArrowBack fontSize='small'/>
          </IconButton>

          {/* TITLE */}
          <Typography 
            fontWeight='600'
            marginLeft='8px'
          >
            Filter
          </Typography>
        </Stack>

        {/* VIEW MODE */}
        <Stack 
          padding='16px'
          spacing='16px'
          borderBottom={`1px solid ${theme.palette.divider}`}
        >
          {/* TEXT */}
          <Typography
            variant='body2'
            fontWeight={600}
            color='text.secondary'
          >
            Tampilan area geofence 
          </Typography>

          {/* BUTTON GROUP */}
          <ButtonGroup variant='contained'>
            {viewModeList.map((item, index) => (
              <Button 
                key={index}
                fullWidth
                variant={tempSelectedViewMode === item ? 'contained' : 'text'}
                onClick={() => setTempSelectedViewMode(item)}
                className={classes.viewModeButton}
                sx={{ color: tempSelectedViewMode === item ? 'white' : theme.palette.text.secondary }}
              >
                {item}
              </Button>
            ))}
          </ButtonGroup>

          {/* PROVINCES AUTOCOMPLETE */}
          {tempSelectedViewMode === viewModeList[1] &&
          <Autocomplete
            value={tempSelectedProvince}
            onChange={(event, newValue) => setTempSelectedProvince(newValue)}
            inputValue={inputProvince}
            onInputChange={(event, newInputValue) => setInputProvince(newInputValue)}
            options={provincesGeoJson?.features?.map(item => item.properties).sort((a, b) => {
              if (a.label < b.label) return -1
              else if (a.label > b.label) return 1
              return 0
            })}
            size='small'
            noOptionsText={
              <ListItem disablePadding>
                <ListItemText primary='Tidak ada opsi'/>
              </ListItem>
            }
            renderInput={(params) => (
              <TextField 
                {...params} 
                fullWidth
                InputProps={{ 
                  ...params.InputProps, 
                  style: { fontSize: '30 !important' }, 
                }}
                label='Pilih Provinsi'
              />
            )}
            renderOption={(props, option) => (
              <ListItem 
                {...props} 
                disablePadding
              >
                <ListItemText primary={option.label}/>
              </ListItem>
            )}
          />}
        </Stack>

        {/* DATE RANGE MODE */}
        <Stack 
          padding='16px'
          spacing='16px'
          borderBottom={`1px solid ${theme.palette.divider}`}
        >
          {/* TEXT */}
          <Typography
            variant='body2'
            fontWeight={600}
            color='text.secondary'
          >
            Tampilan berdasarkan periode
          </Typography>

          {/* DATE RANGE PICKER INPUT */}
          <FormControl variant='outlined'>
            <OutlinedInput
              type='text'
              placeholder='Rentang Tanggal'
              fullWidth
              className={classes.dateRangeInput}
              startAdornment={
                <InputAdornment position='start'>
                  <IconDateRange/>
                </InputAdornment>
              }
              onClick={(event) => setIsDateRangeShown(true)}
              value={formatDateRangeAndTimeText(tempSelectedDateRange)}
            />
          </FormControl>
        </Stack>

        {/* FOOTER */}
        <Stack
          direction='row'
          backgroundColor={colors.wildSand}
          height='48px'
          borderRadius='0px 0px 4px 4px'
        >
          {/* CANCEL BUTTON */}
          <Button 
            fullWidth
            className={`${classes.footerButton} ${classes.footerButtonCancel}`}
            onClick={() => handleCancelApplyClick()}
          >
            Batal
          </Button>

          {/* CONTINUE BUTTON */}
          <Button 
            fullWidth
            className={classes.footerButton}
            onClick={() => handleBtnApplyClick()}
          >
            Terapkan
          </Button>
        </Stack>
      </Stack>

      {/* MENU DATE RANGE PICKER */}
      <Dialog
        open={isDateRangeShown}
        onClose={() => setIsDateRangeShown(false)}
        className={classes.dateRangeAndTimePickerDialog}
      >
        <DateRangeAndTimePicker
          value={tempSelectedDateRange}
          handleSelectButtonClick={handleSelectDateRangeButtonClick}
          handleCancelButtonClick={() => setIsDateRangeShown(false)}
        />
      </Dialog>
    </>
  )
}

export default PanelFilters