import PropTypes from 'prop-types'

// CAROUSEL
import Carousel from 'react-material-ui-carousel'

// COMPONENTS
import CarouselMediaAudio from './CarouselMediaAudio'
import CarouselMediaImage from './CarouselMediaImage'
import CarouselMediaVideo from './CarouselMediaVideo'

// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import MobileStepper from '@mui/material/MobileStepper'
import Stack from '@mui/material/Stack'

// MUI ICONS
import IconArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew'
import IconArrowForwardIos from '@mui/icons-material/ArrowForwardIos'

// PATH ICONS
import IconSos from 'assets/images/pathIcons/IconSos'

// STYLES
import useStyles from './carouselMediasUseStyles'

const variantList = [ 'small', 'medium', 'fullHeight' ]

const CarouselMedias = (props) => {
  const { 
    evidenceObject,
    variant,
    indicators,
    onItemClick,
    activeIndex, setActiveIndex,
    isWithNoZoom
  } = props

  const classes = useStyles()

  const variantObject = {
    small: {
      height: 220,
      navigationButtonClassName: classes.carouselNavigationSmall,
      playButtonClassName: classes.carouselItemButtonSmall,
    },
    medium: {
      height: 420,
      navigationButtonClassName: '',
      playButtonClassName: '',
    },
    fullHeight: {
      height: '100vh',
      navigationButtonClassName: '',
      playButtonClassName: '',
    },
  }

  const getCardMedia = (carouselItem) => {
    if (carouselItem.mediaType === 'IMAGE') {
      return (
        <CarouselMediaImage 
          carouselItem={carouselItem}
          variant={variant}
        />
      )
    }
    else if (carouselItem.mediaType === 'VIDEO') {
      return (
        <CarouselMediaVideo 
          carouselItem={carouselItem}
          variantObject={variantObject}
          variant={variant}
        />
      )
    }
    else if (carouselItem.mediaType === 'AUDIO') {
      return (
        <CarouselMediaAudio 
          carouselItem={carouselItem}
          variantObject={variantObject}
          variant={variant}
        />
      )
    }
  }

  return (
    <>
      {/* SINGLE ITEM */}
      {evidenceObject.medias?.length === 1 &&
      <Stack
        height={variantObject[variant].height}
        justifyContent='center'
        alignItems='center'
        borderRadius='4px !important'
        position='relative'
        overflow='hidden'
        backgroundColor={colors.raven}
        className={isWithNoZoom ? 'no-zoom' : ''}
        onClick={() => onItemClick({ ...evidenceObject, activeIndex })}
      >
        {getCardMedia(evidenceObject.medias[0])}
      </Stack>}

      {/* CAROUSEL */}
      {evidenceObject.medias?.length > 1 &&
      <Carousel
        autoPlay={false}
        indicators={false}
        navButtonsAlwaysVisible={evidenceObject.medias?.length > 1 ? true : false}
        navButtonsAlwaysInvisible={evidenceObject.medias?.length > 1 ? false : true}
        animation='slide'
        className={`${classes.carousel} ${variantObject[variant].navigationButtonClassName} ${isWithNoZoom && 'no-zoom'}`}
        height={variantObject[variant].height}
        index={activeIndex}
        onChange={(now, previous) => setActiveIndex(now)}
        PrevIcon={<IconArrowBackIosNew fontSize='small' />}
        NextIcon={<IconArrowForwardIos fontSize='small' />}
      >
        {evidenceObject.medias.map((carouselItem, carouselIndex) => (
          <Stack
            key={carouselIndex}
            height={variantObject[variant].height}
            maxHeight='100%'
            justifyContent='center'
            alignItems='center'
            backgroundColor={colors.raven}
            position='relative'
            onClick={() => onItemClick({ ...evidenceObject, activeIndex })}
          >
            {getCardMedia(carouselItem)}
          </Stack>
        ))}
      </Carousel>}

      {/* STEPPER */}
      {(evidenceObject.medias?.length > 1 && indicators) &&
      <MobileStepper
        variant='dots'
        steps={evidenceObject.medias.length}
        position='static'
        activeStep={activeIndex}
        className={classes.carouselStepper}
      />}

      {/* SOS ITEM */}
      {evidenceObject.sosStatus &&
      <Stack
        height={variantObject[variant].height}
        justifyContent='center'
        alignItems='center'
        borderRadius='4px !important'
        position='relative'
        overflow='hidden'
        backgroundColor={colors.concrete}
        className={isWithNoZoom ? 'no-zoom' : ''}
        onClick={() => onItemClick({ ...evidenceObject, activeIndex })}
      >
        <IconSos 
          color='error' 
          className={classes.sosIcon}
        />
      </Stack>}
    </>
  )
}

CarouselMedias.defaultProps = {
  evidenceObject: {},
  variant: variantList[1],
  indicators: true,
  onItemClick: () => {},
  activeIndex: 0,
  isWithNoZoom: false,
}

CarouselMedias.propTypes = {
  evidenceObject: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(variantList).isRequired,
  indicators: PropTypes.bool.isRequired,
  onItemClick: PropTypes.func.isRequired,
  activeIndex: PropTypes.number.isRequired, 
  setActiveIndex: PropTypes.func.isRequired,
  isWithNoZoom: PropTypes.bool
}

export default CarouselMedias