// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  bubbleChatBox: {
    padding: '4px 8px',
    borderRadius: 8,
    width: 'fit-content'
  },
  bubbleChatAvatar: {
    minWidth: 'auto',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 8,
    width: 24,
    height: 24
  },
  textMessageInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  menuPickerEmoji: {
    '& .MuiMenu-paper': {
      backgroundColor: 'transparent',
      boxShadow: 'none'
    }
  },
  previewTextEditor: {
    position: 'absolute',
    bottom: '64px',
    left: '16px',
    right: '16px',
    backgroundColor: colors.athensGray,
    borderRadius: 4,
    padding: '8px 16px'
  },
  iconEqualizerRecording: {
    fontSize: 32
  },
  audioRecording: {
    '&::-webkit-media-controls-enclosure': {
      backgroundColor: 'transparent !important'
    },
    '&::-webkit-media-controls-panel': {
      backgroundColor: 'transparent !important'
    }
  },
  conversationAvatar: {
    minWidth: 'auto',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    marginRight: 8,
    cursor: 'pointer'
  },
}))

export default useStyles