import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

// COMPONENTS
import CustomInput from 'components/Customs/CustomInput'
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'

// CONSTANTS
import { basePaths } from 'constants/paths'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// DATAGRID
import { useGridApiRef } from '@mui/x-data-grid-premium'

// MUIS
import Divider from '@mui/material/Divider'
import Fab from '@mui/material/Fab'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconAdd from '@mui/icons-material/Add'
import IconAdjust from '@mui/icons-material/Adjust'
import IconClear from '@mui/icons-material/Clear'
import IconClose from '@mui/icons-material/Close'
import IconDelete from '@mui/icons-material/Delete'
import IconEdit from '@mui/icons-material/Edit'
import IconEditLocationOutlined from '@mui/icons-material/EditLocationOutlined'
import IconInfoOutlined from '@mui/icons-material/InfoOutlined'
import IconSearch from '@mui/icons-material/Search'

// STYLES
import useStyles from './panelMenuPlacesListUseStyles'

// REDUX
import { useDispatch } from 'react-redux'
import { showAlert } from 'store/MainReducer'

// SERVICES
import { deletePlace, getAllPlacesList } from 'services/PlacesService'

// UTILS
import { splitZoneArea } from 'utilities/map'

const PanelMenuPlacesList = (props) => {
  const { handleMenuAnchor } = props
  const { map, setIsPanelSearchDetail, selectionModelPlacesList, setSelectionModelPlacesList } = useContext(PageTrackingContext)
  const classes = useStyles()
  const dataGridApiRef = useGridApiRef()
  const dispatch = useDispatch()
  const history = useHistory()

  const initialColumns = [
    {
      field: 'name',
      headerName: 'Label',
      flex: 1,
      renderCell: (params) => {
        const getRadius = splitZoneArea(params.row?.zone?.area)
        
        return (
          <Stack alignItems='center' direction='row' className={classes.itemPlacesList}>
            <Stack className={classes.itemPlacesListIcon}>
              <IconAdjust />
            </Stack>

            <Stack className={classes.itemPlacesListDetail}>
              <Typography className={classes.itemPlacesListTitle} variant='subtitle2'>
                {params.value}
              </Typography>
              <Typography className={classes.itemPlacesListDesc} variant='body2' noWrap>
                {params.row.zone.address} ({getRadius[3]} meter)
              </Typography>
            </Stack>

            <Stack direction='row' className={`${classes.itemPlacesListInfo} buttonInfoPlacesList`}>
              {/* DELETE */}
              <CustomTooltipBlack
                title='Hapus'
                placement='bottom'
              >
                <IconButton onClick={() => setDialogDeletePlaces({ placeNo: params.row.place_no })}>
                  <IconDelete />
                </IconButton>
              </CustomTooltipBlack>

              {/* DETAIL */}
              <CustomTooltipBlack
                title='Informasi'
                placement='bottom'
              >
                <IconButton onClick={() => handleButtonInfoClick(params.row)}>
                  <IconInfoOutlined />
                </IconButton>
              </CustomTooltipBlack>

              {/* EDIT */}
              <CustomTooltipBlack
                title='Ubah'
                placement='bottom'
              >
                <IconButton onClick={() => history.push(
                  `${basePaths.settingsPlaces}?mode=edit&id=${params.row.id}`, 
                  {
                    id: params.row.id,
                    row: params.row,
                  }
                )}>
                  <IconEdit />
                </IconButton>
              </CustomTooltipBlack>
            </Stack>
          </Stack>
        )
      }
    }
  ]

  // STATES
  const [searchValue, setSearchValue] = useState('')
  const [dataPlaces, setDataPlaces] = useState([])
  const [page, setPage] = useState(0)
  const [size, setPageSize] = useState(100)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('name')
  const [total, setTotal] = useState(0)
  const [dialogDeletePlaces, setDialogDeletePlaces] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const fetchPlacesList = async () => {
    const bodyParamsSearch = {
      globalSearch: searchValue,
      name: null,
      place_no: null,
      zone: {
        address: null,
        area: null,
        description: null,
        geofence_id: null,
        label: null,
        zone_no: null
      }
    }
    
    const response = await getAllPlacesList({
      page, size,
    }, bodyParamsSearch)

    if(response?.rows?.length) {
      setTotal(response.totalElements)
      setDataPlaces(response.rows.map(item => ({...item, id: item.place_no})))
    } else {
      setDataPlaces([])
    }
  }

  const handleChangeRowsPerPage = (newPageSize) => {
    setPageSize(newPageSize)
    setPage(0)
  }

  const handleButtonInfoClick = (item) => {
    const getLatLng = splitZoneArea(item?.zone?.area)
    map.flyTo([Number(getLatLng[1]), Number(getLatLng[2])], 14)
    setIsPanelSearchDetail({
      lat: Number(getLatLng[1]),
      lng: Number(getLatLng[2]),
      address: item.zone.address,
      description: item.zone.description,
      name: item.name,
      type: 'places'
    })
  }

  const handleDeleteButtonClick = async () => {
    setIsLoading(true)
    if(Boolean(dialogDeletePlaces)) {
      let tempPlaceNo = dialogDeletePlaces?.placeNo
      const result = await deletePlace(tempPlaceNo)
      setDialogDeletePlaces({})

      if (result.status !== 'error') {
        setPage(0)
        dispatch(showAlert({ message: 'Sukses Menghapus Tempat' }))
        setSelectionModelPlacesList(current => current.filter(placeNo => placeNo !== tempPlaceNo))
        fetchPlacesList()
      }
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchPlacesList()
  }, [page, size, order, orderBy, searchValue])

  // HANDLE FLY TO
  useEffect(() => {
    if(selectionModelPlacesList?.length && dataPlaces?.length) {
      const listLatLng = selectionModelPlacesList.map(id => {
        const findId = dataPlaces.find(item => item.id === id)
        if(Boolean(findId)) {
          const getLatlng = splitZoneArea(findId?.zone?.area)
          return [Number(getLatlng[1]), Number(getLatlng[2])]
        }
      })

      if(listLatLng?.length === 1) map.flyTo(listLatLng[0], 16)
      else if(listLatLng?.length > 1) map.fitBounds(listLatLng)
    }
  }, [selectionModelPlacesList, dataPlaces])

  return (
    <>
      <Stack
        className={classes.placesMenu}
        sx={{
          height: total < 10 ? 'auto' : '556px'
        }}
      >
        {/* MENU HEADER */}
        <Stack direction='row' className={classes.placesMenuHeader}>
          {/* ICON */}
          <IconEditLocationOutlined className={classes.icon}/>

          {/* TITLE */}
          <Typography
            variant='h6'
            className={classes.placesMenuHeaderTitle}
          >
            Tempat
          </Typography>

          {/* CLOSE ICON */}
          <IconButton onClick={() => handleMenuAnchor(1, null)}>
            <IconClose className={classes.icon}/>
          </IconButton>
        </Stack>

        <Divider/>

        {/* MENU SEARCH */}
        <Stack direction='row' alignItems='center' className={classes.placesMenuWrap}>
          <CustomInput
            onChange={(event) => {
              setPage(0)
              setSearchValue(event.target.value)
            }}
            className={classes.placesInput}
            placeholder='Pencarian'
            disableUnderline
            value={searchValue}
          />

          {/* ICON */}
          <Stack alignItems='center'>
            {searchValue === '' ?
              <IconButton className={classes.searchIcon}>
                <IconSearch/>
              </IconButton> :
              <IconButton 
                className={classes.searchIcon}
                onClick={() => setSearchValue('')}
              >
                <IconClear/>
              </IconButton>}   
          </Stack>
          

          {/* FAB */}
          <Stack className={classes.fabWrapper}>
            <Fab color='primary' href='/settings-places?mode=add'>
              <IconAdd />
            </Fab>
          </Stack>
        </Stack>

        <Divider/>

        {/* CONTENT */}
        <Stack className={classes.contentWrapper}>
          {isLoading && <Stack
            className={classes.loadingWrapper}
            sx={{
              height: total < 10 ? '240px' : '100%'
            }}
          >
            <LinearProgress className={classes.loading}/>
          </Stack>}

          {!isLoading && <DataGridTable
            autoHeight={total < 10}
            apiRef={dataGridApiRef}
            columns={initialColumns}
            rows={dataPlaces}
            page={page}
            pageSize={size}
            setPage={setPage}
            setPageSize={setPageSize}
            onPageSizeChange={handleChangeRowsPerPage}
            onPageChange={(page, details) => setPage(page)}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            total={total}
            setFilters={() => null}
            isFilterOn={false}
            getTreeDataPath={(row) => [row.name, row.id]}
            selectionModel={selectionModelPlacesList}
            setSelectionModel={setSelectionModelPlacesList}
            setSelectedColumnList={() => null}
          />}
        </Stack>
      </Stack>

      {/* DELETE */}
      <DialogConfirmation
        title='Hapus Tempat'
        caption='Apa Anda yakin ingin menghapus tempat ini?'
        dialogConfirmationObject={dialogDeletePlaces}
        setDialogConfirmationObject={setDialogDeletePlaces}
        cancelButtonText='Batal'
        continueButtonText='Hapus'
        onContinueButtonClick={() => handleDeleteButtonClick()}
        onCancelButtonClick={() => setDialogDeletePlaces({})}
      />
    </>
  )
}

export default PanelMenuPlacesList