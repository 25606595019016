import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

// COMPONENTS
import MediaSection from './MediaSection/MediaSection'
import InformationSection from './InformationSection/InformationSection'
import PageHeaderBack from 'components/PageHeaderBack/PageHeaderBack'

// MUIS
import Box from '@mui/material/Box'

// SERVICES
import { getEvidence } from 'services/EvidenceService'

// STYLES
import useStyles from './evidenceDetailUseStyles'

const EvidenceDetail = () => {
  const classes = useStyles()

  const params = useParams()

  const [ evidenceDetailObject, setEvidenceDetailObject ] = useState(null)

  const reloadData = async () => {
    const resultEvidenceDetailObject = await getEvidence(params.id)
    setEvidenceDetailObject(resultEvidenceDetailObject)
  }

  useEffect(() => {
    reloadData()
  }, [])

  return (
    <Box className={classes.pageRoot}>
      {/* HEADER */}
      <PageHeaderBack
        targetBackUrl='/kejadian'
        pageTitle='Detail Kejadian'
      />

      {/* CONTENT */}
      {evidenceDetailObject &&
      <Box className={classes.mediaSectionWrap}>
        <Box className={classes.contentRoot}>
          {/* MEDIA SECTION */}
          <MediaSection 
            evidenceDetailObject={evidenceDetailObject}
            reloadData={reloadData}
          />

          {/* INFORMATION SECTION */}
          <InformationSection evidenceDetailObject={evidenceDetailObject}/>
        </Box>  
      </Box>}
    </Box>
  )
}

export default EvidenceDetail
