// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: colors.boulder,
  },
  mapMenu: {
    borderRadius: 2,
    width: 372,
    padding: 0,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[4],
    pointerEvents: 'auto',
    cursor: 'pointer',
    height: 'fit-content',
  },
  mapMenuHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 62,
    paddingLeft: 18,
    paddingRight: 12,
  },
  mapMenuHeaderTitle: {
    marginLeft: 12,
    marginRight: 'auto',
  },
  mapOptionList: {
    '&.MuiList-root': {
      padding: '8px 4px',
    },
  },
  mapOptionItem: {
    padding: 0,
    height: 45,
  },
  mapOptionItemButton: {
    padding: '0px 12px 0px 4px',
    borderRadius: 2,
    '&:hover': {
      backgroundColor: alpha(colors.clementine, 0.04),
    },
  },
}))

export default useStyles