// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconMoney = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M8.80449 1.41174C7.58622 1.41174 6.57539 2.42257 6.57539 3.64085V6.9845H5.46084C4.84561 6.9845 4.34629 6.48518 4.34629 5.86995V3.86506C4.34629 3.28884 3.66742 3.00604 3.23609 3.38833C2.55064 3.99799 2.11719 4.88134 2.11719 5.86995V20.3591C2.11719 21.5907 3.11471 22.5882 4.34629 22.5882H19.95C21.1816 22.5882 22.1791 21.5907 22.1791 20.3591V9.2136C22.1791 7.98202 21.1816 6.9845 19.95 6.9845V3.64085C19.95 2.42257 18.9392 1.41174 17.7209 1.41174H8.80449ZM8.80449 3.64085H11.0336V6.9845H8.80449V3.64085ZM13.2627 3.64085H17.7209V6.9845H13.2627V3.64085ZM17.7209 13.6718C18.3361 13.6718 18.8355 14.1711 18.8355 14.7864C18.8355 15.4016 18.3361 15.9009 17.7209 15.9009C17.1057 15.9009 16.6064 15.4016 16.6064 14.7864C16.6064 14.1711 17.1057 13.6718 17.7209 13.6718Z'/>
    </SvgIcon>
  )
}

export default IconMoney