// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiPaper-root': {
      minWidth: 800,
      padding: 0,
    }
  },
  dialogHeader: {
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  dialogHeaderTitle: {
    fontSize: 20,
    fontWeight: 600,
    flex: 1,
  },
}))

export default useStyles