import React from 'react'
import PropTypes from 'prop-types'

// ASSETS
import IconToggleFlyout from 'assets/images/icons/flyout_toggle.svg'

// COMPONENTS
import PageSearchField from 'components/PageSearchField/PageSearchField'

// MUI
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

// MATERIAL UI ICONS
import IconAdd from '@mui/icons-material/Add'

// STYLES
import useStyles from './pageHeaderUseStyles'

const PageHeader = (props) => {
  const { 
    isAddButtonAvailable,
    onAddButtonIsClicked,
    title,
    isSearchAvailable,
    search,
    setSearch, 
    isFlyoutAvailable,
    isFlyoutShown,
    flyoutTitle,
    flyoutTitleMargin,
    onToggleFlyoutClick,
  } = props
  
  const classes = useStyles()

  return (
    <Box className={classes.headerRoot}>
      {/* FAB */}
      {isAddButtonAvailable &&
      <Fab 
        color='primary'
        className={classes.addIconButton}
        onClick={onAddButtonIsClicked}
      >
        <IconAdd/>
      </Fab>}

      {/* TITLE */}
      <Typography
        variant='h6'
        className={classes.title}
      >
        {title}
      </Typography>

      {/* SEARCH */}
      {isSearchAvailable &&
      <PageSearchField
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />}

      {/* FLYOUT TITLE */}
      {isFlyoutAvailable &&
      <Typography
        variant='h6'
        className={
          isFlyoutShown ?
            `${classes.flyoutTitle} ${classes.flyoutTitleShown}` :
            classes.flyoutTitle
        }
        sx={{ marginRight: isFlyoutShown ? flyoutTitleMargin : 0 }}
      >
        {flyoutTitle}
      </Typography>}

      {/* TOGGLE FLYOUT ICON */}
      {isFlyoutAvailable &&
      <IconButton 
        className={
          isFlyoutShown ?
            `${classes.flyoutInitialToggle} ${classes.flyoutRotateToggle}` :
            classes.flyoutInitialToggle
        }
        onClick={onToggleFlyoutClick}
      >
        <Box
          component='img'
          src={IconToggleFlyout}
          alt=''
        />
      </IconButton>}
    </Box>
  )
}

PageHeader.defaultProps = {
  isAddButtonAvailable: false,
  title: '',
  isSearchAvailable: true,
  search: '',
  isFlyoutAvailable: false,
  isFlyoutShown: false,
  flyoutTitle: '',
  flyoutTitleMargin: '0px',
}

PageHeader.propTypes = {
  isAddButtonAvailable: PropTypes.bool.isRequired,
  onAddButtonIsClicked: PropTypes.func, 
  title: PropTypes.string.isRequired,
  isSearchAvailable: PropTypes.bool.isRequired,
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func, 
  isFlyoutAvailable: PropTypes.bool.isRequired,
  isFlyoutShown: PropTypes.bool.isRequired,
  flyoutTitle: PropTypes.string,
  flyoutTitleMargin: PropTypes.string,
  onToggleFlyoutClick: PropTypes.func, 
}

export default PageHeader
