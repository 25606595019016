import React from 'react'
import { createRoot } from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import store from './store/store'
import { Provider } from 'react-redux'

// MOMENT
import moment from 'moment'
import 'moment/locale/id'

// COMPONENTS
import App from './App'
import GlobalStyles from 'components/GlobalStyles/GlobalStyles'
import customTheme from 'constants/theme'

// CONTEXTS
import { AllPagesContextProvider } from 'contexts/AllPagesContext'

// GOOGLE MAPS
import { LoadScript } from '@react-google-maps/api'

// MATERIAL UI DATA GRIDS
import { LicenseInfo } from '@mui/x-data-grid-premium'

// MUIS
import { StyledEngineProvider } from '@mui/material/styles'
import { ThemeProvider, createTheme } from '@mui/material/styles'

// REACT GEOCODE
import Geocode from 'react-geocode'

// SENTRY
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

// STYLES
import './index.css'
import 'chat-callkit/lib/assets/icon/iconfont.css'

moment.locale('id')

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY)
Geocode.setLocationType('ROOFTOP')

LicenseInfo.setLicenseKey(process.env.REACT_APP_DATA_GRID_PREMIUM_LICENSE)

if (process.env.REACT_APP_IS_SENTRY_ACTIVE === true) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing(),
      new Sentry.Integrations.Breadcrumbs({console: false})],
    tracesSampleRate: 1.0,
  })
}

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

root.render(
  <ThemeProvider theme={createTheme(customTheme)}>
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        <AllPagesContextProvider>
          <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            libraries={['drawing', 'places']}
          >
            <GlobalStyles/>
            <App />
          </LoadScript>
        </AllPagesContextProvider>
      </Provider>
    </StyledEngineProvider>
  </ThemeProvider>
)

reportWebVitals()
