import { useContext, useState } from 'react'

// COMPONENTS
import DateRangeAndTimePicker from 'components/DateRangeAndTimePicker/DateRangeAndTimePicker'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { PageEvidencesContext } from 'contexts/PageEvidencesContext'

// DATE AND TIME
import { format } from 'date-fns'
import moment from 'moment'

// MUIS
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import Menu from '@mui/material/Menu'
import Stack from '@mui/material/Stack'
import OutlinedInput from '@mui/material/OutlinedInput'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

// MUI ICONS
import IconArrowDropDown from '@mui/icons-material/ArrowDropDown'
import IconDateRange from '@mui/icons-material/DateRange'
import IconSearch from '@mui/icons-material/Search'

// STYLES
import useStyles from './headerUseStyles'

const Header = () => {
  const theme = useTheme()

  const classes = useStyles()

  const { setSnackbarObject } = useContext(AllPagesContext)
  const { 
    evidencesDateRange, setEvidencesDataRange,
    search, setSearch,
  } = useContext(PageEvidencesContext)

  const [ dateRangePickerMenuEl, setDateRangePickerMenuEl ] = useState(null)

  const handleSelectDateRangeButtonClick = (newDateRange) => {
    if (moment(newDateRange[1]).diff(newDateRange[0], 'days') + 1 <= 60) {
      setEvidencesDataRange(newDateRange)
      setDateRangePickerMenuEl(null)
    }
    else setSnackbarObject({
      open: true,
      severity: 'error',
      title: '',
      message: 'Rentang tanggal maksimal 60 hari',
    })
  }

  const formatDateRangeAndTimeText = (dateRangeAndTime) => {
    const formatedDateRange = `${format(new Date(dateRangeAndTime[0]), 'MM/dd/yyyy'
    )} - ${format(new Date(dateRangeAndTime[1]), 'MM/dd/yyyy')}`
    return formatedDateRange
  }

  return (
    <Stack
      direction='row'
      alignItems='center'
      padding='16px'
      spacing='16px'
      height='88px'
      borderBottom={`1px solid ${theme.palette.divider}`}
    >
      {/* PAGE TITLE */}
      <Typography
        variant='h6'
        fontWeight={600}
        marginRight='auto'
      >
        Kejadian
      </Typography>

      {/* SEARCH INPUT */}
      <FormControl variant='outlined'>
        <OutlinedInput
          type='text'
          placeholder='Pencarian'
          className={classes.input}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          startAdornment={
            <InputAdornment position='start'>
              <IconSearch/>
            </InputAdornment>
          }
        />
      </FormControl>
      
      {/* DATE RANGE PICKER INPUT */}
      <FormControl variant='outlined'>
        <OutlinedInput
          type='text'
          placeholder='Date Range Input'
          className={classes.input}
          startAdornment={
            <InputAdornment position='start'>
              <IconDateRange/>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position='end'>
              <IconArrowDropDown/>
            </InputAdornment>
          }
          onClick={(event) => setDateRangePickerMenuEl(event.currentTarget)}
          value={formatDateRangeAndTimeText(evidencesDateRange)}
          onChange={() => {}}
        />
      </FormControl>

      {/* MENU DATE RANGE PICKER */}
      <Menu
        anchorEl={dateRangePickerMenuEl}
        open={Boolean(dateRangePickerMenuEl)}
        onClose={() => setDateRangePickerMenuEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <DateRangeAndTimePicker
          value={evidencesDateRange}
          handleSelectButtonClick={handleSelectDateRangeButtonClick}
          handleCancelButtonClick={() => setDateRangePickerMenuEl(null)}
        />
      </Menu>
    </Stack>
  )
}

export default Header