import React, { useContext, useEffect, useState } from 'react'

// COMPONENTS
import CustomInput from 'components/Customs/CustomInput'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// MUIS
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconClear from '@mui/icons-material/Clear'
import IconClose from '@mui/icons-material/Close'
import IconEditLocationOutlined from '@mui/icons-material/EditLocationOutlined'
import IconHomeWork from '@mui/icons-material/HomeWork'
import IconRoomOutlined from '@mui/icons-material/RoomOutlined'
import IconSearch from '@mui/icons-material/Search'

// STYLES
import useStyles from './panelMenuSearchUseStyles'

// SERVICES
import { getGeoByAddress } from 'services/TrackingServices'
import { getAllPlacesListNoFilter } from 'services/PlacesService'

let timeout
const debounce = (callback, time = 500) => {
  clearTimeout(timeout)
  timeout = setTimeout(callback, time)
}

const PanelMenuSearch = (props) => {
  const { handleMenuAnchor } = props
  const { map, isPanelSearchDetail, setIsPanelSearchDetail } = useContext(PageTrackingContext)
  const classes = useStyles()

  // STATES
  const [searchValue, setSearchValue] = useState('')
  const [tempSearchValue, setTempSearchValue] = useState('')
  const [dataLocation, setDataLocation] = useState([])
  const [dataPlaces, setDataPlaces] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const handleSearchResult = async () => {
    setIsLoading(true)
    const responseLocation = await getGeoByAddress({
      hash: '',
      lang: 'en',
      provider_type: 'google',
      q: searchValue
    })
    const responsePlaces = await getAllPlacesListNoFilter()

    if(responseLocation?.success) {
      setDataLocation(responseLocation.locations)
    } else {
      setDataLocation([])
    }

    if(responsePlaces?.length && searchValue) {
      const filterData = responsePlaces.filter(item => item.zone.address.toLowerCase().includes(searchValue.toLowerCase()))
      setDataPlaces(filterData)
    } else {
      setDataPlaces([])
    }

    setIsLoading(false)
  }

  const handleItemListClick = (item, type) => {
    if(type === 'location') {
      map.flyTo([item.lat, item.lng], 14)
      setIsPanelSearchDetail({...item, type: 'location'})
    } else if (type === 'places') {
      const getLatLng = item?.zone?.area?.replace(/[A-Z]*\(*\)*\,*/ig, '').split(' ')
      map.flyTo([Number(getLatLng[1]), Number(getLatLng[2])], 14)
      setIsPanelSearchDetail({
        lat: Number(getLatLng[1]),
        lng: Number(getLatLng[2]),
        address: item.zone.address,
        description: item.zone.description,
        name: item.name,
        type: 'places'
      })
    }
  }

  useEffect(() => {
    handleSearchResult()
  }, [searchValue])
  
  return (
    <Stack
      className={classes.searchMenu}
      sx={{
        display: !Boolean(isPanelSearchDetail) ? 'flex' : 'none'
      }}
    >
      {/* SEARCH & LIST */}
      <Stack>
        {/* MENU HEADER */}
        <Stack direction='row' className={classes.searchMenuHeader}>
          {/* ICON */}
          <IconSearch className={classes.icon}/>

          {/* TITLE */}
          <Typography
            variant='h6'
            className={classes.searchMenuHeaderTitle}
          >
            Peta
          </Typography>

          {/* CLOSE ICON */}
          <IconButton onClick={() => handleMenuAnchor(0, null)}>
            <IconClose className={classes.icon}/>
          </IconButton>
        </Stack>

        <Divider/>

        {/* MENU SEARCH */}
        <Stack direction='row' className={classes.searchMenuWrap}>
          <CustomInput
            onChange={(event) => {
              setTempSearchValue(event.target.value)
              debounce(() => {
                setSearchValue(event.target.value)
              }, 800)}
            }
            className={classes.searchInput}
            placeholder='Pencarian'
            disableUnderline
            value={tempSearchValue}
          />

          {/* ICON */}
          {searchValue === '' ?
            <IconButton className={classes.searchIcon}>
              <IconSearch/>
            </IconButton> :
            <IconButton 
              className={classes.searchIcon}
              onClick={() => {
                setTempSearchValue('')
                setSearchValue('')
              }}
            >
              <IconClear/>
            </IconButton>}
        </Stack>

        <Divider/>

        {/* MENU RESULT */}
        <Stack className={classes.searchMenuResult}>
          <Stack>
            {/* LOCATION HEADER */}
            {searchValue ? (<>
              <Stack direction='row' alignItems='center' className={classes.resultHeader}>
                {/* ICON */}
                <IconRoomOutlined className={classes.icon}/>

                {/* TITLE */}
                <Typography
                  variant='subtitle1'
                  className={classes.searchMenuHeaderTitle}
                  fontWeight={500}
                >
                  Lokasi
                </Typography>
              </Stack>

              {/* LIST LOCATION */}
              <Stack>
                <List className={classes.menuListResult}>
                  {(!isLoading && dataLocation?.length) ? dataLocation?.slice(0, 1)?.map((item, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemButton onClick={() => handleItemListClick(item, 'location')}>
                        <ListItemText primary={item.address} />
                      </ListItemButton>
                    </ListItem>
                  )) : (
                    <ListItem disablePadding>
                      {isLoading
                        ? <Box className={classes.loadingWrapper}>
                          <LinearProgress className={classes.loadingProgress}/>
                        </Box>
                        : (<ListItemButton>
                          <ListItemText primary='Tempat tidak ditemukan' />
                        </ListItemButton>)}
                    </ListItem>
                  )}
                </List>
              </Stack>

              {/* PLACES */}
              <Stack direction='row' alignItems='center' className={classes.resultHeader}>
                {/* ICON */}
                <IconEditLocationOutlined className={classes.icon}/>

                {/* TITLE */}
                <Typography
                  variant='subtitle1'
                  className={classes.searchMenuHeaderTitle}
                  fontWeight={500}
                >
                  Tempat
                </Typography>
              </Stack>

              {/* LIST PLACES */}
              <List className={classes.menuListResult}>
                {(!isLoading && dataPlaces?.length) ? dataPlaces?.map((item, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemButton onClick={() => handleItemListClick(item, 'places')}>
                      <ListItemAvatar>
                        <Avatar variant='rounded' className={classes.avatarListPlaces}>
                          <IconHomeWork />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={item.name} secondary={item.zone.address} />
                      <LinearProgress />
                    </ListItemButton>
                  </ListItem>
                ))
                  : (
                    <ListItem disablePadding>
                      {isLoading
                        ? <Box className={classes.loadingWrapper}>
                          <LinearProgress className={classes.loadingProgress}/>
                        </Box>
                        : (<ListItemButton>
                          <ListItemText primary='Tempat tidak ditemukan' />
                        </ListItemButton>)}
                    </ListItem>
                  )}
              </List>
            </>
            )
              : (
                <Typography className={classes.noResultText} variant='body2'>Mulai mengetik sebuah alamat atau tempat</Typography>
              )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default PanelMenuSearch