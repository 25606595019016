const mapList = {
  // https://leaflet-extras.github.io/leaflet-providers/preview/
  mapOptionList: [
    // {
    //   name: 'Mapbox Tiler Basic Dark',
    //   url: `https://api.maptiler.com/maps/basic-v2-dark/{z}/{x}/{y}.png?key=${process.env.REACT_APP_MAPTILER_API_KEY}`,
    //   attribution: '&copy; <a href="https://www.maptiler.com/copyright/">MapTiler</a> &copy; <a href="http://www.openstreetmap.org/about/">OpenStreetMap contributors</a> ',
    //   subdomains: '',
    // },
    // {
    //   name: 'Open Street Map',
    //   url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    //   attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    //   subdomains: 'abc',
    // },
    {
      name: 'Mapbox Light',
      url: `https://api.mapbox.com/styles/v1/${process.env.REACT_APP_MAPBOX_USERNAME}/${process.env.REACT_APP_MAPBOX_STYLE_ID_LIGHT}/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
      attribution: '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/about/">OpenStreetMap</a> <a href="https://www.mapbox.com/map-feedback/#/-74.5/40/10">Improve this map</a>',
      subdomains: '',
    },
    {
      name: 'Mapbox Dark',
      url: `https://api.mapbox.com/styles/v1/${process.env.REACT_APP_MAPBOX_USERNAME}/${process.env.REACT_APP_MAPBOX_STYLE_ID_DARK}/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
      attribution: '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/about/">OpenStreetMap</a> <a href="https://www.mapbox.com/map-feedback/#/-74.5/40/10">Improve this map</a>',
      subdomains: '',
    },
    {
      name: 'Mapbox Street',
      url: `https://api.mapbox.com/styles/v1/${process.env.REACT_APP_MAPBOX_USERNAME}/${process.env.REACT_APP_MAPBOX_STYLE_ID_STREET}/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
      attribution: '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/about/">OpenStreetMap</a> <a href="https://www.mapbox.com/map-feedback/#/-74.5/40/10">Improve this map</a>',
      subdomains: '',
    },
    {
      name: 'Mapbox Satelite',
      url: `https://api.mapbox.com/styles/v1/${process.env.REACT_APP_MAPBOX_USERNAME}/${process.env.REACT_APP_MAPBOX_STYLE_ID_SATELITE}/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
      attribution: '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/about/">OpenStreetMap</a> <a href="https://www.mapbox.com/map-feedback/#/-74.5/40/10">Improve this map</a>',
      subdomains: '',
    },
    {
      name: 'Mapbox Satelite Street',
      url: `https://api.mapbox.com/styles/v1/${process.env.REACT_APP_MAPBOX_USERNAME}/${process.env.REACT_APP_MAPBOX_STYLE_ID_SATELITE_STREET}/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
      attribution: '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/about/">OpenStreetMap</a> <a href="https://www.mapbox.com/map-feedback/#/-74.5/40/10">Improve this map</a>',
      subdomains: '',
    },
    {
      name: 'Google Maps Street',
      url: `http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&language=id&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
      attribution: '&copy; Maps Data: <a href="https://about.google/brand-resource-center/products-and-services/geo-guidelines/#required-attribution">Google</a>',
      subdomains: ['mt1','mt2','mt3'],
    },
    {
      name: 'Google Maps Hybrid',
      url: `http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}&language=id&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
      attribution: '&copy; Maps Data: <a href="https://about.google/brand-resource-center/products-and-services/geo-guidelines/#required-attribution">Google</a>',
      subdomains: ['mt1','mt2','mt3'],
    },
    {
      name: 'Google Maps Satellite',
      url: `http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&language=id&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
      attribution: '&copy; Maps Data: <a href="https://about.google/brand-resource-center/products-and-services/geo-guidelines/#required-attribution">Google</a>',
      subdomains: ['mt1','mt2','mt3'],
    },
  ]
}

export default mapList