import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

// COMPONENTS
import LoadingBox from 'components/LoadingBox/LoadingBox'
import DialogAddOrEditSettingsOfficer from './DialogAddOrEditSettingsOfficer/DialogAddOrEditSettingsOfficer'
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DataGridHeaderCell from 'components/DataGridHeaderCell/DataGridHeaderCell'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import PageHeader from 'components/PageHeader/PageHeader'

// CONSTANTS
import { basePaths } from 'constants/paths'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconDelete from '@mui/icons-material/Delete'
import IconEdit from '@mui/icons-material/Edit'

// PATH ICONS
import IconListView from 'assets/images/pathIcons/IconListView'
import IconPoliceMan from 'assets/images/pathIcons/IconPoliceMan'
import IconPoliceBadgeOutlined from 'assets/images/pathIcons/IconPoliceBadgeOutlined'
import IconPoliceStation from 'assets/images/pathIcons/IconPoliceStation'

// RAMDA
import { isEmpty, reject } from 'ramda'

// SERVICES
import { 
  getSearchOfficerList, 
  deleteOfficer, 
} from 'services/settingsOfficersServices'

// STORES
import { showAlert } from 'store/MainReducer'

// STYLES
import useStyles from './settingsOfficerUseStyles'

// UTILS
import { getBackgroundAvatar, getStringAvatar } from 'utilities'

const SettingsOfficer = () => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const history = useHistory()
  const location = useLocation()

  const initialFilters = {
    officerName: '',
    officerRank: '',
    officerCode: '',
    department: '',
  }

  const pageRef = useRef()

  const groupByList = [
    {
      title: 'Don\'t Group',
      value: null,
    },
    {
      title: 'By Departement',
      value: 'roleName',
    },
  ]

  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('created_on')
  const [size, setPageSize] = useState(100)
  const [officerTableData, setOfficerTableData] = useState([])
  const [filters, setFilters] = useState(initialFilters)
  const [selectedGroupBy, setSelectedGroupBy] = useState(groupByList[0])
  const [isFilterOn, setIsFilterOn] = useState(false)
  const [search, setSearch] = useState('')

  const [dialogAddOfficer, setDialogAddOfficer] = useState(null)
  const [dialogEditOfficer, setDialogEditOfficer] = useState(null)
  const [dialogDeleteSettingsOfficer, setDialogDeleteSettingsOfficer] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const handleDeleteIconButtonClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()
    setDialogDeleteSettingsOfficer(inputParams)
  }
  
  const handleEditIconButtonClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()
    history.push(
      `${basePaths.settingsOfficers}?mode=edit&id=${inputParams.id}`, 
      {
        id: inputParams.id,
        row: inputParams.row,
      }
    )
  }

  const [selectionModel, setSelectionModel] = useState([])

  const initialColumns = [
    {
      field: 'officerName',
      headerName: 'Petugas',
      headerIcon: IconPoliceMan,
      flex: 0,
      width: 320,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: params => (
        params.value &&
        <Stack direction='row' alignItems='center'>
          <Avatar
            className={classes.avatarName}
            sx={{ backgroundColor: getBackgroundAvatar(params.value) }}
          >
            {getStringAvatar(params.value)}
          </Avatar>

          <Stack paddingLeft='8px'>
            <Typography className={classes.avatarText} variant='body2' noWrap>
              {params.value}
            </Typography>
          </Stack>
        </Stack>
      )
    },
    {
      field: 'officerRank',
      headerName: 'Pangkat',
      headerIcon: IconPoliceBadgeOutlined,
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'officerCode',
      headerName: 'NRP',
      headerIcon: IconListView,
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: params => (
        params.value &&
        <Chip
          className={classes.columnChipFilled}
          label={params.value}
        />
      )
    },
    {
      field: 'roleName',
      headerName: 'Departemen',
      headerIcon: IconPoliceStation,
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: params => (
        params.value &&
        <Chip
          icon={<IconPoliceStation fontSize='small' />}
          className={classes.columnChipDepartment}
          label={params.value}
        />
      )
    },
    {
      field: 'actions',
      headerName: '',
      width: 120,
      hide: false,
      sortable: false,
      disableColumnMenu: true,
      areFilterAndSortShown: false,
      renderCell: (params) => (
        <Box className={classes.columnActions}>
          {/* EDIT ICON */}
          <CustomTooltipBlack title='Ubah' placement='bottom'>
            <IconButton onClick={(event) => handleEditIconButtonClick(event, params)}>
              <IconEdit />
            </IconButton>
          </CustomTooltipBlack>

          {/* DELETE ICON */}
          <CustomTooltipBlack title='Hapus' placement='bottom'>
            <IconButton
              onClick={(event) => handleDeleteIconButtonClick(event, params)}
            >
              <IconDelete />
            </IconButton>
          </CustomTooltipBlack>
        </Box>
      ),
    },
  ]

  const [selectedColumnList, setSelectedColumnList] = useState(initialColumns)

  const groupingColDef = {
    headerName: 'Petugas',
    flex: 1,
    renderHeader: (params) => (
      <DataGridHeaderCell
        icon={IconPoliceMan}
        headerName='Petugas'
      />
    ),
    renderCell: (params) => {
      if (!params.rowNode.isAutoGenerated) {
        return (
          <Typography 
            variant='inherit'
            noWrap
          >
            {params.row?.officerName}
          </Typography>
        )
      }

      return (
        <Typography 
          variant='subtitle1' 
          className={classes.groupingRow}
          noWrap
        >
          {params.rowNode?.groupingKey}
        </Typography>
      )
    },
  }

  const handleColumnsMenuItemClick = (inputItem, inputIndex) => {
    let tempSelectedColumnList = [...selectedColumnList]
    if (
      (selectedGroupBy.value && inputItem.field === 'roleName') ||
      (selectedGroupBy.value && inputItem.field === 'officerName')
    ) {
    } else {
      tempSelectedColumnList[inputIndex].hide = !tempSelectedColumnList[inputIndex].hide
    }
    setSelectedColumnList(tempSelectedColumnList)
  }

  const reloadData = () => {
    const newFilters = reject(isEmpty, filters)
    let params = {
      page,
      size,
    }

    if (order) params.sort = `${orderBy},${order}`
    if (search) newFilters.globalSearch = search

    setIsLoading(true)

    getSearchOfficerList(params, newFilters).then((response) => {
      setOfficerTableData(response.rows)
      setTotal(response.totalElements)
      setIsLoading(false)
    })
  }

  const handleDeleteButtonClick = async () => {
    setIsLoading(true)

    const result = await deleteOfficer(dialogDeleteSettingsOfficer.row.id)

    if (result.status !== 'error') {
      setIsLoading(false)
      dispatch(showAlert({ message: 'Sukses Menghapus Petugas' }))
      setDialogDeleteSettingsOfficer({})
      reloadData()
    }
  }

  useEffect(() => {
    reloadData()
  }, [page, size, order, orderBy, filters, search])

  useEffect(() => {
    if (!isFilterOn) setFilters(initialFilters)
  }, [isFilterOn])

  useEffect(() => {
    if (selectedGroupBy.value) {
      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        if (
          item.field === selectedGroupBy.value ||
          item.field === 'officerName'
        ) {
          item.hide = true
        }
      })
      setSelectedColumnList(tempSelectedColumnList)
    } else {
      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        if (item.field === 'roleName' || item.field === 'officerName') {
          item.hide = false
        }
      })
      setSelectedColumnList(tempSelectedColumnList)
    }
  }, [selectedGroupBy])

  useEffect(() => {
    if (location.pathname === basePaths.settingsOfficers && location.search === '?mode=add') {
      setDialogAddOfficer(true)
    }
    else if (location.pathname === basePaths.settingsOfficers && location.search.includes('?mode=edit')) {
      setDialogEditOfficer(location?.state)
    }
  }, [location])

  return (
    <Box className={classes.pageRoot} ref={pageRef}>
      {/* HEADER */}
      <PageHeader
        isAddButtonAvailable={true}
        title='Petugas'
        search={search}
        setSearch={setSearch}
        onAddButtonIsClicked={() => history.push(`${basePaths.settingsOfficers}?mode=add`)}
      />

      {/* CONTENT */}
      <LoadingBox isLoading={isLoading}>
        <DataGridFilters
          columns={initialColumns}
          selectedColumnList={selectedColumnList}
          handleColumnsMenuItemClick={handleColumnsMenuItemClick}
          isFilterOn={isFilterOn}
          setIsFilterOn={setIsFilterOn}
          groupByList={groupByList}
          selectedGroupBy={selectedGroupBy}
          setSelectedGroupBy={setSelectedGroupBy}
        />

        <DataGridTable
          initialColumns={initialColumns}
          rows={officerTableData}
          total={total}
          page={page}
          pageSize={size}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setPage={setPage}
          setPageSize={setPageSize}
          setFilters={setFilters}
          isFilterOn={isFilterOn}
          selectedColumnList={selectedColumnList}
          setSelectedColumnList={setSelectedColumnList}
          selectedGroupBy={selectedGroupBy}
          getTreeDataPath={(row) => [row.department, row.id]}
          groupingColDef={groupingColDef}
          selectionModel={selectionModel}
          setSelectionModel={setSelectionModel}
          isCheckboxPinned={true}
        />
      </LoadingBox>

      {/* DIALOG ADD OFFICER */}
      <DialogAddOrEditSettingsOfficer
        dialogType='add'
        dialogAddOrEditOfficer={dialogAddOfficer}
        setDialogAddOrEditOfficer={setDialogAddOfficer}
        reloadData={reloadData}
        pageRef={pageRef}
      />

      {/* DIALOG EDIT OFFICER */}
      {dialogEditOfficer &&
      <DialogAddOrEditSettingsOfficer
        dialogType='edit'
        dialogAddOrEditOfficer={dialogEditOfficer}
        setDialogAddOrEditOfficer={setDialogEditOfficer}
        reloadData={reloadData}
        pageRef={pageRef}
      />}

      {/* DIALOG DELETE OFFICER */}
      <DialogConfirmation
        title='Hapus Petugas'
        caption='Apa Anda yakin ingin menghapus petugas ini?'
        dialogConfirmationObject={dialogDeleteSettingsOfficer}
        setDialogConfirmationObject={setDialogDeleteSettingsOfficer}
        cancelButtonText='Batal'
        continueButtonText='Hapus'
        onContinueButtonClick={handleDeleteButtonClick}
        onCancelButtonClick={() => setDialogDeleteSettingsOfficer({})}
      />
    </Box>
  )
}

export default SettingsOfficer
