import moment from 'moment'
import { useHistory } from 'react-router-dom'

// CONSTANTS
import { basePaths } from 'constants/paths'

// KENDO UI
import { SchedulerItem, useSchedulerEditItemResizeItemContext } from '@progress/kendo-react-scheduler'

// MUIS
import Typography from '@mui/material/Typography'

// SERVICES
import { putDispatchUpdate } from 'services/DispatchService'

// STYLES
import useStyles from './timelineSchedulerUseStyles'

const CustomItem = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const [stateResize, setStateResize] = useSchedulerEditItemResizeItemContext()

  const updateDateRange = async (dataDispatch, startTime, endTime) => {
    const tempDataDispatchPatrols = props.dataDispatchPatrols.map(item => {
      if(item.dispatchNo === dataDispatch.dispatchNo) {
        return {
          ...item,
          start: startTime,
          end: endTime,
        }
      } else return item
    })
    props.setDataDispatchPatrols([...tempDataDispatchPatrols])

    const response = await putDispatchUpdate(dataDispatch.dispatchNo, {
      'dispatchNo': dataDispatch.dispatchNo,
      'category': dataDispatch.category,
      'dispatchType': dataDispatch.dispatchType,
      'label': dataDispatch.label,
      'address': dataDispatch.address,
      'startTime': moment(startTime).toISOString().split('.')[0],
      'endTime': moment(endTime).toISOString().split('.')[0],
      'description': dataDispatch.description
    })

    if(response?.status !== 'error') {
      props.reloadData()
    }
  }

  return (
    <SchedulerItem
      {...props}
      style={{
        ...props.style,
      }}
      className={classes.customItem}
      occurrenceDialog={() => null}
      onClick={(event) => {
        const dataDispatch = event.target.props.dataItem.originData
        history.push(`${basePaths.dispatchPatrols}?mode=edit&id=${event.target.props.dataItem.originData.dispatchNo}`, {
          mode: 'edit',
          data: {
            'dispatchNo': dataDispatch.dispatchNo,
            'category': dataDispatch.category,
            'dispatchType': dataDispatch.dispatchType,
            'label': dataDispatch.label,
            'address': dataDispatch.address,
            'startTime': dataDispatch.startTime,
            'endTime': dataDispatch.endTime,
            'deviceNo': dataDispatch.deviceNo,
          }
        })
      }}
      onResizeRelease={event => {
        props.onResizeRelease(event)
        const originData = event.target.props.dataItem.originData

        updateDateRange(
          originData,
          stateResize.start,
          stateResize.end
        )
      }}
    >
      <Typography variant='caption' className={classes.taskItemTitle} noWrap>{props.dataItem.title}</Typography>
      <Typography variant='caption' className={classes.taskItemDesc} noWrap>
        {moment(props.dataItem.start).format('HH:mm')} - {moment(props.dataItem.end).format('HH:mm')} • {Number(moment.duration(moment(props.dataItem.end, 'HH:mm').diff(moment(props.dataItem.start, 'HH:mm'))).asHours()).toFixed(0)} jam
      </Typography>
    </SchedulerItem>
  )
}

export default CustomItem