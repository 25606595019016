// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  dataSourceSelect: {
    marginBottom: 16,
  },
  dataSourceSelectIcon: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderRadius: 2,
    height: 16,
    width: 16,
    alignSelf: 'center',
  },
  dataSourceMenuItem: {
    '&.MuiButtonBase-root': {
      marginRight: 8,
      marginLeft: 8,
    },
  },
  parentListItem: {
    height: 32,
    padding: 8,
    backgroundColor: alpha(colors.fiord, 0.05),
    borderRadius: 2, 
    borderTop: `1px solid ${theme.palette.divider}`,
    '& .MuiListItemIcon-root': {
      minWidth: 'unset',
      marginRight: 12,
    },
    '& .MuiTypography-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%',
      fontWeight: 600,
    },
  },
  childrenListItem: {
    height: 36,
    padding: 8,
    borderRadius: 4,
    border: '1px solid transparent',
    '& .MuiListItemIcon-root': {
      marginLeft: 2,
      minWidth: 'unset',
      marginRight: 12,
      color: theme.palette.grey[400],
    },
    '& .MuiTypography-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%',
    },
    '& .childrenListItemActions': {
      display: 'none',
    },
    '& .childrenListItemIconButton': {
      width: 28,
      height: 28,
    },
    '&:hover': {
      borderColor: theme.palette.divider,
      backgroundColor: 'unset',
      '& .childrenListItemActions': {
        display: 'flex',
      },
    },
  },
}))

export default useStyles
