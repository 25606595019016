// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      flexDirection: 'row',
      width: '100%',
      height: '100%',
    },
  },
  iconCloseDialog: {
    position: 'absolute',
    top: 10,
    left: 10,
    zIndex: 4,
    color: 'white',
    backgroundColor: theme.palette.grey[800],
    '&:hover': {
      backgroundColor: theme.palette.grey[800],
      opacity: 0.6,
    },
  },
  informationList: {
    padding: '0px 8px',
  },
  informationListItem: {
    alignItems: 'unset',
    '& .MuiListItemIcon-root': {
      minWidth: 'unset',
      marginRight: 12,
      marginTop: 4,
    },
    '& .MuiListItemText-primary': {
      fontWeight: 600,
      color: theme.palette.text.secondary,
    },
    '& .MuiListItemText-secondary': {
      color: theme.palette.text.primary,
    },
  },
  informationValueChip: {
    width: 'fit-content',
    height: 24,
    '& .MuiChip-label': {
      fontWeight: 600,
      paddingLeft: 8,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& .MuiChip-icon': {
      width: '16px !important',
      height: '16px !important',
    },
  },
  informationValueChipSos: {
    '& .MuiChip-label': {
      paddingRight: 8,
    },
  },
  informationValueUploader: {
    color: 'white',
    borderColor: colors.raven,
    backgroundColor: colors.raven,
    marginTop: 4,
  },
  informationValueUploaderIcon: {
    color: 'white !important',
  },
  mediaItemVisual: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    index: 2,
    borderRadius: 4,
  },
  mediaItemBrokenIcon: {
    width: 32,
    height: 32,
  },
  mediaItemIcon: {
    width: 20,
    height: 20,
    color: 'white',
    position: 'absolute',
    zIndex: 3,
  },
  mediaItemAudio: {
    color: 'white',
  },
  cursortPointer: {
    cursor: 'pointer',
  },
}))

export default useStyles