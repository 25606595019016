import React, { createContext, useState } from 'react'

export const PagePatrolsContext = createContext()

const PagePatrolsContextProvider = (props) => {
  // OBJECT
  const [ selectedEvidenceItem, setSelectedEvidenceItem ] = useState(null)

  return (
    <PagePatrolsContext.Provider
      value={{
        // OBJECTS
        selectedEvidenceItem, setSelectedEvidenceItem,
      }}
    >
      {props.children}
    </PagePatrolsContext.Provider>
  )
}

export default PagePatrolsContextProvider