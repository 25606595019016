// MUIS
import ListItemButton from '@mui/material/ListItemButton'
import { styled } from '@mui/material/styles'

const CustomListItemButton = styled(({ className, ...props }) => (
  <ListItemButton
    className={className} 
    {...props}
  />
))(({ theme }) => ({
  fontSize: 14,
  '&:hover': {
    backgroundColor: 'slategray',
  },
}))

export default CustomListItemButton