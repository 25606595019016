// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconWalkieTalkie = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M7.1875 2C6.94653 2 6.75 2.18335 6.75 2.40815V5.431C6.79272 5.41187 6.82861 5.39911 6.87305 5.37998C7.11572 5.27476 7.36865 5.17272 7.625 5.08663V2.40815C7.625 2.18335 7.42847 2 7.1875 2ZM15.9785 4.44889C15.4556 4.44889 14.9873 4.76298 14.8027 5.23968L14.707 5.52028C13.95 5.36882 13.0579 5.26519 12 5.26519C10.127 5.26519 8.57007 5.54739 7.24219 6.11975C5.52295 6.86112 5 7.71727 5 9.80585C5 17.2577 5.68018 19.6875 5.88867 20.2519L5.92969 20.3412C6.25952 21.2468 6.50903 21.7251 8.28125 21.8717C9.56812 21.9786 11.1318 21.9993 11.9453 21.9993C11.9658 21.9993 11.9829 22.0009 12 21.9993C12.0171 22.0009 12.0342 21.9993 12.0547 21.9993C12.8682 21.9993 14.4302 21.9786 15.7188 21.8717C17.4927 21.7235 17.7405 21.25 18.0703 20.3412L18.1113 20.2519C18.3796 19.5297 19 17.0569 19 9.80585C19 8.25616 18.6958 7.34898 17.8789 6.71922L18.043 6.31107C18.2771 5.70841 17.9302 5.04677 17.291 4.83153L16.4023 4.51267C16.2656 4.46643 16.1221 4.44889 15.9785 4.44889ZM9.26562 9.34668H14.748C14.9258 9.34668 15.0625 9.50133 15.0625 9.69105V9.8186C15.0625 10.0083 14.9258 10.163 14.748 10.163H9.26562C9.08789 10.163 8.9375 10.0083 8.9375 9.8186V9.69105C8.9375 9.50133 9.08789 9.34668 9.26562 9.34668ZM9.26562 10.9793H14.748C14.9258 10.9793 15.0625 11.1339 15.0625 11.3236V11.4512C15.0625 11.6409 14.9258 11.7956 14.748 11.7956H9.26562C9.08789 11.7956 8.9375 11.6409 8.9375 11.4512V11.3236C8.9375 11.1339 9.08789 10.9793 9.26562 10.9793ZM9.26562 12.6119H14.748C14.9258 12.6119 15.0625 12.7665 15.0625 12.9562V13.0838C15.0625 13.2735 14.9258 13.4282 14.748 13.4282H9.26562C9.08789 13.4282 8.9375 13.2735 8.9375 13.0838V12.9562C8.9375 12.7665 9.08789 12.6119 9.26562 12.6119Z'/>
    </SvgIcon>
  )
}

export default IconWalkieTalkie