// MUI STYLES
import { makeStyles } from '@mui/styles'
import { colors } from 'constants/colors'

const useStyles = makeStyles((theme) => ({
  sectionText: {
    fontWeight: 600,
    marginBottom: 16,
  },
  pockbaseStyles: {
    backgroundColor: '#e4e9ec',
    transition: 'background-color .4s',
    padding: 4,
    borderRadius: 2,
    '&:focus-within': {
      backgroundColor: '#D7DDE1'
    }
  },
  pockbaseStylesLabel: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginLeft: 8,
    fontWeight: 600,
  },
  formControl: {
    marginBottom: 16,
    borderRadius: '2px !important',
  },
  addIconWrap: {
    backgroundColor: theme.palette.common.white,
    height: 48,
    width: 48,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 2,
  },
  iconAddImage: {
    color: colors.silver,
    fontSize: 36,
  },
  iconAddedView: {
    fontSize: 36,
    color: theme.palette.text.secondary,
  },
  btnAddImage: {
    padding: 8,
  },
  boxColor: {
    height: 32,
    width: 32,
    borderRadius: 4,
    cursor: 'pointer',
  }
}))

export default useStyles