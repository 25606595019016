import { useState, useEffect, useContext, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// COMPONENTS
import ContentTabs from 'components/ContentTabs/ContentTabs'
import DialogAddOrEditChannel from './DialogAddOrEditChannel/DialogAddOrEditChannel'
import DialogAddDevice from './DialogAddDevice/DialogAddDevice'
import DialogAssignChannels from './DialogAssignChannels/DialogAssignChannels'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import FlyoutDevice from './FlyoutDevice/FlyoutDevice'
import LoadingBox from 'components/LoadingBox/LoadingBox'
import PageHeader from 'components/PageHeader/PageHeader'
import TableChannels from './TableChannels/TableChannels'
import TableDevices from './TableDevices/TableDevices'

// CONSTANTS
import { basePaths } from 'constants/paths'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

// SERVICES
import { deleteChannel } from 'services/PushToTalkServices'

// STYLES
import useStyles from './pushToTalksUseStyles'

// UTILITIES
import { capitalizeEachWord } from 'utilities'

const PushToTalks = () => {
  const classes = useStyles()
  const pageRef = useRef()

  const history = useHistory()
  const location = useLocation()

  const { setSnackbarObject } = useContext(AllPagesContext)

  const tabList = [ 
    {
      label: 'PERANGKAT',
      value: 'devices',
      hasBadge : false,
    },
    {
      label: 'KANAL',
      value: 'channels',
      hasBadge : false,
    },
  ]

  const flyoutWidth = 528
  const flyoutTitleMargin = 500 // TO MAKE THE TITLE VERTICALLY ALIGN WITH THE FLYOUT COMPONENT

  const [ selectedTab, setSelectedTab ] = useState(tabList[0].value)
  const [ search, setSearch ] = useState('')
  const [ isTableLoading, setIsTableLoading ] = useState(false)
  const [ deviceTableData, setDeviceTableData ] = useState([])
  const [ channelTableData, setChannelTableData ] = useState([])
  const [ deviceSelectionModel, setDeviceSelectionModel ] = useState([])
  const [ isDeviceFlyoutShown, setIsDeviceFlyoutShown ] = useState(false)
  const [ selectedDevice, setSelectedDevice ] = useState(null)
  const [ dialogAddOrEditChannel, setDialogAddOrEditChannel ] = useState(null)
  const [ dialogAddDevice, setDialogAddDevice ] = useState(null)
  const [ dialogDeleteItem, setDialogDeleteItem ] = useState({})
  const [ mustReloadTableChannels, setMusReloadTableChannels ] = useState(true)
  const [ mustReloadTableDevices, setMusReloadTableDevices ] = useState(true)
  const [ dialogAssignChannels, setDialogAssignChannels ] = useState(null)

  const handleAddButtonClick = () => {
    history.push(
      `${basePaths.pushToTalks}?mode=add&tab=${selectedTab}`, 
      { mode: 'add', tab: selectedTab }
    )    
  }

  const handleDeleteItem = async () => {
    let result
    if (selectedTab === tabList[0].value) {}
    else if (selectedTab === tabList[1].value) {
      result = await deleteChannel({ groupIds: [ dialogDeleteItem.params.row.groupId] })
    }
    
    let severity, message
    if (result.status === 200) {
      severity = 'success'
      message = 'Sukses menghapus kanal'

      setDialogDeleteItem({})
      setMusReloadTableChannels(true)
    }
    else {
      severity = 'error'
      message = 'Gagal menghapus kanal'
    }

    setSnackbarObject({
      open: true,
      severity,
      title: '',
      message,
    })
  }

  useEffect(() => {
    if (selectedTab === tabList[0].value) {
      if (deviceSelectionModel.length === 0 || deviceSelectionModel.length > 1) setSelectedDevice(null)
      else if (deviceSelectionModel.length === 1) {
        const selectedDevice = deviceTableData.find(item => item.id === deviceSelectionModel[0])
        setSelectedDevice(selectedDevice)
        setIsDeviceFlyoutShown(true)
      }
    }
  }, [deviceSelectionModel])

  useEffect(() => {
    if (location.pathname === basePaths.pushToTalks && (location.search.includes('?mode=edit') || location.search.includes('?mode=add'))) {
      if (selectedTab === tabList[0].value) setDialogAddDevice(location?.state)
      else if (selectedTab === tabList[1].value) setDialogAddOrEditChannel(location?.state)
    }
    else {
      setDialogAddOrEditChannel(null)
      setDialogAddDevice(null) 
    }
  }, [location])

  useEffect(() => {
    if (selectedTab === tabList[0].value) setMusReloadTableDevices(true)
    else if (selectedTab === tabList[1].value) {
      setMusReloadTableChannels(true)
      setIsDeviceFlyoutShown(false)
    }
  }, [selectedTab])

  return (
    <Stack
      className={classes.pageRoot}
      ref={pageRef}
    >
      {/* TABS */}
      <ContentTabs
        tabItemList={tabList}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        isExtraComponentAvailable={false}
        extraComponent={null}
        disabledPaddingTabs
        classNameTab={classes.customTab}
        tabsHeight={59}
      />

      {/* HEADER */}
      <Stack margin='24px 24px 0 24px'>
        <PageHeader
          isAddButtonAvailable={false}
          onAddButtonIsClicked={handleAddButtonClick}
          title={`${capitalizeEachWord(tabList.find(item => item.value === selectedTab).label)} Push To Talk`}
          setSearch={setSearch}
          search={search}
          isFlyoutAvailable={selectedTab === tabList[0].value ? true : false}
          isFlyoutShown={selectedTab === tabList[0].value && isDeviceFlyoutShown}
          flyoutTitle=''
          flyoutTitleMargin={`${flyoutTitleMargin}px`}
          onToggleFlyoutClick={() => setIsDeviceFlyoutShown((current) => !current)}
        />
      </Stack>

      {/* MAIN CONTENT AND FLYOUT */}
      <Box 
        className={classes.contentContainer}
        sx={{ paddingRight: isDeviceFlyoutShown ? `${flyoutWidth + 24}px` : 0 }}
      >
        {/* CONTENT */}
        <LoadingBox isLoading={isTableLoading}>
          {/* DEVICES TABLE */}
          {selectedTab === tabList[0].value && 
          <TableDevices 
            search={search}
            tabList={tabList}
            deviceTableData={deviceTableData}
            setDeviceTableData={setDeviceTableData}
            deviceSelectionModel={deviceSelectionModel}
            setDeviceSelectionModel={setDeviceSelectionModel}
            mustReloadTableDevices={mustReloadTableDevices}
            setMusReloadTableDevices={setMusReloadTableDevices}
            setDialogDeleteDevice={setDialogDeleteItem}
          />}

          {/* CHANNELS TABLE */}
          {selectedTab === tabList[1].value &&
          <TableChannels
            search={search}
            tabList={tabList}
            channelTableData={channelTableData}
            setChannelTableData={setChannelTableData}
            setDialogDeleteChannel={setDialogDeleteItem}
            mustReloadTableChannels={mustReloadTableChannels}
            setMusReloadTableChannels={setMusReloadTableChannels}
          />}
        </LoadingBox>

        {/* DEVICE DETAIL */}
        {selectedTab === tabList[0].value &&
        <FlyoutDevice
          isDeviceFlyoutShown={isDeviceFlyoutShown}
          flyoutWidth={flyoutWidth}
          deviceSelectionModel={deviceSelectionModel}
          selectedDevice={selectedDevice}
          setDialogAssignChannels={setDialogAssignChannels}
        />}
      </Box>

      {/* DIALOG ADD OR EDIT CHANNEL */}
      <DialogAddOrEditChannel
        pageRef={pageRef}
        mode={location?.state?.mode}
        dialogAddOrEditChannel={dialogAddOrEditChannel}
        setMusReloadTableChannels={setMusReloadTableChannels}
      />

      {/* DIALOG ADD DEVICE */}
      <DialogAddDevice
        pageRef={pageRef}
        dialogAddDevice={dialogAddDevice}
      />

      {/* DIALOG DELETE ITEM */}
      <DialogConfirmation
        title={`Hapus ${tabList.find(item => item.value === selectedTab).label.toLowerCase()}`}
        caption={`Apa Anda yakin ingin menghapus ${tabList.find(item => item.value === selectedTab).label.toLowerCase()} ini?`}
        dialogConfirmationObject={dialogDeleteItem}
        setDialogConfirmationObject={setDialogDeleteItem}
        cancelButtonText='Batal'
        continueButtonText='Hapus'
        onContinueButtonClick={handleDeleteItem}
        onCancelButtonClick={() => setDialogDeleteItem({})}
      />
      
      {/* DIALOG ASSIGN CHANNEL */}
      <DialogAssignChannels
        dialogAssignChannels={dialogAssignChannels}
        setDialogAssignChannels={setDialogAssignChannels}
        setMusReloadTableDevices={setMusReloadTableDevices}
      />
    </Stack>
  )
}

export default PushToTalks