// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  mediaRoot: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginRight: 24,
  },
  mediaHeader: {
    padding: '16px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mediaTitle: {
    fontWeight: 600,
    fontSize: 18,
  },
  mediaSelect: {
    width: 170,
  },
  masonryContainer: {
    flex: 1,
    padding: 24,
    overflowY: 'auto'
  },
  masonry: {
    '&.MuiMasonry-root': {
      alignContent: 'flex-start',
    },
  },
  masonryItem: {
    padding: 16,
    height: 'fit-content',
  },
  evidenceStatus : {
    marginBottom : 16,
    marginLeft : 43,
    fontSize : 12,
    fontWeight : 400
  },
  masonryAvatarContainer: {
    '&.MuiListItemAvatar-root': {
      minWidth: 'unset',
    },
  },
  masonryAvatar: {
    marginRight: 8,
    width: 35,
    height: 35,
    '&.MuiAvatar-root': {
      fontSize: 18,
      fontWeight: 600,
    },
  },
  masonryMoreIcon: {
    cursor: 'pointer',
    color: colors.raven,
  },
  moreMenuRoot: {
    '& .MuiList-root': {
      width: 140,
    },
    '& .MuiPaper-root': {
      boxShadow: theme.shadows[1],
    },
  },
  masonryMedia: {
    width: '100%',
    maxWidth: '100%',
    cursor: 'pointer',
  },
  masonryCaption: {
    fontSize: 12,
    color: colors.raven,
    fontWeight: 400,
    margin: '16px 0px',
  },
  masonryActions: {
    display: 'flex',
    marginTop: 16,
  },
  masonryActionIcon: {
    cursor: 'pointer',
    color: colors.raven,
    height: 18,
    width: 18,
    marginRight: 16,
  },
  masonryActionLast: {
    marginLeft: 'auto',
    marginRight: 0,
  },
}))

export default useStyles