// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  btnIconFilter: {
    marginRight: 8,
    '&.lastChildIcon': {
      marginRight: 0
    }
  }
}))

export default useStyles