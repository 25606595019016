// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import Slider from '@mui/material/Slider'
import { styled } from '@mui/material/styles'

const CustomSlider = styled(({ className, ...props }) => (
  <Slider
    className={className} 
    {...props}
  />
))(({ theme }) => ({
  '& .MuiSlider-valueLabel': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
    fontSize: 12,
  },
  '& .MuiSlider-rail': {
    backgroundColor: colors.seashell,
    opacity: 1,
  },
}))

export default CustomSlider