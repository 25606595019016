import axios from 'axios'
import { getToken } from './auth'
import nprogress from 'nprogress'

const API_URL = process.env.REACT_APP_API_URL
const http = axios.create({ baseURL: API_URL })

export const setupInterceptors = (history, location) => http.interceptors.response.use(function (config) {
  nprogress.done()
  return config
}, err => {
  nprogress.done()
  const { status } = err.response

  if (status === 401) {
    // here we have access of the useHistory() from current Router
    localStorage.removeItem('token')
    localStorage.removeItem('user')

    if(location.pathname === '/forgot-password'
      || location.pathname.includes('/reset-password')
    ) {
      return null
    } else  history.push('/login')
  }

  if (status === 403) {
    // here we have access of the useHistory() from current Router
    history.push('/profile')
  }

  return Promise.reject(err)
})

http.interceptors.request.use((config) => {
  nprogress.start()
  const token = getToken()
  config.headers.Authorization  = token ? `Bearer ${token}` : ''
  return config
},
(error) => Promise.reject(error),
)

const http2 = axios.create({ baseURL: API_URL }) // for server

export { http, http2 }
