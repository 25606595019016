import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

// AGORA
import AgoraRTC from 'agora-rtc-sdk-ng'

// MUIS
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'

// MUI ICONS
import IconMic from '@mui/icons-material/Mic'
import IconMicOff from '@mui/icons-material/MicOff'
import IconVideocam from '@mui/icons-material/Videocam'
import IconVideocamOff from '@mui/icons-material/VideocamOff'

// SERVICE
import { 
  getAgentHelpList, 
  putAgentHelpJoin, 
} from 'services/AgentHelpServices'

// STYLES
import useStyles from './agentHelpMeetUseStyles' // SERVICE

// UTILITIES
import { getCompanyLogo } from 'utilities'

const Lobby = (props) => {
  const { isMicOn, setIsMicOn, isCameraOn, setIsCameraOn, setIsAccepted } = props

  const params = useParams()
  const classes = useStyles()
  const computerVideoRef = useRef()
  const computerVideoTrackRef = useRef()
  const computerMicTrackRef = useRef()
  const agoraRTCRef = useRef(
    AgoraRTC.createClient({
      mode: 'rtc',
      codec: 'vp8',
    })
  )
  const [listData, setListData] = useState([])
  const [userName, setUsername] = useState([])

  const dummyUserImage =
        'https://img.freepik.com/free-photo/portrait-male-call-center-agent_23-2148094837.jpg'

  const enableWebCame = async () => {
    const [microphoneTrack, cameraTrack] = await AgoraRTC.createMicrophoneAndCameraTracks()
    cameraTrack.play(computerVideoRef.current)
    computerVideoTrackRef.current = cameraTrack
    computerMicTrackRef.current = microphoneTrack
    if(!isCameraOn){
      await computerVideoTrackRef.current.setEnabled(false)
    } else{ await computerVideoTrackRef.current.setEnabled(true)}
    if(!isMicOn){
      computerMicTrackRef.current.setEnabled(false)
    } else computerMicTrackRef.current.setEnabled(true)
  }

  const handleButtonJoin = async () => {
    const bodyRequest = {
      active: listData[0].active,
      adminName: userName.userName,
      adminUid: userName.email,
      channelId: listData[0].channelId,
      id: listData[0].id,
      hostUid: listData[0].hostUid,
      type : 'AGENT_HELP',
    }
    const result = await putAgentHelpJoin(bodyRequest)
    if(result.status === 200){
      setIsAccepted(true)
    }
  }

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('user'))
    setUsername(user)
    const loadAgentHelpList = () => {
      getAgentHelpList({
        page: 0,
        size: 1000,
      }).then((response) => {
        setListData(
          response.rows.filter(
            (item) => item.channelId === params.meetId
          )
        )
      })
    }
    loadAgentHelpList()
  }, [params])

  useEffect(() => {
    enableWebCame()
  }, [])

  useEffect(()=>{
    return async () => {
      if(isCameraOn){
        computerVideoTrackRef.current.setEnabled(false)
      } else computerVideoTrackRef.current.setEnabled(true)
    }
  },[isCameraOn])

  useEffect(()=>{
    return async () => {
      if(isMicOn){
        computerMicTrackRef.current.setEnabled(false)
      } else computerMicTrackRef.current.setEnabled(true)
    }
  },[isMicOn])
  
  return (
    <Box className={classes.content}>
      {/* COMPANY LOGO */}
      <Box
        component='img'
        src={getCompanyLogo('light')}
        alt=''
        className={classes.lobbyLogoCompany}
      />

      {/* AGENT IMAGE */}
      <Box
        ref={(el)=>(computerVideoRef.current = el)}
        className={classes.lobbyWebCam}
      />

      {/* USER ACTIONS */}
      <Box className={classes.lobbyActionsContainer}>
        {/* MIC ICON */}
        <IconButton
          className={classes.actionIconButton}
          onClick={() => setIsMicOn((current) => !current)}
        >
          {isMicOn ? <IconMic /> : <IconMicOff />}
        </IconButton>

        {/* VIDEO ICON */}
        <IconButton
          className={classes.actionIconButton}
          onClick={() => setIsCameraOn((current) => !current)}
        >
          {isCameraOn ? <IconVideocam /> : <IconVideocamOff />}
        </IconButton>

        {/* JOIN BUTTON */}
        <Button
          variant='contained'
          className={classes.lobbyActionButton}
          onClick={() => handleButtonJoin()}
        >
          Bergabung Sekarang
        </Button>
      </Box>
    </Box>
  )
}

export default Lobby
