import { http } from 'utilities/http'
import { stringify } from 'query-string'

export const getSosMessageTypesList = async (queryParams) => {
  try {
    const { data } = await http.get(`/sos-message-type?${stringify(queryParams)}`)
    return data
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

export const postSosMessageTypes = async (bodyParams) => {
  try {
    const { data } = await http.post('/sos-message-type', bodyParams)
    return data
  } catch (error) {
    return {
      status: 'error'
    }
  }
}

export const putSosMessageTypes = async (id, bodyParams) => {
  try {
    const { data } = await http.put(`/sos-message-type/${id}`, bodyParams)
    return data
  } catch (error) {
    return {
      status: 'error'
    }
  }
}

export const deleteSosMessageTypes = async (id) => {
  try {
    const { data } = await http.delete(`/sos-message-type/${id}`)
    return data
  } catch (error) {
    return {
      status: 'error'
    }
  }
}