// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconTpkp = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M15.9131 6.67976C16.9293 6.67976 17.753 5.85602 17.753 4.83988C17.753 3.82374 16.9293 3 15.9131 3C14.897 3 14.0732 3.82374 14.0732 4.83988C14.0732 5.85602 14.897 6.67976 15.9131 6.67976Z' />
      <path d='M12.6422 5.26452L13.9002 5.81492L15.284 6.63264L16.8723 8.18946C17.1396 8.44107 17.3284 8.77131 17.4384 9.11727L18.2719 11.8378C18.3505 12.1051 18.3191 12.3882 18.1775 12.624L16.6207 15.2659C16.4477 15.5647 16.0389 15.6591 15.7558 15.4546C15.5199 15.2974 15.4413 14.9986 15.5356 14.7313L16.4477 12.4825C16.5421 12.2466 16.5421 11.995 16.4477 11.7749L15.976 10.564C15.9131 10.391 15.6615 10.3596 15.5671 10.5326L9.55997 20.5654C9.30836 20.99 8.74224 21.1315 8.33338 20.8642C7.95597 20.6283 7.81444 20.1251 8.01887 19.7162L11.1482 13.426C11.2426 13.2216 11.164 12.97 10.9595 12.8757L10.4878 12.6555C10.3777 12.6083 10.2519 12.6083 10.1418 12.6555L8.75797 13.3002C8.50636 13.4103 8.38056 13.6777 8.41201 13.945L8.85232 16.8385C8.91522 17.2316 8.67934 17.609 8.30193 17.7348C7.86161 17.8764 7.40558 17.609 7.27977 17.1687L6.02173 12.4982C5.94311 12.2152 6.08464 11.9321 6.33624 11.8063L10.2362 9.84064L11.1954 7.73342C11.2583 7.5919 11.1011 7.45037 10.9753 7.52899L9.11965 8.59833C8.77369 8.80276 8.33338 8.61405 8.25475 8.22091L7.34267 4.16374C7.26405 3.83351 7.45275 3.47182 7.78299 3.36174C8.1604 3.22021 8.58499 3.42464 8.69506 3.80206L9.48134 6.28668L11.274 5.32743C11.6986 5.09154 12.2018 5.07582 12.6422 5.26452Z' />
    </SvgIcon>
  )
}

export default IconTpkp