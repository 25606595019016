import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

// COMPONENTS
import SimpleBackdrop from 'components/Partials/SimpleBackdrop'

// CUSTOM COMPONENTS
import CustomDialogActionButton from 'components/Customs/CustomDialogActionButton'
import CustomDialogActions from 'components/Customs/CustomDialogActions'
import CustomDialogDelete from 'components/Customs/CustomDialogDelete'

// MUIS
import Typography from '@mui/material/Typography'

// SERVICES
import { putUpdateEvidenceReviewStatus } from 'services/EvidenceService'

// STORES
import { showAlert } from 'store/MainReducer'

// STYLES
import useStyles from './dialogChangeStatusUseStyles'

const DialogChangeStatus = (props) => {
  const {
    dialogChangeStatus, 
    setDialogChangeStatus,
    setSelectedStatus,
    reloadData,
  } = props

  const classes = useStyles()

  const [ isLoading, setIsLoading ] = useState(false)

  const dispatch = useDispatch()

  const handleDialogClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setDialogChangeStatus(false)
    }
  }

  const handleCancelButtonClick = () => {
    setSelectedStatus(dialogChangeStatus.oldStatus)
    setDialogChangeStatus(null)
  }

  const handleOkButtonClick = async () => {
    setIsLoading(true)

    const result = await putUpdateEvidenceReviewStatus(
      dialogChangeStatus.id, 
      { expectedStatus: dialogChangeStatus.newStatus }
    )

    setIsLoading(false)

    if(result.status !== 'error') {
      dispatch(showAlert({ message: 'Sukses Mengubah Status Review' }))
      setSelectedStatus(dialogChangeStatus.newStatus)
      setDialogChangeStatus(null)
      reloadData()
    }
  }

  return (
    <CustomDialogDelete
      open={Boolean(dialogChangeStatus)}
      onClose={handleDialogClose}
    >
      {/* TITLE */}
      <Typography
        variant='subtitle2'
        className={classes.title}
      >
        Ubah item?
      </Typography>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions className={classes.dialogActions}>
        {/* CANCEL BUTTON */}
        <CustomDialogActionButton 
          className={classes.cancelButton}
          color='default'
          onClick={handleCancelButtonClick}
        >
          Batal
        </CustomDialogActionButton>

        {/* OK BUTTON */}
        <CustomDialogActionButton onClick={handleOkButtonClick}>
          Ubah
        </CustomDialogActionButton>
      </CustomDialogActions>

      {/* LOADING */}
      <SimpleBackdrop isLoading={isLoading}/>
    </CustomDialogDelete>
  )
}

export default DialogChangeStatus