// UTILS
import { colors } from 'constants/colors'

export const groupByList = [
  {
    title: 'Don\'t Group',
    value: null,
  },
  {
    title: 'Status',
    value: 'dispatchStatuses',
  },
]

export const initialFilters = {
  dispatchNo: '',
  deviceNo: '',
  dispatchType: 'EVIDENCE',
  dispatchCategory: '',
  label: '',
  address: '',
  dispatchStatuses: ''
}

export const getColorStatusDispatch = (status) => {
  if (status === 'accepted') return colors.cornflowerBlue
  else if (status === 'completed') return colors.atlantis 
  else if (status === 'assigned') return colors.amber
  else if(status === 'failed') return colors.razzmatazz
  else if(status === 'cancelled') return colors.raven
}

export const getColorTypeDispatch = (type) => {
  if (type === 'patrol') return colors.azureRadiance
  else if (type === 'evidence') return colors.clementine
}

export const getColorCategoryDispatch = (type) => {
  if (type === 'priority') return colors.razzmatazz
  else if (type === 'normal') return colors.forestGreen
}

export const getTextStatusDispatch = (status) => {
  if (status === 'accepted') return 'Diterima'
  else if (status === 'completed') return 'Selesai'
  else if (status === 'assigned') return 'Ditugaskan'
  else if(status === 'failed') return 'Gagal'
  else if(status === 'cancelled') return 'Dibatalkan'
}

export const getTextCategoryDispatch = (type) => {
  if (type === 'priority') return 'Prioritas'
  else if (type === 'normal') return 'Normal'
}

export const getTextTypeDispatch = (type) => {
  if (type === 'patrol') return 'Kegiatan'
  else if (type === 'evidence') return 'Kejadian'
}