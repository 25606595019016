import { useContext, useEffect, useRef, useState } from 'react'

// COMPONENTS
import CustomInput from 'components/Customs/CustomInput'
import DateRangeAndTimePicker from 'components/DateRangeAndTimePicker/DateRangeAndTimePicker'
import FilterEvidence from './FilterEvidence'
import FilterObject from './FilterObject'
import TabDispatch from './TabDispatch'
import TabDispatchDetail from './TabDispatchDetail'
import TabEvidences from './TabEvidences'
import TabEvidenceDetail from './TabEvidenceDetail'
import TabObject from './TabObject'
import TabObjectDetail from './TabObjectDetail'

// CONSTANTS
import { panelsInfoStatic } from './panelInformationIntegratedConstant'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// MUIS
import CircularProgress from '@mui/material/CircularProgress'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Slide from '@mui/material/Slide'
import Stack from '@mui/material/Stack'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconArrowBack from '@mui/icons-material/ArrowBack'
import IconClose from '@mui/icons-material/Close'
import IconDateRange from '@mui/icons-material/DateRange'
import IconMenuOpen from '@mui/icons-material/MenuOpen'
import IconMoreVert from '@mui/icons-material/MoreVert'
import IconRefresh from '@mui/icons-material/Refresh'
import IconSearch from '@mui/icons-material/Search'

// STYLES
import useStyles from './panelUnifiedMapInformationUseStyles'

const PanelUnifiedMapInformation = () => {
  const classes = useStyles()
  const filterWrapperRef = useRef()

  const {
    pageRef, isPanelLeftShown, setIsPanelLeftShown, currentPanelTabLeft, setCurrentPanelTabLeft,
    setObjectSearch, evidenceList, setEvidenceSearch, isFilterSearchExpanded, setIsFilterSearchExpanded,
    isLoadingPanelUnified, evidenceDateRangeFilter, setEvidenceDateRangeFilter, userEvidenceList,
    dispatchDateRangeFilter, setDispatchDateRangeFilter, setDispatchSearch, setSelectionModel,
    markerSettings, setMarkerSettings
  } = useContext(PageTrackingContext)

  const [tempEvidenceSearch, setTempEvidenceSearch] = useState('')
  const [tempObjectSearch, setTempObjectSearch] = useState('')
  const [tempDispatchSearch, setTempDispatchSearch] = useState('')
  const [isDateRangePickerShown, setIsDateRangePickerShown] = useState(false)
  const [tempDefaultDateRange, setTempDefaultDateRange] = useState([])
  const [anchorMenuSettingMarker, setAnchorMenuSettingMarker] = useState(null)

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    classes: {
      root: classes.rootTab,
      iconWrapper: classes.iconWrapperTab,
      selected: classes.selectedTab
    }
  })

  const handleTabChange = (event, newValue) => {
    /**
     * Kejadian
     * Perangkat
     * Informasi Perangkat
     * Riwayat Kejadian
     * Penugasan
     */
    setCurrentPanelTabLeft(newValue)

    // RESET CHECKED OBJECT/DEVICE WHEN CHANGE TAB
    if (newValue === 'Kejadian' || newValue === 'Penugasan') {
      setSelectionModel([])
    }
  }

  const handleCloseButtonClick = () => {
    if (isPanelLeftShown?.title === 'Informasi Peta Terpadu') {
      setIsPanelLeftShown(false)
    } else if (isPanelLeftShown?.title === 'Detail Perangkat') {
      setCurrentPanelTabLeft('Perangkat')
      setIsPanelLeftShown({
        ...panelsInfoStatic?.panelInformation,
        currentLastTab: 'Perangkat'
      })
    } else if (isPanelLeftShown?.title === 'Detail Kejadian') {
      if (isPanelLeftShown?.currentPanel === 'Riwayat Kejadian') {
        setIsPanelLeftShown({
          ...panelsInfoStatic?.panelDeviceDetail,
          currentLastTab: 'Riwayat Kejadian'
        })
      } else if (isPanelLeftShown?.currentPanel === 'Kejadian') {
        setCurrentPanelTabLeft('Kejadian')
        setIsPanelLeftShown({
          ...panelsInfoStatic?.panelInformation,
          currentLastTab: 'Kejadian'
        })
      } else {
        setIsPanelLeftShown({
          ...panelsInfoStatic?.panelInformation,
          currentLastTab: 'Kejadian'
        })
      }
    } else if (isPanelLeftShown?.title === 'Detail Penugasan') {
      setCurrentPanelTabLeft('Penugasan')
      setIsPanelLeftShown({
        ...panelsInfoStatic?.panelInformation,
        currentLastTab: 'Penugasan'
      })
    }
  }

  const handleInputSearchChange = event => {
    if (currentPanelTabLeft === 'Kejadian') setTempEvidenceSearch(event.target.value)
    else if (currentPanelTabLeft === 'Perangkat') setTempObjectSearch(event.target.value)
    else if (currentPanelTabLeft === 'Penugasan') setTempDispatchSearch(event.target.value)
  }

  const getValueInputSearch = () => {
    if (currentPanelTabLeft === 'Kejadian') return tempEvidenceSearch
    if (currentPanelTabLeft === 'Perangkat') return tempObjectSearch
    if (currentPanelTabLeft === 'Penugasan') return tempDispatchSearch
  }

  const handleSelectDateRangeButtonClick = (inputNewDateRange) => {
    if (currentPanelTabLeft === 'Kejadian') setEvidenceDateRangeFilter({
      start: inputNewDateRange[0],
      end: inputNewDateRange[1],
      isActive: true
    })
    else if (currentPanelTabLeft === 'Penugasan') setDispatchDateRangeFilter({
      start: inputNewDateRange[0],
      end: inputNewDateRange[1],
      isActive: true
    })
    setIsDateRangePickerShown(false)
  }

  const getActiveDatePicker = () => {
    if (currentPanelTabLeft === 'Kejadian') {
      return evidenceDateRangeFilter?.isActive ? true : false
    } else if (currentPanelTabLeft === 'Penugasan') {
      return dispatchDateRangeFilter?.isActive ? true : false
    }
  }

  const getDatePickerValue = () => {
    if (currentPanelTabLeft === 'Kejadian') {
      return [evidenceDateRangeFilter.start, evidenceDateRangeFilter.end]
    } else if (currentPanelTabLeft === 'Penugasan') {
      return [dispatchDateRangeFilter.start, dispatchDateRangeFilter.end]
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (currentPanelTabLeft === 'Kejadian') {
        setEvidenceSearch(tempEvidenceSearch)
      } else if (currentPanelTabLeft === 'Perangkat') {
        setObjectSearch(tempObjectSearch)
      } else if (currentPanelTabLeft === 'Penugasan') {
        setDispatchSearch(tempDispatchSearch)
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [tempEvidenceSearch, tempObjectSearch, tempDispatchSearch])

  useEffect(() => {
    if(Boolean(isPanelLeftShown)) setCurrentPanelTabLeft(isPanelLeftShown?.currentLastTab ?? isPanelLeftShown?.defaultTab)
  }, [isPanelLeftShown])

  return (
    <>
      <Slide direction='right' in={Boolean(isPanelLeftShown)} container={pageRef.current}>
        <Stack className={classes.panelContainer}>
          {/* HEADER */}
          <Stack direction='row' className={classes.panelHeader}>
            {/* MINIMIZE ICON */}
            <IconButton 
              className={classes.iconToggle}
              onClick={() => handleCloseButtonClick()}
            >
              {isPanelLeftShown?.title === 'Informasi Peta Terpadu' && <IconMenuOpen/>}
              {isPanelLeftShown?.title !== 'Informasi Peta Terpadu' && <IconArrowBack />}
            </IconButton>

            {/* PANEL TITLE */}
            <Typography variant='subtitle1' color='black' className={classes.panelTitle}>
              {isPanelLeftShown?.title}
            </Typography>
          </Stack>

          <Divider />

          {isPanelLeftShown?.listTabs?.length ? (<>
            <Stack className={classes.tabWrapper}>
              <Tabs value={currentPanelTabLeft} onChange={handleTabChange} classes={{
                root: classes.rootTabs,
                indicator: classes.indicatorTabs
              }}>
                {isPanelLeftShown?.listTabs?.map((item, index) => (
                  <Tab
                    key={index}
                    icon={<item.Icon fontSize='small' />}
                    iconPosition='start'
                    label={item.label}
                    {...a11yProps(index)}
                    value={item.label}
                  />
                ))}
              </Tabs>
            </Stack>

            {currentPanelTabLeft !== 'Riwayat Kejadian' && <Divider />}
          </>) : ''}

          {(currentPanelTabLeft === 'Kejadian' || currentPanelTabLeft === 'Perangkat' || currentPanelTabLeft === 'Penugasan') && (
            <Stack padding='8px 16px' direction='row' flexWrap='nowrap' ref={filterWrapperRef}>
              {/* FILTER */}
              <Stack>
                {(currentPanelTabLeft === 'Kejadian' || currentPanelTabLeft === 'Penugasan') && <FilterEvidence filterWrapperRef={filterWrapperRef} />}
                {currentPanelTabLeft === 'Perangkat' && <FilterObject />}
              </Stack>

              <Divider orientation='vertical' className={classes.filterDivider} />

              {/* MENU SETTING MARKER */}
              <IconButton
                size='small'
                onClick={event => setAnchorMenuSettingMarker(event.currentTarget)}
                color={Boolean(anchorMenuSettingMarker) ? 'primary' : 'default'}
              >
                <IconMoreVert fontSize='small' />
              </IconButton>
              
              <Menu
                anchorEl={anchorMenuSettingMarker}
                open={Boolean(anchorMenuSettingMarker)}
                onClose={() => setAnchorMenuSettingMarker(null)}
                className={classes.menuSettingMarker}
              >
                <MenuItem>
                  <FormControlLabel
                    control={<Checkbox
                      size='small'
                      disableRipple
                      onChange={event => setMarkerSettings({...markerSettings, isClustering: event.target.checked })}
                      checked={markerSettings.isClustering}
                      disabled={markerSettings.isClusteringLocation}
                    />}
                    label='Klasterisasi Penanda Objek'
                  />
                </MenuItem>

                <MenuItem>
                  <FormControlLabel
                    control={<Checkbox
                      size='small'
                      disableRipple
                      onChange={event => setMarkerSettings({...markerSettings, isClusteringLocation: event.target.checked })}
                      checked={markerSettings.isClusteringLocation}
                      disabled={markerSettings.isClustering}
                    />}
                    label='Klasterisasi Berdasarkan Lokasi'
                  />
                </MenuItem>

                <MenuItem>
                  <FormControlLabel
                    control={<Checkbox
                      size='small'
                      disableRipple
                      onChange={event => setMarkerSettings({...markerSettings, isLabelShown: event.target.checked })}
                      checked={markerSettings.isLabelShown}
                    />}
                    label='Label Objek'
                  />
                </MenuItem>
              </Menu>

              {/* SEARCH */}
              <Stack flex={1} direction='row' justifyContent='flex-end'>
                <CustomInput
                  className={`${classes.inputSearch} ${isFilterSearchExpanded ? 'expanded' : 'notExpanded'}`}
                  placeholder='Pencarian'
                  endAdornment={
                  // ICON SEARCH
                    <InputAdornment position='end'>
                      <IconButton size='small' onClick={() => setIsFilterSearchExpanded(true)}>
                        <IconSearch fontSize='small'/>
                      </IconButton>

                      {isFilterSearchExpanded && <IconButton size='small' onClick={() => setIsFilterSearchExpanded(false)}>
                        <IconClose fontSize='small' />
                      </IconButton>}
                    </InputAdornment>
                  }
                  onChange={handleInputSearchChange}
                  value={getValueInputSearch()}
                />
              </Stack>

              {/* DATE RANGE PICKER */}
              {(currentPanelTabLeft === 'Kejadian' || currentPanelTabLeft === 'Penugasan') && <IconButton
                size='small'
                onClick={() => setIsDateRangePickerShown(true)}
                color={getActiveDatePicker() ? 'primary' : 'default'}
                className={getActiveDatePicker() ? classes.activeBtnDateRange : ''}
              >
                <IconDateRange fontSize='small' />
              </IconButton>}
            </Stack>
          )}

          <Stack height='100%' flex={1} overflow='hidden'>
            {currentPanelTabLeft === 'Kejadian' && <TabEvidences evidenceList={evidenceList} currentPanel='Kejadian' />}
            {currentPanelTabLeft === 'Perangkat' && <TabObject />}
            {currentPanelTabLeft === 'Informasi Perangkat' && <TabObjectDetail />}
            {currentPanelTabLeft === 'Detail Kejadian' && <TabEvidenceDetail />}
            {currentPanelTabLeft === 'Penugasan' && <TabDispatch />}
            {currentPanelTabLeft === 'Riwayat Kejadian' && <TabEvidences evidenceList={userEvidenceList} currentPanel='Riwayat Kejadian' />}
            {currentPanelTabLeft === 'Detail Penugasan' && <TabDispatchDetail />}
          </Stack>

          {/* LOADING */}
          {isLoadingPanelUnified && <Stack className={classes.loadingContainer}>
            <CircularProgress className={classes.loading}/>
          </Stack>}
        </Stack>
      </Slide>

      {/* DIALOG DATE RANGE PICKER */}
      <Dialog
        open={isDateRangePickerShown}
        onClose={() => setIsDateRangePickerShown(false)} 
        className={classes.dateRangeAndTimePickerDialog}
      >
        <DateRangeAndTimePicker
          value={getDatePickerValue()}
          handleSelectButtonClick={handleSelectDateRangeButtonClick}
          handleDefaultButtonClick={() => setTempDefaultDateRange([evidenceDateRangeFilter.start, evidenceDateRangeFilter.end])}x
          handleCancelButtonClick={() => setIsDateRangePickerShown(false)}
          CancelIcon={<IconRefresh fontSize='small' />}
          cancelButtonText='Default'
          updateValueTempDate={tempDefaultDateRange}
          onAfterResetToDefault={() => setTempDefaultDateRange([])}
        />
      </Dialog>
    </>
  )
}

export default PanelUnifiedMapInformation