// MUIS
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { alpha } from '@mui/material/styles'

const CustomTooltip = (props) => {
  const { 
    series,
    dataPointIndex,
    categories,
    theme, 
  } = props

  return (
    <Stack style={{
      padding: 8,
      borderRadius: 0,
      backgroundColor: alpha(theme.palette.common.black, 0.8),
      border: 'none',
      color: theme.palette.common.white,
      flexDirection: 'row',
      alignItems: 'center',
      width: 208,
      justifyContent: 'space-between',
      zIndex: theme.zIndex.tooltip,
      position: 'relative'
    }} className='zoom'>
      {/* LABEL */}
      <Typography
        variant='caption'
        color='white'
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          paddingRight: '12px'
        }}
      >
        {categories[dataPointIndex]}
      </Typography>

      {/* VALUE */}
      <Typography
        variant='caption'
        color='white'
      >
        {series[0][dataPointIndex]}
      </Typography>
    </Stack>
  )
}

export default CustomTooltip