import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// COMPONENTS
import CustomInput from 'components/Customs/CustomInput'
import CustomInputLabel from 'components/Customs/CustomInputLabel'
import DialogAddOrEdit from 'components/DialogAddOrEditNew/DialogAddOrEdit'
import SimpleBackdrop from 'components/Partials/SimpleBackdrop'

// CONSTANTS
import { basePaths } from 'constants/paths'

// SERVICES
import { createSSDGroup, updateSSDGroup } from 'services/MasterDataService'

// MUIS
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'

// MUI ICONS
import IconClose from '@mui/icons-material/Close'
import { FormatColorText, AddCircle } from '@mui/icons-material'

// STYLES
import useStyles from './dialogAddOrEditSettingDisturbancesUseStyles'

// UTILITIES
import { clone } from 'ramda'
import { useDispatch } from 'react-redux'
import { showAlert } from 'store/MainReducer'

const DialogAddOrEditSettingsDisturbances = (props) => {
  const {
    dialogType,
    dialogAddOrEditSettingsDisturbances,
    setDialogAddOrEditSettingsDisturbances,
    reloadData,
    disturbanceData,
    pageRef,
  } = props

  const classes = useStyles()
  const dispatch = useDispatch()

  const history = useHistory()
  const location = useLocation()

  const [groupName, setGroupName] = useState('')
  const [groupNo, setGroupNo] = useState('')
  const [subGroups, setSubGroups] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (dialogType === 'edit') {
      if (dialogAddOrEditSettingsDisturbances?.row?.groupNo) {
        let groupdata = disturbanceData?.filter(
          (datas) =>
            datas?.groupNo === dialogAddOrEditSettingsDisturbances?.row?.groupNo
        )
        setGroupName(groupdata[0]?.groupName ?? '')
        setGroupNo(groupdata[0]?.groupNo ?? '')
        setSubGroups(groupdata[0]?.subGroups ?? [])
      } else {
        let groupdata = disturbanceData?.filter(
          (datas) =>
            datas?.groupName ===
            dialogAddOrEditSettingsDisturbances?.rowNode?.groupingKey
        )
        setGroupName(groupdata[0]?.groupName ?? '')
        setGroupNo(groupdata[0]?.groupNo ?? '')
        setSubGroups(groupdata[0]?.subGroups ?? [])
      }
    }
  }, [dialogType, dialogAddOrEditSettingsDisturbances])

  const handleInformationParamsChange = (event) => {
    setGroupName(event.target.value)
  }

  const addSubGroup = () => {
    const newSubgroups = clone(subGroups)
    newSubgroups.push({
      subGroupNo: subGroups[subGroups?.length - 1]?.groupNo
        ? parseInt(subGroups[subGroups?.length - 1]?.groupNo) + 1
        : subGroups?.length + 1,
      subGroupName: '',
    })

    setSubGroups(newSubgroups)
  }

  const handleChange = (event, item) => {
    const newSubgroups = clone(subGroups)
    newSubgroups.map((subGroup) => {
      if (subGroup.subGroupNo === item.subGroupNo) {
        subGroup.subGroupName = event.target.value
      }

      return subGroup
    })

    setSubGroups(newSubgroups)
  }

  const onRemoveSubGroup = (item) => {
    const newSubgroups = clone(subGroups)
    setSubGroups(
      newSubgroups.filter((subGroup) => {
        return subGroup.subGroupNo !== item.subGroupNo
      })
    )
  }

  const closeDialog = () => {
    setGroupName('')
    setSubGroups([])
    setDialogAddOrEditSettingsDisturbances(null)
  }
  const handleSaveBtn = async () => {
    const params = {
      groupName,
      groupNo,
      subGroups,
    }
    setIsLoading(true)

    if (dialogType?.toLowerCase() === 'add') {
      const result = await createSSDGroup(params)
      if (result.status !== 'error') {
        setIsLoading(false)
        dispatch(showAlert({ message: 'Sukses Membuat Gangguan' }))
        closeDialog()
        reloadData()
      }
    } else {
      const result = await updateSSDGroup(groupNo, params)
      if (result.status !== 'error') {
        setIsLoading(false)
        dispatch(showAlert({ message: 'Sukses Memperbarui Gangguan' }))
        closeDialog()
        reloadData()
      }
    }
  }

  useEffect(() => {
    if (
      location.pathname === basePaths.settingsDisturbances && 
      location.search !== '?mode=add' && 
      !location.search.includes('?mode=edit')
    ) {
      closeDialog()
    }
  }, [location])

  let selectedTitle = ''
  if (dialogType === 'add') selectedTitle = 'Tambah Gangguan'
  else if (dialogType === 'edit') selectedTitle = 'Ubah Gangguan'

  return (
    <>
      {/* DIALOG ADD OR EDIT */}
      <DialogAddOrEdit
        open={Boolean(dialogAddOrEditSettingsDisturbances)}
        title={selectedTitle}
        onCloseIconClick={() => history.push(basePaths.settingsDisturbances)}
        containerRef={pageRef}
        contents={[
          <>
            {/* MAIN SECTION TEXT */}
            <Typography variant='h6' className={classes.sectionText}>
              Informasi Utama
            </Typography>

            {/* DISTURBANCE / GROUP NAME INPUT */}
            <Box className={classes.iconAndFormControlContainer}>
              <FormatColorText className={classes.iconFormControl} />
              <FormControl variant='standard' className={classes.formControl}>
                <CustomInputLabel>Nama Gangguan</CustomInputLabel>
                <CustomInput
                  type='text'
                  name='groupName'
                  value={groupName}
                  onChange={handleInformationParamsChange}
                />
              </FormControl>
            </Box>

            {/* SUB GROUP INPUT */}
            <Box className={classes.iconAndFormControlContainer}>
              <Box className={classes.subGroupTitleContainer}>
                <Typography className={classes.subGroupText}>Bagian Kelompok</Typography>
                <IconButton onClick={addSubGroup}>
                  <AddCircle color={'primary'} />
                </IconButton>
              </Box>
            </Box>
            <Box
              className={[
                classes.iconAndFormControlContainer,
                classes.flexColumnSubGroup,
              ]}
            >
              {subGroups?.map((item, index) => {
                return (
                  <Box key={index} className={classes.subGroupTitleContainer}>
                    <FormControl variant='standard' className={classes.formControl}>
                      <CustomInputLabel>Bagian Kelompok {index + 1}</CustomInputLabel>
                      <CustomInput
                        type='text'
                        value={item.subGroupName}
                        onChange={(e) => handleChange(e, item)}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={() => onRemoveSubGroup(item)}
                            >
                              <IconClose className={classes.iconCloseSubGroup} />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Box>
                )
              })}
            </Box>
          </>
        ]}
        onCancelButtonClick={() => history.push(basePaths.settingsDisturbances)}
        onSaveButtonClick={handleSaveBtn}
      />

      {/* LOADING */}
      <SimpleBackdrop isLoading={isLoading} />
    </>
  )
}

export default DialogAddOrEditSettingsDisturbances
