import React, { useContext, useEffect } from 'react'
import moment from 'moment'

// COMPONENTS
import CarouselMediaWrapper from 'components/CardEvidence/CarouselMediaWrapper'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// CONSTANTS
import { iconListTagEvidences } from 'constants/icons'

// MUIS
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconLocationOn from '@mui/icons-material/LocationOn'
import IconPhoneAndroid from '@mui/icons-material/PhoneAndroid'
import IconSchedule from '@mui/icons-material/Schedule'
import IconSos from '@mui/icons-material/Sos'

// PATH ICONS
import IconTagLabel from 'assets/images/pathIcons/IconTagLabel'
import IconSprint from 'assets/images/pathIcons/IconSprint'

// STYLES
import useStyles from './tabEvidenceDetailUseStyles'

// UTILS
import { dateFormat } from 'utilities'

const TabEvidenceDetail = () => {
  const classes = useStyles()
  const { isPanelLeftShown, setIsDialogAddEvidenceShown, markerClustersRef } = useContext(PageTrackingContext)

  const getTagIcon = (iconObject) => {
    const SelectedIconObject = iconListTagEvidences.find(item => item.id === iconObject.iconId) 
    let SelectedIcon = SelectedIconObject.Icon
        
    return (
      <SelectedIcon
        fontSize='small'
        sx={{ color: `${iconObject.tagColor} !important` }}
      />
    )
  }

  const findInstanceMarker = (value) => {
    if (markerClustersRef.current && isPanelLeftShown?.data?.sosStatus) {
      const listLayers = markerClustersRef.current.getLayers()
      const findInstance = listLayers.find(item => item.options.objectData.evidenceNo === isPanelLeftShown.data.evidenceNo)

      findInstance?._setIsAnimation(value)
    }
  }

  const handleSubmitButtonClick = () => {
    const evidenceData = isPanelLeftShown?.data
    setIsDialogAddEvidenceShown({
      address: {
        fullAddress: evidenceData?.address,
        latitude: parseFloat(evidenceData?.latitude),
        longitude: parseFloat(evidenceData?.longitude),
      },
      category: 'NORMAL',
      description: evidenceData?.description,
      dispatchType: 'EVIDENCE',
      endTime: moment().endOf('day').toISOString(),
      label: 'Permintaan Bantuan',
      startTime: moment().startOf('day').toISOString()
    })
  }

  useEffect(() => {
    findInstanceMarker(true)

    return () => {
      findInstanceMarker(false)
    }
  }, [isPanelLeftShown])

  return (
    <Stack
      className='overflowYAuto'
      paddingBottom='100px'
    >
      <Stack className={classes.rootContent}>
        {isPanelLeftShown?.data?.medias?.length ? (<CarouselMediaWrapper
          evidenceObject={isPanelLeftShown?.data}
          setSelectedEvidenceItem={() => null}
        />) : ''}

        <Stack padding='16px'>
          {/* DEVICE & SOS */}
          <Stack className={classes.itemDetail} alignItems='center'>
            <IconPhoneAndroid className={classes.itemDetailIcon}/>
            <Typography color='black' fontWeight={600} variant='caption' flex={1} paddingLeft='4px'>{isPanelLeftShown?.data?.deviceName}</Typography>
            {isPanelLeftShown?.data?.sosStatus && <Chip className={classes.chipSOS} variant='filled' label={<IconSos />} />}
          </Stack>

          {/* LOCATION */}
          <Stack className={classes.itemDetail}>
            <IconLocationOn className={classes.itemDetailIcon}/>
            <Typography color='black' variant='caption' flex={1} paddingLeft='4px' marginTop='-2px'>
              {(typeof isPanelLeftShown?.data?.address === 'string') ? isPanelLeftShown?.data?.address : ''}
            </Typography>
          </Stack>

          {/* TIME */}
          <Stack className={classes.itemDetail} alignItems='center'>
            <IconSchedule className={classes.itemDetailIcon}/>
            <Typography color='black' variant='caption' flex={1} paddingLeft='4px'>
              {dateFormat(isPanelLeftShown?.data?.createdOn, 'dd/MM/yyy, hh:mm a')}
            </Typography>
          </Stack>

          {/* DESCRIPTION */}
          {!isPanelLeftShown?.data?.sosStatus && (
            <>
              <Stack className={classes.itemDetail}>
                <IconSprint className={classes.itemDetailIcon}/>

                <Stack paddingLeft='4px' marginTop='-2px'>
                  <Typography variant='caption' className='textSecondary'>URAIAN SINGKAT</Typography>

                  <Typography color='black' variant='caption'>
                    {isPanelLeftShown?.data?.description ? isPanelLeftShown?.data?.description : '-'}
                  </Typography>
                </Stack>
              </Stack>

              {/* TAGS */}
              <Stack className={classes.itemDetail}>
                <IconTagLabel className={classes.itemDetailIcon}/>
                <Stack paddingLeft='4px'>
                  <Typography variant='caption' className='textSecondary'>TAG</Typography>

                  {isPanelLeftShown?.data?.tags?.length ? isPanelLeftShown?.data?.tags.map((itemTag, indexTag) => (
                    <Chip
                      key={indexTag}
                      icon={getTagIcon(itemTag)}
                      size='small'
                      label={itemTag.tagName} 
                      variant='filled'
                      className={classes.evidenceItemTag}
                      sx={{ 
                        color: itemTag.tagColor,
                        border: `1px solid ${itemTag.tagColor}`, 
                      }}
                    />
                  )) : '-'}
                </Stack>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>

      <Stack className={classes.rootAssignContent}>
        <Stack padding='16px' position='relative'>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            className={classes.buttonSendHelp}
            onClick={() => handleSubmitButtonClick()}
          >
            {isPanelLeftShown?.data?.assignedDevices?.length > 0 ? 'Kirim Bantuan Lagi' : 'Kirim Bantuan'}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default TabEvidenceDetail