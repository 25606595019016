import { createSlice } from '@reduxjs/toolkit'

export const mainSlice = createSlice({
  name: 'MainStore',
  initialState: {
    evidence: {},
    patrol: {},
    alert: {
      open: false,
      message: ''
    },
    devicesVehicles: [],
    messageCount: 0,
    deviceLiveStreamNo: 0,
    deviceStopLiveStreamNo: 0,
    changePositions: [],
    changeDevicesStatus: []
  },
  reducers: {
    updateEvidence: (state, action) => {
      state.evidence = action.payload
    },
    updatePatrol: (state, action) => {
      state.patrol = action.payload
    },
    showAlert: (state, action) => {
      state.alert = {...action.payload, open: true}
    },
    hideAlert: (state, action) => {
      state.alert = {message: '', open: false}
    },
    updateDevicesVehicles: (state, action) => {
      state.devicesVehicles = action.payload
    },
    increaseMessageCount: (state, action) => {
      state.messageCount = state.messageCount + action.payload
    },
    deviceLiveStream: (state, action) => {
      state.deviceLiveStreamNo = action.payload
    },
    deviceStopLiveStream: (state, action) => {
      state.deviceStopLiveStreamNo = action.payload
    },
    onChangePositions: (state, action) => {
      state.changePositions = action.payload
    },
    onChangeDevicesStatus: (state, action) => {
      state.changeDevicesStatus = action.payload
    },
  },
})

export const {
  updateEvidence,
  updatePatrol,
  showAlert,
  updateDevicesVehicles,
  hideAlert,
  increaseMessageCount,
  deviceLiveStream,
  deviceStopLiveStream,
  onChangePositions,
  onChangeDevicesStatus
} = mainSlice.actions

export default mainSlice.reducer
