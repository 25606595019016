import React, { useState, useContext, useEffect } from 'react'

// CUSTOM COMPONENTS
import CustomInput from 'components/Customs/CustomInput'

// COMPONENTS
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import Snackbar from 'components/Snackbar/Snackbar'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { PageAlertRulesContext } from 'contexts/PageAlertRulesContext'

// MUIS
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import Typography from '@mui/material/Typography'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'

// MUI ICONS
import IconClear from '@mui/icons-material/Clear'
import IconSearch from '@mui/icons-material/Search'
import IconDelete from '@mui/icons-material/Delete'
import IconPause from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'

// STYLES
import useStyles from './paneRulesUseStyles'

// SERVICE
import { deleteRule, getAllRules } from 'services/AlertService'


const PanelRules = (props) => {
  const { pageRef } = props
  const classes = useStyles()

  // CONTEXT
  const { 
    snackbarObject, setSnackbarObject,
  } = useContext(AllPagesContext)
  const {
    rulesList, setRulesList,
    selectedRulesItem, setSelectedRulesItem,
    isRefetchingRules, setIsRefetchingRules,
    setSelectionModel
  } = useContext(PageAlertRulesContext)

  // STATES
  const [dialogSuspend, setDialogSuspend] = useState({})
  const [dialogDelete, setDialogDelete] = useState({})
  const [search, setSearch] = useState('')
  const [filteredRulesList, setFilteredRulesList] = useState([])

  const handleItemClicked = (item) => {
    setSelectedRulesItem(item)
  }

  // FETCH RULES
  const fetchListRules = async () => {
    const response = await getAllRules()
    const restructureList = response.map((item) => {
      return {
        ...item,
        isShown : true,
        isSuspend : true,
      }
    })

    setRulesList(restructureList)
    setFilteredRulesList(restructureList)
    setIsRefetchingRules(false)
  }

  // HANDLE SEARCH CHANGE
  const handleSearchChange = () => {
    const newRulesList = rulesList.map((item) => {
      let isShown = false
      if(item.name.toLowerCase().includes(search.toLowerCase())) isShown = true

      return {
        ...item,
        isShown
      }
    })
    setFilteredRulesList(newRulesList)
  }

  // HANDLE SUSPEND RULE BUTTON ?
  const handleSuspendButton = (inputItem) => {
    setDialogSuspend({})
    setSnackbarObject({
      open: true,
      severity: 'success',
      title: '',
      message: 'Sukses melakukan perubahan',
    })
  }

  // HANDLE DELETE RULE BUTTON
  const handleDeleteButton = async () => {
    const result = await deleteRule(dialogDelete.ruleNo)

    setDialogDelete({})
    setSnackbarObject({
      open: true,
      severity: 'success',
      title: '',
      message: 'Sukses Menghapus Ketentuan',
    })
    setSelectedRulesItem(false)
    setSelectionModel([])
    setIsRefetchingRules(true)
  }

  // SIDE EFFECT FETCHING LIST RULES
  useEffect(() => {
    fetchListRules()
  }, [isRefetchingRules])

  // SIDE EFFECT SEARCH
  useEffect(() => {
    search.length && handleSearchChange()
  }, [search])
  
  return (
    <Slide 
      direction='right' 
      in={true} 
      container={pageRef.current}
    >
      <Box className={classes.panelContainer}>
        {/* HEADER */}
        <Box className={classes.panelHeader}>
          {/* PANEL TITLE */}
          <Typography
            variant='h6'
            className={classes.panelTitle}
          >
            Ketentuan
          </Typography>
        </Box>

        <Divider/>

        {/* SEARCH BOX */}
        <Box className={classes.panelHeader}>
          {/* INPUT */}
          <CustomInput
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            className={classes.searchInput}
            placeholder='Pencarian Cepat'
            disableUnderline
          />

          {/* ICON */}
          {search === '' ?
            <IconButton className={classes.searchIcon}>
              <IconSearch/>
            </IconButton> :
            <IconButton 
              className={classes.searchIcon}
              onClick={() => setSearch('')}
            >
              <IconClear/>
            </IconButton>}
        </Box>

        <Divider/>

        <List disablePadding className={classes.objectList}>
          {filteredRulesList && 
          filteredRulesList.map((item, index) => (
            item.isShown &&
              <ListItem
                key={index}
                disablePadding
                className={selectedRulesItem.id === item.id ? classes.objectItemSelected : classes.objectItem}
                onClick={() => handleItemClicked(item)}
              >
                <ListItemButton
                  className={classes.objectItemButton}
                  sx={{
                    opacity: 1
                  }}
                >
                  <Typography className={classes.objectItemText}>
                    {item?.name ?? '-'}
                  </Typography>

                  <ListItemIcon className={classes.objectItemIcon}>
                    <IconButton onClick={() => setDialogDelete(item)}>
                      <IconDelete fontSize='small' />
                    </IconButton>

                    <IconButton onClick={() => setDialogSuspend(item)}>
                      {item.isSuspend ? 
                        <PlayArrowIcon fontSize='small' className={classes.playBtn} /> : 
                        <IconPause fontSize='small' className={classes.pauseBttn} />}
                    </IconButton>
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
          ))}
        </List>

        {/* DIALOG SUSPEND */}
        <DialogConfirmation
          title='Konfirmasi'
          caption={dialogSuspend.isSuspend ? `Lanjutkan ketentuan ${dialogSuspend.name} ?` : `Menangguhkan ketentuan ${dialogSuspend.name} ?`}
          dialogConfirmationObject={dialogSuspend}
          setDialogConfirmationObject={setDialogSuspend}
          cancelButtonText='Batal'
          continueButtonText={dialogSuspend.isSuspend ? 'Lanjutkan' : 'Tangguhkan'}
          onContinueButtonClick={() => handleSuspendButton(dialogSuspend)}
          onCancelButtonClick={() => setDialogSuspend({})}
        />

        {/* DIALOG DELETE */}
        <DialogConfirmation
          title='Hapus Ketentuan'
          caption='Anda yakin ingin menghapus ketentuan ini?'
          dialogConfirmationObject={dialogDelete}
          setDialogConfirmationObject={setDialogDelete}
          cancelButtonText='Batal'
          continueButtonText='Hapus'
          onContinueButtonClick={handleDeleteButton}
          onCancelButtonClick={() => setDialogDelete({})}
        />

        {/* SNACKBAR */}
        <Snackbar
          open={snackbarObject.open}
          setToast={setSnackbarObject}
          severity={snackbarObject.severity}
          title={snackbarObject.title}
          message={snackbarObject.message}
        />
      </Box>
    </Slide>
  )
}

export default PanelRules