// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'
import { alpha } from '@mui/material/styles'

const useStyles = makeStyles((theme) => ({
  listPanelPie: {
    backgroundColor: theme.palette.common.white,
    width: 256,
    padding: 8,
    maxHeight: 252,
    overflowY: 'auto',
    boxShadow: `0 2px 4px ${alpha(theme.palette.common.black, 0.16)}`,
    cursor: 'auto',
    borderRadius: 4,
    position: 'relative'
  },
  itemPanelPie: {
    padding: 0,
    marginBottom: 8,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:last-child': {
      marginBottom: 0,
    }
  },
  titleItemPie: {
    color: colors.bunker,
    fontSize: 12
  },
  boxIconWrap: {
    borderRadius: '100%',
    height: 20,
    width: 20,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 4,
    minWidth: 20,
  },
  boxIcon: {
    fontSize: 12,
    color: theme.palette.common.white,
  },
  iconClose: {
    fontSize: 16,
    color: colors.spunPearl,
  },
  btnCloseMenu: {
    position: 'absolute',
    top: 8,
    right: 8,
    height: 20,
    width: 20,
    zIndex: 9
  },
  dividerTitle: {
    margin: '8px 0'
  }
}))

export default useStyles
