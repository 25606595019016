import React, { useState, useEffect, useContext } from 'react'
import 'nprogress/nprogress.css'

import AppRouter from './AppRouter'

// COMPONENTS
import Snackbar from 'components/Snackbar/Snackbar'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// SERVICE
import { getAgentHelpList } from 'services/AgentHelpServices'
import { getDeviceStreaming } from 'services/DeviceService'
import { getConferenceList } from 'services/ConferenceService'
import { postFcmNotificationToken } from 'services/fcmNotification'
import { getTrackerList } from 'services/TrackingServices'

// UTILITIES
import { 
  fetchFirebaseToken, 
  onMessageListener,
} from 'utilities/firebase'
import { 
  getCompanyLogo, 
  getCompanyTitle, 
} from 'utilities'

function App() {
  const { 
    snackbarObject, setSnackbarObject,
    agentHelpAllData, setAgentHelpAllData,
    setAgentHelpNeedHelp, setAgentHelpOnVideoCall,
    setLiveStreamingList, setIsUpdateEvidenceList,
    setConferenceList, setBadgeType,
    userProfile,
  } = useContext(AllPagesContext)
  
  const url = `${process.env.REACT_APP_WEBSOCKET_URL}/voc/voc-stream-websocket`
  const liveStreamingUrl = '/devices/active-device-streaming'
  const parseLocalStorage = JSON.parse(localStorage.getItem('user'))

  const [ trackerList, setTrackerList ] = useState([])
  const [ fcmToken, setFcmToken ] = useState('')
  
  // GET AGENT HELP LIST
  const loadAgentHelpList = () => {
    getAgentHelpList({
      page: 0,
      size: 1000,
    }).then((response) => {
      setAgentHelpAllData(response.rows)
    })
  }

  // GET LIVE STREAMING LIST
  const loadLiveStreaming = () => {
    getDeviceStreaming(liveStreamingUrl, {
      page: 0,
      size: 1000,
    }).then((response) => {
      setLiveStreamingList(response.rows)
    })
  }

  // GET CONFERENCE LIST
  const loadConference = () => {
    getConferenceList().then((response) => {
      if(response?.status !== 'error'){
        setConferenceList(response)
      } else {setConferenceList([])}      
    })
  }

  const loadTrackerList = async () => {
    const responseTrackerList = await getTrackerList({ ignorePagination: true })
    
    if (responseTrackerList?.status !== 'error') setTrackerList(responseTrackerList)
  }

  // FIREBASE FCM
  const handleFirebaseToken = async () => {
    await postFcmNotificationToken({ token: fcmToken })
  }

  onMessageListener().then(payload => {
    setSnackbarObject({
      open: true,
      severity: 'info',
      title: payload.notification.title,
      message: payload.notification.body,
    })
  }).catch(err => {})

  useEffect(() => {
    userProfile && fetchFirebaseToken(setFcmToken)
  }, [userProfile])

  useEffect(() => {
    fcmToken && handleFirebaseToken()
  }, [fcmToken])

  // WEB SOCKET
  useEffect(() => {
    const ws = new WebSocket(`${url}?email=${parseLocalStorage?.email}`)

    ws.onopen = () => {
      console.log('WS Connected')
    }

    ws.onmessage = (e) => {
      const message = JSON.parse(e.data)

      // AGENT HELP EVENT
      if (message?.messageType?.includes('AGENT HELP')) {
        loadAgentHelpList()

        message.messageType === 'AGENT HELP CREATED' && setSnackbarObject({
          open: true,
          severity: 'info',
          title: '',
          message: `Perangkat ${message.mobileLabel} membutuhkan bantuan`,
        })

        message.messageType === 'AGENT HELP CREATED' && setBadgeType(message)
      }

      // CONFERENCE EVENT
      if(message?.messageType?.includes('CONFERENCE')) {
        loadConference()

        message.messageType === 'CONFERENCE CREATED' && setSnackbarObject({
          open: true,
          severity: 'info',
          title: '',
          message: `Perangkat ${message.mobileLabel} membuat rapat`,
        })

        message.messageType === 'CONFERENCE CREATED' && setBadgeType(message)
      }

      // LIVE NOW EVENT
      if (message.type === 1 || message.type === 2) {
        //if(location.href.includes('/live?tab=live_now')) window.location.reload()
        loadLiveStreaming()

        message.type === 1 && setSnackbarObject({
          open: true,
          severity: 'info',
          title: '',
          message: message.message,
        })

        message.type === 1 && setBadgeType(message)
      }

      // NEEDED HELP EVIDENCE EVENT
      if (message.type === 3) {
        setIsUpdateEvidenceList(true)
        setSnackbarObject({
          open: true,
          severity: 'error',
          title: '',
          message: `Perangkat ${message.message}`,
        })
      }

      // GEOFENCE EVENT
      if ((message.messageType === 'GEOFENCE ENTER' || message.messageType === 'GEOFENCE EXIT') && trackerList.length > 0) {
        let title, message
        const selectedTracker = trackerList.find(item => item.id === message.deviceId)
        const trakcerLabel = selectedTracker ? selectedTracker.label : message.deviceId

        if (message.messageType === 'GEOFENCE ENTER') {
          title = 'Masuk Lokasi'
          message = `Perangkat "${trakcerLabel}" masuk ke dalam lokasi "${message.zoneAddress}"`
        }
        else if (message.messageType === 'GEOFENCE EXIT') {
          title = 'Keluar Lokasi'
          message = `Perangkat "${trakcerLabel}" keluar dari lokasi "${message.zoneAddress}"`
        }

        setSnackbarObject({
          open: true,
          severity: 'info',
          title: title,
          message: message,
        })
      }
    }

    return () => {
      if (ws) {
        ws.close()
      }
    }
  }, [])

  useEffect(() => {
    if (agentHelpAllData) {
      let needHelp = agentHelpAllData.filter((item) => !item.adminUid)
      setAgentHelpNeedHelp(needHelp)

      let onVideoCall = agentHelpAllData.filter((item) => item.adminUid)
      setAgentHelpOnVideoCall(onVideoCall)
    }
  }, [agentHelpAllData])

  useEffect(() => {
    const faviconElement = document.getElementById('favicon')
    faviconElement.href = getCompanyLogo('light')
    document.title = getCompanyTitle()

    loadAgentHelpList()
    loadLiveStreaming()
    loadConference()
    loadTrackerList()
  }, [])

  return (
    <>
      <AppRouter />

      {/* SNACKBAR */}
      <Snackbar
        open={snackbarObject.open}
        setToast={setSnackbarObject}
        severity={snackbarObject.severity}
        title={snackbarObject.title}
        message={snackbarObject.message}
      />
    </>
  )
}

export default App
