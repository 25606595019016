// MUI STYLES
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  labelView: {
    color: '#717171',
    marginRight: 8,
  },
  selectTimelineView: {

  },
  formControlSelectTimeline: {
    marginRight: 8,
    width: 108,
    '& .MuiOutlinedInput-notchedOutline': {
      //border: 'none !important',
    },
    '& .MuiFilledInput-root': {
      backgroundColor: 'transparent !important',
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    '& .MuiSelect-select': {
      padding: '0 0 4px 0',
      fontSize: 12,
      '@media only screen and (max-height: 820px)': {
        zoom: 1/0.85,
      },
      '& .MuiTypography-root': {
        '@media only screen and (max-height: 820px)': {
          zoom: 0.85,
        },
      }
    },
    '& .MuiSvgIcon-root': {
      '@media only screen and (max-height: 820px)': {
        zoom: 0.85,
      },
    }
  },
  selectTimelineMenu: {
    borderRadius: 2,
    '& .MuiList-root': {
      padding: 8,
    },
  },
  textCurrentDate: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 600,
    padding: '0 16px'
  },
  schedulerTimeline: {
    borderColor: alpha(theme.palette.common.black, 0.2),
    '& .k-scheduler-cell.k-resource-cell': {
      minWidth: '240px !important',
      justifyContent: 'flex-start',
      fontWeight: 400,
    },
  },
  customItem: {
    backgroundColor: theme.palette.info.main,
    minHeight: 40,
    transition: 'background-color 0.4s',
    '&:hover': {
      backgroundColor: theme.palette.info.dark
    },
    '&.k-selected': {
      backgroundColor: theme.palette.info.dark
    },
    '& .k-event-actions': {
      display: 'none'
    }
  },
  taskItemTitle: {
    fontSize: 10,
    fontWeight: 600,
    display: 'block',
    lineHeight: 1.6,
    padding: '4px 8px 0 8px'
  },
  taskItemDesc: {
    display: 'block',
    fontSize: 10,
    color: alpha(theme.palette.common.white, 0.72),
    fontWeight: 500,
    lineHeight: 1.6,
    padding: '0 0 0 8px'
  },
}))

export default useStyles