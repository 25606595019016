import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

// COMPONENTS
import LoadingBox from 'components/LoadingBox/LoadingBox'
import ContentTabs from 'components/ContentTabs/ContentTabs'
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import DialogAddOrEditSettingsSecurityControl from './DialogAddOrEditSettingsSecurityControl/DialogAddOrEditSettingsSecurityControl'
import DialogAddOrEditSettingsSecurityControlRole from './DialogAddOrEditSettingsSecurityControlRole/DialogAddOrEditSettingsSecurityControlRole'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import MyTreeView from './TreeView/CustomizedTreeView'
import PageHeader from 'components/PageHeader/PageHeader'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import { Box, Button, IconButton, Typography } from '@mui/material'

// MUI ICONS
import IconDelete from '@mui/icons-material/Delete'
import IconEdit from '@mui/icons-material/Edit'

// RAMDA
import { isEmpty, reject } from 'ramda'

// SERVICES
import { deleteProfile, deleteRole } from 'services/UserService'
import {
  getProfile,
  getAllPermissions,
  getHierarchyRoles,
  getRoles,
  searchProfiles,
} from 'services/UserService'

// STORES
import { showAlert } from 'store/MainReducer'

// STYLES
import useStyles from './settingsSecurityControlUseStyles'

// UTILITES
import { dateFormat, toast } from 'utilities'
import { isNilOrEmpty } from 'ramda-extension'

const SettingsSecurityControl = () => {
  const classes = useStyles()

  const pageRef = useRef()

  const dispatch = useDispatch()

  const initialFilters = {
    profileName: '',
    profileDescription: '',
    createdOn: '',
    createdBy: '',
    modifiedOn: '',
    modifiedBy: '',
  }

  const groupByList = [
    {
      title: 'Don\'t Group',
      value: null,
    },
    // {
    //   title: 'By Profile Name',
    //   value: 'profileName',
    // },
  ]

  const tabItems = [ 
    {
      label: 'Akun',
      value: 'Profiles',
    },
    {
      label: 'Roles',
      value: 'Roles',
    },
  ]

  const [selectedTab, setSelectedTab] = useState(tabItems[0].value)

  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('profileName')
  const [size, setPageSize] = useState(100)
  const [
    settingsSecurityControlTableData,
    setSettingsSecurityControlTableData,
  ] = useState([])
  const [filters, setFilters] = useState(initialFilters)
  const [selectedGroupBy, setSelectedGroupBy] = useState(groupByList[0])
  const [isFilterOn, setIsFilterOn] = useState(false)
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [dialogType, setDialogType] = useState('')
  const [ dialogAddOrEditSettingsSecurityControl, setDialogAddOrEditSettingsSecurityControl ] = useState(null)
  const [ dialogDeleteSettingsSecurityControl, setDialogDeleteSettingsSecurityControl ] = useState({})

  const [deleteType, setDeleteType] = useState('')
  const [
    dialogAddOrEditSettingsSecurityControlRole,
    setDialogAddOrEditSettingsSecurityControlRole,
  ] = useState(null)

  const [permissions, setPermissions] = useState([])
  const [selectionModel, setSelectionModel] = useState([])

  const initialColumns = [
    {
      field: 'profileName',
      headerName: 'Nama Akun',
      flex: 1,
      minWidth: 150,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'profileDescription',
      headerName: 'Keterangan Akun',
      flex: 1,
      minWidth: 250,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'createdOn',
      headerName: 'Tanggal Dibuat',
      flex: 1,
      minWidth: 165,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => params.value && dateFormat(params.value),
    },
    {
      field: 'createdBy',
      headerName: 'Dibuat Oleh',
      flex: 1,
      minWidth: 165,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'modifiedOn',
      headerName: 'Waktu Diubah',
      flex: 1,
      minWidth: 165,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => params.value && dateFormat(params.value),
    },
    {
      field: 'modifiedBy',
      headerName: 'Diubah Oleh',
      flex: 1,
      minWidth: 165,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'actions',
      headerName: '',
      width: 120,
      hide: false,
      areFilterAndSortShown: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        params.row.profileName !== 'Admin' && params.row.profileDescription !== 'Admin' &&
        <Box className={classes.columnActions}>
          {/* EDIT ICON */}
          <CustomTooltipBlack title='Ubah' placement='bottom'>
            <IconButton
              onClick={(event) => handleEditIconButtonClick(event, params)}
            >
              <IconEdit />
            </IconButton>
          </CustomTooltipBlack>

          {/* DELETE ICON */}
          <CustomTooltipBlack title='Hapus' placement='bottom'>
            <IconButton
              onClick={(event) => handleDeleteIconButtonClick(event, params)}
            >
              <IconDelete />
            </IconButton>
          </CustomTooltipBlack>
        </Box>
      ),
    },
  ]

  const [selectedColumnList, setSelectedColumnList] = useState(initialColumns)

  const [hierarchyRoles, setHierarchyRoles] = useState({})
  const [roles, setRoles] = useState([])

  const prepareData = (data) => {
    if (isNilOrEmpty(data)) {
      return {}
    }
    const childData = data.find((theRole) => theRole.roleName === 'POLRI')
    return {
      roleId: 'root',
      roleName: 'POLRI',
      childRoles: childData?.childRoles,
      id: 1
    }
  }

  const reloadTree = () => {
    getHierarchyRoles().then((response) => {
      setHierarchyRoles(prepareData(response?.map(item => ({
        ...item,
        id: item.roleId,
        childRoles: item.childRoles?.map(itemChild => ({
          ...itemChild,
          id: itemChild.roleId
        }))
      }))))
    })

    getRoles({ page: 1, size: 500 }).then((response) => {
      setRoles(response)
    })
  }

  // NOTE: PERHAPS, THIS COULD BE OPTIMIZED. SOURCE: DATA GRID TABLE COMPONENT
  const groupingColDef = {
    headerName: 'Nama Akun',
    renderCell: (params) => {
      return (
        <Typography variant='subtitle1' className={classes.groupingRow}>
          {params.rowNode?.groupingKey?.toLowerCase()}
        </Typography>
      )
    },
  }

  // NOTE: PERHAPS, THIS COULD BE OPTIMIZED. SOURCE: DATA GRID FILTERS COMPONENT
  const handleColumnsMenuItemClick = (inputItem, inputIndex) => {
    let tempSelectedColumnList = [...selectedColumnList]
    if (selectedGroupBy.value && inputItem.field === 'profileName') {
    } else {
      tempSelectedColumnList[inputIndex].hide =
        !tempSelectedColumnList[inputIndex].hide
    }
    setSelectedColumnList(tempSelectedColumnList)
  }

  const handleDeleteIconButtonClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()
    setDeleteType('Profile')
    setDialogDeleteSettingsSecurityControl(inputParams)
  }

  const handleEditIconButtonClick = async (inputEvent, inputParams) => {
    inputEvent.stopPropagation()
    const response = await getProfile(inputParams?.row?.id)
    setDialogType('edit')
    setDialogAddOrEditSettingsSecurityControl(response)
  }

  const reloadData = () => {
    const newFilters = reject(isEmpty, filters)
    let params = {
      page,
      size,
    }

    if (order) params.sort = `${orderBy},${order}`
    if (search) newFilters.globalSearch = search

    searchProfiles(params, newFilters).then((response) => {
      setSettingsSecurityControlTableData(response.rows)
      setTotal(response.totalElements)
    })

    getAllPermissions().then((response) => {
      setPermissions(response)
    })
  }
  const handleDeleteButtonClick = async () => {
    setIsLoading(true)

    if (deleteType === 'Profile') {
      const result = await deleteProfile(
        dialogDeleteSettingsSecurityControl.row?.id
      )

      if (result.status !== 'error') {
        dispatch(showAlert({ message: 'Sukses Menghapus Akun' }))
        setDialogDeleteSettingsSecurityControl({})
        deleteType === 'Profile' ? reloadData() : reloadTree()
      } else {
        toast({ title: result.error, icon: 'error' }).then(() => {})
      }
    } else if (deleteType === 'Role') {
      const result = await deleteRole(dialogDeleteSettingsSecurityControl)

      if (result.status !== 'error') {
        dispatch(showAlert({ message: 'Sukses Menghapus Roles' }))
        setDialogDeleteSettingsSecurityControl({})
        deleteType === 'Profile' ? reloadData() : reloadTree()
      } else {
        toast({ title: result.error, icon: 'error' }).then(() => {})
      }
    }

    setIsLoading(false)
  }

  useEffect(() => {
    reloadTree()
  }, [])

  useEffect(() => {
    reloadData()
  }, [page, size, order, orderBy, search, filters])

  useEffect(() => {
    if (!isFilterOn) setFilters(initialFilters)
  }, [isFilterOn])

  // NOTE: PERHAPS, THIS COULD BE OPTIMIZED. SOURCE: DATA GRID TABLE COMPONENT
  useEffect(() => {
    if (selectedGroupBy.value) {
      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        if (item.field === selectedGroupBy.value) item.hide = true
      })
      setSelectedColumnList(tempSelectedColumnList)
    }
  }, [selectedGroupBy])

  return (
    <Box className={classes.pageRoot} ref={pageRef}>
      {/* HEADER */}
      <PageHeader
        isAddButtonAvailable={false}
        title='Kontrol Keamanan'
        search={search}
        setSearch={setSearch}
      />

      {/* CONTENT */}
      <LoadingBox 
        isLoading={isLoading}
        isYOverFlow={true}
      >
        {/* CONTENT TABS */}
        <ContentTabs
          tabItemList={tabItems}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          isExtraComponentAvailable={false}
          extraComponent={null}
        />

        {/* PROFILES */}
        {selectedTab === 'Profiles' && (
          <Box className={classes.tabContent}>
            <Box className={classes.tabTopContent}>
              <Typography className={classes.tabTitle}>Akun</Typography>
              <Box className={classes.tabInformation}>
                <Typography variant='caption' className={classes.tabSubtitle}>
                  Akun adalah seperangkat izin yang berhubungan dengan akses dan operasi modul, penyesuaian pengaturan, dan akses ke berbagai aplikasi. Anda dapat memberikan serangkaian izin yang berbeda untuk berbagai pengguna.
                </Typography>

                <Button
                  className={classes.btnAddNew}
                  variant='contained'
                  color='primary'
                  disableElevation
                  onClick={() => {
                    setDialogType('add')
                    setDialogAddOrEditSettingsSecurityControl(true)
                  }}
                >
                  Akun Baru
                </Button>
              </Box>
            </Box>
            <DataGridFilters
              columns={initialColumns}
              selectedColumnList={selectedColumnList}
              handleColumnsMenuItemClick={handleColumnsMenuItemClick}
              isFilterOn={isFilterOn}
              setIsFilterOn={setIsFilterOn}
              groupByList={groupByList}
              selectedGroupBy={selectedGroupBy}
              setSelectedGroupBy={setSelectedGroupBy}
            />

            <DataGridTable
              initialColumns={initialColumns}
              rows={settingsSecurityControlTableData}
              total={total}
              page={page}
              pageSize={size}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              setPage={setPage}
              setPageSize={setPageSize}
              setFilters={setFilters}
              isFilterOn={isFilterOn}
              selectedColumnList={selectedColumnList}
              setSelectedColumnList={setSelectedColumnList}
              selectedGroupBy={selectedGroupBy}
              getTreeDataPath={(row) => [row.deviceName, row.id]}
              groupingColDef={groupingColDef}
              selectionModel={selectionModel}
              setSelectionModel={setSelectionModel}
              isCheckboxPinned={true}
            />
          </Box>
        )}

        {/* ROLES */}
        {selectedTab === 'Roles' && (
          <Box className={classes.tabContent}>
            <Box className={classes.tabTopContent}>
              <Typography className={classes.tabTitle}>Roles</Typography>
              <Box className={classes.tabInformation}>
                <Typography variant='caption' className={classes.tabSubtitle}>
                  Halaman ini akan memungkinkan Anda untuk menentukan bagaimana Anda berbagi data di antara pengguna berdasarkan hierarki peran organisasi Anda. 
                </Typography>

                <Button
                  className={classes.btnAddNew}
                  variant='contained'
                  color='primary'
                  disableElevation
                  onClick={() => {
                    setDialogType('add')
                    setDialogAddOrEditSettingsSecurityControlRole(true)
                  }}
                >
                  Roles Baru
                </Button>
              </Box>
            </Box>
            <Box className={classes.tabExpandInfo}>
              <MyTreeView
                hierarchyRoles={hierarchyRoles}
                roles={roles}
                reloadTree={reloadTree}
                setDialogDeleteSettingsSecurityControl={setDialogDeleteSettingsSecurityControl}
                setDialogType={setDialogType}
                setDialogAddOrEditSettingsSecurityControl={setDialogAddOrEditSettingsSecurityControlRole}
                setDeleteType={setDeleteType}
              />
            </Box>
          </Box>
        )}
      </LoadingBox>

      {/* DIALOG ADD SECURITY CONTROL PROFILES */}
      <DialogAddOrEditSettingsSecurityControl
        dialogType={dialogType}
        dialogAddOrEditSettingsSecurityControl={
          dialogAddOrEditSettingsSecurityControl
        }
        setDialogAddOrEditSettingsSecurityControl={
          setDialogAddOrEditSettingsSecurityControl
        }
        permissions={permissions}
        pageRef={pageRef}
        reloadData={reloadData}
      />

      {/* DIALOG DELETE SECURITY CONTROL */}
      <DialogConfirmation
        title={deleteType === 'Profile' ? 'Hapus Akun' : 'Hapus Role'}
        caption={deleteType === 'Profile' ? 'Apa Anda yakin ingin menghapus akun ini?' : 'Apa Anda yakin ingin menghapus role ini?'}
        dialogConfirmationObject={dialogDeleteSettingsSecurityControl}
        setDialogConfirmationObject={setDialogDeleteSettingsSecurityControl}
        cancelButtonText='Batal'
        continueButtonText='Hapus'
        onContinueButtonClick={handleDeleteButtonClick}
        onCancelButtonClick={() => setDialogDeleteSettingsSecurityControl({})}
      />

      {/* DIALOG ADD SECURITY CONTROL ROLES */}
      <DialogAddOrEditSettingsSecurityControlRole
        dialogType={dialogType}
        dialogAddOrEditSettingsSecurityControlRole={
          dialogAddOrEditSettingsSecurityControlRole
        }
        setDialogAddOrEditSettingsSecurityControlRole={
          setDialogAddOrEditSettingsSecurityControlRole
        }
        roles={roles}
        pageRef={pageRef}
        reloadData={reloadTree}
      />
    </Box>
  )
}

export default SettingsSecurityControl
