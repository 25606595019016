// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  agentGrid: {
    padding: 12,
    overflowY: 'auto',
    flex: 1,
  },
  agentItemPadding: {
    padding: 12,
  },
}))

export default useStyles
