import { useContext } from 'react'

// COMPONENTS
import CardEvidence from 'components/CardEvidence/CardEvidence'
import DialogEvidenceDetail from 'components/DialogEvidenceDetail/DialogEvidenceDetail'

// CONTEXTS
import { PagePatrolsContext } from 'contexts/PagePatrolsContext'

// MUIS
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './detailTabEvidencesUseStyles'

const DetailTabEvidences = (props) => {
  const { evidenceList, reloadDetail } = props
  
  const classes = useStyles()

  const { selectedEvidenceItem, setSelectedEvidenceItem } = useContext(PagePatrolsContext)

  return (
    <Stack
      marginTop='50px'
      overflow='auto'
      flex='1'
      className={classes.root}
    >
      {/* TITLE */}
      <Typography
        variant='subtitle1'
        fontWeight={600}
        margin='20px 0px'
      >
        Daftar Kejadian
      </Typography>

      {/* EVIDENCE LIST */}
      {evidenceList.length > 0 ? (
        <Stack spacing='20px'>
          {evidenceList.map((item, index) => (
            <CardEvidence
              key={index}
              item={item}
              setSelectedEvidenceItem={setSelectedEvidenceItem}
              isTransricbeEditable
              reloadDetail={reloadDetail}
            />
          ))}
        </Stack>
      ) : (
        <Typography variant='caption'>
          Tidak ada data
        </Typography>
      )}

      {/* EVIDENCE DETAIL DIALOG */}
      <DialogEvidenceDetail
        isDialogOpen={Boolean(selectedEvidenceItem)} 
        handleDialogClose={() => setSelectedEvidenceItem(null)}
        evidenceObject={selectedEvidenceItem}
      />
    </Stack>
  )
}

export default DetailTabEvidences