import React from 'react'
import PropTypes from 'prop-types'

// CUSTOM COMPONENTS
import CustomTextField from 'components/Customs/CustomTextField'

const FilterTextField = (props) => {
  const { 
    updateFilters, 
    ...others 
  } = props

  const handleFiltersChange = (event) => {
    updateFilters((current) => ({
      ...current,
      [event.target.name]: event.target.value,
    }))
  }

  return (
    <CustomTextField
      onChange={handleFiltersChange}
      onKeyDown={(event) => event.stopPropagation()}
      {...others}
    />
  )
}

FilterTextField.defaultProps = {
}

FilterTextField.propTypes = {
  updateFilters: PropTypes.func.isRequired,
}

export default FilterTextField
