import { useState, useEffect } from 'react'

// COMPONENTS
import CellChannels from './CellChannels'
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DataGridTable from 'components/DataGridTable/DataGridTable'

// CONSTANTS
import { colors } from 'constants/colors'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconAdjust from '@mui/icons-material/Adjust'
import IconAssignmentInd from '@mui/icons-material/AssignmentInd'
import IconDelete from '@mui/icons-material/Delete'
import IconEventAvailable from '@mui/icons-material/EventAvailable'
import IconSecurityUpdate from '@mui/icons-material/SecurityUpdate'
import IconSmartphone from '@mui/icons-material/Smartphone'

// PATH ICONS
import IconCircle from '@mui/icons-material/Circle'
import IconKey from 'assets/images/pathIcons/IconKey'
import IconWalkieTalkie from 'assets/images/pathIcons/IconWalkieTalkie'

// SERVICES
import { postSearchUser } from 'services/PushToTalkServices'

// STYLES
import useStyles from './tableDevicesUseStyles'

// UTILITIES
import { capitalizeEachWord } from 'utilities'

const TableDevices = (props) => {
  const { 
    search,
    tabList,
    deviceTableData, setDeviceTableData,
    deviceSelectionModel, setDeviceSelectionModel, 
    mustReloadTableDevices, setMusReloadTableDevices,
    setDialogDeleteDevice,
  } = props

  const classes = useStyles()

  const groupByList = [
    {
      title: 'Don\'t Group',
      value: null,
    },
  ]

  const initialFilters = {
    userId: undefined,
    label: '',
    // channel: '',
    userOnlineStatus: '',
    // lastOnline: '',
    deviceOsVersion: '',
    deviceAppVersion: '',
  }

  const getColumnStatusIconColor = (status) => {
    if (status.toLowerCase() === 'offline') return colors.reject
    else if (status.toLowerCase() === 'online') return colors.silverTree
  }

  const handleDeleteIconButtonClick = (event, params) => {
    event.stopPropagation()
    setDialogDeleteDevice({ tab: tabList[0].value, params })
  }

  const initialColumns = [
    {
      field: 'userId',
      headerName: 'ID Pengguna',
      headerIcon: IconKey,
      areFilterAndSortShown: true,
      // flex: 1,
      minWidth: 150,
      hide: false,
    },
    {
      field: 'label',
      headerName: 'Label',
      headerIcon: IconAssignmentInd,
      areFilterAndSortShown: true,
      // flex: 1,
      minWidth: 200,
      hide: false,
    },
    {
      field: 'channels',
      headerName: 'Kanal',
      headerIcon: IconWalkieTalkie,
      areFilterAndSortShown: false,
      flex: 1,
      minWidth: 300,
      hide: false,
      renderCell: (params) => (
        params.value.length > 0 &&
        <CellChannels list={params.value.map(item => item.groupName)}/>
      ),
    },
    {
      field: 'userOnlineStatus',
      headerName: 'Status',
      headerIcon: IconAdjust,
      areFilterAndSortShown: true,
      // flex: 1,
      minWidth: 140,
      hide: false,
      renderCell: (params) => (
        params.value &&
        <Stack 
          direction='row'
          spacing='8px'
          alignItems='center'
        >
          {/* ICON */}
          <IconCircle 
            className={classes.columnStatusIcon}
            sx={{ color: getColumnStatusIconColor(params.value) }}
          />

          {/* TEXT */}
          <Typography variant='inherit'>
            {capitalizeEachWord(params.value)}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'lastOnline',
      headerName: 'Terakhir Online',
      headerIcon: IconEventAvailable,
      areFilterAndSortShown: false,
      // flex: 1,
      minWidth: 150,
      hide: false,
    },
    {
      field: 'deviceOsVersion',
      headerName: 'Versi Perangkat',
      headerIcon: IconSecurityUpdate,
      areFilterAndSortShown: true,
      // flex: 1,
      minWidth: 150,
      hide: false,
    },
    {
      field: 'deviceAppVersion',
      headerName: 'Versi Aplikasi',
      headerIcon: IconSmartphone,
      areFilterAndSortShown: true,
      // flex: 1,
      minWidth: 150,
      hide: false,
    },
    {
      field: 'actions',
      headerName: '',
      width: 120,
      hide: false,
      areFilterAndSortShown: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box className={classes.columnActions}>
          {/* DELETE ICON */}
          <CustomTooltipBlack 
            title='Hapus' 
            placement='bottom'
          >
            <IconButton onClick={(event) => handleDeleteIconButtonClick(event, params)}>
              <IconDelete/>
            </IconButton>
          </CustomTooltipBlack>
        </Box>
      ),
    },
  ]

  const [ selectedColumnList, setSelectedColumnList ] = useState(initialColumns)
  const [ isFilterOn, setIsFilterOn ] = useState(false)
  const [ selectedGroupBy, setSelectedGroupBy ] = useState(groupByList[0])
  const [ total, setTotal ] = useState(0)
  const [ page, setPage ] = useState(0)
  const [ order, setOrder ] = useState('desc')
  const [ orderBy, setOrderBy ] = useState('created_on')
  const [ size, setPageSize ] = useState(100)
  const [ filters, setFilters ] = useState(initialFilters)

  const handleColumnsMenuItemClick = (inputItem, inputIndex) => {
    let tempSelectedColumnList = [...selectedColumnList]
    tempSelectedColumnList[inputIndex].hide = !tempSelectedColumnList[inputIndex].hide
    setSelectedColumnList(tempSelectedColumnList)
  }

  const getDeviceListData = async () => {
    let paramsFilter = {}
    Object.keys(filters)?.forEach(key => {
      if (key === 'userId' && filters[key]) paramsFilter[key] = Number(filters[key])
      else if (key === 'userOnlineStatus' && filters[key]) paramsFilter[key] = filters[key].toUpperCase()
      else if (filters[key]) paramsFilter[key] = filters[key]
    })

    const resultDeviceList = await postSearchUser({ page, size }, paramsFilter)
    
    if (resultDeviceList.status === 200) {
      const newTableData = resultDeviceList.data.rows.map(item => {
        return {
          ...item,
          id: item.userId,
        }
      })

      setDeviceTableData(newTableData)
      setTotal(resultDeviceList.data.totalElements)

      setMusReloadTableDevices(false)
    }
  }

  useEffect(() => {
    mustReloadTableDevices && getDeviceListData()
  }, [page, order, orderBy, size, filters, search, mustReloadTableDevices])

  useEffect(() => {
    setMusReloadTableDevices(true)
  }, [page, order, orderBy, size, filters, search])

  return (
    <>
      <DataGridFilters
        columns={initialColumns}
        selectedColumnList={selectedColumnList}
        handleColumnsMenuItemClick={handleColumnsMenuItemClick}
        isFilterOn={isFilterOn}
        setIsFilterOn={setIsFilterOn}
        groupByList={groupByList}
        selectedGroupBy={selectedGroupBy}
        setSelectedGroupBy={setSelectedGroupBy}
      />

      <DataGridTable
        initialColumns={initialColumns}
        rows={deviceTableData}
        total={total}
        page={page}
        pageSize={size}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        setPage={setPage}
        setPageSize={setPageSize}
        setFilters={setFilters}
        isFilterOn={isFilterOn}
        selectedColumnList={selectedColumnList}
        setSelectedColumnList={setSelectedColumnList}
        selectedGroupBy={selectedGroupBy}
        getTreeDataPath={(row) => [ row.userId, row.id ]}
        selectionModel={deviceSelectionModel} 
        setSelectionModel={setDeviceSelectionModel}
        isCheckboxPinned={true}
      />
    </>
  )
}

export default TableDevices