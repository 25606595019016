// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  viewModeButton: {
    paddingLeft: 'unset',
    paddingRight: 'unset',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  dateRangeInput: {
    height: 40,
    paddingLeft: 8,
  },
  footerButton: {
    textTransform: 'uppercase',
    fontWeight: 700,
    borderRadius: 'unset',
  },
  footerButtonCancel: {
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: alpha(theme.palette.text.secondary, 0.08),
    },
  },
  dateRangeAndTimePickerDialog: {
    '& .MuiDialog-paper': {
      maxWidth: 'unset',
    },
    '& .MuiPaper-root': {
      borderRadius: 0,
    },
  },
}))

export default useStyles