import React, { useContext, useEffect, useState } from 'react'

// STYLES
import useStyles from './panelObjectsUseStyles'

// MUIS
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

// MUI DATA GRID
import { useGridApiRef } from '@mui/x-data-grid-premium'

// COMPONENTS
import CustomDataGridObjects from 'components/DataGridPanelObject/CustomDataGridObjects'
import GroupingHeader from 'components/DataGridPanelObject/GroupingHeader'
import CustomGroupingCell from 'components/DataGridPanelObject/GroupingCell'

// SERVICES
import { getTrackerList } from 'services/TrackingServices'
import { getGroups } from 'services/DeviceService'

// CONTEXT
import { PageAlertRulesContext } from 'contexts/PageAlertRulesContext'

// CONST
import { colors } from 'constants/colors'

// UTILS
import { filterObjectListBySearchPanelObject, handleCellClickPanelObject, handleColumnHeaderClickPanelObject, setRowClassByGroupObject, setSxCustomClassPanelObject } from 'utilities'

const PanelObjects = () => {
  const classes = useStyles()
  const dataGridApiRef = useGridApiRef()

  // CONST
  const groupingColDef = {
    headerName: '',
    flex: 1,
    renderCell: (params) => <CustomGroupingCell {...params} contentWidth={220} />,
    renderHeader: (params) => (
      <GroupingHeader
        {...params}
        setSearch={setSearch}
        search={search}
        headerWidth={225}
      />
    )
  }

  // CONTEXT
  const {
    objectList, setObjectList,
    selectionModel, setSelectionModel,
    selectedRulesItem
  } = useContext(PageAlertRulesContext)

  // STATE
  const [search, setSearch] = useState('')
  const [sxCustom, setSxCustom] = useState()
  const [ filteredObjectList, setFilteredObjectList ] = useState([])

  // INTEGRATE OBJECT (WULING DASHBOARD VERSION) - without joining getStates
  const integrateObjectData = (
    inputTrackerList,
    inputGroupList
  ) => {
    let output = inputTrackerList.map(trackerItem => {
      let groupObject = inputGroupList.find((groupItem) => groupItem.groupNo === trackerItem.groupNo)

      if (!groupObject) groupObject = {
        groupNo: 0,
        groupName: 'Main Group',
        groupColor: colors.clementine,
      }

      return {
        ...trackerItem,
        isSelected: false,
        isShown: true,
        group: { ...groupObject },
      }
    })

    output = output.filter((item) => item)

    return output
  }

  // FETCH TRACKER LIST
  const fetchDataListTracker = async () => {
    const response = await getTrackerList({ ignorePagination: true })
    const responseGroupList = await getGroups({ page: 0, size: 100 })

    // jOIN GROUP AND TRACKER
    const joinTrackerAndGroup = integrateObjectData(response, responseGroupList.rows)
    setObjectList(joinTrackerAndGroup)
    setFilteredObjectList(joinTrackerAndGroup)

    // SET SX FOR COLORING BORDER
    setSxCustomClassPanelObject(responseGroupList, setSxCustom)
  }

  // SIDE EFFECT FIRST MOUNT
  useEffect(() => {
    fetchDataListTracker()
  }, [])

  // SIDE EFFECT SEARCH
  useEffect(() => {
    search.length && filterObjectListBySearchPanelObject(search, objectList, setFilteredObjectList)
  }, [search])

  // SIDE EFFECT SET DEFAULT SELECTED OBJECT
  useEffect(() => {
    if(Boolean(selectedRulesItem)) {
      setSelectionModel(selectedRulesItem.deviceObjectIds)
    }
  }, [selectedRulesItem])
  
  return (
    <Stack flex='1' className={classes.mainBox}>
      {/* PANEL CONTENT */}
      <Box className={classes.panelContent} sx={sxCustom}>
        <CustomDataGridObjects
          apiRef={dataGridApiRef}
          columns={[]}
          selectionModel={selectionModel}
          rows={filteredObjectList}
          treeData={true}
          getTreeDataPath={(row) => [ row.group.groupName, row.id ]}
          groupingColDef={groupingColDef}
          defaultGroupingExpansionDepth={-1}
          getRowClassName={(params) => setRowClassByGroupObject(params)}
          className={classes.objectDataGrid}
          onCellClick={(params, event, details) => handleCellClickPanelObject(params, event, details, selectionModel, setSelectionModel)}
          onColumnHeaderClick={(params, event, details) => handleColumnHeaderClickPanelObject(params, event, details, selectionModel, setSelectionModel, dataGridApiRef)}
        />
      </Box>
    </Stack>
  )
}

export default PanelObjects