import React, { createContext, useState } from 'react'

const AuthLayoutContext = createContext()

const AuthLayoutContextProvider = (props) => {
  const [ isLoading, setIsLoading ] = useState(false)

  return (
    <AuthLayoutContext.Provider
      value={{
        isLoading, setIsLoading,
      }}
    >
      {props.children}
    </AuthLayoutContext.Provider>
  )
}

export { AuthLayoutContextProvider, AuthLayoutContext }