import React, { useContext } from 'react'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// MUIS
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconLocationOn from '@mui/icons-material/LocationOn'
import IconPhoneAndroid from '@mui/icons-material/PhoneAndroid'
import IconSchedule from '@mui/icons-material/Schedule'
import IconTextFields from '@mui/icons-material/TextFields'

// STYLES
import useStyles from './tabDispatchUseStyles'

// UTILS
import { dateFormat } from 'utilities'
import { getColorStatusDispatch, getTextStatusDispatch } from 'pages/DispatchEvidences/dispatchEvidencesUtils'
import { panelsInfoStatic } from './panelInformationIntegratedConstant'
import { joinAllLayers } from '../Clusters/clustersUtils'

const TabDispatch = () => {
  const classes = useStyles()
  const { dispatchEvidenceList, markerClustersRef, setIsPanelLeftShown, map, markerSettings, markerClustersLocationRef } = useContext(PageTrackingContext)

  const handleItemTooltip = (inputItem, isOn) => {
    if (markerSettings?.isClusteringLocation) {
      const listLayers = joinAllLayers(markerClustersLocationRef)
      const findInstance = listLayers.find(item => item.options.objectData.dispatchNo === inputItem.dispatchNo)
    
      if(findInstance && isOn === 'over') findInstance?.openTooltip()
      else if(findInstance && isOn === 'leave') findInstance?.closeTooltip()
      return
    }

    if (!markerClustersRef.current) return null
    const listLayers = markerClustersRef.current.getLayers()
    const findInstance = listLayers.find(item => item.options.objectData.dispatchNo === inputItem.dispatchNo)
    
    if(findInstance && isOn === 'over') findInstance?.openTooltip()
    else if(findInstance && isOn === 'leave') findInstance?.closeTooltip()
  }

  const handleItemClick = (inputItem) => {
    setIsPanelLeftShown({
      ...panelsInfoStatic.panelDispatchDetail,
      data: inputItem
    })

    if (inputItem?.address?.latitude && inputItem?.address?.longitude) {
      map.flyTo({lat: parseFloat(inputItem?.address?.latitude), lng: parseFloat(inputItem?.address?.longitude)}, 14)
    }
  }

  return (
    <>
      <Divider />

      <Stack overflow='hidden'>
        <Stack className={classes.root}>
          {dispatchEvidenceList?.length ? dispatchEvidenceList?.map((item, index) => (
            <React.Fragment key={index}>
              <Stack
                dataid={`${item.dispatchNo}`}
                className={classes.itemDetailWrapper}
                onMouseEnter={() => handleItemTooltip(item, 'over')}
                onMouseLeave={() => handleItemTooltip(item, 'leave')}
                onClick={() => handleItemClick(item)}
              >
                {/* DEVICE & STATUS */}
                <Stack className={classes.itemDetail} alignItems='center'>
                  <IconPhoneAndroid className={classes.itemDetailIcon}/>
                  <Typography color='black' fontWeight={600} variant='caption' flex={1} paddingLeft='4px'>
                    {item.deviceLabel}
                  </Typography>
              
                  <Chip
                    className={classes.chipStatus}
                    variant='outlined'
                    label={getTextStatusDispatch(item.status.toLowerCase())}
                    sx={{
                      '& .MuiChip-label': {
                        color: getColorStatusDispatch(item.status.toLowerCase())
                      }
                    }}
                  />
                </Stack>

                {/* TITLE */}
                <Stack className={classes.itemDetail}>
                  <IconTextFields className={classes.itemDetailIcon}/>
                  <Typography fontWeight={600} color='black' variant='caption' flex={1} paddingLeft='4px' marginTop='-2px'>
                    {item.label ?? '-'}
                  </Typography>
                </Stack>

                {/* LOCATION */}
                <Stack className={classes.itemDetail}>
                  <IconLocationOn className={classes.itemDetailIcon}/>
                  <Typography color='black' variant='caption' flex={1} paddingLeft='4px' marginTop='-2px'>
                    {item.address.fullAddress ?? '-'}
                  </Typography>
                </Stack>

                {/* TIME */}
                <Stack className={classes.itemDetail} alignItems='center'>
                  <IconSchedule className={classes.itemDetailIcon}/>
                  <Typography color='black' variant='caption' flex={1} paddingLeft='4px'>
                    {dateFormat(item.startTime, 'dd-MM-yyy, hh:mm a')}
                  </Typography>
                </Stack>
              </Stack>

              <Divider />
            </React.Fragment>
          ))
            : <Stack alignItems='center' padding='16px'>
              <Typography variant='caption'>Data tidak ditemukan</Typography>
            </Stack>}
        </Stack>
      </Stack>
    </>
  )
}

export default TabDispatch