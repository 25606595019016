// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 16,
    fontWeight: 600,
  },
  caption: {
    marginBottom: 40,
  },
  caption2: {
    marginBottom: 40,
    opacity: 0.54,
  },
  dialogActions: {
    padding: 8,
  },
  cancelButton: {
    color: colors.raven,
    '&:hover': {
      backgroundColor: alpha(colors.raven, 0.1),
    },
  },
}))

export default useStyles
