// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconBoolean = (props) => {
  return (
    <SvgIcon {...props}>
      <path fillRule='evenodd' clipRule='evenodd' d='M12.5668 2.38401C12.4168 2.23403 12.2134 2.14978 12.0012 2.14978C11.7891 2.14978 11.5857 2.23403 11.4356 2.38401L2.38444 11.4336C2.30994 11.5079 2.25083 11.5962 2.2105 11.6934C2.17017 11.7906 2.14941 11.8948 2.14941 12C2.14941 12.1052 2.17017 12.2094 2.2105 12.3066C2.25083 12.4038 2.30994 12.4921 2.38444 12.5664L11.4356 21.616C11.5857 21.766 11.7891 21.8502 12.0012 21.8502C12.2134 21.8502 12.4168 21.766 12.5668 21.616L21.618 12.5664C21.6925 12.4921 21.7517 12.4038 21.792 12.3066C21.8323 12.2094 21.8531 12.1052 21.8531 12C21.8531 11.8948 21.8323 11.7906 21.792 11.6934C21.7517 11.5962 21.6925 11.5079 21.618 11.4336L12.5668 2.38401ZM12.0004 4.08001L4.08044 12L12.0004 19.92V4.08001Z'/>
    </SvgIcon>
  )
}

export default IconBoolean