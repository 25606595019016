import { menuCst } from 'constants/values'
import { isNilOrEmpty } from 'ramda-extension'

function getToken() {
  return localStorage.getItem('token')
}

function isAuthenticated() {
  if (getToken()) {
    return true
  }

  return false
}

function setToken(token) {
  localStorage.setItem('token', token)
}

function setUser(user) {
  localStorage.setItem('user', JSON.stringify(user))
}

function getUser() {
  const user = localStorage.getItem('user')

  return JSON.parse(user)
}

function getMenuPermissions() {
  const user = getUser()

  if (isNilOrEmpty(user)) {
    return []
  }

  return user.profileDetails.menuPermissions
}

const checkItemSettingsPageEnable = () => {
  const menuPermissions = getMenuPermissions()

  let tempListItemPermissions = []

  menuPermissions.filter(item => {
    if(item.menuName === menuCst.dutyTypes ||
    item.menuName === menuCst.activityTypes ||
    item.menuName === menuCst.disturbances ||
    item.menuName === menuCst.cases ||
    item.menuName === menuCst.officers ||
    item.menuName === menuCst.groups ||
    item.menuName === menuCst.places ||
    item.menuName === menuCst.gpsDevices ||
    item.menuName === menuCst.users ||
    item.menuName === menuCst.securityControl) {
      tempListItemPermissions = [...tempListItemPermissions, ...item.permissionResponses]
      return true
    }
  })

  const findEnable = tempListItemPermissions.find(item => item.enable === true)

  return Boolean(findEnable)
}

function isAccessMenu(menuName) {
  const menuPermissions = getMenuPermissions()
  // SHOWING LOGOUT
  if(menuName === 'Profile') return true

  if(menuName === 'Settings') return checkItemSettingsPageEnable()

  if (isNilOrEmpty(menuPermissions)) {
    return false
  }

  let permission = null
  menuPermissions.forEach((menu) => {
    if (menuName === menu.menuName) {
      permission = menu.permissionResponses.find((item) => item.enable)
    }
  })

  if (isNilOrEmpty(permission)) {
    return false
  }

  return true
}

function hasPermission(menuName, permissionName) {
  if (!isAccessMenu(menuName)) {
    return false
  }

  const menuPermissions = getMenuPermissions()
  let permissions = null
  menuPermissions.forEach((menu) => {
    if (menuName === menu.menuName) {
      permissions = menu.permissionResponses
    }
  })

  // Check full access
  const fullAccess = permissions.find((item) => item.permissionName === 'Full Access')
  if (!isNilOrEmpty(fullAccess) && fullAccess.enable === true) {
    return true
  }

  const detailPermission = permissions.find((item) => item.permissionName === permissionName)

  return !isNilOrEmpty(detailPermission) && detailPermission.enable === true
}

export { getToken, isAuthenticated, setToken, setUser, isAccessMenu, getMenuPermissions, getUser, hasPermission }
