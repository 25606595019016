import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

// COMPONENTS
import DialogAddOrEdit from 'components/DialogAddOrEditNew/DialogAddOrEdit'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import SimpleBackdrop from 'components/Partials/SimpleBackdrop'
import SwitchesGroup from '../SwitchesGroup/SwitchesGroup'

// CUSTOM COMPONENTS
import CustomInput from 'components/Customs/CustomInput'
import CustomInputLabel from 'components/Customs/CustomInputLabel'

// MUIS
import {
  Alert,
  AlertTitle,
  Box,
  Divider,
  FormControl,
  Typography,
} from '@mui/material'

// MUI ICONS
import {
  Notes as IconNotes,
  Person,
} from '@mui/icons-material'

// SERVICES
import { 
  createProfile, 
  updateProfile, 
} from 'services/UserService'

// UTILITIES
import { clone } from 'ramda'
import { actionCst } from 'constants/values'

// STORES
import { showAlert } from 'store/MainReducer'

// STYLES
import useStyles from './dialogAddOrEditSettingsSecurityControlUseStyles'

// UTILITIES
import { toast } from 'utilities'

const DialogAddOrEditSettingsSecurityControl = (props) => {
  const {
    dialogType,
    dialogAddOrEditSettingsSecurityControl,
    setDialogAddOrEditSettingsSecurityControl,
    permissions,
    pageRef,
    reloadData,
  } = props

  const classes = useStyles()

  const dispatch = useDispatch()

  let initialDialogParams = {
    id: '',
    profileName: '',
    description: '',
  }

  const [dialogParams, setDialogParams] = useState(initialDialogParams)
  const [currentPermissions, setCurrentPermissions] = useState([])

  useEffect(() => {
    setDialogParams({
      id: dialogAddOrEditSettingsSecurityControl?.id ?? '',
      profileName: dialogAddOrEditSettingsSecurityControl?.profileName ?? '',
      description:
        dialogAddOrEditSettingsSecurityControl?.profileDescription ?? '',
    })

    if (dialogType === 'add') {
      setCurrentPermissions(permissions ?? [])
    } else {
      setCurrentPermissions(
        dialogAddOrEditSettingsSecurityControl?.menuPermissions &&
          dialogAddOrEditSettingsSecurityControl?.menuPermissions.length > 0
          ? dialogAddOrEditSettingsSecurityControl?.menuPermissions
          : permissions ?? []
      )
    }
  }, [dialogType, dialogAddOrEditSettingsSecurityControl])

  const [isLoading, setIsLoading] = useState(false)
  const [ dialogSaveSettingsSecurityControl, setDialogSaveSettingsSecurityControl ] = useState({})

  const handleDialogParamsChange = (event) => {
    setDialogParams({
      ...dialogParams,
      [event.target.name]: event.target.value,
    })
  }

  const prepareData = (permission) => {
    const tempData = clone(permission)

    return tempData.map((item) => {
      item.permissionRequests = item.permissionResponses
      delete item.permissionResponses

      return item
    })
  }

  const closeDialog = () => {
    setDialogParams(initialDialogParams)
    setCurrentPermissions(permissions ?? [])
    setDialogAddOrEditSettingsSecurityControl(null)
    setDialogSaveSettingsSecurityControl({})
  }

  const changePermission = (event, menuId, permissionId, permissionName) => {
    const newPermission = currentPermissions.map((menu) => {
      if (menu.menuId === menuId) {
        const permissionResponses = menu.permissionResponses.map((item) => {
          // IF ACTION ON FULL ACCESS
          if (permissionName === actionCst.fullAccess) {
            item.enable = event.target.checked

            return item
          }

          // IF ACTION ON OTHER ITEM
          if (item.permissionId === permissionId) {
            item.enable = event.target.checked
          }

          return item
        })

        // CHECK IF ONE OF ITEM DISABLED
        const checkItem = permissionResponses.find(
          item => item.permissionName !== actionCst.fullAccess && item.enable === false
        )

        // IF FOUND DISABLED, THEN CHANGE FULL ACCESS TO DISABLED
        if(checkItem) {
          menu.permissionResponses = permissionResponses.map(item => {
            if(item.permissionName === actionCst.fullAccess) {
              return {
                ...item,
                enable: false
              }
            } else {
              return item
            }
          })
        } else { // IF NOT FOUND DISABLED, THEN CHANGE FULL ACCESS TO ENABLED
          menu.permissionResponses = permissionResponses.map(item => {
            if(item.permissionName === actionCst.fullAccess) {
              return {
                ...item,
                enable: true
              }
            } else {
              return item
            }
          })
        }
      }

      return menu
    })

    setCurrentPermissions(newPermission)
  }

  const handleSaveButtonClick = async () => {
    const bodyRequest = {
      profileName: dialogParams.profileName,
      profileDescription: dialogParams.description,
      menuPermissionRequests: prepareData(currentPermissions),
    }
    setIsLoading(true)

    if (dialogType === 'add') {
      const result = await createProfile(bodyRequest)
      if (result.status === 201) {
        setIsLoading(false)
        dispatch(showAlert({ message: 'Sukses Membuat Akun Baru' }))
        closeDialog()
        reloadData()
      }
    } else {
      setDialogSaveSettingsSecurityControl({
        ...bodyRequest,
        id: dialogParams?.id,
      })
    }
  }

  const handleUpdateProfileButtonClick = async () => {
    const id = dialogSaveSettingsSecurityControl?.id
    let params = dialogSaveSettingsSecurityControl
    delete params.id

    setIsLoading(true)
    const result = await updateProfile(id, params)

    if (result.status !== 'error') {
      dispatch(showAlert({ message: 'Sukses Memperbarui Akun' }))
      closeDialog()
      reloadData()
      setIsLoading(false)
    } else {
      setIsLoading(false)
      toast({ title: result?.error, icon: 'error' }).then(() => {})
      setDialogSaveSettingsSecurityControl({})
    }
  }
  
  const translateGropNamePermissions = (item) => {
    if(item === 'Dashboard') return 'Beranda'
    else if(item === 'Android Devices') return 'Perangkat Selular'
    else if(item === 'Vehicles') return 'Kendaraan'
    else if(item === 'Activity Types') return 'Jenis Kegiatan'
    else if(item === 'Disturbances') return 'Gangguan'
    else if(item === 'Cases') return 'Jenis Kejahatan'
    else if(item === 'Groups') return 'Kelompok'
    else if(item === 'GPS Devices') return 'Perangkat GPS'
    else if(item === 'Users') return 'Pengguna'
    else if(item === 'Profile') return 'Akun'
    else if(item === 'Duty Types') return 'Metode Patroli'
    else if(item === 'Officers') return 'Petugas'
    else if(item === 'Places') return 'Lokasi'
    else if(item === 'Security Control') return 'Kontrol Keamanan'
    else if(item === 'Agent Playback Video') return 'Pemutar Video Agen'
    else if(item === 'Live') return 'Siaran Langsung'
    else if(item === 'Tracking') return 'Pelacakan'
    else if(item === 'Agent Help') return 'Bantuan Agen'
    else if(item === 'Agent') return 'Agen'
    else return item
  }

  let selectedTitle = ''
  if (dialogType === 'add') selectedTitle = 'Tambah Penugasan Giat'
  else if (dialogType === 'edit') selectedTitle = 'Ubah Penugasan Giat'

  return (
    <>
      {/* DIALOG ADD OR EDIT */}
      <DialogAddOrEdit
        open={Boolean(dialogAddOrEditSettingsSecurityControl)}
        title={selectedTitle}
        onCloseIconClick={closeDialog}
        containerRef={pageRef}
        contents={[
          <>
            {/* MAIN SECTION TEXT */}
            <Typography variant='h6' className={classes.sectionText}>
              Informasi Utama
            </Typography>

            {/* PROFILE NAME INPUT */}
            <Box className={classes.iconAndFormControlContainer}>
              <Person className={classes.iconFormControl} />
              <FormControl variant='standard' className={classes.formControl}>
                <CustomInputLabel>Nama Akun</CustomInputLabel>
                <CustomInput
                  type='text'
                  name='profileName'
                  value={dialogParams.profileName}
                  onChange={handleDialogParamsChange}
                />
              </FormControl>
            </Box>

            {/* DESCRIPTION INPUT */}
            <Box className={classes.iconAndFormControlContainer}>
              <IconNotes className={classes.iconFormControl} />
              <FormControl variant='standard' className={classes.formControl}>
                <CustomInputLabel>Keterangan</CustomInputLabel>
                <CustomInput
                  type='text'
                  name='description'
                  value={dialogParams.description}
                  onChange={handleDialogParamsChange}
                />
              </FormControl>
            </Box>

            {/* BASIC PERMISSIONS */}
            <Box className={'mt-4 mb-4'}>
              <Alert severity='info'>
                <AlertTitle className={classes.basicTitle}>
                  Izin Dasar
                </AlertTitle>
                <Typography className={classes.basicSubtitle}>
                  Tetapkan izin untuk peran ini. Semua peran akan memiliki izin yang telah ditentukan sebelumnya.
                </Typography>
              </Alert>
            </Box>

            {currentPermissions?.map((item, index) => {
              return (
                <Box key={index} className={'mb-4'}>
                  <FormControl fullWidth variant='standard'>
                    <SwitchesGroup
                      groupName={translateGropNamePermissions(item.menuName)}
                      menuId={item.menuId}
                      changePermission={changePermission}
                      permissions={item.permissionResponses}
                    />
                  </FormControl>
                  <Divider sx={{ marginTop: '10px' }} />
                </Box>
              )
            })}
          </>
        ]}
        onCancelButtonClick={closeDialog}
        onSaveButtonClick={handleSaveButtonClick}
      />

      {/* LOADING */}
      <SimpleBackdrop isLoading={isLoading} />

      {/* DIALOG UPDATE SECURITY CONTROL PROFILE */}
      <DialogConfirmation
        title='Konfirmasi'
        caption='Apa Anda yakin untuk perbarui data Akun?'
        dialogConfirmationObject={dialogSaveSettingsSecurityControl}
        setDialogConfirmationObject={setDialogSaveSettingsSecurityControl}
        cancelButtonText='Batal'
        continueButtonText='Perbarui'
        onContinueButtonClick={handleUpdateProfileButtonClick}
        onCancelButtonClick={() => setDialogSaveSettingsSecurityControl({})}
      />
    </>
  )
}

export default DialogAddOrEditSettingsSecurityControl
