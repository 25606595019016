// MUI STYLES
import { makeStyles } from '@mui/styles'
import { colors } from 'constants/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    height: '100%',
    overflowY: 'scroll'
  },
  itemDetailWrapper: {
    padding: '16px 16px 16px 12px',
    borderLeftStyle: 'solid',
    borderLeftWidth: 3,
    cursor: 'pointer',
    transition: 'background-color 0.4s',
    '&:hover': {
      backgroundColor: `${colors.linkWater} !important`
    },
    '&:active': {
      backgroundColor: `${colors.perwinkleGray} !important`
    }
  },
  itemDetail: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0,
    }
  },
  itemDetailIcon: {
    color: colors.raven,
    fontSize: 14,
  },
  chipSOS: {
    backgroundColor: theme.palette.error.main,
    height: '24px !important',
    borderRadius: '32px',
    paddingTop: 5,
    '& .MuiChip-label': {
      color: theme.palette.common.white,
      paddingLeft: '10px !important',
      paddingRight: '10px !important',
      '& .MuiSvgIcon-root': {
        fontSize: 14,
      }
    }
  },
  evidenceItemTag: {
    width: 'fit-content',
    border: `1px solid ${colors.silver}`,
    backgroundColor: 'transparent',
    margin: 0,
    borderRadius: 4,
    padding: '4px 2px',
    '& .MuiChip-label': {
      padding: '4px 6px 4px 8px',
      fontWeight: 600,
      fontSize: 12,
    },
    '& .MuiChip-icon': {
      fontSize: 14,
    },
    marginBottom: 4,
    '&:last-child': {
      marginBottom: 0,
    }
  },
}))

export default useStyles