// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  titleText: {
    marginBottom: 8,
  },
  selectedDeviceItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${colors.geyser}`,
    marginBottom: 8,
    padding: 8,
    '&:last-child': {
      marginBottom: 8,
    },
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
  buttonAddDevice: {
    width: 'fit-content',
    '& .MuiSvgIcon-root': {
      fontSize: 16,
    },
    '& .MuiButton-startIcon': {
      marginRight: 4,
    }
  },
  textDark: {
    color: '#656565',
  },
  buttonAddDeviceDark: {
    color: 'white'
  },
  menuAdd: {
    zIndex: theme.zIndex.modal - 1,
    transform: 'translate(145px, -26px)',
    '& .MuiPaper-root': {
      borderRadius: 0,
      boxShadow: `0px 4px 16px 0px ${alpha(theme.palette.common.black, 0.16)}`,
      width: 'calc(500px - 48px - 48px)', // 48px is from the horizontal padding of the Dialog Add a New Vehicle component
    },
    '& .MuiMenu-list': {
      padding: 0,
    },
  },
  menuAddTitle: {
    fontWeight: 700,
    color: '#000000de'
  },
  menuTab: {
    '& .Mui-selected': {
      fontWeight: 600,
      color: '#000000de',
      backgroundColor: alpha(theme.palette.primary.main, 0.1)
    },
    '& .MuiTab-root': {
      borderBottom: '1px solid #0000001f',
      fontSize: 12,
    },
    '& .MuiTabs-indicator': {
      height: 3,
    },
    '& .MuiTab-textColorInherit': {
      color: '#000000de',
    },
  },
  menuSearchBox: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
    borderBottom: '1px solid #0000001f',
  },
  menuSearchInput: {
    width: '100%',
    marginLeft: 20,
    '& .MuiInput-input' : {
      color: '#000'
    }
  },
  menuSearchIcon: {
    marginRight: 20,
    cursor: 'pointer',
    color: '#0000008a'
  },
  menuAvailableDeviceList: {
    height: 360,
    maxHeight: 360,
    overflowY: 'auto',
  },
  availableDeviceItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 20px',
    borderBottom: '1px solid #0000001f',
    height: 64,
  },
  availableDeviceCheckbox: {
    marginLeft: -12,
    color: '#00000094'
  },
  availableDeviceData: {
    width: '100%',
    marginLeft: 8,
    color: '#000'
  },
  availableDeviceStatus: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  availableDeviceDistance: {
    color: theme.palette.text.secondary,
  },
  menuActionsBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 72,
    padding: '0px 20px',
    boxShadow: `0px -2px 4px 0px ${alpha(theme.palette.common.black, 0.08)}`
  },
  cancelButton: {
    color: colors.raven,
    fontWeight: 700,
    '&:hover': {
      backgroundColor: alpha(colors.raven, 0.1),
    },
  },
  '@media only screen and (max-height: 820px)': {
    menuAdd: {
      '& .MuiPaper-root': {
        maxWidth: 360,
      }
    }
  },
  chooseButton: {
    fontWeight: 700,
  }
}))

export default useStyles