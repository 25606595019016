// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    position: 'relative',
    zIndex: 1,
    '&.leaflet-container': {
      width: '100%',
      height: '100%',
    },
    '& .customBindPopupGeofence': {
      '& .leaflet-popup-tip-container': {
        display: 'none'
      },
      '& .leaflet-popup-content-wrapper': {
        boxShadow: 'none',
        borderRadius: 4,
      },
      '& .leaflet-popup-content': {
        margin: 0,
        width: 252,
        '& p': {
          margin: 0,
        }
      },
      '& .leaflet-popup-close-button': {
        fontSize: '32px !important',
        color: colors.spunPearl,
        top: 14,
        right: 8,
      }
    }
  },
  mapButtonContainer: {
    position: 'absolute',
    zIndex: theme.zIndex.fab,
    backgroundColor: 'white',
    borderRadius: 4,
    right: 120,
    top: 20,
    boxShadow: theme.shadows[4],
  },
  mapButton: {
    width: 40,
    height: 40,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  panelChangeMapContainer: {
    position: 'absolute',
    top: 60,
    right: 0,
    bottom: 0,
    zIndex: 4,
    width: 'auto',
    padding: 20,
    pointerEvents: 'none',
  },
  panelZoom: {
    position: 'absolute',
    right: 20,
    top: 20,
    zIndex: theme.zIndex.fab,
  },
  panelLeft: {
    position: 'absolute',
    top: 20,
    left: 20,
    zIndex: 4,
    width: 250,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    boxShadow: theme.shadows[4],
  },
  panelLeftHeader: {
    height: 48,
    padding: 12,
    alignItems: 'center',
    backgroundColor: colors.wildSand,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}))

export default useStyles