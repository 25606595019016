// ASSETS
import IconDispatchEvidenceWhite from 'assets/images/icons/dashboard-dispatch-evidence-white.svg'

// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

const InfoPatrols = (props) => {
  const { 
    region,
    total, 
  } = props

  return (
    <Stack padding='16px'>
      {/* TITLE */}
      <Typography 
        variant='body2'
        marginBottom='8px'
        fontWeight={600}
      >
        Patroli Berjalan
      </Typography>

      {/* CAPTION */}
      <Typography
        variant='caption'
        color='text.secondary'
      >
        Rangkuman Total Kegiatan yang terjadi pada suatu daerah
      </Typography>

      {/* CONTENT */}
      <Stack
        direction='row'
        spacing='16px'
        marginTop='16px'
        alignItems='center'
      >
        {/* ICON CONTAINER */}
        <Stack
          borderRadius='8px'
          backgroundColor={colors.blueRibbon}
          height={40}
          width={40}
          justifyContent='center'
          alignItems='center'
        >
          {/* ICON */}
          <Box
            component='img'
            src={IconDispatchEvidenceWhite}
            alt=''
            height='32px'
            width='32px'
          />
        </Stack>

        {/* REGION AND TOTAL TEXTS */}
        <Stack>
          {/* REGION TEXT */}
          <Typography
            variant='caption'
            color='text.secondary'
            noWrap
          >
            {region}
          </Typography>

          {/* TOTAL TEXT */}
          <Typography
            variant='h5'
            color='text.primary'
            fontWeight={700}
          >
            {total}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default InfoPatrols