// MUI STYLES
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  headerTab: {
    fontWeight: 600,
    '&.MuiTab-root.Mui-selected': {
      color: theme.palette.text.primary,
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
    },
  },
  content: {
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
  },
  informationListItem: {
    alignItems: 'unset',
    '& .MuiBadge-badge': {
      right: '18px !important',
      top: '6px !important',
    },
    '& .MuiListItemIcon-root': {
      minWidth: 'unset',
      marginRight: 12,
      marginTop: 4,
    },
    '& .MuiListItemText-primary': {
      fontWeight: 600,
      color: theme.palette.text.secondary,
      fontSize: 12,
    },
    '& .MuiListItemText-secondary': {
      fontWeight: 400,
      color: theme.palette.text.primary,
    },
  },
  informationListItemSecondary: {
    '& .MuiListItemText-secondary': {
      color: theme.palette.text.secondary,
    },
  },
  backgroundColorPrimary012: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
  },
  button: {
    textTransform: 'uppercase',
  },
  greyButton: {
    color: theme.palette.action.active,
    '&:hover': {
      backgroundColor: alpha(theme.palette.action.active, 0.12),
    },
  },
  menuAutocomplete: {
    '& .MuiPaper-root': {
      width: 460,
    },
  },
}))

export default useStyles