// CONTEXT
import PageAlertRulesContextProvider from 'contexts/PageAlertRulesContext'
import PageAnalyticsContextProvider from 'contexts/PageAnalyticsContext'
import PageOverviewDashboardContextProvider from 'contexts/PageOverviewDashboardContext'
import PageEvidencesContextProvider from 'contexts/PageEvidencesContext'
import PagePatrolsContextProvider from 'contexts/PagePatrolsContext'
import PageTrackingContextProvider from 'contexts/PageTrackingContext'

// import ActivityType from 'components/MasterData/ActivityType'
// import CrimeType from 'components/MasterData/CrimeType'
// import Group from 'components/MasterData/Group'
// import SSDGroup from 'components/MasterData/SSDGroup'
// import Video from 'components/Video'
// import Watch from 'components/Video/Watch'
// import AlertPage from 'components/Alert'
// import AlertRule from 'components/AlertRule'
// import LiveRoom from 'components/Live/LiveRoom'

// import Tracking from 'components/Tracking/Tracking'
// import DashboardOld from 'components/Dashboard/Dashboard'
// import Patroli from 'components/Patroli/Patroli'
// import DispatchPatroli from 'components/Dispatch/Patroli/Patroli'
// import Live from 'components/Live/Live'
// import UsersControl from 'components/Settings/UsersControl'
// import SecurityControl from 'components/Settings/SecurityControl'
// import Device from 'components/Device/Device'

// PAGES
import AgentHelpMeet from 'pages/LiveAgentHelpMeet/AgentHelpMeet'
import OverviewAnalytics from 'pages/OverviewAnalytics/OverviewAnalytics'
import OverviewDashboard from 'pages/OverviewDashboard/OverviewDashboard'
import DevicesMobileApps from 'pages/DevicesMobileApps/AndroidDevice'
import DevicesVehicles from 'pages/DevicesVehicles/Vehicles'
import Evidences from 'pages/Evidences/Evidences'
import Live from 'pages/Live/Live'
import LiveRoom from 'pages/LiveLiveRoom/LiveRoom'
import LiveConferenceRoom from 'pages/LiveConferenceRoom/LiveConferenceRoom'
import Patrols from 'pages/Patrols/Patrols'
import ReportEvidenceDetail from 'pages/ReportEvidenceDetail/EvidenceDetail'
import SettingsActivityTypes from 'pages/SettingsActivityTypes/ActivityTypes'
import SettingsCases from 'pages/SettingsCases/SettingsCases'
import SettingsDisturbances from 'pages/SettingsDisturbances/SettingsDisturbances'
import SettingsDutyTypes from 'pages/SettingsDutyTypes/DutyTypes'
import SettingsGroups from 'pages/SettingsGroups/Groups'
import SettingsOfficer from 'pages/SettingsOfficer/SettingsOfficer'
import SettingsPlaces from 'pages/SettingsPlaces/SettingsPlaces'
import SettingsSecurityControl from 'pages/SettingsSecurityControl/SettingsSecurityControl'
import SettingsUsers from 'pages/SettingsUsers/SettingsUsers'
import Tracking from 'pages/Tracking/Tracking'
import AlertPage from 'pages/Alert'
import AlertRules from 'pages/AlertRules'
import ChangePassword from 'pages/ChangePassword/ChangePassword'
import DispatchEvidences from 'pages/DispatchEvidences/DispatchEvidences'
import TagEvidences from 'pages/TagEvidences/TagEvidences'
import PushToTalks from 'pages/PushToTalks/PushToTalks'
import DispatchPatrols from 'pages/DispatchPatrols/DispatchPatrols'
import SettingsTagLocation from 'pages/SettingsTagLocation/SettingsTagLocation'
import SettingsSosMessageTypes from 'pages/SettingsSosMessageTypes/SettingsSosMessageTypes'

// SORTED BY MENU ORDER
const privateRouteList = [
  // OVERVIEW - DASHBOARD
  {
    isExact: true,
    path: '/',
    component: OverviewDashboard,
    isYScrollable: true,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: PageOverviewDashboardContextProvider,
  },
  // OVERVIEW - ANALYTICS
  {
    isExact: true,
    path: '/analytics',
    component: OverviewAnalytics,
    isYScrollable: true,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: PageAnalyticsContextProvider,
  },
  // AGENT
  {
    isExact: true,
    path: '/live/agent-help/id=:meetId',
    component: AgentHelpMeet,
    isYScrollable: false,
    isWithDrawer: false,
    routeType: 'private',
    providerContext: null
  },
  // LIVE
  {
    isExact: true,
    path: '/live',
    component: Live,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: null
  },
  {
    isExact: true,
    path: '/live/live/:deviceCode',
    component: LiveRoom,
    isYScrollable: false,
    isWithDrawer: false,
    routeType: 'private',
    providerContext: null
  },
  // CONFERENCE ROOM
  {
    isExact: true,
    path: '/live/conference',
    component: LiveConferenceRoom,
    isYScrollable: false,
    isWithDrawer: false,
    routeType: 'private',
    providerContext: null
  },
  // TRACKING
  {
    isExact: true,
    path: '/tracking',
    component: Tracking,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: PageTrackingContextProvider
  },
  // PENUGASAN - EVIDENCES
  {
    isExact: true,
    path: '/penugasan-kejadian',
    component: DispatchEvidences,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: null
  },
  // PENUGASAN - PATROLS
  {
    isExact: true,
    path: '/penugasan-kegiatan',
    component: DispatchPatrols,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: null
  },
  // REPORT - PATROLS
  {
    isExact: true,
    path: '/kegiatan',
    component: Patrols,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: PagePatrolsContextProvider,
  },
  // REPORT - EVIDENCES
  {
    isExact: true,
    path: '/kejadian',
    component: Evidences,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: PageEvidencesContextProvider,
  },
  {
    isExact: true,
    path: '/kejadian/:id',
    component: ReportEvidenceDetail,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: null
  },
  // DEVICES - MOBILE APPS
  {
    isExact: true,
    path: '/devices-mobile-apps',
    component: DevicesMobileApps,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: null
  },
  // DEVICES - VEHICLES
  {
    isExact: true,
    path: '/devices-vehicles',
    component: DevicesVehicles,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: null
  },
  // PUSH TO TALKS
  {
    isExact: true,
    path: '/push-to-talks',
    component: PushToTalks,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: null,
  },
  // ALERTS
  {
    isExact: true,
    path: '/alert',
    component: AlertPage,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: null
  },
  {
    isExact: true,
    path: '/alert/rule',
    component: AlertRules,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: PageAlertRulesContextProvider
  },
  // SETTINGS - DUTY TYPES
  {
    isExact: true,
    path: '/settings-duty-types',
    component: SettingsDutyTypes,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: null
  },
  // SETTINGS - ACTIVITY TYPES
  {
    isExact: true,
    path: '/settings-activity-types',
    component: SettingsActivityTypes,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: null
  },
  // SETTINGS - SOS MESSAGE TYPES
  {
    isExact: true,
    path: '/settings-sos-message-types',
    component: SettingsSosMessageTypes,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: null
  },
  // SETTINGS - DISTURBANCES
  {
    isExact: true,
    path: '/settings-disturbances',
    component: SettingsDisturbances,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: null
  },
  // SETTINGS - CASES
  {
    isExact: true,
    path: '/settings-cases',
    component: SettingsCases,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: null
  },
  // SETTINGS - TAG EVIDENCES
  {
    isExact: true,
    path: '/settings-tag-evidences',
    component: TagEvidences,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: null
  },
  // SETTINGS - OFFICERS
  {
    isExact: true,
    path: '/settings-officers',
    component: SettingsOfficer,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: null
  },
  // SETTINGS - GROUPS
  {
    isExact: true,
    path: '/settings-groups',
    component: SettingsGroups,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: null
  },
  // SETTINGS - PLACES
  {
    isExact: true,
    path: '/settings-places',
    component: SettingsPlaces,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: null
  },
  // SETTINGS - USERS
  {
    isExact: true,
    path: '/settings-users',
    component: SettingsUsers,
    // component: UsersControl,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: null
  },
  // SETTINGS - TAG LOCATION
  {
    isExact: true,
    path: '/settings-tag-location',
    component: SettingsTagLocation,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: null
  },
  // SECURITY CONTROL
  {
    isExact: true,
    path: '/settings-security-control',
    component: SettingsSecurityControl,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: null
  },
  {
    isExact: true,
    path: '/ubah-kata-sandi',
    component: ChangePassword,
    isYScrollable: false,
    isWithDrawer: true,
    routeType: 'private',
    providerContext: null
  },
  // ERROR
  // {
  //   isExact: false,
  //   path: '*',
  //   component: Error,
  //   isYScrollable: false,
  //   isWithDrawer: true,
  //   routeType: 'private',
  //   providerContext: null
  // }
]

export default privateRouteList
