import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// COMPONENTS
import ConversationItem from '../ConversationItem/ConversationItem'

// CONSTANTS
import { colors } from 'constants/colors'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { PageChatContext } from 'contexts/PageChatContext'

// MUIS
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import List from '@mui/material/List'
import Stack from '@mui/material/Stack'
import OutlinedInput from '@mui/material/OutlinedInput'

// MUI ICONS
import IconSearch from '@mui/icons-material/Search'

// UTILS
import { agoraSendMessage } from '../agoraUtils'
import { setCurrentConversation } from '../chatUtils'

const TabAllGroup = (props) => {
  const { tabType, setIsDialogDiscussionGroupShown } = props
  const parseLocalStorage = JSON.parse(localStorage.getItem('user'))

  // CONTEXT
  const {
    fetchJoinedGroup, fetchPublicGroup, conversationList,
    joinedGroupList, publicGroupList, conn
  } = useContext(PageChatContext)
  const { setSnackbarObject } = useContext(AllPagesContext)

  // STATES
  const [search, setSearch] = useState('')
  const [dataList, setDataList] = useState([])

  const handleJoinGroupClick = (event, item) => {
    event.stopPropagation()
    if (item?.groupid && conn) {
      conn?.joinGroup({
        groupId: item?.groupid,
        message: '',
        success: async () => {
          await fetchJoinedGroup()
          await fetchPublicGroup()
          setSnackbarObject({
            open: true,
            severity: 'success',
            title: '',
            message: 'Berhasil bergabung dengan grup',
          })
        },
        error: () => {
          setSnackbarObject({
            open: true,
            severity: 'error',
            title: '',
            message: 'Gagal bergabung dengan grup',
          })
        }
      })
    }
  }

  const handleMakeGroupChat = async (item) => {
    const isAlreadyJoined = conversationList?.find(itemConversation => itemConversation?.group?.id === item?.groupid)

    if (isAlreadyJoined) {
      setSnackbarObject({
        open: true,
        severity: 'warning',
        title: '',
        message: 'Anda telah tergabung didalam percakapan',
      })

      return
    }

    await agoraSendMessage(conn, {
      msg: `${parseLocalStorage?.userName} telah bergabung`,
      to: item.groupid,
      chatType: 'groupChat',
      id: (Math.random() * 1000).toFixed(0).toString(),
      type: 'txt',
      ext: {
        reply: {}
      }
    })
  
    setCurrentConversation({
      meta: {},
      group: {
        groupname: item.groupname,
        id: item.groupid
      },
      channel_id: `_${item.groupid}@`,
      unread_num: 0,
      chatType: 'groupChat'
    })
    setIsDialogDiscussionGroupShown(false)
  }

  useEffect(() => {
    if (tabType === 'searchGroup') {
      setDataList(joinedGroupList)
    } else {
      setDataList(publicGroupList.filter((item) => {
        const hasJoined = joinedGroupList.find(itemJoined => itemJoined.groupid === item.groupid)
        return hasJoined ? false : true
      }))
    }
  }, [joinedGroupList, publicGroupList, tabType])

  return (
    <Stack overflow='hidden'>
      {/* SEARCH */}
      <Stack padding='16px'>
        {/* SEARCH INPUT */}
        <FormControl variant='outlined'>
          <OutlinedInput
            type='text'
            placeholder='Pencarian'
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            startAdornment={
              <InputAdornment position='start'>
                <IconSearch />
              </InputAdornment>
            }
            sx={{
              height: 40,
              paddingLeft: '8px',
              backgroundColor: colors.whiteLilac
            }}
          />
        </FormControl>
      </Stack>

      {/* LIST */}
      <Stack overflow='auto' px='16px'>
        <List>
          {dataList.filter(item => item.groupname.toLowerCase().includes(search.toLowerCase())).map((item, index) => (
            <React.Fragment key={index}>
              <ConversationItem
                name={item?.groupname}
                title={item?.groupname}
                avatarSize='large'
                rightComponent={
                  tabType === 'joinGroup' && <Button
                    size='small'
                    variant='text'
                    className='fontWeight700'
                    onClick={event => handleJoinGroupClick(event, item)}
                  >
                    Gabung
                  </Button>
                }
                onItemClick={() => handleMakeGroupChat(item)}
              />

              <Divider />
            </React.Fragment>
          ))}
        </List>
      </Stack>
    </Stack>
  )
}

TabAllGroup.propTypes = {
  tabType: PropTypes.oneOf(['searchGroup', 'joinGroup'])
}

export default TabAllGroup