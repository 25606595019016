import { useContext, useEffect, useState } from 'react'

// CONTEXTS
import { PageAnalyticsContext } from 'contexts/PageAnalyticsContext'

// ECHART
import ReactEcharts from 'echarts-for-react'

// MUIS
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconDownload from '@mui/icons-material/Download'

// SERVICES
import { downloadEvidenceReport, getEvidenceStatsChart } from 'services/DashboardService'

// STYLES
import useStyles from './panelChartUseStyles'

// UTILS
import { createStructureLineStackChartData, restructureResultApi } from './panelChartUtils'
import { getLastNDays } from 'utilities'

const TrendEvidences = (props) => {
  const { setIsLoading } = props
  const classes = useStyles()
  const { viewType } = useContext(PageAnalyticsContext)

  const [dataListChart, setDataListChart] = useState([])
  const [rerendered, setRerendered] = useState(1)

  const fetchDataInternalEvidencesChart = async () => {
    dataListChart?.length && setDataListChart([])
  
    const lastSevenDays = getLastNDays(7)
    const initialParams = {
      'chartType': 'LINE',
      'statsName': 'COUNT',
      'from': lastSevenDays?.start,
      'to': lastSevenDays?.end,
      'timezoneOffset': 0,
    }

    const responseTags = await getEvidenceStatsChart({
      ...initialParams,
      'options': {
        'limit': 5,
        'groupBy': 'tagName'
      }
    })
    const resultTags = restructureResultApi(responseTags)

    const responseLocations = await getEvidenceStatsChart({
      ...initialParams,
      'options': {
        'limit': 5,
        'groupBy': 'slug'
      }
    })
    const resultLocations = restructureResultApi(responseLocations)

    const responseTimes = await getEvidenceStatsChart({
      ...initialParams,
      'options': {
        'limit': 5,
        'groupBy': 'incidentTime'
      }
    })
    const resultTimes = restructureResultApi(responseTimes)

    setDataListChart([
      {
        title: 'Tren Kejadian Minggu Ini',
        option: createStructureLineStackChartData(
          resultTags?.xAxisData, resultTags?.series
        ),
        isHaveData: resultTags?.isHaveData,
        groupBy: 'tagName'
      },
      {
        title: 'Daerah dengan Kejadian Terbanyak',
        option: createStructureLineStackChartData(
          resultLocations?.xAxisData, resultLocations?.series
        ),
        isHaveData: resultLocations?.isHaveData,
        groupBy: 'slug'
      },
      {
        title: 'Kejadian Terbanyak Berdasarkan Waktu',
        option: createStructureLineStackChartData(
          resultTimes?.xAxisData, resultTimes?.series, true
        ),
        isHaveData: resultTimes?.isHaveData,
        groupBy: 'incidentTime'
      }
    ])

    setRerendered(current => current++)
  }

  const fetchDataCitizenEvidencesChart = async () => {
    dataListChart?.length && setDataListChart([])

    setDataListChart([
      {
        title: 'Tren Kejadian Minggu Ini',
        option: createStructureLineStackChartData(
          [], []
        ),
        isHaveData: false
      },
      {
        title: 'Daerah dengan Kejadian Terbanyak',
        option: createStructureLineStackChartData(
          [], []
        ),
        isHaveData: false
      },
      {
        title: 'Kejadian Terbanyak Berdasarkan Waktu',
        option: createStructureLineStackChartData(
          [], []
        ),
        isHaveData: false
      }
    ])

    setRerendered(current => current++)
  }

  const handleDownloadReport = async (groupBy) => {
    setIsLoading(true)
    const lastSevenDays = getLastNDays(7)
    let groupName = ''

    if (groupBy === 'tagName') groupName = 'Tren Kejadian Minggu Ini'
    else if (groupBy === 'slug') groupName = 'Daerah dengan Kejadian Terbanyak'
    else if (groupBy === 'incidentTime') groupName = 'Kejadian Terbanyak Berdasarkan Waktu'

    const name = `${groupName} ${lastSevenDays?.start} - ${lastSevenDays?.end}.xls`

    await downloadEvidenceReport({
      'chartType': 'LINE',
      'statsName': 'COUNT',
      'from': lastSevenDays?.start,
      'to': lastSevenDays?.end,
      'timezoneOffset': 0,
      'options': {
        'limit': 5,
        'groupBy': groupBy
      }
    }, name)
    setIsLoading(false)
  }

  useEffect(() => {
    viewType === 'internal' && fetchDataInternalEvidencesChart()
    viewType === 'citizen' && fetchDataCitizenEvidencesChart()
  }, [viewType])

  return (
    <>
      {dataListChart?.map((item, index) => (
        <Stack key={index} className={classes.cardChart} marginBottom='16px'>
          <Stack className={classes.cardHeader}>
            <Typography flex={1} variant='subtitle2'>{item.title}</Typography>

            <IconButton size='small' onClick={() => handleDownloadReport(item.groupBy)}>
              <IconDownload fontSize='small' />
            </IconButton>
          </Stack>

          <Stack className={`${classes.cardContent} no-zoom`}>
            {item.isHaveData
              ? <ReactEcharts
                key={rerendered}
                option={item.option}
              />
              : <Typography variant='caption' textAlign='center'>Tidak ada data</Typography>}
          </Stack>
        </Stack>
      ))}
    </>
  )
}

export default TrendEvidences