import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

// COMPONENTS
import LoadingBox from 'components/LoadingBox/LoadingBox'
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import PageHeader from 'components/PageHeader/PageHeader'
import DialogAddEditSettingsUsersNew from './DialogAddEditSettingsUsersNew/DialogAddEditSettingsUsersNew'

// CONSTANTS
import { basePaths } from 'constants/paths'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

// MUI ICONS
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import IconDelete from '@mui/icons-material/Delete'
import IconEdit from '@mui/icons-material/Edit'

// RAMDA
import { isEmpty, reject } from 'ramda'

// SERVICES
import { searchUsers } from 'services/UserService'
import { deleteUser } from 'services/UserService'

// STORES
import { showAlert } from 'store/MainReducer'

// STYLES
import useStyles from './settingsUsersUseStyles'

// UTILITES
import { dateFormat } from 'utilities'
import { statusIcon } from '../../utilities/style'
import { isNilOrEmpty } from 'ramda-extension'

const SettingsUsers = () => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const history = useHistory()
  const location = useLocation()

  const initialFilters = {
    userRegistrationNumber: '',
    userName: '',
    phoneNumber: '',
    email: '',
    createdOn: '',
    status: '',
    profileName: '',
    roleName: '',
  }

  const pageRef = useRef()

  const groupByList = [
    {
      title: 'Don\'t Group',
      value: null,
    },
    {
      title: 'By Akun',
      value: 'profileName',
    },
  ]

  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('createdOn')
  const [size, setPageSize] = useState(100)
  const [dispatchEvidenceTableData, setSettingsUsersTableData] = useState([])
  const [filters, setFilters] = useState(initialFilters)
  const [selectedGroupBy, setSelectedGroupBy] = useState(groupByList[0])
  const [isFilterOn, setIsFilterOn] = useState(false)
  const [search, setSearch] = useState('')

  const [dialogAddOrEditSettingsUsers, setDialogAddOrEditSettingsUsers] = useState(null)
  const [dialogDeleteSettingsUsers, setDialogDeleteSettingsUsers] = useState({})
  
  const [isLoading, setIsLoading] = useState(false)

  const handleDeleteIconButtonClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()
    setDialogDeleteSettingsUsers(inputParams)
  }

  const handleEditIconButtonClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()
    history.push(
      `${basePaths.settingsUsers}?mode=edit&id=${inputParams.id}`, 
      {
        id: inputParams.id,
        row: inputParams.row,
      }
    )
  }

  const [selectionModel, setSelectionModel] = useState([])

  const getIcon = (status) => {
    switch (status) {
    case 'PENDING':
      return (
        <FiberManualRecordIcon
          sx={statusIcon.small}
          className={'mr-2 subTextColor'}
        />
      )
    case 'INACTIVE':
      return <FiberManualRecordIcon sx={statusIcon.smallInActive} />
    case 'ACTIVE':
      return <FiberManualRecordIcon sx={statusIcon.smallActive} />
    default:
      return (
        <FiberManualRecordIcon
          sx={statusIcon.small}
          className={'mr-2 subTextColor'}
        />
      )
    }
  }
  const initialColumns = [
    {
      field: 'userRegistrationNumber',
      headerName: 'Nomor Registrasi Pokok',
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'userName',
      headerName: 'Nama',
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'phoneNumber',
      headerName: 'Nomor Telepon',
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'createdOn',
      headerName: 'Dibuat',
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => {
        return (
          <Typography 
            variant='caption' 
            className={classes.normalText}
            noWrap
          >
            {dateFormat(params.value)}
          </Typography>
        )
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => {
        if (isNilOrEmpty(params.value)) {
          return ''
        }

        return (
          <Box className={classes.rowStatus}>
            <Box>{getIcon(params.value?.toUpperCase())}</Box>
            <Typography 
              variant='caption' 
              className={classes.normalText}
              noWrap
            >
              {params.value?.toLowerCase()}
            </Typography>
          </Box>
        )
      },
    },
    {
      field: 'profileName',
      headerName: 'Akun',
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'roleName',
      headerName: 'Roles',
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
    },
    {
      field: 'actions',
      headerName: '',
      width: 120,
      hide: false,
      sortable: false,
      disableColumnMenu: true,
      areFilterAndSortShown: false,
      renderCell: (params) => (
        <Box className={classes.columnActions}>
          {/* EDIT ICON */}
          <CustomTooltipBlack title='Ubah' placement='bottom'>
            <IconButton
              onClick={(event) => handleEditIconButtonClick(event, params)}
            >
              <IconEdit />
            </IconButton>
          </CustomTooltipBlack>

          {/* DELETE ICON */}
          <CustomTooltipBlack title='Hapus' placement='bottom'>
            <IconButton
              onClick={(event) => handleDeleteIconButtonClick(event, params)}
            >
              <IconDelete />
            </IconButton>
          </CustomTooltipBlack>
        </Box>
      ),
    },
  ]

  const [selectedColumnList, setSelectedColumnList] = useState(initialColumns)

  const groupingColDef = {
    headerName: 'Nomor Registrasi Pokok',
    renderCell: (params) => {
      if (!params.rowNode.isAutoGenerated) {
        return (
          <Typography 
            variant='inherit'
            noWrap
          >
            {params.row?.userRegistrationNumber}
          </Typography>
        )
      }

      return (
        <Typography 
          variant='subtitle1' 
          className={classes.groupingRow}
          noWrap
        >
          {params.rowNode.groupingKey.toLowerCase()}
        </Typography>
      )
    },
  }

  const handleColumnsMenuItemClick = (inputItem, inputIndex) => {
    let tempSelectedColumnList = [...selectedColumnList]
    if (
      selectedGroupBy.value && inputItem.field === 'profileName' ||
      selectedGroupBy.value && inputItem.field === 'userRegistrationNumber'
    ) {
    } else {
      tempSelectedColumnList[inputIndex].hide = !tempSelectedColumnList[inputIndex].hide
    }
    setSelectedColumnList(tempSelectedColumnList)
  }

  const reloadData = () => {
    const newFilters = reject(isEmpty, filters)
    const params = {
      page,
      size,
    }

    if (order) params.sort = `${orderBy},${order}`
    if (search) newFilters.globalSearch = search

    setIsLoading(true)

    searchUsers(params, newFilters).then((response) => {
      setSettingsUsersTableData(response.rows)
      setTotal(response.totalElements)
      setIsLoading(false)
    })
  }

  const handleDeleteButtonClick = async () => {
    setIsLoading(true)
    const result = await deleteUser(dialogDeleteSettingsUsers.row.userId)

    if (result.status !== 'error') {
      setIsLoading(false)
      dispatch(showAlert({ message: 'Sukses Menghapus Pengguna' }))
      setDialogDeleteSettingsUsers({})
      reloadData()
    }
  }

  useEffect(() => {
    reloadData()
  }, [page, size, order, orderBy,filters, search])

  useEffect(() => {
    if (!isFilterOn) setFilters(initialFilters)
  }, [isFilterOn])

  useEffect(() => {
    if (selectedGroupBy.value) {
      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        if (
          item.field === selectedGroupBy.value ||
          item.field === 'userRegistrationNumber'
        ) {
          item.hide = true
        }
      })
      setSelectedColumnList(tempSelectedColumnList)
    } else {
      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        if (
          item.field === 'profileName' ||
          item.field === 'userRegistrationNumber'
        ) {
          item.hide = false
        }
      })
      setSelectedColumnList(tempSelectedColumnList)
    }
  }, [selectedGroupBy])

  useEffect(() => {
    if (location.pathname === basePaths.settingsUsers && location.search === '?mode=add') {
      setDialogAddOrEditSettingsUsers({
        type: 'add'
      })
    }
    else if (location.pathname === basePaths.settingsUsers && location.search.includes('?mode=edit')) {
      setDialogAddOrEditSettingsUsers({
        type: 'edit',
        data: location?.state
      })
    }
  }, [location])

  return (
    <Box className={classes.pageRoot} ref={pageRef}>
      {/* HEADER */}
      <PageHeader
        isAddButtonAvailable={true}
        title='Pengguna'
        search={search}
        setSearch={setSearch}
        onAddButtonIsClicked={() => history.push(`${basePaths.settingsUsers}?mode=add`)}
      />
      {/* CONTENT */}
      <LoadingBox isLoading={isLoading}>
        <DataGridFilters
          columns={initialColumns}
          selectedColumnList={selectedColumnList}
          handleColumnsMenuItemClick={handleColumnsMenuItemClick}
          isFilterOn={isFilterOn}
          setIsFilterOn={setIsFilterOn}
          groupByList={groupByList}
          selectedGroupBy={selectedGroupBy}
          setSelectedGroupBy={setSelectedGroupBy}
        />

        <DataGridTable
          initialColumns={initialColumns}
          rows={dispatchEvidenceTableData}
          total={total}
          page={page}
          pageSize={size}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setPage={setPage}
          setPageSize={setPageSize}
          setFilters={setFilters}
          isFilterOn={isFilterOn}
          selectedColumnList={selectedColumnList}
          setSelectedColumnList={setSelectedColumnList}
          selectedGroupBy={selectedGroupBy}
          getTreeDataPath={(row) => [
            row.profileName,
            row.userRegistrationNumber,
          ]}
          groupingColDef={groupingColDef}
          selectionModel={selectionModel}
          setSelectionModel={setSelectionModel}
          isCheckboxPinned={true}
        />
      </LoadingBox>

      {/* DIALOG ADD / EDIT USERS */}
      {/* <DialogAddOrEditSettingsUsers
        dialogType={dialogType}
        dialogAddOrEditSettingsUsers={dialogAddOrEditSettingsUsers}
        setDialogAddOrEditSettingsUsers={setDialogAddOrEditSettingsUsers}
        reloadData={reloadData}
        pageRef={pageRef}
      /> */}
      <DialogAddEditSettingsUsersNew
        pageRef={pageRef}
        dialogAddOrEditSettingsUsers={dialogAddOrEditSettingsUsers}
        setDialogAddOrEditSettingsUsers={setDialogAddOrEditSettingsUsers}
        reloadData={reloadData}
      />

      {/* DIALOG DELETE USERS */}
      <DialogConfirmation
        title='Hapus Pengguna'
        caption='Apa Anda yakin ingin menghapus pengguna ini?'
        dialogConfirmationObject={dialogDeleteSettingsUsers}
        setDialogConfirmationObject={setDialogDeleteSettingsUsers}
        cancelButtonText='Batal'
        continueButtonText='Hapus'
        onContinueButtonClick={handleDeleteButtonClick}
        onCancelButtonClick={() => setDialogDeleteSettingsUsers({})}
      />
    </Box>
  )
}

export default SettingsUsers
