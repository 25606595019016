import { useEffect, useRef } from 'react'
import ReactDOMServer from 'react-dom/server'

// CONSTANTS
import { colors } from 'constants/colors'
import { iconListTagEvidences } from 'constants/icons'

// LEAFLET
import L from 'leaflet'
import MarkerAlert from './MarkerAlert'

// MUIS
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { alpha } from '@mui/material/styles'

// MUI ICONS
import IconSos from '@mui/icons-material/Sos'
import IconFactCheck from '@mui/icons-material/FactCheck'
import IconHeadsetMic from '@mui/icons-material/HeadsetMic'
import IconCheck from '@mui/icons-material/Check'
import IconClear from '@mui/icons-material/Clear'

const ListMarkers = (props) => {
  const { notificationList, map, paramsStatus } = props
  const featureGroupRef = useRef(L.featureGroup())

  const generateMarkers = () => {
    if (!map.hasLayer(featureGroupRef.current)) {
      featureGroupRef.current.addTo(map)
    }

    featureGroupRef.current.clearLayers()

    if (notificationList?.length && map) {
      notificationList?.forEach(item => {
        if (item.latitude && item.longitude) {
          let markerType = 'evidence'
          let MarkerIcon = IconSos

          if (item.eventType.includes('EVIDENCE') || item.eventType.includes('PATROL')) {
            markerType = 'evidence'
            MarkerIcon = IconFactCheck
          } else if (item.eventType.includes('CONFERENCE') || item.eventType.includes('LIVE') || item.eventType.includes('AGENT')) {
            markerType = 'conference'
            MarkerIcon = IconHeadsetMic
          } else if (item.eventType.includes('SOS')) {
            markerType = 'sos'
            MarkerIcon = IconSos
          }

          const marker = MarkerAlert(
            [parseFloat(item.latitude), parseFloat(item.longitude)],
            markerType
          )

          const IconSosType = iconListTagEvidences[Number(item?.sos?.messageType?.iconId)-1]?.Icon
          const colorSosType = item?.sos?.messageType?.sosMessageTypeColor
          
          // TOOLTIP
          marker.bindPopup(ReactDOMServer.renderToString(
            <>
              <Stack style={{ flexDirection: 'row', flexWrap: 'nowrap', display: 'flex', alignItems: 'center' }}>
                <Typography variant='subtitle1' style={{
                  flex: 1,
                  fontWeight: '700 !important',
                  fontSize: 16,
                  margin: 0,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  paddingRight: '8px'
                }}>{item?.deviceName ?? '-'}</Typography>

                <Chip
                  variant='filled'
                  label={<MarkerIcon />}
                  className={`custom-chip ${markerType}`}
                />

                {(item.eventType.includes('SOS') && item?.sos?.messageType) ? <Chip
                  variant='filled'
                  label={item?.sos?.messageType?.sosMessageTypeName ?? '-'}
                  icon={IconSosType && <IconSosType
                    style={{
                      color: colorSosType,
                      marginRight: '4px'
                    }}
                  />}
                  className='custom-chip sosMessageType'
                  style={{
                    border: `1px solid ${colorSosType}`,
                    borderRadius: '20px',
                    backgroundColor: colorSosType ? alpha(colorSosType, 0.24) : '',
                    color: colorSosType,
                    marginLeft: '8px'
                  }}
                /> : ''}
              </Stack>

              <Typography
                variant='caption'
                style={{
                  margin: '4px 0',
                  display: 'block'
                }}
              >Titik Referensi</Typography>

              <Typography
                style={{
                  textWrap: 'wrap',
                  margin: '0 0 4px 0',
                }}
              >
                {item?.location ?? '-'}
              </Typography>

              <Typography
                style={{
                  textWrap: 'wrap',
                  margin: 0,
                }}
              >
                {item?.latitude ?? '-'}, {item?.longitude ?? '-'}
              </Typography>

              {item.eventType.includes('SOS') ? (
                <>
                  <Divider style={{
                    margin: '12px 0'
                  }}/>

                  <Typography
                    variant='caption'
                    style={{
                      margin: '4px 0',
                      display: 'block'
                    }}
                  >Instruksi Tambahan</Typography>

                  <Typography
                    style={{
                      textWrap: 'wrap',
                      margin: '0 0 4px 0',
                    }}
                  >
                    {item?.sos?.description ?? '-'}
                  </Typography>
                </>
              ) : ''}

              {item?.sos?.status === 'REPORTED' ? (
                <>
                  <Divider style={{
                    margin: '12px 0'
                  }}/>

                  <Stack style={{ flexDirection: 'row', flexWrap: 'nowrap', display: 'flex', alignItems: 'center' }}>
                    <a
                      style={{
                        backgroundColor: colors.clementine,
                        border: 'none',
                        color: 'white',
                        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.32)',
                        borderRadius: '4px',
                        padding: '6px 16px',
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 700,
                        fontSize: 14,
                      }}
                      href={`/alert?alertNo=${item.alertNo}&status=accept`}
                    >
                      <IconCheck
                        style={{
                          fontSize: '20px',
                          marginRight: '8px',
                          color: 'white'
                        }}
                      />
                      TERIMA
                    </a>

                    <a
                      style={{
                        marginLeft: '12px',
                        flex: 1,
                        backgroundColor: 'transparent',
                        border: '1px solid #D10E00',
                        color: '#D10E00',
                        borderRadius: '4px',
                        padding: '6px 16px',
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 700,
                        justifyContent: 'center',
                        fontSize: 14,
                      }}
                      href={`/alert?alertNo=${item.alertNo}&status=cancel`}
                    >
                      <IconClear
                        style={{
                          fontSize: '20px',
                          marginRight: '8px',
                          color: '#D10E00'
                        }}
                      />

                      TOLAK
                    </a>
                  </Stack>
                </>
              ) : ''}
            </>
          ), {
            direction: 'top',
            className: 'popup-marker-clear popup-marker-alert',
            closeButton: false,
            maxWidth: 340,
            minWidth: 340,
            offset: [0, -20]
          })

          if (item.alertNo === Number(paramsStatus?.alertNo)) {
            marker.on('add', event => {
              map.flyTo([parseFloat(item.latitude), parseFloat(item.longitude)], 14)
              event.target.openPopup()
            })
          }

          featureGroupRef.current?.addLayer(marker)
        }
      })
    }
  }

  useEffect(() => {
    generateMarkers()
  }, [map, notificationList, paramsStatus])

  return (
    <></>
  )
}

export default ListMarkers