import { useContext } from 'react'

// COMPONENTS
import CardEvidence from 'components/CardEvidence/CardEvidence'
import DialogEvidenceDetail from 'components/DialogEvidenceDetail/DialogEvidenceDetail'

// CONTEXTS
import { PageAnalyticsContext } from 'contexts/PageAnalyticsContext'

// MUIS
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './evidencesListUseStyles'

const EvidencesList = () => {
  const classes = useStyles()
  const { selectedEvidenceItem, setSelectedEvidenceItem, viewType, dataEvidences } = useContext(PageAnalyticsContext)

  return (
    <>
      <Stack className={classes.root}>
        {viewType === 'internal' && dataEvidences.slice(0, 100).map(item => (
          <Stack key={item.evidenceNo} className={classes.itemCardList}>
            <CardEvidence
              item={item}
              uploaderName={item.deviceName}
              setSelectedEvidenceItem={setSelectedEvidenceItem}
            />
          </Stack>
        ))}

        {viewType === 'citizen' && dataEvidences.slice(0, 100).map(item => (
          <Stack key={item.evidenceNo} className={classes.itemCardList}>
            <CardEvidence
              key={item.evidenceNo}
              item={item}
              setSelectedEvidenceItem={setSelectedEvidenceItem}
            />
          </Stack>
        ))}

        {dataEvidences.length ? '' : <Typography align='center' variant='body2' className='textSecondary'>
          Data tidak ditemukan
        </Typography>}
      </Stack>

      {/* EVIDENCE DETAIL DIALOG */}
      <DialogEvidenceDetail
        isDialogOpen={Boolean(selectedEvidenceItem)} 
        handleDialogClose={() => setSelectedEvidenceItem(null)}
        evidenceObject={selectedEvidenceItem}
      />
    </>
  )
}

export default EvidencesList