import React from 'react'

// CONSTANTS
import { values } from 'constants/values'

// MUIS
import MuiGlobalStyles from '@mui/material/GlobalStyles'
import { alpha } from '@mui/material/styles'
import { colors } from 'constants/colors'

const GlobalStyles = () => {
  const zoomValue = 0.85

  return (
    <MuiGlobalStyles
      styles={(theme) => ({
        // ALL ELEMENTS
        '*, *::before, *::after': {
          boxSizing: 'border-box',
          fontFamily: values.fontFamily,
          shapeRendering: 'geometricPrecision',
          textRendering: 'geometricPrecision',
          imageRendering: 'optimizeQuality',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
        },

        // CLASSES
        '.alignSelfBaseline': {
          alignSelf: 'baseline'
        },
        '.fontWeight700': {
          fontWeight: 700
        },
        '.textSecondary': {
          color: theme.palette.text.secondary,
        },
        '.marginRight8': {
          marginRight: 8,
        },
        '.overflowYAuto': {
          overflowY: 'auto'
        },
        '.tooltip-marker-clear': {
          opacity: '1 !important',
          width: 300,
          padding: 12,
          backgroundColor: colors.blackSqueeze,
          boxShadow: `0px 0px 16px 0px ${alpha(theme.palette.common.black, 0.24)}`,
          borderRadius: 4,
          borderColor: colors.blackSqueeze,
          '&::before': {
            borderWidth: 20,
            borderTopColor: colors.blackSqueeze,
            marginBottom: '-41px',
            marginLeft: '-21px',
            filter: `drop-shadow(-1px 9px 2px ${alpha(theme.palette.common.black, 0.1)})`
          },
          '&.tooltip-object::before': {
            marginBottom: '-41px',
            marginLeft: '-15.5px',
          }
        },
        '.popup-marker-clear': {
          opacity: '1 !important',
          width: 340,
          padding: 12,
          backgroundColor: 'transparent',
          boxShadow: 'transparent',
          borderRadius: 4,
          borderColor: colors.blackSqueeze,
          '&::before': {
            borderWidth: 20,
            borderTopColor: colors.blackSqueeze,
            marginBottom: '-41px',
            marginLeft: '-21px',
            filter: `drop-shadow(-1px 9px 2px ${alpha(theme.palette.common.black, 0.1)})`
          },
          '&.popup-object::before': {
            marginBottom: '-41px',
            marginLeft: '-15.5px',
          },
          '& .leaflet-popup-content-wrapper': {
            borderRadius: 4
          }
        },
        '.iconfont': {
          '&::before': {
            fontFamily: 'iconfont !important'
          }
        },

        // PRINT
        '.printComponent': {
          display: 'none !important',
          // MAX HEIGHT AND WIDTH ARE NOT WORKING HERE
          // maxWidth: '100%',
          // maxHeight: '100%',
        },
        '@media print': {
          '.printComponent': {
            // MAX HEIGHT AND WIDTH ARE NOT WORKING HERE
            // maxWidth: '100%',
            // maxHeight: '100%',
            display: 'flex !important',
          }
        },

        // SCROLLBAR
        '&::-webkit-scrollbar': {
          width: 5,
          height: 5,
          backgroundColor: alpha('#000000', 0.15),
        },
        '&::-webkit-scrollbar-thumb': {
          width: 5,
          height: 5,
          backgroundColor: alpha('#000000', 0.2),
        },
        '.scroll-display-none': {
          '&::-webkit-scrollbar' : {
            display : 'none'
          },
        },

        // TINYMCE
        '.tox': {
          '& .tox-editor-header': {
            padding: '0 !important',
          },
          '& .tox-toolbar__group': {
            borderRight: '2px solid #E5E9EC !important',
          },
          '& .tox-toolbar__overflow': {
            padding: '3px 0 !important',
          },
        },

        // ZOOM
        '@media only screen and (max-height: 820px)': {
          'body': {
            zoom: zoomValue,
          },
          '.zoom': {
            zoom: zoomValue,
          },
          '.no-zoom': {
            zoom: 1 / zoomValue,
          },
          '.leaflet-contextmenu a.leaflet-contextmenu-item': {
            zoom: zoomValue,
          },
          // AUTOCOMPLETE PURE
          '.neutralize-zoom-autocomplete': {
            '& .MuiInputBase-input': {
              zoom: zoomValue,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1.5,
            },
            '& .MuiFormLabel-root': {
              zoom: zoomValue,
            },
            '& legend': {
              zoom: zoomValue,
            },
            '& .MuiButtonBase-root': {
              zoom: zoomValue,
            }
          },
          '.MuiAutocomplete-popper li': {
            fontSize: 12,
          },

          // MENU
          '.neutralize-zoom-menu': {
            '& .MuiPaper-root': {
              zoom: 1 / zoomValue,
            },
            '& .MuiList-root': {
              zoom: zoomValue,
            }
          },
        },

        // LEAFLET
        '.leaflet-control-attribution': {
          'a:first-child': {
            display: 'none',
          },
          'span': {
            display: 'none'
          }
        },
        '.popup-wrapper-geofences': {
          bottom: 'unset !important',
          '& .leaflet-popup-content-wrapper': {
            backgroundColor: alpha(colors.california, 0.74),
            borderRadius: 0,
            boxShadow: 'none'
          },
          '& .leaflet-popup-content': {
            margin: 4,
            color: theme.palette.common.white,
          },
          '& .leaflet-popup-tip': {
            display: 'none'
          }
        }
      })}
    />
  )
}

export default GlobalStyles