import React, { useEffect, useState, useRef } from 'react'

import { Box, Collapse, IconButton } from '@mui/material'
import Typography from '@mui/material/Typography'

// STYLES
import { styled } from '@mui/material/styles'
import useStyles from './settingsPlacesUseStyles'

// LEAFLET
import { 
  MapContainer, 
  TileLayer,
  Circle,
} from 'react-leaflet'

import {
  AutofpsSelect,
  ExpandMore as ExpandMoreIcon,
  Place,
  Radar,
} from '@mui/icons-material'

// STYLES
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export default function SettingsPlacesDetail({ place, reloadData }) {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(true)
  const [mapRadius, setMapRadius] = useState('')
  const [mapCenter, setMapCenter] = useState([])
  const [map, setMap] = useState(null)
  const pathColor = { color: '#EF6C00' }

  const circleRef = useRef()

  useEffect(() => {
    if(place.zone){
      setMapRadius(place.zone.area.replace(')', '').split(' '))
      let center = place.zone.area.replace('(', '').replace(',', '').split(' ')
      setMapCenter([center[1] , center[2]])
    }
  }, [place])

  useEffect(() => {
    if(mapCenter.length > 0 && map) {
      map.fitBounds(circleRef.current.getBounds())
    }
  }, [map, mapCenter])
  
  if (place === null) {
    return ''
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        color={'#6F7687'}
        sx={{
          background: '#fff',
          borderRadius: 1,
        }}
      >
        <Box className={classes.mapsSection}>
          {/* MAP */}
          <MapContainer
            center={[ -0.7893, 113.9213 ]}
            zoomControl={false}
            zoom={2}
            className={classes.mapContainer}
            whenReady={(mapObject) => setMap(mapObject.target)}
          >
            {/* BASE MAP */}
            <TileLayer
              attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/about/">OpenStreetMap</a> <a href="https://www.mapbox.com/map-feedback/#/-74.5/40/10">Improve this map</a>'
              url={`https://api.mapbox.com/styles/v1/${process.env.REACT_APP_MAPBOX_USERNAME}/${process.env.REACT_APP_MAPBOX_STYLE_ID_DARK}/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`}
            />

            {/* CIRCLE */}
            {mapCenter.length > 0 &&
            <Circle 
              ref={circleRef}
              center={mapCenter} 
              pathOptions={pathColor} 
              radius={mapRadius[3]} 
            />}
          </MapContainer>
          
        </Box>
        <div className={classes.topMainSection}>
          <h4 className={classes.titleMainSection}>Bagian Utama</h4>
          <ExpandMore
            expand={expanded}
            onClick={() => setExpanded(!expanded)}
            aria-expanded={expanded}
            aria-label='show more'
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </div>

        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <div className={'flex mt-4 alignItemsCenter'}>
            <AutofpsSelect className={classes.iconMargin} />
            <div>
              <Typography variant='body2' className={classes.labelDataTitle}>
                Nama Lokasi
              </Typography>
              <Typography variant='body2' className={classes.labelData}>
                {place.name}
              </Typography>
            </div>
          </div>

          <div className={'flex mt-4 alignItemsCenter'}>
            <Place className={classes.iconMargin} />
            <div>
              <Typography variant='body2' className={classes.labelDataTitle}>
                Alamat
              </Typography>
              <Typography variant='body2' className={classes.labelData}>
                {place.address}
              </Typography>
            </div>
          </div>

          <div className={'flex mt-4 alignItemsCenter'}>
            <Radar className={classes.iconMargin} />
            <div>
              <Typography variant='body2' className={classes.labelDataTitle}>
                Radius
              </Typography>
              <Typography variant='body2' className={classes.labelData}>
                {mapRadius[3]}
              </Typography>
            </div>
          </div>
        </Collapse>
      </Box>
    </Box>
  )
}
