// MUIS
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  headerRoot: {
    display: 'flex',
    marginBottom: 24,
    alignItems: 'center',
  },
  addIconButton: {
    marginRight: 25,
  },
  title: {
    fontWeight: 600,
    marginRight: 'auto',
  },
  flyoutTitle: {
    fontWeight: 600,
    display: 'none',
  },
  flyoutTitleShown: {
    display: 'unset',
    marginLeft: 24,
  },
  flyoutInitialToggle: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.25s ease-in-out',
  },
  flyoutRotateToggle: {
    transform: 'rotate(-180deg)',
    transition: 'transform 0.25s ease-in-out',
  },
}))

export default useStyles