import axios from 'axios'
import { http } from 'utilities/http'

export const postMediaPresignedUrl = async (params, inputFileObject) => {
  try {
    const response =  await http.post('/medias/pre-signed-url', params)

    if (response?.status === 200) {
      try {
        await axios.put(response.data.url, inputFileObject, {
          headers: {
            'Content-Type': inputFileObject.type,
          }
        })
      } catch (error) {
        if (!error.response) return { status: 'No Server Response' }
        else return error.response
      }
    }

    return response
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

// export const getMediaPresignedUrl = async (inputSignal, inputQuery, inputFileObject) => {
//   try {
//     const response = await axios.get(`/media/presigned-url?${stringify(inputQuery)}`, {
//       signal: inputSignal,
//     })
  
//     if(didSuccessfullyCallTheApi(response?.status)) {
//       try {
//         await axiosPure.put(response.data.value.url, inputFileObject, {
//           headers: {
//             'Content-Type': inputFileObject.type,
//           }
//         })
//       } catch (error) {
//         if (!error.response) return { status: 'No Server Response' }
//         else return error.response
//       }
//     }
  
//     return response
//   } catch (error) {
//     if (!error.response) return { status: 'No Server Response' }
//     else return error.response
//   }
// }