import { useState } from 'react'
import { useHistory } from 'react-router-dom'

// COMPONENTS
import DialogAddOrEdit from 'components/DialogAddOrEditNew/DialogAddOrEdit'
import MenuDeviceList from './MenuDeviceList'

// CONSTANTS
import { basePaths } from 'constants/paths'

// CUSTOM COMPONENTS
import CustomFormControl from 'components/Customs/CustomFormControl'
import CustomInputNew from 'components/Customs/CustomInputNew'
import CustomInputLabelNew from 'components/Customs/CustomInputLabelNew'

// MUIS
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconArrowDropDown from '@mui/icons-material/ArrowDropDown'
import IconPhoneAndroid from '@mui/icons-material/PhoneAndroid'

// STYLES
import useStyles from './dialogAddDeviceUseStyles'

const DialogAddDevice = (props) => {
  const { 
    pageRef,
    dialogAddDevice,
  } = props

  const classes = useStyles()

  const history = useHistory()

  const [ menuDeviceListAnchor, setMenuDeviceListAnchor ] = useState(null)
  const [ selectedDevice, setSelectedDevice ] = useState(null)

  return (
    <DialogAddOrEdit
      open={Boolean(dialogAddDevice)}
      title='Tambah Perangkat'
      isExpandButtonAvailable={false}
      onCloseIconClick={() => history.push(basePaths.pushToTalks)}
      containerRef={pageRef}
      className=''
      contents={[
        <Stack>
          {/* SECTION TITLE */}
          <Typography 
            variant='h6'
            className={classes.sectionTitle} 
          >
            Informasi
          </Typography>

          {/* CHANNEL NAME */}
          <CustomFormControl variant='filled' className={classes.formControl}>
            {/* INPUT LABEL */}
            <CustomInputLabelNew shrink={true}>
              {/* ICON */}
              <IconPhoneAndroid/>

              {/* TEXT */}
              <Typography variant='subtitle1'>
                Perangkat
              </Typography>
            </CustomInputLabelNew>

            {/* INPUT */}
            <CustomInputNew
              disableUnderline
              placeholder='Pilih Perangkat'
              onClick={(event) => setMenuDeviceListAnchor(event.currentTarget)}
              endAdornment={
                <InputAdornment position='end'>
                  <IconArrowDropDown/>
                </InputAdornment>
              }
              value={selectedDevice?.label}
              onChange={() => {}}
            />
          </CustomFormControl>

          {/* MENU DEVICE LIST */}
          <MenuDeviceList
            menuDeviceListAnchor={menuDeviceListAnchor}
            setMenuDeviceListAnchor={setMenuDeviceListAnchor}
            selectedDevice={selectedDevice}
            setSelectedDevice={setSelectedDevice}
          />
        </Stack>
      ]}
      onCancelButtonClick={() => history.push(basePaths.pushToTalks)}
      onSaveButtonClick={() => history.push(basePaths.pushToTalks)}
    />
  )
}

export default DialogAddDevice