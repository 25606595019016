// CONSTANTS
import { colors } from 'constants/colors'

// MATERIAL UIS
import { createTheme } from '@mui/material/styles'

const customTheme = createTheme({
  palette: {
    primary: {
      main: colors.clementine,
    },
  },
  typography: {
    fontFamily: [ 'DIN Regular', 'sans-serif' ].join(','),
  },
})

export default customTheme