// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 24,
    position: 'relative',
  },
  panelContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },
  panelLeft: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    left: 2,
    top: 5,
    height: '100%',
    width: 350,
    marginRight : 20,
  },
  panelRight: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    right: 2,
    top: 5,
    height: '100%',
    width: '100%',
  }
}))

export default useStyles
