// UTILITIES
import { http } from 'utilities/http'
import {stringify} from 'query-string'

export async function getListPlayback(params, query) {
  try {
    const { data } = await http.post(`/recordings/search?${stringify(query)}`, params)
    return data
  } catch(err) {
    return {
      status: 'error',
      rows: []
    }
  }
}