import { useCallback, useContext, useEffect, useState } from 'react'

// COMPONENTS
import CustomDialogDelete from 'components/Customs/CustomDialogDelete'
import TabListMember from './TabListMember'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import DialogAddDevice from '../DialogAddDevice/DialogAddDevice'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { PageChatContext } from 'contexts/PageChatContext'

// MUIS
import Divider from '@mui/material/Divider'
import Dialog from '@mui/material/Dialog'
import Fab from '@mui/material/Fab'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconClose from '@mui/icons-material/Close'
import IconGroup from '@mui/icons-material/Group'
import IconLogout from '@mui/icons-material/Logout'
import IconAdd from '@mui/icons-material/Add'

// STYLES
import useStyles from './dialogDetailGroupUseStyles'

// UTILS
import { getBackgroundAvatar } from 'utilities'
import { convertEmailToAgoraId, getCurrentConversation, getUserNicknameFromFriends, getUserRoleFromFriends, setCurrentConversation } from '../chatUtils'

const DialogDetailGroup = () => {
  // CONTEXTS
  const {
    isDialogDetailGroupShown, setIsDialogDetailGroupShown, conn,
    friendList, fetchJoinedGroup, fetchPublicGroup, fetchConversationList
  } = useContext(PageChatContext)
  const { setSnackbarObject } = useContext(AllPagesContext)
  
  const classes = useStyles()
  const parseLocalStorage = JSON.parse(localStorage.getItem('user'))

  const initialListTabs = (totalMember = 0) => [
    {
      label: `Anggota (${totalMember})`,
      icon: IconGroup,
      value: 'member'
    },
    {
      label: 'Tinggalkan Grup',
      icon: IconLogout,
      value: 'leave'
    }
  ]

  // STATES
  const [currentTab, setCurrentTab] = useState(initialListTabs()[0].value)
  const [isDialogLeaveShown, setIsDialogLeaveShown] = useState({})
  const [isDialogAddDeviceShown, setIsDialogAddDeviceShown] = useState(false)
  const [selectedDevices, setSelectedDevices] = useState([])
  const [detailGroupData, setDetailGroupData] = useState({})
  const [isAdmin, setIsAdmin] = useState(false)

  const resetAllStates = () => {
    setCurrentTab(initialListTabs()[0].value)
    setIsDialogDetailGroupShown(false)
  }

  const handleLeaveClick = () => {
    if (isAdmin) {
      setSnackbarObject({
        open: true,
        severity: 'warning',
        title: '',
        message: 'Anda tidak bisa keluar dari grup karena admin',
      })
    } else if (isDialogDetailGroupShown?.id && conn) {
      conn?.leaveGroup({
        groupId: isDialogDetailGroupShown?.id
      })
        .then(async () => {
          const dataCurrentConversation = getCurrentConversation()
          await fetchJoinedGroup()
          await fetchPublicGroup()
          await fetchConversationList()
          setSnackbarObject({
            open: true,
            severity: 'success',
            title: '',
            message: 'Berhasil keluar dari grup',
          })
          if (Object.keys(dataCurrentConversation)?.length) setCurrentConversation(null)
          setCurrentTab(initialListTabs()[0].value)
          setIsDialogLeaveShown({})
          setIsDialogDetailGroupShown(false)
        })
    }
  }

  const handleCancelLeaveClick = () => {
    setCurrentTab(initialListTabs()[0].value)
    setIsDialogLeaveShown({})
  }

  const fetchDetailGroup = useCallback(() => {
    if (isDialogDetailGroupShown?.id && conn && friendList?.length) {
      conn?.getGroupInfo({
        groupId: isDialogDetailGroupShown?.id,
        success: (response) => {
          const findUserAdmin = response?.data?.[0]?.affiliations?.find(item => item?.owner === convertEmailToAgoraId(parseLocalStorage.email))
          setIsAdmin(Boolean(findUserAdmin))
          setDetailGroupData(response?.data?.[0] ? {
            ...response?.data?.[0],
            affiliations: response?.data?.[0]?.affiliations?.map(item => {
              const nickname = getUserNicknameFromFriends(friendList, item?.member ?? item?.owner)
              const role = getUserRoleFromFriends(friendList, item?.member ?? item?.owner)
              return {
                ...item,
                userId: item?.member ?? item?.owner,
                nickname: nickname === '-' ? parseLocalStorage.userName : nickname,
                role: role === '-' ? parseLocalStorage?.role?.roleName : role,
                type: item.owner ? 'admin' : 'member'
              }
            })
          } : {})
        }
      })
    }
  }, [conn, friendList, isDialogDetailGroupShown])

  useEffect(() => {
    if (currentTab === initialListTabs()[1].value) {
      setIsDialogLeaveShown({ delete: true })
    } else {
      setIsDialogLeaveShown({})
    }
  }, [currentTab])

  useEffect(() => {
    fetchDetailGroup()
  }, [fetchDetailGroup])

  return (
    <>
      <CustomDialogDelete
        open={Boolean(isDialogDetailGroupShown)}
        className={classes.dialog}
        sx={{
          '& .MuiPaper-root': {
            height: '604px !important',
          },
        }}
        onClose={() => resetAllStates()}
        slotProps={{
          backdrop: {
            invisible: (isDialogDetailGroupShown?.from === 'dialogDicussion') ? false : true
          }
        }}
      >
        {/* HEADER */}
        <Stack className={classes.dialogHeader}>
          <Typography className={classes.dialogHeaderTitle}>
            Detail Grup
          </Typography>

          <IconButton onClick={() => resetAllStates()}>
            <IconClose />
          </IconButton>
        </Stack>

        <Divider />

        {/* CONTENT */}
        <Stack direction='row' flex={1} overflow='hidden'>
          {/* LIST TABS */}
          <Stack className={classes.contentListTabs}>
            {/* DETAIL GROUP */}
            <Stack alignItems='center' padding='16px'>
              <ListItemAvatar
                sx={{
                  backgroundColor: getBackgroundAvatar(isDialogDetailGroupShown?.name ?? '')
                }}
                className={classes.conversationAvatar}
              >
                <Typography fontWeight={600} variant='h4' color='white'>
                  {isDialogDetailGroupShown?.name?.toUpperCase()?.split(' ')?.[0]?.[0]}
                  {isDialogDetailGroupShown?.name?.toUpperCase()?.split(' ')?.[1]?.[0]}
                </Typography>
              </ListItemAvatar>

              <Typography fontWeight={700} variant='subtitle2'>
                {isDialogDetailGroupShown?.name}
              </Typography>

              <Typography variant='caption' color='text.secondary'>
                Group ID: {isDialogDetailGroupShown?.id}
              </Typography>
            </Stack>

            {/* LIST TABS MENU */}
            <List disablePadding>
              {initialListTabs(detailGroupData?.affiliations_count ?? 0)?.map((item, index) => (
                <ListItem disablePadding key={index}>
                  <ListItemButton
                    selected={currentTab === item.value}
                    className={classes.listItemButton}
                    onClick={() => setCurrentTab(item.value)}
                  >
                    <ListItemIcon>
                      <item.icon />
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Stack>

          {/* CONTENT TABS */}
          <Stack flex={1} overflow='hidden' position='relative'>
            {currentTab === initialListTabs()[0].value && <TabListMember memberList={detailGroupData?.affiliations ?? []} />}

            {/* ADD NEW MEMBER */}
            {isAdmin && <Stack position='absolute' bottom='16px' right='16px'>
              <Fab color='primary' onClick={() => setIsDialogAddDeviceShown(true)}>
                <IconAdd />
              </Fab>
            </Stack>}
          </Stack>
        </Stack>
      </CustomDialogDelete>

      {/* DIALOG LEAVE */}
      <DialogConfirmation
        title='Konfirmasi'
        caption='Apa anda yakin ingin keluar dari grup?'
        dialogConfirmationObject={isDialogLeaveShown}
        setDialogConfirmationObject={setIsDialogLeaveShown}
        cancelButtonText='Batal'
        continueButtonText='Keluar'
        onContinueButtonClick={() => handleLeaveClick()}
        onCancelButtonClick={handleCancelLeaveClick}
      />

      {/* DIALOG ADD DEVICE */}
      <Dialog
        open={isDialogAddDeviceShown}
        slotProps={{
          backdrop: {
            invisible: true
          }
        }}
      >
        <DialogAddDevice
          selectedDevices={selectedDevices}
          setSelectedDevices={setSelectedDevices}
          setDialogOpen={setIsDialogAddDeviceShown}
          memberList={detailGroupData?.affiliations ?? []}
          isDialogDetailGroupShown={isDialogDetailGroupShown}
          onPage='inviteMember'
        />
      </Dialog>
    </>
  )
}

export default DialogDetailGroup