// MUI STYLES
import { makeStyles } from '@mui/styles'
import { colors } from 'constants/colors'

const useStyles = makeStyles((theme) => ({
  rootDialog: {
    zIndex: theme.zIndex.modal - 1,
    '& .MuiDialog-paper': {
      maxWidth: 'unset',
    },
    '& .MuiPaper-root': {
      borderRadius: 0,
      backgroundColor: colors.blackSqueeze
    },
  },
  root: {
    width: 1008,
  },
  titleDialog: {
    fontSize: 18,
    fontWeight: 700,
    flex: 1,
  },
  groupTitle: {
    fontWeight: 700,
    marginBottom: 16,
  },
  formControl: {
    marginBottom: 16,
    borderRadius: '2px !important',
    '& .formControlDatePicker': {
      cursor: 'pointer'
    },
    '&:last-child': {
      marginBottom: 0,
    }
  },
  dateTimePickerWrapper: {
    padding: '40px 4px 4px 4px'
  },
  detailDateIcon: {
    fontSize: 16,
    color: theme.palette.text.secondary
  },
  detailDateTitle: {
    fontSize: 12,
    color: theme.palette.text.primary,
    paddingLeft: 8,
    fontWeight: 600,
  },
  detailDateDesc: {
    fontSize: 10,
    color: theme.palette.text.secondary,
    paddingLeft: 8,
    marginTop: 4,
  },
  boxPreviewDatePicker: {
    backgroundColor: theme.palette.common.white,
    alignItems: 'center',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    flex: 1,
    padding: '0 12px',
    height: 36,
    borderRadius: 2,
  },
  editorWrapper: {
    padding: '40px 3px 3px 3px',
    '& .tox-tinymce': {
      borderRadius: 0,
    }
  },
  pockbaseStyles: {
    backgroundColor: '#e4e9ec',
    transition: 'background-color .4s',
    padding: '4px 0 4px 12px',
    borderRadius: 2,
    '& .MuiInput-input': {
      padding: '12px 16px'
    },
    '&:focus-within': {
      backgroundColor: '#D7DDE1'
    }
  },
  pockbaseStylesIcon: {
    fontSize: 16,
    color: theme.palette.text.secondary,
  },
  pockbaseStylesLabel: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginLeft: 8,
    fontWeight: 600,
  },
  inputDeviceWrapper: {
    '& .titleInputDevice': {
      fontSize: 20,
      fontWeight: 500,
    }
  },
  customMenuAdd: {
    zIndex: theme.zIndex.modal - 1,
    transform: 'translate(196px, -248px) !important',
    '& .MuiPaper-root': {
      width: 'calc(508px - 16px - 16px) !important',
    },
    '& .MuiMenu-list': {
      padding: 0,
    },
    '@media only screen and (max-height: 820px)': {
      transform: 'translate(36px, -200px) !important',
      '& .MuiPaper-root': {
        width: 'calc(436px - 16px - 16px) !important',
        maxWidth: '436px !important'
      }
    }
  },
  dateRangeAndTimePickerDialog: {
    '& .MuiDialog-paper': {
      maxWidth: 'unset',
    },
    '& .MuiPaper-root': {
      borderRadius: 0,
    },
  },
  btnDispatch: {
    fontWeight: 700,
    color: theme.palette.text.secondary,
    fontSize: 14,
    padding: '4px 16px',
    width: 'fit-content'
  },
}))

export default useStyles