// RAMDA
import { isEmpty } from 'ramda'

export const updateMapBoundsAndZoom = (
  inputMap,
  inputSetBounds,
  inputSetZoom,
) => {
  if (!isEmpty(inputMap)) {
    const b = inputMap.getBounds()
  
    inputSetBounds([
      b.getSouthWest().lng,
      b.getSouthWest().lat,
      b.getNorthEast().lng,
      b.getNorthEast().lat
    ])
  
    inputSetZoom(inputMap.getZoom())
  }
}

export const splitZoneArea = (zone) => {
  // 1 = lat, 2 = long, 3 = radius
  return zone.replace(/[A-Z]*\(*\)*\,*/ig, '').split(' ')
}