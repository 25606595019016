import { stringify } from 'query-string'

// UTILITIES
import { http } from 'utilities/http'

export const getStateHashMap = async () => {
  try {
    const { data } = await http.get('/tracker/get_states?version=updated')
    return data
  }
  catch (error) {
    return {
      status: 'error', 
      rows: {},
    }
  }
}

export const getTrackerList = async (params = {}) => {
  try {
    const { data } = await http.get(`/tracker/list?${stringify(params)}`)
    return data
  }
  catch (error) {
    return {
      status: 'error', 
      rows: [],
    }
  }
}

export const getAddressByLatLong = async (params, signal) => {
  try {
    if (signal) {
      const { data } = await http.get(`/geocoder/search_location?${stringify(params)}`)
      return data
    } else {
      const { data } = await http.get(`/geocoder/search_location?${stringify(params)}`, {
        signal: signal
      })
      return data
    }
  }
  catch (error) {
    return {
      status: 'error', 
      rows: [],
    }
  }
}

export const getGeoByAddress = async (params) => {
  try {
    const { data } = await http.get(`/geocoder/search_address?${stringify(params)}`)
    return data
  }
  catch (error) {
    return {
      status: 'error', 
      rows: [],
    }
  }
}

export const postTrackerHistory = async (bodyParams) => {
  try {
    const { data } = await http.post('/track/read', bodyParams)
    return data
  }
  catch (error) {
    return {
      status: 'error', 
      rows: [],
    }
  }
}