import { useEffect, useState } from 'react'

// COMPONENTS
import CarouselMedias from 'components/CarouselMedias/CarouselMedias'

const CarouselMediaWrapper = (props) => {
  const { evidenceObject, setSelectedEvidenceItem } = props

  const [ activeIndex, setActiveIndex ] = useState(0)

  useEffect(() => {
    setActiveIndex(0)
  }, [evidenceObject])

  return (
    <CarouselMedias
      evidenceObject={evidenceObject}
      onItemClick={setSelectedEvidenceItem}
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
    />
  )
}

export default CarouselMediaWrapper