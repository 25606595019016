import { useContext, useEffect, useState } from 'react'
import CallKit from 'chat-callkit'

// COMPONENTS
import ConversationList from './ConversationList/ConversationList'
import ChatRoom from './ChatRoom/ChatRoom'
import DialogDiscussionGroup from './DialogDiscussionGroup/DialogDiscussionGroup'
import DialogNewChat from './DialogNewChat/DialogNewChat'
import DialogDetailGroup from './DialogDetailGroup/DialogDetailGroup'

// CONSTANTS
import { createMenuList } from './chatConstants'

// CONTEXTS
import { PageChatContext } from 'contexts/PageChatContext'

// MUIS
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

// SERVICES
import { getAgoraChatRTCToken } from 'services/ChatService'

// STYLES
import useStyles from './chatUseStyles'

// UTILS
import { clearCurrentConversation } from './chatUtils'

const Chat = (props) => {
  const { buttonAddEl, setButtonAddEl } = props
  const classes = useStyles()

  // CONTEXTS
  const { currentConversation, isLoading, conn, startCall, setStartCall } = useContext(PageChatContext)

  // STATES
  const [isDialogNewChatShown, setIsDialogNewChatShown] = useState(false)
  const [isDialogDiscussionGroupShown, setIsDialogDiscussionGroupShown] = useState(false)

  const handleInvite = async (data) => {
    const rtcToken = await getAgoraChatRTCToken(data.channel)
    CallKit.answerCall(true, rtcToken?.token)
  }

  const handleHangupCall = (info) => {
    setStartCall({})
  }

  const handleRefuceCall = (info) => {
    setStartCall({})
  }

  const handleCallStateChange = (info) => {
    switch (info.type) {
    case 'hangup':
      handleHangupCall(info)
      break
    case 'accept':
      // The callee accepts the call invitation.
      break
    case 'refuse':
      handleRefuceCall(info)
      // The callee refuses the call invitation.
      break
    case 'user-published':
      // A remote user publishes media streams during the call.
      break
    case 'user-unpublished':
      // A remote user stops publishing media streams during the call.
      break
    case 'user-left':
      // A remote user leaves the call.
      break
    default:
      break
    }
  }

  useEffect(() => {
    clearCurrentConversation()
    setStartCall({})
  }, [])

  useEffect(() => {
    if (conn?.agoraUid) {
      CallKit.init(
        process.env.REACT_APP_AGORA_APP_ID,
        conn?.agoraUid,
        conn
      )
    }
  }, [conn])

  useEffect(() => {
    if (Object.keys(startCall)?.length) {
      CallKit.startCall(startCall)
    }
  }, [startCall])

  return (
    <>
      {/* MENU CREATE */}
      <Menu
        anchorEl={buttonAddEl}
        open={Boolean(buttonAddEl)}
        onClose={() => setButtonAddEl(null)}
        MenuListProps={{
          className: classes.menuListCreate
        }}
      >
        {createMenuList(
          () => setIsDialogNewChatShown(true),
          () => setIsDialogDiscussionGroupShown(true)
        ).map((item, index) => (
          <MenuItem key={index} className={classes.menuItemCreate} onClick={() => {
            item.onClick()
            setButtonAddEl(null)
          }}>
            <ListItemIcon>
              <item.icon />
            </ListItemIcon>

            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>

      <Grid container spacing={0} height='100%'>
        {/* CONVERSATION LIST */}
        <Grid item xs={3} className={classes.gridConversationList}>
          <ConversationList />
        </Grid>

        {/* CHAT ROOM */}
        <Grid item xs={9} className={classes.gridChatRoom}>
          {currentConversation?.channel_id && <ChatRoom />}
        </Grid>
      </Grid>

      {/* DIALOG NEW CHAT */}
      <DialogNewChat
        isDialogNewChatShown={isDialogNewChatShown}
        setIsDialogNewChatShown={setIsDialogNewChatShown}
      />

      {/* DIALOG DISCUSSION GROUP */}
      <DialogDiscussionGroup
        isDialogDiscussionGroupShown={isDialogDiscussionGroupShown}
        setIsDialogDiscussionGroupShown={setIsDialogDiscussionGroupShown}
      />

      {/* DIALOG DETAIL GROUP */}
      <DialogDetailGroup />

      <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress color='primary'/>
      </Backdrop>

      {/* CALL KIT */}
      <CallKit
        agoraUid={conn?.agoraUid}
        onStateChange={handleCallStateChange}
        onInvite={handleInvite}
      ></CallKit>
    </>
  )
}

export default Chat