import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: 24,
    right: 24,
    bottom: 24,
    zIndex: 2,
    pointerEvents: 'none'
  },
  panelEvidenceWrapper: {
    paddingLeft: 24,
    position: 'relative',
    transition: 'width 225ms',
    height: '100%',
  }
}))

export default useStyles