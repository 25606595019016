// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 24,
    position: 'relative',
  },
  contentRoot: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  groupingRow: {
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  columnStatus: {
    font: 'inherit',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    textTransform: 'capitalize',
    '&::before': {
      content: '""',
      width: 10,
      height: 10,
      backgroundColor: colors.boulder,
      borderRadius: '50%',
      marginRight: 10,
    },
  },
  columnStatusRed: {
    '&::before': {
      backgroundColor: colors.carnation,
    },
  },
  columnStatusGreen: {
    '&::before': {
      backgroundColor: colors.atlantis,
    },
  },
  columnStatusYellow: {
    '&::before': {
      backgroundColor: colors.amber,
    },
  },
  columnDispatchStatus: {
    fontSize: 'inherit',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  columnDispatchStatusRed: {
    color: colors.carnation,
    backgroundColor: alpha(colors.carnation, 0.1),
  },
  columnDispatchStatusRedIcon: {
    color: `${colors.carnation} !important`,
  },
  columnDispatchStatusGreen: {
    color: colors.atlantis,
    backgroundColor: alpha(colors.atlantis, 0.1),
  },
  columnDispatchStatusGreenIcon: {
    color: `${colors.atlantis} !important`,
  },
  normalText: {
    textTransform: 'capitalize',
  },
  columnFlexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  secondaryText: {
    color: theme.palette.text.secondary,
  },
  columnVehicle: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  columnVehicleIcon: {
    color: colors.boulder,
    marginRight: 10,
  },
  columnOfficerNames: {
    fontSize: 12,
    textTransform: 'capitalize',
    backgroundColor: colors.cornflowerBlue,
    color: 'white',
  },
  columnActions: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowStatus: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  tabContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
  },
  tabTopContent: {
    padding: '30px 24px 15px 24px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  tabTitle: {
    color: colors.black,
    fontSize: '22px',
    lineHeight: '29.96px',
    letterSpacing: '0.15 px',
    fontWeight: 600,
  },
  tabInformation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '20px',
  },
  tabSubtitle: {
    color: colors.black,
    maxWidth: '85%',
    opacity: 0.54,
    fontSize: '14px',
    lineHeight: '19.07px',
    letterSpacing: '0.15 px',
    fontWeight: 400,
  },
  btnAddNew: {
    height: '41px',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    fontSize: '14px',
    textTransform: 'capitalize',
    lineHeight: '19.07px',
    letterSpacing: '1%',
    fontWeight: 600,
  },
  permissionNames: {
    color: colors.black,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15 px',
  },
  switchPermissions: {
    color: colors.black,
    opacity: 0.54,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.15 px',
  },
  tabExpandInfo: {
    padding: '24px',
    paddingTop : 0
  },
}))

export default useStyles
