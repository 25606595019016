import React, { useState, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'

// COMPONENTS
import DialogAddOrEdit from 'components/DialogAddOrEditNew/DialogAddOrEdit'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import InputDeviceList from 'components/InputDeviceList/InputDeviceList'

// CUSTOM COMPONENTS
import CustomInput from 'components/Customs/CustomInput'
import CustomInputLabel from 'components/Customs/CustomInputLabel'
import CustomMenuItem from 'components/Customs/CustomMenuItem'

// MUIS
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Menu from '@mui/material/Menu'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconArrowDropDown from '@mui/icons-material/ArrowDropDown'
import IconSearch from '@mui/icons-material/Search'
import {
  AccountTree,
  Apartment,
  Badge,
  FilterFrames,
  Label,
  Public,
} from '@mui/icons-material'

// SERVICES
import { createRole, getRole, updateRole } from 'services/UserService'

// STORES
import { showAlert } from 'store/MainReducer'

// STYLES
import useStyles from '../DialogAddOrEditSettingsSecurityControl/dialogAddOrEditSettingsSecurityControlUseStyles'

// Timezone Data
import { Timezone } from '../TimeZone/TimeZone'

// UTILITIES
import { toast } from 'utilities'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

const DialogAddOrEditSettingsSecurityControlRole = (props) => {
  const {
    dialogType,
    dialogAddOrEditSettingsSecurityControlRole,
    setDialogAddOrEditSettingsSecurityControlRole,
    roles,
    pageRef,
    reloadData,
  } = props

  const { snackbarObject, setSnackbarObject } = useContext(AllPagesContext)

  const classes = useStyles()
  const dispatch = useDispatch()
  const timezones = Timezone
  const initialDialogParams = {
    direktorat: '',
    parentRoleNo: '',
    parentRoleName: '',
    polresDaerah: '',
    roleName: '',
    subject: '',
    timeZone: '',
  }
  
  const [informationParams, setInformationParams] =
        useState(initialDialogParams)
  const [selectedDeviceList, setSelectedDeviceList] = useState([])
  const [parentNameMenuAnchor, setParentNameMenuAnchor] = useState(null)
  const [searchParentName, setSearchParentName] = useState('')
  const [parentNameList, setParentNameList] = useState(roles)
  const [timeZoneMenuAnchor, setTimeZoneMenuAnchor] = useState(null)

  useEffect(() => {
    setParentNameList(roles)
    if (dialogAddOrEditSettingsSecurityControlRole?.id) {
      getRole(dialogAddOrEditSettingsSecurityControlRole?.id).then(
        (response) => {
          const parentRole = roles?.find(
            (therole) => therole.id === response?.parentRoleNo
          )

          let checkTimezone = () => {
            if (response?.timeZone === 'GMT+7')
              return 'Waktu Indonesia Barat'
            else if (response?.timeZone === 'GMT+8')
              return 'Waktu Indonesia Tengah'
            else if (response?.timeZone === 'GMT+9')
              return 'Waktu Indonesia Timur'
          }

          setInformationParams({
            id: response?.id,
            direktorat: response?.dirextorat,
            parentRoleNo: response?.parentRoleNo,
            parentRoleName: parentRole?.roleName ?? '',
            polresDaerah: response?.polresDaerah,
            roleName: response?.roleName,
            subject: response?.subject,
            timeZone: checkTimezone(),
          })
          setSelectedDeviceList(response?.trackers ?? [])
        }
      )
    }
  }, [dialogType, dialogAddOrEditSettingsSecurityControlRole])

  const [isLoading, setIsLoading] = useState(false)

  const [
    dialogSaveSettingsSecurityControl,
    setDialogSaveSettingsSecurityControl,
  ] = useState({})

  const handleInformationParamsChange = (event) => {
    setInformationParams({
      ...informationParams,
      [event.target.name]: event.target.value,
    })
  }

  const handleParentMenuItemClick = (item) => {
    setInformationParams({
      ...informationParams,
      parentRoleName: item.roleName,
      parentRoleNo: item.id,
    })
    setParentNameMenuAnchor(null)
  }

  const handleTimeZoneMenuItemClick = (item) => {
    if (item === 'Waktu Indonesia Bagian Tengah') {
      item = 'Waktu Indonesia Tengah'
    }
    setInformationParams({
      ...informationParams,
      timeZone: item,
    })
    setTimeZoneMenuAnchor(null)
  }

  const getTimeZomeCode = (inputTimezoneString) => {
    if (inputTimezoneString === 'Waktu Indonesia Barat') return 'GMT+7'
    else if (inputTimezoneString === 'Waktu Indonesia Tengah')
      return 'GMT+8'
    else if (inputTimezoneString === 'Waktu Indonesia Timur')
      return 'GMT+9'
  }

  useEffect(() => {
    const searchParents = roles?.filter((theRole) =>
      theRole.roleName?.toLowerCase()?.includes(searchParentName)
    )
    setParentNameList(searchParents)
  }, [searchParentName])

  useEffect(() => {
    if (!parentNameMenuAnchor) setSearchParentName('')
  }, [parentNameMenuAnchor])

  const closeDialog = () => {
    setInformationParams(initialDialogParams)
    setDialogAddOrEditSettingsSecurityControlRole(null)
    setDialogSaveSettingsSecurityControl({})
    // reloadData()
  }

  const handleSaveBtn = async () => {
    const bodyRequest = {
      dirextorat: informationParams.direktorat,
      parentRoleNo: informationParams.parentRoleNo,
      polresDaerah: informationParams.polresDaerah,
      roleName: informationParams.roleName,
      subject: informationParams.subject,
      timeZone: getTimeZomeCode(informationParams.timeZone),
      trackerIds: selectedDeviceList.map((item) => item?.id || item?.trackerId),
    }

    if (dialogType === 'add') {
      setIsLoading(true)

      const result = await createRole(bodyRequest)

      if (result.status === 201) {
        setIsLoading(false)
        dispatch(showAlert({ message: 'Sukses Membuat Role Baru' }))
        closeDialog()
        reloadData()
      }
    } else {
      setDialogSaveSettingsSecurityControl({
        ...bodyRequest,
        id: informationParams?.id,
      })
    }
  }

  const handleUpdateRoleButtonClick = async () => {
    const id = dialogSaveSettingsSecurityControl?.id
    let params = dialogSaveSettingsSecurityControl
    delete params.id

    setIsLoading(true)
    const result = await updateRole(id, params)

    if (result.status !== 'error') {
      setIsLoading(false)
      dispatch(showAlert({ message: 'Sukses Memperbarui Role' }))
      closeDialog()
      reloadData()
    } else {
      setIsLoading(false)
      toast({ title: result?.error, icon: 'error' }).then(() => {})
      setDialogSaveSettingsSecurityControl({})
    }
  }

  const changeTimeZoneOptionList = (item) => {
    if (item.text === 'Waktu Indonesia Barat')
      return 'Waktu Indonesia Barat'
    else if (item.text === 'Waktu Indonesia Bagian Tengah')
      return 'Waktu Indonesia Tengah'
    else if (item.text === 'Waktu Indonesia Timur')
      return 'Waktu Indonesia Timur'
  }

  const validateInformationParams = () => {
    if(informationParams.parentRoleName === '') setSnackbarObject({
      open: true,
      severity: 'error',
      title: '',
      message: 'Induk tidak boleh kosong',
    })

    else if(informationParams.timeZone === '') setSnackbarObject({
      open: true,
      severity: 'error',
      title: '',
      message: 'Zona waktu tidak boleh kosong',
    })

    else handleSaveBtn()
  }

  let selectedTitle = ''
  if (dialogType === 'add') selectedTitle = 'Tambah Role'
  else if (dialogType === 'edit') selectedTitle = 'Ubah Role'

  return (
    <>
      {/* DIALOG ADD OR EDIT */}
      <DialogAddOrEdit
        open={Boolean(dialogAddOrEditSettingsSecurityControlRole)}
        title={selectedTitle}
        onCloseIconClick={closeDialog}
        containerRef={pageRef}
        contents={[
          <>
            {/* MAIN SECTION TEXT */}
            <Typography variant='h6' className={classes.sectionText}>
              Informasi Utama
            </Typography>

            {/* ROLE NAME INPUT */}
            <Box className={classes.iconAndFormControlContainer}>
              <Label className={classes.iconFormControl} />
              <FormControl
                variant='standard'
                className={classes.formControl}
              >
                <CustomInputLabel>Nama Role</CustomInputLabel>
                <CustomInput
                  type='text'
                  name='roleName'
                  value={informationParams.roleName}
                  onChange={handleInformationParamsChange}
                />
              </FormControl>
            </Box>

            {/* PARENT SECTION */}
            <Box className={classes.iconAndFormControlContainer}>
              <AccountTree className={classes.iconFormControl} />
              {/* INPUT */}
              <FormControl
                variant='standard'
                className={classes.formControl}
              >
                <CustomInputLabel>Induk</CustomInputLabel>
                <CustomInput
                  readOnly
                  type='text'
                  name='parentRoleNo'
                  value={informationParams.parentRoleName}
                  onChange={handleInformationParamsChange}
                  endAdornment={
                    <IconArrowDropDown
                      className={`${classes.dropDownIcon} zoom`}
                    />
                  }
                  onClick={(event) =>
                    setParentNameMenuAnchor(event.currentTarget)
                  }
                  className={`${classes.inputInduk} no-zoom`}
                />
              </FormControl>

              {/* ROLES MENU */}
              <Menu
                anchorEl={parentNameMenuAnchor}
                open={Boolean(parentNameMenuAnchor)}
                onClose={() => setParentNameMenuAnchor(null)}
                className={`${classes.menu} no-zoom`}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Stack className='zoom'>
                  {/* SEARCH INPUT */}
                  <Box className={classes.menuSearch}>
                    <CustomInput
                      type='text'
                      placeholder='Search'
                      className={classes.menuSearchInput}
                      value={searchParentName}
                      onChange={(event) =>
                        setSearchParentName(event.target.value)
                      }
                      startAdornment={
                        <IconSearch
                          className={classes.menuSearchIcon}
                        />
                      }
                      onClick={(event) => event.stopPropagation()}
                    />
                  </Box>

                  {/* DISTURBANCE ITEM OPTION LIST */}
                  <Box className={classes.menuItemContainer}>
                    {parentNameList?.map((item, index) => (
                      <CustomMenuItem
                        key={index}
                        className={
                          informationParams.parentRoleName ===
                                            item.roleName
                            ? `${classes.menuItem} ${classes.menuItemSelected}`
                            : classes.menuItem
                        }
                        onClick={() =>
                          handleParentMenuItemClick(item)
                        }
                      >
                        {item.roleName}
                      </CustomMenuItem>
                    ))}
                  </Box>
                </Stack>
              </Menu>
            </Box>

            {/* POLRES DAERAH INPUT */}
            <Box className={classes.iconAndFormControlContainer}>
              <Apartment className={classes.iconFormControl} />
              <FormControl
                variant='standard'
                className={classes.formControl}
              >
                <CustomInputLabel>POLRES DAERAH</CustomInputLabel>
                <CustomInput
                  type='text'
                  name='polresDaerah'
                  value={informationParams.polresDaerah}
                  onChange={handleInformationParamsChange}
                />
              </FormControl>
            </Box>

            {/* DIREKTORAT DAERAH INPUT */}
            <Box className={classes.iconAndFormControlContainer}>
              <Badge className={classes.iconFormControl} />
              <FormControl
                variant='standard'
                className={classes.formControl}
              >
                <CustomInputLabel>DIREKTORAT</CustomInputLabel>
                <CustomInput
                  type='text'
                  name='direktorat'
                  value={informationParams.direktorat}
                  onChange={handleInformationParamsChange}
                />
              </FormControl>
            </Box>

            {/* SUBDIT DAERAH INPUT */}
            <Box className={classes.iconAndFormControlContainer}>
              <FilterFrames className={classes.iconFormControl} />
              <FormControl
                variant='standard'
                className={classes.formControl}
              >
                <CustomInputLabel>SUBDIT</CustomInputLabel>
                <CustomInput
                  type='text'
                  name='subject'
                  value={informationParams.subject}
                  onChange={handleInformationParamsChange}
                />
              </FormControl>
            </Box>

            {/* ZONA WAKTU SECTION */}
            <Box className={classes.iconAndFormControlContainer}>
              <Public className={classes.iconFormControl} />
              {/* INPUT */}
              <FormControl
                variant='standard'
                className={classes.formControl}
              >
                <CustomInputLabel>Zona Waktu</CustomInputLabel>
                <CustomInput
                  readOnly
                  type='text'
                  name='timeZone'
                  value={informationParams.timeZone}
                  onChange={handleInformationParamsChange}
                  endAdornment={
                    <IconArrowDropDown
                      className={classes.dropDownIcon}
                    />
                  }
                  onClick={(event) =>
                    setTimeZoneMenuAnchor(event.currentTarget)
                  }
                />
              </FormControl>

              {/* TIME ZONE MENU */}
              <Menu
                anchorEl={timeZoneMenuAnchor}
                open={Boolean(timeZoneMenuAnchor)}
                onClose={() => setTimeZoneMenuAnchor(null)}
                className={classes.menu}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                {/* TIMEZONE ITEM OPTION LIST */}
                <Box className={classes.menuItemContainer}>
                  {timezones?.map((item, index) => (
                    <CustomMenuItem
                      key={index}
                      className={
                        informationParams.timeZone === item.text
                          ? `${classes.menuItem} ${classes.menuItemSelected}`
                          : classes.menuItem
                      }
                      onClick={() =>
                        handleTimeZoneMenuItemClick(item.text)
                      }
                    >
                      {changeTimeZoneOptionList(item)}
                    </CustomMenuItem>
                  ))}
                </Box>
              </Menu>
            </Box>

            {/* BLANK SPACE */}
            <Box className={classes.blankSpace} />

            {/* DEVICE LIST INPUT */}
            <InputDeviceList
              title='Perangkat'
              buttonText='Tambah Perangkat'
              selectedDeviceList={selectedDeviceList}
              setSelectedDeviceList={setSelectedDeviceList}
              isWithDistance={false}
              type='trackerList'
            />
          </>
        ]}
        onCancelButtonClick={closeDialog}
        onSaveButtonClick={validateInformationParams}
      />

      {/* DIALOG SAVE SECURITY CONTROL ROLE */}
      <DialogConfirmation
        title='Konfirmasi'
        caption='Apa Anda yakin ingin mengubah role ini?'
        dialogConfirmationObject={dialogSaveSettingsSecurityControl}
        setDialogConfirmationObject={setDialogSaveSettingsSecurityControl}
        cancelButtonText='Batal'
        continueButtonText='Ubah'
        onContinueButtonClick={handleUpdateRoleButtonClick}
        onCancelButtonClick={() => setDialogSaveSettingsSecurityControl({})}
      />
    </>
  )
}

export default DialogAddOrEditSettingsSecurityControlRole
