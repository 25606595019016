// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  panelContainer: {
    backgroundColor: colors.blackSqueeze,
    height: '100%',
    boxShadow: `0px 4px 16px 0px ${alpha(theme.palette.common.black, 0.16)}`,
    position: 'relative',
    pointerEvents: 'auto',
    cursor: 'auto'
  },
  panelHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 48,
    padding: '0px 16px',
  },
  iconToggle: {
    width: 36,
    height: 36,
  },
  panelTitle: {
    marginLeft: 4,
    fontWeight: 600,
  },
  tabWrapper: {
    padding: '8px 16px'
  },
  rootTabs: {
    minHeight: 32,
  },
  rootTab: {
    padding: 8,
    minHeight: 32,
    color: theme.palette.text.secondary,
    fontSize: 12,
    borderRadius: 2
  },
  iconWrapperTab: {
    marginRight: '4px !important',
    fontSize: 16,
  },
  selectedTab: {
    backgroundColor: colors.mystic,
    color: `${theme.palette.common.black} !important`
  },
  indicatorTabs: {
    display: 'none'
  },
  inputSearch: {
    padding: '1px 0',
    '&::after': {
      borderBottom: `1px solid ${colors.astral} !important`,
    },
    '&::before': {
      borderBottom: `1px solid ${colors.astral} !important`
    },
    transition: 'width 0.25s ease-in-out, border-bottom-color 0.25s ease-in-out',
    '&.expanded': {
      width: '100%'
    },
    '&.notExpanded': {
      width: 38,
      '&::after': {
        borderBottomColor: 'transparent !important',
      },
      '&::before': {
        borderBottomColor: 'transparent !important'
      },
    }
  },
  loadingContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 2,
    backgroundColor: alpha('#FFFFFF', 0.7),
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  loading: {
    width: '48px !important',
    height: '48px !important',
  },
  dateRangeAndTimePickerDialog: {
    '& .MuiDialog-paper': {
      maxWidth: 'unset',
    },
  },
  activeBtnDateRange: {
    position: 'relative',
    '&::after': {
      position: 'absolute',
      content: '""',
      height: 8,
      width: 8,
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      bottom: 4,
      right: 4,
    }
  },
  filterDivider: {
    margin: '0 8px'
  },
  menuSettingMarker: {
    padding: 0,
    '& .MuiPaper-root': {
      boxShadow: `0 0 10px ${alpha(theme.palette.common.black, 0.16)}`,
      borderRadius: 2,
    },
    '& .MuiList-root': {
      padding: 4,
      maxWidth: 220
    },
    '& .MuiMenuItem-root': {
      padding: '4px 8px',
      height: 28,
      '&:hover': {
        backgroundColor: '#DAE7F2'
      }
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 12,
      marginLeft: 4,
      color: theme.palette.common.black,
    },
    '& .MuiCheckbox-root': {
      padding: '0 0 0 8px',
    }
  }
}))

export default useStyles