import { useState } from 'react'

// MUIS
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { alpha, styled } from '@mui/material/styles'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem'

// MUI ICONS
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

const StyledTreeItem = styled(({ nodeId, onDelete, onEdit, ...props }) => (
  <TreeItem
    {...props}
    label={
      <div className={'tree-label'}>
        {props.label}
        {nodeId !== 'root' && (
          <span className={'ml-2 tree-action'}>
            <EditIcon
              onClick={onEdit}
              sx={{
                fontSize: 18,
                color: '#000',
                opacity: 0.54,
                marginRight: '4px',
              }}
            />
            <DeleteIcon
              onClick={onDelete}
              sx={{
                fontSize: 18,
                color: '#000',
                opacity: 0.54,
              }}
            />
          </span>
        )}
      </div>
    }
    sx={{
      '& .MuiTreeItem-label': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: 40,
      },
    }}
  >
    {props.children}
  </TreeItem>
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,

    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}))

export default function CustomizedTreeView({
  hierarchyRoles,
  roles,
  reloadTree,
  setDialogType,
  setDialogDeleteSettingsSecurityControl,
  setDialogAddOrEditSettingsSecurityControl,
  setDeleteType,
}) {
  const [expanded, setExpanded] = useState([])

  const handleExpandClick = () => {
    const ids = roles?.map((item) => item.id + '')
    ids.push('root')
    setExpanded((oldExpanded) => (oldExpanded.length === 0 ? ids : []))
  }
  const handleCollapseClick = () => {
    setExpanded((oldExpanded) => [])
  }

  const handleEdit = (id) => {
    setDialogAddOrEditSettingsSecurityControl({ id })
    setDialogType('edit')
  }

  const handleDelete = (id) => {
    setDialogDeleteSettingsSecurityControl(id)
    setDeleteType('Role')
  }

  const renderTree = (nodes) => (
    <StyledTreeItem
      key={nodes?.roleId}
      itemId={nodes?.roleId?.toString()}
      nodeId={nodes?.roleId?.toString()}
      label={nodes?.roleName}
      onEdit={() => handleEdit(nodes?.roleId)}
      onDelete={() => handleDelete(nodes?.roleId)}
    >
      {Array.isArray(nodes?.childRoles)
        ? nodes?.childRoles?.map((node) => {
          return renderTree(node)
        })
        : null}
    </StyledTreeItem>
  )

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginTop: '10px',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <Button
          className={'mr-2'}
          sx={{ textTransform: 'capitalize', marginRight: '10px' }}
          onClick={handleExpandClick}
        >
          Expand All
        </Button>
        <Button
          className={'mr-2'}
          sx={{ textTransform: 'capitalize', marginRight: '10px' }}
          onClick={handleCollapseClick}
        >
          Collapse All
        </Button>
      </Box>

      <SimpleTreeView
        aria-label='customized'
        defaultExpandedItems={['root']}
        expandedItems={expanded}
        sx={{ flexGrow: 1, overflowY: 'auto' }}
        onExpandedItemsChange={(event, itemIds) => setExpanded(itemIds)}
      >
        {renderTree(hierarchyRoles)}
      </SimpleTreeView>
    </Box>
  )
}
