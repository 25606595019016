// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconMapPin = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M11.9999 2.3999C8.9071 2.3999 6.3999 4.9071 6.3999 7.9999C6.3999 10.8201 8.48739 13.1465 11.1999 13.5358V20.7999C11.1984 20.9059 11.218 21.0112 11.2575 21.1095C11.297 21.2079 11.3557 21.2974 11.4302 21.3729C11.5046 21.4484 11.5933 21.5084 11.6911 21.5493C11.7889 21.5902 11.8939 21.6113 11.9999 21.6113C12.1059 21.6113 12.2109 21.5902 12.3087 21.5493C12.4065 21.5084 12.4952 21.4484 12.5696 21.3729C12.6441 21.2974 12.7028 21.2079 12.7423 21.1095C12.7818 21.0112 12.8014 20.9059 12.7999 20.7999V13.5358C15.5124 13.1465 17.5999 10.8201 17.5999 7.9999C17.5999 4.9071 15.0927 2.3999 11.9999 2.3999ZM11.9999 3.9999C12.9943 3.9999 13.7999 4.5375 13.7999 5.1999C13.7999 5.8623 12.9943 6.3999 11.9999 6.3999C11.0055 6.3999 10.1999 5.8623 10.1999 5.1999C10.1999 4.5375 11.0055 3.9999 11.9999 3.9999Z' />
    </SvgIcon>
  )
}

export default IconMapPin