import React from 'react'
import PropTypes from 'prop-types'

// CONSTANTS
import { values } from 'constants/values'

// MUIS
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'

// MUI ICONS
import IconAdd from '@mui/icons-material/Add'
import IconRemove from '@mui/icons-material/Remove'

// STYLES
import useStyles from './panelZoomUseStyles'

const PanelZoom = (props) => {
  const { 
    setZoom, 
    theme,
    className,
    direction,
    ...otherProps
  } = props

  const classes = useStyles()

  let rootClassName = classes.root
  if (theme === 'LIGHT') rootClassName = `${rootClassName} ${classes.rootLightTheme}`
  else if (theme === 'DARK') rootClassName = `${rootClassName} ${classes.rootDarkTheme}`
  rootClassName = `${rootClassName} ${className}`

  let buttonGroupClassName = classes.buttonGroupItem
  if (theme === 'LIGHT') buttonGroupClassName = `${buttonGroupClassName} ${classes.buttonGroupItemLightTheme}` 
  else if (theme === 'DARK') buttonGroupClassName = `${buttonGroupClassName} ${classes.buttonGroupItemDarkTheme}`

  let iconClassName = classes.icon
  if (theme === 'LIGHT') iconClassName = `${iconClassName} ${classes.iconLightTheme}`
  else if (theme === 'DARK') iconClassName = `${iconClassName} ${classes.iconDarkLight}`

  const buttonList = [
    {
      icon: IconAdd,
      action: 'zoom in',
    },
    {
      icon: IconRemove,
      action: 'zoom out',
    },
  ]

  const handleButtonClick = (inputAction) => {
    setZoom(current => {
      if (inputAction === 'zoom in' && current < values.maxMapZoom) return current + 1
      else if (inputAction === 'zoom out' && current > values.minMapZoom) return current - 1
      else return current
    })
  }
  
  return (
    <ButtonGroup 
      className={rootClassName}
      variant='contained'
      orientation={direction}
      {...otherProps}
    >
      {buttonList.map((item, index) => (
        <Button 
          key={index}
          className={buttonGroupClassName}
          variant='text'
          onClick={() => handleButtonClick(item.action)}
        >
          <item.icon className={iconClassName}/>
        </Button>
      ))}
    </ButtonGroup>
  )
}

PanelZoom.defaultProps = {
  theme: 'LIGHT',
  className: '',
  direction: 'vertical'
}

PanelZoom.propTypes = {
  setZoom: PropTypes.func.isRequired, 
  theme: PropTypes.oneOf([ 'LIGHT', 'DARK' ]),
  className: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['vertical', 'horizontal'])
}

export default PanelZoom