import React from 'react'
import PropTypes from 'prop-types'

// MUIS
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

// STYLES
import useStyles from './loadingBoxUseStyles'

const LoadingBox = (props) => {
  const { 
    isLoading, 
    children,
    isYOverFlow,
  } = props

  const classes = useStyles()

  return (
    <Box className={classes.contentRoot} 
      sx={{overflowY : isYOverFlow ? 'auto' : 'unset'}}
    >
      {/* LOADING */}
      {isLoading &&
      <Box className={classes.loadingContainer}>
        <CircularProgress className={classes.loading}/>
      </Box>}

      {/* CHILDREN */}
      {children}
    </Box>
  )
}

LoadingBox.defaultProps = {
  isLoading: false,
  children: null,
  isYOverFlow : false
}

LoadingBox.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  isYOverFlow : PropTypes.bool.isRequired,
}

export default LoadingBox