// MUIS
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

// MUI ICONS
import IconAdjust from '@mui/icons-material/Adjust'
import IconAssignmentInd from '@mui/icons-material/AssignmentInd'
import IconEventAvailable from '@mui/icons-material/EventAvailable'
import IconPowerSettingsNew from '@mui/icons-material/PowerSettingsNew'
import IconSecurityUpdate from '@mui/icons-material/SecurityUpdate'
import IconSmartphone from '@mui/icons-material/Smartphone'

// PATH ICONS
import IconKey from 'assets/images/pathIcons/IconKey'

// STYLES
import useStyles from './flyoutDeviceUseStyles'

// UTILITIES
import { capitalizeEachWord } from 'utilities'

const TabInformation = (props) => {
  const { selectedDevice } = props

  const classes = useStyles()

  const informationList = selectedDevice ? [
    {
      icon: IconKey,
      label: 'ID Pengguna',
      value: selectedDevice?.userId,
    },
    {
      icon: IconAssignmentInd,
      label: 'Label',
      value: selectedDevice?.label,
    },
    {
      icon: IconPowerSettingsNew,
      label: 'Kondisi',
      value: selectedDevice?.condition,
    },
    {
      icon: IconAdjust,
      label: 'Status',
      value: selectedDevice?.userOnlineStatus ? capitalizeEachWord(selectedDevice?.userOnlineStatus) : null,
    },
    {
      icon: IconEventAvailable,
      label: 'Terakhir Online',
      value: selectedDevice?.lastOnline,
    },
    {
      icon: IconSecurityUpdate,
      label: 'Versi Perangkat',
      value: selectedDevice?.deviceVersion,
    },
    {
      icon: IconSmartphone,
      label: 'Versi Aplikasi',
      value: selectedDevice?.aplicationVersion,
    },
  ] : []

  return (
    <List className={classes.content}>
      {informationList.length > 0 &&
      informationList.map((item, index) => (
        <ListItem
          key={index}
          disablePadding
          className={classes.informationListItem}
        >
          {/* ICON */}
          <ListItemIcon>
            <item.icon/>
          </ListItemIcon>

          {/* TEXT */}
          <ListItemText
            primary={item.label}
            secondary={item.value ? item.value : '[Tidak ada data]'}
          />
        </ListItem>
      ))}
    </List>
  )
}

export default TabInformation