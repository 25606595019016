import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

// COMPONENTS
import CustomFormControl from 'components/Customs/CustomFormControl'
import CustomInputNew from 'components/Customs/CustomInputNew'
import CustomInputLabelNew from 'components/Customs/CustomInputLabelNew'
import DialogAddOrEdit from 'components/DialogAddOrEditNew/DialogAddOrEdit'
import DateRangeAndTimePicker from 'components/DateRangeAndTimePicker/DateRangeAndTimePicker'
import InputLocationNew from 'components/InputLocation/InputLocationNew'
import InputDeviceList from 'components/InputDeviceList/InputDeviceList'
import SimpleBackdrop from 'components/Partials/SimpleBackdrop'

// CONSTANTS
import { basePaths } from 'constants/paths'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'

// LIBRARY
import { Editor } from '@tinymce/tinymce-react'

// MUIS
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'

// MUI ICON
import IconAccessTime from '@mui/icons-material/AccessTime'
import IconCalendarToday from '@mui/icons-material/CalendarToday'
import IconDateRange from '@mui/icons-material/DateRange'
import IconPlace from '@mui/icons-material/Place'
import IconTextFields from '@mui/icons-material/TextFields'
import IconTimelapse from '@mui/icons-material/Timelapse'
import IconWysiwyg from '@mui/icons-material/Wysiwyg'

// MOMENT
import moment from 'moment'

// MUI ICONS
import IconSubject from '@mui/icons-material/Subject'


// SERVICES
import { getDevice } from 'services/DeviceService'
import { postDispatchCreate, putDispatchUpdate } from 'services/DispatchService'

// STYLES
import useStyles from './dialogAddOrEditDispatchEvidencesUseStyles'
  
const DialogAddOrEditDispatchEvidences = (props) => {
  const {
    dialogAddOrEditDispatch,
    pageRef,
    reloadData,
  } = props

  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const editorRef = useRef(null)
  const { isDialogAddOrEditExpanded } = useContext(PrivateLayoutContext)
  const { setSnackbarObject } = useContext(AllPagesContext)

  const defaultParamsObject = {
    address: {
      //city: '',
      //country: '',
      fullAddress: '',
      latitude: 0,
      longitude: 0,
      //province: '',
      //subDistrict: '',
      //village: ''
    },
    category: 'NORMAL',
    description: '',
    //deviceCode: '',
    deviceNos: [],
    dispatchType: 'EVIDENCE',
    endTime: moment().endOf('day').toISOString(),
    label: '',
    startTime: moment().startOf('day').toISOString()
  }

  // STATES
  const [isLoading, setIsLoading] = useState(false)
  const [refreshRender, setRefreshRender] = useState(1)
  const [paramsObject, setParamsObject] = useState(defaultParamsObject)
  const [circle, setCircle] = useState(null)
  const [circleRadius, setCircleRadius] = useState(50)
  const [selectedDeviceList, setSelectedDeviceList] = useState([])
  const [isDateTimeRangeOpen, setIsDateTimeRangeOpen] = useState(false)
  const [dispatchLocation, setDispatchLocation] = useState('')

  const closeDialog = () => {
    setParamsObject(defaultParamsObject)
    setCircle(null)
    setSelectedDeviceList([])
    setDispatchLocation('')
    setIsLoading(false)
    history.push(basePaths.dispatchEvidences)
  }

  const handleParamsChange = (key, value) => {
    return setParamsObject({
      ...paramsObject,
      [key]: value
    })
  }

  const handleSelectDateRangeButtonClick = (inputNewDateRange) => {
    setParamsObject({
      ...paramsObject,
      startTime: inputNewDateRange[0],
      endTime: inputNewDateRange[1]
    })
    setIsDateTimeRangeOpen(false)
  }

  const handleButtonSaveClick = async () => {
    let tempParamsObject = paramsObject
    tempParamsObject.startTime = moment(paramsObject.startTime).toISOString()
    tempParamsObject.endTime = moment(paramsObject.endTime).toISOString()
    tempParamsObject.address.fullAddress = dispatchLocation
    tempParamsObject.deviceNos = selectedDeviceList.map(item => item.deviceNo)

    // VALIDATION ALL FIELD MUST FILLED
    let isFoundEmpty = false
    Object.keys(tempParamsObject).forEach(keyParent => {
      if(keyParent === 'address') {
        Object.keys(tempParamsObject[keyParent]).forEach(keyAddress => {
          if(!tempParamsObject[keyParent][keyAddress]) {
            isFoundEmpty = true
          }
        })
      } else if(keyParent === 'deviceNos') {
        if(!tempParamsObject[keyParent].length) {
          isFoundEmpty = true
        }
      } else {
        if(!tempParamsObject[keyParent]) {
          isFoundEmpty = true
        }
      }
    })

    if(isFoundEmpty) {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Semua Field Harus Terisi',
      })
      return
    }

    if(dialogAddOrEditDispatch?.mode === 'add') {
      const response = await postDispatchCreate(tempParamsObject)

      if(response?.status === 'error') {
        setSnackbarObject({
          open: true,
          severity: 'error',
          title: '',
          message: 'Gagal Membuat Penugasan Kejadian',
        })
      } else {
        setSnackbarObject({
          open: true,
          severity: 'success',
          title: '',
          message: 'Berhasil Membuat Penugasan Kejadian',
        })
      }
    } else if( dialogAddOrEditDispatch?.mode === 'edit') {
      if(paramsObject.status === 'ASSIGNED') {
        const response = await putDispatchUpdate(tempParamsObject.dispatchNo, tempParamsObject)

        if(response?.status === 'error') {
          setSnackbarObject({
            open: true,
            severity: 'error',
            title: '',
            message: 'Gagal Mengubah Penugasan Kejadian',
          })
        } else {
          setSnackbarObject({
            open: true,
            severity: 'success',
            title: '',
            message: 'Berhasil Mengubah Penugasan Kejadian',
          })
        }
      } else {
        setSnackbarObject({
          open: true,
          severity: 'error',
          title: '',
          message: 'Hanya status ditugaskan yang bisa diubah',
        })
      }
      
    }

    reloadData()
    closeDialog()
  }

  const handleInitEdit = async () => {
    if(dialogAddOrEditDispatch?.mode === 'edit') {
      let itemDetail = dialogAddOrEditDispatch?.data
      const deviceDetail = await getDevice(itemDetail?.deviceNo)
      itemDetail.deviceNos = [deviceDetail]

      setSelectedDeviceList([deviceDetail])
      setParamsObject(itemDetail)
      setDispatchLocation(itemDetail?.address?.fullAddress)
    } else {
      setParamsObject(defaultParamsObject)
    }
  }

  useEffect(() => {
    handleInitEdit()

    if(location?.search && dialogAddOrEditDispatch?.mode === 'add') {
      const values = queryString.parse(location?.search)

      setParamsObject({
        ...paramsObject,
        address: {
          fullAddress: values?.address,
          latitude: parseFloat(values?.lat),
          longitude: parseFloat(values?.lng),
        }
      })

      setDispatchLocation(values?.address)
    }
  }, [dialogAddOrEditDispatch])

  useEffect(() => {
    if(isDialogAddOrEditExpanded) setRefreshRender(current => current + 1)
  }, [isDialogAddOrEditExpanded])

  return (
    <>
      {/* DIALOG ADD OR EDIT */}
      <DialogAddOrEdit
        open={Boolean(dialogAddOrEditDispatch)}
        title='Penugasan'
        isExpandButtonAvailable={true}
        onCloseIconClick={() => closeDialog()}
        containerRef={pageRef}
        className=''
        contents={[
          <Stack>
            <Typography className={classes.dialogHeaderTitle} variant='h6'>Informasi Tugas</Typography>

            {/* NAME PENUGASAN */}
            <CustomFormControl variant='filled' className={classes.formControl}>
              <CustomInputLabelNew shrink={true}>
                <IconTextFields />
                <Typography variant='subtitle1'>Nama Penugasan</Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Masukkan Nama Penugasan'
                onChange={event => handleParamsChange('label', event.target.value)}
                value={paramsObject.label}
              />
            </CustomFormControl>

            {/* LOCATION INPUT */}
            <CustomFormControl variant='filled' className={classes.formControl}>
              <CustomInputLabelNew shrink={true}>
                <IconPlace />
                <Typography variant='subtitle1'>Lokasi</Typography>
              </CustomInputLabelNew>

              <InputLocationNew
                location={dispatchLocation}
                setLocation={setDispatchLocation}
                circle={circle}
                setCircle={setCircle}
                radius={circleRadius}
                setRadius={setCircleRadius}
                customPlaceholder='Masukkan Lokasi Penugasan'
                getLatLng={latLng => setParamsObject({
                  ...paramsObject,
                  address: {
                    ...paramsObject.address,
                    latitude: latLng.lat,
                    longitude: latLng.lng,
                  }
                })}
                latitudeProp={paramsObject?.address?.latitude}
                longitudeProp={paramsObject?.address?.longitude}
                suggestContainerWidth={isDialogAddOrEditExpanded ? '396px' : ''}
                radiusMenuWidth={isDialogAddOrEditExpanded ? '184px' : ''}
              />
            </CustomFormControl>

            {/* PERIODE TANGGAL */}
            <CustomFormControl variant='filled' className={`${classes.formControl} formControlDatePicker`} onClick={() => setIsDateTimeRangeOpen(true)}>
              <CustomInputLabelNew shrink={true}>
                <IconDateRange />
                <Typography variant='subtitle1'>Periode Tanggal</Typography>
              </CustomInputLabelNew>

              <Stack className={classes.dateTimePickerWrapper} direction='row'>
                <Stack className={classes.boxPreviewDatePicker}>
                  <Stack direction='row' flexWrap='nowrap' alignItems='center' paddingRight='26px'>
                    <IconCalendarToday className={classes.detailDateIcon}/>

                    <Box>
                      <Typography className={classes.detailDateTitle}>
                        {moment(paramsObject?.endTime).format('DD MMMM YYYY')}
                      </Typography>
                    </Box>
                  </Stack>

                  <Stack direction='row' flexWrap='nowrap' alignItems='center' paddingRight='26px'>
                    <IconAccessTime className={classes.detailDateIcon}/>

                    <Box>
                      <Typography className={classes.detailDateTitle}>
                        {moment(paramsObject?.endTime).format('HH:mm')}
                      </Typography>
                    </Box>
                  </Stack>

                  <Stack direction='row' flexWrap='nowrap' alignItems='center' paddingRight='26px'>
                    <IconTimelapse className={classes.detailDateIcon}/>

                    <Box>
                      <Typography className={classes.detailDateTitle}>
                        {moment(paramsObject.endTime).diff(paramsObject?.startTime, 'days') + 1} hari
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
            </CustomFormControl>
          </Stack>,
          <Stack width='100%'>
            <Typography className={classes.dialogHeaderTitle} variant='h6'>Detail Tugas</Typography>

            <CustomFormControl variant='filled' className={classes.formControl}>
              <CustomInputLabelNew shrink={true}>
                <IconSubject />
                <Typography variant='subtitle1'>Deskripsi</Typography>
              </CustomInputLabelNew>

              <Stack className={classes.editorWrapper}>
                <Editor
                  key={refreshRender}
                  tinymceScriptSrc={process.env.REACT_APP_DEPLOYED_URL + '/tinymce/tinymce.min.js'}
                  onInit={(evt, editor) => editorRef.current = editor}
                  value={paramsObject.description}
                  init={{
                    height: 412,
                    menubar: false,
                    branding: false,
                    statusbar: true,
                    toolbar_mode: 'scrolling',
                    placeholder: 'Type description here...',
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                      'fullscreen'
                    ],
                    fullscreen_native: true,
                    toolbar: 'undo redo | blocks | alignleft aligncenter alignright |' +
                        'bold italic underline backcolor | fullscreen',
                    content_style: 'body {font-family:Helvetica,Arial,sans-serif; font-size:14px;margin: 0 12px;} .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before { color: #A6A6A6; }',
                  }}
                  onEditorChange={(value, editor) => handleParamsChange('description', value)}
                />
              </Stack>
            </CustomFormControl>

            {/* PRIORITAS TUGAS */}
            <Stack className={`${classes.pockbaseStyles} ${classes.formControl}`} direction='row' flexWrap='nowrap' alignItems='center'>
              <Stack direction='row' flexWrap='nowrap' flex={1} alignItems='center'>
                <IconWysiwyg className={classes.pockbaseStylesIcon}/>
                <Typography className={classes.pockbaseStylesLabel}>Prioritas Tugas</Typography>
              </Stack>

              {/* TOGGLE BUTTON */}
              <Switch
                onChange={event => handleParamsChange('category', event.target.checked ? 'PRIORITY' : 'NORMAL')}
                checked={paramsObject.category === 'PRIORITY' ? true : false}
              />
            </Stack>

            <Stack className={classes.inputDeviceWrapper}>
              {/* DEVICE LIST INPUT */}
              <InputDeviceList
                title='Perangkat'
                buttonText='Pilih Perangkat'
                selectedDeviceList={selectedDeviceList}
                setSelectedDeviceList={setSelectedDeviceList}
                type='patrol'
                classNameMenuAdd={`${classes.customMenuAdd} ${isDialogAddOrEditExpanded && classes.customMenuAddFullWidth} neutralize-zoom-menu`}
                disabledInput={dialogAddOrEditDispatch?.mode === 'edit'}
              />
            </Stack>
          </Stack>
        ]}
        onCancelButtonClick={() => closeDialog()}
        onSaveButtonClick={() => handleButtonSaveClick()}
        disableButtonSave={(dialogAddOrEditDispatch?.data?.status !== 'ASSIGNED' && dialogAddOrEditDispatch?.mode === 'edit') ? true : false}
      />

      {/* LOADING */}
      <SimpleBackdrop isLoading={isLoading} />

      {/* DIALOG DATE RANGE PICKER */}
      <Dialog
        open={isDateTimeRangeOpen}
        onClose={() => setIsDateTimeRangeOpen(false)} 
        className={classes.dateRangeAndTimePickerDialog}
      >
        <DateRangeAndTimePicker
          value={[paramsObject.startTime, paramsObject.endTime]}
          handleSelectButtonClick={handleSelectDateRangeButtonClick}
          handleCancelButtonClick={() => setIsDateTimeRangeOpen(false)}
        />
      </Dialog>
    </>
  )
}

export default DialogAddOrEditDispatchEvidences