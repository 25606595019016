import { useState, useRef, useEffect, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// COMPONENTS
import LoadingBox from 'components/LoadingBox/LoadingBox'
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import PageHeader from 'components/PageHeader/PageHeader'
import DialogAddOrEditSosMessageTypes from './DialogAddOrEditSosMessageTypes/DialogAddOrEditSosMessageTypes'

// CONSTANTS
import { basePaths } from 'constants/paths'
import { iconListTagEvidences } from 'constants/icons'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconDelete from '@mui/icons-material/Delete'
import IconEdit from '@mui/icons-material/Edit'

// PATH ICONS
import IconSos from 'assets/images/pathIcons/IconSos'

// SERVICES
import { deleteSosMessageTypes, getSosMessageTypesList } from 'services/SettingsSosMessageTypesServices'

// STYLES
import useStyles from './settingsSosMessaggeTypesUseStyles'

const SettingsSosMessageTypes = () => {
  const classes = useStyles()
  const pageRef = useRef()
  const history = useHistory()
  const location = useLocation()

  const { setSnackbarObject } = useContext(AllPagesContext)

  const groupByList = [
    {
      title: 'Don\'t Group',
      value: null,
    },
  ]

  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('sosMessageTypeNo')
  const [size, setPageSize] = useState(100)
  const [sosMessageTableData, setSosMessageTableData] = useState([])
  const [filters, setFilters] = useState()
  const [selectedGroupBy, setSelectedGroupBy] = useState(groupByList[0])
  const [isFilterOn, setIsFilterOn] = useState(false)
  const [search, setSearch] = useState('')
  const [dialogDeleteSosMessageType, setDialogDeleteSosMessageType] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [selectionModel, setSelectionModel] = useState([])
  const [dialogAddEditSosMessageType, setDialogAddEditSosMessageType] = useState(null)

  const initialColumns = [
    {
      field: 'sosMessageTypeName',
      headerName: 'Jenis Pesan SOS',
      headerIcon: IconSos,
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: params => {
        const Icon = params.row.iconId ? iconListTagEvidences[Number(params.row.iconId)-1]?.Icon : null

        return (
          <Stack direction='row' alignItems='center'>
            {params.row.sosMessageTypeColor && (
              <Box
                className={classes.boxColorColumn}
                sx={{
                  backgroundColor: params.row.sosMessageTypeColor
                }}
              ></Box>
            )}

            {Icon && <Icon className={classes.boxIconColumn} />}

            <Typography className={classes.textActivityTypeName} variant='body2'>{params.value}</Typography>
          </Stack>
        )
      }
    },
    {
      field: 'actions',
      headerName: '',
      width: 120,
      hide: false,
      areFilterAndSortShown: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box className={classes.columnActions}>
          {/* EDIT ICON */}
          <CustomTooltipBlack title='Ubah' placement='bottom'>
            <IconButton onClick={event => handleEditIconButtonClick(event, params)}>
              <IconEdit />
            </IconButton>
          </CustomTooltipBlack>

          {/* DELETE ICON */}
          <CustomTooltipBlack title='Hapus' placement='bottom'>
            <IconButton
              onClick={(event) => handleDeleteIconButtonClick(event, params)}
            >
              <IconDelete />
            </IconButton>
          </CustomTooltipBlack>
        </Box>
      ),
    },
  ]

  const [selectedColumnList, setSelectedColumnList] = useState(initialColumns)

  const handleEditIconButtonClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()
    history.push(
      `${basePaths.settingsSosMessageType}?mode=edit&id=${inputParams.id}`, 
      {
        mode: 'edit',
        data: inputParams.row,
      }
    )
  }
 
  const handleDeleteIconButtonClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()
    setDialogDeleteSosMessageType(inputParams)
  }
 
  const handleColumnsMenuItemClick = (inputItem, inputIndex) => {
    let tempSelectedColumnList = [...selectedColumnList]
    if (selectedGroupBy.value && inputItem.field === 'sosMessageName') {
    } else {
      tempSelectedColumnList[inputIndex].hide =
       !tempSelectedColumnList[inputIndex].hide
    }
    setSelectedColumnList(tempSelectedColumnList)
  }

  const groupingColDef = {
    headerName: 'Jenis Pesan SOS',
    renderCell: (params) => {
      return (
        <Typography 
          variant='subtitle1' 
          className={classes.groupingRow}
          noWrap
        >
          {params.rowNode.groupingKey.toLowerCase()}
        </Typography>
      )
    },
  }

  const reloadData = async () => {
    setIsLoading(true)
    let params = {
      page,
      size,
    }

    if (order) params.sort = `${orderBy},${order}`
    if (search) params.query = search

    const response = await getSosMessageTypesList(params)

    setSosMessageTableData(response?.rows.map(item => ({
      ...item,
      id: item.sosMessageTypeNo
    })))
    setTotal(response?.totalElements ?? 0)
    setIsLoading(false)
  }

  const handleDeleteButtonClick = async () => {
    setIsLoading(true)
    const response = await deleteSosMessageTypes(dialogDeleteSosMessageType.row.id)

    if(response?.status !== 'error') {
      reloadData()
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Berhasil menghapus Tipe Pesan SOS',
      })
    } else {
      setIsLoading(false)
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Gagal menghapus Tipe Pesan SOS',
      })
    }

    setDialogDeleteSosMessageType({})
  }

  useEffect(() => {
    if (location.pathname === basePaths.settingsSosMessageType && (location.search.includes('?mode=edit') || location.search.includes('?mode=add'))) {
      setDialogAddEditSosMessageType(location?.state)
    }
    else setDialogAddEditSosMessageType(false)
  }, [location])

  useEffect(() => {
    reloadData()
  }, [page, size, order, orderBy, search])

  return (
    <Box className={classes.pageRoot} ref={pageRef}>
      {/* HEADER */}
      <PageHeader
        isAddButtonAvailable={true}
        onAddButtonIsClicked={() => history.push(`${basePaths.settingsSosMessageType}?mode=add`, {
          mode: 'add'
        })}
        title='Jenis Pesan SOS'
        search={search}
        setSearch={setSearch}
      />

      {/* CONTENT */}
      <LoadingBox isLoading={isLoading}>
        <DataGridFilters
          columns={initialColumns}
          selectedColumnList={selectedColumnList}
          handleColumnsMenuItemClick={handleColumnsMenuItemClick}
          isFilterOn={isFilterOn}
          setIsFilterOn={setIsFilterOn}
          groupByList={groupByList}
          selectedGroupBy={selectedGroupBy}
          setSelectedGroupBy={setSelectedGroupBy}
        />

        <DataGridTable
          initialColumns={initialColumns}
          rows={sosMessageTableData}
          total={total}
          page={page}
          pageSize={size}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setPage={setPage}
          setPageSize={setPageSize}
          setFilters={setFilters}
          isFilterOn={isFilterOn}
          selectedColumnList={selectedColumnList}
          setSelectedColumnList={setSelectedColumnList}
          selectedGroupBy={selectedGroupBy}
          getTreeDataPath={(row) => [row.sosMessageName, row.id]}
          getRowId={(row) => row.id}
          groupingColDef={groupingColDef}
          selectionModel={selectionModel} 
          setSelectionModel={setSelectionModel}
          isCheckboxPinned={true}
        />
      </LoadingBox>

      {/* DIALOG DELETE SOS MESSAGE TYPE */}
      <DialogConfirmation
        title='Hapus Jenis Pesan SOS'
        caption='Apa Anda yakin ingin menghapus jenis pesan SOS ini?'
        dialogConfirmationObject={dialogDeleteSosMessageType}
        setDialogConfirmationObject={setDialogDeleteSosMessageType}
        cancelButtonText='Batal'
        continueButtonText='Hapus'
        onContinueButtonClick={handleDeleteButtonClick}
        onCancelButtonClick={() => setDialogDeleteSosMessageType({})}
      />

      {/* DIALOG ADD OR EDIT SOS MESSAGE TYPE */}
      <DialogAddOrEditSosMessageTypes
        dialogAddEditSosMessageType={dialogAddEditSosMessageType}
        setDialogAddEditSosMessageType={setDialogAddEditSosMessageType}
        pageRef={pageRef}
        reloadData={reloadData}
      />
    </Box>
  )
}

export default SettingsSosMessageTypes