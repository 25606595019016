// MUIS
import { blueGrey } from '@mui/material/colors'
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      padding: 16,
      width: 450,
    },
    '& .MuiList-root': {
      padding: 0,
    },
  },
  inputLabel: {
    left: 0,
    marginLeft: -12,
    fontWeight: '600 !important',
    fontSize: 18,
  },
  input: {
    height: 36,
    position: 'relative',
    'label + &': {
      marginTop: 12,
    },
  },
  selectMenuPaper: {
    maxHeight: 400,
  },
  selectMenuItem: {
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: blueGrey[100],
    },
  },
  actionButton: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  actionButtonCancel: {
    marginLeft: 'auto',
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: alpha(theme.palette.text.secondary, 0.04),
    },
  },
}))

export default useStyles
