// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  dialogContainer : {
    '& .MuiDialog-paper' : {
      maxHeight : 'unset',
      maxWidth : 'unset'
    }
  },
  panelContainer: {
    backgroundColor: 'white',
    height: 670,
    width : 935,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.shadows[4],
    position: 'relative',
  },
  panelHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
    justifyContent : 'space-between',
    padding : '0px 24px'
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
  },
  panelTitle: {
    fontWeight: 600,
  },
  ruleSettingsBox: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
  settingTabs: {
    flex : 1,
  },
  ruleSettingTab: {
    '&.MuiTab-root': {
      height: 54,
      fontSize: 14,
      color: theme.palette.text.secondary,
    },
    '&.MuiTab-root.Mui-selected': {
      fontWeight: 600,
      color: theme.palette.text.primary,
    },
  },
  contentTabs: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 12
  },
  iconAndFormControlContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    height: 65,
    width: '100%',
    flex: '0 0 65px',
    padding: 12,
  },
  iconFormControl: {
    marginRight: 12,
    marginBottom: 2,
    color: colors.raven,
  },
  formControl: {
    width: '100%',
  },
  iconInfo: {
    color: '#4C9CEE'
  },
  geofenceRow: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    paddingLeft: 12,
    paddingRight: 12,
  },
  footer: {
    position: 'relative',
    bottom: 0,
    display: 'flex', 
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: 50,
    padding: '0 16px',
    borderTop: `1px solid ${alpha('#000000', 0.1)}`,
  },
  selectedInfo: {
    opacity: 0.54,
    fontWeight: 400
  },
  customSave: {
    marginRight : 8,
  },
  customCancel : {
    textTransform: 'uppercase',
    color : theme.palette.text.secondary,
  },
  stepperContainer : {
    height : 42,
    display : 'flex',
    borderBottom: `1px solid ${alpha('#000000', 0.1)}`,
  },
  stepper : {
    width : 336,
    marginLeft : 20
  },
  objectTypography : {
    padding : 24
  },
  objectTitle : {
    fontWeight : 600,
    fontSize :18,
    color: theme.palette.text.primary,
    marginBottom : 8,
  },
  objectValue : {
    fontWeight : 400,
    fontSize :13,
    color: theme.palette.text.secondary,
  },  
  autocomplete: {
    width: '100%',
    fontSize: 12,
    '& .MuiChip-root': {
      backgroundColor: alpha(colors.clementine, 0.1),
      color: colors.clementine,
      fontSize: 12,
      fontWeight: 600,
      textTransform: 'capitalize',
      lineHeight: '24px',
    },
    '& .MuiInputLabel-root': {
      fontSize: 14,
    },
    '& .MuiChip-deleteIcon': {
      color: 'unset',
      height: '20px',
      width: '20px',
      '&:hover': {
        color: 'unset',
      },
    },
  },
  chipCustomMenu: {
    maxWidth: '240px'
  },
}))

export default useStyles