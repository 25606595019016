// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  iconClose: {
    marginRight: 16,
    cursor: 'pointer',
  },
  titleText: {
    fontWeight: 600,
    fontSize: 24,
  },
  sectionText: {
    marginBottom: 8,
    fontWeight: 600,
  },
  patrolStatusContainer: {
    marginTop: 28,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  patrolStatusToggleButtonGroup: {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
  },
  patrolStatusToggleButton: {
    fontSize: 12,
    height: 32,
    fontWeight: 500,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      fontWeight: 600,
      border: 'none',
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.main,
      filter: 'brightness(0.96)',
    },
  },
  iconAndFormControlContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    height: 65,
    width: '100%',
    flex: '0 0 65px',
  },
  iconAndFormControlContainerEnd : {
    display: 'flex',
    alignItems: 'flex-end',
    height: 65,
    width: '100%',
    flex: '0 0 65px',
    marginBottom : 40
  },
  iconFormControl: {
    marginRight: 12,
    marginBottom: 2,
    color: colors.raven,
  },
  formControl: {
    width: '100%',
  },
  cancelButton: {
    color: colors.raven,
    '&:hover': {
      backgroundColor: alpha(colors.raven, 0.1),
    },
  },
}))

export default useStyles