import { useContext, useEffect, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server'

// CONSTANTS
import { colors } from 'constants/colors'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// MUIS
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// LEAFLET
import L from 'leaflet'
import '@geoman-io/leaflet-geoman-free'  
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'

// SERVICES
import { getAllPlacesListNoFilter } from 'services/PlacesService'

// STYLES
import useStyles from './geofencesListUseStyles'

// UTILS
import { splitZoneArea } from 'utilities/map'

const GeofencesList = () => {
  const { map, selectionModelPlacesList } = useContext(PageTrackingContext)
  const geoJsonGeofenceListRef = useRef()
  const geoJsonMarkerListRef = useRef()
  const classes = useStyles()

  // STATES
  const [dataPlaces, setDataPlaces] = useState([])
  
  const fetchDataPlaces = async () => {
    const response = await getAllPlacesListNoFilter()

    if(response?.length) {
      setDataPlaces(response)
    } else {
      setDataPlaces([])
    }
  }

  const calculateNextLatLngByMeter = (inputType, value, meter) => {
    const earth = 6378.137
    const m = (1 / ((2 * Math.PI / 360) * earth)) / 1000
    
    if(inputType === 'latitude') {
      return  value + (meter * m)
    } else if (inputType === 'longitude') {
      return value + (meter * m) / Math.cos(value * (Math.PI / 180))
    }
  }

  const drawGeofencesListTomap = () => {
    if (geoJsonGeofenceListRef.current) geoJsonGeofenceListRef.current.clearLayers()
    if (geoJsonMarkerListRef.current) geoJsonMarkerListRef.current.clearLayers()

    const featuresList = selectionModelPlacesList.map(selectionId => {
      const findItemPlaces = dataPlaces.find(item => item.place_no === selectionId)

      if (findItemPlaces?.zone?.area.includes('CIRCLE')) {
        const getLatLng = splitZoneArea(findItemPlaces?.zone?.area)

        return {
          type: 'Feature',
          properties: {...findItemPlaces, radius: getLatLng[3]},
          geometry: {
            type: 'Point',
            coordinates: [ getLatLng[2], getLatLng[1], 0 ],
          },
        }
      }
      else return null
    })

    const geoJsonData = {
      type: 'FeatureCollection',
      features: featuresList,
    }

    geoJsonGeofenceListRef.current = L.geoJson(geoJsonData, {
      pointToLayer: (feature, latlng) => {
        if (feature.properties.radius) return new L.Circle(latlng, Number(feature.properties.radius))
        else return new L.Marker(latlng)
      },
      style: (feature) => {
        return {
          fillColor: colors.california,
          color: colors.california,
          fillOpacity: 0.2,
          opacity: 0.2,
        }
      },
    })

    geoJsonMarkerListRef.current = L.geoJson(geoJsonData, {
      pointToLayer: (feature, latlng) => {
        return new L.Marker([
          calculateNextLatLngByMeter('latitude', latlng.lat, feature.properties.radius),
          latlng.lng
        ], {
          icon: L.divIcon({
            className: 'geofences-marker-popup',
            html: ReactDOMServer.renderToString(
              <Box className={classes.circleWrapper}></Box>
            ),
            iconSize: [16, 16]
          })
        })
          .bindPopup(
            ReactDOMServer.renderToString(
              <Box>
                <Typography
                  variant='body2'
                  className={classes.popupTitle}
                >
                  {feature.properties.name}
                </Typography>

                <Typography className={classes.popupDescription}>
                  {feature.properties.zone.address}
                </Typography>

                <Typography className={classes.popupDescription}>
                  Objek didalam geofence: 0
                </Typography>
              </Box>
            )
            , {
              minWidth: 264,
              maxWidth: 264,
              className: 'popup-wrapper-geofences' ,
              offset: [152, 40],
              closeButton: false,
              
            })
      },
    })

    geoJsonGeofenceListRef.current.addTo(map)
    geoJsonMarkerListRef.current.addTo(map)
  }

  useEffect(() => {
    fetchDataPlaces()
  }, [])

  useEffect(() => {
    drawGeofencesListTomap()
  }, [map, dataPlaces, selectionModelPlacesList])

  return null
}

export default GeofencesList