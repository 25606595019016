import React, { createContext, useCallback, useEffect, useRef, useState } from 'react'

// AGORA
import AC from 'agora-chat'

// SERVICES
import { getAgoraChatRTCToken, getAgoraChatToken, getConversationList, postFriendList } from 'services/ChatService'

// UTILS
import { clearCurrentConversation, convertEmailToAgoraId, getCurrentConversation, setCurrentConversation, splitChannel } from 'pages/Chat/chatUtils'
import { agoraSendMessage } from 'pages/Chat/agoraUtils'

const PageChatContext = createContext()

const PageChatContextProvider = (props) => {
  const parseLocalStorage = JSON.parse(localStorage.getItem('user'))
  const currentConversation = getCurrentConversation()

  const agoraChatRef = useRef(new AC.connection({
    appKey: process.env.REACT_APP_AGORA_APP_KEY.replace('-', '#'),
  }))
  const textEditorInputRef = useRef()

  const [conn, setConn] = useState(null)
  const [friendList, setFriendList] = useState([])
  const [conversationList, setConversationList] = useState([])
  const [historyMessages, setHistoryMessages] = useState([])
  const [isDialogDetailGroupShown, setIsDialogDetailGroupShown] = useState('')
  const [rerender, setRerender] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [replyMessage, setReplyMessage] = useState({})
  const [joinedGroupList, setJoinedGroupList] = useState([])
  const [publicGroupList, setPublicGroupList] = useState([])
  const [startCall, setStartCall] = useState({})

  const authAgoraChat = useCallback(async () => {
    setIsLoading(true)
    const responseToken = await getAgoraChatToken()
    const channel = String(Math.ceil(Math.random() * 100000000))
    const rtcToken = await getAgoraChatRTCToken(channel)

    agoraChatRef?.current?.open({
      user: convertEmailToAgoraId(parseLocalStorage?.email),
      agoraToken: responseToken?.token
    }).then(() => {
      agoraChatRef.current.agoraUid = rtcToken.uid
      setConn(agoraChatRef?.current)
      setIsLoading(false)
    })
  }, [])

  const fetchFriendList = async () => {
    const response = await postFriendList({
      limit: 0,
      cursor: '',
      ignorePagination: true
    })

    setFriendList(response?.contacts)
  }

  const fetchConversationList = async () => {
    const response = await getConversationList()
    setConversationList(response?.data ?? [])
    setIsLoading(false)
  }

  const fetchHistoryMessages = async () => {
    const dataCurrentConversation = getCurrentConversation()
    if (!dataCurrentConversation?.channel_id) return
    const response = await conn?.getHistoryMessages({
      targetId: splitChannel(dataCurrentConversation?.channel_id),
      searchDirection: 'down',
      chatType: dataCurrentConversation?.chatType,
      pageSize: 50
    })
    setHistoryMessages(response?.messages ?? [])
  }

  const handleDeleteCurrentConversation = (event) => {
    const dataCurrentConversation = getCurrentConversation()
    if (event.conversationId === splitChannel(dataCurrentConversation?.channel_id)) {
      clearCurrentConversation()
      setHistoryMessages([])
      fetchConversationList()
    }
  }

  const fetchJoinedGroup = async () => {
    const response = await conn?.getJoinedGroups({
    	pageNum: 1,
      pageSize: 500,
      needAffiliations: false,
      needRole: false
    })
    setJoinedGroupList(response?.data ?? [])
  }

  const fetchPublicGroup = async () => {
    const response = await conn?.getPublicGroups({
      limit: 1000,
      cursor: null
    })
    setPublicGroupList(response?.data ?? [])
  }

  useEffect(() => {
    authAgoraChat()
    fetchFriendList()
    fetchConversationList()
  }, [ authAgoraChat ])

  useEffect(() => {
    if (conn) {
      fetchJoinedGroup()
      fetchPublicGroup()

      conn.addEventHandler('eventAgoraChat', {
        onReceivedMessage: () => {
          setTimeout(() => {
            fetchConversationList()
            fetchHistoryMessages()
          }, 500)
        },
        onTextMessage: () => {
          setTimeout(() => {
            fetchConversationList()
            fetchHistoryMessages()
          }, 500)
        },
        onImageMessage: () => {
          setTimeout(() => {
            fetchConversationList()
            fetchHistoryMessages()
          }, 500)
        },
        onMultiDeviceEvent: (event) => {
          if (event.operation === 'deleteRoaming') handleDeleteCurrentConversation(event)
        },
        onAudioMessage: () => {
          setTimeout(() => {
            fetchConversationList()
            fetchHistoryMessages()
          }, 500)
        },
        onFileMessage: () => {
          setTimeout(() => {
            fetchConversationList()
            fetchHistoryMessages()
          }, 500)
        },
        onGroupEvent: async (msg) => {
          switch(msg.operation) {
          case 'directJoined': {
            await agoraSendMessage(conn, {
              msg: '',
              to: msg.id,
              chatType: 'groupChat',
              id: (Math.random() * 1000).toFixed(0).toString(),
              type: 'txt',
              ext: {
                reply: {}
              }
            })
            setTimeout(async () => {
              await fetchConversationList()
              setCurrentConversation({
                meta: {},
                group: {
                  groupid: msg.id,
                  groupname: msg.name
                },
                channel_id: `_${msg.id}@`,
                unread_num: 0,
                chatType: 'groupChat'
              })
            }, 500)
            break
          }
          default:
            break
          }
        }
      })
    }
  }, [conn])

  return (
    <PageChatContext.Provider
      value={{
        conn, setConn,
        friendList, setFriendList,
        conversationList, setConversationList,
        historyMessages, setHistoryMessages,
        isDialogDetailGroupShown, setIsDialogDetailGroupShown,
        fetchConversationList, fetchHistoryMessages,
        rerender, setRerender, fetchFriendList,
        currentConversation,
        isLoading, setIsLoading,
        replyMessage, setReplyMessage,
        textEditorInputRef,
        fetchJoinedGroup, fetchPublicGroup,
        joinedGroupList, setJoinedGroupList,
        publicGroupList, setPublicGroupList,
        startCall, setStartCall
      }}
    >
      {props.children}
    </PageChatContext.Provider>
  )
}

export { PageChatContextProvider, PageChatContext }