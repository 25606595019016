import { useContext, useState } from 'react'

// COMPONENTS
import CustomDialogDelete from 'components/Customs/CustomDialogDelete'
import TabAllGroup from './TabAllGroup'
import TabNewGroup from './TabNewGroup'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { PageChatContext } from 'contexts/PageChatContext'

// MUIS
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconGroupAdd from '@mui/icons-material/GroupAdd'
import IconClose from '@mui/icons-material/Close'
import IconGroup from '@mui/icons-material/Group'
import IconHowToReg from '@mui/icons-material/HowToReg'

// STYLES
import useStyles from './dialogDiscussionGroupUseStyles'

// UTILS
import { agoraCreateGroup } from '../agoraUtils'

const DialogDiscussionGroup = (props) => {
  const {
    isDialogDiscussionGroupShown, setIsDialogDiscussionGroupShown
  } = props
  const classes = useStyles()
  const { setSnackbarObject } = useContext(AllPagesContext)
  const { conn, fetchJoinedGroup, fetchPublicGroup } = useContext(PageChatContext)

  const initialListTabs = [
    {
      label: 'Semua Grup',
      icon: IconGroup
    },
    {
      label: 'Grup Baru',
      icon: IconGroupAdd
    },
    {
      label: 'Gabung ke Grup',
      icon: IconHowToReg
    }
  ]

  // STATES
  const [currentTab, setCurrentTab] = useState(initialListTabs[0].label)
  const [selectedDevices, setSelectedDevices] = useState([])
  const [paramsCreateGroup, setParamsCreateGroup] = useState({ groupName: '' })

  const resetAllStates = () => {
    setCurrentTab(initialListTabs[0].label)
    setSelectedDevices([])
    setParamsCreateGroup({ groupName: '' })
    setIsDialogDiscussionGroupShown(false)
  }

  const handleCreateGroup = async () => {
    if (paramsCreateGroup?.groupName && selectedDevices?.length) {
      const uidDevicesList = selectedDevices.map(item => item.username)
      await agoraCreateGroup(conn, {
        groupname: paramsCreateGroup.groupName,
        desc: '',
        members: uidDevicesList
      })
      await fetchJoinedGroup()
      await fetchPublicGroup()
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Berhasil membuat grup chat',
      })
      resetAllStates()
    } else {
      setSnackbarObject({
        open: true,
        severity: 'warning',
        title: '',
        message: 'Semua Field Harus Terisi',
      })
    }
  }

  return (
    <CustomDialogDelete
      open={isDialogDiscussionGroupShown}
      className={classes.dialog}
      sx={{
        '& .MuiPaper-root': {
          height: '500px !important',
        },
      }}
      onClose={() => resetAllStates()}
    >
      {/* HEADER */}
      <Stack className={classes.dialogHeader}>
        <Typography className={classes.dialogHeaderTitle}>
          Obrolan Grup
        </Typography>

        <IconButton onClick={() => resetAllStates()}>
          <IconClose />
        </IconButton>
      </Stack>

      <Divider />

      {/* CONTENT */}
      <Stack direction='row' flex={1} overflow='hidden'>
        {/* LIST TABS */}
        <Stack className={classes.contentListTabs}>
          <List disablePadding>
            {initialListTabs?.map((item, index) => (
              <ListItem disablePadding key={index}>
                <ListItemButton
                  selected={currentTab === item.label}
                  className={classes.listItemButton}
                  onClick={() => setCurrentTab(item.label)}
                >
                  <ListItemIcon>
                    <item.icon />
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Stack>

        {/* CONTENT TABS */}
        <Stack flex={1} overflow='hidden'>
          {currentTab === initialListTabs[0].label && <TabAllGroup
            tabType='searchGroup'
            setIsDialogDiscussionGroupShown={setIsDialogDiscussionGroupShown}
          />}
          {currentTab === initialListTabs[1].label && <TabNewGroup
            selectedDevices={selectedDevices}
            setSelectedDevices={setSelectedDevices}
            paramsCreateGroup={paramsCreateGroup}
            setParamsCreateGroup={setParamsCreateGroup}
          />}
          {currentTab === initialListTabs[2].label && <TabAllGroup tabType='joinGroup' />}
        </Stack>
      </Stack>

      {currentTab === initialListTabs[1].label && <>
        <Divider />

        <Stack padding='16px' alignItems='flex-end'>
          <Button
            className={classes.buttonDialog}
            variant='text'
            onClick={() => handleCreateGroup()}
          >SIMPAN</Button>
        </Stack>
      </>}
    </CustomDialogDelete>
  )
}

export default DialogDiscussionGroup