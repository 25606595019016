// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  panelContainer: {
    position: 'absolute',
    zIndex: theme.zIndex.fab,
    backgroundColor: 'white',
    borderRadius: 2,
    right: 94,
    top: 0,
    pointerEvents: 'auto',
    boxShadow: `0 0 10px ${alpha(theme.palette.common.black, 0.16)}`,
    '& .MuiButtonGroup-grouped:not(:last-of-type)': {
      borderColor: theme.palette.divider,
    },
  },
  buttonGroupItem: {
    width: 40,
    height: 40,
    borderRadius: 2,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  icon: {
    color: colors.boulder,
  },
  iconActive: {
    color: colors.clementine,
  },
  panelSearchDetailWrap: {
    position: 'absolute',
    zIndex: 2,
    right: 0,
    top: 0,
  },
  panelMenuContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    position: 'absolute',
    top: 40,
    right: 0,
    bottom: 0,
    zIndex: 4,
    width: 'auto',
    padding: '20px 0 0 0',
    pointerEvents: 'none'
  },
  panelZoomContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: theme.zIndex.fab,
  },
  searchLocation: {
    position: 'absolute',
    right: 202,
    zIndex: 3,
    top: 0,
    width: 320,
    pointerEvents: 'auto'
  },
  inputSearchLocation: {
    width: '100%',
    padding: '0 12px 0 8px',
    borderRadius: 2,
    backgroundColor: theme.palette.common.white,
    boxShadow: `0px 0px 10px ${alpha(theme.palette.common.black, 0.16)}`,
    '& .MuiInput-input': {
      padding: '10px 0 !important',
      color: theme.palette.common.black,
      '&::placeholder': {
        color: theme.palette.text.secondary,
        opacity: 1,
      }
    },
    '& .MuiSvgIcon-root': {
      color: colors.spunPearl
    },
    '@media only screen and (max-height: 820px)': {
      '& .MuiInput-input': {
        zoom: 0.85
      },
      '& .MuiInputAdornment-root': {
        zoom: 0.85
      }
    }
  },
  locationSuggestionsContainer: {
    backgroundColor: theme.palette.common.white,
    padding: 8,
    position: 'absolute',
    zIndex: theme.zIndex.snackbar,
    marginTop: '8px !important',
    boxShadow: `0px 0px 10px ${alpha(theme.palette.common.black, 0.16)}`,
    borderRadius: 2
  },
  suggestItemMenu: {
    width: 304,
    padding: '8px 4px',
    marginBottom: 4,
    '&:last-child': {
      marginBottom: 0,
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.08)
    }
  },
  markerPulse: {
    height: 32,
    width: 32,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: 16,
      color: theme.palette.common.white,
    },
    '&::before, &::after': {
      opacity: .8,
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      content: '""'
    },
    '&::after': {
      animation: '$pulse-circle 2s 1s ease-out infinite'
    },
    '&::before': {
      animation: '$pulse-circle 2s ease-out infinite'
    }
  },
  '@keyframes pulse-circle': {
    '0%': {
      transform: 'scale(1)',
    },
    '100%': {
      opacity: 0,
      transform: 'scale(1.8)',
    }
  },
  dispatchPointWrapper: {
    position: 'absolute',
    right: 132,
    zIndex: 3,
    top: 0,
    pointerEvents: 'auto'
  },
  controlIconButton: {
    pointerEvents: 'auto',
    marginRight: 16,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.secondary,
    boxShadow: `0 0 10px ${alpha(theme.palette.common.black, 0.16)}`,
    borderRadius: 2,
    height: 40,
    width: 40,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    '& .MuiSvgIcon-root': {
      color: colors.spunPearl
    },
    '&.active': {
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main
      }
    }
  },
}))

export default useStyles