import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'

// COMPONENTS
import DialogAddOrEdit from 'components/DialogAddOrEditNew/DialogAddOrEdit'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import SimpleBackdrop from 'components/Partials/SimpleBackdrop'

// CONSTANTS
import { basePaths } from 'constants/paths'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// CUSTOM COMPONENTS
import CustomFormControl from 'components/Customs/CustomFormControl'
import CustomInput from 'components/Customs/CustomInput'
import CustomInputLabelNew from 'components/Customs/CustomInputLabelNew'
import CustomInputNew from 'components/Customs/CustomInputNew'
import CustomMenuItem from 'components/Customs/CustomMenuItem'

// MUIS
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconAnimation from '@mui/icons-material/Animation'
import IconApartment from '@mui/icons-material/Apartment'
import IconArrowDropDown from '@mui/icons-material/ArrowDropDown'
import IconBallot from '@mui/icons-material/Ballot'
import IconChromeReaderMode from '@mui/icons-material/ChromeReaderMode'
import IconDirectionsCar from '@mui/icons-material/DirectionsCar'
import IconEventNote from '@mui/icons-material/EventNote'
import IconGarage from '@mui/icons-material/Garage'
import IconLabel from '@mui/icons-material/Label'
import IconNearMe from '@mui/icons-material/NearMe'
import IconNotes from '@mui/icons-material/Notes'
import IconSearch from '@mui/icons-material/Search'
import IconWidgets from '@mui/icons-material/Widgets'

// SERVICES
import {
  postANewVehicle,
  getGpsTrackerList,
  getVehicleTypeList,
  putEditAVehicle,
} from 'services/vehicleServices'
import { getOfficerDepartmentList } from 'services/settingsOfficersServices'

// STYLES
import useStyles from './dialogAddOrEditVehicleUseStyles'

// UTILITIES
import { doesSuccessfullyCallTheApi } from 'utilities/api'

const DialogAddOrEditVehicle = (props) => {
  const {
    dialogType,
    dialogAddOrEditVehicle,
    setDialogAddOrEditVehicle,
    reloadData,
    pageRef,
    vehiclesTableData
  } = props

  const classes = useStyles()

  const history = useHistory()

  const { setSnackbarObject } = useContext(AllPagesContext)

  let initialDialogParams = {
    vehicleName: '',
    deviceObjectId: '',
    deviceObjectLabel: '',
    vehicleTypeNo: '',
    plateNo: '',
    brand: '',
    model: '',
    productionYear: '',
    skeletonNumber: '',
    machineNumber: '',
    description: '',
    department: '',
  }

  if (dialogType === 'edit') {
    initialDialogParams = {
      vehicleName: dialogAddOrEditVehicle.row.vehicleName,
      deviceObjectId: dialogAddOrEditVehicle.row.deviceObjectId,
      deviceObjectLabel: dialogAddOrEditVehicle.row.deviceObjectLabel,
      vehicleTypeNo: dialogAddOrEditVehicle.row.vehicleTypeNo,
      plateNo: dialogAddOrEditVehicle.row.plateNo,
      brand: dialogAddOrEditVehicle.row.brand,
      model: dialogAddOrEditVehicle.row.model,
      productionYear: dialogAddOrEditVehicle.row.productionYear,
      skeletonNumber: dialogAddOrEditVehicle.row.skeletonNumber,
      machineNumber: dialogAddOrEditVehicle.row.machineNumber,
      description: dialogAddOrEditVehicle.row.description,
      department: dialogAddOrEditVehicle.row.roleName,
    }
  }

  const [isLoading, setIsLoading] = useState(false)
  const [objectMenuAnchor, setObjectMenuAnchor] = useState(null)
  const [objectList, setObjectList] = useState([])
  const [searchObject, setSearchObject] = useState('')
  const [selectedObject, setSelectedObject] = useState({})
  const [vehicleTypeMenuAnchor, setVehicleTypeMenuAnchor] = useState(false)
  const [vehicleTypeList, setVehicleTypeList] = useState([])
  const [selectedVehicleType, setSelectedVehicleType] = useState({})
  const [dialogParams, setDialogParams] = useState(initialDialogParams)
  const [isReassignOpen, setIsReassignOpen] = useState({})
  const [vehicleTypeName, setVehicleTypeName] = useState('')
  const [departmentList, setDepartmentList] = useState([])
  const [departmentMenuAnchor, setDepartmentMenuAnchor] = useState(null)
  const [searchDepartment, setSearchDepartment] = useState('')

  /* FUNCTION CHECK OBJECT ALREADY USED */
  const isUsedDeviceObject = (deviceLabel, tableData) => {
    // if no have table data/empty
    if(!tableData || !tableData.length) return false

    const findDeviceObject = tableData.find(item => item.deviceObjectLabel === deviceLabel)
    return findDeviceObject
  }

  /* HANDLE INPUT */
  const handleDialogParamsChange = (event) => {
    setDialogParams({
      ...dialogParams,
      [event.target.name]: event.target.value,
    })
  }

  /* HANDLE BUTTON SAVE */
  const onSaveButtonIsClicked = async () => {
    setIsLoading(true)

    let result
    if (dialogType === 'add') {
      result = await postANewVehicle({
        ...dialogParams,
        deviceObjectId: selectedObject.id,
        vehicleTypeNo: selectedVehicleType.vehicleTypeNo,
        roleNo: departmentList.find(item => item?.name?.toLowerCase() === dialogParams?.department?.toLowerCase()).id
      })

      // CLEAR dialogParams AFTER SUCCESS POST
      setDialogParams(initialDialogParams)
    } else if (dialogType === 'edit') {
      result = await putEditAVehicle(dialogAddOrEditVehicle.id, {
        ...dialogParams,
        roleNo: departmentList.find(item => item?.name?.toLowerCase() === dialogParams?.department?.toLowerCase()).id
      })
    }
    setIsLoading(false)

    let resultMessage = 'Sukses Menambahkan Kendaraan Baru'
    if (dialogType === 'edit') resultMessage = 'Sukses Mengubah Data Kendaraan'

    // HANDLE SUCCESS CONDITION
    if (doesSuccessfullyCallTheApi(result.status)) {
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: resultMessage,
      })
      
      setDialogAddOrEditVehicle(null)
      reloadData()
      history.push(basePaths.deviceVehicles)
    }
    // HANDLE UNSUCCESS CONDITIONS
    else {
      let errorMessage = ''
      if(result.status === 'No Server Response') errorMessage = 'Server tidak meresponse'
      else if(result.data.message === 'Plate No already exist') errorMessage = 'Plat nomor sudah digunakan'
      
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: errorMessage,
      })
    }
  }

  /* HANDLE OBJECT ITEM OPTION LIST ONCLICK */
  const handleObjectMenuItemClick = (item, index) => {
    setIsReassignOpen(isUsedDeviceObject(item.label, vehiclesTableData))

    setDialogParams({
      ...dialogParams,
      deviceObjectId: item.id,
      deviceObjectLabel: item.name ?? item.label,
    })
    setSelectedObject(item)
    setObjectMenuAnchor(null)
  }

  /* HANDLE VEHICLE TYPE MENU ONCLICK */
  const handleVehicleTypeMenuItemClick = (inputItem, inputIndex) => {
    setVehicleTypeMenuAnchor(null)
    setVehicleTypeName(inputItem.vehicleTypeName)
    setSelectedVehicleType(inputItem)
  }

  const handleMenuItemClicked = (item) => {
    setDialogParams(current => {
      return {
        ...current,
        department: item.name,
        roleNo: item.id
      }
    })
    setDepartmentMenuAnchor(null)
  }

  /* GET DEVICE OBJECT LIST */
  const loadObjectListData = async () => {
    const objectListParams = {
      assignedPatrol : true,
      clockType : 0
    }

    return await getGpsTrackerList({
      ignorePagination : true,
      type : 'VEHICLE'
    }, objectListParams)
  }

  /* GET VEHICLE TYPE LIST */
  const loadVehicleTypeListData = async () => {
    const vehicleTypeListParams = {
      page: 0,
      size: 100,
    }

    return await getVehicleTypeList(vehicleTypeListParams)
  }

  const loadDepartmentListData = async () => {
    const resultDepartmentList = await getOfficerDepartmentList()

    if (resultDepartmentList.status !== 'error') {
      const newDepartmentList = resultDepartmentList.map(item => {
        return {
          ...item,
          isShown: true,
        }
      })
      setDepartmentList(newDepartmentList)
    }
  }

  const updateDepartmentListBySearch = () => {
    const newDepartmentList = departmentList.map(item => {
      if (searchDepartment === '') item.isShown = true
      else {
        if (item.name !== '' && item.name.toLowerCase().includes(searchDepartment.toLowerCase())) item.isShown = true
        else item.isShown = false
      }

      return item
    })
    setDepartmentList(newDepartmentList)
  }

  useEffect(() => {
    loadDepartmentListData()
  }, [])

  useEffect(() => {
    updateDepartmentListBySearch()
  }, [searchDepartment])

  /* EXECUTE loadObjectListData & loadVehicleTypeListData */
  useEffect(() => {
    const loadObjectVehicle = async () => {
      const responseObjectList = await loadObjectListData()
      const responseVehicleTypeList = await loadVehicleTypeListData()

      if (responseObjectList.status !== 'error') {
        let newObjectList = responseObjectList.forEach((item) => {
          if(item.label.toLowerCase().includes(searchObject.toLowerCase())) item.isShown = true
          else item.isShown = false
        })
        setObjectList(responseObjectList)
      }
      if (responseVehicleTypeList) {
        setVehicleTypeList(responseVehicleTypeList.rows)
      }

      if (
        dialogType === 'edit' &&
        dialogAddOrEditVehicle.row.deviceObjectId &&
        dialogAddOrEditVehicle.row.vehicleType
      ) {
        const tempSelectedObject = responseObjectList?.rows?.find(
          item => item.deviceObjectId === dialogAddOrEditVehicle?.row?.deviceObjectId)
        if (tempSelectedObject) setSelectedObject(tempSelectedObject)

        const tempSelectedVehicleType = responseVehicleTypeList?.rows?.find(
          item => item.type === dialogAddOrEditVehicle?.row?.vehicleType
        )
        if (tempSelectedVehicleType) setSelectedVehicleType(tempSelectedVehicleType)

        if (tempSelectedObject && tempSelectedVehicleType) setDialogParams({
          ...dialogParams,
          deviceObjectId: tempSelectedObject?.id,
          deviceObjectLabel: tempSelectedObject?.label,
          vehicleTypeNo: tempSelectedVehicleType?.vehicleTypeNo,
        })
      }
    }

    loadObjectVehicle()
  }, [dialogAddOrEditVehicle])

  // HANDLE SEARCH BAR
  useEffect(() => {
    let newObjectList = [...objectList]

    newObjectList.forEach((item) => {
      if(item.label.toLowerCase().includes(searchObject.toLowerCase())) item.isShown = true
      else item.isShown = false
    })

    setObjectList(newObjectList)
  }, [searchObject])
  
  
  /* CLEAR searchObject  */
  useEffect(() => {
    if (!objectMenuAnchor) setSearchObject('')
  }, [objectMenuAnchor])

  /* RUN SIDE EFFECT WHEN SELECT "JENIS KENDARAAN" ON CHANGE */
  useEffect(() => {
    if (vehicleTypeList.length >= 1) {
      vehicleTypeName &&
        setDialogParams({
          ...dialogParams,
          vehicleTypeNo: vehicleTypeList.find(
            (item) => item.vehicleTypeName === vehicleTypeName
          ).vehicleTypeNo,
        })
    }
  }, [vehicleTypeList, vehicleTypeName])

  /* SET DEFAULT VALUE STATE vehicleTypeName  */
  useEffect(() => {
    if (Boolean(dialogAddOrEditVehicle) && dialogType === 'edit')
      vehicleTypeList.length &&
        setVehicleTypeName(
          vehicleTypeList.find(
            (item) => item.vehicleTypeNo === dialogParams.vehicleTypeNo
          ).vehicleTypeName
        )
  }, [dialogAddOrEditVehicle, vehicleTypeList])

  let selectedTitle = ''
  if (dialogType === 'add') selectedTitle = 'Tambah Kendaraan'
  else if (dialogType === 'edit') selectedTitle = 'Ubah Kendaraan'

  return (
    <>
      {/* DIALOG REASSIGN DEVICE OBJECT */}
      {isReassignOpen && (
        <DialogConfirmation
          title='Informasi'
          caption='Objek ini sudah terpasang pada vehicle lain. Apakah Anda ingin menugaskan kembali?'
          dialogConfirmationObject={isReassignOpen}
          setDialogConfirmationObject={setIsReassignOpen}
          cancelButtonText='Batal'
          continueButtonText='Reassign'
          onContinueButtonClick={() => setIsReassignOpen({})}
          onCancelButtonClick={() => setIsReassignOpen({})}
        />
      )}

      {/* DIALOG ADD OR EDIT */}
      <DialogAddOrEdit
        open={Boolean(dialogAddOrEditVehicle)}
        title={selectedTitle}
        onCloseIconClick={() => history.push(basePaths.deviceVehicles)}
        containerRef={pageRef}
        contents={[
          <Stack>
            {/* MAIN SECTION TEXT */}
            <Typography 
              className={classes.dialogHeaderTitle} 
              variant='h6'
            >
              Informasi Tugas
            </Typography>

            {/* VEHICLE NAME INPUT */}
            <CustomFormControl 
              variant='filled' 
              className={classes.formControl}
            >
              <CustomInputLabelNew shrink={true}>
                <IconLabel/>
                <Typography variant='subtitle1'>
                  Nama Kendaraan
                </Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Masukkan Nama Kendaraan'
                type='text'
                name='vehicleName'
                value={dialogParams.vehicleName}
                onChange={handleDialogParamsChange}
              />
            </CustomFormControl>

            {/* DEPARTMENT INPUT */}
            <CustomFormControl 
              variant='filled' 
              className={classes.formControl}
            >
              <CustomInputLabelNew shrink={true}>
                <IconApartment/>
                <Typography variant='subtitle1'>
                  Departemen
                </Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Pilih Departemen'
                readOnly
                type='text'
                name='department'
                value={dialogParams.department}
                onChange={handleDialogParamsChange}
                endAdornment={<IconArrowDropDown className={`${classes.dropDownIcon} zoom`}/>}
                onClick={(event) => setDepartmentMenuAnchor(event.currentTarget)}
                className={`${classes.inputDepartment} no-zoom`}
              />
            </CustomFormControl>
            
            {/* DEPARTMENT MENU */}
            <Menu
              anchorEl={departmentMenuAnchor}
              open={Boolean(departmentMenuAnchor)}
              onClose={() => setDepartmentMenuAnchor(null)}
              className={`${classes.menu} no-zoom`}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Stack className='zoom'>
                {/* SEARCH INPUT */}
                <Box className={classes.menuSearch}>
                  <CustomInput
                    type='text'
                    placeholder='Search'
                    className={classes.menuSearchInput}
                    value={searchDepartment}
                    onChange={(event) => setSearchDepartment(event.target.value)}
                    startAdornment={<IconSearch className={classes.menuSearchIcon}/>}
                    onClick={(event) => event.stopPropagation()}
                    onKeyDown={(event) => event.stopPropagation()}
                  />
                </Box>

                {/* DEPARTMENT OPTION LIST */}
                <Box className={classes.menuItemContainer}>
                  {departmentList.map((item, index) => (
                    item.isShown &&
                    <CustomMenuItem
                      key={index}
                      className={
                        dialogParams.department === item.name
                          ? `${classes.menuItem} ${classes.menuItemSelected}`
                          : classes.menuItem
                      }
                      onClick={() => handleMenuItemClicked(item)}
                    >
                      {item.name}
                    </CustomMenuItem>
                  ))}
                </Box>
              </Stack>
            </Menu>

            {/* OBJECT INPUT */}
            <CustomFormControl 
              variant='filled' 
              className={classes.formControl}
            >
              <CustomInputLabelNew shrink={true}>
                <IconNearMe/>
                <Typography variant='subtitle1'>
                  Objek
                </Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Pilih Objek'
                readOnly
                type='text'
                name='deviceObjectLabel'
                value={dialogParams.deviceObjectLabel}
                onChange={handleDialogParamsChange}
                endAdornment={<IconArrowDropDown className={`${classes.dropDownIcon} zoom`}/>}
                onClick={(event) => setObjectMenuAnchor(event.currentTarget)}
                className={`${classes.inputDepartment} no-zoom`}
              />
            </CustomFormControl>

            {/* OBJECT MENU */}
            <Menu
              anchorEl={objectMenuAnchor}
              open={Boolean(objectMenuAnchor)}
              onClose={() => setObjectMenuAnchor(null)}
              className={`${classes.objectMenu} no-zoom`}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Stack className='zoom'>
                {/* SEARCH INPUT */}
                <Box className={classes.objectMenuItemSearch}>
                  <CustomInput
                    type='text'
                    name='search'
                    placeholder='Pencarian'
                    className={classes.objectSearchInput}
                    value={searchObject}
                    onChange={(event) => setSearchObject(event.target.value)}
                    startAdornment={<IconSearch className={classes.objectSearchIcon}/>}
                    onClick={(event) => event.stopPropagation()}
                  />
                </Box>

                {/* OBJECT ITEM OPTION LIST */}
                <Box className={classes.objectMenuItemContainer}>
                  {objectList.map((item, index) => (
                    item.isShown &&
                    <MenuItem
                      key={index}
                      className={
                        dialogParams.deviceObjectId === item.id
                          ? `${classes.objectMenuItem} ${classes.objectMenuItemSelected}`
                          : classes.objectMenuItem
                      }
                      onClick={() => handleObjectMenuItemClick(item, index)}
                    >
                      <Typography
                        variant='subtitle2'
                        className={classes.objectMenuItemText}
                      >
                        {item.name ?? item.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </Box>
              </Stack>
            </Menu>

            {/* VEHICLE TYPE INPUT */}
            <CustomFormControl 
              variant='filled' 
              className={classes.formControl}
            >
              <CustomInputLabelNew shrink={true}>
                <IconWidgets/>
                <Typography variant='subtitle1'>
                  Jenis Kendaraan
                </Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Pilih Jenis Kendaraan'
                readOnly
                type='text'
                name='vehicleTypeName'
                value={vehicleTypeName}
                onChange={(e) => setVehicleTypeName(e.target.value)}
                endAdornment={<IconArrowDropDown className={classes.dropDownIcon} />}
                onClick={(event) =>setVehicleTypeMenuAnchor(event.currentTarget)}
              />
            </CustomFormControl>

            {/* VEHICLE TYPE MENU */}
            <Menu
              anchorEl={vehicleTypeMenuAnchor}
              open={Boolean(vehicleTypeMenuAnchor)}
              onClose={() => setVehicleTypeMenuAnchor(null)}
              className={classes.vehicleMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {vehicleTypeList.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleVehicleTypeMenuItemClick(item, index)}
                  className={
                    dialogParams.vehicleTypeNo === item.vehicleTypeNo
                      ? classes.vehicleTypeMenuItemSelected
                      : ''
                  }
                >
                  {item.vehicleTypeName}
                </MenuItem>
              ))}
            </Menu>

            {/* LICENSE PLAT INPUT */}
            <CustomFormControl 
              variant='filled' 
              className={classes.formControl}
            >
              <CustomInputLabelNew shrink={true}>
                <IconChromeReaderMode/>
                <Typography variant='subtitle1'>
                  Plat Nomor
                </Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Masukan Plat Nomor'
                type='text'
                name='plateNo'
                value={dialogParams.plateNo}
                onChange={handleDialogParamsChange}
              />
            </CustomFormControl>

            {/* BRAND INPUT */}
            <CustomFormControl 
              variant='filled' 
              className={classes.formControl}
            >
              <CustomInputLabelNew shrink={true}>
                <IconDirectionsCar/>
                <Typography variant='subtitle1'>
                  Merek
                </Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Masukan Merek'
                type='text'
                name='brand'
                value={dialogParams.brand}
                onChange={handleDialogParamsChange}
              />
            </CustomFormControl>

            {/* MODEL INPUT */}
            <CustomFormControl 
              variant='filled' 
              className={classes.formControl}
            >
              <CustomInputLabelNew shrink={true}>
                <IconAnimation/>
                <Typography variant='subtitle1'>
                  Model
                </Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Masukan Model'
                type='text'
                name='model'
                value={dialogParams.model}
                onChange={handleDialogParamsChange}
              />
            </CustomFormControl>

            {/* PRODUCTION YEAR INPUT*/}
            <CustomFormControl 
              variant='filled' 
              className={classes.formControl}
            >
              <CustomInputLabelNew shrink={true}>
                <IconEventNote/>
                <Typography variant='subtitle1'>
                  Tahun Produksi
                </Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Masukan Tahun Produksi'
                type='text'
                name='productionYear'
                value={dialogParams.productionYear}
                onChange={handleDialogParamsChange}
              />
            </CustomFormControl>

            {/* SKELETON NUMBER INPUT */}
            <CustomFormControl 
              variant='filled' 
              className={classes.formControl}
            >
              <CustomInputLabelNew shrink={true}>
                <IconGarage/>
                <Typography variant='subtitle1'>
                  Nomor Kerangka
                </Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Masukan Nomor Kerangka'
                type='text'
                name='skeletonNumber'
                value={dialogParams.skeletonNumber}
                onChange={handleDialogParamsChange}
              />
            </CustomFormControl>

            {/* MACHINE NUMBER */}
            <CustomFormControl 
              variant='filled' 
              className={classes.formControl}
            >
              <CustomInputLabelNew shrink={true}>
                <IconBallot/>
                <Typography variant='subtitle1'>
                  Nomor Mesin
                </Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Masukan Nomor Mesin'
                type='text'
                name='machineNumber'
                value={dialogParams.machineNumber}
                onChange={handleDialogParamsChange}
              />
            </CustomFormControl>

            {/* DESCRIPTION INPUT */}
            <CustomFormControl 
              variant='filled' 
              className={classes.formControl}
            >
              <CustomInputLabelNew shrink={true}>
                <IconNotes/>
                <Typography variant='subtitle1'>
                  Keterangan
                </Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Masukan Keterangan'
                type='text'
                name='description'
                value={dialogParams.description}
                onChange={handleDialogParamsChange}
              />
            </CustomFormControl>
          </Stack>
        ]}
        onCancelButtonClick={() => history.push(basePaths.deviceVehicles)}
        onSaveButtonClick={onSaveButtonIsClicked}
      />

      {/* LOADING */}
      <SimpleBackdrop isLoading={isLoading} />
    </>
  )
}

export default DialogAddOrEditVehicle
