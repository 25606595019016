// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '4px',
    boxShadow: '0 0 12px rgba(0, 0, 0, 0.10)'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#1D1F2D',
    padding: 12,
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
  },
  title: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '19px',
    color: '#FFFFFF !important'
  },
  contentContainer: {
    padding: 12,
  },
  avatar: {
    width: 28,
    height: 28,
    fontSize: 14,
  },
  button: {
    padding: '7px 8px',
    width: '100%'
  },
  iconVideo: {
    color: '#FFFFFF'
  },
  listUserJoin: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 0 24px 0'
  },
  iconGroup: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: '8px'
  },
  iconPlay: {
    height: '18px',
    marginRight: '4px'
  },
  dateWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 21
  },
  dateText: {
    color: '#000000',
    marginLeft: '4px'
  },
  iconTime: {
    height: '18px',
    color: 'rgba(0, 0, 0, 0.54)'
  }
}))

export default useStyles
