// CONSTANTS
import { menuCst } from 'constants/values'

// MUI ICONS
import IconAccountCircle from '@mui/icons-material/AccountCircle'
import IconAdUnits from '@mui/icons-material/AdUnits'
import IconDashboard from '@mui/icons-material/Dashboard'
import IconFactCheck from '@mui/icons-material/FactCheck'
import IconConfirmationNumberOutlined from '@mui/icons-material/ConfirmationNumberOutlined'
import IconLanguage from '@mui/icons-material/Language'
import IconNotifications from '@mui/icons-material/Notifications'
import IconOutbound from '@mui/icons-material/Outbound'
import IconSensors from '@mui/icons-material/Sensors'
import IconSettings from '@mui/icons-material/Settings'

// PATH ICONS
import IconTextToSpeech from 'assets/images/pathIcons/IconTextToSpeech'

// UTILITIES
import { getUser, isAccessMenu } from 'utilities/auth'

const drawerNavigationList = (inputBadgeLiveStreaming) => {
  return [
    {
      type: 'collection',
      accessMenu: menuCst.dashboard,
      activeValue: ['/', '/analytics'],
      icon: IconDashboard,
      text: 'Overview',
      badge: null,
      children: [
        {
          linkTo: '/',
          activeValue: ['/'],
          text: 'Dashboard',
          badge: null,
          accessMenuChild: 'Dashboard'
        },
        {
          linkTo: '/analytics',
          activeValue: ['/analytics'],
          text: 'Analytics',
          badge: null,
          accessMenuChild: 'Dashboard'
        },
      ],
    },
    {
      type: 'single',
      linkTo: '/live',
      accessMenu: isAccessMenu(menuCst.live) && menuCst.live
      || isAccessMenu(menuCst.agentHelp) && menuCst.agentHelp
      || isAccessMenu(menuCst.agentPlaybackVideo) && menuCst.agentPlaybackVideo
      || isAccessMenu(menuCst.conference) && menuCst.conference,
      activeValue: ['/live'],
      icon: IconSensors,
      text: 'Siaran Langsung',
      badge: (isAccessMenu(menuCst.live) 
      || isAccessMenu(menuCst.agentHelp)
      || isAccessMenu(menuCst.conference)) ? inputBadgeLiveStreaming : 0,
    },
    {
      type: 'single',
      linkTo: '/tracking',
      accessMenu: menuCst.tracking,
      activeValue: ['/tracking'],
      icon: IconLanguage,
      text: 'Peta',
      badge: null,
    },
    {
      type: 'collection',
      accessMenu: menuCst.dispatchPatrol || menuCst.dispatchEvidence,
      activeValue: ['/penugasan-kejadian', '/penugasan-kegiatan'],
      icon: IconOutbound,
      text: 'Penugasan',
      badge: null,
      children: [
        {
          linkTo: '/penugasan-kegiatan',
          activeValue: ['/penugasan-kegiatan'],
          text: 'Kegiatan',
          badge: null,
          accessMenuChild: 'Penugasan - Giat'
        },
        {
          linkTo: '/penugasan-kejadian',
          activeValue: ['/penugasan-kejadian'],
          text: 'Kejadian',
          badge: null,
          accessMenuChild: 'Penugasan - Kejadian'
        },
      ],
    },
    {
      type: 'single',
      accessMenu: menuCst.patrol,
      activeValue: ['/kegiatan'],
      icon: IconFactCheck,
      text: 'Kegiatan',
      linkTo: '/kegiatan',
      badge: null,
    },
    {
      type: 'single',
      accessMenu: menuCst.evidence,
      activeValue: ['/kejadian'],
      icon: IconConfirmationNumberOutlined,
      text: 'Kejadian',
      linkTo: '/kejadian',
      badge: null,
    },
    {
      type: 'collection',
      accessMenu: menuCst.androidDevices || menuCst.vehicles,
      activeValue: ['/devices-mobile-apps', '/devices-vehicles'],
      icon: IconAdUnits,
      text: 'Perangkat',
      children: [
        {
          linkTo: '/devices-mobile-apps',
          activeValue: ['/devices-mobile-apps'],
          text: 'Aplikasi Seluler',
          badge: null,
          accessMenuChild: 'Android Devices'
        },
        {
          linkTo: '/devices-vehicles',
          activeValue: ['/devices-vehicles'],
          text: 'Kendaraan',
          badge: null,
          accessMenuChild: 'Vehicles'
        },
      ],
      badge: null,
    },
    {
      type: 'single',
      accessMenu: menuCst.evidence,
      activeValue: ['/push-to-talks'],
      icon: IconTextToSpeech,
      text: 'Push To Talks',
      linkTo: '/push-to-talks',
      badge: null,
    },
    {
      type: 'single',
      linkTo: '/alert',
      accessMenu: menuCst.alert,
      activeValue: ['/alert'],
      icon: IconNotifications,
      text: 'Pemberitahuan',
      badge: null,
    },
    {
      type: 'collection',
      accessMenu: 'Settings',
      activeValue: [
        '/settings-duty-types',
        '/settings-activity-types',
        '/settings-sos-message-types',
        '/settings-disturbances',
        '/settings-cases',
        '/settings-officers',
        '/settings-groups',
        '/settings-places',
        '/settings-gps-devices',
        '/settings-users',
        '/settings-security-control',
        '/settings-tag-evidences',
        '/settings-tag-location',
        '/settings-sos-message-types'
      ],
      icon: IconSettings,
      text: 'Pengaturan',
      children: [
        {
          linkTo: '/settings-duty-types',
          activeValue: ['/settings-duty-types'],
          text: 'Metode Patroli',
          badge: null,
          accessMenuChild: 'Duty Types'
        },
        {
          linkTo: '/settings-activity-types',
          activeValue: ['/settings-activity-types'],
          text: 'Jenis Kegiatan',
          badge: null,
          accessMenuChild: 'Activity Types'
        },
        {
          linkTo: '/settings-sos-message-types',
          activeValue: ['/settings-sos-message-types'],
          text: 'Jenis Pesan SOS',
          badge: null,
          accessMenuChild: 'Activity Types'
        },
        {
          linkTo: '/settings-sos-message-types',
          activeValue: ['/settings-sos-message-types'],
          text: 'Jenis Pesan SOS',
          badge: null,
          accessMenuChild: 'SOS Message Types'
        },
        {
          linkTo: '/settings-tag-evidences',
          activeValue: ['/settings-tag-evidences'],
          text: 'Tag Kejadian',
          badge: null,
          accessMenuChild: 'Activity Types'
        },
        {
          linkTo: '/settings-disturbances',
          activeValue: ['/settings-disturbances'],
          text: 'Gangguan',
          badge: null,
          accessMenuChild: 'Disturbances'
        },
        {
          linkTo: '/settings-cases',
          activeValue: ['/settings-cases'],
          text: 'Jenis Kejahatan',
          badge: null,
          accessMenuChild: 'Cases'
        },
        {
          linkTo: '/settings-officers',
          activeValue: ['/settings-officers'],
          text: 'Petugas',
          badge: null,
          accessMenuChild: 'Officers'
        },
        {
          linkTo: '/settings-groups',
          activeValue: ['/settings-groups'],
          text: 'Kelompok',
          badge: null,
          accessMenuChild: 'Groups'
        },
        {
          linkTo: '/settings-places',
          activeValue: ['/settings-places'],
          text: 'Lokasi',
          badge: null,
          accessMenuChild: 'Places'
        },
        {
          linkTo: '/settings-users',
          activeValue: ['/settings-users'],
          text: 'Pengguna',
          badge: null,
          accessMenuChild: 'Users'
        },
        {
          linkTo: '/settings-tag-location',
          activeValue: ['/settings-tag-location'],
          text: 'Tag Lokasi',
          badge: null,
          accessMenuChild: 'Users'
        },
        {
          linkTo: '/settings-security-control',
          activeValue: ['/settings-security-control'],
          text: 'Kontrol Keamanan',
          badge: null,
          accessMenuChild: 'Security Control'
        },
      ],
      badge: null,
    },
    {
      type: 'collection',
      accessMenu: menuCst.profile,
      activeValue: ['/profile'],
      icon: IconAccountCircle,
      text: getUser() ? getUser().userName : 'Profil',
      children: [
        {
          linkTo: '/ubah-kata-sandi',
          activeValue: ['/ubah-kata-sandi'],
          text: 'Kata Sandi',
          accessMenuChild: 'Profile'
        },
        {
          onClickType: 'logOut',
          activeValue: ['/profile-log-out'],
          text: 'Keluar',
          accessMenuChild: 'Profile'
        },
      ],
      badge: null,
    },
  ]
}

export default drawerNavigationList
