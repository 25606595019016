// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconWildFire = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M11.5764 1.41174C11.5764 3.95292 9.88224 5.22351 9.03518 5.64704C9.03518 5.64704 9.03518 3.52939 6.91753 3.10586C6.91753 5.42257 5.51135 5.97739 5.26147 7.34115H18.7639C18.5988 5.07951 16.6587 3.95292 16.6587 3.95292C16.862 5.21927 14.5411 6.07057 14.5411 6.07057C14.5411 3.52939 12.8469 1.41174 11.5764 1.41174ZM3.5293 8.18821V14.1176H8.86147L11.9999 10.9792L15.1383 14.1176H15.2781C15.3035 13.9482 15.3162 13.7534 15.3162 13.5162V12.1613L16.5363 12.7536C16.7692 12.8679 16.9721 13.0039 17.1542 13.1564C17.0737 12.6736 16.9342 12.3261 16.9342 12.3176L15.8282 9.56882L18.2172 11.3184C19.2718 12.0934 20.0212 13.0249 20.449 14.1176H20.4705V8.18821H3.5293ZM17.7175 12C17.7175 12 18.4939 13.8947 17.7175 15.4105C17.3292 14.6529 16.9408 13.8945 16.164 13.5154C16.164 15.4103 15.3881 15.411 15.3881 16.9268C15.3881 17.9725 16.1645 19.2 17.7175 19.2C19.2706 19.2 20.0469 18.1095 20.0469 16.5471C20.0469 14.6523 19.2706 13.1367 17.7175 12ZM11.9999 12.1737L9.20889 14.9647H4.79989V22.5882H10.3058V18.7764C10.3058 17.8404 11.0639 17.0823 11.9999 17.0823C12.9359 17.0823 13.694 17.8404 13.694 18.7764V22.5882H19.1999V19.717C18.7721 19.933 18.2724 20.047 17.7175 20.047C15.7312 20.047 14.5411 18.4592 14.5411 16.926C14.5411 16.0281 14.7696 15.5234 14.9729 15.083C14.9898 15.0449 15.0072 15.0028 15.0242 14.9647H14.7909L11.9999 12.1737ZM6.494 16.6588H8.61165V19.6235H6.494V16.6588Z'/>
    </SvgIcon>
  )
}

export default IconWildFire