import { http } from 'utilities/http'
import { stringify } from 'query-string'

export const getLocationTagsList = async (queryParams) => {
  try {
    const { data } = await http.get(`/location-tags?${stringify(queryParams)}`)
    return data
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

export const postLocationTags = async (bodyParams) => {
  try {
    const { data } = await http.post('/location-tags', bodyParams)
    return data
  } catch (error) {
    return {
      status: 'error'
    }
  }
}

export const putLocationTags = async (id, bodyParams) => {
  try {
    const { data } = await http.put(`/location-tags/${id}`, bodyParams)
    return data
  } catch (error) {
    return {
      status: 'error',
    }
  }
}

export const deleteLocationTags = async (id) => {
  try {
    const { data } = await http.delete(`/location-tags/${id}`)
    return data
  } catch (error) {
    return {
      status: 'error',
    }
  }
}