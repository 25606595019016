// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    fontWeight: 600,
    marginBottom: 16,
  },
  formControl: {
    marginBottom: 16,
    borderRadius: '2px !important',
    '& .formControlDatePicker': {
      cursor: 'pointer'
    },
    '&:last-child': {
      marginBottom: 0,
    }
  },
}))

export default useStyles