// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 70,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.borderLightBlack}`,
    padding: '0px 20px 0px 24px',
  },
  groupByText: {
    marginRight: 12,
    fontWeight: 400,
    color: colors.boulder,
  },
  groupByInput: {
    width: 200,
    marginRight: 'auto',
    '&::before': {
      borderBottom: `1px solid ${colors.borderLightBlack}`,
    },
  },
  groupByIcon: {
    color: colors.boulder,
  },
  groupByMenu: {
    '& .MuiPaper-root': {
      width: 200,
    },
  },
  groupByMenuItemSelected: {
    color: colors.clementine,
  },
  filterIconButton: {
    marginLeft: 'auto',
  },
  settingsIconButton: {
    marginLeft: 5,
  },
  columnsMenuRoot: {
    '& .MuiList-root': {
      padding: '12px 4px',
      width: 250,
    },
  },
  columnsMenuTitle: {
    fontWeight: 600,
    padding: '0px 18px 6px',
  },
  columnsMenuItem: {
    display: 'flex',
    padding: '0px 16px 0px 6px',
  },
  columnsMenuText: {
    color: colors.boulder,
    fontWeight: 400,
  },
}))

export default useStyles