// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      minWidth: 800,
      padding: 0,
    }
  },
  dialogHeader: {
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  dialogHeaderTitle: {
    fontSize: 20,
    fontWeight: 600,
    flex: 1,
  },
  listItemButton: {
    height: 56,
    borderBottom: `1px solid ${theme.palette.divider}`,
    width: 240,
    '& .MuiListItemText-primary': {
      fontWeight: 600
    },
    '& .MuiListItemIcon-root': {
      minWidth: 40
    }
  },
  contentListTabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  conversationAvatar: {
    minWidth: 'auto',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 16,
    width: 74,
    height: 74
  },
  itemMemberTab: {
    '&.MuiTab-root': {
      height: 54,
      fontSize: 14,
      color: theme.palette.text.secondary,
    },
    '&.MuiTab-root.Mui-selected': {
      fontWeight: 600,
      color: theme.palette.text.primary,
    },
  },
  parentListMember: {
    '& .MuiTabs-indicator': {
      height: 2
    }
  }
}))

export default useStyles