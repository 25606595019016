import { useHistory } from 'react-router-dom'
import moment from 'moment'

// COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// CONSTANTS
import { basePaths } from 'constants/paths'
import { colors } from 'constants/colors'

// LIBRARY
import parseHTML from 'html-react-parser'

// MUIS
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconCheckCircleOutline from '@mui/icons-material/CheckCircleOutline'
import IconDateRange from '@mui/icons-material/DateRange'
import IconDelete from '@mui/icons-material/Delete'
import IconEdit from '@mui/icons-material/Edit'
import IconErrorOutline from '@mui/icons-material/ErrorOutline'
import IconKey from 'assets/images/pathIcons/IconKey'
import IconSubject from '@mui/icons-material/Subject'
import IconPhoneAndroid from '@mui/icons-material/PhoneAndroid'
import IconTextFields from '@mui/icons-material/TextFields'
import IconWysiwyg from '@mui/icons-material/Wysiwyg'

// PATH ICONS
import IconCircleAround from 'assets/images/pathIcons/IconCircleAround'
import IconMapPin from 'assets/images/pathIcons/IconMapPin'
import IconRealtime from 'assets/images/pathIcons/IconRealtime'

// STYLES
import useStyles from './flyoutDispatchEvidencesDetailUseStyles'
import useStylesPage from '../dispatchEvidencesUseStyles'

// UTILS
import { getColorCategoryDispatch, getColorStatusDispatch, getTextCategoryDispatch, getTextStatusDispatch } from '../dispatchEvidencesUtils'

const FlyoutDispatchEvidencesDetail = (props) => {
  const { itemDetail, setIsFullViewShown, setDialogDeleteEvidence } = props
  const history = useHistory()
  const classes = useStyles()
  const pageClasses = useStylesPage()

  const itemRows = [
    {
      Icon: IconKey,
      label: 'ID',
      value: (
        <Chip
          variant='outlined'
          className={`${pageClasses.columnChipFilled} ${pageClasses.columnChipId}`}
          label={itemDetail?.dispatchNo}
        />
      )
    },
    {
      Icon: IconCircleAround,
      label: 'Status',
      value: (
        <Stack direction='row' alignItems='center'>
          <Box
            className={classes.circleStatus}
            sx={{
              backgroundColor: getColorStatusDispatch(itemDetail?.status?.toLowerCase())
            }}
          ></Box>

          <Typography className={pageClasses.columnTextStatus} variant='body2'>
            {getTextStatusDispatch(itemDetail.status?.toLowerCase())}
          </Typography>
        </Stack>
        
      )
    },
    {
      Icon: IconPhoneAndroid,
      label: 'Perangkat',
      value: (
        <Chip
          className={pageClasses.columnChipFilled}
          icon={<IconPhoneAndroid fontSize='small' />}
          label={itemDetail?.deviceLabel}
          sx={{ backgroundColor: colors.raven }}
        />
      )
    },
    {
      Icon: IconWysiwyg,
      label: 'Kategori',
      value: (
        <Chip
          variant='outlined'
          className={pageClasses.columnChipOutlined}
          icon={itemDetail?.category?.toLowerCase() === 'priority'
            ? <IconErrorOutline fontSize='small'/>
            : <IconCheckCircleOutline fontSize='small'/>
          }
          label={getTextCategoryDispatch(itemDetail?.category?.toLowerCase())}
          sx={{
            borderColor: getColorCategoryDispatch(itemDetail?.category?.toLowerCase()),
            color: getColorCategoryDispatch(itemDetail?.category?.toLowerCase()),
            '& .MuiSvgIcon-root': {
              color: getColorCategoryDispatch(itemDetail?.category?.toLowerCase()),
            }
          }}
        />
      )
    },
    {
      Icon: IconTextFields,
      label: 'Judul',
      value: <Typography variant='caption' fontWeight={600}>{itemDetail?.label}</Typography>
    },
    {
      Icon: IconDateRange,
      label: 'Tanggal Mulai',
      value: <Typography variant='caption'>{moment(itemDetail?.startTime).format('DD MMM yyyy - HH:mm')}</Typography>
    },
    {
      Icon: IconDateRange,
      label: 'Tanggal Akhir',
      value: <Typography variant='caption'>{moment(itemDetail?.endTime).format('DD MMM yyyy - HH:mm')}</Typography>
    },
    {
      Icon: IconMapPin,
      label: 'Lokasi',
      value: <Typography variant='caption'>{itemDetail?.address?.fullAddress}</Typography>
    },
    {
      Icon: IconRealtime,
      label: 'Aktual',
      value: itemDetail?.acceptedTime ? (
        <>
          <Typography display='block' variant='caption'>{moment(itemDetail?.acceptedTime).format('DD MMM yyyy - HH:mm')}</Typography>
          <Typography display='block' variant='caption'>{itemDetail?.address?.fullAddress}</Typography>
        </>
      ) : '-'
    }
  ]

  return (
    <Stack className={classes.root}>
      {/* HEADER */}
      <Stack className={classes.headerFlyout}>
        <Typography className={classes.headerTitle} variant='h6'>
          Detail Penugasan
        </Typography>

        <Stack direction='row' flexWrap='nowrap'>
          <CustomTooltipBlack title='Ubah' placement='bottom'>
            <IconButton onClick={() => history.push(`${basePaths.dispatchEvidences}?mode=edit&id=${itemDetail?.dispatchNo}`, {
              mode: 'edit',
              data: itemDetail
            })}>
              <IconEdit />
            </IconButton>
          </CustomTooltipBlack>

          <CustomTooltipBlack title='Hapus' placement='bottom'>
            <IconButton
              onClick={() => setDialogDeleteEvidence(itemDetail)}
              disabled={itemDetail.status !== 'ASSIGNED'}
            >
              <IconDelete />
            </IconButton>
          </CustomTooltipBlack>
        </Stack>
      </Stack>

      <Divider />

      <Stack className={classes.contentFlyout}>
        <Stack>
          {itemRows.map((item, index) => {
            const { Icon, label, value } = item
            return (
              <Stack key={index} className={classes.itemDetail} marginBottom='16px'>
                <Icon className={classes.labelIcon} fontSize='small' />

                <Stack paddingLeft='12px'>
                  <Typography className={classes.labelTitle}>{label}</Typography>

                  <Stack direction='column' justifyContent='center'>
                    {value}
                  </Stack>
                </Stack>
              </Stack>
            )
          })}

          {/* DESKRIPSI */}
          <Stack className={classes.itemDetail}>
            <IconSubject className={classes.labelIcon} />

            <Stack paddingLeft='12px' width='100%'>
              <Typography className={classes.labelTitle}>Deskripsi</Typography>

              {/* CONTENT */}
              <Stack className={classes.contentBoxDescription} onClick={() => setIsFullViewShown(true)}>
                <Typography
                  className={classes.titleBoxDescription}
                  variant='h5'
                >{itemDetail?.label}</Typography>

                {itemDetail?.description && parseHTML(itemDetail?.description)}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default FlyoutDispatchEvidencesDetail