import React from 'react'
import ReactDOMServer from 'react-dom/server'

// CONSTANTS
import { colors } from 'constants/colors'

// LEAFLET
import L from 'leaflet'
import '../L.MarkerEvidence'

// MUIS
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconLocationOn from '@mui/icons-material/LocationOn'
import IconPhoneAndroid from '@mui/icons-material/PhoneAndroid'
import IconSos from '@mui/icons-material/Sos'
import IconTask from '@mui/icons-material/Task'
import IconTextFields from '@mui/icons-material/TextFields'

// UTILITIES
import { getEvidenceStatusColor } from 'utilities/colors'
import { panelsInfoStatic } from '../PanelUnifiedMapInformation/panelInformationIntegratedConstant'
import { getColorStatusDispatch, getTextStatusDispatch } from 'pages/DispatchEvidences/dispatchEvidencesUtils'

export const MarkerEvidence = (evidenceData, setIsEvidencesPanelShown, classes, markerType, selectedMarkerId, markerColor) => {
  const generateColorMarker = () => {
    if (markerType === 'SOS') return colors.scarlet
    else if (markerType === 'evidence') return getEvidenceStatusColor(evidenceData.status)
    else if (markerType === 'dispatch') return colors.amber
  }

  const instance = L.markerEvidence(
    [
      parseFloat(evidenceData?.latitude ?? evidenceData?.address?.latitude),
      parseFloat(evidenceData?.longitude ?? evidenceData?.address?.longitude)
    ], {
      icon: L.divIcon({
        className: 'custom-icon',
        html: ReactDOMServer.renderToString(
          <Box
            style={{
              width: evidenceData?.sosStatus ? 14 : 20,
              height: evidenceData?.sosStatus ? 14 : 20,
              borderRadius: '50%',
              border: !evidenceData?.sosStatus ? '2px solid white' : 'none',
              backgroundColor: markerColor ? markerColor : generateColorMarker(markerType),
              position: 'relative'
            }}
            className='zoom'
          >
            <Box id='parent-element' />
            {markerType === 'dispatch' ? <IconTask className={classes.iconMarkerCenter} /> : ''}
          </Box>
        ),
        iconSize: [evidenceData?.sosStatus ? 14 : 20, evidenceData?.sosStatus ? 14 : 20]
      }),
      objectData: evidenceData,
      htmlAnimation: ReactDOMServer.renderToString(
        <>
          <Stack
            className={classes.circle}
            style={{
              height: `${evidenceData?.sosStatus ? 14 : 20}px`,
              width: `${evidenceData?.sosStatus ? 14 : 20}px`,
              animationDelay: '0s'
            }}
          ></Stack>

          <Stack
            className={classes.circle}
            style={{
              height: `${evidenceData?.sosStatus ? 14 : 20}px`,
              width: `${evidenceData?.sosStatus ? 14 : 20}px`,
              animationDelay: '1s'
            }}
          ></Stack>

          <Stack
            className={classes.circle}
            style={{
              height: `${evidenceData?.sosStatus ? 14 : 20}px`,
              width: `${evidenceData?.sosStatus ? 14 : 20}px`,
              animationDelay: '2s'
            }}
          ></Stack>

          <Stack
            className={classes.circle}
            style={{
              height: `${evidenceData?.sosStatus ? 14 : 20}px`,
              width: `${evidenceData?.sosStatus ? 14 : 20}px`,
              animationDelay: '3s'
            }}
          ></Stack>

          <Stack
            className={classes.circle}
            style={{
              height: `${evidenceData?.sosStatus ? 14 : 20}px`,
              width: `${evidenceData?.sosStatus ? 14 : 20}px`,
              animationDelay: '4s'
            }}
          ></Stack>
        </>
      ),
      isAnimationActive: (markerType === 'SOS' && selectedMarkerId === evidenceData?.evidenceNo) ? true : false,
      type: 'evidence'
    })

  instance.bindTooltip(ReactDOMServer.renderToString(
    <>
      <Stack className={`${classes.itemTooltip} zoom`}>
        <IconPhoneAndroid className={classes.itemTooltipIcon} />

        <Typography className={classes.itemTooltipText}>
          {evidenceData?.deviceName ?? evidenceData?.deviceLabel}
        </Typography>

        {evidenceData?.sosStatus && <Chip className={classes.chipSOS} variant='filled' label={<IconSos />} />}

        {markerType === 'dispatch' && <Chip
          className={classes.chipStatus}
          variant='outlined'
          label={getTextStatusDispatch(evidenceData.status.toLowerCase())}
          style={{
            color: `${getColorStatusDispatch(evidenceData.status.toLowerCase())} !important`
          }}
        />}
      </Stack>

      {markerType === 'dispatch' && <Stack className={`${classes.itemTooltip} description zoom`}>
        <IconTextFields className={classes.itemTooltipIcon} />

        <Typography className={classes.itemTooltipText}>
          {evidenceData?.label ?? '-'}
        </Typography>
      </Stack>}

      <Stack className={`${classes.itemTooltip} description zoom`}>
        <IconLocationOn className={classes.itemTooltipIcon} />

        <Typography className={`${classes.itemTooltipText} description`}>
          {markerType === 'dispatch' ? evidenceData?.address?.fullAddress : evidenceData?.address}
        </Typography>
      </Stack>
    </>
  ), {
    direction: 'top',
    className: 'tooltip-marker-clear',
    offset: L.point(0, -28)
  })

  instance.on('click', (event) => {
    if (markerType !== 'dispatch') setIsEvidencesPanelShown({
      ...panelsInfoStatic.panelEvidenceDetail,
      data: evidenceData,
      currentPanel: 'Kejadian'
    })
    else if (markerType === 'dispatch') setIsEvidencesPanelShown({
      ...panelsInfoStatic.panelDispatchDetail,
      data: evidenceData,
    })
  })

  instance.on('mouseover', event => {
    const itemElement = event.target._map._container.parentElement?.querySelector(`[dataid="${markerType !== 'dispatch' ? evidenceData?.evidenceNo : evidenceData?.dispatchNo}"`)
    if (itemElement) {
      itemElement.style.backgroundColor = colors.linkWater
    }
  })

  instance.on('mouseout', event => {
    const itemElement = event.target._map._container.parentElement?.querySelector(`[dataid="${markerType !== 'dispatch' ? evidenceData?.evidenceNo : evidenceData?.dispatchNo}"`)
    if (itemElement) {
      itemElement.style.backgroundColor = 'transparent'
    }
  })

  return instance
}

export default MarkerEvidence