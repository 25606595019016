// MUI STYLES
import { makeStyles } from '@mui/styles'


const useStyles = makeStyles(({
  root: {
    borderRadius: '50%',
    position: 'relative',
    backgroundColor: '#EF6C00CC',
  },
  circle: {
    borderRadius: '50%',
    position: 'absolute',
    opacity: 0,
    border: '1px solid #EF6C00',
    animation: '$markerClusterScaleIn 4.8s infinite',
  },
  '@keyframes markerClusterScaleIn': {
    'from': {
      transform: 'scale(1, 1)',
      opacity: 1
    },
    'to': {
      transform: 'scale(2.2, 2.2)',
      opacity: 0,
    }
  },
}))

export default useStyles