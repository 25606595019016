import React, { useState, useEffect, useRef, useContext } from 'react'
import { useParams } from 'react-router-dom'

// MUIS
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconComment from '@mui/icons-material/Comment'
import IconMic from '@mui/icons-material/Mic'
import IconMicOff from '@mui/icons-material/MicOff'
import IconVideocam from '@mui/icons-material/Videocam'
import IconVideocamOff from '@mui/icons-material/VideocamOff'
import IconCallEnd from '@mui/icons-material/CallEnd'

// STYLES
import useStyles from './agentHelpMeetUseStyles'

// AGORA
import AgoraRTC from 'agora-rtc-sdk-ng'

// SERVICE
import { getAgentHelpList } from 'services/AgentHelpServices'

// CONTEXT
import { AllPagesContext } from 'contexts/AllPagesContext'

const Room = (props) => {
  const {
    isMicOn,
    setIsMicOn,
    isCameraOn,
    setIsCameraOn,
    setDialogEndShown,
  } = props

  const {agentHelpAllData} = useContext(AllPagesContext)
  
  const [listData, setListData] = useState([])

  const classes = useStyles()
  const params = useParams()
  const channel = params.meetId

  const videoElementRef = useRef()
  const computerVideoRef = useRef()
  const computerVideoTrackRef = useRef()
  const computerMicTrackRef = useRef()
  const videoTrackRef = useRef()
  const audioTrackRef = useRef()

  const agoraAppId = process.env.REACT_APP_AGORA_APP_ID
  const randomId = Math.floor(Math.random() * 9999)
  const agoraRTCRef = useRef(
    AgoraRTC.createClient({
      mode: 'rtc',
      codec: 'vp8',
    })
  )

  const closeTab= ()=>{
    window.opener = null
    window.open('', '_self')
    window.close()
  }

  const joinRtcChannel = async () => {
    const rtcTokenResponse = await fetch(
      `${process.env.REACT_APP_API_MOBILE_URL}/devices/generate-agora-token?deviceCode=${channel}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: { deviceCode: channel },
      }
    )

    const rtcTokenResponseJson = await rtcTokenResponse.json()
    const rtcToken = rtcTokenResponseJson.token

    agoraRTCRef.current
      .join(agoraAppId, channel, rtcToken, randomId)
      .then(async () => {
        const [microphoneTrack, cameraTrack] = await AgoraRTC.createMicrophoneAndCameraTracks()
        await agoraRTCRef.current.publish([cameraTrack, microphoneTrack])
        cameraTrack.play(computerVideoRef.current)
        computerVideoTrackRef.current = cameraTrack
        computerMicTrackRef.current = microphoneTrack
        if(!isCameraOn){
          await computerVideoTrackRef.current.setEnabled(false)
        } else{ await computerVideoTrackRef.current.setEnabled(true)}
        if(!isMicOn){
          computerMicTrackRef.current.setEnabled(false)
        } else computerMicTrackRef.current.setEnabled(true)
        
        let remoteUser = agoraRTCRef.current.remoteUsers[0]
        if (remoteUser) {
          if (remoteUser.hasVideo) {
            agoraRTCRef.current
              .subscribe(remoteUser, 'video')
              .then((track) => {
                videoTrackRef.current = track
                track.play(videoElementRef.current)
              })
          }

          if (remoteUser.hasAudio) {
            agoraRTCRef.current
              .subscribe(remoteUser, 'audio')
              .then((track) => {
                track.play()
                audioTrackRef.current = track
              })
          }
        }
      })

    agoraRTCRef.current.on('user-published', async (user, mediaType) => {
      if (mediaType === 'video') {
        agoraRTCRef.current.subscribe(user, 'video').then((track) => {
          videoTrackRef.current = track
          track.play(videoElementRef.current)
        })
      }
      if (mediaType === 'audio') {
        agoraRTCRef.current.subscribe(user, 'audio').then((track) => {
          track.play()
          audioTrackRef.current = track
        })
      }
    })
  }

  useEffect(() => {
    
    joinRtcChannel()

    return async () => {
      await agoraRTCRef.current.unpublish()
      
      computerVideoTrackRef.current.close()
      computerMicTrackRef.current.close()
      videoTrackRef.current?.stop()
      audioTrackRef.current?.stop()

      await agoraRTCRef.current.removeAllListeners()
      await agoraRTCRef.current.leave()
    }
  }, [])

  useEffect(()=>{
    return async () => {
      if(isCameraOn){
        computerVideoTrackRef.current.setEnabled(false)
      } else computerVideoTrackRef.current.setEnabled(true)
    }
  },[isCameraOn])

  useEffect(()=>{
    return async () => {
      if(isMicOn){
        computerMicTrackRef.current.setEnabled(false)
      } else computerMicTrackRef.current.setEnabled(true)
    }
  },[isMicOn])

  useEffect(() => {
    const loadAgentHelpList = () => {
      getAgentHelpList({
        page: 0,
        size: 1000,
      }).then((response) => {
        setListData(
          response.rows.filter(
            (item) => item.channelId === params.meetId
          )
        )
      })
    }
    loadAgentHelpList()
  }, [params,agentHelpAllData])

  useEffect(() => {
    return async () => {
      if(listData.length < 1){
        await agoraRTCRef.current.unpublish()
      
        computerVideoTrackRef.current.close()
        computerMicTrackRef.current.close()
        videoTrackRef.current?.stop()
        audioTrackRef.current?.stop()

        await agoraRTCRef.current.removeAllListeners()
        await agoraRTCRef.current.leave()
        closeTab()
      }
    }
  }, [listData])
  
  return (
    <Box className={classes.content}>
      {/* SOMEONE VIDEO CONTAINER */}
      <Box className={`${classes.roomVideoContainer} ${classes.roomSomeoneVideoContainer}`}>
        {/* SOMEONE VIDEO */}
        <Box
          ref={(el) => (videoElementRef.current = el)}
          className={classes.video}
        />

        {/* SOMEONE NAME */}
        <Typography
          variant='subtitle1'
          className={classes.roomSomeoneName}
        >
          {listData[0]?.deviceLabel}
        </Typography>

        {/* USER VIDEO CONTAINER */}
        <Box className={`${classes.roomVideoContainer} ${classes.roomUserVideoContainer}`}>
          {/* USER VIDEO */}
          <Box
            ref={(el)=>(computerVideoRef.current = el)}
            className={classes.video}
          />

          {/* USER MIC ICON */}
          {isMicOn ? 
            <IconMic className={classes.roomUserIconMic}/> : 
            <IconMicOff className={classes.roomUserIconMic}/>}
        </Box>
      </Box>

      {/* USER ACTIONS */}
      <Box className={classes.roomUserActionsContainer}>
        {/* MIC ICON */}
        <IconButton 
          className={classes.actionIconButton}
          onClick={() => setIsMicOn(current => !current)}
        >
          {isMicOn ? <IconMic/> : <IconMicOff/>}
        </IconButton>

        {/* VIDEO ICON */}
        <IconButton 
          className={classes.actionIconButton}
          onClick={() => setIsCameraOn(current => !current)}
        >
          {isCameraOn ? <IconVideocam/> : <IconVideocamOff/>}
        </IconButton>

        {/* COMMENT ICON 
        <IconButton className={classes.actionIconButton}>
          <IconComment/>
        </IconButton>*/}

        {/* DIVIDER */}
        <Box className={classes.roomActionsDivider}/>

        {/* END BUTTON */}
        <Button
          variant='contained'
          className={classes.roomActionsButton}
          startIcon={<IconCallEnd/>}
          onClick={() => setDialogEndShown(true)}
        >
          Akhiri
        </Button>
      </Box>
    </Box>
  )
}

export default Room