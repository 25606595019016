import { useCallback, useContext, useEffect, useState } from 'react'

// COMPONENTS
import ConversationItem from '../ConversationItem/ConversationItem'

// CONTEXTS
import { PageChatContext } from 'contexts/PageChatContext'

// MUIS
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import List from '@mui/material/List'
import OutlinedInput from '@mui/material/OutlinedInput'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

// MUI ICONS
import IconSearch from '@mui/icons-material/Search'

// STYLES
import useStyles from './dialogAddDeviceUseStyles'

const DialogAddDevice = (props) => {
  const {
    setDialogOpen, selectedDevices, setSelectedDevices, memberList, isDialogDetailGroupShown, onPage
  } = props
  const classes = useStyles()
  const { friendList, conn, fetchFriendList } = useContext(PageChatContext)

  // STATES
  const [search, setSearch] = useState('')
  const [availableFriendList, setAvailableFriendList] = useState([])
  const [prevSelectedDevices, setPrevSelectedDevices] = useState(selectedDevices)

  const checkIsCheckedDevices = (inputDeviceNo) => {
    const findDevices = selectedDevices.find(item => item.id === inputDeviceNo)
    return Boolean(findDevices)
  }

  const handleCheckedItem = (inputItem) => {
    let tempSelectedDevices = [...selectedDevices]

    if (!checkIsCheckedDevices(inputItem.id)) {
      tempSelectedDevices.push(inputItem)
    } else {
      tempSelectedDevices = tempSelectedDevices.filter(item => item.id !== inputItem.id)
    }

    setSelectedDevices([...tempSelectedDevices])
  }

  const handleCancelClick = () => {
    setSelectedDevices(prevSelectedDevices)
    setPrevSelectedDevices([])
    setDialogOpen(false)

  }

  const handleSaveClick = () => {
    if (onPage === 'newGroup') {
      setPrevSelectedDevices([])
      setDialogOpen(false)
    } else if (onPage === 'inviteMember') {
      const idList = selectedDevices.map(item => item.username)
      if (idList.length && isDialogDetailGroupShown?.groupid) {
        conn?.inviteUsersToGroup({
          groupId: isDialogDetailGroupShown?.groupid,
          users: idList
        })
          .then(async () => {
            await fetchFriendList()
            setPrevSelectedDevices([])
            setDialogOpen(false)
          })
      }
    }
  }

  const fetchAvailableFriendList = useCallback(() => {
    if (friendList?.length) {
      setAvailableFriendList(friendList.filter((item) => {
        const hasJoined = memberList?.find(itemFind => itemFind.userId === item.username)
        return Boolean(hasJoined) ? false : true
      }))
    }
  }, [memberList, friendList])

  useEffect(() => {
    fetchAvailableFriendList()
  }, [fetchAvailableFriendList])

  return (
    <Stack className={classes.root}>
      <Stack className={classes.dialogMenuHeader}>
        <Typography variant='subtitle1' className={classes.dialogMenuTitleHeader}>Pilih Perangkat</Typography>
                      
        <FormControl>
          <OutlinedInput
            className={classes.inputDevicesSearch}
            placeholder='Pencarian'
            endAdornment={
              <InputAdornment position='end'>
                <IconSearch />
              </InputAdornment>
            }
            value={search}
            onChange={event => setSearch(event.target.value)}
          />
        </FormControl>
      </Stack>

      <Stack className={classes.content}>
        <List disablePadding>
          {availableFriendList
            .map((item, index) => ({ ...item, id: index+1 }))
            .filter(item => item.nickname.toLowerCase().includes(search.toLowerCase()))
            .map(item => (
              <ConversationItem
                key={item.id}
                isWithCheckbox
                isChecked={checkIsCheckedDevices(item.id)}
                onCheckboxChange={() => handleCheckedItem(item)}
                onItemClick={() => handleCheckedItem(item)}
                name={item.nickname}
                title={item.nickname}
                description={item.role}
                avatarSize='small'
              />
            ))}
        </List>
      </Stack>

      <Stack className={classes.footer}>
        <Button onClick={handleCancelClick} variant='text' className={`${classes.buttonDialog} btnCancel`}>BATAL</Button>
        <Button onClick={() => handleSaveClick()} variant='text' className={classes.buttonDialog}>PILIH</Button>
      </Stack>
    </Stack>
  )
}

export default DialogAddDevice