// MUI STYLES
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    position: 'relative',
    zIndex: 1,
    '&.leaflet-container': {
      width: '100%',
      height: '100%',
    },
  },
  controlWrapper: {
    position: 'absolute',
    top: 24,
    zIndex: 999,
    flexDirection: 'row',
    pointerEvents: 'none',
    transition: '.2s right'
  },
  controlIconButton: {
    pointerEvents: 'auto',
    marginRight: 16,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.secondary,
    boxShadow: `0 0 10px ${alpha(theme.palette.common.black, 0.16)}`,
    borderRadius: 4,
    height: 40,
    '&.buttonListDashboard': {
      padding: '0 24px',
      marginRight: 0,
      marginLeft: 16,
      borderRadius: 2,
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  panelMenuContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    position: 'absolute',
    top: 48,
    right: 0,
    zIndex: 4,
    width: 'auto',
    pointerEvents: 'none',
  },
  panelRightWrapper: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer,
    top: 24,
    right: 24,
    bottom: 24,
  }
}))

export default useStyles