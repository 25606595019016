import React, { useState, useEffect, useContext} from 'react'

// COMPONENTS
import ContentTabs from 'components/ContentTabs/ContentTabs'
import VideoCallItem from 'components/VideoCallItem/VideoCallItem'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Grid from '@mui/material/Grid'

// STYLES
import useStyles from './conferenceUseStyles'

// SERVICE 
import {getConferenceList, putConferenceStop} from 'services/ConferenceService'
import {putConferenceJoin} from 'services/ConferenceService'

// UTILS
import { getUser } from 'utilities/auth'

const Conference = () => {
  const classes = useStyles()
  const userStorage = getUser()
  const {conferenceList, setConferenceList} = useContext(AllPagesContext)
  
  const tabItemList = [
    {
      label: 'Semua',
      value: 'Semua',
    },
    {
      label: 'Dalam Panggilan Video',
      value: 'Dalam Panggilan Video',
    },
  ]
  const inConferenceData = []

  const [selectedTab, setSelectedTab] = useState(tabItemList[0].value)
  const [updatedTabList, setUpdatedTabList] = useState([])

  const getSelectedList = () => {
    if (selectedTab === 'Semua') return conferenceList
    else if (selectedTab === 'Dalam Panggilan Video') return inConferenceData
  }

  const handleJoinButtonClick = async (inputItem, inputIndex) => {
    const bodyParams = {
      active: inputItem.active,
      channelId: inputItem.channelId ,
      hostUid:inputItem.hostUid,
      id: inputItem.id,
      type: inputItem.type,
      totalParticipant: 0,
    }

    localStorage.setItem('ownerRoomDetail', JSON.stringify(bodyParams))
    localStorage.setItem('meetRoomClosed', JSON.stringify(false))
    localStorage.setItem('isUserJoinRoomMeet', JSON.stringify(true))

    const resultJoinConference = await putConferenceJoin(bodyParams)

    if(resultJoinConference.status !== 'error'){
      const newWindow = window.open(
        `${window.location.origin}/live/conference?id=${inputItem.channelId}&typeUser=${userStorage.email === inputItem.channelId ? 'owner' : 'participant'}`,
        '_blank',
        'noopener,noreferrer'
      )
      if (newWindow) newWindow.opener = null
    }

    await loadConferenceList()
  }

  const handleStopMeet = async (params) => {
    localStorage.setItem('isUserJoinRoomMeet', JSON.stringify(false))
    localStorage.setItem('ownerRoomDetail', JSON.stringify({}))
    localStorage.setItem('meetRoomClosed', JSON.stringify(false))

    if (Object.keys(params)?.length) {
      await putConferenceStop(params)
    }
  }

  const loadConferenceList = () => {
    getConferenceList().then((response)=>{
      setConferenceList(response)
    })
  }

  useEffect(() => {
    const videoLengthList = [
      conferenceList.length,
      inConferenceData.length,
    ]
    const newTabList = tabItemList.map((item, index) => {
      return {
        ...item,
        label: `${item.label} (${videoLengthList[index]})`,
      }
    })
    setUpdatedTabList(newTabList)
  }, [conferenceList])

  useEffect(() => {
    loadConferenceList()

    window.addEventListener('storage', () => {
      const ownerRoomDetail = JSON?.parse(localStorage.getItem('ownerRoomDetail')) || {}
      loadConferenceList()

      // MEET WILL STOP WHEN NO HAVE PARTICIPANT
      if(JSON.parse(localStorage.getItem('meetRoomClosed')) && ownerRoomDetail?.totalParticipant === 0) {
        handleStopMeet(ownerRoomDetail)
      }
    })
  }, [])

  return (
    <>
      {/* CONTENT TABS */}
      <ContentTabs
        tabItemList={updatedTabList}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />

      {/* CONFERENCE LIST */}
      <Grid container className={classes.conferenceGrid}>
        {getSelectedList()?.map((item, index) => (
          <Grid
            key={index}
            item
            xs={3}
            className={classes.conferenceItemPadding}
          >
            <VideoCallItem
              data={item}
              onJoinButtonClick={() =>
                handleJoinButtonClick(item, index)
              }
              type='conference'
              customDisabledBtn={() => JSON.parse(localStorage.getItem('isUserJoinRoomMeet')) ? true : false}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default Conference