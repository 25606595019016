import { useContext, useEffect, useRef, useState } from 'react'
import * as ReactDOMServer from 'react-dom/server'

// COMPONENTS
import MarkerCluster from '../MarkerCluster/MarkerCluster'
import MarkerEvidence from '../MarkerEvidence/MarkerEvidence'

// CONTEXTS
import { PageAnalyticsContext } from 'contexts/PageAnalyticsContext'

// LEAFLET
import L from 'leaflet'

// MUIS
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

// STYLES
import useStyles from './clusteringUseStyles'

const Clustering = () => {
  const classes = useStyles()
  const { map, dataEvidences } = useContext(PageAnalyticsContext)
  const layerGroupMarkerRef = useRef(L.layerGroup([]))
  const theme = useTheme()

  const [dataListMarker, setDataListMarker] = useState([])
  const [dataMarkers, setDataMarkers] = useState([])

  const algorithmFilteringNearestMarker = () => {
    let tempDataListMarker = [...dataListMarker]

    dataListMarker.forEach((itemDataListMarker) => {
      const isPartOfNull = tempDataListMarker.find(itemTempDataListMarker => itemTempDataListMarker.partOf === null)

      if(Boolean(isPartOfNull)) {
        tempDataListMarker.forEach((itemTempDataListMarker, indexTempDataListMarker) => {
          // IN METERS
          const distance = L.latLng(parseFloat(itemDataListMarker.latitude), parseFloat(itemDataListMarker.longitude))
            .distanceTo(L.latLng(parseFloat(itemTempDataListMarker.latitude), parseFloat(itemTempDataListMarker.longitude)))

          // IF DISTANCE <= 2000 METERS, ADD partOf
          if(distance <= 2000) {
            tempDataListMarker[indexTempDataListMarker].partOf = itemDataListMarker.id
          }
        })
      }
    })

    tempDataListMarker.forEach((itemTempDataListMarker, index) => {
      const totalPartOf = tempDataListMarker.filter(itemFilter => itemFilter.partOf === itemTempDataListMarker.id)
      tempDataListMarker[index].parent = Boolean(totalPartOf.length)
      tempDataListMarker[index].totalChild = Number(totalPartOf.length)
    })
    setDataMarkers([...tempDataListMarker])
  }

  const iconMarker = (type, index, totalParent) => {
    let calculateSize
    const ratioMin = 16 // PX
    const ratioMax = 64 // PX

    if (index === 0) {
      calculateSize = ratioMin
    } else if (index === totalParent - 1) {
      calculateSize = ratioMax
    } else if (index > 0 && index < totalParent - 1) {
      // RATIO FORMULA
      const step = (ratioMax - ratioMin) / (totalParent - 1)
      calculateSize = ratioMin + index * step
    }

    if (type === 'top5') {
      return L.divIcon({
        className: 'icon-marker',
        html: ReactDOMServer.renderToString(
          <MarkerCluster calculateSize={calculateSize} />
        ),
        iconSize: [calculateSize, calculateSize]
      })
    } else if (type === 'normal') {
      return L.divIcon({
        className: 'icon-marker',
        html: ReactDOMServer.renderToString(
          <MarkerEvidence calculateSize={calculateSize} />
        ),
        iconSize: [calculateSize, calculateSize]
      })
    }
  }

  const typeClusterChecker = (index, totalParent) => {
    if (totalParent <= 5) return 'top5'
    else if (totalParent > 5) {
      return (index + 1) > (totalParent - 5) ? 'top5' : 'normal'
    }
  }
  
  const addMarkersToMap = () => {
    layerGroupMarkerRef.current.clearLayers()

    if(dataMarkers.length) {
      dataMarkers
        .filter(item => item.parent)
        .sort((a, b) => a.totalChild - b.totalChild)
        .forEach((item, index) => {
          const totalParent = dataMarkers.filter(item => item.parent)?.length
          const marker = L.marker([parseFloat(item.latitude), parseFloat(item.longitude)], {
            icon: iconMarker(typeClusterChecker(index, totalParent), index, totalParent)
          }).bindTooltip(
            ReactDOMServer.renderToString(
              <Stack
                className={classes.tooltipClustering}
                style={{
                  borderColor: typeClusterChecker(index, totalParent) === 'top5' ? theme.palette.primary.main : '#724603B2'
                }}
              >
                <Typography>{typeClusterChecker(index, totalParent) === 'top5' ? 'Top 5' : 'Normal'}</Typography>

                <Stack className={classes.tooltipContent}>
                  <Box
                    className={classes.tooltipCircle}
                    style={{
                      backgroundColor: typeClusterChecker(index, totalParent) === 'top5' ? theme.palette.primary.main : '#724603'
                    }}
                  ></Box>
                  <Typography className={classes.tooltipEvidenceText}>Kejadian</Typography>
                  <Typography className={classes.tooltipEvidenceNumber}>{item.totalChild}</Typography>
                </Stack>
              </Stack>
            ), {
              className: 'customBindTooltipClustering',
              offset: L.point(
                item.totalChild >= 64 ? 64/2 : (16 + item.totalChild) / 2,
                item.totalChild >= 64 ? (64 + 70)/2 : ((16 + 70) + item.totalChild) / 2
              )
            }
          )

          layerGroupMarkerRef.current.addLayer(marker).addTo(map)
        })
    }
  }

  // UPDATE ZOOM & BOUNDS
  useEffect(() => {
    map && algorithmFilteringNearestMarker()
  }, [dataListMarker])

  useEffect(() => {
    setDataListMarker(dataEvidences.map(item => ({...item, partOf: null, parent: false, totalChild: 0 })))
  }, [dataEvidences])

  useEffect(() => {
    addMarkersToMap()
  }, [dataMarkers])

  return <></>
}

export default Clustering