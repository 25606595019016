// CONSTANT
import { colors } from 'constants/colors'

export const initialMapCenter = {
  lat: -0.7893,
  lng: 113.9213,
}

export const mapContainerStyle = {
  width: '100%',
  height: 412,
  borderRadius: 2,
  flex: '0 0 280px',
}

export const circleOptions = {
  fillColor: colors.clementine,
  fillOpacity: 0.3,
  strokeWeight: 2,
  strokeColor: colors.clementine,
  clickable: false,
  editable: true,
  draggable: true,
  zIndex: 1,
}