import { useState, useRef } from 'react'

// ASSETS
import PictureBrokenVideo from 'assets/images/pictures/broken-video.svg'

// MUIS
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

// MUI ICONS
import IconPlayCircleFilledOutlined from '@mui/icons-material/PlayCircleFilledOutlined'

// STYLES
import useStyles from './dialogEvidenceDetailUseStyles'

const MediaVideo = (props) => {
  const { mediaItem } = props

  const classes = useStyles()

  const videoRef = useRef()

  const [ source, setSource ] = useState(mediaItem.mediaUrl)

  return (
    <Stack
      position='relative'
      height='100%'
      width='100%'
      justifyContent='center'
      alignItems='center'
    >
      {/* PLAY ICON */}
      {source &&
      <IconPlayCircleFilledOutlined className={classes.mediaItemIcon}/>}

      {/* VIDEO */}
      <Box
        ref={videoRef}
        component={source ? 'video' : 'img'}
        src={source ? source : PictureBrokenVideo}
        autoPlay={false}
        controls={false}
        alt=''
        onError={() => setSource(PictureBrokenVideo)}
        className={source ? classes.mediaItemVisual : classes.mediaItemBrokenIcon}
      />
    </Stack>
  )
}

export default MediaVideo