import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// REDUX
import { useDispatch } from 'react-redux'

// COMPONENTS
import DialogAddOrEdit from 'components/DialogAddOrEditNew/DialogAddOrEdit'
import InputLocation from 'components/InputLocation/InputLocation'
import SimpleBackdrop from 'components/Partials/SimpleBackdrop'

// CONSTANTS
import { basePaths } from 'constants/paths'

// CUSTOM COMPONENTS
import CustomInput from 'components/Customs/CustomInput'
import CustomInputLabel from 'components/Customs/CustomInputLabel'

// MUIS
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconFormatColorText from '@mui/icons-material/FormatColorText'

// SERVICES
import {AddNewPlace, updatePlace} from 'services/PlacesService'

// STORES
import { showAlert } from 'store/MainReducer'

// STYLES
import useStyles from './dialogAddOrEditSettingsPlacesUseStyles'

const DialogAddOrEditSettingsPlaces = (props) => {
  const {
    dialogType,
    dialogAddSettingsPlaces,
    setDialogAddSettingsPlaces,
    reloadData,
    pageRef,
  } = props

  const classes = useStyles()
  const dispatch = useDispatch()

  const history = useHistory()
  const location = useLocation()
  
  const [placeName, setPlaceName] = useState('')
  const [circle, setCircle] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [circleRadius, setCircleRadius] = useState(150)
  const [placeLocation, setPlaceLocation] = useState('')
  const [currentLatLng, setCurrentLatLng] = useState(null)

  const handleDialogParamsChange = (event) => {
    setPlaceName(event.target.value)
  }

  const closeDialog = () => {
    setPlaceName('')
    setCircle(null)
    setPlaceLocation('')
    setCircleRadius(150)
    setDialogAddSettingsPlaces(null)
  }

  const handleSaveBtn = async () => {
    setIsLoading(true)
    
    if(dialogType === 'add') {
      const bodyParams = {
        name: placeName,
        zone: {
          address: placeLocation,
          area: `CIRCLE (${circle?.center?.lat()} ${circle?.center?.lng()}, ${circleRadius})`,
          description: '',
          label: placeLocation,
        }
      }

      const result = await AddNewPlace(bodyParams)

      if(result.status !== 'error') {
        setIsLoading(false)
        dispatch(showAlert({ message: 'Sukses Membuat Lokasi' }))
        closeDialog()
        reloadData()
      }
    }

    if(dialogType === 'edit') {
      const bodyParams = {
        place_no: dialogAddSettingsPlaces.row.place_no,
        name: placeName,
        zone: {
          address: placeLocation,
          area: `CIRCLE (${circle?.center?.lat()} ${circle?.center?.lng()}, ${circleRadius})`,
          description: '',
          label: placeLocation,
          zone_no: 1,
          geofence_id: 0
        }
      }

      const result = await updatePlace(dialogAddSettingsPlaces.row.place_no, bodyParams)

      if(result.status !== 'error') {
        setIsLoading(false)
        dispatch(showAlert({ message: 'Sukses Memperbarui Lokasi' }))
        closeDialog()
        reloadData()
      }
    }
  }

  // SET DEFAULT VALUE WHEN ON EDIT MODE
  useEffect(() => {
    // EXAMPLE SPLIT RESULT: ['CIRCLE', '-8.7831169', '115.1853335', '150']
    // [type shape, lat, lng, radius]
    if(dialogType === 'edit' && dialogAddSettingsPlaces) {
      const getDataGeofence = dialogAddSettingsPlaces.row.zone.area
        .replace('(', '').replace(')', '').replace(',', '')
        .split(' ')

      setCurrentLatLng({
        lat: Number.parseFloat(getDataGeofence[1]),
        lng: Number.parseFloat(getDataGeofence[2])
      })

      setPlaceName(dialogAddSettingsPlaces.row.name)
      setCircleRadius(Number(getDataGeofence[3]))
      setPlaceLocation(dialogAddSettingsPlaces.row.zone.address)
    }
  }, [dialogType, dialogAddSettingsPlaces])
  
  useEffect(() => {
    if (
      location.pathname === basePaths.settingsPlaces && 
      location.search !== '?mode=add' &&
      !location.search.includes('?mode=edit')
    ) {
      closeDialog()
    }
  }, [location])

  let selectedTitle = ''
  if (dialogType === 'add') selectedTitle = 'Tambah Lokasi'
  else if (dialogType === 'edit') selectedTitle = 'Ubah Lokasi'

  return (
    <>
      {/* DIALOG ADD OR EDIT */}
      <DialogAddOrEdit
        open={Boolean(dialogAddSettingsPlaces)}
        title={selectedTitle}
        onCloseIconClick={() => history.push(basePaths.settingsPlaces)}
        containerRef={pageRef}
        contents={[
          <>
            {/* MAIN SECTION TEXT */}
            <Typography variant='h6' className={classes.sectionText}>
              Informasi Utama
            </Typography>

            {/* PLACES NAME INPUT */}
            <Box className={classes.iconAndFormControlContainer}>
              <IconFormatColorText className={classes.iconFormControl} />
              <FormControl variant='standard' className={classes.formControl}>
                <CustomInputLabel>Nama Lokasi</CustomInputLabel>
                <CustomInput
                  type='text'
                  name='placeName'
                  value={placeName}
                  onChange={handleDialogParamsChange}
                />
              </FormControl>
            </Box>

            {/* LOCATION INPUT */}
            <InputLocation
              location={placeLocation}
              setLocation={setPlaceLocation}
              circle={circle}
              setCircle={setCircle}
              radius={circleRadius}
              setRadius={setCircleRadius}
              inputName='Lokasi Departemen'
              latitudeProp={currentLatLng && currentLatLng.lat}
              longitudeProp={currentLatLng && currentLatLng.lng}
            />
          </>
        ]}
        onCancelButtonClick={() => history.push(basePaths.settingsPlaces)}
        onSaveButtonClick={handleSaveBtn}
      />

      {/* LOADING */}
      <SimpleBackdrop isLoading={isLoading} />
    </>
  )
}

export default DialogAddOrEditSettingsPlaces
