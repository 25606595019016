// MUI STYLES
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  headerTab: {
    fontWeight: 600,
    '&.MuiTab-root.Mui-selected': {
      color: theme.palette.text.primary,
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
    },
  },
}))

export default useStyles