import { useState } from 'react'

// COMPONENTS
import Flyout from 'components/Flyout/Flyout'
import TabChannels from './TabChannels'
import TabInformation from './TabInformation'

// MUIS
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './flyoutDeviceUseStyles'

const FlyoutDevice = (props) => {
  const {
    isDeviceFlyoutShown,
    flyoutWidth,
    deviceSelectionModel,
    selectedDevice,
    setDialogAssignChannels,
  } = props

  const classes = useStyles()

  const tabList = [
    {
      value: 0,
      label: 'Informasi',
    },
    {
      value: 1,
      label: 'Daftar Kanal',
    },
  ]

  const [ selectedTab, setSelectedTab ] = useState(tabList[0].value)

  return (
    <Flyout
      isFlyoutShown={isDeviceFlyoutShown} 
      flyoutWidth={flyoutWidth}
    >
      {selectedDevice ? (
        <Stack 
          position='relative'
          maxHeight='100%'
        >
          {/* TABS */}
          <Tabs 
            value={selectedTab} 
            onChange={(event, newValue) => setSelectedTab(newValue)}
            className={classes.headerTabs}
          >
            {tabList.map((item, index) => (
              <Tab 
                key={index}
                label={item.label}
                className={classes.headerTab}
              />
            ))}
          </Tabs>

          {/* INFORMATION TAB */}
          {selectedTab === tabList[0].value && 
          <TabInformation selectedDevice={selectedDevice}/>}

          {/* CHANNELS TAB */}
          {selectedTab === tabList[1].value && 
          <TabChannels 
            selectedDevice={selectedDevice}
            setDialogAssignChannels={setDialogAssignChannels}
          />}
        </Stack>
      ) : (
        <Typography
          variant='subtitle2'
          color='text.secondary'
        >
          {deviceSelectionModel.length === 0 && 'Pilih entri dari daftar'}
          {deviceSelectionModel.length > 1 && `${deviceSelectionModel.length} catatan entri yang dipilih`}
        </Typography>
      )}
    </Flyout>
  )
}

export default FlyoutDevice