// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconGuard = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M5.36661 10.7768H19.6222C20.0265 10.7768 20.3559 10.4474 20.3559 10.0431V8.02152C20.3559 7.61722 20.0265 7.28778 19.6222 7.28778H5.00722C4.70774 7.28778 4.4382 7.46747 4.3184 7.75199L3.80928 9.06973C3.68948 9.36921 3.77933 9.69865 4.03389 9.89332L4.9024 10.5971C5.0222 10.7169 5.18691 10.7768 5.36661 10.7768Z' />
      <path d='M9.64953 11.9298L5.20215 11.9448V10.7618H10.1736L9.64953 11.9298Z' />
      <path d='M5.20207 11.9448L3.06074 16.9313C2.8511 17.4255 3.21048 17.9645 3.73459 17.9645H7.22361C7.50812 17.9645 7.77766 17.7998 7.89745 17.5453L9.90401 13.2326L9.69437 11.84L5.20207 11.9448Z' />
      <path d='M15.115 10.7618H10.1735L9.64941 11.9298L9.889 13.2325L11.2067 14.0112C11.3715 14.1011 11.5512 14.161 11.7308 14.161H14.2915C14.4262 14.161 14.546 14.1011 14.6209 13.9962L15.0102 13.4871C15.0701 13.4122 15.1001 13.3224 15.1001 13.2325V10.7618H15.115ZM14.0369 12.7534C14.0369 12.8732 13.992 12.993 13.9171 13.0828L13.8722 13.1277C13.7524 13.2625 13.5727 13.3374 13.393 13.3374H12.1801C12.0004 13.3374 11.8357 13.2924 11.6859 13.2176L11.1468 12.9331C11.057 12.8881 10.9971 12.7983 10.9672 12.7084L10.8623 12.1694L11.072 11.7501C11.1319 11.6303 11.2666 11.5554 11.4014 11.5554H13.6625C13.8722 11.5554 14.0219 11.7052 14.0219 11.8849V12.7534H14.0369Z' />
      <path d='M10.1434 7.6322H5.2767C5.09701 7.6322 4.94727 7.48246 4.94727 7.30277V6.32944C4.94727 6.14974 5.09701 6 5.2767 6H10.1434C10.3231 6 10.4728 6.14974 10.4728 6.32944V7.31774C10.4728 7.49743 10.3231 7.6322 10.1434 7.6322Z' />
      <path d='M20.7155 9.48895H20.2962C20.1465 9.48895 20.0117 9.36916 20.0117 9.20444V8.23111C20.0117 8.08136 20.1315 7.94659 20.2962 7.94659H20.7155C20.8653 7.94659 21 8.06639 21 8.23111V9.20444C21 9.35418 20.8653 9.48895 20.7155 9.48895Z' />

    </SvgIcon>
  )
}

export default IconGuard