// MUIS
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// PATH ICONS
import IconWalkieTalkie from 'assets/images/pathIcons/IconWalkieTalkie'

// STYLES
import useStyles from './flyoutDeviceUseStyles'

const TabChannels = (props) => {
  const { 
    selectedDevice, 
    setDialogAssignChannels,
  } = props

  const classes = useStyles()

  return (
    <Stack 
      className={classes.content}
      paddingTop='8px'
    >
      {/* TOTAL CHANNELS TEXT AND ADD CHANNEL BUTTON */}
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        {/* TOTAL CHANNEL TEXT */}
        <Stack>
          <Typography
            variant='caption'
            color='text.secondary'
          >
            Total Kanal
          </Typography>
          
          <Typography variant='body2'>
            {selectedDevice.channels.length}
          </Typography>
        </Stack>

        {/* ADD CHANNEL BUTTON */}
        <Button 
          variant='outlined'
          onClick={() => setDialogAssignChannels(selectedDevice)}
        >
          Tambah Kanal
        </Button>
      </Stack>

      {/* CHANNEL LIST */}
      <List>
        {selectedDevice.channels.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            className={classes.informationListItemChannel}
          >
            {/* ICON */}
            <ListItemIcon>
              <IconWalkieTalkie/>
            </ListItemIcon>

            {/* TEXT */}
            <ListItemText primary={item.group_name}/>
          </ListItem>
        ))}
      </List>
    </Stack>
  )
}

export default TabChannels