import React, { useCallback, useEffect, useRef, useState } from 'react'

// MUIS
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  IconButton,
} from '@mui/material'

// ICON
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import IconDelete from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

// COMPONENTS
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import LoadingBox from 'components/LoadingBox/LoadingBox'
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'
import PageHeader from 'components/PageHeader/PageHeader'
import Flyout from 'components/Flyout/Flyout'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import SimpleBackdrop from 'components/Partials/SimpleBackdrop'
import MobileAppDetail from './MobileAppDetail/MobileAppDetail'

// SERVICES
import {
  getDeviceAppVersion,
  getDeviceOSVersion,
  getGroups,
  gets,
  getDeviceListOfficer,
  getDeviceListVehicle
} from '../../services/DeviceService'
import { doDeleteDevice } from 'services/DeviceService'
import { getTrackerList, getStateHashMap } from 'services/TrackingServices'

// STYLES
import useStyles from './androidDeviceUseStyles'

// RAMDA
import { isEmpty, reject } from 'ramda'

// STATE
import { useDispatch } from 'react-redux'
import { showAlert } from 'store/MainReducer'


// UTILS
import { getConnectionStatusColor, getConnectionStatusText } from 'utilities'
import DialogEditLabel from './DialogEditLabel/DialogEditLabel'

export default function AndroidDevice() {
  const dispatch = useDispatch()

  const pageRef = useRef()
  const [dialogEditLabel, setDialogEditLabel] = useState(false)
  const [open, setOpen] = useState(false)
  const [firstRun, setFirstRun] = useState(true)
  const [devices, setDevices] = useState([])
  const [openConfirm, setOpenConfirm] = useState(false)
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [size, setPageSize] = useState(100)
  const [order, setOrder] = React.useState(null)
  const [orderBy, setOrderBy] = React.useState(null)
  const [OSVersions, setOSVersions] = useState([])
  const [appVersions, setAppVersions] = useState([])
  const [groups, setGroups] = useState([])
  const [deviceDetail, setDeviceDetail] = useState(null)
  const [listOfficer, setListOfficer] = useState([])
  const [listVehicle, setListVehicle] = useState([])
  const [informationDetail, setInformationDetail] = useState(
    'Select an entry from the list'
  )
  const [search, setSearch] = useState('')
  const mountedRef = useRef(true)
  const [dialogDeleteDevice, setDialogDeleteDevice] = useState({})

  const groupByList = [
    {
      title: 'Don\'t Group',
      value: null,
    },
    {
      title: 'By Status',
      value: 'status',
    },
  ]
  const [selectedGroupBy, setSelectedGroupBy] = useState(groupByList[0])
  const [isFilterOn, setIsFilterOn] = useState(false)
  const [selectionModel, setSelectionModel] = useState([])
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  const classes = useStyles()

  const [filters, setFilters] = useState({
    status: '',
    label: '',
    deviceModel: '',
    deviceOsVersion: '',
    deviceAppVersion: '',
    deviceLanguage: '',
    identifier: '',
    groupNo: '',
    numberOfOfficer: '',
    numberOfVehicle: ''
  })

  const initData = useCallback(() => {
    try {
      const params = order
        ? {
          page,
          size,
          sort: `${orderBy},${order}`,
        }
        : {
          page,
          size,
        }

      gets(params).then((response) => {
        if (mountedRef.current) {
          setDevices(response.rows)
          setTotal(response.totalElements)
          setFirstRun(false)
        }
      })
      getDeviceOSVersion({ page: 0, size: 500 }).then((response) => {
        if (mountedRef.current) {
          setOSVersions(response.rows)
        }
      })
      getDeviceAppVersion({ page: 0, size: 500 }).then((response) => {
        if (mountedRef.current) {
          setAppVersions(response.rows)
        }
      })

      getGroups({ page: 0, size: 500 }).then((response) => {
        if (mountedRef.current) {
          setGroups(response.rows)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }, [mountedRef])

  const reloadData = async () => {
    setIsTableLoading(true)

    const newFilters = reject(isEmpty, filters)
    let params = {
      page,
      size,
    }
  
    if (order) params.sort = `${orderBy},${order}`
    if (orderBy === 'deviceOSVersion') params.sort = `deviceOsVersion,${order}`
    if (orderBy === 'groupName') params.sort = `vG.groupName,${order}`
    if (search) params.globalSearch = search

    const responseDevicesList = await gets(params,newFilters) // API 1
    const responseTrackerList = await getTrackerList({ ignorePagination: true }) // API 2
    const responseStateList = await getStateHashMap()
    const tempStateHashmap = Object.values(responseStateList.states)

    // PROCCESS JOIN API 1 TO API 2 WITH deviceObjectId === id or tracker.label === device.label
    // NOT ALL API 1 HAVE deviceObjectId BUT API 1 & API 2 HAVE SIMILAR label NAME
    const joinDeviceTracker = responseDevicesList && responseDevicesList.rows.map(device => {
      if(responseTrackerList.find(tracker => tracker.id === device.deviceObjectId)) {
        return {
          ...device,
          tracker: responseTrackerList.find(tracker => tracker.id === device.deviceObjectId),
        }
      } else if (responseTrackerList.find(tracker => tracker.label === device.label)) {
        return {
          ...device,
          tracker: responseTrackerList.find(tracker => tracker.label === device.label),
        }
      } else {
        return {
          ...device,
          tracker: null
        }
      }
    })

    // PROCESS JOIN API 1 TO API 3 WITH tracker.source.id === source_id
    const joinDeviceState = joinDeviceTracker?.map((device, index) => {
      if(!device.tracker) return {
        ...device,
        state: {
          connection_status: 'just_registered'
        }
      }

      let getState = tempStateHashmap.find(stateItem => {
        if (stateItem.source_id === device.tracker.source.id) {
          return stateItem
        }
      })
  
      return {
        ...device,
        state: getState ? getState : {
          connection_status: 'just_registered'
        },
      }
    })

    setDevices(joinDeviceState)
    setTotal(responseDevicesList.totalElements)
    setIsTableLoading(false)
  }

  const refetchOfficer = async () => {
    const lists = await getDeviceListOfficer(selectionModel[0])
    setListOfficer(lists.list)
  }

  const fetchVehicle = async () => {
    const response = await getDeviceListVehicle(selectionModel[0])
    setListVehicle(response.vehicleList)
  }

  const groupingColDef = {
    headerName: 'Label',
    renderCell: (params) => {
      if (!params.rowNode.isAutoGenerated) {
        return (
          <Typography 
            variant='inherit'
            noWrap
          >
            {params.row?.label}
          </Typography>
        )
      }

      return (
        <Typography 
          variant='subtitle1'
          className={`${classes.columnStatus} ${classes.groupingRow}`}
          fontWeight='600 !important'
          noWrap
          sx={{
            '&::before': {
              backgroundColor: getConnectionStatusColor(params.rowNode.groupingKey.toLowerCase())
            }
          }}
        >
          {getConnectionStatusText(params.rowNode.groupingKey).toUpperCase()}
        </Typography>
      )
    },
  }
  const handleColumnsMenuItemClick = (inputItem, inputIndex) => {
    let tempSelectedColumnList = [...selectedColumnList]
    if (
      selectedGroupBy.value && inputItem.field === 'status' ||
      selectedGroupBy.value && inputItem.field === 'label'
    ) {
    } else {
      tempSelectedColumnList[inputIndex].hide = !tempSelectedColumnList[inputIndex].hide
    }
    setSelectedColumnList(tempSelectedColumnList)
  }
  useEffect(() => {
    if (!isFilterOn) setFilters(filters)
  }, [isFilterOn])

  useEffect(() => {
    if (selectedGroupBy.value) {
      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        if (item.field === selectedGroupBy.value || item.field === 'label') {
          item.hide = true
        }
      })
      setSelectedColumnList(tempSelectedColumnList)
    } else {
      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        if (item.field === 'label' || item.field === 'status') {
          item.hide = false
        }
      })
      setSelectedColumnList(tempSelectedColumnList)
    }
  }, [selectedGroupBy])

  const handleDeleteIconButtonClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()
    setDialogDeleteDevice(inputParams)
  }

  const handleEditIconButtonClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()
    setDialogEditLabel(inputParams)
  }

  const initialColumns = [
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 130,
      hide: false,
      areFilterAndSortShown: false,
      renderCell: (params) => {
        return (
          <Typography
            className={classes.columnStatus}
            noWrap
            sx={{
              '&::before': {
                backgroundColor: getConnectionStatusColor(params.row?.state?.connection_status || 'offline')
              }
            }}
          >
            {getConnectionStatusText(params.row?.state?.connection_status) || 'offline'}
          </Typography>
        )
      },
    },
    {
      field: 'label',
      headerName: 'Label',
      flex: 1,
      minWidth: 150,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) =>
        params.value && (
          <Typography
            variant='caption'
            noWrap
          >
            {params.value}
          </Typography>
        ),
    },
    {
      field: 'deviceCode',
      headerName: 'Pengenal',
      flex: 1,
      minWidth: 175,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) =>
        params.value && (
          <Typography
            variant='caption'
            className={classes.normalText}
            noWrap
          >
            {params.value}
          </Typography>
        ),
    },
    {
      field: 'deviceModel',
      headerName: 'Model Perangkat',
      flex: 1,
      minWidth: 150,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) =>
        params.value && (
          <Typography
            variant='caption'
            className={classes.normalText}
            noWrap
          >
            {params.value}
          </Typography>
        ),
    },
    {
      field: 'deviceOSVersion',
      headerName: 'Versi Perangkat',
      flex: 1,
      minWidth: 125,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) =>
        params.value && (
          <Typography
            variant='caption'
            className={classes.normalText}
            noWrap
          >
            {params.value}
          </Typography>
        ),
    },
    {
      field: 'deviceAppVersion',
      headerName: 'Versi Aplikasi Perangkat',
      flex: 1,
      minWidth: 125,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) =>
        params.value && (
          <Typography
            variant='caption'
            className={classes.normalText}
            noWrap
          >
            {params.value}
          </Typography>
        ),
    },
    {
      field: 'description',
      headerName: 'Keterangan',
      flex: 1,
      minWidth: 175,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) =>
        params.value && (
          <Typography
            variant='caption'
            className={classes.normalText}
            noWrap
          >
            {params.value.toLowerCase() !== 'no description' ? params.value : 'Tanpa Keterangan'}
          </Typography>
        )
      ,
    },
    {
      field: 'groupName',
      headerName: 'Kelompok',
      flex: 1,
      minWidth: 125,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) =>
        params.value && (
          <Typography
            variant='caption'
            className={classes.normalText}
            noWrap
          >
            {params.value}
          </Typography>
        ),
    },
    {
      field: 'numberOfOfficer',
      headerName: 'Petugas',
      flex: 1,
      minWidth: 120,
      hide: false,
      areFilterAndSortShown: true,
      valueGetter: (params) => `${params.value} Petugas`,
    },
    {
      field: 'numberOfVehicle',
      headerName: 'Kendaraan',
      flex: 1,
      minWidth: 120,
      hide: false,
      areFilterAndSortShown: true,
      valueGetter: (params) => `${params.value} Kendaraan`,
    },
    {
      field: 'actions',
      headerName: '',
      width: 130,
      hide: false,
      sortable: false,
      disableColumnMenu: true,
      areFilterAndSortShown: false,
      renderCell: (params) => (
        <Box className={classes.columnActions}>
          {/* DELETE ICON */}
          <CustomTooltipBlack title='Ubah' placement='bottom'>
            <IconButton
              onClick={(event) =>
                handleEditIconButtonClick(event, params)
              }
            >
              <EditIcon />
            </IconButton>
          </CustomTooltipBlack>

          {/* DELETE ICON */}
          <CustomTooltipBlack title='Hapus' placement='bottom'>
            <IconButton
              onClick={(event) =>
                handleDeleteIconButtonClick(event, params)
              }
            >
              <IconDelete />
            </IconButton>
          </CustomTooltipBlack>
        </Box>
      ),
    },
  ]
  const [selectedColumnList, setSelectedColumnList] =
        useState(initialColumns)

  const [isFlyoutShown, setIsFlyoutShown] = useState(false)

  const flyoutWidth = 528
  const flyoutTitleMargin = 400 // TO MAKE THE TITLE VERTICALLY ALIGN WITH THE FLYOUT COMPONENT

  const handleDeleteButtonClick = async () => {
    setIsDeleteLoading(true)
    const result = await doDeleteDevice({
      deviceNos: [dialogDeleteDevice.row.deviceNo],
    })
    setIsDeleteLoading(false)

    if (result.status !== 'error') {
      dispatch(
        showAlert({ message: 'Sukses Menghapus Perangkat Android' })
      )
      setDialogDeleteDevice({})
      reloadData()
    }
  }

  useEffect(() => {
    if (selectionModel.length === 0) {
      setDeviceDetail(null)
      setInformationDetail('Pilih entri dari daftar')
    } else if (selectionModel.length === 1) {
      const deviceSelected = devices.find(
        (item) => item.id === selectionModel[0]
      )
      setDeviceDetail(deviceSelected)

      refetchOfficer()
      fetchVehicle()
      setInformationDetail('')
      if (!isFlyoutShown) setIsFlyoutShown(true)
    } else if (selectionModel.length > 1) {
      setDeviceDetail(null)
      setInformationDetail(
        `${selectionModel.length} catatan entri yang dipilih`
      )
    }
  }, [selectionModel])

  useEffect(() => {
    initData()
    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    reloadData()
  }, [page, size, order, orderBy, search, filters])

  return (
    <Box className={classes.pageRoot} ref={pageRef}>
      <PageHeader
        isAddButtonAvailable={false}
        title='Aplikasi Seluler'
        search={search}
        setSearch={setSearch}
        isFlyoutAvailable={true}
        isFlyoutShown={isFlyoutShown}
        flyoutTitle='Informasi'
        flyoutTitleMargin={`${flyoutTitleMargin}px`}
        onToggleFlyoutClick={() => setIsFlyoutShown((current) => !current)}
      />

      {/* MAIN CONTENT AND FLYOUT */}
      <Box
        className={classes.contentContainer}
        sx={{
          paddingRight: isFlyoutShown ? `${flyoutWidth + 24}px` : 0,
        }}
      >
        <LoadingBox isLoading={isTableLoading}>
          <DataGridFilters
            columns={initialColumns}
            selectedColumnList={selectedColumnList}
            handleColumnsMenuItemClick={handleColumnsMenuItemClick}
            isFilterOn={isFilterOn}
            setIsFilterOn={setIsFilterOn}
            groupByList={groupByList}
            selectedGroupBy={selectedGroupBy}
            setSelectedGroupBy={setSelectedGroupBy}
          />

          <DataGridTable
            initialColumns={initialColumns}
            rows={devices}
            total={total}
            page={page}
            pageSize={size}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            setPage={setPage}
            setPageSize={setPageSize}
            setFilters={setFilters}
            isFilterOn={isFilterOn}
            selectedColumnList={selectedColumnList}
            setSelectedColumnList={setSelectedColumnList}
            selectedGroupBy={selectedGroupBy}
            getTreeDataPath={(row) => [row.state?.connection_status, row.id]}
            groupingColDef={groupingColDef}
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
            isCheckboxPinned={true}
          />
        </LoadingBox>

        {/* FLYOUT */}
        <Flyout isFlyoutShown={isFlyoutShown} flyoutWidth={flyoutWidth}>
          {deviceDetail ? (
            <MobileAppDetail 
              initialDeviceDetail={deviceDetail}
              deviceId={deviceDetail?.id}
              reloadData={reloadData}
              closeFlyout={() => setIsFlyoutShown(false)}
            />
          ) : (
            <Typography
              variant='subtitle2'
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                background: '#fff',
              })}
            >
              {informationDetail}
            </Typography>
          )}
        </Flyout>
      </Box>

      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <Box textAlign={'center'} marginTop={'1.5rem'}>
          <ErrorOutlineIcon
            sx={{ color: '#FBBE05', fontSize: '4rem' }}
          />
        </Box>
        <Box textAlign={'center'} marginBottom={'1rem'}>
          <DialogTitle
            id='alert-dialog-title'
            sx={{ fontWeight: '400' }}
          >
            {'Ubah Jenis Kejadian untuk 3 Dokumen'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              Anda akan mengubah jenis kejadian untuk 3 dokumen.
            </DialogContentText>
            <DialogContentText>
              Apakah Anda yakin?
            </DialogContentText>
          </DialogContent>
        </Box>
        <DialogActions>
          <Button
            color={'default'}
            sx={{ color: '#6F7687' }}
            onClick={() => setOpenConfirm(false)}
          >
            Batal
          </Button>
          <Button onClick={() => setOpenConfirm(false)} autoFocus>
            Iya, Perbarui
          </Button>
        </DialogActions>
      </Dialog>

      {/* DIALOG DELETE DEVICE */}
      <DialogConfirmation
        title='Hapus Android Device'
        caption='Apa Anda yakin ingin menghapus device ini?'
        dialogConfirmationObject={dialogDeleteDevice}
        setDialogConfirmationObject={setDialogDeleteDevice}
        cancelButtonText='Batal'
        continueButtonText='Hapus'
        onContinueButtonClick={handleDeleteButtonClick}
        onCancelButtonClick={() => setDialogDeleteDevice({})}
      />

      {/* LOADING */}
      <SimpleBackdrop isLoading={isDeleteLoading} />

      {/* DIALOG EDIT LABEL */}
      <DialogEditLabel
        pageRef={pageRef}
        dialogEditLabel={dialogEditLabel}
        setDialogEditLabel={setDialogEditLabel}
        reloadData={reloadData}
      />
    </Box>
  )
}
