// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  panelContainer: {
    backgroundColor: 'white',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.shadows[4],
    marginBottom: 10,
    position: 'relative',
  },
  panelHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
  },
  panelTitle: {
    fontWeight: 600,
    marginLeft: 16
  },
  searchInput: {
    width: '100%',
    marginLeft: 6,
    paddingLeft: 12,
  },
  searchIcon: {
    marginRight: 8,
    color: colors.iconDark,
    cursor: 'pointer',
  },
  objectList: {
    flex: 1,
    overflow: 'auto',
  },
  objectItemSelected: {
    marginTop: 4,
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
  },
  objectItem: {
    marginTop: 4,
  },
  objectItemButton: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '9px 0px 9px 16px',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
    },
  },
  objectItemIcon: {
    marginRight: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  objectItemText: {
    fontSize: 14,
    fontWeight: 400,
  },
  pauseBttn: {
    color: theme.palette.primary.main
  },
  playBtn : {
    color : '#81C784'
  }
}))

export default useStyles