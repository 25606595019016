// MUIS
import Stack from '@mui/material/Stack'

// STYLES
import useStyles from './markerClusterUseStyles'

const MarkerCluster = (props) => {
  const { calculateSize } = props
  const classes = useStyles()

  return (
    <Stack
      className={classes.root}
      style={{
        height: `${calculateSize}px`,
        width: `${calculateSize}px`,
      }}
    >
      <Stack
        className={classes.circle}
        style={{
          height: `${calculateSize}px`,
          width: `${calculateSize}px`,
          animationDelay: '0s'
        }}
      ></Stack>

      <Stack
        className={classes.circle}
        style={{
          height: `${calculateSize}px`,
          width: `${calculateSize}px`,
          animationDelay: '1s'
        }}
      ></Stack>

      <Stack
        className={classes.circle}
        style={{
          height: `${calculateSize}px`,
          width: `${calculateSize}px`,
          animationDelay: '2s'
        }}
      ></Stack>

      <Stack
        className={classes.circle}
        style={{
          height: `${calculateSize}px`,
          width: `${calculateSize}px`,
          animationDelay: '3s'
        }}
      ></Stack>

      <Stack
        className={classes.circle}
        style={{
          height: `${calculateSize}px`,
          width: `${calculateSize}px`,
          animationDelay: '4s'
        }}
      ></Stack>
    </Stack>
  )
}

export default MarkerCluster