// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconCannabisAbuse = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M12.0003 1.41174C12.0003 1.41174 10.1408 3.155 9.72387 7.8158L4.62829 2.72166C4.54887 2.63731 4.43968 2.591 4.32387 2.58935C4.15182 2.591 3.99795 2.6952 3.93343 2.85563C3.87057 3.01441 3.90862 3.198 4.0327 3.31708L20.9739 20.2534C21.0764 20.3791 21.2402 20.437 21.3991 20.4006C21.5562 20.3642 21.6803 20.2402 21.7167 20.0831C21.7531 19.9243 21.6952 19.7606 21.5695 19.658L18.79 16.8794C21.6869 15.538 22.5886 13.4392 22.5886 13.4392C22.5886 13.4392 20.7307 12.7677 18.0621 13.1481C20.772 9.12572 20.8945 5.05041 20.8945 5.05041C20.8945 5.05041 17.2101 6.24456 14.3298 10.0122V9.70791C14.3728 3.73718 12.0003 1.41174 12.0003 1.41174ZM3.10623 5.05041C3.10623 5.05041 3.10954 8.99837 6.03123 13.1481C3.27829 12.3013 1.41211 13.1878 1.41211 13.1878C1.41211 13.1878 2.55862 16.021 6.24299 17.2499C5.22718 17.842 4.63159 18.4771 4.58858 18.5201L3.66211 19.5389L4.9724 19.9226C5.01542 19.9226 5.90384 20.174 7.09005 20.174C7.80972 20.174 8.47976 20.093 9.11505 19.9226C10.1408 19.6497 10.9978 19.1536 11.6033 18.7053C11.7323 20.8455 12.4768 22.3175 12.4768 22.3175C12.5364 22.4697 12.677 22.5756 12.8408 22.5871C13.0046 22.5987 13.1601 22.516 13.2412 22.3738C13.3206 22.2299 13.3123 22.0546 13.218 21.9206C13.218 21.9206 12.5761 20.6504 12.4503 18.7318C13.0641 19.1718 13.8798 19.6547 14.8856 19.9226C15.5209 20.0913 16.191 20.174 16.9106 20.174C18.0969 20.174 18.9853 19.9226 19.0283 19.9226L19.3327 19.83L6.36211 6.91606C4.62498 5.68883 3.10623 5.05041 3.10623 5.05041Z'/>
    </SvgIcon>
  )
}

export default IconCannabisAbuse