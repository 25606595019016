import { useState } from 'react'
import PropTypes from 'prop-types'

// COMPONENTS
import CarouselMediaWrapper from './CarouselMediaWrapper'

// CONSTANTS
import { iconListTagEvidences } from 'constants/icons'

// MUIS
import Button from '@mui/material/Button'
import CardMedia from '@mui/material/CardMedia'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { alpha } from '@mui/material/styles'

// MUI ICONS
import IconAccessTime from '@mui/icons-material/AccessTime'
import IconCheckCircle from '@mui/icons-material/CheckCircle'
import IconClose from '@mui/icons-material/Close'
import IconDoneAll from '@mui/icons-material/DoneAll'
import IconDoNotDisturb from '@mui/icons-material/DoNotDisturb'
import IconEdit from '@mui/icons-material/Edit'
import IconInfoOutlined from '@mui/icons-material/InfoOutlined'
import IconLabel from '@mui/icons-material/Label'
import IconPhoneAndroid from '@mui/icons-material/PhoneAndroid'
import IconRoom from '@mui/icons-material/Room'
import IconSave from '@mui/icons-material/Save'

// PATH ICONS
import IconAssigned from 'assets/images/pathIcons/IconAssigned'

// SERVICES
import { putUpdateEvidence } from 'services/EvidenceService'

// STYLES
import useStyles from './cardEvidenceUseStyles'

// UTILITIES
import { dateFormat } from 'utilities'
import { getColorStatusDispatch, getTextStatusDispatch } from 'pages/DispatchEvidences/dispatchEvidencesUtils'

const CardEvidence = (props) => {
  const {
    item, setSelectedEvidenceItem, isShowNeededHelp, isShowStatus,
    uploaderName, isTransricbeEditable, reloadDetail
  } = props
  const classes = useStyles()

  const [isEditing, setIsEditing] = useState(false)
  const [paramsObject, setParamsObject] = useState({
    description: item.description
  })

  const getTagIcon = (iconObject) => {
    const SelectedIconObject = iconListTagEvidences.find(item => item.id === iconObject.iconId) 
    let SelectedIcon = SelectedIconObject ? SelectedIconObject.Icon : IconLabel
        
    return (
      <SelectedIcon
        fontSize='small'
        sx={{ color: `${iconObject.tagColor} !important` }}
      />
    )
  }

  const geIconStatus = (status) => {
    if (status === 'accepted') return <IconDoneAll />
    else if (status === 'completed') return <IconCheckCircle />
    else if (status === 'assigned') return <IconAssigned />
    else if(status === 'failed') return <IconClose />
    else if(status === 'cancelled') return <IconDoNotDisturb />
  }

  const handleButtonSaveDesc = async () => {
    const response = await putUpdateEvidence(item.evidenceNo, paramsObject)
    if (response.status === 200) {
      reloadDetail()
      setIsEditing(false)
    }
  }

  return (
    <Stack className={classes.root}>
      {/* INFORMATION LIST */}
      {[
        {
          icon: IconRoom,
          text: item.address,
        },
        {
          icon: IconAccessTime,
          text: dateFormat(item.createdOn, 'dd/MM/yyy, hh:mm a'),
        },
      ].map((informationItem, informationIndex) => (
        <ListItem 
          key={informationIndex}
          className={classes.evidenceInformation} 
          disablePadding
          sx={informationIndex === 0 ? {
            '& .MuiListItemText-primary': {
              fontWeight: 600,
            },
          } : { marginBottom: '16px' }}
        >
          {/* ICON */}
          <ListItemIcon>
            <informationItem.icon fontSize='small'/>
          </ListItemIcon>

          {/* TEXT */}
          <ListItemText primary={informationItem.text}/>
        </ListItem>
      ))}

      {/* MEDIAS CAROUSEL */}
      {(item.medias && item.medias?.length > 0) &&
      <Stack marginBottom='16px'>
        <CarouselMediaWrapper
          setSelectedEvidenceItem={setSelectedEvidenceItem}
          evidenceObject={item}
        />
      </Stack>
      }

      {/* HEADER DETAIL */}
      <Stack direction='row' marginTop='8px'>
        <Typography
          component='a'
          className={classes.linkId}
          onClick={() => setSelectedEvidenceItem({ ...item, activeIndex: 0 })}
        >
          #{item.evidenceNo}
        </Typography>

        {(isShowNeededHelp && item.neededHelp) && <Chip
          label='Butuh Bantuan'
          className={`${classes.headerChip} needHelp`}
          size='small'
          variant='filled'
          icon={<IconInfoOutlined />}
        />}

        {isShowStatus && (
          <Chip
            label={getTextStatusDispatch(item?.status?.toLowerCase())}
            className={classes.headerChip}
            size='small'
            variant='filled'
            icon={geIconStatus(item?.status?.toLowerCase())}
            sx={{
              color: getColorStatusDispatch(item?.status?.toLowerCase()),
              backgroundColor: alpha(getColorStatusDispatch(item?.status?.toLowerCase()), 0.08),
              '& .MuiSvgIcon-root': {
                color: getColorStatusDispatch(item?.status?.toLowerCase()),
              }
            }}
          />
        )}
      </Stack>

      {/* DESCRIPTION LABEL TEXT */}
      <Typography 
        variant='caption'
        textTransform='uppercase'
        color='text.secondary'
        marginTop='8px'
        marginBottom='4px'
      >
        Uraian Singkat
      </Typography>

      <Stack marginBottom='12px'>
        {/* VOICE NOTE MEDIA */}
        {item.voiceNoteMedia?.mediaUrl &&
        <CardMedia
          component='audio'
          src={item.voiceNoteMedia?.mediaUrl}
          type={item.voiceNoteMedia?.fileType}
          autoPlay={false}
          controls
          alt=''
          className={classes.evidenceItemVoiceNote}
        />}

        {/* DESCRIPTION VALUE TEXT */}
        {!isEditing && <Typography 
          variant='body2'
          fontWeight={600}
          marginTop='4px'
        >
          {item.description}
        </Typography>}

        {isEditing && <TextField
          className={classes.textareaDescription}
          rows={6}
          multiline
          defaultValue={paramsObject.description}
          value={paramsObject.description}
          onChange={event => setParamsObject({
            description: event.target.value
          })}
        />}

        {isTransricbeEditable && <Stack direction='row' marginTop='8px'>
          {!isEditing && <IconButton size='small' onClick={() => setIsEditing(!isEditing)}>
            <IconEdit fontSize='small' />
          </IconButton>}

          {isEditing && <Button
            variant='text'
            className={classes.buttonSave}
            startIcon={<IconSave />}
            onClick={() => handleButtonSaveDesc()}
          >
            Simpan Perubahan
          </Button>}
        </Stack>}
      </Stack>

      {/* TAG CHIP LIST */}
      <Stack 
        direction='row'
        spacing='4px'
        alignItems='center'
      >
        {item.tags.slice(0, 2).map((tagItem, tagIndex) => (
          <Chip
            key={tagIndex}
            icon={getTagIcon(tagItem)}
            size='small'
            label={tagItem.tagName} 
            variant='filled'
            className={classes.evidenceItemTag}
            sx={{ 
              color: tagItem.tagColor, 
              backgroundColor: alpha(tagItem.tagColor, 0.08),
              border: `1px solid ${tagItem.tagColor}`, 
            }}
          />
        ))}

        {item.tags.length >= 3 && (
          <Chip
            className={`${classes.evidenceItemTag} chipMore`}
            size='small'
            label={`+${item.tags.length-2}`}
          />
        )}

        {item.sosStatus &&
        <Chip
          icon={IconLabel}
          label='SOS' 
          variant='filled'
          className={classes.evidenceItemTag}
          size='small'
          color='error'
        />}
      </Stack>

      {/* UPLOADED BY */}
      {uploaderName && (
        <Stack direction='row' alignItems='center' marginTop='12px'>
          <IconPhoneAndroid fontSize='small' className={classes.iconUploadBy} />

          <Typography className={classes.textUploaded1} variant='caption'>Diupload Oleh</Typography>
          <Typography className={classes.textUploaded2} variant='caption'>{uploaderName}</Typography>
        </Stack>
      )}
    </Stack>
  )
}

CardEvidence.propTypes = {
  item: PropTypes.object.isRequired,
  setSelectedEvidenceItem: PropTypes.func,
  isShowNeededHelp: PropTypes.bool,
  isShowStatus: PropTypes.bool,
  uploaderName: PropTypes.string,
  isTransricbeEditable: PropTypes.bool
}

export default CardEvidence