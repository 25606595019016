// MUI STYLES
import { alpha } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { colors } from 'constants/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  headerTitle: {
    paddingRight: 32,
    fontSize: 16,
    fontWeight: 600,
    width: '100%',
  },
  headerFlyout: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    marginBottom: 12,
  },
  contentFlyout: {
    paddingTop: 24,
    height: 0,
    overflowY: 'auto',
    flex: 1,
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
  },
  itemDetail: {
    flexDirection: 'row',
    flexWrap: 'nowrap'
  },
  circleStatus: {
    height: 14,
    width: 14,
    borderRadius: '100%',
  },
  textStatus: {
    paddingLeft: 12,
    fontSize: 12,
    fontWeight: 600
  },
  labelTitle: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 6,
    color: colors.raven
  },
  labelIcon: {
    color: colors.raven
  },
  headerBoxDescription: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    border: `1px solid ${theme.palette.divider}`,
    borderBottom: 'none',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: alpha(theme.palette.common.black, 0.08),
    padding: '12px 8px',
    alignItems: 'center',
  },
  contentBoxDescription: {
    padding: '8px 16px',
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: alpha(colors.gallery, 0.48),
    borderRadius: 2,
    cursor: 'pointer',
    '& p': {
      margin: '4px 0 !important',
      fontSize: 14,
    }
  },
  titleBoxDescription: {
    fontWeight: 700,
    marginBottom: 4
  }
}))

export default useStyles