import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

// COMPONENTS
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DataGridTable from 'components/DataGridTable/DataGridTable'

// CONSTANTS
import { basePaths } from 'constants/paths'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

// MUI ICONS
import IconDelete from '@mui/icons-material/Delete'
import IconEdit from '@mui/icons-material/Edit'

// PATH ICONS
import IconKey from 'assets/images/pathIcons/IconKey'
import IconWalkieTalkie from 'assets/images/pathIcons/IconWalkieTalkie'

// SERVICES
import { postSearchChannels } from 'services/PushToTalkServices'

// STYLES
import useStyles from './tableChannelUseStyles'

const TableChannels = (props) => {
  const { 
    search,
    tabList,
    channelTableData, setChannelTableData,
    setDialogDeleteChannel, 
    mustReloadTableChannels, setMusReloadTableChannels,
  } = props

  const history = useHistory()
  const classes = useStyles()

  const groupByList = [
    {
      title: 'Don\'t Group',
      value: null,
    },
  ]

  const initialFilters = {
    channelId: '',
    channelName: '',
    createdDate: '',
    lastUpdate: '',
  }

  const handleEditIconButtonClick = (event, params) => {
    event.stopPropagation()
    history.push(
      `${basePaths.pushToTalks}?mode=edit&id=${params.id}`, 
      {
        mode: 'edit',
        data: params.row,
      },
    )
  }
  
  const handleDeleteIconButtonClick = (event, params) => {
    event.stopPropagation()
    setDialogDeleteChannel({ tab: tabList[1].value, params })
  }

  const initialColumns = [
    {
      field: 'groupId',
      headerName: 'ID Pengguna',
      headerIcon: IconKey,
      areFilterAndSortShown: true,
      // flex: 1,
      minWidth: 150,
      hide: false,
    },
    {
      field: 'groupName',
      headerName: 'Label',
      headerIcon: IconWalkieTalkie,
      areFilterAndSortShown: true,
      flex: 1,
      minWidth: 200,
      hide: false,
    },
    {
      field: 'actions',
      headerName: '',
      width: 120,
      hide: false,
      areFilterAndSortShown: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box className={classes.columnActions}>
          {/* EDIT ICON */}
          <CustomTooltipBlack 
            title='Ubah' 
            placement='bottom'
          >
            <IconButton onClick={(event) => handleEditIconButtonClick(event, params)}>
              <IconEdit/>
            </IconButton>
          </CustomTooltipBlack>

          {/* DELETE ICON */}
          <CustomTooltipBlack 
            title='Hapus' 
            placement='bottom'
          >
            <IconButton onClick={(event) => handleDeleteIconButtonClick(event, params)}>
              <IconDelete/>
            </IconButton>
          </CustomTooltipBlack>
        </Box>
      ),
    },
  ]

  const [ selectedColumnList, setSelectedColumnList ] = useState(initialColumns)
  const [ isFilterOn, setIsFilterOn ] = useState(false)
  const [ selectedGroupBy, setSelectedGroupBy ] = useState(groupByList[0])
  const [ total, setTotal ] = useState(0)
  const [ page, setPage ] = useState(0)
  const [ order, setOrder ] = useState('desc')
  const [ orderBy, setOrderBy ] = useState('created_on')
  const [ size, setPageSize ] = useState(100)
  const [ filters, setFilters ] = useState(initialFilters)
  const [ selectionModel, setSelectionModel ] = useState([])

  const handleColumnsMenuItemClick = (inputItem, inputIndex) => {
    let tempSelectedColumnList = [...selectedColumnList]
    tempSelectedColumnList[inputIndex].hide = !tempSelectedColumnList[inputIndex].hide
    setSelectedColumnList(tempSelectedColumnList)
  }
  
  const getChannelListData = async () => {
    const resultChannelList = await postSearchChannels({ page, size }, {
      groupName: filters?.groupName,
      groupId: Number(filters?.groupId) || null,
      globalSearch: search
    })
    
    if (resultChannelList.status === 200) {
      const newTableData = resultChannelList.data.rows.map((item) => {
        return {
          ...item,
          id: item.groupId,
        }
      })

      setChannelTableData(newTableData)
      setTotal(resultChannelList.data.totalElements)
      
      setMusReloadTableChannels(false)
    }
  }

  useEffect(() => {
    mustReloadTableChannels && getChannelListData()
  }, [page, order, orderBy, size, filters, search, mustReloadTableChannels])

  useEffect(() => {
    setMusReloadTableChannels(true)
  }, [page, order, orderBy, size, filters, search])

  return (
    <>
      <DataGridFilters
        columns={initialColumns}
        selectedColumnList={selectedColumnList}
        handleColumnsMenuItemClick={handleColumnsMenuItemClick}
        isFilterOn={isFilterOn}
        setIsFilterOn={setIsFilterOn}
        groupByList={groupByList}
        selectedGroupBy={selectedGroupBy}
        setSelectedGroupBy={setSelectedGroupBy}
      />

      <DataGridTable
        initialColumns={initialColumns}
        rows={channelTableData}
        total={total}
        page={page}
        pageSize={size}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        setPage={setPage}
        setPageSize={setPageSize}
        setFilters={setFilters}
        isFilterOn={isFilterOn}
        selectedColumnList={selectedColumnList}
        setSelectedColumnList={setSelectedColumnList}
        selectedGroupBy={selectedGroupBy}
        getTreeDataPath={(row) => [ row.userId, row.id ]}
        selectionModel={selectionModel} 
        setSelectionModel={setSelectionModel}
        isCheckboxPinned={true}
      />
    </>
  )
}

export default TableChannels