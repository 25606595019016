import * as React from 'react'
import {
  Box,
  FormControl,
  FormGroup,
  FormLabel,
  Switch,
  Typography,
} from '@mui/material'
import useStyles from '../settingsSecurityControlUseStyles'

export default function SwitchesGroup({
  groupName,
  menuId,
  permissions,
  changePermission,
}) {
  const classes = useStyles()

  const translatePermissionName = (item) => {
    if(item === 'Full Access') return 'Akses Lengkap'
    else if(item === 'View') return 'Melihat'
    else if(item === 'Edit') return 'Mengubah'
    else if(item === 'Delete') return 'Menghapus'
    else if(item === 'Denied') return 'Menolak'
    else if(item === 'Approve') return 'Menyetujui'
    else if(item === 'Create') return 'Membuat'
    else if(item === 'Assign Device') return 'Menetapkan Perangkat'
    else return item
  }

  return (
    <FormControl component='fieldset' variant='standard'>
      <FormLabel component='legend' className={classes.permissionNames}>
        {groupName}
      </FormLabel>
      <FormGroup>
        {permissions?.sort((a, b) => a.permissionId - b.permissionId).map((item, index) => {
          return (
            <Box
              className={'flex justifyContentBetween alignItemsCenter ml-4'}
              key={index}
            >
              <Typography className={classes.switchPermissions}>
                {translatePermissionName(item.permissionName)}
              </Typography>
              <Switch
                checked={item.enable}
                onChange={(e) =>
                  changePermission(
                    e,
                    menuId,
                    item.permissionId,
                    item.permissionName
                  )
                }
              />
            </Box>
          )
        })}
      </FormGroup>
    </FormControl>
  )
}
