// MUIS
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  searchFieldRoot: {
    display: 'flex',
    overflow: 'hidden',
  },
  iconSearch: {
    height: 36,
    width: 36,
  },
  customSearchInputWide: {
    width: 250,
    transition: 'width 0.25s ease-in-out',
  },
  customSearchInputNarrow: {
    width: 0,
    transition: 'width 0.25s ease-in-out',
  },
  iconClose: {
    cursor: 'pointer',
  },
}))

export default useStyles