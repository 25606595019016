// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  iconClose: {
    marginRight: 16,
    cursor: 'pointer',
  },
  titleText: {
    fontWeight: 600,
    fontSize: 24,
  },
  sectionText: {
    marginBottom: 16,
    fontWeight: 600,
  },
  formControl: {
    marginBottom: 16,
    borderRadius: '2px !important'
  },
  dropDownIcon: {
    color: colors.boulder,
  },
  menu: {
    transform: 'translate(0px, -16px)',
    borderRadius: 2,
    '& .MuiPaper-root': {
      width: 'calc(500px - 48px - 48px)',
      maxHeight: 500,
    },
    '& .MuiMenu-list': {
      padding: 0,
    },
    '@media only screen and (max-height: 820px)': {
      transform: 'translate(0px, -16px)',
    }
  },
  menuSearch: {
    padding: 16,
  },
  menuSearchInput: {
    width: '100%',
    fontWeight: 600,
    paddingBottom: 4,
    '&::-webkit-input-placeholder': {
      color: colors.boulder,
    },
  },
  menuSearchIcon: {
    marginRight: 4,
  },
  menuItemContainer: {
    width: '100%',
    overflow: 'auto',
    maxHeight: 'calc(500px - 65px)', // 65 is from the height of the search Input container component
    paddingLeft: 4,
    paddingRight: 4,
  },
  menuItem: {
    height: 48,
    paddingLeft: 20,
    '&:hover': {
      backgroundColor: colors.geyser,
    }
  },
  menuItemSelected: {
    backgroundColor: colors.geyser,
  },
}))

export default useStyles
