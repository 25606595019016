import ReactDOMServer from 'react-dom/server'

// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MOMENT
import moment from 'moment'

export const createStructureLineStackChartData = (xAxisData, series, isLastpanel) => {
  return {
    title: {
      text: ''
    },
    tooltip: {
      position: (pos, params, dom, rect, size) => {
        let xValue = 0
        let yValue = 0

        if (pos[0] >= (size.viewSize[0]/2)) {
          xValue = size.viewSize[0] - size.contentSize[0]
        } else if (pos[0] < (size.viewSize[0]/2)) {
          xValue = 0
        }

        if (pos[1] >= (size.viewSize[1]/2)) {
          yValue = size.viewSize[1] - size.contentSize[1]
        } else if (pos[1] < (size.viewSize[1]/2)) {
          yValue = 0
        }

        return [xValue, isLastpanel ? yValue : pos[1] + 8]
      },
      trigger: 'axis',
      className: 'customTooltipLineStack',
      textStyle: {
        color: '#FFFFFF'
      },
      backgroundColor: `${alpha('#000000', 0.8)} !important`,
      formatter: (params, ticket) => {
        return ReactDOMServer.renderToString(
          <>
            <Typography
              variant='caption'
              style={{ margin: '0 0 4px 0 !important', fontWeight: 600, display: 'block' }}
            >
              {moment(`${params[0].axisValue}/${moment().year()}`, 'DD/MM/YYYY').format('DD MMMM YYYY')}
            </Typography>

            {params?.map((item, index) => (
              <Stack style={{
                flexDirection:'row',
                alignItems: 'center',
                margin: '4px 0',
              }} key={index}>
                <Stack
                  style={{
                    width: '8px',
                    height: '8px',
                    backgroundColor: item.color,
                    borderRadius: '100%'
                  }}
                />

                <Typography
                  noWrap
                  variant='caption'
                  style={{
                    margin: '0 !important',
                    display: 'block',
                    paddingLeft: '4px',
                    width: '200px'
                  }}
                >{item.seriesName}</Typography>

                <Typography
                  noWrap
                  variant='caption'
                  style={{
                    margin: '0 !important',
                    display: 'block',
                    paddingLeft: '12px',
                  }}
                >{item.value}</Typography>
              </Stack>
            ))}
          </>
        )
      }
    },
    legend: {
      show: false
    },
    grid: {
      left: 4,
      right: 16,
      bottom: 4,
      top: 20,
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xAxisData,
      axisTick: {
        show: false
      },
      axisLine: {
        lineStyle: {
          opacity: 0.2
        }
      }
    },
    yAxis: {
      type: 'value'
    },
    series,
    axisTick: {
      show: false
    }
  }
}

export const createStructureBarChartData = (yAxisData, seriesActiveTab, seriesTotalActivity) => {
  return {
    title: {
      text: ''
    },
    tooltip: {
      position: (pos, params, dom, rect, size) => {
        let xValue = 0

        if (pos[0] >= (size.viewSize[0]/2)) {
          xValue = size.viewSize[0] - size.contentSize[0]
        } else if (pos[0] < (size.viewSize[0]/2)) {
          xValue = 0
        }

        return [xValue, pos[1] + 8]
      },
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      className: 'customTooltipLineStack',
      textStyle: {
        color: '#FFFFFF'
      },
      backgroundColor: `${alpha('#000000', 0.8)} !important`,
      formatter: (params, ticket) => {
        return ReactDOMServer.renderToString(
          <>
            <Stack style={{
              flexDirection:'row',
              alignItems: 'center',
            }}>
              <Typography
                variant='caption'
                style={{ flex: 1, margin: '0 !important', fontWeight: 600, display: 'block' }}
              >
                {params[0].axisValue}
              </Typography>

              <Typography
                variant='caption'
                style={{ margin: '0 0 4px 0 !important', display: 'block' }}
              >
                {((params[1].value/params[0].value) * 100).toFixed(0)}%
              </Typography>
            </Stack>

            {params?.map((item, index) => (
              <Stack style={{
                flexDirection:'row',
                alignItems: 'center',
                margin: '4px 0',
              }} key={index}>
                <Typography
                  noWrap
                  variant='caption'
                  style={{
                    margin: '0 !important',
                    display: 'block',
                    width: '180px'
                  }}
                >{item.seriesName}</Typography>

                <Typography
                  noWrap
                  variant='caption'
                  style={{
                    margin: '0 !important',
                    display: 'block',
                    paddingLeft: '12px',
                  }}
                >{item.value}</Typography>
              </Stack>
            ))}
          </>
        )
      }
    },
    legend: {
      bottom: 0,
      itemGap: 20,
      textStyle: {
        color: '#717171'
      }
    },
    grid: {
      left: 4,
      right: '10%',
      bottom: 40,
      top: 20,
      containLabel: true
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01]
    },
    yAxis: {
      type: 'category',
      data: yAxisData,
      axisTick: {
        show: false
      },
      axisLine: {
        lineStyle: {
          opacity: 0.2
        }
      },
      axisLabel: {
        margin: 12
      }
    },
    series: [
      {
        name: 'Tablet Aktif',
        type: 'bar',
        data: seriesActiveTab,
        color: colors.amber,
        barWidth: 8
      },
      {
        name: 'Jumlah Kegiatan',
        type: 'bar',
        data: seriesTotalActivity,
        color: colors.cornflowerBlue,
        barWidth: 8
      }
    ]
  
  }
}

export const restructureResultApi = (response) => {
  let result = { xAxisData: [], series: [], isHaveData: false }

  result.xAxisData = response?.data[0]?.x?.map(valueDate => moment(valueDate, 'YYYY-MM-DD').format('DD/MM'))
  result.series = response?.data?.map(item => ({
    name: item.label,
    type: 'line',
    data: item.y
  }))
  result.isHaveData = result.series?.length ? true : false

  return result
}