import React, { useState, useContext, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// ASSETS
import arrowDoubleLeft from 'icons/arrowdoubleleft.svg'

// CONTEXTS
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'
import { AllPagesContext } from 'contexts/AllPagesContext'

// CUSTOM COMPONENTS
import CustomDrawer from 'components/Customs/CustomDrawer'
import CustomDrawerHeader from 'components/Customs/CustomDrawerHeader'
import CustomListItemButton from 'components/Customs/CustomListItemButton'

// DATA
import drawerNavigationList from './drawerNavigationList'

// MUIS
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Popper from '@mui/material/Popper'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconArrowDropDown from '@mui/icons-material/ArrowDropDown'
import IconArrowDropUp from '@mui/icons-material/ArrowDropUp'

// STYLES
import useStyles from './drawerUseStyles'

// UTILITIES
import { isAccessMenu } from 'utilities/auth'
import { getCompanyLogo, getCompanyTitle } from 'utilities'

const Drawer = () => {
  const history = useHistory()
  const location = useLocation()
  const { pathname } = location

  const { isDrawerExpanded, changeIsDrawerExpanded } = useContext(PrivateLayoutContext)
  const { agentHelpNeedHelp, liveStreamingList } = useContext(AllPagesContext)

  const liveStreamingListBadge = liveStreamingList.length + agentHelpNeedHelp.length

  const classes = useStyles()

  const popperRef = useRef()

  const [ expandParent, setExpandParent ] = useState(
    location.state?.expandParent ? 
      location.state.expandParent : 
      null
  )
  const [ hoveredParent, setHoveredParent ] = useState(null)
  const [ extraAnchorEl, setExtraAnchorEl ] = useState(null)

  const isActive = (paths) => {
    if(paths[0] === '/' && pathname === '/') return true
    else {
      const currentPath = pathname.split('/')[1]
      const isMatch = paths.find(item => item.split('/')[1] === currentPath)
      return isMatch
    }
  }

  const handleParentItemClick = (inputItem, inputIndex) => {
    if(inputItem.type === 'single') {
      history.push({ 
        pathname: inputItem.linkTo,
        state: { 
          expandParent: null,
          isDrawerExpanded,
          lastClicked: 'parent',
        },
      })
    }
    else if(inputItem.type === 'collection' && isDrawerExpanded) {
      if(!expandParent) {
        setExpandParent(inputItem.text)
      }
      else if(expandParent === inputItem.text) {
        setExpandParent(null)
      }
      else {
        setExpandParent(inputItem.text)
      }
    }
    // else if(inputItem.type === 'collection' && !isDrawerExpanded) {
    //   history.push({ 
    //     pathname: inputItem.children[0].linkTo,
    //     state: { 
    //       expandParent,
    //       isDrawerExpanded, 
    //       lastClicked: 'parent',
    //     },
    //   })
    // }
  }
  
  const logoutUser = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    history.push({ 
      pathname: '/login',
      state: { 
        expandParent: null,
        isDrawerExpanded: true, 
      },
    })
  }

  const handleChildrenItemClick = (inputItem, inputIndex) => {
    if(inputItem.onClickType === 'logOut') logoutUser()
    else {
      history.push({ 
        pathname: inputItem.linkTo,
        state: { 
          expandParent,
          isDrawerExpanded, 
          lastClicked: 'children',
        },
      })
    }
  }

  const handleParentItemHovered = (inputEvent, inputItem, inputIndex) => {
    setHoveredParent(inputIndex)
    setExtraAnchorEl(inputEvent.currentTarget)
  }

  const handleParentItemUnhovered = (inputEvent, inputItem, inputIndex) => {
    if(extraAnchorEl == null || popperRef?.current?.contains(inputEvent.nativeEvent.relatedTarget)) {
      return
    }
    else {
      setHoveredParent(null)
      // setExtraAnchorEl(null)
    }
  }

  return (
    <CustomDrawer
      className={`${classes.drawerRoot} zoom`}
      variant='permanent'
      open={isDrawerExpanded}
    >
      {/* DRAWER HEADER */}
      <CustomDrawerHeader className={classes.drawerHeader}>
        {/* DRAWER TOGGLE BUTTON */}
        <IconButton
          onClick={() => changeIsDrawerExpanded((current) => !current)}
          className={isDrawerExpanded ? classes.toggleIconOnOpen : classes.toggleIconOnClose}
        >
          <Box
            component='img' 
            src={arrowDoubleLeft} 
            alt='logo' 
          />
        </IconButton>

        {/* COMPANY LOGO */}
        {isDrawerExpanded && (
          <>
            {/* IMAGE */}
            <Box
              component='img'
              src={getCompanyLogo('light')}
              alt='logo'
              className={classes.companyLogoImage}
            />

            {/* TEXT */}
            <Typography
              variant='caption'
              className={classes.companyLogoText}
            >
              {getCompanyTitle()}
            </Typography>
          </>
        )}
      </CustomDrawerHeader>

      {/* NAVIGATION LIST */}
      <List>
        {drawerNavigationList(liveStreamingListBadge).map((parentItem, parentIndex) => {
          return (
            <React.Fragment key={parentIndex}>
              {/* PARENT */}
              <CustomListItemButton 
                key={parentIndex}
                className={
                  isAccessMenu(parentItem.accessMenu) 
                    ? (isActive(parentItem.activeValue) ? `${classes.listItem} ${classes.listItemSelected}` : classes.listItem) 
                    : classes.hidden
                }
                onClick={() => handleParentItemClick(parentItem, parentIndex)}
                onMouseEnter={(event) => handleParentItemHovered(event, parentItem, parentIndex)}
                onMouseLeave={(event) => handleParentItemUnhovered(event, parentItem, parentIndex)}
              >
                {/* ICON */}
                <ListItemIcon>
                  {!isDrawerExpanded && parentItem.badge ? 
                  // WITH BADGE
                    <Badge 
                      badgeContent={parentItem.badge < 100 ? parentItem.badge : '99+'} 
                      className={classes.listItemBadge}
                    >
                      <parentItem.icon/>
                    </Badge> :
                  // WITHOUT BADGE
                    <parentItem.icon/>}
                </ListItemIcon>

                {/* TEXT */}
                {isDrawerExpanded &&
                <ListItemText
                  primary={
                    parentItem.badge ? 
                    // WITH BADGE
                      <Badge 
                        badgeContent={parentItem.badge < 100 ? parentItem.badge : '99+'} 
                        className={classes.listItemBadge}
                      >
                        {parentItem.text}
                      </Badge> :
                    // WITHOUT BADGE
                      parentItem.text
                  }
                />}

                {/* EXPAND/COLLAPSE ICON */}
                {(parentItem.type === 'collection' && isDrawerExpanded) &&
                (expandParent === parentItem.text ? <IconArrowDropUp/> : <IconArrowDropDown/>)}

                {/* EXTRA ITEMS ON PARENT IS HOVERED AND DRAWER IS COLLAPSED */}
                <Popper 
                  ref={popperRef}
                  className={classes.extraList}
                  open={Boolean(!isDrawerExpanded && hoveredParent === parentIndex)}
                  anchorEl={extraAnchorEl}
                  // onClose={() => setExtraAnchorEl(null)}
                  // onMouseLeave={() => setExtraAnchorEl(null)}
                  placement='right-start'
                >
                  {/* PARENT */}
                  {parentItem.type === 'single' &&
                  <CustomListItemButton
                    className={
                      isAccessMenu(parentItem.accessMenu) 
                        ? (isActive(parentItem.activeValue) ? `${classes.listItem} ${classes.listItemSelected}` : classes.listItem) 
                        : classes.hidden
                    }
                    onClick={() => handleParentItemClick(parentItem, parentIndex)}
                  >
                    <ListItemText primary={parentItem.text}/>
                  </CustomListItemButton>}

                  {/* CHILDREN */}
                  {parentItem.type === 'collection' &&
                  parentItem.children.map((childrenItem, childrenIndex) => (
                    <CustomListItemButton 
                      key={childrenIndex}
                      className={isActive(childrenItem.activeValue) ? `${classes.listItem} ${classes.listItemSelected}` : classes.listItem}
                      onClick={() => handleChildrenItemClick(childrenItem, childrenIndex)}
                    >                  
                      {/* TEXT */}
                      <ListItemText primary={childrenItem.text}/>
                    </CustomListItemButton>
                  ))}
                </Popper>
              </CustomListItemButton>
              
              {/* CHILDREN */}
              <Collapse 
                in={(parentItem.children && expandParent === parentItem.text) && isDrawerExpanded} 
                timeout='auto' 
              >
                {parentItem.children &&
                parentItem.children.map((childrenItem, childrenIndex) => {
                  return (
                    <CustomListItemButton 
                      key={childrenIndex}
                      className={isActive(childrenItem.activeValue) ? `${classes.listItem} ${classes.listItemSelected}` : classes.listItem}
                      onClick={() => handleChildrenItemClick(childrenItem, childrenIndex)}
                      sx={{ display: isAccessMenu(childrenItem.accessMenuChild) ? 'block' : 'none' }}
                    >                  
                      {/* TEXT */}
                      <ListItemText
                        className={classes.listItemChildren}
                        primary={childrenItem.text}
                      />
                    </CustomListItemButton>
                  )
                })}
              </Collapse>
            </React.Fragment>
          )
        })}
      </List>
    </CustomDrawer>
  )
}

export default Drawer