// MUI STYLES
import { makeStyles } from '@mui/styles'

// CONSTAN
import {colors} from 'constants/colors'

const useStyles = makeStyles((theme) => ({
  mainContainer : {
    width : '100%',
    height : 97,
    display : 'flex',
    flexDirection : 'column',
    backgroundColor : colors.ebonyClay,
    borderRadius : 4,
    boxShadow : '0px -1px 4px rgba(145, 158, 171, 0.1), 0px 2px 6px rgba(145, 158, 171, 0.07), 0px 2px 10px rgba(145, 158, 171, 0.05), 0px 7px 10px rgba(145, 158, 171, 0.08)',
  },
  header : {
    display : 'flex',
    flexDirection : 'row',
    justifyContent : 'space-between',
    alignItems : 'center',
    padding : '8px 12px'
  },
  titleContainer : {
    display : 'flex',
    flexDirection : 'row',
    alignItems : 'center',
  },
  title : {
    fontWeight : 700,
    fontSize : 12,
    color : 'white'
  },
  dotTitle : {
    width : 6,
    color : colors.scarlet,
    marginRight : 4,
  },
  videoCamIcon : {
    width : 16,
    color : 'white'
  },
  content: {
    width : '100%',
    backgroundColor : 'white',
  },
  contentVideo: {
    backgroundColor: colors.athensGray,
    height: '175px',
    position: 'relative',
    '& .agora_video_player': {
      objectFit: 'contain !important',
    },
    '& .btn-action-pause': {
      display: 'none'
    },
    '&:hover .btn-action-pause': {
      display: 'block'
    }
  },
  detailListWrap : {
    width : '100%',
    backgroundColor : 'white',
    display : 'flex',
    justifyContent : 'center',
    alignItems : 'center',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    boxShadow: '0 0 12px rgba(0, 0, 0, 0.10)'
  },
  listContainer : {
    width : '100%',
    paddingTop : 0,
    paddingBottom : 0,
    height : 62,
    display : 'flex',
    alignItems : 'center',
    justifyContent : 'center'
  },
  avatarSize : {
    width : 20,
    height : 20,
    fontSize : '10px',
    fontWeight: 600
  },
  listItemAvatar : {
    minWidth : 28,
  },
  listItemText : {
    '& .MuiListItemText-secondary' : {
      fontSize : '12px !important',
    }
  },
  detailIcon : {
    width : 20,
    height : 20,
  },
  detailNotAudioIcon: {
    width : 20,
    height : 20,
    color: colors.carnation
  },
  listItem : {
    paddingLeft : 12,
  },
  buttonPlay: {
    position: 'absolute',
    zIndex: 99,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '& .MuiLoadingButton-loadingIndicator': {
      color: colors.clementine,
    },
    '& .MuiCircularProgress-root': {
      width: '33px !important',
      height: '33px !important'
    },
  },
  butonPlayIcon: {
    width: '33px',
    height: '33px',
    color: colors.raven,
    '&:hover': {
      color: colors.clementine
    }
  },
  buttonDetailVideo: {
    padding: 0,
    marginRight: '10px',
    '&:last-child': {
      marginRight: 0
    }
  }
}))

export default useStyles
