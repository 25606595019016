import React, { useState } from 'react'

// CUSTOM COMPONENTS
import CustomTextField from 'components/Customs/CustomTextField'

// MUIS
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconCheck from '@mui/icons-material/Check'
import IconEdit from '@mui/icons-material/Edit'
import IconUndo from '@mui/icons-material/Undo'

// STYLES
import useStyles from './informationEditTextUseStyles'

const InformationEditText = (props) => {
  const {
    label,
    editable,
    initialValue,
    inputName,
    informationParams,
    setInformationParams,
  } = props

  const classes = useStyles()

  const [isHovered, setIsHovered] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [textFieldValue, setTextFieldValue] = useState(initialValue)

  const handleUndoIconClick = () => {
    setIsEditing(false)
    setTextFieldValue(initialValue)
  }

  const handleCheckIconClick = () => {
    setIsEditing(false)
    setInformationParams({
      ...informationParams,
      inputName: textFieldValue,
    })
  }

  return (
    <Box
      className={classes.informationEditTextRoot}
      onMouseEnter={() => editable && setIsHovered(true)}
      onMouseLeave={() => editable && setIsHovered(false)}
    >
      <Box className={classes.labelContainer}>
        {/* LABEL */}
        <Typography variant='subtitle2' className={classes.label}>
          {label}
        </Typography>

        {/* EDIT ICON */}
        {isHovered && !isEditing && (
          <IconEdit
            className={`${classes.iconAction} ${classes.iconActionEdit}`}
            onClick={() => setIsEditing(true)}
          />
        )}

        {isEditing && (
          <>
            {/* UNDO ICON */}
            <IconUndo
              color='primary'
              className={classes.iconAction}
              onClick={handleUndoIconClick}
            />

            {/* CHECK ICON */}
            <IconCheck
              color='primary'
              className={classes.iconAction}
              onClick={handleCheckIconClick}
            />
          </>
        )}
      </Box>

      {/* VALUE */}
      {!isEditing && (
        <Typography variant='subtitle2' className={classes.text}>
          {textFieldValue || textFieldValue !== ''
            ? textFieldValue
            : '-'}
        </Typography>
      )}

      {/* TEXT FIELD */}
      {isEditing && (
        <CustomTextField
          autoFocus
          variant='standard'
          className={classes.textField}
          value={textFieldValue}
          onChange={(event) => setTextFieldValue(event.target.value)}
        />
      )}
    </Box>
  )
}

export default InformationEditText
