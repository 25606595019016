// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 24,
    position: 'relative',
  },
  groupingRow: {
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  columnStatus: {
    font: 'inherit',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    textTransform: 'capitalize',
    '&::before': {
      content: '""',
      width: 10,
      height: 10,
      backgroundColor: colors.boulder,
      borderRadius: '50%',
      marginRight: 10,
    },
  },
  columnStatusBlue: {
    '&::before': {
      backgroundColor: colors.denim,
    },
  },
  columnStatusRed: {
    '&::before': {
      backgroundColor: colors.carnation,
    },
  },
  columnStatusGreen: {
    '&::before': {
      backgroundColor: colors.atlantis,
    },
  },
  columnStatusYellow: {
    '&::before': {
      backgroundColor: colors.amber,
    },
  },
  columnDispatchStatus: {
    fontSize: 'inherit',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  columnDispatchStatusRed: {
    color: colors.carnation,
    backgroundColor: alpha(colors.carnation, 0.1),
  },
  columnDispatchStatusRedIcon: {
    color: `${colors.carnation} !important`,
  },
  columnDispatchStatusGreen: {
    color: colors.atlantis,
    backgroundColor: alpha(colors.atlantis, 0.1),
  },
  columnDispatchStatusGreenIcon: {
    color: `${colors.atlantis} !important`,
  },
  normalText: {
    textTransform: 'capitalize',
  },
  columnFlexColumn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  secondaryText: {
    color: theme.palette.text.secondary,
  },
  columnVehicle: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  columnVehicleIcon: {
    color: colors.boulder,
    marginRight: 10,
  },
  columnOfficerNames: {
    fontSize: 12,
    textTransform: 'capitalize',
    backgroundColor: colors.cornflowerBlue,
    color: 'white',
  },
  columnActions: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowStatus: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  columnFileType: {
    color: colors.spunPearl,
    cursor: 'pointer',
  },
}))

export default useStyles
