import { useState, useEffect, useContext } from 'react'

// COMPONENTS
import MenuAutocomplete from '../MenuAutocomplete/MenuAutocomplete'
import RenderIncomingMobileApp from './RenderIncomingMobileApp'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Badge from '@mui/material/Badge'
import Stack from '@mui/material/Stack'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

// MUI ICONS
import IconAdjust from '@mui/icons-material/Adjust'
import IconAdUnits from '@mui/icons-material/AdUnits'
import IconAssignment from '@mui/icons-material/Assignment'
import IconGroup from '@mui/icons-material/Group'
import IconPhoneAndroid from '@mui/icons-material/PhoneAndroid'
import IconPhonelinkSetup from '@mui/icons-material/PhonelinkSetup'
import IconReceipt from '@mui/icons-material/Receipt'
import IconSecurityUpdate from '@mui/icons-material/SecurityUpdate'
import IconSmartphone from '@mui/icons-material/Smartphone'
import IconTextSnippet from '@mui/icons-material/TextSnippet'

// PATH ICONS
import IconPoliceStation from 'assets/images/pathIcons/IconPoliceStation'

// SERVICES
import { 
  changeDeviceGroup,
  searchGroups, 
} from 'services/DeviceService'
import { getRoles } from 'services/UserService'

// STYLES
import useStyles from './mobileAppDetailUseStyles'

const TabInformation = (props) => {
  const { 
    deviceDetail, 
    reloadData,
    closeFlyout,
  } = props

  const classes = useStyles()

  const { setSnackbarObject } = useContext(AllPagesContext)

  const [ groupList, setGroupList ] = useState([])
  const [ selectedGroup, setSelectedGroup ] = useState(null)
  const [ departmentList, setDepartmentList ] = useState([])
  const [ selectedDepartment, setSelectedDepartment ] = useState(null)

  const updateGroupOrDepartment = async (option) => {
    const bodyParameter = {}

    if (selectedGroup) bodyParameter.expectedGroupNo = selectedGroup.groupNo
    if (selectedDepartment) bodyParameter.roleNo = selectedDepartment.id

    if (typeof option?.groupName === 'string') bodyParameter.expectedGroupNo = option.groupNo
    else if (typeof option?.roleName === 'string') bodyParameter.roleNo = option.id

    const resultChangeGroupAndDepartment = await changeDeviceGroup(
      deviceDetail.deviceNo,
      bodyParameter,
    )

    if (resultChangeGroupAndDepartment?.status !== 'error') setSnackbarObject({
      open: true,
      severity: 'success',
      title: '',
      message: 'Sukses Mengubah Informasi Perangkat',
    })
    else setSnackbarObject({
      open: true,
      severity: 'error',
      title: '',
      message: 'Gagal Mengubah Informasi Perangkat',
    })
  }

  let informationList = deviceDetail ? [
    deviceDetail?.status === 'JUST_REGISTERED' && {
      icon: IconAdUnits,
      isWithBadge: true,
      label: 'Perangkat Masuk',
      reference: deviceDetail?.connection_status,
      className: classes.backgroundColorPrimary012,
      renderValue: (
        <RenderIncomingMobileApp
          deviceDetail={deviceDetail}
          reloadData={reloadData}
          closeFlyout={closeFlyout}
        />
      ),
    },
    {
      icon: IconAdjust,
      label: 'Status',
      reference: deviceDetail?.connection_status,
    },
    {
      icon: IconAssignment,
      label: 'Label',
      reference: deviceDetail?.label,
    },
    {
      icon: IconReceipt,
      label: 'Pengenal',
      reference: deviceDetail?.deviceCode,
    },
    {
      icon: IconPhoneAndroid,
      label: 'Model Perangkat',
      reference: deviceDetail?.deviceModel,
    },
    {
      icon: IconSecurityUpdate,
      label: 'Versi Perangkat',
      reference: deviceDetail?.deviceOSVersion,
    },
    {
      icon: IconSmartphone,
      label: 'Versi Aplikasi Perangkat',
      reference: deviceDetail?.deviceAppVersion,
    },
    {
      icon: IconPhonelinkSetup,
      label: 'Lokal Perangkat',
      reference: deviceDetail?.deviceLanguage,
    },
    {
      icon: IconTextSnippet,
      label: 'Keterangan',
      reference: deviceDetail?.description,
    },
    {
      icon: IconGroup,
      label: 'Kelompok',
      reference: true,
      renderValue: (
        <MenuAutocomplete
          label='Kelompok'
          initialOptionList={groupList}
          selectedValue={selectedGroup} 
          setSelectedValue={setSelectedGroup}
          menuClassName={classes.menuAutocomplete}
          onMenuClose={updateGroupOrDepartment}
        />
      ),
    },
    {
      icon: IconPoliceStation,
      label: 'Departemen',
      reference: true,
      renderValue: (
        <MenuAutocomplete
          label='Departemen'
          initialOptionList={departmentList}
          selectedValue={selectedDepartment} 
          setSelectedValue={setSelectedDepartment}
          menuClassName={classes.menuAutocomplete}
          onMenuClose={updateGroupOrDepartment}
        />
      ),
    },
  ] : []

  informationList = informationList.filter(item => item)

  const getGroupList = async () => {
    const resultGroupList = await searchGroups({
      page: 0,
      size: 10000,
    }, {})

    if (resultGroupList.rows.length > 0) {
      const newGroupList = resultGroupList.rows.map(item => {
        return {
          ...item,
          label: item.groupName,
          isShown: true,
        }
      })

      setGroupList(newGroupList)
    }
  }

  const getDepartmentList = async () => {
    const resultdepartmentList = await getRoles({
      page: 0,
      size: 10000,
    })

    if (resultdepartmentList.length > 0) {
      const newdepartmentList = resultdepartmentList.map(item => {
        return {
          ...item,
          label: item.roleName,
          isShown: true,
        }
      })

      setDepartmentList(newdepartmentList)
    }
  }

  useEffect(() => {
    getGroupList()
    getDepartmentList()
  }, [])

  useEffect(() => {
    if (groupList.length > 0 && (typeof deviceDetail?.groupNo !== 'undefined' && deviceDetail?.groupNo !== null)) {
      const foundGroup = groupList.find(item => item.groupNo === deviceDetail.groupNo)
      setSelectedGroup(foundGroup)
    }
    else setSelectedGroup(null)

    if (departmentList.length > 0 && (typeof deviceDetail?.roleNo !== 'undefined' && deviceDetail?.roleNo !== null)) {
      const foundDepartment = departmentList.find(item => item.id === deviceDetail.roleNo)
      setSelectedDepartment(foundDepartment)
    }
    else setSelectedDepartment(null)
  }, [deviceDetail?.groupNo, deviceDetail?.roleNo, groupList, departmentList])

  return (
    <Stack
      marginTop='50px'
      overflow='auto'
      flex='1'
      paddingTop='16px'
      className={classes.content}
    >
      {/* INFORMATION LIST */}
      <List disablePadding>
        {informationList.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            className={`${classes.informationListItem} ${item.className}`}
          >
            {/* ICON */}
            <Badge 
              color='error' 
              variant='dot' 
              invisible={!item.isWithBadge}
              className=''
            >
              <ListItemIcon>
                <item.icon/>
              </ListItemIcon>
            </Badge>

            {/* TEXT */}
            <ListItemText
              primary={item.label}
              secondary={item.reference 
                ? (item.renderValue ? item.renderValue : item.reference) 
                : 'Tidak ada data'
              }
              className={(item.renderValue || item.reference) ? '' : classes.informationListItemSecondary}
            />
          </ListItem>
        ))}
      </List>
    </Stack>
  )
}

export default TabInformation