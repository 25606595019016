import { useState, useEffect } from 'react'

// COMPONENTS
import DetailTabEvidences from '../DetailTabEvidences/DetailTabEvidences'
import DetailTabInformation from '../DetailTabInformation/DetailTabInformation'

// CONSTANTS
import { colors } from 'constants/colors'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useTheme } from '@mui/material/styles'

// MUI ICONS
import IconDownload from '@mui/icons-material/Download'

// SERVICES
import { 
  downloadPDF, 
  getPatroli, 
} from 'services/DispatchService'
import { getEvidences } from 'services/EvidenceService'
import { getTagEvidencesList } from 'services/TagEvidencesService'

// STYLES
import useStyles from './patrolsDetailUseStyles'

// UTILITIES
import { generateMediaUrlBySize } from 'utilities/media'

const PatrolDetail = (props) => {
  const { rowItem } = props

  const initialTabList = [
    {
      value: 0,
      label: 'Kejadian',
    },
    {
      value: 1,
      label: 'Informasi',
    },
  ]

  const [ tabList, setTabList ] = useState(initialTabList)
  const [ selectedTab, setSelectedTab ] = useState(initialTabList[0].value)
  const [ evidenceList, setEvidenceList ] = useState([])
  const [ informationDetail, setInformationDetail ] = useState(null)

  const theme = useTheme()

  const classes = useStyles()

  const downloadReportPatrol = async () => {
    const currentDate = new Date()

    const result = await downloadPDF(rowItem.patrolNo, {
      timezoneOffset: -currentDate.getTimezoneOffset() / 60,
    })
    
    if (result.status !== 'error') {
      var a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'

      const blob = new Blob([result], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)

      a.href = url
      a.download = 'laporan_giat.pdf'
      a.click()

      window.URL.revokeObjectURL(url)
    }
  }

  const getReportPatrolDetail = async () => {
    const result = await getPatroli(rowItem.patrolNo)
    
    if (result.status !== 'error') {
      let officerList = []
        
      if (result?.officerNames?.includes(',')) {
        officerList = result.officerNames.split(',')
      }
      else if (result?.officerNames) {
        officerList = [ result.officerNames ]
      }

      let shiftObject = {}

      if (result?.shift === '08.00 WIB s/d 20.00 WIB') {
        shiftObject = {
          label: 'Day',
          color: colors.amber,
        }
      }
      else if (result?.shift === '20.00 WIB s/d 08.00 WIB') {
        shiftObject = {
          label: 'Night',
          color: colors.lilacBush,
        }
      }

      const newInformationDetail = {
        ...result,
        officerList,
        shiftObject,
        activityType: { ...rowItem.activityType },
        vehicleType: { ...rowItem.vehicleType },
      }

      setInformationDetail(newInformationDetail)
    }
  }

  const getReportEvidenceList = async () => {
    const resultEvidenceList = await getEvidences({
      page: 0,
      size: 10000,
    }, { patrolNo: rowItem.patrolNo })

    const resultTagEvidenceList = await getTagEvidencesList({
      page: 0,
      size: 10000,
    })

    if (resultEvidenceList?.status !== 'error' && resultTagEvidenceList?.status !== 'error') {
      // EVIDENCE LIST WITH UPDATED TAGS
      const newEvidenceList = resultEvidenceList.rows.map(evidenceItem => {
        const newTagList = evidenceItem.tags.map(evidenceTagItem => {
          const tagEvidenceObject = resultTagEvidenceList.rows.find(tagItem => tagItem.tagNo === evidenceTagItem.tagNo)

          return {
            ...evidenceTagItem,
            ...tagEvidenceObject,
          }
        })

        return {
          ...evidenceItem,
          tags: [...newTagList],
        }
      })

      // EVIDENCE LIST WITH UPDATED MEDIAS
      const finalEvidenceList = newEvidenceList.map(evidenceItem => {
        const newMediaList = evidenceItem.medias.map(mediaItem => {
          return generateMediaUrlBySize(mediaItem)
        })

        return {
          ...evidenceItem,
          medias: newMediaList,
        }
      })

      setEvidenceList(finalEvidenceList)
      setTabList(current => {
        return [
          {
            value: 0,
            label: `Kejadian (${finalEvidenceList.length})`,
          }, 
          current[1],
        ]
      })
    }
  }

  useEffect(() => {
    getReportPatrolDetail()
    getReportEvidenceList()
  }, [rowItem])

  return (
    <Stack 
      position='relative'
      maxHeight='100%'
    >
      {/* HEADER */}
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        borderBottom={`1px solid ${theme.palette.divider}`}
        position='absolute'
        width='100%'
        zIndex={1}
      >
        {/* TABS */}
        <Tabs 
          value={selectedTab} 
          onChange={(event, newValue) => setSelectedTab(newValue)}
        >
          {tabList.map((item, index) => (
            <Tab 
              key={index}
              label={item.label}
              className={classes.headerTab}
            />
          ))}
        </Tabs>

        {/* DOWNLAOD ICON */}
        {rowItem?.clockedOutTime &&
        <CustomTooltipBlack
          title='Unduh Kegiatan'
          placement='top'
        >
          <IconButton onClick={downloadReportPatrol}>
            <IconDownload/>
          </IconButton>
        </CustomTooltipBlack>}
      </Stack>

      {/* EVIDENCE LIST */}
      {selectedTab === 0 &&
      <DetailTabEvidences evidenceList={evidenceList} reloadDetail={getReportEvidenceList}/>}

      {/* INFORMATION LIST */}
      {selectedTab === 1 &&
      <DetailTabInformation 
        rowItem={rowItem}
        informationDetail={informationDetail}
      />}
    </Stack>
  )
}

export default PatrolDetail