// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconFire = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M16.4563 6.13231C16.4563 6.13231 15.7946 8.77937 14.1402 8.77937C10.5005 8.77937 11.824 2.82349 11.824 2.82349C11.824 2.82349 8.51517 4.80878 7.52252 10.1029C6.19899 9.44113 5.86811 7.45584 5.86811 7.45584C5.86811 7.45584 3.88281 9.77202 3.88281 13.4117C3.88281 20.3603 10.5005 21.022 10.5005 21.022C10.5005 21.022 8.84605 19.0367 8.84605 16.7205C8.84605 13.0808 10.3999 11.4264 10.3999 11.4264C10.3999 11.4264 10.7308 15.0661 12.8166 15.0661C14.1402 15.0661 14.8019 14.0735 14.8019 14.0735C14.8019 14.0735 16.1255 15.397 16.1255 17.7132C16.1255 19.8136 14.471 21.022 14.471 21.022C17.938 20.3252 20.4269 17.1209 20.4269 13.4574C20.4269 9.11025 16.4563 6.13231 16.4563 6.13231Z'/>
    </SvgIcon>
  )
}

export default IconFire