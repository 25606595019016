import React, { createContext, useState } from 'react'
import { useLocation } from 'react-router-dom'

const PrivateLayoutContext = createContext()

const PrivateLayoutContextProvider = (props) => {
  const location = useLocation()

  let initialIsDrawerExpanded
  if (location.state && location.state.lastClicked === 'children' && !location.state.isDrawerExpanded) initialIsDrawerExpanded = false
  else initialIsDrawerExpanded = true

  const [ isDrawerExpanded, setIsDrawerExpanded ] = useState(initialIsDrawerExpanded)
  const [ isDialogAddOrEditExpanded, setIsDialogAddOrEditExpanded ] = useState(false)

  const changeIsDrawerExpanded = (inputState) => {
    setIsDrawerExpanded(inputState)
  }

  return (
    <PrivateLayoutContext.Provider
      value={{
        isDrawerExpanded, changeIsDrawerExpanded,
        isDialogAddOrEditExpanded, setIsDialogAddOrEditExpanded,
      }}
    >
      {props.children}
    </PrivateLayoutContext.Provider>
  )
}

export { PrivateLayoutContextProvider, PrivateLayoutContext }