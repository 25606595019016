import ReactDOMServer from 'react-dom/server'

// ASSETS
import markerConference from 'assets/images/markers/markerConference.svg'
import markerEvidence from 'assets/images/markers/markerEvidence.svg'

// LEAFLET
import L from 'leaflet'

// MUIS
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

// MUI ICONS
import IconSos from '@mui/icons-material/Sos'

const MarkerAlert = (latLng, type = ['evidence', 'sos', 'conference']) => {
  const getMarkerByType = () => {
    if (type === 'evidence') return markerEvidence
    else if (type === 'conference') return markerConference
  }

  return L.marker(latLng, {
    icon: L.divIcon({
      className: 'marker-alert',
      html: ReactDOMServer.renderToString(<Stack
        style={{
          position: 'relative',
          flexDirection: 'row',
          justifyContent: 'center'
        }}
      >
        {type !== 'sos' && <Box
          component='img'
          src={getMarkerByType()}
          style={{
            width: '32px',
          }}
        />}

        {type === 'sos' && <Stack
          className='circleRipple'
          style={{
            position: 'relative',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <IconSos />
        </Stack>}
      </Stack>),
      iconSize: [36, 36],
    })
  })
}

export default MarkerAlert