// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  evidenceItem: {
    height: 220,
    borderRadius: 4,
  },
  pagination: {
    minHeight: 'unset',
    paddingLeft: 'unset',
    fontSize: 12,
    '& .MuiTablePagination-selectLabel': {
      fontSize: 12,
    },
    '& .MuiTablePagination-select': {
      fontSize: 12,
    },
    '& .MuiTablePagination-displayedRows': {
      fontSize: 12,
    },
    '& .MuiIconButton-root': {
      padding: 8,
    },
    '@media only screen and (max-height: 820px)': {
      '& .MuiToolbar-root': {
        zoom: 1/0.85,
      },
      '& .MuiTablePagination-selectLabel': {
        zoom: 0.85,
      },
      '& .MuiTablePagination-displayedRows': {
        zoom: 0.85,
      },
      '& .MuiTablePagination-actions': {
        zoom: 0.85,
      },
      '& .MuiDataGrid-footerContainer .MuiInputBase-root': {
        zoom: 1/0.85,
      },
      '& .MuiDataGrid-footerContainer .MuiTablePagination-select': {
        zoom: 0.85,
        fontSize: 10,
      },
      '& .MuiDataGrid-footerContainer .MuiSelect-nativeInput': {
        zoom: 0.85,
      },
      '& .MuiDataGrid-footerContainer .MuiSvgIcon-root': {
        zoom: 0.85,
      }
    }
  },
}))

export default useStyles
