// PAGES
import Login from 'pages/Login/Login'
import ResetPassword from 'pages/ResetPassword'
import ForgotPassword from 'pages/ForgotPassword'

const authRouteList = [
  // LOGIN
  {
    isExact: true,
    path: '/login',
    component: Login,
    isYScrollable: false,
    isWithDrawer: false,
    routeType: 'auth',
    providerContext: null
  },
  // FORGOT PASSWORD //
  {
    isExact: true,
    path: '/forgot-password',
    component: ForgotPassword,
    isYScrollable: false,
    isWithDrawer: false,
    routeType: 'auth',
    providerContext: null
  },
  // RESET PASSWORD
  {
    isExact: true,
    path: '/reset-password/:resetPasswordToken',
    component: ResetPassword,
    isYScrollable: false,
    isWithDrawer: false,
    routeType: 'auth',
    providerContext: null
  }
]

export default authRouteList