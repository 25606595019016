// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  mainBox:{
    maxWidth : 300,
    width : '100%',
    height : '100%',
    borderRight: `1px solid ${alpha('#000000', 0.1)}`,
  },
  panelContainer: {
    backgroundColor: 'white',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.shadows[4],
    borderRadius: 4,
    marginBottom: 10,
    position: 'relative',
  },
  panelContent: {
    overflowY: 'auto',
    height: '100%'
  },
  objectDataGrid: {
    '& .MuiDataGrid-columnHeaders': {
      borderLeft: '4px solid transparent !important'
    }
  },
}))

export default useStyles