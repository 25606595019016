// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  menu: {
    '& .MuiPaper-root': {
      borderRadius: 0,
      width: 336,
      maxHeight: 400,
    },
    '& .MuiList-root': {
      padding: 0,
    },
  },
  searchInput: {
    paddingLeft: 16,
    paddingRight: 8,
    height: 48,
    '& .MuiInputLabel-root': {
      fontSize: 12,
    },
    '& .MuiInputBase-input': {
      fontSize: 12,
    },
  },
  optionListItemActive: {
    '& .MuiTypography-root': {
      color: theme.palette.primary.main,
    },
  },
}))

export default useStyles