import { useState, useRef, useEffect, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DataGridHeaderCell from 'components/DataGridHeaderCell/DataGridHeaderCell'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import DialogAddOrEditDispatchEvidences from './DialogAddOrEditDispatchEvidences/DialogAddOrEditDispatchEvidences'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import Flyout from 'components/Flyout/Flyout'
import FlyoutDispatchEvidencesDetail from './FlyoutDispatchEvidencesDetail/FlyoutDispatchEvidencesDetail'
import FullViewDescription from './FullViewDescription/FullViewDescription'
import LoadingBox from 'components/LoadingBox/LoadingBox'
import PageHeader from 'components/PageHeader/PageHeader'

// CONSTANTS
import { colors } from 'constants/colors'
import { basePaths } from 'constants/paths'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// LIBRARY
import moment from 'moment'

// MUIS
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import { grey } from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconCheckCircleOutline from '@mui/icons-material/CheckCircleOutline'
import IconDateRange from '@mui/icons-material/DateRange'
import IconDelete from '@mui/icons-material/Delete'
import IconErrorOutline from '@mui/icons-material/ErrorOutline'
import IconEdit from '@mui/icons-material/Edit'
import IconKey from 'assets/images/pathIcons/IconKey'
import IconPhoneAndroid from '@mui/icons-material/PhoneAndroid'
import IconTextFields from '@mui/icons-material/TextFields'
import IconWysiwyg from '@mui/icons-material/Wysiwyg'

// PATH ICONS
import IconCircleAround from 'assets/images/pathIcons/IconCircleAround'
import IconMapPin from 'assets/images/pathIcons/IconMapPin'
import IconRealtime from 'assets/images/pathIcons/IconRealtime'

// RAMDA
import { isEmpty, reject } from 'ramda'

// SERVICES
import { gets } from 'services/DeviceService'
import { deleteDispatch, postDispatchSearch } from 'services/DispatchService'

// STYLES
import useStyles from './dispatchEvidencesUseStyles'

// UTILS
import {
  getColorCategoryDispatch, getColorStatusDispatch,
  getTextCategoryDispatch, getTextStatusDispatch,
  groupByList, initialFilters
} from './dispatchEvidencesUtils'

const DispatchEvidences = () => {
  const classes = useStyles()
  const pageRef = useRef()
  const history = useHistory()
  const location = useLocation()
  const { setSnackbarObject } = useContext(AllPagesContext)

  const flyoutWidth = 528
  const flyoutTitleMargin = 496

  // INITIAL COLUMNS
  const initialColumns = [
    {
      field: 'dispatchNo',
      headerName: 'ID',
      headerIcon: IconKey,
      flex: 1,
      minWidth: 140,
      hide: false,
      areFilterAndSortShown: false,
      renderCell: (params) => (
        params.value && 
        <Chip
          variant='outlined'
          className={`${classes.columnChipFilled} ${classes.columnChipId}`}
          label={params?.value}
        />
      )
    },
    {
      field: 'dispatchStatuses',
      headerName: 'Status',
      headerIcon: IconCircleAround,
      flex: 1,
      minWidth: 160,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => (
        params.row.status && <Stack direction='row' alignItems='center'>
          <Box
            className={classes.columnCircleStatus}
            sx={{
              backgroundColor: getColorStatusDispatch(params?.row.status?.toLowerCase())
            }}
          ></Box>
          <Typography className={classes.columnTextStatus} variant='body2'>
            {getTextStatusDispatch(params?.row.status?.toLowerCase())}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'deviceLabel',
      headerName: 'Perangkat',
      headerIcon: IconPhoneAndroid,
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => (
        params.value && 
        <Chip
          className={classes.columnChipFilled}
          icon={<IconPhoneAndroid fontSize='small' />}
          label={params?.value}
          sx={{ backgroundColor: colors.raven }}
        />
      )
    },
    {
      field: 'dispatchCategory',
      headerName: 'Kategori',
      headerIcon: IconWysiwyg,
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => (
        params?.row?.category && <Chip
          variant='outlined'
          className={classes.columnChipOutlined}
          icon={params.row.category?.toLowerCase() === 'priority'
            ? <IconErrorOutline fontSize='small'/>
            : <IconCheckCircleOutline fontSize='small'/>
          }
          label={getTextCategoryDispatch(params?.row.category?.toLowerCase())}
          sx={{
            borderColor: getColorCategoryDispatch(params?.row.category?.toLowerCase()),
            color: getColorCategoryDispatch(params?.row.category?.toLowerCase()),
            '& .MuiSvgIcon-root': {
              color: getColorCategoryDispatch(params?.row.category?.toLowerCase()),
            }
          }}
        />
      )
    },
    {
      field: 'label',
      headerName: 'Judul',
      headerIcon: IconTextFields,
      flex: 1,
      minWidth: 260,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => (
        params.value && <Typography className={classes.columnTitleText} variant='body2' noWrap>{params?.value}</Typography>
      )
    },
    {
      field: 'startTime',
      headerName: 'Tanggal Mulai',
      headerIcon: IconDateRange,
      flex: 1,
      minWidth: 180,
      hide: false,
      areFilterAndSortShown: true,
      valueGetter: params => params.value && moment(params.value).format('DD MMM yyyy - HH:mm')
    },
    {
      field: 'endTime',
      headerName: 'Tanggal Akhir',
      headerIcon: IconDateRange,
      flex: 1,
      minWidth: 180,
      hide: false,
      areFilterAndSortShown: true,
      valueGetter: params => params.value && moment(params.value).format('DD MMM yyyy - HH:mm')
    },
    {
      field: 'address',
      headerName: 'Lokasi',
      headerIcon: IconMapPin,
      flex: 1,
      minWidth: 320,
      hide: false,
      areFilterAndSortShown: true,
      valueGetter: params => params?.value?.fullAddress
    },
    {
      field: 'acceptedTime',
      headerName: 'Aktual',
      headerIcon: IconRealtime,
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: false,
      renderCell: (params) => (
        params.value && <Typography
          className={classes.columnActualText}
          variant='body2'
          noWrap
        >
          {params.value ? moment(params.value).format('DD MMM yyyy - HH:mm') : '-'}
        </Typography>
      )
    },
    {
      field: 'actions',
      headerName: '',
      width: 120,
      hide: false,
      areFilterAndSortShown: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box className={classes.columnActions}>
          {/* EDIT ICON */}
          <CustomTooltipBlack title='Ubah' placement='bottom'>
            <IconButton onClick={event => handleEditIconButtonClick(event, params)}>
              <IconEdit />
            </IconButton>
          </CustomTooltipBlack>

          {/* DELETE ICON */}
          <CustomTooltipBlack title='Hapus' placement='bottom'>
            <IconButton
              onClick={event => {
                event.stopPropagation()
                // DELETE ONLY FOR ASSIGNED STATUS
                params.row.status === 'ASSIGNED' && setDialogDeleteEvidence(params.row)
              }}
              sx={{
                cursor: params.row.status !== 'ASSIGNED' ? 'not-allowed' : 'pointer'
              }}
            >
              <IconDelete
                sx={{
                  color: theme => params.row.status !== 'ASSIGNED' ? grey[400] : theme.palette.action
                }}
              />
            </IconButton>
          </CustomTooltipBlack>
        </Box>
      ),
    },
  ]

  // STATES
  const [selectedGroupBy, setSelectedGroupBy] = useState(groupByList[0])
  const [selectedColumnList, setSelectedColumnList] = useState(initialColumns)
  const [selectionModel, setSelectionModel] = useState([])
  const [filters, setFilters] = useState(initialFilters)
  const [dispatchTableData, setDispatchTableData] = useState([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [size, setPageSize] = useState(100)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('dispatchNo')
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isFilterOn, setIsFilterOn] = useState(false)
  const [dialogAddOrEditDispatch, setDialogAddOrEditDispatch] = useState(null)
  const [isFlyoutShown, setIsFlyoutShown] = useState(false)
  const [isFullViewShown, setIsFullViewShown] = useState(false)
  const [itemDetail, setItemDetail] = useState({})
  const [dialogDeleteEvidence, setDialogDeleteEvidence] = useState({})

  const handleEditIconButtonClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()

    history.push(
      `${basePaths.dispatchEvidences}?mode=edit&id=${inputParams.id}`, 
      {
        mode: 'edit',
        data: inputParams.row,
      }
    )
  }

  const handleColumnsMenuItemClick = (inputItem, inputIndex) => {
    let tempSelectedColumnList = [...selectedColumnList]
    if(
      selectedGroupBy.value && inputItem.field === 'dispatchStatuses' ||
      selectedGroupBy.value && inputItem.field === 'dispatchNo'
    ) {}
    else {
      tempSelectedColumnList[inputIndex].hide = !tempSelectedColumnList[inputIndex].hide
    }
    setSelectedColumnList(tempSelectedColumnList)
  }

  const groupingColDef = {
    headerName: 'ID',
    renderHeader: (params) => (
      <DataGridHeaderCell
        icon={IconKey}
        headerName='ID'
      />
    ),
    renderCell: (params) => {
      if (!params.rowNode.isAutoGenerated) {
        return (
          <Chip
            variant='outlined'
            className={`${classes.columnChipFilled} ${classes.columnChipId}`}
            label={params.row?.dispatchNo}
          />
        )
      }

      return (
        <Typography 
          variant='subtitle1' 
          className={classes.groupingRow}
          noWrap
        >
          {getTextStatusDispatch(params.rowNode.groupingKey.toLowerCase())}
        </Typography>
      )
    },
  }

  const reloadData = async () => {
    let tempFilters = filters
    if(tempFilters['deviceNo']) tempFilters['deviceNo'] = Number(tempFilters['deviceNo'].toLowerCase().replaceAll(/[a-zA-Z]+/ig, '').replaceAll(/ /ig, ''))
    if(tempFilters['dispatchCategory']) {
      if(tempFilters['dispatchCategory'].toLowerCase() === 'prioritas') tempFilters['dispatchCategory'] = 'PRIORITY'
      else if(tempFilters['dispatchCategory'].toLowerCase() === 'normal') tempFilters['dispatchCategory'] = 'NORMAL'
    }
    if(tempFilters['dispatchStatuses']) {
      if(tempFilters['dispatchStatuses'].toLowerCase() === 'diterima') tempFilters['dispatchStatuses'] = ['ACCEPTED']
      else if(tempFilters['dispatchStatuses'].toLowerCase() === 'selesai') tempFilters['dispatchStatuses'] = ['COMPLETED']
      else if(tempFilters['dispatchStatuses'].toLowerCase() === 'ditugaskan') tempFilters['dispatchStatuses'] = ['ASSIGNED']
      else if(tempFilters['dispatchStatuses'].toLowerCase() === 'gagal') tempFilters['dispatchStatuses'] = ['FAILED']
      else if(tempFilters['dispatchStatuses'].toLowerCase() === 'dibatalkan') tempFilters['dispatchStatuses'] = ['CANCELLED']

    }

    const newFilters = reject(isEmpty, tempFilters)
    const params = {
      page,
      size,
    }

    if (order) params.sort = `${orderBy},${order}`
    if (search) newFilters.globalSearch = search

    setIsLoading(true)

    const responseDispatchSearch = await postDispatchSearch(params, newFilters)
    const responseDeviceSearch = await gets({ size: 10000 }, {})
    
    if (responseDispatchSearch.status !== 'error' && responseDeviceSearch.status !== 'error') {
      const newTableData = responseDispatchSearch.rows.map(dispatchItem => {
        const deviceObject = responseDeviceSearch.rows.find(deviceItem => dispatchItem.deviceNo === deviceItem.deviceNo)
        
        let label = '[Tanpa Label]'
        if (deviceObject) label = deviceObject.label

        return {
          ...dispatchItem,
          id: dispatchItem.dispatchNo,
          deviceLabel: label,
        }
      })

      setDispatchTableData(newTableData)
      setTotal(newTableData.totalElements)
    }

    setItemDetail({})
    setSelectionModel([])
    setIsLoading(false)
  }

  const fetchDetail = (inputId) => {
    const result = dispatchTableData.find(item => item.dispatchNo === inputId)
    setItemDetail(result || {})
  }

  const handleDeleteButtonClick = async () => {
    setIsLoading(true)
    const result = await deleteDispatch(dialogDeleteEvidence.dispatchNo)
    setIsLoading(false)

    if(result.status !== 'error') {
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Berhasil Menghapus Penugasan Kejadian',
      })
      setDialogDeleteEvidence({})
      reloadData()
    }
  }

  useEffect(() => {
    reloadData()
  }, [page, size, order, orderBy, filters, search])

  useEffect(() => {
    if (selectedGroupBy.value) {
      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        if (
          item.field === selectedGroupBy.value ||
          item.field === 'dispatchNo'
        ) {
          item.hide = true
        }
      })
      setSelectedColumnList(tempSelectedColumnList)
    } else {
      let tempSelectedColumnList = [...selectedColumnList]
      tempSelectedColumnList.forEach((item) => {
        if (item.field === 'dispatchStatuses' || item.field === 'dispatchNo')
          item.hide = false
      })
      setSelectedColumnList(tempSelectedColumnList)
    }
  }, [selectedGroupBy])

  useEffect(() => {
    if (location.pathname === basePaths.dispatchEvidences && (location.search.includes('?mode=edit') || location.search.includes('?mode=add'))) {
      if(location.search.includes('&lat=') && location.search.includes('&lng=')) {
        setDialogAddOrEditDispatch({ mode: 'add' })
      } else {
        setDialogAddOrEditDispatch(location?.state || { mode: 'add' })
      }
    }
    else setDialogAddOrEditDispatch(false)
  }, [location])

  useEffect(() => {
    if (selectionModel.length === 1) {
      fetchDetail(Number(selectionModel[0]))
      setIsFlyoutShown(true)
    }
    else setIsFlyoutShown(false)
  }, [selectionModel])

  return (
    <Box className={classes.pageRoot} ref={pageRef}>
      <PageHeader
        isAddButtonAvailable
        title='Penugasan Kejadian'
        search={search}
        setSearch={setSearch}
        onAddButtonIsClicked={() => history.push(`${basePaths.dispatchEvidences}?mode=add`, { mode: 'add' })}
        isFlyoutAvailable={true}
        isFlyoutShown={isFlyoutShown}
        flyoutTitle=''
        flyoutTitleMargin={`${flyoutTitleMargin}px`}
        onToggleFlyoutClick={() => setIsFlyoutShown((current) => !current)}
      />

      <Stack className={classes.contentContainer} sx={{
        paddingRight: isFlyoutShown ? `${flyoutWidth + 24}px` : 0,
      }}>
        <LoadingBox isLoading={isLoading}>
          {/* FILTER */}
          <DataGridFilters
            columns={initialColumns}
            selectedColumnList={selectedColumnList}
            handleColumnsMenuItemClick={handleColumnsMenuItemClick}
            isFilterOn={isFilterOn}
            setIsFilterOn={setIsFilterOn}
            groupByList={groupByList}
            selectedGroupBy={selectedGroupBy}
            setSelectedGroupBy={setSelectedGroupBy}
          />

          {/* TABLE */}
          <DataGridTable
            initialColumns={initialColumns}
            rows={dispatchTableData}
            total={total}
            page={page}
            pageSize={size}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            setPage={setPage}
            setPageSize={setPageSize}
            setFilters={setFilters}
            isFilterOn={isFilterOn}
            selectedColumnList={selectedColumnList}
            setSelectedColumnList={setSelectedColumnList}
            selectedGroupBy={selectedGroupBy}
            getTreeDataPath={(row) => [ row.status, row.id ]}
            groupingColDef={groupingColDef}
            selectionModel={selectionModel} 
            setSelectionModel={setSelectionModel}
            isCheckboxPinned={true}
          />
        </LoadingBox>

        <Flyout
          isFlyoutShown={isFlyoutShown} 
          flyoutWidth={flyoutWidth}
          className={classes.flyoutDispatchDetail}
        >
          <FlyoutDispatchEvidencesDetail
            setIsFullViewShown={setIsFullViewShown}
            itemDetail={itemDetail}
            setDialogDeleteEvidence={setDialogDeleteEvidence}
            setIsFlyoutShown={setIsFlyoutShown}
          />
        </Flyout>
      </Stack>

      <FullViewDescription
        open={isFullViewShown}
        containerRef={pageRef}
        setIsFullViewShown={setIsFullViewShown}
        itemDetail={itemDetail}
      />

      <DialogAddOrEditDispatchEvidences
        pageRef={pageRef}
        dialogAddOrEditDispatch={dialogAddOrEditDispatch}
        reloadData={reloadData}
      />

      {/* DIALOG DELETE EVIDENCES */}
      <DialogConfirmation
        title='Hapus Penugasan Kejadian'
        caption='Apa Anda yakin ingin menghapus penugasan kejadian ini?'
        dialogConfirmationObject={dialogDeleteEvidence}
        setDialogConfirmationObject={setDialogDeleteEvidence}
        cancelButtonText='Batal'
        continueButtonText='Hapus'
        onContinueButtonClick={() => handleDeleteButtonClick()}
        onCancelButtonClick={() => setDialogDeleteEvidence({})}
      />
    </Box>
  )
}

export default DispatchEvidences