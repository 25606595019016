// MUIS
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '24px 24px 0 24px',
  },
  contentRoot: {
    flex: 1,
    height: '100%',
    display: 'flex',
  },
  mediaSectionWrap: {
    overflowY: 'hidden',
    paddingBottom: 24,
    height: '100%',
  }
}))

export default useStyles