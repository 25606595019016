// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

const InfoLegends = (props) => {
  const { legendList } = props

  const getBackgroundColor = (index) => {
    if (index === 0) return colors.candlelight
    else if (index === 1) return colors.buttercup
    else if (index === 2) return colors.goldenBell
    else if (index === 3) return colors.bamboo
    else if (index === 4) return colors.milanoRed
  }

  return (
    <Stack padding='16px'>
      {/* TITLE */}
      <Typography 
        variant='body2'
        marginBottom='8px'
        fontWeight={600}
      >
        Legenda
      </Typography>

      {/* CAPTION */}
      <Typography
        variant='caption'
        marginBottom='8px'
        color='text.secondary'
      >
        Intensitas Kejadian yang terjadi pada suatu daerah
      </Typography>

      {/* LEGEND LIST */}
      <List disablePadding>
        {legendList.map((item, index) => (
          <ListItem 
            key={index} 
            disablePadding
          >
            {/* ICON */}
            <ListItemIcon>
              <Stack
                height='8px'
                width='24px'
                borderRadius='8px'
                backgroundColor={getBackgroundColor(index)}
              />
            </ListItemIcon>

            {/* TEXT */}
            <ListItemText primary={item}/>
          </ListItem>
        ))}
      </List>
    </Stack>
  )
}

export default InfoLegends