// MUI STYLES
import { makeStyles } from '@mui/styles'


const useStyles = makeStyles(({
  root: {
    backgroundColor: '#724603B2',
    borderRadius: '50%'
  },
}))

export default useStyles