import { http } from '../utilities/http'
import { stringify } from 'query-string'

export async function getEvidences(params, body) {
  try {
    const date = new Date()
    body.timezoneOffset = -date.getTimezoneOffset()/60
    const { data } = await http.post(`/evidences/search?${stringify(params)}`, body ? body : {})

    const newRows = data.rows.map((item, index) => {
      item.id = item.evidenceNo
      if(!item.description) item.description = '-'
      return item
    })

    return {
      ...data,
      rows: newRows
    }

  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function getEvidencesCitizen(params, body) {
  try {
    const date = new Date()
    body.timezoneOffset = -date.getTimezoneOffset()/60
    const { data } = await http.post(`/evidences/citizen/search?${stringify(params)}`, body ? body : {})

    const newRows = data?.rows.map((item, index) => {
      item.id = item.evidenceNo
      if(!item.description) item.description = '-'
      return item
    })

    return {
      ...data,
      rows: newRows || []
    }

  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function getEvidence(id) {
  try {
    const { data } = await http.get(`/evidences/${id}`)

    return data
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}


export async function getEvidenceByPatrolNo(patrolNo) {
  try {
    const { data } = await http.get(`/evidences/filter-by/patrol/${patrolNo}`)

    return data
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function changeEvidenceStatus(id, body) {
  try {
    const { data } = await http.put(`/evidences/${id}/update-status`, body)

    return data
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function removeEvidenceImage(body) {
  try {
    return await http.post('/evidences/remove-image', body)
  } catch (e) {
    return {'status': 'error', message: e?.response?.data.error}
  }
}

export const putUpdateEvidenceReviewStatus = async (id, body) => {
  try {
    return await http.put(`/evidences/${id}/review-status`, body)
  } catch (e) {
    return {
      'status': 'error', 
      message: e?.response?.data.error,
    }
  }
}

export const getEvidenceStatistics = async (bodyParameters) => {
  try {
    return await http.post('/evidences/stats', bodyParameters)
  } catch (e) {
    return {
      status: 'error', 
      message: e?.response?.data.error,
    }
  }
}

export const advanceSearchEvidences = async (queryParameters, bodyParameters) => {
  try {
    return await http.post(`/evidences/advanced-search?${stringify(queryParameters)}`, bodyParameters)
  } catch (e) {
    return {
      status: 'error', 
      message: e?.response?.data.error,
    }
  }
}

export const saveAsNewFilterAdvanceSearch = async (bodyParameters) => {
  try {
    return await http.post('/evidences/advanced-search/save', bodyParameters)
  } catch (e) {
    return {
      status: 'error', 
      message: e?.response?.data.error,
    }
  }
}

export const getSavedFilterListAdvanceSearch = async () => {
  try {
    return await http.get('/evidences/advanced-search/fetch')
  } catch (e) {
    return {
      status: 'error', 
      message: e?.response?.data.error,
    }
  }
}

export const deleteSavedFilterItemAdvanceSearch = async (id) => {
  try {
    return await http.delete(`/evidences/advanced-search/${id}/delete`)
  } catch (e) {
    return {
      status: 'error', 
      message: e?.response?.data.error,
    }
  }
}

export const editSavedFilterItemAdvanceSearch = async (id, bodyParameters) => {
  try {
    return await http.put(`/evidences/advanced-search/${id}/edit`, bodyParameters)
  } catch (e) {
    return {
      status: 'error', 
      message: e?.response?.data.error,
    }
  }
}

export const putUpdateEvidence = async (id, body) => {
  try {
    return await http.put(`/evidences/update/${id}`, body)
  } catch (e) {
    return {
      'status': 'error', 
      message: e?.response?.data.error,
    }
  }
}