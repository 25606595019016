import { http } from '../utilities/http'
import { stringify } from 'query-string'
import { toast } from '../utilities'

export async function gets(query, params = {}) {
  try {
    const { data } = await http.post(
      `/devices/search?${stringify(query)}`,
      params
    )

    const newRows = data.rows.map((item) => {
      item.id = item.deviceNo
      item.checked = false
      return item
    })

    return {
      ...data,
      rows: newRows,
    }
  } catch (e) {
    return { status: 'error', rows: [] }
  }
}

export async function getDeviceOSVersion(params) {
  try {
    const { data } = await http.get(
      `/devices/device-os-version?${stringify(params)}`
    )

    return data
  } catch (e) {
    return { status: 'error', rows: [] }
  }
}

export async function getDeviceStreaming(
  url = '/devices/device-streaming',
  query
) {
  try {
    const { data } = await http.get(`${url}?${stringify(query)}`)

    const newRows = data.rows.map((item) => {
      item.id = item.deviceNo
      return item
    })

    return {
      ...data,
      rows: newRows,
    }
  } catch (e) {
    return { status: 'error', rows: [] }
  }
}

export async function getDevice(id) {
  try {
    const { data } = await http.get(`/devices/${id}`)

    return data
  } catch (e) {
    return { status: 'error', rows: [] }
  }
}

export async function getDeviceAppVersion(params) {
  try {
    const { data } = await http.get(
      `/devices/device-app-version?${stringify(params)}`
    )

    return data
  } catch (e) {
    return { status: 'error', rows: [] }
  }
}

export async function getDeviceAndVehicles(params) {
  try {
    const { data } = await http.get(
      `/voc-groups/device-vehicle?${stringify(params)}`
    )

    const newRows = data.rows.map((item) => {
      item.checked = false
      return item
    })

    return {
      ...data,
      rows: newRows,
    }
  } catch (e) {
    return { status: 'error', rows: [] }
  }
}

export async function getGroups(queryParams, bodyParams = {}) {
  try {
    const { data } = await http.post(`/voc-groups/search?${stringify(queryParams)}`, bodyParams)

    return data
  } catch (e) {
    return { status: 'error', rows: [] }
  }
}

export async function searchGroups(queryParams, bodyParams) {
  try {
    const { data } = await http.post(`/voc-groups/search?${stringify(queryParams)}`, bodyParams)

    return data
  } catch (e) {
    return { status: 'error', rows: [] }
  }
}

export async function updateStatus(id, params) {
  try {
    const { data } = await http.put(`/devices/${id}/update-status`, params)

    return data
  } catch (e) {
    toast({ title: e?.response?.data.error, icon: 'error' }).then(() => {})
    return { status: 'error', rows: [] }
  }
}

export async function doDeleteDevice(body) {
  try {
    const { data } = await http.put('/devices/soft-deleted', body)

    return data
  } catch (e) {
    toast({ title: e?.response?.data.error, icon: 'error' }).then(() => {})
    return { status: 'error', rows: [] }
  }
}

export async function getDeviceAndVehicleDetail(objectNo, params) {
  try {
    const { data } = await http.get(
      `/voc-groups/device-vehicle/${objectNo}/detail?${stringify(params)}`
    )

    return data
  } catch (e) {
    return { status: 'error', rows: [] }
  }
}

export async function getRecords(query) {
  try {
    const { data } = await http.post(
      `/device-recordings/search?${stringify(query)}`,
      {}
    )

    return data
  } catch (e) {
    return { status: 'error', rows: [] }
  }
}

export async function getRecord(id) {
  try {
    const { data } = await http.get(`/device-recordings/${id}`)

    return data
  } catch (e) {
    return { status: 'error', rows: [] }
  }
}

export async function changeDeviceGroup(id, params) {
  try {
    const { data } = await http.put(`/devices/${id}/change-group`, params)

    return data
  } catch (e) {
    toast({ title: e?.response?.data.error, icon: 'error' }).then(() => {})
    return { status: 'error', rows: [] }
  }
}

export async function getDeviceListOfficer(id) {
  try {
    const { data } = await http.get(`/devices/${id}/officers`)

    return data
  } catch (e) {
    return { status: 'error', rows: [] }
  }
}

export async function changeDeviceListOfficers(id, params) {
  try {
    const { data } = await http.put(`/devices/${id}/officers`, params)

    return data
  } catch (e) {
    toast({ title: e?.response?.data.error, icon: 'error' }).then(() => {})
    return { status: 'error', rows: [] }
  }
}

export async function getDeviceListVehicle(id) {
  try {
    const { data } = await http.get(`/devices/${id}/vehicle`)

    return data
  } catch (e) {
    return { status: 'error', rows: [] }
  }
}

export async function changeDeviceListVehicles(id, params) {
  try {
    const { data } = await http.put(`/devices/${id}/vehicle`, params)

    return data
  } catch (e) {
    toast({ title: e?.response?.data.error, icon: 'error' }).then(() => {})
    return { status: 'error', rows: [] }
  }
}

export const getDeviceList = async (queryParams) => {
  try {
    const response = await http.get(`/devices/list?${stringify(queryParams)}`)
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const putEditDevice = async (id, bodyParams) => {
  try {
    const response = await http.put(`/devices/${id}`, bodyParams)
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}