// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconMusicNotePlay = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M19.184 3.61896C19.1558 3.44975 19.0917 3.28855 18.9959 3.14624C18.9001 3.00392 18.7749 2.88379 18.6288 2.79395C18.4827 2.70411 18.319 2.64664 18.1488 2.62542C17.9785 2.60421 17.8057 2.61973 17.642 2.67096L9.24201 5.29536C8.99776 5.37161 8.78428 5.52394 8.63273 5.7301C8.48118 5.93627 8.3995 6.18548 8.39961 6.44136V16.1998C7.85897 15.7943 7.19725 15.5832 6.52167 15.6008C5.84609 15.6183 5.19622 15.8635 4.67737 16.2965C4.15852 16.7295 3.80107 17.3251 3.66294 17.9866C3.52482 18.6481 3.6141 19.3369 3.91633 19.9414C4.21856 20.5459 4.71603 21.0306 5.32814 21.317C5.94025 21.6034 6.63114 21.6748 7.28887 21.5195C7.9466 21.3642 8.53264 20.9914 8.95203 20.4615C9.37143 19.9316 9.59961 19.2756 9.59961 18.5998V10.0414L17.9996 7.41576V10.8274C18.4112 10.8634 18.8132 10.939 19.1996 11.0482V3.81576C19.1996 3.74856 19.1936 3.68376 19.184 3.61896ZM22.7996 17.3998C22.7996 18.8319 22.2307 20.2054 21.218 21.2181C20.2053 22.2308 18.8318 22.7998 17.3996 22.7998C15.9674 22.7998 14.5939 22.2308 13.5812 21.2181C12.5685 20.2054 11.9996 18.8319 11.9996 17.3998C11.9996 15.9676 12.5685 14.5941 13.5812 13.5814C14.5939 12.5687 15.9674 11.9998 17.3996 11.9998C18.8318 11.9998 20.2053 12.5687 21.218 13.5814C22.2307 14.5941 22.7996 15.9676 22.7996 17.3998ZM20.084 16.8778L16.4912 14.8978C16.3999 14.8473 16.297 14.8214 16.1927 14.8229C16.0884 14.8243 15.9862 14.8529 15.8963 14.9058C15.8064 14.9587 15.7319 15.0342 15.6801 15.1248C15.6282 15.2153 15.6009 15.3178 15.6008 15.4222V19.3822C15.6007 19.4866 15.6279 19.5892 15.6796 19.68C15.7314 19.7707 15.8059 19.8463 15.8958 19.8994C15.9857 19.9525 16.088 19.9812 16.1924 19.9827C16.2968 19.9841 16.3998 19.9583 16.4912 19.9078L20.084 17.9278C20.1778 17.8759 20.256 17.7998 20.3104 17.7075C20.3648 17.6152 20.3935 17.5099 20.3935 17.4028C20.3935 17.2956 20.3648 17.1904 20.3104 17.098C20.256 17.0057 20.1778 16.9296 20.084 16.8778Z'/>
    </SvgIcon>
  )
}

export default IconMusicNotePlay