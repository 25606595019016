import React from 'react'

// COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// LIBRARY
import parseHTML from 'html-react-parser'

// MUIS
import Backdrop from '@mui/material/Backdrop'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconCloseFullscreen from '@mui/icons-material/CloseFullscreen'
import IconContentCopy from '@mui/icons-material/ContentCopy'

// STYLES
import useStyles from './fullViewDescriptionUseStyles'

const FullViewDescription = (props) => {
  const { open, containerRef, setIsFullViewShown, itemDetail } = props
  const classes = useStyles()

  const handleCopyDescription = (content = '') => {
    navigator.clipboard.writeText(content)
  }
  
  return (
    <Backdrop
      open={open}
      className={classes.root}
    >
      <Slide 
        direction='right' 
        in={open} 
        container={containerRef?.current}
      >
        <Stack className={classes.viewRoot}>
          <Stack className={classes.viewHeader}>
            <Typography className={classes.titleViewHeader} noWrap>
              Deskripsi
            </Typography>

            <Stack direction='row' alignItems='center' flex={1} justifyContent='flex-end'>
              <CustomTooltipBlack title='Salin' placement='bottom'>
                <IconButton
                  className={classes.btnIconCopy}
                  onClick={() => handleCopyDescription(itemDetail?.description)}>
                  <IconContentCopy fontSize='small'/>
                </IconButton>
              </CustomTooltipBlack>

              <CustomTooltipBlack title='Tutup' placement='bottom'>
                <IconButton onClick={() => setIsFullViewShown(false)}>
                  <IconCloseFullscreen fontSize='small'/>
                </IconButton>
              </CustomTooltipBlack>
              
            </Stack>
          </Stack>

          <Stack className={classes.bodyView}>
            <Typography variant='h5' className={classes.titleBodyView}>
              {itemDetail?.label}
            </Typography>

            {itemDetail?.description && parseHTML(itemDetail?.description)}
          </Stack>
        </Stack>
      </Slide>
    </Backdrop>
  )
}

export default FullViewDescription