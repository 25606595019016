// MUI STYLES
import { makeStyles } from '@mui/styles'
import { alpha } from '@mui/material/styles'


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    boxShadow: `0px 4px 16px ${alpha(theme.palette.common.black, 0.16)}`,
    borderRadius: 2,
    width: 340
  },
  content: {
    maxHeight: 300,
    overflowY: 'auto',
  },
  footer: {
    boxShadow: `0px -2px 4px ${alpha(theme.palette.common.black, 0.16)}`,
    flexDirection: 'row',
    padding: '8px 24px',
    justifyContent: 'flex-end'
  },
  buttonDialog: {
    fontWeight: 700,
    '&.btnCancel': {
      color: theme.palette.text.secondary,
      fontWeight: 600
    }
  },
  dialogMenuHeader: {
    boxShadow: `0px 2px 4px ${alpha(theme.palette.common.black, 0.16)}`,
  },
  dialogMenuTitleHeader: {
    padding: '16px 24px',
    fontWeight: 600,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
  },
  inputDevicesSearch: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
}))

export default useStyles
