// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  columnIcon: {
    color: colors.boulder,
    marginRight: 4,
    width: 16,
    height: 16,
  },
  columnUnsortedIconAsc: {
    width: 18,
    height: 18,
    transform: 'rotate(0deg)',
    transition: 'transform 0.25s ease-in-out',
  },
  columnUnsortedIconDesc: {
    transform: 'rotate(180deg)',
  },
  columnSortedIconAsc: {
    color: colors.clementine,
    transform: 'rotate(0deg)',
  },
  columnSortedIconDesc: {
    color: colors.clementine,
    transform: 'rotate(180deg)',
  },
  columnFilter: {
    marginBottom: 10,
    '& .MuiInput-root:before': {
      borderBottom: `1px solid ${colors.borderLightBlack}`,
    },
  },
}))

export default useStyles