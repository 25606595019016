// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: colors.boulder,
  },
  searchIcon: {
    marginRight: 8,
    color: colors.iconDark,
    cursor: 'pointer',
  },
  searchMenu: {
    borderRadius: 2,
    width: 400,
    padding: 0,
    overflowY: 'hidden',
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[4],
    height: 400,
    pointerEvents: 'auto',
    cursor: 'pointer',
  },
  searchMenuHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 62,
    paddingLeft: 18,
    paddingRight: 12,
  },
  searchMenuHeaderTitle: {
    marginLeft: 12,
    marginRight: 'auto',
  },
  searchMenuWrap: {
    padding: '8px 4px 8px 0'
  },
  searchInput: {
    width: '100%',
    marginLeft: 6,
    paddingLeft: 12,
  },
  searchMenuResult: {
    padding: '12px 0',
    height: 280,
    overflowY: 'auto'
  },
  resultHeader: {
    padding: '4px 16px',
  },
  menuListResult: {
    '&.MuiList-root': {
      width: '100% !important',
      height: 'auto !important'
    },
    '& .MuiListItemButton-root': {
      padding: '4px 24px',
    }
  },
  noResultText: {
    fontWeight: 500,
    padding: '4px 16px'
  },
  avatarListPlaces: {
    backgroundColor: colors.california
  },
  loadingWrapper: {
    width: '100%',
    padding: '12px 24px'
  },
  loadingProgress: {
    width: '25%',
    margin: '0 auto'
  }
}))

export default useStyles