// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 24,
    position: 'relative',
  },
  columnCircleStatus: {
    height: 14,
    width: 14,
    borderRadius: '100%',
  },
  columnTextStatus: {
    paddingLeft: '12px',
    textTransform: 'capitalize',
    fontSize: 12,
    fontWeight: 400,
  },
  columnChipFilled: {
    height: 24,
    color: theme.palette.common.white,
    '& .MuiSvgIcon-root': {
      color: theme.palette.common.white,
      fontSize: 14,
      marginLeft: 8,
    },
    '& .MuiChip-label': {
      padding: '0 12px',
      fontSize: 12,
      fontWeight: 600,
    }
  },
  columnChipOutlined: {
    backgroundColor: 'transparent',
    height: 24,
    borderWidth: 1.4,
    '& .MuiSvgIcon-root': {
      fontSize: 14,
      marginLeft: 8,
    },
    '& .MuiChip-label': {
      padding: '0 12px',
      fontSize: 12,
      fontWeight: 600,
    }
  },
  columnTitleText: {
    fontSize: 12,
    fontWeight: 400,
  },
  columnActualText: {
    fontSize: 12,
    fontWeight: 400,
  },
  groupingRow: {
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  contentContainer: {
    height: '100%',
    position: 'relative'
  },
  columnChipId: {
    backgroundColor: colors.geyser,
    color: theme.palette.common.black,
  },
  flyoutDispatchDetail: {
    overflowY: 'hidden'
  }
}))

export default useStyles