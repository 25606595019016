// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconAutoGraph = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M18.1563 3.72907C17.8321 3.76813 17.5352 3.99079 17.4376 4.32282L16.8751 6.26032L21.4063 7.57282L21.9688 5.63532C22.0977 5.19001 21.8516 4.73297 21.4063 4.60407L18.4688 3.76032C18.3595 3.72907 18.2657 3.71735 18.1563 3.72907ZM6.37508 7.54157C4.17586 7.69001 3.05477 10.6588 2.68758 12.0103C2.29695 13.4439 1.00008 17.7798 3.56258 19.0416C5.23445 19.8619 7.41414 18.3697 7.43758 18.3541C7.43367 18.3892 7.21492 19.9009 8.00008 20.3228C8.92195 20.815 10.4376 19.9048 10.4688 19.8853C10.4766 19.9009 11.1134 21.5728 13.0001 21.5728C13.0001 21.5728 11.6485 18.1822 10.7813 17.8853C10.1095 17.6548 9.63289 18.0572 9.46883 18.1353C9.50789 17.4673 9.43758 16.6548 8.62508 16.2291C7.72273 15.7603 6.85945 16.3463 6.25008 16.7603C5.43758 17.3111 4.98445 17.5845 4.43758 17.2603C3.19148 16.522 4.68758 12.3853 4.68758 12.3853C4.96102 11.4048 5.89852 9.35797 6.90633 9.60407C7.54305 9.75641 7.97664 10.022 8.18758 10.4166C8.5157 11.0259 8.32039 11.8814 8.21883 12.1666L10.0938 12.8853C10.1641 12.6978 10.7384 10.9908 9.93758 9.47907C9.60164 8.84625 8.86336 8.11188 7.37508 7.66657C7.0157 7.55719 6.68758 7.52204 6.37508 7.54157ZM16.4376 7.72907L13.2813 18.6666L14.8751 21.5728L17.8126 19.9791L20.9688 9.04157L16.4376 7.72907Z'/>
    </SvgIcon>
  )
}

export default IconAutoGraph