import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// COMPONENTS
import CustomFormControl from 'components/Customs/CustomFormControl'
import CustomInputNew from 'components/Customs/CustomInputNew'
import CustomInputLabelNew from 'components/Customs/CustomInputLabelNew'
import DialogAddOrEdit from 'components/DialogAddOrEditNew/DialogAddOrEdit'
import DialogColorPicker from 'components/DialogColorPicker/DialogColorPicker'
import DialogIconPicker from 'components/DialogIconPicker/DialogIconPicker'
import SimpleBackdrop from 'components/Partials/SimpleBackdrop'

// CONSTANTS
import { basePaths } from 'constants/paths'
import { colors } from 'constants/colors'
import { iconListTagEvidences } from 'constants/icons'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconTag from '@mui/icons-material/Tag'
import IconTextFields from '@mui/icons-material/TextFields'

// PATH ICONS
import IconClipboard from 'assets/images/pathIcons/IconClipboard'
import IconImageAdd from 'assets/images/pathIcons/IconImageAdd'

// SERVICES
import { getSSDGroups } from 'services/DispatchService'
import { postTagEvidencesCreate, putTagEvidencesUpdate } from 'services/TagEvidencesService'

// STYLES
import useStyles from './dialogAddOrEditTagEvidencesUseStyles'

const DialogAddOrEditTagEvidences = (props) => {
  const {
    dialogAddEditTagEvidence,
    setDialogAddEditTagEvidence,
    pageRef,
    reloadData,
  } = props

  const classes = useStyles()

  const { setSnackbarObject } = useContext(AllPagesContext)


  const history = useHistory()
  const location = useLocation()

  const defaultParamsObject = {
    iconId: null,
    tagColor: '',
    tagName: '',
    categoryNo: 1,
  }

  const [isLoading, setIsLoading] = useState(false)
  const [isDialogColorShown, setIsDialogColorShown] = useState(false)
  const [isDialogIconShown, setIsDialogIconShown] = useState(false)
  const [paramsObject, setParamsObject] = useState(defaultParamsObject)
  const [listCategory, setListCategory] = useState([])

  const closeDialog = () => {
    setDialogAddEditTagEvidence(null)
  }

  const showAddIcon = () => {
    if (paramsObject?.iconId) {
      const Icon = iconListTagEvidences[Number(paramsObject.iconId)-1]?.Icon
      return <Icon className={classes.iconAddedView} />
    } else return <IconImageAdd className={classes.iconAddImage} fontSize='large'/>
  }

  const handleParamsChange = (key, value) => {
    setParamsObject({
      ...paramsObject,
      [key]: value,
    })
  }

  const handleButtonSaveClick = async () => {
    setIsLoading(true)
    let response = {}

    if(!paramsObject?.tagName || !paramsObject?.iconId || !paramsObject?.categoryNo) {
      setIsLoading(false)
      return setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Semua field harus terisi',
      })
    }

    if(dialogAddEditTagEvidence?.mode === 'add') {
      response = await postTagEvidencesCreate({
        ...paramsObject,
        tagColor: paramsObject.tagColor || colors.flamingo
      })
      
      if(response?.status === 'error') {
        setSnackbarObject({
          open: true,
          severity: 'error',
          title: '',
          message: 'Gagal Menambahkan Tag Kejadian',
        })
      } else {
        setSnackbarObject({
          open: true,
          severity: 'success',
          title: '',
          message: 'Berhasil Menambahkan Tag Kejadian'
        })
      }
    } else if(dialogAddEditTagEvidence?.mode === 'edit') {
      response = await putTagEvidencesUpdate({
        ...paramsObject,
        tagColor: paramsObject.tagColor || colors.flamingo
      })

      if(response?.status === 'error') {
        setSnackbarObject({
          open: true,
          severity: 'error',
          title: '',
          message: 'Gagal Mengubah Tag Kejadian'
        })
      } else {
        setSnackbarObject({
          open: true,
          severity: 'success',
          title: '',
          message: 'Berhasil Mengubah Tag Kejadian'
        })
      }
    }
  
    setIsLoading(false)
    
    if (response?.status !== 'error') {
      reloadData()
      closeDialog()
    }
  }

  const fetchCategoryList = async () => {
    const response = await getSSDGroups({ size: 10000 })
    setListCategory(response.rows)
  }

  useEffect(() => {
    fetchCategoryList()
  }, [])

  useEffect(() => {
    if (location.pathname === basePaths.settingsTagEvidences && (!location.search.includes('?mode=edit') && !location.search.includes('?mode=add'))) {
      closeDialog()
    }
  }, [location])

  useEffect(() => {
    if(dialogAddEditTagEvidence?.mode === 'edit') {
      setParamsObject({
        ...dialogAddEditTagEvidence?.data,
        categoryNo: dialogAddEditTagEvidence?.data.category.groupNo
      })
    } else {
      setParamsObject(defaultParamsObject)
    }
  }, [dialogAddEditTagEvidence])

  return (
    <>
      {/* DIALOG ADD OR EDIT */}
      <DialogAddOrEdit
        open={Boolean(dialogAddEditTagEvidence)}
        title='Tag Kejadian'
        onCloseIconClick={() => history.push(basePaths.settingsTagEvidences)}
        containerRef={pageRef}
        contents={[
          <>
            {/* MAIN SECTION TEXT */}
            <Typography variant='h6' className={classes.sectionText}>
              Informasi
            </Typography>

            {/* ICON & COLOR */}
            <Stack className={`${classes.pockbaseStyles} ${classes.formControl}`} direction='row' flexWrap='nowrap' alignItems='center'>
              {/* CHANGE ICON */}
              <Stack className={classes.addIconWrap}>
                <IconButton
                  className={classes.btnAddImage}
                  disableRipple
                  onClick={() => setIsDialogIconShown(true)}
                >
                  {showAddIcon()}
                </IconButton>
              </Stack>

              <Typography variant='subtitle1' className={classes.pockbaseStylesLabel}>Icon</Typography>

              {/* CHANGE COLOR */}
              <Stack flex={1} direction='row' justifyContent='flex-end' paddingRight='8px'>
                <Box
                  className={classes.boxColor}
                  sx={{
                    backgroundColor: paramsObject?.tagColor || colors.flamingo
                  }}
                  onClick={() => setIsDialogColorShown(true)}
                ></Box>
              </Stack>
            </Stack>

            {/* NAME TAG KEJADIAN */}
            <CustomFormControl variant='filled' className={classes.formControl}>
              <CustomInputLabelNew shrink={true}>
                <IconTextFields />
                <Typography variant='subtitle1'>Nama Tag Kejadian</Typography>
              </CustomInputLabelNew>

              <CustomInputNew
                disableUnderline
                placeholder='Masukkan Tag Kejadian'
                onChange={(event) => handleParamsChange('tagName', event.target.value)}
                value={paramsObject?.tagName}
              />
            </CustomFormControl>

            {/* CATEGORY */}
            <CustomFormControl variant='filled' className={`${classes.formControl} ${classes.selectCategory}`}>
              <CustomInputLabelNew shrink={true}>
                <IconClipboard />
                <Typography variant='subtitle1'>Kategori</Typography>
              </CustomInputLabelNew>

              <Select
                disableUnderline
                value={paramsObject.categoryNo}
                onChange={(event) => handleParamsChange('categoryNo', Number(event.target.value))}
                MenuProps={{
                  PaperProps: {
                    className: classes.categoryPaper
                  }
                }}
              >
                {listCategory?.map(item => (
                  <MenuItem
                    key={item.groupNo}
                    value={item.groupNo}
                    className={classes.categoryItem}
                  >
                    <IconTag />
                    <Typography variant='subtitle2' className={classes.categoryItemText} noWrap>{item.groupName}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </CustomFormControl>
          </>
        ]}
        onCancelButtonClick={() => history.push(basePaths.settingsTagEvidences)}
        onSaveButtonClick={() => handleButtonSaveClick()}
      />

      {/* LOADING */}
      <SimpleBackdrop isLoading={isLoading} />

      {/* DIALOG COLOR */}
      <DialogColorPicker
        isDialogColorShown={isDialogColorShown}
        setIsDialogColorShown={setIsDialogColorShown}
        selectedColor={paramsObject.tagColor}
        onSaveButtonClick={selectedColor => setParamsObject({
          ...paramsObject, tagColor: selectedColor
        })}
      />

      {/* DIALOG ICON */}
      <DialogIconPicker
        isDialogIconShown={isDialogIconShown}
        setIsDialogIconShown={setIsDialogIconShown}
        selectedIcon={paramsObject.iconId?.toString()}
        onSaveButtonClick={selectedIcon => setParamsObject({
          ...paramsObject, iconId: Number(selectedIcon)
        })}
        listIcon={iconListTagEvidences}
      />
    </>
  )
}

export default DialogAddOrEditTagEvidences
