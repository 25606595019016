import { useEffect, useState } from 'react'
import moment from 'moment'

// COMPONENTS
import InfoBiggestFive from '../InfoBiggestFive/InfoBiggestFive'
import InfoLegends from '../InfoLegends/InfoLegends'
import InfoPatrols from '../InfoPatrols/InfoPatrols'

// PATH ICONS
import IconEqualizerLine from 'assets/images/pathIcons/IconEqualizerLine'

// MUIS
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconExpandLess from '@mui/icons-material/ExpandLess'
import IconExpandMore from '@mui/icons-material/ExpandMore'

// SERVICES
import { getEvidenceStatsChart, getPatrolStatsChart } from 'services/DashboardService'

// STYLES
import usePageStyles from '../overviewDashboardUseStyles'
import useStyles from './panelInfoUseStyles'

const PanelInfo = (props) => {
  const { 
    setIsPanelFiltersOpen, 
    selectedDateRange, 
    legendList, 
    viewModeList,
    selectedViewMode,
    selectedProvince,
    userGMT
  } = props

  const pageClasses = usePageStyles()
  const classes = useStyles()

  const [ isPanelExpanded, setIsPanelExpanded ] = useState(true)
  const [ dataTopProvince, setDataTopProvince ] = useState({
    categories: [],
    list: []
  })
  const [ dataTopEvidence, setDataTopEvidence ] = useState({
    categories: [],
    list: []
  })
  const [ dataTopTagLocation, setDataTopTagLocation ] = useState({
    categories: [],
    list: []
  })
  const [ totalClockInPatrol, setTotalClockInPatrol ] = useState(0)
  const [ dataTopActivity, setDataTopActivity ] = useState({
    categories: [],
    list: []
  })

  let SelectedExpandIcon = IconExpandLess
  if (!isPanelExpanded) SelectedExpandIcon = IconExpandMore

  const getBarChartData = async () => {
    const resTopProvince = await getEvidenceStatsChart({
      statsName: 'TOP_GEOFENCE',
      chartType: 'BAR',
      from: moment(selectedDateRange[0]).format('yyyy-MM-DD HH:mm:ss').toString(),
      to: moment(selectedDateRange[1]).format('yyyy-MM-DD HH:mm:ss').toString(),
      timezoneOffset: userGMT,
      options: {
        limit: 5,
        slug: selectedViewMode === viewModeList[0] ? 'indonesia' : selectedProvince?.slug
      }
    })

    const resTopEvidence = await getEvidenceStatsChart({
      statsName: 'TOP_TAGS',
      chartType: 'BAR',
      from: moment(selectedDateRange[0]).format('yyyy-MM-DD HH:mm:ss').toString(),
      to: moment(selectedDateRange[1]).format('yyyy-MM-DD HH:mm:ss').toString(),
      timezoneOffset: userGMT,
      options: {
        limit: 5,
        slug: selectedViewMode === viewModeList[0] ? 'indonesia' : selectedProvince?.slug
      }
    })

    const resTopTagLocation = await getEvidenceStatsChart({
      statsName: 'TOP_LOCATION_TAG',
      chartType: 'BAR',
      from: moment(selectedDateRange[0]).format('yyyy-MM-DD HH:mm:ss').toString(),
      to: moment(selectedDateRange[1]).format('yyyy-MM-DD HH:mm:ss').toString(),
      timezoneOffset: userGMT,
      options: {
        limit: 5,
        slug: selectedViewMode === viewModeList[0] ? 'indonesia' : selectedProvince?.slug
      }
    })

    const resPatrolStats = await getPatrolStatsChart({
      statsName: 'PATROL_COUNT',
      chartType: 'PIE',
      from: moment(selectedDateRange[0]).format('yyyy-MM-DD HH:mm:ss').toString(),
      to: moment(selectedDateRange[1]).format('yyyy-MM-DD HH:mm:ss').toString(),
      timezoneOffset: userGMT,
      options: {
        slug: selectedViewMode === viewModeList[0] ? 'indonesia' : selectedProvince?.slug
      }
    })

    const resTopActivity = await getPatrolStatsChart({
      statsName: 'TOP_ACTIVITY',
      chartType: 'BAR',
      from: moment(selectedDateRange[0]).format('yyyy-MM-DD HH:mm:ss').toString(),
      to: moment(selectedDateRange[1]).format('yyyy-MM-DD HH:mm:ss').toString(),
      timezoneOffset: userGMT,
      options: {
        limit: 5,
        slug: selectedViewMode === viewModeList[0] ? 'indonesia' : selectedProvince?.slug
      }
    })

    if(resTopProvince?.status !== 'error') {
      setDataTopProvince({
        categories: resTopProvince.data.slice(0, 5).map(item => item.label),
        list: resTopProvince.data.slice(0, 5).map(item => item.value)
      })
    }

    if(resTopEvidence?.status !== 'error') {
      setDataTopEvidence({
        categories: resTopEvidence.data.slice(0, 5).map(item => item.label),
        list: resTopEvidence.data.slice(0, 5).map(item => item.value)
      })
    }

    if(resTopActivity?.status !== 'error') {
      setDataTopActivity({
        categories: resTopActivity.data.slice(0, 5).map(item => item.label),
        list: resTopActivity.data.slice(0, 5).map(item => item.value)
      })
    }

    if(resTopTagLocation?.status !== 'error') {
      setDataTopTagLocation({
        categories: resTopTagLocation.data.slice(0, 5).map(item => item.label),
        list: resTopTagLocation.data.slice(0, 5).map(item => item.value)
      })
    }

    if(resPatrolStats?.status !== 'error') {
      setTotalClockInPatrol(resPatrolStats?.data[0]?.value)
    }
  }

  useEffect(() => {
    if (userGMT) getBarChartData()
  }, [selectedDateRange, selectedProvince, userGMT])

  return (
    <Stack 
      className={pageClasses.panelLeft}
      bottom={isPanelExpanded ? '20px' : 'unset'}
    >
      {/* HEADER */}
      <Stack
        direction='row'
        className={pageClasses.panelLeftHeader}
        borderRadius={isPanelExpanded ? '4px 4px 0px 0px' : '4px !important'}
      >
        {/* TITLE */}
        <Typography
          fontWeight='600'
          marginRight='auto'
        >
          Info
        </Typography>

        {/* FILTER ICON BUTTON */}
        {isPanelExpanded &&
        <IconButton 
          size='small'
          onClick={() => setIsPanelFiltersOpen(true)}
        >
          <IconEqualizerLine
            fontSize='small' 
            color='primary'
          />
        </IconButton>}

        {/* EXPAND/COLLAPSE ICON BUTTON */}
        <IconButton 
          size='small'
          onClick={() => setIsPanelExpanded(current => !current)}
        >
          <SelectedExpandIcon fontSize='small'/>
        </IconButton>
      </Stack>

      {/* CONTENT */}
      <Stack
        flex='1'
        display={isPanelExpanded ? 'flex' : 'none'}
        className={classes.content}
      >
        {/* PATROLS INFORMATION */}
        <InfoPatrols
          region={selectedViewMode === viewModeList[0] ? 'Indonesia' : selectedProvince?.label}
          total={totalClockInPatrol}
        />

        <Divider/>

        {/* 5 LARGEST PROVINCE */}
        <InfoBiggestFive
          title={`5 ${selectedViewMode === viewModeList[0] ? viewModeList[0] : viewModeList[1]} Terbesar`}
          caption='Menampilkan 5 daerah dengan intensitas total kejadian tertinggi'
          chartCategories={dataTopProvince.categories}
          chartList={[{ data: dataTopProvince.list}]}
        />

        <Divider/>

        {/* 5 MOST EVIDENCE CASES */}
        <InfoBiggestFive
          title='5 Kejadian Terbesar'
          caption='Menampilkan 5 Kejadian dengan intensitas tertinggi'
          chartCategories={dataTopEvidence.categories}
          chartList={[{ data: dataTopEvidence.list}]}
        />

        <Divider/>

        {/* 5 MOST ACTIVITY CASES */}
        <InfoBiggestFive
          title='5 Kegiatan Terbesar'
          caption='Menampilkan 5 kegiatan dengan intensitas tertinggi'
          chartCategories={dataTopActivity.categories}
          chartList={[{ data: dataTopActivity.list}]}
        />

        <Divider/>

        {/* 5 MOST TAG LOCATIONS */}
        <InfoBiggestFive
          title='5 Tag Lokasi Terbesar'
          caption='Menampilkan 5 Tag Lokasi dengan intensitas tertinggi'
          chartCategories={dataTopTagLocation.categories}
          chartList={[{ data: dataTopTagLocation.list}]}
        />

        <Divider/>

        {/* LEGENDS */}
        <InfoLegends legendList={legendList} />
      </Stack>
    </Stack>
  )
}

export default PanelInfo