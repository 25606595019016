// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconFraud = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M1.41211 6.8571V19.4621C1.41211 20.2941 2.09278 20.9747 2.92471 20.9747H21.076C21.9079 20.9747 22.5886 20.2941 22.5886 19.4621V6.8571H1.41211ZM14.0172 11.3042V15.4285C14.0172 17.0974 12.6608 18.4537 10.9919 18.4537C9.32303 18.4537 7.96673 17.0974 7.96673 15.4285V12.7008L9.83732 14.5663C10.034 14.763 10.034 15.0857 9.83732 15.2823C9.64068 15.4789 9.31799 15.4789 9.12135 15.2823L8.97513 15.131V15.4285C8.97513 16.5428 9.87766 17.4453 10.9919 17.4453C12.1062 17.4453 13.0087 16.5428 13.0087 15.4285V11.3042C12.4188 11.1025 12.0003 10.5428 12.0003 9.88231C12.0003 9.04533 12.676 8.3697 13.5129 8.3697C14.3499 8.3697 15.0256 9.04533 15.0256 9.88231C15.0256 10.5428 14.6071 11.1025 14.0172 11.3042ZM21.076 2.82349H2.92471C2.09278 2.82349 1.41211 3.50416 1.41211 4.33609V5.8487H22.5886V4.33609C22.5886 3.50416 21.9079 2.82349 21.076 2.82349ZM3.42892 4.84029C3.15161 4.84029 2.92471 4.6134 2.92471 4.33609C2.92471 4.05878 3.15161 3.83189 3.42892 3.83189C3.70623 3.83189 3.93312 4.05878 3.93312 4.33609C3.93312 4.6134 3.70623 4.84029 3.42892 4.84029ZM4.94152 4.84029C4.66421 4.84029 4.43732 4.6134 4.43732 4.33609C4.43732 4.05878 4.66421 3.83189 4.94152 3.83189C5.21883 3.83189 5.44572 4.05878 5.44572 4.33609C5.44572 4.6134 5.21883 4.84029 4.94152 4.84029ZM6.45413 4.84029C6.17682 4.84029 5.94992 4.6134 5.94992 4.33609C5.94992 4.05878 6.17682 3.83189 6.45413 3.83189C6.73144 3.83189 6.95833 4.05878 6.95833 4.33609C6.95833 4.6134 6.73144 4.84029 6.45413 4.84029Z'/>
    </SvgIcon>
  )
}

export default IconFraud