import { http } from '../utilities/http'
import { stringify } from 'query-string'
import { toast } from '../utilities'

export async function getPatrolies(params, body) {
  try {
    const date = new Date()
    body.timezoneOffset = -date.getTimezoneOffset()/60
    const { data } = await http.post(`/patrols/search?${stringify(params)}`, body)

    const newRows = data.rows.map((item, index) => {
      item.id = index
      return item
    })

    return {
      ...data,
      rows: newRows
    }

  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function getDispatchPatrolies(params, body) {
  try {
    const date = new Date()
    body.timezoneOffset = -date.getTimezoneOffset()/60
    const { data } = await http.post(`/dispatches/search/dispatch-patrol?${stringify(params)}`, body)

    const newRows = data.rows.map((item, index) => {
      item.id = index
      return item
    })

    return {
      ...data,
      rows: newRows
    }

  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function getDispatchEvidences(params, body) {
  try {
    const date = new Date()
    body.timezoneOffset = -date.getTimezoneOffset()/60
    const { data } = await http.post(`/dispatches/search/dispatch-evidence?${stringify(params)}`, body)

    const newRows = data.rows.map((item, index) => {
      item.id = index
      return item
    })

    return {
      ...data,
      rows: newRows
    }

  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function dispatchPatrol(params) {
  try {
    const { data } = await http.post('/dispatches/patrol', params)

    return data

  } catch (e) {
    toast({title: e?.response?.data.error, icon: 'error'}).then(() => {})
    return {'status': 'error', message: e?.response?.data.error}
  }
}

export async function dispatchEvidence(params) {
  try {
    const { data } = await http.post('/dispatches/evidence', params)

    return data

  } catch (e) {
    toast({title: e?.response?.data.error, icon: 'error'}).then(() => {})
    return {'status': 'error', message: e?.response?.data.error}
  }
}

export async function getActivityType(params) {
  try {
    const { data } = await http.get(`/activity-types?${stringify(params)}`)

    return data
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function searchActivityType(queryParams, bodyParams) {
  try {
    const { data } = await http.post(`/activity-types/search?${stringify(queryParams)}`, bodyParams)

    return data
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export const postActivityType = async (bodyParams) => {
  try {
    const { data } = await http.post('/activity-types', bodyParams)
    return data
  } catch (error) {
    return {
      status: 'error'
    }
  }
}

export const putActivityType = async (id, bodyParams) => {
  try {
    const { data } = await http.put(`/activity-types/${id}`, bodyParams)
    return data
  } catch (error) {
    return {
      status: 'error',
    }
  }
}

export async function getSSDGroups(params) {
  try {
    const { data } = await http.get(`/ssd-groups?${stringify(params)}`)

    return data
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function getSSDSubGroups(params) {
  try {
    const { data } = await http.get(`/ssd-sub-groups?${stringify(params)}`)

    return data
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function getCrimeTypes(params) {
  try {
    const { data } = await http.get(`/crime-types?${stringify(params)}`)

    return data
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function getDeviceAppVersion(params) {
  try {
    const { data } = await http.get(`/devices/device-app-version?${stringify(params)}`)

    return data
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function getDeviceAndVehicles(params) {
  try {
    const { data } = await http.get(`/voc-groups/device-vehicle?${stringify(params)}`)

    return data
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function getGroups(params) {
  try {
    const { data } = await http.get(`/voc-groups?${stringify(params)}`)

    return data
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function updateStatus(id, params) {
  try {
    const { data } = await http.put(`/devices/${id}/update-status`, params)

    return data
  } catch (e) {
    toast({title: e?.response?.data.error, icon: 'error'}).then(() => {})
    return {'status': 'error', rows: []}
  }
}

export async function getPatroli(id) {
  try {
    const { data } = await http.get(`/patrols/${id}`)

    return data
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function downloadPDF(id, params) {
  try {
    const { data } = await http.post(`/reports/generate/${id}?${stringify(params)}`, {}, {responseType: 'arraybuffer'})

    return data
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}


export async function assignEvidenceToDevice(body) {
  try {
    const { data } = await http.post('/dispatches/assign/evidence', body)

    return data

  } catch (e) {
    toast({title: e?.response?.data.error, icon: 'error'}).then(() => {})
    return {'status': 'error', message: e?.response?.data.error}
  }
}

export async function postDispatchSearch(queryParams, bodyParams) {
  try {
    const { data } = await http.post(`/dispatches/search?${stringify(queryParams)}`, bodyParams)
    return data
  } catch (e) {
    return {'status': 'error', message: e?.response?.data.error}
  }
}

export async function postDispatchDetail(inputDispatchNo) {
  try {
    const { data } = await http.post('/dispatches/search?size=100000', {})
    return data.rows.find(item => item.dispatchNo === inputDispatchNo)
  } catch (e) {
    return {'status': 'error', message: e?.response?.data.error}
  }
}

export async function postDispatchCreate(bodyParams) {
  try {
    const { data } = await http.post('/dispatches', bodyParams)
    return data
  } catch (e) {
    return {'status': 'error', message: e?.response?.data.error}
  }
}

export async function putDispatchUpdate(inputDispatchNo, bodyParams) {
  try {
    const { data } = await http.put(`/dispatches/${inputDispatchNo}`, bodyParams)
    return data
  } catch (e) {
    return {'status': 'error', message: e?.response?.data.error}
  }
}

export async function deleteDispatch(inputDispatchNo) {
  try {
    const response = await http.delete(`/dispatches/${inputDispatchNo}`)
    return response
  } catch (e) {
    return {'status': 'error', message: e?.response?.data.error}
  }
}

export const advanceSearchDispatch = async (queryParameters, bodyParameters) => {
  try {
    const { data } = await http.post(`/dispatches/advanced-search?${stringify(queryParameters)}`, bodyParameters)
    return data
  } catch (e) {
    return {
      status: 'error', 
      message: e?.response?.data.error,
    }
  }
}