// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { DataGridPremium } from '@mui/x-data-grid-premium'
import { styled } from '@mui/material/styles'

const cellWidth = '322px'
const checkboxCellWidth = '50px'

const CustomDataGridObjects = styled(({ className, ...props }) => (
  <DataGridPremium
    checkboxSelection
    rowHeight={48}
    headerHeight={48}
    hideFooter
    disableColumnMenu
    {...props}
    className={className}
  />
))(({ theme }) => ({
  border: 'none',
  fontSize: 14,
  color: theme.palette.text.primary,

  // HEADER
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-sortIcon': {
    color: theme.palette.primary.main,
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    padding: 0,
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 600,
  },
  '& .MuiDataGrid-pinnedColumnHeaders': {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader': {
    minWidth: `${cellWidth} !important`,
  },
  '& .MuiDataGrid-columnHeaderCheckbox': {
    minWidth: `${checkboxCellWidth} !important`,
  },

  // CELL
  '& .MuiDataGrid-cell': {
    minWidth: `${cellWidth} !important`,
  },
  '& .MuiDataGrid-cellCheckbox': {
    minWidth: `${checkboxCellWidth} !important`,
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },

  // ROW
  '& .MuiDataGrid-row': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${colors.linkWater} !important`
    },
    '&:active': {
      backgroundColor: `${colors.perwinkleGray} !important`
    },
    '&.Mui-selected': {
      backgroundColor: `${colors.perwinkleGray} !important`,
      '&:hover': {
        backgroundColor: `${colors.linkWater} !important`
      },
      '&:active': {
        backgroundColor: `${colors.perwinkleGray} !important`
      },
    }
  },
}))

export default CustomDataGridObjects