// MUI STYLES
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  carousel: {
    borderRadius: 'inherit !important',
    '& .MuiButtonBase-root': {
      backgroundColor: `${alpha(theme.palette.common.white, 0.2)}!important`,
      '&:hover': {
        backgroundColor: `${alpha(theme.palette.common.white, 0.2)}!important`
      }
    }
  },
  carouselNavigationSmall: {
    '& .MuiButtonBase-root': {
      height: 28,
      width: 28,
      top: 'calc(50% - 12px) !important',
    },
    '& .MuiSvgIcon-root': {
      height: 18,
      width: 18,
    },
  },
  carouselItemVisual: {
    maxWidth: '100%',
    maxHeight: '100%',
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    position: 'absolute',
    zIndex: 3,
  },
  carouselBlurredBackground: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    filter: 'blur(8px)',
    WebkitFilter: 'blur(8px)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 2,
  },
  carouselItemAudioWave: {
    position: 'absolute',
    zIndex: 1,
    margin: 'auto',
    maxWidth: '75%',
    maxHeight: '25%',
  },
  carouselItemButton: {
    position: 'absolute',
    zIndex: 4,
    margin: 'auto',
    color: 'white',
    width: '68px !important',
    height: '68px !important',
    cursor: 'pointer',
  },
  carouselItemButtonSmall: {
    width: '40px !important',
    height: '40px !important',
  },
  carouselStepper: {
    marginTop: 12,
    justifyContent: 'center',
    '&.MuiPaper-root': {
      backgroundColor: 'transparent',
    },
  },
  displayNone: {
    display: 'none',
  },
  sosIcon: {
    height: 80,
    width: 80,
  },
}))

export default useStyles