import { useContext, useEffect } from 'react'
import moment from 'moment'

// COMPONENTS
import Clustering from './Clustering/Clustering'
import Map from './Map/Map'
import SimpleBackdrop from 'components/Partials/SimpleBackdrop'

// CONTEXTS
import { PageAnalyticsContext } from 'contexts/PageAnalyticsContext'
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'

// MUIS
import Stack from '@mui/material/Stack'

// SERVICES
import { getEvidences, getEvidencesCitizen } from 'services/EvidenceService'

// STYLES
import useStyles from './overviewAnalyticsUseStyles'

// UTILS
import { generateMediaUrlBySize } from 'utilities/media'
import { getLastNDays } from 'utilities'

const OverviewAnalytics = () => {
  const classes = useStyles()
  const {
    map, viewType, setDataEvidences, paramsFilter,
    isFetchingOnLoading, setIsFetchingOnLoading,
    pageRef
  } = useContext(PageAnalyticsContext)
  const { isDrawerExpanded } = useContext(PrivateLayoutContext)

  const fetchEvidences = async () => {
    setIsFetchingOnLoading(true)
    const lastSevenDays = getLastNDays(7)

    let filters = {
      startCreatedDate: moment(lastSevenDays?.start, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-yyyy'),
      endCreatedDate: moment(lastSevenDays?.end, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-yyyy')
    }

    if(paramsFilter.location.latLng.length === 2) {
      filters.geospatial = {
        point: {
          latitude: paramsFilter.location.latLng[0],
          longitude: paramsFilter.location.latLng[1]
        },
        radius: 500,
        type: 'radius'
      }
    }

    if(paramsFilter.tagEvidences.length) {
      filters.tagNos = paramsFilter.tagEvidences.map(item => item.tagNo)
    }

    if(viewType === 'internal' && paramsFilter.devices.length) {
      filters.deviceNos = paramsFilter.devices.map(item => item.deviceNo)
    }

    let response
    if(viewType === 'internal') {
      response = await getEvidences({ page: 0, size: 2000 }, filters)
    } else if (viewType === 'citizen') {
      response = await getEvidencesCitizen({ page: 0, size: 2000 }, filters)
    }
    
    const finalEvidenceList = response?.rows?.map(evidenceItem => {
      const newMediaList = evidenceItem.medias.map(mediaItem => {
        return generateMediaUrlBySize(mediaItem)
      })

      return {
        ...evidenceItem,
        medias: newMediaList,
      }
    })

    setDataEvidences(finalEvidenceList)
    setIsFetchingOnLoading(false)
  }

  useEffect(() => {
    fetchEvidences()
  }, [paramsFilter, viewType])

  /* SIDE EFFECT WHEN DRAWER EXPANDED */
  useEffect(() => {
    let timer = setTimeout(() => {
      if (Object.keys(map)?.length) map?.invalidateSize()
    }, 300)

    return () => clearTimeout(timer)
  }, [isDrawerExpanded])

  return (
    <>
      <Stack className={classes.pageRoot} ref={pageRef}>
        <Stack className={classes.mapWrapper}>
          <Map>
            <Clustering />
          </Map>
        </Stack>
      </Stack>

      <SimpleBackdrop isLoading={isFetchingOnLoading}/>
    </>
  )
}

export default OverviewAnalytics