import React, { useState,useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// COMPONENTS
import Snackbar from 'components/Snackbar/Snackbar'

// CONSTANTS
import { basePaths } from 'constants/paths'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { PageAlertRulesContext } from 'contexts/PageAlertRulesContext'

// CUSTOM COMPONENTS
import CustomInput from 'components/Customs/CustomInput'
import CustomInputLabel from 'components/Customs/CustomInputLabel'
import CustomDialogActionButton from 'components/Customs/CustomDialogActionButton'
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'
import CustomTextField from 'components/Customs/CustomTextField'
import PanelObjects from '../PanelObjects'

// MUIS
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import Dialog from '@mui/material/Dialog'
import Stepper from '@mui/material/Stepper'
import StepLabel from '@mui/material/StepLabel'
import Step from '@mui/material/Step'

// MUI ICONS
import IconFormatColorText from '@mui/icons-material/FormatColorText'
import IconWidgets from '@mui/icons-material/Widgets'
import IconNotes from '@mui/icons-material/Notes'
import IconInfo from '@mui/icons-material/InfoOutlined'
import CloseIcon from '@mui/icons-material/Close'
import PlaceIcon from '@mui/icons-material/Place'

// STYLES
import useStyles from './dialogAddAlertRuleUseStyles'

// SERVICE 
import { addNewRule } from 'services/AlertService'
import { getAllPlacesList } from 'services/PlacesService'

const DialogAddAlertRule = (props) => {
  const {
    dialogAddAlertRule, 
    setDialogAddAlertRule,
  } = props

  const history = useHistory()
  const location = useLocation()

  const classes = useStyles()
  const steps = ['Nama dan jenis', 'Pengaturan']

  // CONTEXT
  const { snackbarObject, setSnackbarObject } = useContext(AllPagesContext)
  const {
    selectionModel, setSelectionModel, geofencesList, setGeofencesList, setIsRefetchingRules, setSelectedRulesItem
  } = useContext(PageAlertRulesContext)

  // GEOFENCES TYPE
  const geofenceType = [{
    id: 1,
    label: 'Geofence Visits',
    value: 'INOUTZONE'
  }]

  // INIT PARAMS
  const initialDialogParams = {
    name: '',
    type: '',
    labelGeofence: '',
    description: '',
    zoneIds : []
  }
  
  // STATES
  const [dialogParams, setDialogParams] = useState(initialDialogParams)
  const [activeStep, setActiveStep] = useState(0)

  // FETCH GEOFENCE PLACES
  const fetchGeofencePlaces = async () => {
    const response = await getAllPlacesList(
      {},
      {
        name: '',
        place_no: '',
        zone: {
          address: '',
          area: '',
          description: '',
          geofence_id: '',
          label: '',
          zone_no: ''
        }
      })
    setGeofencesList(response)
  }

  // HANDLE PARAMS
  const handleDialogParamsChange = (event) => {
    setDialogParams({
      ...dialogParams,
      [event.target.name]: event.target.value,
    })
  }

  // HANDLE SAVE BUTTON
  const handleSaveButton = async () => {
    const params = {
      description : dialogParams.description,
      name : dialogParams.name,
      objectIds : selectionModel,
      type : dialogParams.type,
      zoneIds : dialogParams.zoneIds,
    }

    if(activeStep <= 0) return setActiveStep(1)

    const result = await addNewRule(params)

    setSnackbarObject({
      open: true,
      severity: 'success',
      title: '',
      message: 'Sukses Membuat Ketentuan Baru',
    })

    setDialogParams(initialDialogParams)
    setActiveStep(0)
    setSelectionModel([])
    setSelectedRulesItem(false)
    setIsRefetchingRules(true)
    setDialogAddAlertRule(false)
      
  }

  // SIDE EFFECT FIRST MOUNT
  useEffect(() => {
    // CLEAR SELECTION MODEL
    setSelectionModel([])

    // FETCH
    fetchGeofencePlaces()
  }, [])

  useEffect(() => {
    if (location.pathname === basePaths.alertRules && location.search !== '?mode=add') {
      setDialogParams(initialDialogParams)
      setActiveStep(0)
      setDialogAddAlertRule(false)
    }
  }, [location])

  return (
    <Dialog
      open={Boolean(dialogAddAlertRule)}
      className={classes.dialogContainer}
    >
      <Box className={classes.panelContainer}>
        {/* HEADER */}
        <Box className={classes.panelHeader}>
          {/* PANEL TITLE */}
          <Typography
            variant='h6'
            className={classes.panelTitle}
          >
            Ketentuan Baru
          </Typography>

          {/* CLOSE BUTTON */}
          <IconButton onClick={() => history.push(basePaths.alertRules)}>
            <CloseIcon/>
          </IconButton>
          
        </Box>

        <Divider/>

        <Box className={classes.ruleSettingsBox}>
          {/* PANEL OBJECTS */}
          <PanelObjects />

          <Box className={classes.settingTabs}>
            {/* STEPPER */}
            <Box className={classes.stepperContainer}>
              <Stepper activeStep={activeStep} className={classes.stepper}>
                {steps.map((label, index) => {
                  return (
                    <Step key={label}>
                      <StepLabel >{label}</StepLabel>
                    </Step>
                  )
                })}
              </Stepper>
            </Box>
            
            {/* OBJECT INFORMATION */}
            {selectionModel.length < 1 && (
              <Box className={classes.objectTypography}>
                <Typography className={classes.objectTitle}>
                  Silahkan pilih object
                </Typography>

                <Typography>
                  Tetapkan aturan untuk tetap mendapat informasi tentang peristiwa apa pun yang terjadi dengan objek Anda. Sistem akan mencatat peristiwa secara real time dan dapat langsung memberi tahu Anda begitu peristiwa itu terjadi. Setelah itu Anda dapat melihat semua catatan acara dalam laporan tabel.
                </Typography>
              </Box>
            )}

            {/* CONTENT NAME AND TYPE */}
            {activeStep === 0 && selectionModel.length >= 1 && (
              <Box className={classes.contentTabs}>
                <Typography variant='h6' className={classes.panelTitle}>
                  Nama dan jenis
                </Typography>

                {/* GEOFENCES TYPE */}
                <Box className={classes.iconAndFormControlContainer}>
                  <IconWidgets className={classes.iconFormControl}/>
                  <FormControl variant='standard' className={classes.formControl}>
                    <Autocomplete
                      disablePortal
                      options={geofenceType.map(item => item.label)}
                      className={classes.autocomplete}
                      getOptionLabel={(option) => option}
                      value={dialogParams.labelGeofence}
                      onChange={(event, value, reason, details) => {
                        const findTypeDetail = geofenceType.find(item => item.label === value)

                        setDialogParams({
                          ...dialogParams,
                          labelGeofence: value,
                          type: findTypeDetail.value
                        })
                      }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          variant='standard'
                          label='Jenis'
                        />
                      )}
                    />
                  </FormControl>
                </Box>
                
                {/* NAME */}
                <Box className={classes.iconAndFormControlContainer}>
                  <IconFormatColorText className={classes.iconFormControl}/>
                  <FormControl 
                    variant='standard' 
                    className={classes.formControl}
                  >
                    <CustomInputLabel>
                      Nama
                    </CustomInputLabel>
                    <CustomInput
                      type='text'
                      name='name'
                      value={dialogParams.name}
                      onChange={handleDialogParamsChange}
                    />
                  </FormControl>
                </Box>

                {/* DESCRIPTION */}
                <Box className={classes.iconAndFormControlContainer}>
                  <IconNotes className={classes.iconFormControl}/>
                  <FormControl 
                    variant='standard' 
                    className={classes.formControl}
                  >
                    <CustomInputLabel>
                      Keterangan
                    </CustomInputLabel>
                    <CustomInput
                      type='text'
                      name='description'
                      value={dialogParams.description}
                      onChange={handleDialogParamsChange}
                    />
                  </FormControl>
                </Box>
              </Box>
            )}

            {/* CHOOSE GEOFENCES SETTING */}
            {activeStep === 1 && selectionModel.length >= 1 && (
              <Box className={classes.contentTabs}>
                <Box className={classes.titleRow}>
                  <Typography variant='h6' className={classes.panelTitle}>
                    Geofence
                  </Typography>

                  <CustomTooltipBlack
                    title='Isi bagian, jika aturan hanya perlu bekerja di dalam/di luar geofence yang dipilih'
                    placement='top'
                  >
                    <IconButton
                      size='small'
                      sx={{ ml: 1 }}
                    >
                      <IconInfo className={classes.iconInfo} fontSize='small' />
                    </IconButton>
                  </CustomTooltipBlack>
                </Box>

                {/* CHOOSE GEOFENCES CONTROL */}
                <Box className={classes.geofenceRow}>
                  <PlaceIcon className={classes.iconFormControl}/>
                  
                  <FormControl variant='standard' className={classes.formControl}>
                    <Autocomplete
                      disablePortal
                      multiple
                      limitTags={2}
                      options={geofencesList ? geofencesList.rows.map(item => item.place_no) : []}
                      className={classes.autocomplete}
                      getOptionLabel={(option) => geofencesList.rows.find(item => item.place_no === option).name}
                      value={dialogParams.zoneIds}
                      onChange={(event, value, reason, details) => {
                        setDialogParams({
                          ...dialogParams,
                          zoneIds: value,
                        })
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            label={geofencesList.rows.find(item => item.place_no === option).name}
                            {...getTagProps({ index })}
                            deleteIcon={<CloseIcon />}
                            className={classes.chipCustomMenu}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          variant='standard'
                          label='Geofence'
                        />
                      )}
                    />
                  </FormControl>

                  <CustomTooltipBlack
                    title='Buat/ubah geofence di bagian pelacakan'
                    placement='top'
                  >
                    <IconButton
                      size='small'
                      sx={{ ml: 1 }}
                    >
                      <IconInfo className={classes.iconInfo} fontSize='small' />
                    </IconButton>
                  </CustomTooltipBlack>
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        {/* FOOTER */}
        <Box className={classes.footer}>
          <Typography variant='subtitle2' className={classes.selectedInfo}>
            {selectionModel.length > 0 && (
              `Terpilih: ${selectionModel.length} Objek `
            )}
          </Typography>

          <CustomDialogActionButton
            onClick={handleSaveButton} 
            className={classes.customSave}
            disabled={selectionModel.length < 1 ? true : false}>
            {activeStep === 0 ? 'Selanjutnya' : 'Simpan'}
          </CustomDialogActionButton>
          
        </Box>
      </Box>

      {/* SNACKBAR */}
      <Snackbar
        open={snackbarObject.open}
        setToast={setSnackbarObject}
        severity={snackbarObject.severity}
        title={snackbarObject.title}
        message={snackbarObject.message}
      />
    </Dialog>
  )
}

export default DialogAddAlertRule