// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconBarChart = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M3 12H7V21H3V12ZM17 8H21V21H17V8ZM10 2H14V21H10V2Z' />
    </SvgIcon>
  )
}

export default IconBarChart