import * as React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { useEffect, useState } from 'react'

export default function SimpleBackdrop({isLoading}) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(isLoading)
  }, [isLoading])

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
        open={open}
      >
        <CircularProgress color='primary' />
      </Backdrop>
    </div>
  )
}
