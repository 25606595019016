import React, { useContext, useState } from 'react'

// STYLES
import useStyles from './dialogEditPasswordUseStyles'

// MUIS
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import LoadingButton from '@mui/lab/LoadingButton'

// COMPONENTS
import CustomInput from 'components/Customs/CustomInput'
import CustomInputLabel from 'components/Customs/CustomInputLabel'

// ICONS
import Lock from '@mui/icons-material/Lock'
import RemoveRedEye from '@mui/icons-material/RemoveRedEye'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import PasswordIcon from '@mui/icons-material/Password'

// SERVICES
import { updatePasswordDashboard } from 'services/UserService'

// CONTEXT
import { AllPagesContext } from 'contexts/AllPagesContext'

const DialogEditPassword = (props) => {
  const { isOpen, setIsOpen } = props
  const classes = useStyles()
  
  const { setSnackbarObject } = useContext(AllPagesContext)

  const initValueState = {
    value: '',
    isShow: false
  }

  /* STATE */
  const [isLoading, setIsLoading] = useState(false)
  const [oldPassword, setOldPassword] = useState(initValueState)
  const [newPassword, setNewPassword] = useState(initValueState)
  const [confirmNewPassword, setConfirmNewPassword] = useState(initValueState)

  /* HANDLE INPUT */
  const handleInputChange = (inputEvent, setState, valueState) => {
    setState({
      ...valueState,
      value: inputEvent.target.value
    })
  }

  /* ICON SHOW */
  const IconShow = ({isShow}) => {
    return(
      <>
        {!isShow && (<RemoveRedEye className={classes.iconFormControl2} />)}
        {isShow && (<VisibilityOff className={classes.iconFormControl2} />)}
      </>
    )
  }

  /* DISABLED BUTTON */
  const disabledButton = () => {
    if(oldPassword.value.length && newPassword.value.length && confirmNewPassword.value.length) return false
    else return true
  }

  /* HANDLE SAVE PASSWORD */
  const handleSaveButton = async () => {
    setIsLoading(true)

    const update = await updatePasswordDashboard({
      oldPassword: oldPassword.value,
      newPassword: newPassword.value,
      confirmNewPassword: confirmNewPassword.value
    })

    if(update.status === 'success') {
      /* CLEAR STATE AFTER SAVE */
      setOldPassword(initValueState)
      setNewPassword(initValueState)
      setConfirmNewPassword(initValueState)

      /* NOTIF */
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Sukses Mengubah Kata Sandi',
      })

      /* CLOSE DIALOG */
      setIsLoading(false)
      setIsOpen(false)
    } else {
      setIsLoading(false)

      let msg = ''
      if(update.err.response.data.message === 'Invalid Confirmed Password')
        msg = 'Konfirmasi kata sandi harus sama dengan kata sandi baru'
      else if (update.err.response.data.message === 'Invalid Old Password')
        msg = 'Kata sandi saat ini salah'
      else
        msg = ''

      msg && setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: msg,
      })
    }
  }

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <Box className={classes.dialogWrap}>
        {/* HEADER */}
        <DialogTitle className={classes.dialogHeader}>
          {/* TITLE */}
          <Typography variant='h6' className={classes.dialogTitle}>Ubah Kata Sandi</Typography>

          {/* DESCRIPTION */}
          <Typography variant='body2' className={classes.dialogDescription}>
            Atur kata sandi yang kuat untuk mencegah akses yang tidak sah ke akun Anda.
          </Typography>
        </DialogTitle>

        {/* CONTENT */}
        <DialogContent className={classes.dialogContent}>
          {/* OLD PASSWORD INPUT */}
          <Box className={classes.iconAndFormControlContainer}>
            <Lock className={classes.iconFormControl} />
            <FormControl variant='standard' className={classes.formControl}>
              <CustomInputLabel>Kata Sandi Saat Ini</CustomInputLabel>
              <CustomInput
                type={!oldPassword.isShow ? 'password' : 'text'}
                name='oldPassword'
                value={oldPassword.value}
                onChange={(event) => handleInputChange(event, setOldPassword, oldPassword)}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setOldPassword({...oldPassword, isShow: !oldPassword.isShow})}>
                      <IconShow isShow={oldPassword.isShow} />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>

          {/* NEW PASSWORD INPUT */}
          <Box className={classes.iconAndFormControlContainer}>
            <Lock className={classes.iconFormControl} />
            <FormControl variant='standard' className={classes.formControl}>
              <CustomInputLabel>Kata Sandi Baru</CustomInputLabel>
              <CustomInput
                type={!newPassword.isShow ? 'password' : 'text'}
                name='newPassword'
                value={newPassword.value}
                onChange={(event) => handleInputChange(event, setNewPassword, newPassword)}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setNewPassword({...newPassword, isShow: !newPassword.isShow})}>
                      <IconShow isShow={newPassword.isShow} />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>

          {/* CONFIRM NEW PASSWORD INPUT */}
          <Box className={classes.iconAndFormControlContainer}>
            <PasswordIcon className={classes.iconFormControl} />
            <FormControl variant='standard' className={classes.formControl}>
              <CustomInputLabel>Konfirmasi Kata Sandi</CustomInputLabel>
              <CustomInput
                type={!confirmNewPassword.isShow ? 'password' : 'text'}
                name='confirmNewPassword'
                value={confirmNewPassword.value}
                onChange={(event) => handleInputChange(event, setConfirmNewPassword, confirmNewPassword)}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setConfirmNewPassword({...confirmNewPassword, isShow: !confirmNewPassword.isShow})}>
                      <IconShow isShow={confirmNewPassword.isShow} />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
        </DialogContent>

        {/* DIALOG ACTIONS */}
        <DialogActions className={classes.dialogActions}>
          <LoadingButton
            className={classes.btnChangePassword}
            variant='contained'
            color='primary'
            disableElevation
            disabled={disabledButton()}
            onClick={() => handleSaveButton()}
            loading={isLoading}
          >
            Ubah Kata Sandi
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default DialogEditPassword