import React, { useState, useRef, useEffect } from 'react'

// CAROUSEL
import Carousel from 'react-material-ui-carousel'

// MUIS
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import CardMedia from '@mui/material/CardMedia'

// MUI ICONS
import IconClose from '@mui/icons-material/Close'
import IconFileDownload from '@mui/icons-material/FileDownload'
import IconLocalPrintshop from '@mui/icons-material/LocalPrintshop'

// PDF PRINT
import ReactToPrint from 'react-to-print'

// STYLES
import useStyles from './backdropDetailMediaUseStyles'

// SERVICES
import { viewMediaCount, downloadMediaWithQR } from 'services/BlockChainService'

const BackdropDetailMedia = (props) => {
  const { 
    backdropDetailMedia, 
    setBackdropDetailMedia, 
  } = props

  const classes = useStyles()

  const printPreviewRef = useRef()

  const [ selectedMedia, setSelectedMedia ] = useState(0)

  const handleDownloadIconClick = async () => {
    const downloadMedia = await downloadMediaWithQR(backdropDetailMedia.medias[selectedMedia])
  }

  const handleMediaPlayer = (item) => {
    if (item.mediaType === 'IMAGE') return 'img'
    else if (item.mediaType === 'VIDEO' && item.fileType !== 'video/3gp') return 'video'
    else if (item.mediaType === 'AUDIO') return 'audio'
  }

  const handleMediaShow = async () => {
    const param = { mediaNo : backdropDetailMedia?.medias[selectedMedia]?.mediaNo }

    if(selectedMedia === 0 && backdropDetailMedia ) await viewMediaCount(param) 
    else if (selectedMedia !== 0 && backdropDetailMedia) await viewMediaCount(param)
  }

  useEffect(() => {
    handleMediaShow()
  }, [selectedMedia, backdropDetailMedia])

  return (
    <Backdrop
      open={Boolean(backdropDetailMedia)}
      onClick={() => setBackdropDetailMedia(null)}
      className={classes.root}
    >
      {/* CONTENT CONTAINER */}
      <Box className={classes.contentContainer}>
        {/* MAIN CONTENT */}
        <Box className={classes.mainContent}>
          {/* CAROUSEL */}
          {(backdropDetailMedia && backdropDetailMedia.medias) &&
          <Carousel 
            autoPlay={false} 
            indicators={false} 
            navButtonsAlwaysVisible={backdropDetailMedia.medias.length > 1 ? true : false}
            navButtonsAlwaysInvisible={backdropDetailMedia.medias.length > 1 ? false : true}
            animation='slide'
            className={classes.carouselContainer}
            index={selectedMedia}
            onChange={(now, previous) => setSelectedMedia(now)}
          >
            {backdropDetailMedia.medias.map((item, index) => (
              <Box
                key={index}
                className={classes.carouselItem}
              >
                <CardMedia
                  ref={printPreviewRef}
                  component={handleMediaPlayer(item)}
                  src={item.mediaUrl}
                  autoPlay
                  controls
                  alt=''
                  className={classes.carouselMedia}
                  onClick={(event) =>
                    event.stopPropagation()
                  }
                />
              </Box>
            ))}
          </Carousel>}
        </Box>

        {/* PAGINATION */}
        <Grid 
          container
          className={classes.paginationContainer}
          onClick={(event) => event.stopPropagation()}
        >
          {/* TITLE */}
          <Grid
            item
            xs={4}
            className={classes.paginationItem}
          >
            <Typography
              variant='subtitle1'
              className={classes.paginationTitle}
            >
              Judul
            </Typography>
          </Grid>

          {/* ACTIONS */}
          <Grid
            item
            xs={4}
            className={classes.paginationItem}
          >
            {/* PRINT ICON */}
            <ReactToPrint
              trigger={() =>
                <IconLocalPrintshop className={`${classes.iconActions} ${classes.paginationIcon}`}/>
              }
              content={() => printPreviewRef.current}
              // onAfterPrint={() => setBackdropFileType(null)}
            />           

            {/* DOWNLOAD ICON */}
            <IconFileDownload 
              className={`${classes.iconActions} ${classes.paginationIcon}`}
              onClick={() => handleDownloadIconClick()}
            />

            {/* CLOSE BUTTON */}
            <IconClose
              className={`${classes.iconActions} ${classes.contentCloseIcon}`}
              onClick={() => setBackdropDetailMedia(null)}
            />
          </Grid>
        </Grid>
      </Box>
    </Backdrop>
  )
}

export default BackdropDetailMedia