import { http } from 'utilities/http'

export const getAgoraChatToken = async () => {
  try {
    const { data } = await http.get('agora-chat/generate-user-token')
    return data
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

export const getAgoraChatRTCToken = async (channelId) => {
  try {
    const { data } = await http.get(`agora-chat/generate-rtc-token?channelId=${channelId}`)
    return data
  } catch (error) {
    return {
      status: 'error'
    }
  }
}

export const postFriendList = async (bodyParams) => {
  try {
    const { data } = await http.post('agora-chat/contacts', bodyParams)
    return data
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

export const getConversationList = async () => {
  try {
    const { data } = await http.get('agora-chat/conversations')
    return data
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}