// UTILITIES
import { http } from 'utilities/http'

export const getConferenceList = async () => {
  try {
    const { data } = await http.get('conference/list')
    return data
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

export const putConferenceJoin = async (params) => {
  try {
    return  await http.put('conference/join', params)
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

export const putConferenceLeave = async (params) => {
  try {
    return  await http.put('conference/leave', params)
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

export const putConferenceStop = async (params) => {
  try {
    return  await http.put('conference/stop', params)
  } catch (error) {
    return {
      status: 'error',
    }
  }
}

export const postConferenceCreate = async (params) => {
  try {
    return await http.post('conference/start', params)
  } catch (error) {
    return {
      status: 'error',
    }
  }
}