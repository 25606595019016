// MUIS
import Tab from '@mui/material/Tab'
import { styled } from '@mui/material/styles'

const CustomTab = styled(({ className, ...props }) => (
  <Tab 
    className={className} 
    {...props} />
))(({ theme }) => ({
  '&.MuiTab-root': {
    height: 66,
    fontSize: 16,
    color: theme.palette.text.secondary,
  },
  '&.MuiTab-root.Mui-selected': {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
}))

export default CustomTab
