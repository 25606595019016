import { useContext, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// MUIS
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Popper from '@mui/material/Popper'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconAddCircle from '@mui/icons-material/AddCircle'
import IconClose from '@mui/icons-material/Close'

// PATH ICONS
import IconBoolean from 'assets/images/pathIcons/IconBoolean'
import IconEqualizerLine from 'assets/images/pathIcons/IconEqualizerLine'
import IconText from 'assets/images/pathIcons/IconText'

// STYLES
import useStyles from './filterEvidenceUseStyles'

const FilterEvidence = (props) => {
  const { filterWrapperRef } = props
  const classes = useStyles()
  const {
    queryFilterEvidence, setQueryFilterEvidence, objectList, tagList,
    queryFilterDispatch, setQueryFilterDispatch, currentPanelTabLeft
  } = useContext(PageTrackingContext)

  const fieldEvidenceList = [
    {
      icon: IconBoolean,
      value: 'SOS_STATUS',
      label: 'SOS'
    },
    {
      icon: IconText,
      value: 'DEVICE',
      label: 'Perangkat'
    },
    {
      icon: IconText,
      value: 'TAG',
      label: 'Tag'
    },
  ]
  const fieldDispatchList = [
    {
      icon: IconText,
      value: 'STATUS',
      label: 'Status'
    },
    {
      icon: IconText,
      value: 'DEVICE',
      label: 'Perangkat'
    },
  ]
  const sosDeviceStatusOperatorList = [
    { value: 'IS' },
    { value: 'IS_NOT' },
  ]
  const tagOperatorList = [
    { value: 'IS' },
    { value: 'IS_NOT' },
    { value: 'IS_EMPTY' },
    { value: 'IS_NOT_EMPTY' },
    { value: 'CONTAINS' },
    { value: 'DOES_NOT_CONTAINS' },
    { value: 'ENDS_WITH' },
    { value: 'STARTS_WITH' },
  ]
  const valueSosList = [{value: 'true' }, {value: 'false'}]
  const valueStatusDispatchList = [
    {value: 'FAILED', label: 'Gagal' },
    {value: 'ASSIGNED', label: 'Ditugaskan'},
    {value: 'COMPLETED', label: 'Selesai'},
    {value: 'ACCEPTED', label: 'Diterima'},
    {value: 'CANCELLED', label: 'Dibatalkan'}
  ]

  const initItemQuery = {id: uuidv4(), field: '', op: '', value: ''}

  const [dialogFilterAnchor, setDialogFilterAnchor] = useState(null)
  const [tempQueryEvidenceFilter, setTempQueryEvidenceFilter] = useState([initItemQuery])
  const [tempQueryDispatchFilter, setTempQueryDispatchFilter] = useState([initItemQuery])

  const updateTempQueryFilter = (id, key, value) => {
    const tempData = currentPanelTabLeft === 'Kejadian' ? [...tempQueryEvidenceFilter] : [...tempQueryDispatchFilter]
    const itemIndex = currentPanelTabLeft === 'Kejadian' ? tempQueryEvidenceFilter.findIndex(item => item.id === id) : tempQueryDispatchFilter.findIndex(item => item.id === id)

    tempData[itemIndex] = {
      ...tempData[itemIndex],
      [key]: value
    }

    if (key === 'field') {
      tempData[itemIndex].op = ''
      tempData[itemIndex].value = ''
    }

    currentPanelTabLeft === 'Kejadian' ? setTempQueryEvidenceFilter([...tempData]) : setTempQueryDispatchFilter([...tempData])
  }

  const addQueryFilter = () => {
    const tempData = currentPanelTabLeft === 'Kejadian' ? [...tempQueryEvidenceFilter] : [...tempQueryDispatchFilter]
    tempData.push(initItemQuery)
    currentPanelTabLeft === 'Kejadian' ? setTempQueryEvidenceFilter([...tempData]) : setTempQueryDispatchFilter([...tempData])
  }

  const removeQueryFilter = (id) => {
    const tempData = currentPanelTabLeft === 'Kejadian' ? tempQueryEvidenceFilter.filter(item => item.id !== id) : tempQueryDispatchFilter.filter(item => item.id !== id)
    currentPanelTabLeft === 'Kejadian' ? setTempQueryEvidenceFilter([...tempData]) : setTempQueryDispatchFilter([...tempData])
  }

  const findQueryFilter = (id, key) => {
    const findItem = currentPanelTabLeft === 'Kejadian' ? tempQueryEvidenceFilter.find(item => item.id === id) : tempQueryDispatchFilter.find(item => item.id === id)
    return findItem?.[key]
  }

  const handleSaveButtonClick = () => {
    const tempData = currentPanelTabLeft === 'Kejadian' ? [...tempQueryEvidenceFilter] : [...tempQueryDispatchFilter]
    currentPanelTabLeft === 'Kejadian' ? setQueryFilterEvidence([...tempData]) : setQueryFilterDispatch([...tempData])
    setDialogFilterAnchor(null)
  }

  const handleCancelButtonClick = () => {
    currentPanelTabLeft === 'Kejadian' ? setTempQueryEvidenceFilter([...queryFilterEvidence]) : setTempQueryDispatchFilter([...queryFilterDispatch])
    setDialogFilterAnchor(null)
  }

  const getTotalFilter = () => {
    let total = 0
    if (currentPanelTabLeft === 'Kejadian') {
      queryFilterEvidence.forEach(item => {
        if (item.field) total += 1
      })
    } else if (currentPanelTabLeft === 'Penugasan') {
      queryFilterDispatch.forEach(item => {
        if (item.field) total += 1
      })
    }
    return total
  }

  const getCurrentFieldList = () => {
    return currentPanelTabLeft === 'Kejadian' ? fieldEvidenceList : fieldDispatchList
  }

  const getCurrentTempQuery = () => {
    return currentPanelTabLeft === 'Kejadian' ? tempQueryEvidenceFilter : tempQueryDispatchFilter
  }

  useEffect(() => {
    if(Boolean(dialogFilterAnchor)) {
      currentPanelTabLeft === 'Kejadian'
        ? setTempQueryEvidenceFilter(queryFilterEvidence.length ? [...queryFilterEvidence] : [initItemQuery])
        : setTempQueryDispatchFilter(queryFilterDispatch.length ? [...queryFilterDispatch] : [initItemQuery])
    }
  }, [dialogFilterAnchor])

  return (
    <>
      <Stack>
        <Button
          variant='contained'
          startIcon={<IconEqualizerLine fontSize='small'/>}
          className={`${classes.buttonFilterEvidence} ${getTotalFilter() && 'active'}`}
          onClick={() => setDialogFilterAnchor(filterWrapperRef.current)}
        >
          Filter
          {getTotalFilter() ? <Stack className={classes.badgeFilter}>{getTotalFilter()}</Stack> : ''}
        </Button>
      </Stack>

      {/* DIALOG FILTER */}
      <Popper
        anchorEl={dialogFilterAnchor}
        open={Boolean(dialogFilterAnchor)}
        className={classes.dialogFilterEvidence}
      >
        <Stack className={classes.dialogFilterEvidenceBox}>
          <Typography fontWeight={600} variant='subtitle2' marginBottom='8px'>Tambah Filter</Typography>
          <Divider />

          {getCurrentTempQuery().map(item => (
            <Stack key={item.id} direction='row' marginTop='8px' justifyContent='space-between'>
              {/* FIELD */}
              <Stack width='108px' paddingRight='4px'>
                <Typography marginBottom='4px' variant='caption'>Field</Typography>
                <FormControl size='small'>
                  <Select
                    onChange={event => updateTempQueryFilter(item.id, 'field', event.target.value)}
                    value={findQueryFilter(item.id, 'field')}
                    renderValue={selected => selected === 'placeholder'
                      ? <Typography variant='caption' className={classes.placeholderSelect}>Pilih Field</Typography>
                      : getCurrentFieldList().find(item => item.value === selected)?.label}
                    MenuProps={{
                      MenuListProps: {
                        className: classes.menuSelectFilter,
                      },
                      PaperProps: {
                        className: classes.paperSelectFilter
                      }
                    }}
                    className={classes.selectFilter}
                  >
                    {getCurrentFieldList().map((item, index) => (
                      <MenuItem key={index} value={item.value} className={classes.itemSelectFilter}>
                        <item.icon />
                        <Typography variant='caption' noWrap>
                          {item.label}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>

              {/* OPERATOR */}
              <Stack width='108px' paddingRight='4px'>
                <Typography marginBottom='4px' variant='caption'>Operator</Typography>
                <FormControl size='small'>
                  <Select
                    onChange={event => updateTempQueryFilter(item.id, 'op', event.target.value)}
                    value={findQueryFilter(item.id, 'op')}
                    classNa
                    renderValue={selected => selected === '-'
                      ? <Typography variant='caption' className={classes.placeholderSelect}>-</Typography>
                      : selected?.replaceAll('_', ' ')?.toLowerCase()}
                    MenuProps={{
                      MenuListProps: {
                        className: classes.menuSelectFilter,
                      },
                      PaperProps: {
                        className: classes.paperSelectFilter
                      }
                    }}
                    className={classes.selectFilter}
                  >
                    {(findQueryFilter(item.id, 'field') === 'DEVICE' || findQueryFilter(item.id, 'field') === 'SOS_STATUS' || findQueryFilter(item.id, 'field') === 'STATUS') && sosDeviceStatusOperatorList.map((item, index) => (
                      <MenuItem key={index} value={item.value} className={classes.itemSelectFilter}>
                        <Typography variant='caption' noWrap>
                          {item.value.replaceAll('_', ' ').toLowerCase()}
                        </Typography>
                      </MenuItem>
                    ))}

                    {findQueryFilter(item.id, 'field') === 'TAG' && tagOperatorList.map((item, index) => (
                      <MenuItem key={index} value={item.value} className={classes.itemSelectFilter}>
                        <Typography variant='caption' noWrap>
                          {item.value.replaceAll('_', ' ').toLowerCase()}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>

              {/* VALUE */}
              <Stack width='108px' paddingRight='4px'>
                <Typography marginBottom='4px' variant='caption'>Value</Typography>
                {(!findQueryFilter(item.id, 'field') || findQueryFilter(item.id, 'op') === 'IS_EMPTY' || findQueryFilter(item.id, 'op') === 'IS_NOT_EMPTY') && <Typography marginTop='4px'>-</Typography>}
                {/* SOS */}
                {(findQueryFilter(item.id, 'field') === 'SOS_STATUS' || findQueryFilter(item.id, 'field') === 'STATUS') && <FormControl size='small'>
                  <Select
                    onChange={event => updateTempQueryFilter(item.id, 'value', event.target.value)}
                    value={findQueryFilter(item.id, 'value')}
                    MenuProps={{
                      MenuListProps: {
                        className: classes.menuSelectFilter,
                      },
                      PaperProps: {
                        className: classes.paperSelectFilter
                      }
                    }}
                    className={classes.selectFilter}
                  >
                    {findQueryFilter(item.id, 'field') === 'SOS_STATUS' && valueSosList.map((itemSos, index) => (
                      <MenuItem key={index} className={classes.itemSelectFilter} value={itemSos.value}>{itemSos.value}</MenuItem>
                    ))}

                    {findQueryFilter(item.id, 'field') === 'STATUS' && valueStatusDispatchList.map((itemStatus, index) => (
                      <MenuItem key={index} className={classes.itemSelectFilter} value={itemStatus.value}>{itemStatus.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>}

                {/* DEVICE & TAG */}
                {(findQueryFilter(item.id, 'field') === 'DEVICE' || findQueryFilter(item.id, 'field') === 'TAG'
                && (findQueryFilter(item.id, 'op') !== 'IS_EMPTY' && findQueryFilter(item.id, 'op') !== 'IS_NOT_EMPTY')) && <Autocomplete
                  disablePortal
                  options={findQueryFilter(item.id, 'field') === 'DEVICE' ? objectList : tagList}
                  getOptionLabel={option => option.label ?? ''}
                  renderInput={(params) => <TextField {...params} className={classes.autoCompleteInput} placeholder='Masukkan Value' />}
                  componentsProps={{
                    paper: {
                      className: classes.paperAutocomplete
                    }
                  }}
                  disableClearable
                  renderOption={(props, option) => <MenuItem
                    {...props}
                    key={option.id}
                    className={classes.autocompleteItem}
                  >
                    <Typography variant='caption' noWrap>{option.label ?? `[Tidak ada label ${option.id}]`}</Typography>
                  </MenuItem>}
                  onChange={(event, value) => {
                    updateTempQueryFilter(item.id, 'value', value)
                  }}
                  value={findQueryFilter(item.id, 'value')}
                />}
              </Stack>

              <Stack flex={0} justifyContent='flex-end' paddingBottom='4px'>
                <IconButton size='small' onClick={() => removeQueryFilter(item.id)}>
                  <IconClose fontSize='small'/>
                </IconButton>
              </Stack>
            </Stack>
          ))}

          <Button
            className={classes.btnAddCondition}
            startIcon={<IconAddCircle color='primary'/>}
            variant='text'
            disableRipple
            onClick={() => addQueryFilter()}
          >
            Tambah Kondisi
          </Button>

          <Stack direction='row' marginTop='8px' justifyContent='flex-end'>
            <Button onClick={() => handleCancelButtonClick()} variant='text' className={classes.btnCancel}>BATAL</Button>

            <Button onClick={() => handleSaveButtonClick()} className={classes.btnSave} color='primary' variant='text'>SIMPAN</Button>
          </Stack>
        </Stack>
      </Popper>
    </>
  )
}

export default FilterEvidence