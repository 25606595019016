// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'
import { alpha } from '@mui/material/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    height: '100%',
    width: 420,
    borderRadius: 2,
    boxShadow: theme.shadows[4]
  },
  headerWrapper: {
    padding: '16px 16px 12px 16px',
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
    borderTop: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
    backgroundColor: colors.alabaster
  },
  titleHeader: {
    fontWeight: 600,
    fontSize: 16,
    marginLeft: 8,
  },
  formControlTypeData: {
    width: 124,
  },
  iconTypeData: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 2,
    height: 16,
    width: 16,
    marginRight: 8,
  },
  selectTypeData: {
    '& .MuiSelect-select': {
      padding: '8px 12px',
    }
  },
  selectTypeDataMenu: {
    '& .MuiList-root': {
      padding: 8,
    }
  },
  dividerFormControl: {
    margin: '0 8px',
    borderColor: alpha(theme.palette.common.black, 0.23),
    maxHeight: 37,
  },
  buttonFilter: {
    borderColor: alpha(theme.palette.common.black, 0.23),
    color: theme.palette.common.black,
    maxHeight: 37,
    backgroundColor: colors.whiteLilac
  },
  iconButtonFilter: {
    color: theme.palette.text.secondary,
    marginRight: 8,
  },
  iconViewData: {
    fontSize: 24,
  },
  textResultTotal: {
    color: colors.raven
  },
  circleTotalFilter: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    width: 20,
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 4,
    color: theme.palette.common.white,
    fontSize: 12,
    fontWeight: 600,
  },
  content: {
    overflowY: 'hidden',
    flex: 1
  },
  iconToggle: {
    width: 36,
    height: 36,
    transform: 'rotateY(180deg)',
  },
}))

export default useStyles
