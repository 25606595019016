// UTILITIES
import { http } from 'utilities/http'
import { stringify } from 'query-string'

export const getAllPlacesListNoFilter = async () => {
  try {
    const { data } = await http.get('/places')
    return data
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

export const getAllPlacesList = async (queryParams, bodyParams) => {
  try {
    const { data } = await http.post(`/places/search?${stringify(queryParams)}`, bodyParams)
    return data
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

export const AddNewPlace = async (params) => {
  try {
    return  await http.post('/places', params)
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

export const updatePlace = async (id, params) => {
  try {
    return  await http.put(`/places/${id}`, params)
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

export const deletePlace = async (id) => {
  try {
    return  await http.delete(`/places/${id}`)
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

export const getPlaceDetail = async (id) => {
  try {
    const { data } =  await http.get(`/places/${id}`)
    return data
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}