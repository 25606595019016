// MUIS
import Select from '@mui/material/Select'
import { styled } from '@mui/material/styles'

const CustomSelectOutlined = styled(({ className, ...props }) => (
  <Select
    className={className} 
    {...props}
  />
))(({ theme }) => ({
  '& .MuiSelect-select': {
    fontSize: 14,
    padding: '10px 26px 10px 12px',
    height: 44,
    boxSizing: 'border-box',
    // transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
}))

export default CustomSelectOutlined