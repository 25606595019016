import { useContext, useEffect, useState } from 'react'

// COMPONENTS
import MapChildrenZoom from 'components/PanelZoom/MapChildrenZoom'
import PanelZoom from 'components/PanelZoom/PanelZoom'
import PanelChangeMap from 'components/PanelChangeMap/PanelChangeMap'
import PanelFilter from '../PanelFilter/PanelFilter'
import PanelRight from '../PanelRight/PanelRight'

// CONTEXTS
import { PageAnalyticsContext } from 'contexts/PageAnalyticsContext'

// CONSTANTS
import mapList from 'constants/mapList'
import valuesPageAnalytics from 'constants/valuesPageAnalytics'

// MUIS
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Slide from '@mui/material/Slide'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconList from '@mui/icons-material/List'
import IconMapOutlined from '@mui/icons-material/MapOutlined'

// LEAFLET
import { MapContainer, TileLayer } from 'react-leaflet'

// RAMDA
import { isEmpty } from 'ramda'

// STYLES
import useStyles from './mapUseStyles'

// UTILITIES
import { updateMapBoundsAndZoom } from 'utilities/map'

const Map = (props) => {
  const { children } = props
  const classes = useStyles()
  const {
    setMap, map, setMapZoom, mapZoom, setMapBounds, isPanelFilterShown,
    pageRef, isPanelRightShown, setIsPanelRightShown
  } = useContext(PageAnalyticsContext)

  // STATES
  const [isPanelChangeMapOpen, setIsPanelChangeMapOpen] = useState(false)
  const [mapStyleTiles, setMapStyleTiles] = useState(mapList.mapOptionList[0])

  useEffect(() => {
    if(!isEmpty(map)) {
      updateMapBoundsAndZoom(map, setMapBounds, setMapZoom)

      map.on('zoomend dragend', () => {
        updateMapBoundsAndZoom(map, setMapBounds, setMapZoom)
      })
    }
  }, [map])

  return (
    <>
      {/* CONTROL */}
      <Stack
        className={classes.controlWrapper}
        sx={{
          right: isPanelRightShown ? 464 : 24
        }}
      >
        {/* BUTTON MAP STYLES */}
        <IconButton
          onClick={() => setIsPanelChangeMapOpen(current => !current)}
          className={classes.controlIconButton}
        >
          <IconMapOutlined color={isPanelChangeMapOpen ? 'primary' : 'text.secondary'}/>
        </IconButton>

        {/* PANEL MAP STYLES */}
        <Stack className={classes.panelMenuContainer}>
          {isPanelChangeMapOpen && 
          <Fade in={isPanelChangeMapOpen}>
            <Stack>
              <PanelChangeMap 
                handleCloseIconClick={() => setIsPanelChangeMapOpen(false)}
                selectedMapObject={mapStyleTiles}
                setSelectedMapObject={setMapStyleTiles}
                theme='light'
              />
            </Stack>
          </Fade>}
        </Stack>

        {/* PANEL MAP ZOOM */}
        <PanelZoom
          setZoom={setMapZoom} 
          theme='LIGHT'
          direction='horizontal'
        />

        {/* BUTTON STAR */}
        {!isPanelRightShown && <IconButton
          className={`${classes.controlIconButton} buttonListDashboard`}
          onClick={() => setIsPanelRightShown(true)}
        >
          <IconList />
          <Typography fontWeight={600} color='black' marginLeft='8px'>Dashboard</Typography>
        </IconButton>}
      </Stack>

      {/* PANEL RIGHT */}
      <Slide
        direction='left'
        in={isPanelRightShown} 
        container={pageRef.current}
      >
        <Stack className={classes.panelRightWrapper}>
          {!isPanelFilterShown && <PanelRight />}
          {isPanelFilterShown && <PanelFilter />}
        </Stack>
      </Slide>

      <MapContainer
        center={valuesPageAnalytics.defaultMapCenter}
        className={`${classes.mapContainer} no-zoom`}
        zoomControl={false}
        whenReady={(mapObject) => setMap(mapObject.target)}
        zoom={mapZoom}
      >
        {/* MAP TILES */}
        <TileLayer
          attribution={mapStyleTiles.attribution}
          url={mapStyleTiles.url}
          subdomains={mapStyleTiles.subdomains}
          minZoom={valuesPageAnalytics.minZoom}
          maxZoom={valuesPageAnalytics.maxZoom}
          key={mapStyleTiles.name}
        />

        {/* MAP CHILDREN ZOOM */}
        <MapChildrenZoom
          zoom={mapZoom}
          setZoom={setMapZoom}
        />
        {children}
      </MapContainer>
    </>
  )
}

export default Map