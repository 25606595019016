// APEX CHARTS
import Chart from 'react-apexcharts'

// CONSTANTS
import { getChartOptions } from './infoBiggestFiveConstants'

// MUIS
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

// STYLES
import useStyles from './infoBiggestFiveUseStyles'

const InfoBiggestFive = (props) => {
  const { title, caption, chartCategories, chartList } = props

  const classes = useStyles()

  const theme = useTheme()

  return (
    <Stack padding='16px'>
      {/* TITLE */}
      <Typography 
        variant='body2'
        marginBottom='8px'
        fontWeight={600}
      >
        {title}
      </Typography>

      {/* CAPTION */}
      <Typography
        variant='caption'
        marginBottom='8px'
        color='text.secondary'
      >
        {caption}
      </Typography>

      {/* BAR CHART */}
      <Chart
        options={getChartOptions(theme, chartCategories)}
        series={chartList}
        type='bar'
        width='100%'
        height={180}
        className={`${classes.chart} no-zoom`}
      />
    </Stack>
  )
}

export default InfoBiggestFive