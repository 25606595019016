// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconBodyBanishment = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M3.74155 7.41174C3.23608 7.41174 2.75131 7.61254 2.39389 7.96996C2.03647 8.32739 1.83567 8.81215 1.83567 9.31763C1.83567 9.8231 2.03647 10.3079 2.39389 10.6653C2.75131 11.0227 3.23608 11.2235 3.74155 11.2235C4.24703 11.2235 4.73179 11.0227 5.08922 10.6653C5.44664 10.3079 5.64744 9.8231 5.64744 9.31763C5.64744 8.81215 5.44664 8.32739 5.08922 7.96996C4.73179 7.61254 4.24703 7.41174 3.74155 7.41174ZM8.59394 8.26046C7.50632 8.26046 6.8271 8.82282 6.59459 9.46404C6.56875 9.51444 6.54739 9.56859 6.53172 9.62535L5.42657 12.0722H2.3047C1.88286 12.0722 1.49149 12.3607 1.42372 12.777C1.33732 13.3077 1.74461 13.7663 2.2592 13.7663H6.07097C6.45172 13.7663 6.76248 13.5047 6.88742 13.1451C6.98356 12.8698 7.34155 12.0706 7.34155 12.0706H14.5416L16.3085 13.4669C16.5351 13.6532 16.8198 13.7556 17.1133 13.7564L21.5298 13.7647C22.1507 13.7647 22.6464 13.2307 22.5828 12.5967C22.5278 12.0457 22.023 11.647 21.4694 11.647H17.4525C17.4525 11.647 14.9343 9.07545 14.8178 8.99667C14.5599 8.79761 14.2204 8.68316 13.7714 8.68316L12.0177 8.6815C10.5108 8.6815 9.37578 8.26046 8.59394 8.26046ZM16.5682 9.53022L17.8322 10.8H21.4967C21.8571 10.8 22.1979 10.9006 22.4927 11.0705C22.5689 10.9248 22.6159 10.759 22.6159 10.5833C22.6159 10.0017 22.1444 9.53022 21.5629 9.53022H16.5682ZM8.05378 12.9424C8.96479 13.8746 10.729 13.8135 10.729 14.3611C10.729 14.8227 9.4592 14.7049 9.4592 15.3711C9.4592 16.1038 11.995 16.3059 14.2181 16.3059C16.4412 16.3059 18.7768 16.0339 18.7768 15.2487C18.7768 14.9781 18.4375 14.7803 17.953 14.6308L17.1109 14.6283C16.6928 14.6274 16.2872 14.4952 15.9412 14.2635C15.8827 14.2563 15.8238 14.2496 15.7675 14.2428L15.9114 14.2436C15.8644 14.211 15.8144 14.1824 15.77 14.146L14.3058 12.9424H8.05378Z'/>
    </SvgIcon>
  )
}

export default IconBodyBanishment