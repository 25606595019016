// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 2,
    pointerEvents: 'auto',
    boxShadow: `0 0 10px ${alpha(theme.palette.common.black, 0.16)}`,
    '& .MuiButtonGroup-grouped:not(:last-of-type)': {
      borderColor: theme.palette.divider,
    },
  },
  rootLightTheme: {
    backgroundColor: 'white',
  },
  rootDarkTheme: {
    backgroundColor: colors.bunker,
  },
  buttonGroupItem: {
    width: 40,
    height: 40,
  },
  buttonGroupItemLightTheme: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  buttonGroupItemDarkTheme: {
    '&:hover': {
      backgroundColor: colors.tuna,
    },
  },
  iconLightTheme: {
    color: colors.boulder,
  },
  iconDarkLight: {
    color: 'white',
  },
}))

export default useStyles