import { http } from '../utilities/http'
import { stringify } from 'query-string'

export async function getUsers(query) {
  try {
    const {data} = await http.get(`/users?${stringify(query)}`)

    const newRows = data.rows.map((item) => {
      item.id = item.userId
      return item
    })

    return {
      ...data,
      rows: newRows
    }
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function searchUsers(queryParams,bodyParams) {
  try {
    const {data} = await http.post(`/users/search?${stringify(queryParams)}`, bodyParams)

    const newRows = data.rows.map((item) => {
      item.id = item.userId
      return item
    })

    return {
      ...data,
      rows: newRows
    }
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function createUser(params) {
  try {
    return await http.post('/users', params)
  } catch (e) {
    return {'status': 'error', rows: [], message: e?.response?.data?.message}
  }
}

export async function getUser(id) {
  try {
    const {data} = await http.get(`/users/${id}`)

    return data
  } catch (e) {
    return {'status': 'error', error: e?.response?.data?.error}
  }
}

export async function getLoggedUserInfo() {
  try {
    const { data } = await http.get('/users/loggedin-user-detail')

    return data
  } catch (e) {
    return {'status': 'error', error: e?.response?.data?.error}
  }
}

export async function updateUser(id, params) {
  try {
    return await http.put(`/users/${id}`, params)
  } catch (e) {
    return {'status': 'error', error: e?.response?.data?.error, message: e?.response?.data?.message}
  }
}

export async function deleteUser(id) {
  try {
    return await http.delete(`/users/${id}`)
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function getRoles(query) {
  try {
    const {data} = await http.get(`/roles?${stringify(query)}`)
    return data
  } catch (e) {
    return {'status': 'error', data: []}
  }
}

export async function getRole(id) {
  try {
    const { data } = await http.get(`/roles/${id}`)
    return data
  } catch (e) {
    return {'status': 'error', data: []}
  }
}

export async function deleteRole(id) {
  try {
    return await http.delete(`/roles/${id}`)
  } catch (e) {
    return {'status': 'error', data: []}
  }
}

export async function getHierarchyRoles() {
  try {
    const {data} = await http.get('/roles/hierarchy')
    return data
  } catch (e) {
    return {'status': 'error', data: []}
  }
}

export async function createRole(params) {
  try {
    return await http.post('/roles', params)
  } catch (e) {
    return {'status': 'error', error: e?.response?.data?.error}
  }
}

export async function updateRole(id, params) {
  try {
    return await http.put(`/roles/${id}`, params)
  } catch (e) {
    return {'status': 'error', error: e?.response?.data?.error}
  }
}

export async function getProfiles(query) {
  try {
    const {data} = await http.get(`/profiles?${stringify(query)}`)

    return data
  } catch (e) {
    return {'status': 'error', error: e?.response?.data?.error}
  }
}

export async function getProfile(id) {
  try {
    const {data} = await http.get(`/profiles/${id}`)

    return data
  } catch (e) {
    return {'status': 'error', error: e?.response?.data?.error}
  }
}

export async function searchProfiles(queryParams, bodyParams) {
  try {
    const {data} = await http.post(`/profiles/search?${stringify(queryParams)}`, bodyParams)

    return data
  } catch (e) {
    return {'status': 'error', error: e?.response?.data?.error}
  }
}

export async function deleteProfile(id) {
  try {
    const {data} = await http.delete(`/profiles/${id}`)

    return data
  } catch (e) {
    return {'status': 'error', error: e?.response?.data?.error}
  }
}

export async function updateProfile(id, params) {
  try {
    const {data} = await http.put(`/profiles/${id}`, params)

    return data
  } catch (e) {
    return {'status': 'error', error: e?.response?.data?.error}
  }
}

export async function createProfile(params) {
  try {
    return await http.post('/profiles', params)
  } catch (e) {
    return {'status': 'error', message: e?.response?.data.error}
  }
}

export async function getProfileAll() {
  try {
    const {data} = await http.get('/profiles/all')

    return data

  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function getMenuAll() {
  try {
    const {data} = await http.get('/menus')

    return data

  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function getAllPermissions() {
  try {
    const {data} = await http.get('/menu-permissions')

    const newRows = data.map((item) => {
      const temp = item.permissionResponses.map((permission) => {
        permission.enable = false
        return permission
      })
      item.permissionResponses = temp

      return item
    })

    return newRows
  } catch (e) {
    return {'status': 'error', data: []}
  }
}

export async function generateResetTokenPassword(query) {
  try {
    const { data } = await http.post(`/users/reset-password?${stringify(query)}`, {})
    return data
  } catch (err) {
    return {'status': 'error'}
  }
}

export async function updateResetPassword(params) {
  try {
    const { data } = await http.put('/users/change-password', params)
    return data
  } catch (err) {
    return {'status': 'error', err}
  }
}

export async function updatePasswordDashboard(params) {
  try {
    const update = await http.put('/users/update-new-password', params)
    return {status: 'success'}
  } catch (err) {
    return {'status': 'error', err}
  }
}