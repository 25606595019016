// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 24,
    position: 'relative',
  },
  groupingRow: {
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  columnActions: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  boxColorColumn: {
    height: 24,
    width: 24,
    borderRadius: 2,
    marginRight: 8,
  },
  boxIconColumn: {
    color: theme.palette.text.secondary,
    marginRight: 20,
  },
  textTagLocationName: {
    fontSize: 12
  }
}))

export default useStyles
