/* eslint-disable no-undef */
L.MarkerEvidence = L.Marker.extend({
  options: {
    htmlAnimation: '', // required
    isAnimationActive: false
  }, 
  initialize: function (latlngs, options) {
    L.Marker.prototype.initialize.call(this, latlngs, options)

    this._isAnimationActive = this.options.isAnimationActive
  },

  onAdd: function (map) {
    L.Marker.prototype.onAdd.call(this, map)

    if (this._isAnimationActive) {
      this._icon.querySelector('#parent-element').innerHTML = this.options.htmlAnimation
    } else {
      this._icon.querySelector('#parent-element').innerHTML = ''
    }
  },
  /**
   * @param {Boolean} value 
   */
  _setIsAnimation: function (value) {
    if (!this._icon) return

    if (value) {
      this._isAnimationActive = value
      this._icon.querySelector('#parent-element').innerHTML = this.options.htmlAnimation
    } else {
      this._isAnimationActive = value
      this._icon.querySelector('#parent-element').innerHTML = ''
    }
  }
})

L.markerEvidence = function (latlngs, options) {
  return new L.MarkerEvidence(latlngs, options)
}