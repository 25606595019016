// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconPoliceCar = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M7.2001 2.3999C6.7585 2.3999 6.4001 2.7583 6.4001 3.1999V4.2874C5.61378 4.63987 4.9791 5.29531 4.68291 6.14209L3.03604 10.8468C2.15684 11.4252 1.6001 12.4241 1.6001 13.5233V19.1999C1.6001 20.0839 2.3161 20.7999 3.2001 20.7999C4.0841 20.7999 4.8001 20.0839 4.8001 19.1999V18.1905C4.8001 18.1905 9.0249 18.3999 12.0001 18.3999C14.9753 18.3999 19.2001 18.1905 19.2001 18.1905V19.1999C19.2001 20.0839 19.9161 20.7999 20.8001 20.7999C21.6841 20.7999 22.4001 20.0839 22.4001 19.1999V13.5233C22.4001 12.4241 21.8418 11.4244 20.9642 10.8468L19.3188 6.14365C19.0221 5.29634 18.3865 4.63993 17.6001 4.2874V3.1999C17.6001 2.7583 17.2417 2.3999 16.8001 2.3999H13.6001V3.9999H10.4001V2.3999H7.2001ZM7.70322 5.5999H16.297C16.977 5.5999 17.5831 6.03018 17.8079 6.67178L18.7235 9.29053C18.8675 9.70253 18.525 10.1179 18.0938 10.0483C16.525 9.79474 14.5081 9.5999 12.0001 9.5999C9.4921 9.5999 7.47515 9.79474 5.90635 10.0483C5.47515 10.1179 5.13266 9.70253 5.27666 9.29053L6.19229 6.67178C6.41708 6.03018 7.02322 5.5999 7.70322 5.5999ZM5.2001 12.7999C5.8625 12.7999 6.4001 13.3375 6.4001 13.9999C6.4001 14.6623 5.8625 15.1999 5.2001 15.1999C4.5377 15.1999 4.0001 14.6623 4.0001 13.9999C4.0001 13.3375 4.5377 12.7999 5.2001 12.7999ZM9.6001 12.7999H14.4001C14.8417 12.7999 15.2001 13.1583 15.2001 13.5999C15.2001 14.0415 14.8417 14.3999 14.4001 14.3999H9.6001C9.1585 14.3999 8.8001 14.0415 8.8001 13.5999C8.8001 13.1583 9.1585 12.7999 9.6001 12.7999ZM18.8001 12.7999C19.4625 12.7999 20.0001 13.3375 20.0001 13.9999C20.0001 14.6623 19.4625 15.1999 18.8001 15.1999C18.1377 15.1999 17.6001 14.6623 17.6001 13.9999C17.6001 13.3375 18.1377 12.7999 18.8001 12.7999Z'/>
    </SvgIcon>
  )
}

export default IconPoliceCar