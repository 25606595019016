import React from 'react'
import { format } from 'date-fns'
import moment from 'moment'

// STYLES
import useStyles from './videoPlaybackUseStyles'

// MUIS
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

// MUI ICONS
import EventIcon from '@mui/icons-material/Event'
import { InputAdornment } from '@mui/material'

const DatePickerSort = (props) => {
  const { onClick, dateRangeValue } = props
  const classes = useStyles()

  /* FORMAT DATE RANGE */
  const formatDateRangeAndTimeText = (inputDateRange) => {
    const formatedDateRange = `${format(
      new Date(inputDateRange[0]),
      'MM/dd/yyyy'
    )} - ${format(new Date(inputDateRange[1]), 'MM/dd/yyyy')}`
    return formatedDateRange
  }

  return (
    <Box
      className={classes.datePickerSort}
      onClick={onClick}
    >
      <TextField
        variant='standard'
        className={classes.datePickerTextField}
        value={formatDateRangeAndTimeText(dateRangeValue)}
        onChange={() => {}}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <EventIcon className={classes.datePickerIcon}/>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  )
}

export default DatePickerSort