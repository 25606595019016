// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconPatrol = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M7.55859 18.9888H17.6331V20.5001H7.55859V18.9888Z' />
      <path d='M15.6522 11.2201C14.8723 10.4402 13.7961 9.95667 12.595 9.95667C10.2086 9.95667 8.27441 11.8908 8.27441 14.2929V18.4887H16.9156V14.2929C16.9156 13.0918 16.4321 12.0156 15.6522 11.2201ZM14.6072 12.3587C14.3888 12.7799 13.7025 12.8735 13.0786 12.5459C12.4546 12.2184 12.1427 11.61 12.3611 11.1889C12.5794 10.7678 13.2657 10.6742 13.8897 11.0017C14.5136 11.3293 14.8411 11.9376 14.6072 12.3587Z' />
      <path d='M13.8427 8.39687H11.5654V3.26516C11.5654 3.10919 11.6902 3 11.8306 3H13.5776C13.7335 3 13.8427 3.12478 13.8427 3.26516V8.39687Z' />
      <path d='M16.9474 9.81619L15.3564 8.89592L17.4466 5.27721C17.509 5.16802 17.6337 5.13683 17.7429 5.19922L18.9596 5.90112C19.0687 5.96351 19.0999 6.0883 19.0375 6.19748L16.9474 9.81619Z' />
      <path d='M8.57106 9.95658L10.0529 8.86473L7.526 5.41759C7.44801 5.32401 7.32323 5.29281 7.21404 5.3708L6.091 6.19749C5.99741 6.27548 5.96621 6.40026 6.0442 6.50945L8.57106 9.95658Z' />
    </SvgIcon>
  )
}

export default IconPatrol