import { useState, useContext, useEffect, useRef } from 'react'
import ReactDOMServer from 'react-dom/server'

// COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'
import CustomInput from 'components/Customs/CustomInput'
import CustomMenuItem from 'components/Customs/CustomMenuItem'
import PanelChangeMap from 'components/PanelChangeMap/PanelChangeMap'
import PanelMenuSearch from '../PanelMenuSearch/PanelMenuSearch'
import PanelMenuPlacesList from '../PanelMenuPlacesList/PanelMenuPlacesList'
import PanelSearchDetail from '../PanelMenuSearchDetail/PanelMenuSearchDetail'
import PanelZoom from 'components/PanelZoom/PanelZoom'
import PopupMarkerPoint from '../PopupMarkerPoint/PopupMarkerPoint'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// LEAFLET
import L from 'leaflet'

// MUIS
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Fade from '@mui/material/Fade'
import InputAdornment from '@mui/material/InputAdornment'
import LinearProgress from '@mui/material/LinearProgress'
import Popper from '@mui/material/Popper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconMapOutlined from '@mui/icons-material/MapOutlined'
import IconRoom from '@mui/icons-material/Room'
import IconSearch from '@mui/icons-material/Search'
import IconStar from '@mui/icons-material/Star'

// STYLES
import useStyles from './panelMenuUseStyles'
import useStylesPopupMarkerPoint from '../PopupMarkerPoint/popupMarkerPointUseStyles'

// SERVICES
import { getAddressByLatLong, getGeoByAddress } from 'services/TrackingServices'
import { CircularProgress, IconButton } from '@mui/material'

// UTILS
import { readReferenceLocationFromLocalStorage, setReferenceLocationToLocalStorage } from 'utilities/localStorage'

let tempTimeout
const debounce = (callback, time = 500) => {
  clearTimeout(tempTimeout)
  tempTimeout = setTimeout(callback, time)
}

const PanelMenu = () => {  
  const {
    selectedMapObject, setSelectedMapObject, isPanelSearchDetail,
    setZoom, map, isSuccessCreateDispatch, referenceLocation, setReferenceLocation
  } = useContext(PageTrackingContext)


  const classes = useStyles()
  const classesPopupMarkerPoint = useStylesPopupMarkerPoint()

  const markerFeatureRef = useRef(L.featureGroup())

  // 0 = search, 1 = places list, 2 = map styles
  const [ menuAnchor, setMenuAnchor ] = useState([null, null, null])
  const [ suggestAnchor, setSuggestAnchor ] = useState(null)
  const [ locationSearch, setLocationSearch ] = useState('')
  const [ tempLocationSearch, setTempLocationSearch ] = useState('')
  const [ suggestList, setSuggestList ] = useState([])
  const [ isLoadingFetchLocation, setIsLoadingFetchLocation ] = useState(false)
  const [ isDispatchPointActive, setIsDispatchPointActive ] = useState(readReferenceLocationFromLocalStorage().length ? true : false)
  const [ isFetchAddressDispatch, setIsFetchDispatchAddress ] = useState(false)
  
  const handleMenuAnchor = (inputIndex, inputValue) => {
    let tempValue = menuAnchor.map((item, index) => {
      if(index === inputIndex) return inputValue
      else return false
    })
    
    setMenuAnchor(tempValue)
  }

  const generateDispatchPointByLatLng = async () => {
    if (!referenceLocation?.length && !isDispatchPointActive) return
    setIsFetchDispatchAddress(true)
    setIsDispatchPointActive(false)

    const response = await getAddressByLatLong({
      lat: referenceLocation[0],
      lng: referenceLocation[1]
    })

    createMarkerPoint({
      lat: referenceLocation[0],
      lng: referenceLocation[1],
      address: response.value,
    }, referenceLocation[2])

    setIsFetchDispatchAddress(false)
  }

  const fetchSuggestAddress = async () => {
    setIsLoadingFetchLocation(true)

    if (locationSearch) {
      const response = await getGeoByAddress({
        provider_type: 'google',
        q: locationSearch
      })
      setSuggestList(response.locations)
    } else {
      setSuggestList([])
    }
    setIsLoadingFetchLocation(false)
  }

  const createMarkerPoint = (inputItem, typeMarker) => {
    markerFeatureRef.current.clearLayers()
    const marker = L.marker({ lat: inputItem.lat, lng: inputItem.lng }, {
      icon: L.divIcon({
        className: 'markerPulser',
        iconSize: [32, 32],
        html: ReactDOMServer.renderToString(
          <Stack className={classes.markerPulse}>
            {typeMarker === 'location' ? <IconRoom /> : <IconStar />}
          </Stack>
        ),
      })
    })

    marker.bindPopup(ReactDOMServer.renderToString(<PopupMarkerPoint
      item={inputItem}
      classes={classesPopupMarkerPoint}
    />), {
      offset: L.point(0, 192),
      maxWidth: 308,
      minWidth: 308,
      maxHeight: 180,
      className: 'popup-wrapper-geofences',
      closeButton: false,
    })

    markerFeatureRef.current.addLayer(marker).addTo(map)
  }

  const handleSuggestItemClick = (inputItem) => {
    setReferenceLocation([inputItem.lat, inputItem.lng, 'location'])
    createMarkerPoint(inputItem, 'location')
    setReferenceLocationToLocalStorage([inputItem.lat, inputItem.lng, 'location'])
    map.flyTo({ lat: inputItem.lat, lng: inputItem.lng }, 17)
    setTempLocationSearch(inputItem.address)
    setSuggestAnchor(null)
  }

  const handleDispatchPointClick = () => {
    if (!referenceLocation.length) {
      setIsDispatchPointActive(true)
    } else {
      if (referenceLocation[2] === 'location') {
        setIsDispatchPointActive(true)
      } else {
        setIsDispatchPointActive(false)
      }

      setReferenceLocationToLocalStorage([])
      markerFeatureRef.current.clearLayers()
      setReferenceLocation([])
    }
  }

  useEffect(() => {
    fetchSuggestAddress()
  }, [locationSearch])

  useEffect(() => {
    if (map && isDispatchPointActive && !referenceLocation.length) {
      map.on('click', event => {
        setReferenceLocationToLocalStorage([event.latlng.lat, event.latlng.lng, 'point'])
        setReferenceLocation([event.latlng.lat, event.latlng.lng, 'point'])
      })
    } else if (map) {
      map.off('click')
    }
  }, [map, isDispatchPointActive])

  useEffect(() => {
    if (referenceLocation?.length === 3 && map && isDispatchPointActive) {
      generateDispatchPointByLatLng()
    }
  }, [referenceLocation, map])

  useEffect(() => {
    if (isSuccessCreateDispatch) {
      setReferenceLocationToLocalStorage([])
      markerFeatureRef.current.clearLayers()
      setReferenceLocation([])
      setIsDispatchPointActive(false)
    }
  }, [isSuccessCreateDispatch])

  return (
    <Box position='relative' height='100%'>
      {/* SEARCH LOCATION */}
      <ClickAwayListener onClickAway={() => setSuggestAnchor(null)}>
        <Stack className={classes.searchLocation}>
          <CustomInput
            placeholder='Pencarian Lokasi'
            disableUnderline
            type='text'
            value={tempLocationSearch.length < 38 ? tempLocationSearch : `${tempLocationSearch.substring(0, 36)}...`}
            className={`${classes.inputSearchLocation} no-zoom`}
            startAdornment={<InputAdornment position='start' className={classes.formControlLocationAdorment}>
              <IconSearch />
            </InputAdornment>}
            onClick={event => setSuggestAnchor(suggestAnchor ? null : event.currentTarget)}
            onChange={event => {
              setTempLocationSearch(event.target.value)
              debounce(() => {
                setSuggestList([])
                setLocationSearch(event.target.value)
              }, 500)
            }}
          />

          <Popper
            open={Boolean(suggestAnchor) && Boolean(locationSearch)}
            anchorEl={suggestAnchor}
            className={`${classes.locationSuggestionsContainer} no-zoom`}
          >
            {suggestList?.length ? suggestList?.map((item, index) => (
              <CustomMenuItem
                className={`${classes.suggestItemMenu} zoom`}
                key={index}
                onClick={() => handleSuggestItemClick(item)}
              >
                <Typography variant='subtitle2' noWrap>
                  {item.address}
                </Typography>
              </CustomMenuItem>
            ))
              : <CustomMenuItem className={`${classes.suggestItemMenu} zoom`}>
                {!isLoadingFetchLocation ? <Typography variant='subtitle2' noWrap padding='0 4px'>
                  Lokasi tidak ditemukan
                </Typography>
                  : <Stack width='100%' className='zoom'><LinearProgress color='primary' /></Stack>}
              </CustomMenuItem>}
          </Popper>
        </Stack>
      </ClickAwayListener>

      {/* STAR DISPATCH */}
      <Stack className={classes.dispatchPointWrapper}>
        {/* BUTTON STAR */}
        <IconButton
          className={`${classes.controlIconButton} ${referenceLocation[2] === 'point' ? 'active' : ''}`}
          onClick={() => !isFetchAddressDispatch && handleDispatchPointClick()}
        >
          {isFetchAddressDispatch ? <CircularProgress size={20} /> : <IconStar />}
        </IconButton>
      </Stack>

      {/* CONTROL BUTTON GROUP PANEL */}
      <ButtonGroup 
        className={classes.panelContainer}
        variant='contained'
        sx={{
          display: !Boolean(isPanelSearchDetail) ? 'flex' : 'none'
        }}
      >
        {/* SEARCH BUTTON */}
        {/* <CustomTooltipBlack
          title='Pencarian'
          placement='bottom'
        >
          <Box>
            <Button
              className={`${classes.buttonGroupItem} zoom`}
              variant='text'
              onClick={(event) => handleMenuAnchor(0, !Boolean(menuAnchor[0]) ? event.currentTarget : null)}
            >
              <IconSearch className={Boolean(menuAnchor[0]) ? classes.iconActive : classes.icon}/>
            </Button>
          </Box>
        </CustomTooltipBlack> */}

        {/* PLACES BUTTON */}
        {/* <CustomTooltipBlack
          title='Tempat'
          placement='bottom'
        >
          <Box>
            <Button
              className={`${classes.buttonGroupItem} zoom`}
              variant='text'
              onClick={(event) => handleMenuAnchor(1, !Boolean(menuAnchor[1]) ? event.currentTarget : null)}
            >
              <IconEditLocationOutlined className={Boolean(menuAnchor[1]) ? classes.iconActive : classes.icon}/>
            </Button>
          </Box>
        </CustomTooltipBlack> */}

        {/* MAP BUTTON */}
        <CustomTooltipBlack
          title='Map'
          placement='bottom'
        >
          <Box>
            <Button 
              className={`${classes.buttonGroupItem} zoom`}
              variant='text'
              onClick={(event) => handleMenuAnchor(2, !Boolean(menuAnchor[2]) ? event.currentTarget : null)}
            >
              <IconMapOutlined className={Boolean(menuAnchor[2]) ? classes.iconActive : classes.icon}/>
            </Button>
          </Box>
        </CustomTooltipBlack>
      </ButtonGroup>

      {/* ZOOM PANEL */}
      <Box className={`${classes.panelZoomContainer}`}>
        <PanelZoom
          setZoom={setZoom} 
          theme='LIGHT'
          direction='horizontal'
          className=''
          sx={{
            display: !Boolean(isPanelSearchDetail) ? 'flex' : 'none'
          }}
        />
      </Box>

      <Box className={classes.panelMenuContainer}>
        {/* PANEL MENU SEARCH */}
        {Boolean(menuAnchor[0]) && 
        <Fade in={(!Boolean(isPanelSearchDetail) && Boolean(menuAnchor[0]))}>
          <Stack>
            <PanelMenuSearch handleMenuAnchor={handleMenuAnchor} />
          </Stack>
        </Fade>}

        {/* PANEL MENU PLACES LIST */}
        {Boolean(menuAnchor[1]) && 
        <Fade in={(!Boolean(isPanelSearchDetail) && Boolean(menuAnchor[1]))}>
          <Stack>
            <PanelMenuPlacesList handleMenuAnchor={handleMenuAnchor} />
          </Stack>
        </Fade>}

        {/* PANEL CHANGE MAP */}
        {Boolean(menuAnchor[2]) && 
        <Fade in={(!Boolean(isPanelSearchDetail) && Boolean(menuAnchor[2]))}>
          <Stack>
            <PanelChangeMap 
              handleCloseIconClick={() => handleMenuAnchor(2, null)}
              selectedMapObject={selectedMapObject}
              setSelectedMapObject={setSelectedMapObject}
            />
          </Stack>
        </Fade>}
      </Box>

      {/* PANEL SEARCH DETAIL */}
      <Box className={classes.panelSearchDetailWrap}>
        <PanelSearchDetail />
      </Box>
    </Box>
  )
}

export default PanelMenu