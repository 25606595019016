// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 24,
    position: 'relative',
  },
  columnStatus: {
    font: 'inherit',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    textTransform: 'capitalize',
    '&::before': {
      content: '""',
      width: 10,
      height: 10,
      backgroundColor: colors.boulder,
      borderRadius: '50%',
      marginRight: 10,
    },
  },
  columnStatusRed: {
    color: colors.carnation,
    '&::before': {
      backgroundColor: colors.carnation,
    },
  },
  columnStatusGreen: {
    color: colors.silverTree,
    '&::before': {
      backgroundColor: colors.silverTree,
    },
  },
  columnVehicle: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  columnVehicleIcon: {
    color: colors.boulder,
    marginRight: 10,
  },
  columnVehicleText: {
    font: 'inherit',
    lineHeight: 1.4,
  },
  columnWidth: {
    width: '100%',
  },
  columnActions: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  groupingRow: {
    fontWeight: 600,
    textTransform: 'capitalize',
  },
}))

export default useStyles