// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconEscort = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M6.23921 16.9592L10.4258 19.773C10.6841 19.9633 11.0511 19.9089 11.2414 19.6506L16.8416 12.4056C16.8824 12.3513 16.9096 12.2833 16.9368 12.2153C16.9504 12.2289 16.9504 12.2697 16.9504 12.2969L16.9776 13.3707C16.9776 13.5202 16.9368 13.6562 16.8416 13.7785L11.6492 20.6429C11.3637 21.0371 10.8064 21.1187 10.4122 20.8196L6.21202 17.9243C6.13046 17.8564 6.07609 17.7612 6.0625 17.6525L6.08969 16.633C6.08969 16.633 6.08969 16.9049 6.23921 16.9592Z' />
      <path d='M16.6786 11.6848L12.356 9.21093C12.1114 9.07501 11.7987 9.14297 11.622 9.36046L6.13049 16.1161C6.02175 16.252 5.98097 16.4287 6.00816 16.5919C6.02175 16.7278 6.10331 16.8637 6.23923 16.9589L10.4258 19.7726C10.6841 19.9629 11.0511 19.9085 11.2414 19.6503L16.8689 12.5548C16.9096 12.5004 16.9368 12.4324 16.964 12.3645C17.032 12.1062 16.9368 11.8208 16.6786 11.6848ZM10.1676 17.9376L9.82775 18.4405C9.80057 18.4813 9.75979 18.4813 9.71901 18.4677L7.46259 16.9317C7.42181 16.9045 7.42181 16.8637 7.43541 16.8229L7.77523 16.32C7.80241 16.2792 7.84319 16.2792 7.88397 16.2928L10.1404 17.8288C10.1812 17.856 10.1948 17.8968 10.1676 17.9376ZM10.7928 13.4655C10.5074 13.4655 10.2763 13.2344 10.2763 12.949C10.2763 12.6635 10.5074 12.4324 10.7928 12.4324C11.0783 12.4324 11.3094 12.6635 11.3094 12.949C11.323 13.2344 11.0783 13.4655 10.7928 13.4655ZM13.2124 14.6073C13.0085 14.8112 12.6823 14.8112 12.4784 14.6073C12.2745 14.4034 12.2745 14.0772 12.4784 13.8733C12.6823 13.6694 13.0085 13.6694 13.2124 13.8733C13.4163 14.0772 13.4163 14.4034 13.2124 14.6073ZM15.2241 12.4868L14.5717 13.316C14.5037 13.3975 14.3814 13.4247 14.2862 13.3704L11.323 11.6576C11.2006 11.5897 11.1734 11.4402 11.255 11.3314L11.9075 10.5023C11.9754 10.4207 12.0978 10.3935 12.1929 10.4479L15.1562 12.1606C15.2785 12.2285 15.3057 12.3917 15.2241 12.4868Z' />
      <path d='M14.6126 9.79543L14.1776 10.3527L12.1387 9.2789L12.6824 8.58567C12.7911 8.43614 12.995 8.39537 13.1717 8.49052L13.4844 8.68082L14.0281 8.99345L14.4902 9.26531C14.6941 9.36046 14.7349 9.61872 14.6126 9.79543Z' />
      <path d='M18.473 3.31177C18.473 3.37974 18.4594 3.4477 18.405 3.51566L14.0281 8.97999L13.4844 8.66736L17.9156 3.12147C18.0244 2.98554 18.2147 2.95836 18.3642 3.0671C18.4322 3.12147 18.473 3.21662 18.473 3.31177Z' />
    </SvgIcon>
  )
}

export default IconEscort