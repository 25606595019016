// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconEviction = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M4.18585 1.41174C4.18585 1.41174 6.82578 3.00834 9.65455 5.68355L1.69501 13.6431C1.6067 13.7279 1.5362 13.8294 1.48763 13.9418C1.43906 14.0542 1.4134 14.1751 1.41216 14.2975C1.41091 14.4199 1.4341 14.5414 1.48038 14.6547C1.52665 14.7681 1.59507 14.871 1.68164 14.9576C1.7682 15.0442 1.87117 15.1126 1.98451 15.1589C2.09785 15.2051 2.21928 15.2283 2.3417 15.2271C2.46412 15.2258 2.58505 15.2002 2.69743 15.1516C2.8098 15.103 2.91136 15.0325 2.99614 14.9442L10.9557 6.98468C13.6309 9.81345 15.2275 12.4534 15.2275 12.4534C15.2275 12.4534 15.223 8.46616 12.6234 5.31693L13.1176 4.82272C13.2506 4.69347 13.3414 4.52707 13.3782 4.34533C13.415 4.16358 13.396 3.97497 13.3237 3.80421C13.2514 3.63344 13.1293 3.48849 12.9732 3.38836C12.8171 3.28822 12.6345 3.23756 12.4491 3.24303C12.2101 3.25015 11.9832 3.35006 11.8165 3.52159L11.3223 4.0158C8.17307 1.41624 4.18585 1.41174 4.18585 1.41174ZM18.908 10.6131C18.6725 10.6131 18.4369 10.7028 18.2575 10.8827L15.4971 13.6431C15.3241 13.8152 15.2275 14.0498 15.2275 14.2937V22.5749H16.1476C16.6555 22.5749 17.0678 22.1627 17.0678 21.6547V14.8328L18.908 12.9925V21.6547C18.908 22.1627 19.3203 22.5749 19.8282 22.5749H22.5886V14.2937C22.5886 14.0498 22.492 13.8152 22.319 13.6431L19.5586 10.8827C19.3792 10.7028 19.1436 10.6131 18.908 10.6131ZM7.84663 15.194C7.33871 15.194 6.92649 15.6062 6.92649 16.1142V20.0193C6.92649 20.2632 7.02308 20.4978 7.19606 20.6699L9.10283 22.5749H13.3872V21.6547L8.78653 17.0541H10.6268L13.3872 19.8145V16.4502L12.4024 15.4636C12.2294 15.2906 11.9957 15.194 11.7518 15.194H7.84663Z'/>
    </SvgIcon>
  )
}

export default IconEviction