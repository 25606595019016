// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  subGroupText: {
    fontSize: 14,
    fontWeight: 400,
    color: '#3F4254',
    lineHeight: '24px',
    letterSpacing: '0.15 px',
  },
  iconClose: {
    marginRight: 16,
    cursor: 'pointer',
  },
  titleText: {
    fontWeight: 600,
    fontSize: 24,
  },
  sectionText: {
    marginBottom: 8,
    fontWeight: 600,
  },
  subGroupTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 5,
  },
  iconAndFormControlContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    height: 65,
    width: '100%',
    flex: '0 0 65px',
  },
  iconFormControl: {
    marginRight: 12,
    marginBottom: 2,
    color: colors.raven,
  },
  iconCloseSubGroup: {
    height: '11.67px',
    width: '11.67px',
  },
  formControl: {
    width: '100%',
  },
  cancelButton: {
    color: colors.raven,
    '&:hover': {
      backgroundColor: alpha(colors.raven, 0.1),
    },
  },
  flexColumnSubGroup: {
    flexDirection: 'column',
  },
}))

export default useStyles
