import { useState, useRef } from 'react'

// ASSETS
import PictureAudioWave from 'assets/images/pictures/audio-wave.svg'
import PictureBrokenAudio from 'assets/images/pictures/broken-audio.svg'

// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { alpha } from '@mui/material/styles'

// MUI ICONS
import IconPauseCircleFilledOutlined from '@mui/icons-material/PauseCircleFilledOutlined'
import IconPlayCircleFilledOutlined from '@mui/icons-material/PlayCircleFilledOutlined'

// STYLES
import useStyles from './carouselMediasUseStyles'

// UTILITIES
import { convertSecondToHMS } from 'utilities'

const CarouselMediaAudio = (props) => {
  const { 
    carouselItem, 
    variantObject,
    variant,
  } = props

  const classes = useStyles()

  const audioRef = useRef()

  const [ source, setSource ] = useState(carouselItem.mediaUrl)
  const [ isPlaying, setIsPlaying ] = useState(false)
  const [ duration, setDuration ] = useState(0)

  const handleButtonClick = (event, command) => {
    event.stopPropagation()
    
    if (command === 'play') {
      setIsPlaying(true)
      audioRef.current.play()
    }
    else if (command === 'pause') {
      setIsPlaying(false)
      audioRef.current.pause()
    }
  }

  const handleLoadMetaData = () => {
    if (audioRef?.current) setDuration(audioRef.current.duration)
  }

  return (
    <Stack
      position='relative'
      justifyContent='center'
      alignItems='center'
      height='100%'
      width='100%'
    >
      {/* BACKGROUND IMAGE */}
      {source &&
      <Box
        component='img'
        src={PictureAudioWave}
        alt=''
        className={classes.carouselItemAudioWave}
      />}

      {/* ACTION BUTTON */}
      {source && (isPlaying ? (
        <IconPauseCircleFilledOutlined
          onClick={event => handleButtonClick(event, 'pause')}
          className={`${classes.carouselItemButton} ${variantObject[variant].playButtonClassName}`}
        />
      ) : (
        <IconPlayCircleFilledOutlined
          onClick={event => handleButtonClick(event, 'play')}
          className={`${classes.carouselItemButton} ${variantObject[variant].playButtonClassName}`}
        />
      ))}

      {/* INFORMATION */}
      <Stack
        position='absolute'
        bottom={0}
        left={0}
        right={0}
        backgroundColor={alpha(colors.ebonyClay, 0.5)}
        padding={variant === 'small' ? '8px' : '16px'}
        zIndex={4}
      >
        {/* FILE NAME */}
        <Typography
          variant='body2'
          color='white'
          noWrap
        >
          Audio {carouselItem.fileName}
        </Typography>

        {/* DURATION */}
        <Typography
          variant='caption'
          color='white'
          noWrap
        >
          {convertSecondToHMS(duration)}
        </Typography>
      </Stack>

      {/* AUDIO */}
      <Box
        ref={audioRef}
        component={source ? 'audio' : 'img'}
        src={source ? source : PictureBrokenAudio}
        autoPlay={false}
        controls={false}
        alt=''
        onLoadedMetadata={handleLoadMetaData}
        onEnded={() => setIsPlaying(false)}
        onError={() => setSource(PictureBrokenAudio)}
      />
    </Stack>
  )
}

export default CarouselMediaAudio