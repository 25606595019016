// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    padding: 16,
  },
  dialogActions: {
    padding: 8,
  },
  cancelButton: {
    color: colors.raven,
    '&:hover': {
      backgroundColor: alpha(colors.raven, 0.1),
    },
  },
}))

export default useStyles