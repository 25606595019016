// UTILITIES
import { http } from 'utilities/http'
import { stringify } from 'query-string'

export const getVehicleList = async (bodyParams) => {
  try {
    const { data } = await http.post('/vehicles/search?ignorePagination=true', bodyParams)

    const newRows = data.map((item) => {
      return {
        ...item,
        id: item.vehicleNo,
        status: item.status ? item.status : 'Not Specified',
      }
    })

    return {
      ...data,
      rows: newRows,
    }
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

export const postANewVehicle = async (bodyParams) => {
  try {
    const response = await http.post('/vehicles', bodyParams)
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const putEditAVehicle = async (id, bodyParams) => {
  try {
    const response = await http.put(`/vehicles/${id}`, bodyParams)
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const getGpsTrackerDeviceList = async (bodyParams) => {
  try {
    const { data } = await http.post('/gps-tracker-devices/search', bodyParams)
    return data
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

export const getGpsTrackerList = async (query,bodyParams={}) => {
  try {
    const { data } = await http.get(`/tracker/list?${stringify(query)}`, bodyParams)
    return data
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

export const postVehicleTypeList = async (bodyParams) => {
  try {
    const { data } = await http.post('/vehicle-types', bodyParams)
    return data
  } catch (error) {
    return {
      status: 'error',
    }
  }
}

export const putVehicleTypeList = async (id, bodyParams) => {
  try {
    const { data } = await http.put(`/vehicle-types/${id}`, bodyParams)
    return data
  } catch (error) {
    return {
      status: 'error',
    }
  }
}

export const getVehicleTypeList = async (queryParams) => {
  try {
    const { data } = await http.get(`/vehicle-types?${stringify(queryParams)}`)
    return data
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}

export async function searchVehicleType(queryParams, bodyParams) {
  try {
    const { data } = await http.post(`/vehicle-types/search?${stringify(queryParams)}`, bodyParams)

    return data
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export const deleteVehicleTypeList = async (id) => {
  try {
    const { data } = await http.delete(`/vehicle-types/${id}`)
    return data
  } catch (error) {
    return {
      status: 'error',
    }
  }
}

export const deleteVehicle = async (id) => {
  try {
    const { data } = await http.delete(`/vehicles/${id}`)
    return data
  } catch (error) {
    return {
      status: 'error',
      rows: [],
    }
  }
}
