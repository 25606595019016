// MUIS
import { grey } from '@mui/material/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px 8px 18px',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  leftPanelContainer: {
    width: 140,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  leftPanelItemButton: {
    padding: '8px 0px 7px 16px',
    height: 34.5,
    borderLeft: '4px solid transparent',
    '&:hover': {
      backgroundColor: grey[100],
    },
  },
  leftPanelItemText: {
    '& .MuiTypography-root': {
      color: theme.palette.text.secondary,
      fontWeight: 600,
      fontSize: 13,
    },
  },
  dateAndTimeInputContainer: {
    alignItems: 'center',
    marginBottom: 16,
    width: '100%',
    backgroundColor: 'orange,'
  },
  dateAndTimeInput: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: 14,
    width: 88,
  },
  dateAndTimeSelect: {
    width: 88,
    '& .MuiSelect-select': {
      color: theme.palette.text.primary,
      fontWeight: 700,
      fontSize: 14,
    },
  },
  startAndEndDivider: {
    width: 12,
    height: 2,
    backgroundColor: theme.palette.text.secondary,
    margin: '0px auto',
  },
  dateRangePicker: {
    '& .css-1tape97, .css-mvmu1r': {
      width: 280,
    },
    '& .MuiPickersArrowSwitcher-root': {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderBottom: `1px solid ${theme.palette.divider}`,
      padding: '8px 0px',
    },
    '& .css-1tape97:not(:last-of-type)': {
      borderRight: 'unset',
      marginRight: 16,
    },
    '& .MuiTypography-root': {
      fontWeight: 700,
    },
    '& .PrivatePickersSlideTransition-root': {
      height: 254,
      minHeight: 'unset !important',
    },
  },
  actionButton: {
    fontWeight: 700,
    fontSize: 14,
    width: 88,
  },
  actionButtonCancel: {
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  selectMenuTime: {
    maxHeight: 240,
  },
}))

export default useStyles
