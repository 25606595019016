import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// COMPONENTS
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import Lobby from './Lobby'
import Room from './Room'

// MUIS
import Box from '@mui/material/Box'

// STYLES
import useStyles from './agentHelpMeetUseStyles'

// SERVICE
import {getAgentHelpList, putAgentHelpLeave} from 'services/AgentHelpServices'

const AgentHelpMeet = () => {
  const classes = useStyles()
  const params = useParams()

  const [ isMicOn, setIsMicOn ] = useState(true)
  const [ isCameraOn, setIsCameraOn ] = useState(true)
  const [ isAccepted, setIsAccepted ] = useState(false)
  const [ dialogEndShown, setDialogEndShown ] = useState({})
  const [listData, setListData] = useState([])

  const handleEndButtonClick = async () => {
    const bodyRequest = {
      active: listData[0].active,
      adminName: listData[0].adminName,
      adminUid: listData[0].adminUid,
      channelId: listData[0].channelId,
      id: listData[0].id,
      hostUid: listData[0].hostUid,
      type : 'AGENT_HELP'
    }
    
    const result = await putAgentHelpLeave(listData[0].id,bodyRequest)
    
    setIsAccepted(false)
    setDialogEndShown({})
  }

  useEffect(() => {
    const loadAgentHelpList = () => {
      getAgentHelpList({
        page: 0,
        size: 1000,
      }).then((response) => {
        setListData(
          response.rows.filter(
            (item) => item.channelId === params.meetId
          )
        )
      })
    }
    loadAgentHelpList()
  }, [params])

  return (
    <Box className={classes.root}>
      {!isAccepted ?
        <Lobby
          isMicOn={isMicOn}
          setIsMicOn={setIsMicOn}
          isCameraOn={isCameraOn}
          setIsCameraOn={setIsCameraOn}
          setIsAccepted={setIsAccepted}
        /> :
        <Room
          isMicOn={isMicOn}
          setIsMicOn={setIsMicOn}
          isCameraOn={isCameraOn}
          setIsCameraOn={setIsCameraOn}
          setDialogEndShown={setDialogEndShown}
        />}

      {/* DIALOG END */}
      <DialogConfirmation
        title='Keluar Panggilan Video'
        caption='Apa Anda yakin keluar dalam video call ini?'
        dialogConfirmationObject={dialogEndShown}
        setDialogConfirmationObject={setDialogEndShown}
        cancelButtonText='Batal'
        continueButtonText='Keluar'
        onContinueButtonClick={handleEndButtonClick}
        onCancelButtonClick={() => setDialogEndShown({})}
      />
    </Box>
  )
}

export default AgentHelpMeet