// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'
import { alpha } from '@mui/material/styles'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 24,
  },
  contentContainer: {
    position: 'relative',
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  columnActivityType: {
    textTransform: 'capitalize',
  },
  columnSprin: {
    color: colors.mariner,
    borderColor: colors.mariner,
    backgroundColor: alpha(colors.mariner, 0.16),
  },
  columnDevice: {
    color: 'white',
    backgroundColor: colors.raven,
    '& .MuiChip-label': {
      paddingLeft: 8,
    },
  },
  columnDeviceIcon: {
    color: 'white !important',
  },
  columnActions: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  columnOfficer: {
    borderColor: colors.denim,
  },
  columnOfficerAvatar: {
    backgroundColor: colors.denim,
    color: 'white !important',
    fontSize: '10px !important',
  },
  groupingRow: {
    fontWeight: 600,
    textTransform: 'capitalize',
  },
}))

export default useStyles