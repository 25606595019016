// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'
import { alpha } from '@mui/material/styles'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: colors.boulder,
  },
  placesMenu: {
    borderRadius: 2,
    width: 400,
    padding: 0,
    overflowY: 'hidden',
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[4],
    pointerEvents: 'auto',
    cursor: 'pointer',
  },
  placesMenuHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 62,
    paddingLeft: 18,
    paddingRight: 12,
  },
  placesMenuHeaderTitle: {
    marginLeft: 12,
    marginRight: 'auto',
  },
  placesMenuWrap: {
    padding: '8px 4px 8px 0'
  },
  placesInput: {
    flex: 1,
    width: 'auto',
    marginLeft: 6,
    paddingLeft: 12,
  },
  searchIcon: {
    marginRight: 8,
    color: colors.iconDark,
    cursor: 'pointer',
  },
  fabWrapper: {
    padding: '0 12px 0 0',
    marginBottom: -20,
  },
  contentWrapper: {
    flex: 1,
  },
  itemPlacesList: {
    position: 'relative',
    '&:hover .buttonInfoPlacesList': {
      display: 'flex',
      backgroundColor: '#f5f5f5',
    },
  },
  itemPlacesListIcon: {
    width: 24,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary
  },
  itemPlacesListDetail: {
    paddingLeft: 12,
  },
  itemPlacesListTitle: {
    width: 268
  },
  itemPlacesListDesc: {
    fontSize: 12,
    width: 268
  },
  itemPlacesListInfo: {
    display: 'none',
    position: 'absolute',
    right: 0,
    width: 'auto'
  },
  loadingWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: '24px 0',
  },
  loading: {
    width: '25%'
  }
}))

export default useStyles