import { useContext, useEffect, useRef, useState } from 'react'

// COMPONENTS
import CustomFormControl from 'components/Customs/CustomFormControl'
import CustomInputNew from 'components/Customs/CustomInputNew'
import CustomInputLabelNew from 'components/Customs/CustomInputLabelNew'
import DateRangeAndTimePicker from 'components/DateRangeAndTimePicker/DateRangeAndTimePicker'
import InputLocationNew from 'components/InputLocation/InputLocationNew'
import InputDeviceList from 'components/InputDeviceList/InputDeviceList'
import SimpleBackdrop from 'components/Partials/SimpleBackdrop'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// LIBRARY
import { Editor } from '@tinymce/tinymce-react'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { isString } from 'lodash'

// MUIS
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconAccessTime from '@mui/icons-material/AccessTime'
import IconCalendarToday from '@mui/icons-material/CalendarToday'
import IconClose from '@mui/icons-material/Close'
import IconDateRange from '@mui/icons-material/DateRange'
import IconPlace from '@mui/icons-material/Place'
import IconSubject from '@mui/icons-material/Subject'
import IconTextFields from '@mui/icons-material/TextFields'
import IconTimelapse from '@mui/icons-material/Timelapse'
import IconWysiwyg from '@mui/icons-material/Wysiwyg'

// SERVICES
import { postDispatchCreate } from 'services/DispatchService'

// STYLES
import useStyles from './dialogAddDispatchEvidenceUseStyles'

const DialogAddDispatchEvidence = () => {
  const classes = useStyles()
  const { isDialogAddEvidenceShown, setIsDialogAddEvidenceShown, setIsSuccessCreateDispatch } = useContext(PageTrackingContext)
  const { setSnackbarObject } = useContext(AllPagesContext)
  const editorRef = useRef(null)
  const location = useLocation()

  const defaultParamsObject = {
    address: {
      fullAddress: '',
      latitude: 0,
      longitude: 0,
    },
    category: 'NORMAL',
    description: '',
    deviceNos: [],
    dispatchType: 'EVIDENCE',
    endTime: moment().endOf('day').toISOString(),
    label: '',
    startTime: moment().startOf('day').toISOString()
  }

  // STATES
  const [isLoading, setIsLoading] = useState(false)
  const [paramsObject, setParamsObject] = useState(defaultParamsObject)
  const [circle, setCircle] = useState(null)
  const [circleRadius, setCircleRadius] = useState(50)
  const [selectedDeviceList, setSelectedDeviceList] = useState([])
  const [isDateTimeRangeOpen, setIsDateTimeRangeOpen] = useState(false)
  const [dispatchLocation, setDispatchLocation] = useState('')

  const handleParamsChange = (key, value) => {
    return setParamsObject({
      ...paramsObject,
      [key]: value
    })
  }

  const handleSelectDateRangeButtonClick = (inputNewDateRange) => {
    setParamsObject({
      ...paramsObject,
      startTime: inputNewDateRange[0],
      endTime: inputNewDateRange[1]
    })
    setIsDateTimeRangeOpen(false)
  }

  const handleButtonSaveClick = async () => {
    let tempParamsObject = paramsObject
    tempParamsObject.startTime = moment(paramsObject.startTime).toISOString()
    tempParamsObject.endTime = moment(paramsObject.endTime).toISOString()
    tempParamsObject.address.fullAddress = dispatchLocation
    tempParamsObject.deviceNos = selectedDeviceList.map(item => item.deviceNo)

    // VALIDATION ALL FIELD MUST FILLED
    let isFoundEmpty = false
    Object.keys(tempParamsObject).forEach(keyParent => {
      if(keyParent === 'address') {
        Object.keys(tempParamsObject[keyParent]).forEach(keyAddress => {
          if(!tempParamsObject[keyParent][keyAddress]) {
            isFoundEmpty = true
          }
        })
      } else if(keyParent === 'deviceNos') {
        if(!tempParamsObject[keyParent].length) {
          isFoundEmpty = true
        }
      } else {
        if(!tempParamsObject[keyParent]) {
          isFoundEmpty = true
        }
      }
    })

    if(isFoundEmpty) {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Semua Field Harus Terisi',
      })
      return
    }

    setIsLoading(true)
    setIsSuccessCreateDispatch(true)
    const response = await postDispatchCreate(tempParamsObject)
    setIsSuccessCreateDispatch(false)
    if(response?.status === 'error') {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Gagal Membuat Penugasan Kejadian',
      })
      setIsLoading(false)
    } else {
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Berhasil Membuat Penugasan Kejadian',
      })

      setIsLoading(false)
      setIsDialogAddEvidenceShown(false)
    }
  }

  useEffect(() => {
    if(location?.search) {
      const values = queryString.parse(location?.search)

      setParamsObject({
        ...defaultParamsObject,
        address: {
          fullAddress: values?.address,
          latitude: parseFloat(values?.lat),
          longitude: parseFloat(values?.lng),
        }
      })
      
      setDispatchLocation(isString(values?.address) ? values?.address : '')
      setIsDialogAddEvidenceShown({})
    }
  }, [location])

  useEffect(() => {
    if (Object.keys(isDialogAddEvidenceShown)?.length && isString(isDialogAddEvidenceShown?.address?.fullAddress)) {
      setParamsObject({
        ...defaultParamsObject,
        ...isDialogAddEvidenceShown,
        latitude: isDialogAddEvidenceShown?.address?.latitude,
        longitude: isDialogAddEvidenceShown?.address?.longitude,
        address: isDialogAddEvidenceShown?.address
      })
      
      setDispatchLocation(isString(isDialogAddEvidenceShown?.address?.fullAddress) ? isDialogAddEvidenceShown?.address?.fullAddress : '')
    }
  }, [isDialogAddEvidenceShown])

  return (
    <>
      <Dialog
        open={Boolean(isDialogAddEvidenceShown)}
        className={classes.rootDialog}
        onClose={() => setIsDialogAddEvidenceShown(false)}
      >
        <Stack className={classes.root}>
          <Stack direction='row' alignItems='center' padding='8px 16px'>
            <Typography variant='h6' className={classes.titleDialog}>Penugasan</Typography>

            <IconButton onClick={() => setIsDialogAddEvidenceShown(false)}>
              <IconClose />
            </IconButton>
          </Stack>

          <Divider />

          <Stack padding='16px' direction='row'>
            {/* LEFT */}
            <Stack width='50%' paddingRight='8px'>
              <Typography className={classes.groupTitle} variant='subtitle1'>Informasi Tugas</Typography>
            
              {/* NAME PENUGASAN */}
              <CustomFormControl variant='filled' className={classes.formControl}>
                <CustomInputLabelNew shrink={true}>
                  <IconTextFields />
                  <Typography variant='subtitle1'>Nama Penugasan</Typography>
                </CustomInputLabelNew>

                <CustomInputNew
                  disableUnderline
                  placeholder='Masukkan Nama Penugasan'
                  onChange={event => handleParamsChange('label', event.target.value)}
                  value={paramsObject.label}
                />
              </CustomFormControl>

              {/* LOCATION INPUT */}
              <CustomFormControl variant='filled' className={classes.formControl}>
                <CustomInputLabelNew shrink={true}>
                  <IconPlace />
                  <Typography variant='subtitle1'>Lokasi</Typography>
                </CustomInputLabelNew>

                <InputLocationNew
                  location={dispatchLocation}
                  setLocation={setDispatchLocation}
                  circle={circle}
                  setCircle={setCircle}
                  radius={circleRadius}
                  setRadius={setCircleRadius}
                  customPlaceholder='Masukkan Lokasi Penugasan'
                  getLatLng={latLng => setParamsObject({
                    ...paramsObject,
                    address: {
                      ...paramsObject.address,
                      latitude: latLng.lat,
                      longitude: latLng.lng,
                    }
                  })}
                  latitudeProp={paramsObject?.address?.latitude}
                  longitudeProp={paramsObject?.address?.longitude}
                  suggestContainerWidth='472px'
                  radiusMenuWidth='226px'
                  mapHeight={248}
                />
              </CustomFormControl>

              {/* PERIODE TANGGAL */}
              <CustomFormControl variant='filled' className={`${classes.formControl} formControlDatePicker`} onClick={() => setIsDateTimeRangeOpen(true)}>
                <CustomInputLabelNew shrink={true}>
                  <IconDateRange />
                  <Typography variant='subtitle1'>Periode Tanggal</Typography>
                </CustomInputLabelNew>

                <Stack className={classes.dateTimePickerWrapper} direction='row'>
                  <Stack className={classes.boxPreviewDatePicker}>
                    <Stack direction='row' flexWrap='nowrap' alignItems='center' paddingRight='26px'>
                      <IconCalendarToday className={classes.detailDateIcon}/>

                      <Box>
                        <Typography className={classes.detailDateTitle}>
                          {moment(paramsObject?.endTime).format('DD MMMM YYYY')}
                        </Typography>
                      </Box>
                    </Stack>

                    <Stack direction='row' flexWrap='nowrap' alignItems='center' paddingRight='26px'>
                      <IconAccessTime className={classes.detailDateIcon}/>

                      <Box>
                        <Typography className={classes.detailDateTitle}>
                          {moment(paramsObject?.endTime).format('HH:mm')}
                        </Typography>
                      </Box>
                    </Stack>

                    <Stack direction='row' flexWrap='nowrap' alignItems='center' paddingRight='26px'>
                      <IconTimelapse className={classes.detailDateIcon}/>

                      <Box>
                        <Typography className={classes.detailDateTitle}>
                          {moment(paramsObject.endTime).diff(paramsObject?.startTime, 'days') + 1} hari
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </Stack>
              </CustomFormControl>
            </Stack>

            {/* RIGHT */}
            <Stack width='50%' paddingLeft='8px'>
              <Typography className={classes.groupTitle} variant='subtitle1'>Detail Tugas</Typography>

              {/* EDITOR */}
              <CustomFormControl variant='filled' className={classes.formControl}>
                <CustomInputLabelNew shrink={true}>
                  <IconSubject />
                  <Typography variant='subtitle1'>Deskripsi</Typography>
                </CustomInputLabelNew>

                <Stack className={classes.editorWrapper}>
                  <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    onInit={(evt, editor) => editorRef.current = editor}
                    value={paramsObject.description}
                    init={{
                      height: 292,
                      menubar: false,
                      branding: false,
                      statusbar: true,
                      toolbar_mode: 'scrolling',
                      placeholder: 'Type description here...',
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                        'fullscreen'
                      ],
                      fullscreen_native: true,
                      toolbar: 'undo redo | blocks | alignleft aligncenter alignright |' +
                        'bold italic underline backcolor | fullscreen',
                      content_style: 'body {font-family:Helvetica,Arial,sans-serif; font-size:14px;margin: 0 12px;} .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before { color: #A6A6A6; }',
                    }}
                    onEditorChange={(value, editor) => handleParamsChange('description', value)}
                  />
                </Stack>
              </CustomFormControl>

              {/* PRIORITAS TUGAS */}
              <Stack className={`${classes.pockbaseStyles} ${classes.formControl}`} direction='row' flexWrap='nowrap' alignItems='center'>
                <Stack direction='row' flexWrap='nowrap' flex={1} alignItems='center'>
                  <IconWysiwyg className={classes.pockbaseStylesIcon}/>
                  <Typography className={classes.pockbaseStylesLabel}>Prioritas Tugas</Typography>
                </Stack>

                {/* TOGGLE BUTTON */}
                <Switch
                  onChange={event => handleParamsChange('category', event.target.checked ? 'PRIORITY' : 'NORMAL')}
                  checked={paramsObject.category === 'PRIORITY' ? true : false}
                />
              </Stack>

              {/* DEVICE */}
              <Stack className={classes.inputDeviceWrapper}>
                <Typography marginBottom='0 !important' className={classes.groupTitle} variant='subtitle1'>Perangkat</Typography>
            
                {/* DEVICE LIST INPUT */}
                <InputDeviceList
                  buttonText='Perangkat'
                  selectedDeviceList={selectedDeviceList}
                  setSelectedDeviceList={setSelectedDeviceList}
                  type='patrol'
                  classNameMenuAdd={`${classes.customMenuAdd} neutralize-zoom-menu`}
                  defaultDeviceNos={isDialogAddEvidenceShown?.deviceNos}
                />
              </Stack>
            </Stack>
          </Stack>

          <Divider />

          <Stack padding='16px' direction='row' justifyContent='flex-end'>
            <Button
              onClick={() => handleButtonSaveClick()}
              variant='text'
              className={classes.btnDispatch}>DISPATCH</Button>
          </Stack>
        </Stack>
      </Dialog>

      {/* LOADING */}
      <SimpleBackdrop isLoading={isLoading} />

      {/* DIALOG DATE RANGE PICKER */}
      <Dialog
        open={isDateTimeRangeOpen}
        onClose={() => setIsDateTimeRangeOpen(false)} 
        className={classes.dateRangeAndTimePickerDialog}
      >
        <DateRangeAndTimePicker
          value={[paramsObject.startTime, paramsObject.endTime]}
          handleSelectButtonClick={handleSelectDateRangeButtonClick}
          handleCancelButtonClick={() => setIsDateTimeRangeOpen(false)}
        />
      </Dialog>
    </>
  )
}

export default DialogAddDispatchEvidence