// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconOffice = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M4 20H12V4H4V20ZM9 6H11V8H9V6ZM9 10H11V12H9V10ZM9 14H11V16H9V14ZM5 6H7V8H5V6ZM5 10H7V12H5V10ZM5 14H7V16H5V14ZM13 9H20V10H13V9ZM13 20H15V16H18V20H20V11H13V20Z' />
    </SvgIcon>
  )
}

export default IconOffice