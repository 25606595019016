// MUI ICONS
import IconDescription from '@mui/icons-material/Description'
import IconHistoryEdu from '@mui/icons-material/HistoryEdu'
import IconPhoneIphone from '@mui/icons-material/PhoneIphone'
import IconTask from '@mui/icons-material/Task'

export const panelsInfoStatic = {
  panelInformation: {
    title: 'Informasi Peta Terpadu',
    listTabs: [{
      label: 'Kejadian',
      Icon: IconDescription
    }, {
      label: 'Perangkat',
      Icon: IconPhoneIphone
    }, {
      label: 'Penugasan',
      Icon: IconTask
    }],
    defaultTab: 'Kejadian'
  },
  panelDeviceDetail: {
    title: 'Detail Perangkat',
    listTabs: [{
      label: 'Informasi Perangkat',
      Icon: IconPhoneIphone
    }, {
      label: 'Riwayat Kejadian',
      Icon: IconHistoryEdu
    }],
    defaultTab: 'Informasi Perangkat'
  },
  panelEvidenceDetail: {
    title: 'Detail Kejadian',
    listTabs: [],
    defaultTab: 'Detail Kejadian'
  },
  panelDispatchDetail: {
    title: 'Detail Penugasan',
    listTabs: [],

    defaultTab: 'Detail Penugasan'
  }
}