import React, { createContext, useState } from 'react'

// MOMENT
import moment from 'moment'

export const PageEvidencesContext = createContext()

const PageEvidencesContextProvider = (props) => {
  // BOOLEAN
  const [ isDialogSaveNewFilterOpen, setIsDialogNewFilterOpen ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ mustReloadFilterOptionsData, setMustReloadFilterOptionsData ] = useState(true)
  const [ isCreateNewFilterButtonShown, setIsCreateNewFilterButtonShown ] = useState(false)
  const [ isSaveExistingFilterButtonShown, setIsSaveExistingFilterButtonShown ] = useState(false)

  // LIST
  const dataSourceList = [ 'Internal', 'Citizen' ]
  const [ evidenceList, setEvidenceList ] = useState([])
  const [ selectedFilterList, setSelectedFilterList ] = useState([])
  const [ evidencesDateRange, setEvidencesDataRange ] = useState([
    moment().startOf('week').toDate(), 
    moment().endOf('week').toDate(),
  ])

  // NUMBER
  const [ totalEvidences, setTotalEvidences ] = useState(0)
  const [ pageEvidences, setPageEvidences ] = useState(0)
  const [ rowsPerPageEvidences, setRowsPerPageEvidences ] = useState(100)

  // OBJECT
  const [ selectedEvidenceItem, setSelectedEvidenceItem ] = useState(null)
  const [ selectedSavedFilter, setSelectedSavedFilter ] = useState(null)
  const [ dialogDeleteSavedFilter, setDialogDeleteSavedFilter ] = useState({})

  // STRING
  const [ search, setSearch ] = useState('')
  const [ selectedDataSource, setSelectedDataSource ] = useState(dataSourceList[0])

  // FUNCTION
  const addFilterItemToSelectedFilterList = (field, fieldLabel, operator, value) => {
    const newFilterItem = { field, fieldLabel, operator, value }

    setSelectedFilterList(current => {
      let newSelectedFilterList = [...current]

      if (current.length === 0) newSelectedFilterList.push(newFilterItem)
      else {
        const foundItem = current.find(currentItem => 
          currentItem.field === field 
          && currentItem.fieldLabel === fieldLabel 
          && currentItem.value === value
        )

        if (Boolean(foundItem)) {
          if (foundItem?.operator !== operator) {
            newSelectedFilterList = newSelectedFilterList.map((currentItem => {
              let newOperator = currentItem.operator

              if (
                currentItem.field === field 
                && currentItem.fieldLabel === fieldLabel 
                && currentItem.value === value
              ) newOperator = operator

              return {
                ...currentItem,
                operator: newOperator,
              }
            }))
          }
        }
        else newSelectedFilterList.push(newFilterItem)
      }

      return newSelectedFilterList
    })
  }

  return (
    <PageEvidencesContext.Provider
      value={{
        // BOOLEAN
        isDialogSaveNewFilterOpen, setIsDialogNewFilterOpen,
        isLoading, setIsLoading,
        isCreateNewFilterButtonShown, setIsCreateNewFilterButtonShown,
        isSaveExistingFilterButtonShown, setIsSaveExistingFilterButtonShown,
        mustReloadFilterOptionsData, setMustReloadFilterOptionsData,
        // LIST
        dataSourceList,
        evidenceList, setEvidenceList,
        selectedFilterList, setSelectedFilterList,
        evidencesDateRange, setEvidencesDataRange,
        // NUMBER
        totalEvidences, setTotalEvidences,
        pageEvidences, setPageEvidences,
        rowsPerPageEvidences, setRowsPerPageEvidences,
        // OBJECTS
        selectedEvidenceItem, setSelectedEvidenceItem,
        selectedSavedFilter, setSelectedSavedFilter,
        dialogDeleteSavedFilter, setDialogDeleteSavedFilter,
        // STRING
        search, setSearch,
        selectedDataSource, setSelectedDataSource,
        // FUNCTION
        addFilterItemToSelectedFilterList,
      }}
    >
      {props.children}
    </PageEvidencesContext.Provider>
  )
}

export default PageEvidencesContextProvider