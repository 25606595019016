import { useState, useEffect } from 'react'

// CUSTOM COMPONENTS
import CustomInput from 'components/Customs/CustomInput'

// MUIS
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

// MUI ICONS
import IconSearch from '@mui/icons-material/Search'

// SERVICES
import { getDeviceList } from 'services/DeviceService'

// STYLES
import useStyles from './dialogAddDeviceUseStyles'

const MenuDeviceList = (props) => {
  const { 
    menuDeviceListAnchor, setMenuDeviceListAnchor, 
    selectedDevice, setSelectedDevice,
  } = props

  const classes = useStyles()

  const theme = useTheme()

  const [ search, setSearch ] = useState('')
  const [ deviceList, setDeviceList ] = useState([])
  const [ tempSelectedDevice, setTempSelectedDevice ] = useState(selectedDevice)

  const getDeviceListData = async () => {
    const result = await getDeviceList({
      isPTTRegistered: false,
      limit: 1000000,
    })

    if (result.status === 200) {
      const newDeviceList = result.data.map(item => {
        return {
          ...item,
          isSelected: false,
          isShown: true,
        }
      })

      setDeviceList(newDeviceList)
    }
  }

  const handleSearchChange = (event) => {
    setSearch(event.target.value)

    const newDeviceList = [...deviceList].map(item => {
      let isShown = true

      if (event.target.value !== '') {
        isShown = item?.label?.toLowerCase().includes(search.toLowerCase())
      }
      
      return {
        ...item,
        isShown,
      }
    })

    setDeviceList(newDeviceList)
  }

  const handleCancelButtonClick = () => {
    setTempSelectedDevice(selectedDevice)
    setMenuDeviceListAnchor(null)
  }

  const handleSaveButtonClick = () => {
    setSelectedDevice(tempSelectedDevice)
    setMenuDeviceListAnchor(null)
  }

  useEffect(() => {
    getDeviceListData()
  }, [menuDeviceListAnchor])

  return (
    <Menu
      anchorEl={menuDeviceListAnchor}
      open={Boolean(menuDeviceListAnchor)}
      onClose={() => setMenuDeviceListAnchor(null)}
      className={classes.menuDeviceList}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {/* TITLE */}
      <Typography
        padding='16px'
        fontWeight={600}
        borderBottom={`1px solid ${theme.palette.divider}`}
      >
        Pilih Perangkat
      </Typography>

      {/* SEARCH INPUT */}
      <FormControl 
        fullWidth
        variant='standard'
        className={classes.menuDeviceListSearch}
      >
        <CustomInput
          autoFocus
          value={search}
          onChange={handleSearchChange}
          placeholder='Pencarian Cepat'
          disableUnderline
          endAdornment={
            <InputAdornment position='end'>
              <IconSearch/>
            </InputAdornment>
          }
        />
      </FormControl>

      {/* DEVICE LIST */}
      <List 
        disablePadding
        className={classes.menuDeviceListDeviceList}
      >
        {deviceList.map((item, index) => (
          item.isShown &&
          <ListItemButton
            key={index}
            className={item.deviceNo === tempSelectedDevice?.deviceNo ? classes.menuDeviceListSelectedOption : ''}
            onClick={() => setTempSelectedDevice(item)}
          >
            <ListItemText primary={item.label}/>
          </ListItemButton>
        ))}
      </List>

      {/* ACTIONS */}
      <Stack
        direction='row'
        padding='16px'
        justifyContent='flex-end'
        spacing='8px'
      >
        {/* CANCEL BUTTON */}
        <Button
          className={`${classes.menuDeviceListButton} ${classes.menuDeviceListButtonCancel}`}
          onClick={handleCancelButtonClick}
        >
          Batal
        </Button>

        {/* SAVE BUTTON */}
        <Button
          className={classes.menuDeviceListButton}
          onClick={handleSaveButtonClick}
        >
          Simpan
        </Button>
      </Stack>
    </Menu>
  )
}

export default MenuDeviceList