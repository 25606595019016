import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

// COMPONENTS
import DialogAddOrEdit from 'components/DialogAddOrEditNew/DialogAddOrEdit'
import SimpleBackdrop from 'components/Partials/SimpleBackdrop'

// CONSTANTS
import { basePaths } from 'constants/paths'

// CUSTOM COMPONENTS
import CustomInput from 'components/Customs/CustomInput'
import CustomInputLabel from 'components/Customs/CustomInputLabel'

// MUIS
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconFormatColorText from '@mui/icons-material/FormatColorText'

// SERVICES
import { 
  createCrimeType, 
  updateCrimeType,
} from 'services/MasterDataService'

// STORES
import { showAlert } from 'store/MainReducer'

// STYLES
import useStyles from './dialogAddOrEditSettingsCasesUseStyles'

const DialogAddOrEditSettingsCases = (props) => {
  const {
    dialogType,
    dialogAddOrEditSettingsCases,
    setDialogAddOrEditSettingsCases,
    pageRef,
    reloadData,
  } = props

  const classes = useStyles()
  const dispatch = useDispatch()

  const history = useHistory()
  const location = useLocation()

  const [caseName, setCaseName] = useState(dialogType === 'edit' ? dialogAddOrEditSettingsCases.row.crimeTypeName : '')
  const [isLoading, setIsLoading] = useState(false)

  const handleDialogParamsChange = (event) => {
    setCaseName(event.target.value)
  }

  const closeDialog = () => {
    setCaseName('')
    setDialogAddOrEditSettingsCases(null)
  }

  const handleSaveButtonClick = async () => {
    setIsLoading(true)

    const params = {
      crimeTypeName: caseName,
    }

    let resultCase
    if (dialogType === 'add') {
      resultCase = await createCrimeType(params)
    }
    else if (dialogType === 'edit') {
      resultCase = await updateCrimeType(
        dialogAddOrEditSettingsCases.row.crimeTypeNo, 
        {
          crimeTypeName: caseName,
          crimeTypeNo: dialogAddOrEditSettingsCases.row.crimeTypeNo,
        }
      )
    }

    if (resultCase.status !== 'error') {
      let message
      if (dialogType === 'add') message = 'Sukses Membuat Jenis Kejahatan'
      else if (dialogType === 'edit') message = 'Sukses Memperbarui Jenis Kejahatan'
      
      setIsLoading(false)
      dispatch(showAlert({ message }))
      closeDialog()
      reloadData()
    }
  }

  useEffect(() => {
    if (
      location.pathname === basePaths.settingsCases && 
      location.search !== '?mode=add' &&
      !location.search.includes('?mode=edit')
    ) {
      closeDialog()
    }
  }, [location])

  let selectedTitle = ''
  if (dialogType === 'add') selectedTitle = 'Tambah Jenis Kejahatan'
  else if (dialogType === 'edit') selectedTitle = 'Ubah Jenis Kejahatan'

  return (
    <>
      {/* DIALOG ADD OR EDIT */}
      <DialogAddOrEdit
        open={Boolean(dialogAddOrEditSettingsCases)}
        title={selectedTitle}
        onCloseIconClick={() => history.push(basePaths.settingsCases)}
        containerRef={pageRef}
        contents={[
          <>
            {/* MAIN SECTION TEXT */}
            <Typography variant='h6' className={classes.sectionText}>
              Informasi Utama
            </Typography>

            {/* LABEL INPUT */}
            <Box className={classes.iconAndFormControlContainer}>
              <IconFormatColorText className={classes.iconFormControl} />
              <FormControl variant='standard' className={classes.formControl}>
                <CustomInputLabel>Nama Jenis Kejahatan</CustomInputLabel>
                <CustomInput
                  type='text'
                  name='label'
                  value={caseName}
                  onChange={handleDialogParamsChange}
                />
              </FormControl>
            </Box>
          </>
        ]}
        onCancelButtonClick={() => history.push(basePaths.settingsCases)}
        onSaveButtonClick={handleSaveButtonClick}
      />

      {/* LOADING */}
      <SimpleBackdrop isLoading={isLoading} />
    </>
  )
}

export default DialogAddOrEditSettingsCases
