import { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import AgoraChat from 'agora-chat'
import { isString } from 'lodash'
import { v4 as uuid } from 'uuid'

// CONTEXTS
import { PageChatContext } from 'contexts/PageChatContext'

// LIBRARY
import EmojiPicker from 'emoji-picker-react'

// MUIS
import CardMedia from '@mui/material/CardMedia'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import OutlinedInput from '@mui/material/OutlinedInput'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconSend from '@mui/icons-material/Send'
import IconInsertEmoticon from '@mui/icons-material/InsertEmoticon'
import IconMic from '@mui/icons-material/Mic'
import IconAddPhotoAlternate from '@mui/icons-material/AddPhotoAlternate'
import IconClose from '@mui/icons-material/Close'
import IconGraphicEq from '@mui/icons-material/GraphicEq'
import IconAttachFile from '@mui/icons-material/AttachFile'

// STYLES
import useStyles from './chatRoomUseStyles'

// UTILS
import { formatBytes, secondsToTimerFormat } from 'utilities'
import { agoraSendMessage } from '../agoraUtils'
import { getCurrentConversation, splitChannel } from '../chatUtils'

const TextEditor = (props) => {
  const { replyMessageData } = props
  const { conn, setReplyMessage, setIsLoading, textEditorInputRef, rerender } = useContext(PageChatContext)
  const classes = useStyles()
  const mimeType = 'audio/webm'
  const currentConversation = getCurrentConversation()

  // STATES
  const [menuPickerEl, setMenuPickerEl] = useState(null)
  const [fileMessage, setFileMessage] = useState(null)
  const [textMessage, setTextMessage] = useState('')
  const [editorMode, setEditorMode] = useState('text')
  const [isOnSubmitData, setIsOnSubmitData] = useState(false)

  // AUDIO RECORDING
  const mediaRecorderRef = useRef(null)
  const timerRecordingRef = useRef(null)
  const [stream, setStream] = useState(null)
  const [audioChunks, setAudioChunks] = useState([])
  const [voiceRecording, setVoiceRecording] = useState(null)
  const [voiceRecordingTime, setVoiceRecordingTime] = useState(0)

  const enableSendMessageButton = () => {
    if (fileMessage || textMessage || voiceRecording) return true
    else return false
  }

  const getMicrophonePermission = async () => {
    if ('MediaRecorder' in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        })
        let tempStream = streamData
        setStream(tempStream)
        return tempStream
      } catch (err) {
        alert(err.message)
      }
    } else {
      alert('The MediaRecorder API is not supported in your browser.')
    }
  }

  const clearMessageStates = () => {
    stream?.getTracks()?.forEach(function(track) {
      track.stop()
      setStream(null)
    })
    setVoiceRecording(null)
    setVoiceRecordingTime(0)
    setFileMessage(null)
    setTextMessage('')
    setIsOnSubmitData(false)
    setEditorMode('text')
  }

  const startRecording = async () => {
    const tempStream = await getMicrophonePermission()

    clearMessageStates()

    setEditorMode('recordingActive')
    const media = new MediaRecorder(tempStream, { type: mimeType })
    mediaRecorderRef.current = media
    mediaRecorderRef.current.start()
    let localAudioChunks = []
    mediaRecorderRef.current.ondataavailable = (event) => {
      if (typeof event.data === 'undefined') return
      if (event.data.size === 0) return
      localAudioChunks.push(event.data)
    }
    setAudioChunks(localAudioChunks)
    timerRecordingRef.current = setInterval(() => {
      setVoiceRecordingTime(current => current + 1)
    }, 1000)
  }

  const stopRecording = () => {
    setEditorMode('recordingPreview')
    clearInterval(timerRecordingRef.current)
    mediaRecorderRef.current.stop()
    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunks, { type: mimeType })
      const audioFile = new File([audioBlob], `${uuid()}-voice-recording.webm`, {
        type: audioBlob.type
      })
      const audioUrl = URL.createObjectURL(audioBlob)
      setVoiceRecording({
        data: audioFile,
        filename: audioFile?.name,
        filetype: 'webm',
        dataUrl: audioUrl
      })
      setAudioChunks([])
    }

    stream.getTracks().forEach(function(track) {
      track.stop()
      setStream(null)
    })
  }

  const handleFileMessage = (event) => {
    clearMessageStates()
    const generateFile = AgoraChat.utils.getFileUrl(event.target)
    setFileMessage(generateFile)
    setEditorMode('filePreview')
    event.target.value = ''
  }

  const handleEmojiClick = (emojiData) => {
    setTextMessage(textMessage + emojiData.emoji)
    setMenuPickerEl(null)
  }

  const handleStartStopRecording = () => {
    if (editorMode === 'recordingActive') {
      stopRecording()
    } else {
      startRecording()
    }
  }

  const handleSendMessageClick = async () => {
    if (editorMode === 'text') {
      await agoraSendMessage(conn, {
        msg: textMessage,
        to: splitChannel(currentConversation?.channel_id),
        chatType: currentConversation?.chatType,
        id: (Math.random() * 1000).toFixed(0).toString(),
        type: 'txt',
        ext: {
          reply: replyMessageData
        }
      })
    } else if (editorMode === 'filePreview') {
      await agoraSendMessage(conn, {
        type: fileMessage.data.type?.includes('image') ? 'img' : 'file',
        file: fileMessage,
        ext: {
          fileLength: fileMessage.data.size,
          fileName: fileMessage.filename,
          fileType: fileMessage.filetype,
          reply: replyMessageData
        },
        to: splitChannel(currentConversation?.channel_id),
        chatType: currentConversation?.chatType,
        onFileUploadError: () => setIsLoading(false),
        onFileUploadProgress: () => setIsLoading(true),
        onFileUploadComplete: () => setIsLoading(false),
      })
    } else if (editorMode === 'recordingPreview') {
      await agoraSendMessage(conn, {
        type: 'audio',
        file: voiceRecording,
        ext: {
          fileLength: voiceRecording.data.size,
          fileName: voiceRecording.filename,
          fileType: voiceRecording.filetype,
          reply: replyMessageData
        },
        length: voiceRecordingTime.toString(),
        to: splitChannel(currentConversation?.channel_id),
        chatType: currentConversation?.chatType,
        onFileUploadError: () => setIsLoading(false),
        onFileUploadProgress: () => setIsLoading(true),
        onFileUploadComplete: () => setIsLoading(false),
      })
    }

    clearMessageStates()
    setReplyMessage({})
  }

  useEffect(() => {
    document?.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        setIsOnSubmitData(true)
      }
    })

    textEditorInputRef?.current?.querySelector('input')?.focus()
  }, [])

  useEffect(() => {
    textEditorInputRef?.current?.querySelector('input')?.focus()
  }, [rerender])

  useEffect(() => {
    if (isOnSubmitData && enableSendMessageButton()) {
      handleSendMessageClick()
    }
  }, [isOnSubmitData])

  return (
    <>
      {/* PREVIEW REPLY */}
      {(replyMessageData?.replyTo) && <Stack className={classes.previewTextEditor} direction='row' alignItems='center'>
        {replyMessageData?.typeMessage === 'img' && <Stack
          component='img'
          src={replyMessageData?.message?.thumb}
          height='40px'
          mr='8px'
        />}

        {replyMessageData?.replyTo && <Stack flex={1}>
          <Typography variant='subtitle' fontWeight={600}>
            membalas {replyMessageData?.nickname ?? '-'}
          </Typography>

          <Typography variant='body2'>{isString(replyMessageData?.message) ? replyMessageData?.message : replyMessageData?.message?.fileName}</Typography>
        </Stack>}

        <Stack>
          <IconButton size='small' onClick={() => setReplyMessage({})}>
            <IconClose fontSize='small' />
          </IconButton>
        </Stack>
      </Stack>}

      {/* TEXT EDITOR */}
      <Stack
        direction='row'
        padding='8px 16px'
        flexWrap='nowrap'
        alignItems='center'
        height='56px'
      >
        {/* EMOJI */}
        <Stack direction='row' flexWrap='nowrap'>
          <IconButton size='small' onClick={event => setMenuPickerEl(event.currentTarget)}>
            <IconInsertEmoticon fontSize='small' />
          </IconButton>
        </Stack>

        {/* TEXT MESSAGE */}
        {editorMode === 'text' && <OutlinedInput
          className={classes.textMessageInput}
          size='small'
          placeholder='Type a message'
          fullWidth
          disabled={voiceRecording?.dataUrl ? true : false}
          onChange={event => setTextMessage(event.target.value)}
          value={textMessage}
          autoFocus
          ref={textEditorInputRef}
        />}

        {/* VOICE RECORD PREVIEW */}
        {editorMode === 'recordingPreview' && <Stack flex={1} padding='0 24px' direction='row' alignItems='center'>
          <CardMedia
            component='audio'
            src={voiceRecording?.dataUrl}
            controls
            className={classes.audioRecording}
          />

          <Stack>
            <IconButton size='small' onClick={clearMessageStates}>
              <IconClose fontSize='small' />
            </IconButton>
          </Stack>
        </Stack>}

        {/* ON VOICE RECORD TIMER */}
        {editorMode === 'recordingActive' && (
          <Stack flex={1} pl='24px' direction='row' alignItems='center'>
            <IconGraphicEq className={classes.iconEqualizerRecording} />

            <Stack pl='8px'>
              <Typography variant='caption' color='text.secondary'>Recording</Typography>
              <Typography variant='body2'>{secondsToTimerFormat(voiceRecordingTime)}</Typography>
            </Stack>
          </Stack>
        )}

        {/* FILE / IMAGE PREIVEW */}
        {editorMode === 'filePreview' && <Stack px='24px' flex={1} direction='row' alignItems='center'>
          {fileMessage?.data?.type?.includes('image') && <Stack
            pr='8px'
            component='img'
            height={40}
            sx={{ objectFit: 'cover' }}
            src={fileMessage.url}
          />}

          <Stack flex={1}>
            <Typography variant='subtitle2' fontWeight={500} noWrap>
              {fileMessage?.data?.name}
            </Typography>
            <Typography variant='caption' color='text.secondary'>
              {formatBytes(fileMessage?.data?.size)}
            </Typography>
          </Stack>

          <Stack>
            <IconButton size='small' onClick={clearMessageStates}>
              <IconClose fontSize='small' />
            </IconButton>
          </Stack>
        </Stack>}

        <Stack direction='row' flexWrap='nowrap' spacing='4px'>
          {/* SEND */}
          <IconButton
            size='small'
            onClick={() => handleSendMessageClick()}
            disabled={!enableSendMessageButton()}
          >
            <IconSend fontSize='small' />
          </IconButton>

          {/* ADD IMAGE */}
          <label htmlFor='uploadImage'>
            <IconButton size='small' component='span'>
              <IconAddPhotoAlternate fontSize='small' />
            </IconButton>

            <input
              accept='image/*'
              id='uploadImage'
              type='file'
              hidden
              onChange={handleFileMessage}
            />
          </label>

          {/* ADD IMAGE */}
          <label htmlFor='uploadFile'>
            <IconButton size='small' component='span'>
              <IconAttachFile fontSize='small' />
            </IconButton>

            <input
              accept='application/*,audio/*'
              id='uploadFile'
              type='file'
              hidden
              onChange={handleFileMessage}
            />
          </label>

          {/* MIC */}
          <IconButton size='small' onClick={handleStartStopRecording}>
            <IconMic fontSize='small' />
          </IconButton>
        </Stack>
      </Stack>

      {/* EMOJI PICKER */}
      <Menu
        anchorEl={menuPickerEl}
        onClose={() => setMenuPickerEl(null)}
        open={Boolean(menuPickerEl)}
        className={classes.menuPickerEmoji}
      >
        <EmojiPicker
          open
          width={300}
          height={320}
          skinTonesDisabled
          onEmojiClick={handleEmojiClick}
        />
      </Menu>
    </>
  )
}

TextEditor.propTypes = {
  replyMessageData: PropTypes.shape({
    replyTo: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    typeMessage: PropTypes.oneOf(['txt', 'img', 'audio', 'file']),
  })
}

export default TextEditor