// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconRoadClosure = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M16.706 1.41174C13.4622 1.41174 10.8235 4.05049 10.8235 7.2943C10.8235 10.5381 13.4622 13.1769 16.706 13.1769C19.9498 13.1769 22.5886 10.5381 22.5886 7.2943C22.5886 4.05049 19.9498 1.41174 16.706 1.41174ZM14.6891 6.45394H18.7229C19.0086 6.45394 19.2271 6.67243 19.2271 6.95815V7.63045C19.2271 7.91617 19.0086 8.13467 18.7229 8.13467H14.6891C14.4034 8.13467 14.1849 7.91617 14.1849 7.63045V6.95815C14.1849 6.67243 14.4034 6.45394 14.6891 6.45394ZM8.63851 8.13467C8.45363 8.13467 8.26888 8.23538 8.18485 8.42026L1.46193 21.8661C1.36108 22.0846 1.4115 22.3536 1.63 22.5049C1.71404 22.5721 1.83149 22.589 1.93233 22.589H10.9915C11.2773 22.589 11.4958 22.3705 11.4958 22.0847V20.9417C11.4958 20.6728 11.6976 20.4208 11.9665 20.404C12.2522 20.3872 12.5042 20.6225 12.5042 20.9082V22.0847C12.5042 22.3705 12.7227 22.589 13.0084 22.589H22.0676C22.1685 22.589 22.2859 22.5553 22.37 22.5049C22.5884 22.3536 22.6389 22.0846 22.538 21.8661L18.7564 14.2861C18.6555 14.0844 18.4204 13.9668 18.2019 14.0172C17.7145 14.1349 17.2102 14.1853 16.706 14.1853C15.2606 14.1853 13.9159 13.7316 12.7898 12.9753C12.6889 12.9081 12.5377 12.9751 12.5209 13.1096L12.5042 13.8492C12.5042 14.1349 12.269 14.3702 11.9665 14.3534C11.6976 14.3366 11.4958 14.1014 11.4958 13.8157V12.0338C11.4958 11.8826 11.4453 11.7314 11.3444 11.5969C10.6385 10.7229 10.151 9.681 9.93255 8.53811C9.88212 8.3028 9.68044 8.13467 9.42833 8.13467H8.63851ZM12 16.0341C12.2857 16.0341 12.5042 16.2526 12.5042 16.5383V18.2191C12.5042 18.5048 12.2857 18.7233 12 18.7233C11.7143 18.7233 11.4958 18.5048 11.4958 18.2191V16.5383C11.4958 16.2526 11.7143 16.0341 12 16.0341Z'/>
    </SvgIcon>
  )
}

export default IconRoadClosure