import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// MUIS
import Avatar from '@mui/material/Avatar'
import AvatarGroup from '@mui/material/AvatarGroup'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconPeople from '@mui/icons-material/People'
import VideoCallIcon from '@mui/icons-material/VideoCall'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

// STYLES
import useStyels from './videoCallItemUseStyles'

// UTILITIES
import { getBackgroundAvatar } from 'utilities'

const VideoCallItem = (props) => {
  const { data, onJoinButtonClick, type, customDisabledBtn } = props

  const classes = useStyels()
  const [userName, setUserName] = useState([])

  const handleDisableButtonJoin = () => {
    return data.adminUid ? true : false
  }

  useEffect(() => {
    if (data.adminUid !== null) {
      setUserName(JSON.parse(localStorage.getItem('user')))
    }
  }, [data])

  return (
    <Box className={classes.root}>
      {/* HEADER SECTION */}
      <Box className={classes.header}>
        {/* TITLE */}
        <Typography variant='subtitle2' className={classes.title}>
          {data?.deviceLabel?.toUpperCase()?? '[Tidak ada label]'}
        </Typography>

        {type === 'playback'
          ? (<OndemandVideoIcon className={classes.iconVideo} />)
          : (<VideoCallIcon className={classes.iconVideo} />)}
      </Box>

      {/* CONTENT SECTION */}
      <Box className={classes.contentContainer}>
        {/* USER JOIN */}
        <Box className={classes.listUserJoin}>
          {/* ICON */}
          <IconPeople fontSize='small' className={classes.iconGroup} />

          {/* AVATAR */}
          <AvatarGroup
            total={userName.userName && data.adminUid ? 2 : 1}
          >
            {data && (
              <Avatar
                className={classes.avatar}
                sx={{
                  backgroundColor: getBackgroundAvatar(
                    data?.deviceLabel?? 'unknown'
                  ),
                }}
              >
                {data.deviceLabel ? data.deviceLabel[0].toUpperCase() : '-'}
              </Avatar>
            )}
            {userName.userName && data.adminUid && (
              <Avatar
                className={classes.avatar}
                sx={{
                  backgroundColor: getBackgroundAvatar(
                    userName.userName
                  ),
                }}
              >
                {userName.userName[0].toUpperCase()}
              </Avatar>
            )}
          </AvatarGroup>
        </Box>

        {/* DATE */}
        {type === 'playback' && (<Box className={classes.dateWrapper}>
          <AccessTimeIcon className={classes.iconTime} />

          <Typography variant='body2' className={classes.dateText}>
            Tanggal: {data.date}
          </Typography>
        </Box>)}

        {/* FOOTER SECTION */}
        <Box className={classes.footer}>
          {/* JOIN BUTTON */}
          <Button
            variant='contained'
            className={classes.button}
            disableElevation
            disabled={customDisabledBtn ? customDisabledBtn() : handleDisableButtonJoin()}
            onClick={onJoinButtonClick}
          >
            {type === 'playback' && (<PlayCircleOutlineIcon className={classes.iconPlay} />)}
            {type === 'playback' ? 'Putar Video' : 'Gabung'}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

VideoCallItem.defaultProps = {
  data: {},
}

VideoCallItem.propTypes = {
  data: PropTypes.object.isRequired,
  onJoinButtonClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['live_streaming', 'agent_help', 'playback', 'conference'])
}

export default VideoCallItem
