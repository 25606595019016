import { useEffect, useState } from 'react'

// CONSTANTS
import { colors } from 'constants/colors'

// CUSTOM COMPONENTS
import CustomDataGridObjects from 'components/DataGridPanelObject/CustomDataGridObjects'
import CustomGroupingCell from 'components/DataGridPanelObject/GroupingCell'

// ECHART
import ReactEcharts from 'echarts-for-react'

// MUIS
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI DATA GRID
import { useGridApiRef } from '@mui/x-data-grid-premium'

// SERVICES
import { getPatrolStatsChart } from 'services/DashboardService'

// STYLES
import useStyles from './panelChartUseStyles'

// UTILS
import { createStructureBarChartData } from './panelChartUtils'
import { getLastNDays, handleCellClickPanelObject, handleColumnHeaderClickPanelObject, setRowClassByGroupObject, setSxCustomClassPanelObject } from 'utilities'
import getCheckboxColumn from 'components/DataGridTable/checkboxColumn'
import { v4 as uuidv4 } from 'uuid'

const TrendActivity = () => {
  const classes = useStyles()
  const dataGridApiRef = useGridApiRef()
  
  const columnList = [
    {
      field: 'label',
      headerName: '',
      sortable: false,
      renderCell: (params) => (
        <CustomGroupingCell
          {...params}
          itemTextWidth={223}
          onClickIconInfo={() => null}
          isPanelTablet
        />
      ),
    }
  ]
  const groupingColDef = {
    headerName: '',
    flex: 1,
    renderCell: (params) => (
      <CustomGroupingCell
        {...params}
        contentWidth={336}
        itemTextWidth={223}
        parentTextWidth={223}
        onClickIconInfo={() => null}
        isPanelTablet
      />
    ),
    renderHeader: (params) => null
  }

  const [dataObject, setDataObject] = useState([])
  const [selectionModel, setSelectionModel] = useState([])
  const [sxCustom, setSxCustom] = useState(null)
  const [dataChart, setDataChart] = useState({})
  const [dataGroups, setDataGroups] = useState({})

  const fetchDataBarChart = async () => {
    const lastSevenDays = getLastNDays(7)
    const response = await getPatrolStatsChart({
      'statsName': 'COUNT',
      'chartType': 'HORIZONTAL_BAR',
      'from': lastSevenDays?.start,
      'to': lastSevenDays?.end,
      'timezoneOffset': 0,
      'options': {
        'limit': 10
      }
    })

    if (response?.data) {
      setDataChart(
        createStructureBarChartData(
          response?.data?.map(item => item.label),
          response?.data?.map(item => item.activeDeviceCount),
          response?.data?.map(item => item.value)
        )
      )
    }
  }

  const fetchDataTableTablet = async () => {
    const lastSevenDays = getLastNDays(7)
    let listDevices = []
    let listGroup = []

    const response = await getPatrolStatsChart({
      'statsName': 'COUNT',
      'from': lastSevenDays.start,
      'to': lastSevenDays.end,
      'chartType': 'LIST',
      'timezoneOffset': 0,
      'options': {
        'limit': 100,
        'groupBy': 'groupName'
      }
    })

    if (response?.data) {
      response?.data?.forEach((item, index) => {
        const devices = item.devices
        const activities = item.values
        const group = {
          groupName: item.label,
          groupColor: colors.clementine, // to-do: need to update again when api ready to return field color
          totalGroupActivity: item.total
        }

        listGroup?.push(group)

        const result = devices?.map((deviceName, indexDevice) => ({
          // id: Number(`${index+1}${indexDevice+1}`),
          id: uuidv4(),
          label: deviceName,
          totalActivity: activities[indexDevice],
          group,
        }))

        listDevices?.push(...result)
      })

      setDataGroups({ rows: listGroup })
      setDataObject(listDevices)
    }
  }

  useEffect(() => {
    fetchDataBarChart()
    fetchDataTableTablet()
  }, [])

  useEffect(() => {
    if (dataGroups?.rows?.length) {
      setSxCustomClassPanelObject(dataGroups, setSxCustom)
    }
  }, [dataGroups])

  return (
    <>
      <Typography variant='subtitle2' fontWeight={600} marginBottom='8px'>
        Keaktifan Polda
      </Typography>

      <Typography variant='caption'>
        Visualisasi dari keaktifan polda berdasarkan perbandingan jumlah laporan kegiatan dan tablet yang digunakan
      </Typography>
      
      {/** CHART */}
      <Stack flex={1}>
        <ReactEcharts
          key={1}
          option={dataChart}
        />

        {/* TABLE */}
        <Stack flex={1} height='100%' sx={sxCustom} margin='24px -16px -16px -16px'>
          <CustomDataGridObjects
            autoHeight
            apiRef={dataGridApiRef}
            columns={[getCheckboxColumn(dataGridApiRef), ...columnList]}
            selectionModel={selectionModel}
            rows={dataObject}
            treeData
            getTreeDataPath={(row) => [ row.group.groupName, row.id ]}
            groupingColDef={groupingColDef}
            defaultGroupingExpansionDepth={-1}
            getRowClassName={(params) => {
              return setRowClassByGroupObject(params)
            }}
            className={classes.objectDataGrid}
            onColumnHeaderClick={(params, event, details) => handleColumnHeaderClickPanelObject(params, event, details, selectionModel, setSelectionModel, dataGridApiRef)}
            headerHeight={0}
            onCellClick={(params, event, details) => {
              handleCellClickPanelObject(params, event, details, selectionModel, setSelectionModel)
            }}
          />
        </Stack>
      </Stack>
    </>
  )
}

export default TrendActivity