// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  customTab: {
    '&.MuiTab-root': {
      height: 60,
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.text.secondary,
    },
    '&.MuiTab-root.Mui-selected': {
      color: '#000000'
    }
  },
  datePickerSort: {
    flex: '0 1 auto',
    display: 'flex',
    alignItems: 'center',
    paddingRight: 24
  },
  datePickerTextField: {
    width: '220px',
    '& .MuiInput-underline::before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12) !important',
      fontSize: '14px',
    },
  },
  datePickerIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    height: 22
  },
  dateRangeAndTimePickerDialog: {
    '& .MuiDialog-paper': {
      maxWidth: 'unset',
    },
  },
  liveGrid: {
    padding: 24,
  },
  tabsWrapper: {
    backgroundColor: '#ffffff',
    position: 'relative',
    zIndex: 9
  },
  stackPlayback: {
    overflowY: 'auto'
  },
  liveList: {
    flex: 1,
    overflowY: 'auto',
    height: '100%'
  }
}))

export default useStyles