import { useState, useEffect } from 'react'

// COMPONENTS
import CarouselMedias from 'components/CarouselMedias/CarouselMedias'
import MediaAudio from './MediaAudio'
import MediaImage from './MediaImage'
import MediaVideo from './MediaVideo'

// CONSTANTS
import { colors } from 'constants/colors'
import { iconListTagEvidences } from 'constants/icons'

// MUI
import Chip from '@mui/material/Chip'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { alpha, useTheme } from '@mui/material/styles'

// MUI ICONS
import IconCalendarToday from '@mui/icons-material/CalendarToday'
import IconClose from '@mui/icons-material/Close'
import IconLabel from '@mui/icons-material/Label'
import IconLocationOn from '@mui/icons-material/LocationOn'
import IconPhoneAndroid from '@mui/icons-material/PhoneAndroid'

// PATH ICONS
import IconSprint from 'assets/images/pathIcons/IconSprint'
import IconTagLabel from 'assets/images/pathIcons/IconTagLabel'

// STYLES
import useStyles from './dialogEvidenceDetailUseStyles'

// UTILITIES
import { dateFormat } from 'utilities'

const DialogEvidenceDetail = (props) => {
  const { 
    isDialogOpen, 
    handleDialogClose,
    evidenceObject,
  } = props

  const classes = useStyles()

  const theme = useTheme()

  const [ activeIndex, setActiveIndex ] = useState(evidenceObject?.activeIndex)

  const getEvidenceTagIcon = (iconObject) => {
    const SelectedIconObject = iconListTagEvidences.find(item => item.id === iconObject.iconId)

    let SelectedIcon = SelectedIconObject ? SelectedIconObject.Icon : IconLabel
    
    return (
      <SelectedIcon
        fontSize='small'
        sx={{ color: `${iconObject.tagColor} !important` }}
      />
    )
  }

  const informationList = [
    {
      label: 'Diunggah Oleh',
      reference: evidenceObject?.deviceName,
      renderValue: (
        <Chip
          icon={<IconPhoneAndroid className={classes.informationValueUploaderIcon}/>}
          label={evidenceObject?.deviceName} 
          variant='filled'
          className={`${classes.informationValueChip} ${classes.informationValueUploader}`}
        />
      ),
      icon: IconPhoneAndroid,
    },
    (evidenceObject?.sosStatus ? {
      label: 'Lokasi Kejadian',
      reference: evidenceObject?.address,
      renderValue: evidenceObject?.address,
      icon: IconLocationOn,
    } : {
      label: 'Uraian Singkat',
      reference: evidenceObject?.description,
      renderValue: evidenceObject?.description,
      icon: IconSprint,
    }),
    {
      label: 'Tag Kejadian',
      reference: evidenceObject?.tags?.length > 0 || evidenceObject?.sosStatus,
      renderValue: evidenceObject?.sosStatus ? (
        <Chip
          icon={IconLabel}
          label='SOS' 
          variant='filled'
          className={`${classes.informationValueChip} ${classes.informationValueChipSos}`}
          color='error'
        />
      ) : (
        <Stack 
          spacing='4px'
          marginTop='4px'
        >
          {evidenceObject?.tags?.map((item, index) => (
            <Chip
              key={index}
              icon={getEvidenceTagIcon(item)}
              label={item.tagName} 
              variant='outlined'
              className={classes.informationValueChip}
              sx={{
                color: item.tagColor, 
                backgroundColor: alpha(item.tagColor, 0.08),
                border: `1px solid ${item.tagColor}`, 
              }}
            />
          ))}
        </Stack>
      ),
      icon: IconTagLabel,
    },
    {
      label: 'Tanggal Kejadian',
      reference: evidenceObject?.createdOn,
      renderValue: dateFormat(evidenceObject?.createdOn),
      icon: IconCalendarToday,
    },
  ]

  const getMediaItem = (mediaItem) => {
    if (mediaItem.mediaType === 'IMAGE') return <MediaImage mediaItem={mediaItem}/>
    else if (mediaItem.mediaType === 'VIDEO') return <MediaVideo mediaItem={mediaItem}/>
    else if (mediaItem.mediaType === 'AUDIO') return <MediaAudio mediaItem={mediaItem}/>
  }

  const getMediaTitleType = (mediaItem) => {
    if (mediaItem?.mediaType === 'IMAGE') return 'Foto'
    else if (mediaItem?.mediaType === 'VIDEO') return 'Video'
    else if (mediaItem?.mediaType === 'AUDIO') return 'Audio'
  }

  useEffect(() => {
    setActiveIndex(evidenceObject?.activeIndex)
  }, [evidenceObject])

  return (
    <Dialog
      fullScreen
      open={isDialogOpen}
      onClose={handleDialogClose}
      className={classes.root}
    >
      {/* CAROUSEL */}
      <Stack 
        flex='1'
        position='relative'
      >
        {/* CLOSE ICON */}
        <IconButton 
          className={classes.iconCloseDialog}
          onClick={handleDialogClose}
        >
          <IconClose/>
        </IconButton>

        {/* CAROUSEL MEDIAS */}
        {evidenceObject &&
        <CarouselMedias
          evidenceObject={evidenceObject}
          variant='fullHeight'
          indicators={false}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          isWithNoZoom
        />}
      </Stack>

      {/* INFORMATION PANEL */}
      <Stack
        width='460px'
        height='100%'
        padding='16px'
      >
        {/* MEDIA NAME */}
        <Typography
          borderBottom={`1px solid ${theme.palette.divider}`}
          paddingLeft='8px'
          paddingBottom='16px'
          marginBottom='16px'
          fontWeight={600}
          textTransform='capitalize'
        >
          {(activeIndex !== null || activeIndex !== undefined) && evidenceObject
            ? evidenceObject.sosStatus 
              ? 'Detail Kejadian'
              : `${getMediaTitleType(evidenceObject?.medias[activeIndex])} ${evidenceObject?.medias[activeIndex]?.fileName}` 
            : '[Media Tanpa Nama]'
          }
        </Typography>

        {/* INFORMATION */}
        <List className={classes.informationList}>
          {informationList.length > 0 &&
          informationList.map((item, index) => (
            <ListItem
              key={index}
              disablePadding
              className={classes.informationListItem}
            >
              {/* ICON */}
              <ListItemIcon>
                <item.icon/>
              </ListItemIcon>

              {/* TEXT */}
              <ListItemText
                primary={item.label}
                secondary={item.reference ? item.renderValue : 'Tidak ada data'}
              />
            </ListItem>
          ))}
        </List>
        
        {/* CAROUSEL TEXT */}
        {evidenceObject?.tags?.length > 0 &&
        <Typography
          variant='body2'
          borderBottom={`1px solid ${theme.palette.divider}`}
          paddingLeft='8px'
          paddingBottom='16px'
          marginTop='16px'
          marginBottom='16px'
          fontWeight={600}
        >
          Unggahan Lainnya
        </Typography>}

        {/* MEDIA LIST */}
        {evidenceObject?.tags?.length > 0 &&
        <Stack
          direction='row'
          gap='8px'
          flexWrap='wrap'
        >
          {evidenceObject?.medias?.map((item, index) => (
            <Stack
              key={index}
              height='64px'
              width='64px'
              borderRadius='4px'
              border='2px solid white'
              borderColor={index === activeIndex ? theme.palette.primary.main : 'white'}
              backgroundColor={colors.raven}
              justifyContent='center'
              alignItems='center'
              position='relative'
              className={classes.cursortPointer}
              onClick={() => setActiveIndex(index)}
            >
              {getMediaItem(item)}
            </Stack>
          ))}
        </Stack>}
      </Stack>
    </Dialog>
  )
}

export default DialogEvidenceDetail