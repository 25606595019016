import { useContext, useEffect, useRef, useState } from 'react'
import moment from 'moment'

// COMPONENTS
import BubbleChat from './BubbleChat'
import TextEditor from './TextEditor'

// CONTEXTS
import { PageChatContext } from 'contexts/PageChatContext'

// MUIS
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import ListItemAvatar from '@mui/material/ListItemAvatar'

// MUI ICONS
import IconVideocam from '@mui/icons-material/Videocam'
import IconCall from '@mui/icons-material/Call'
import IconMoreVert from '@mui/icons-material/MoreVert'

// SERVICES
import { getAgoraChatRTCToken } from 'services/ChatService'

// STYLES
import useStyles from './chatRoomUseStyles'

// UTILS
import { convertEmailToAgoraId, getCurrentConversation, getRestructureReplyMsg, getReturnMessageByType, getUserNicknameFromFriends, splitChannel } from '../chatUtils'
import { getBackgroundAvatar } from 'utilities'

const ChatRoom = () => {
  const historyParentRef = useRef()
  const parseLocalStorage = JSON.parse(localStorage.getItem('user'))
  const currentConversation = getCurrentConversation()
  const classes = useStyles()

  const {
    historyMessages, friendList, setReplyMessage,
    replyMessage, conn, fetchConversationList, setStartCall,
    fetchHistoryMessages, setIsDialogDetailGroupShown
  } = useContext(PageChatContext)

  // STATES
  const [menuHeaderEl, setMenuHeaderEl] = useState(null)

  const handleDeleteConversationByUserId = () => {
    conn?.deleteConversation({
      channel: splitChannel(currentConversation?.channel_id),
      chatType: currentConversation?.chatType,
      deleteRoam: true
    })
    setMenuHeaderEl(null)
  }

  const handleClearConversationByUserId = async () => {
    await conn?.removeHistoryMessages({
      targetId: splitChannel(currentConversation?.channel_id),
      chatType: currentConversation?.chatType,
      beforeTimeStamp: Date.now()
    })
    await fetchConversationList()
    fetchHistoryMessages()
    setMenuHeaderEl(null)
  }

  const handleCall = async (callType = 0) => {
    const channel = Math.uuid(8)

    let callTypeText = 'video'
    if (callType === 0) callTypeText = 'audio'
    else callTypeText = 'video'

    const rtcToken = await getAgoraChatRTCToken(channel)
    let params = {
      callType,
      chatType: currentConversation?.chatType,
    }

    if (currentConversation?.chatType === 'singleChat') {
      params = {
        ...params,
        to: splitChannel(currentConversation?.channel_id),
        message: `${parseLocalStorage.userName} memulai panggilan ${callTypeText}`,
        accessToken: rtcToken.token,
        channel
      }
    } else if (currentConversation?.chatType === 'groupChat') {
      params = {
        ...params,
        to: currentConversation?.group?.affiliations.filter(item => {
          if (item.owner) return item.owner !== convertEmailToAgoraId(parseLocalStorage.email)
          else return item.member !== convertEmailToAgoraId(parseLocalStorage.email)
        }).map(item => item?.member ?? item?.owner),
        message: `${parseLocalStorage.userName} memulai panggilan ${callTypeText}`,
        groupId: currentConversation?.group?.id,
        groupName: currentConversation?.group?.groupname,
        accessToken: rtcToken.token,
        channel
      }
    }

    setStartCall(params)
  }

  useEffect(() => {
    setTimeout(() => {
      if (historyParentRef.current?.scrollHeight) {
        historyParentRef.current.scrollTop = historyParentRef.current?.scrollHeight
      }
    }, 1000)
  }, [historyMessages])

  return (
    <>
      <Stack flex={1} height='100%' position='relative'>
        {/* HEADER */}
        <Stack direction='row' alignItems='center' padding='8px 16px'>
          {currentConversation?.group?.groupname && <ListItemAvatar
            sx={{
              backgroundColor: getBackgroundAvatar(currentConversation?.group?.groupname ?? '')
            }}
            className={classes.conversationAvatar}
            onClick={() => setIsDialogDetailGroupShown({
              ...currentConversation?.group,
              from: 'dialogDicussion'
            })}
          >
            <Typography fontWeight={600} variant='caption' color='white'>
              {currentConversation?.group?.groupname?.toUpperCase()?.split(' ')?.[0]?.[0]}
              {currentConversation?.group?.groupname?.toUpperCase()?.split(' ')?.[1]?.[0]}
            </Typography>
          </ListItemAvatar>}

          <Stack flex={1}>
            <Typography variant='subtitle1' fontWeight={700} flex={1}>
              {currentConversation?.chatType === 'singleChat'
                ? getUserNicknameFromFriends(friendList, splitChannel(currentConversation?.channel_id))
                : currentConversation?.group?.groupname
              }
            </Typography>
          </Stack>

          <Stack direction='row'>
            <IconButton size='medium' onClick={() => handleCall(1)}>
              <IconVideocam fontSize='medium' />
            </IconButton>

            <IconButton size='medium' onClick={() => handleCall(0)}>
              <IconCall fontSize='medium' />
            </IconButton>

            <IconButton size='medium' onClick={event => setMenuHeaderEl(event.currentTarget)}>
              <IconMoreVert fontSize='medium' />
            </IconButton>
          </Stack>
        </Stack>

        <Divider />

        {/* HISTORY MESSAGES */}
        <Stack
          flex={1}
          padding='16px'
          spacing='12px'
          overflow='auto'
          ref={historyParentRef}
        >
          {historyMessages?.map(item => (
            getReturnMessageByType(item.type, item) && <BubbleChat
              key={item.id}
              isOwner={convertEmailToAgoraId(parseLocalStorage?.email) === item.from}
              name={convertEmailToAgoraId(parseLocalStorage?.email) === item.from ? 'Anda' : getUserNicknameFromFriends(friendList, item.from)}
              typeMessage={item.type}
              message={getReturnMessageByType(item.type, item)}
              time={moment(item.time).format('HH:mm')}
              onReplyClick={() => setReplyMessage({
                replyTo: item.from,
                nickname: convertEmailToAgoraId(parseLocalStorage?.email) === item.from ? parseLocalStorage?.userName : getUserNicknameFromFriends(friendList, item.from),
                message: getRestructureReplyMsg(item.type, item),
                typeMessage: item.type
              })}
              isReply={item?.ext?.reply?.typeMessage ? true : false}
              replyTo={item?.ext?.reply?.nickname === parseLocalStorage?.userName ? 'anda' : item?.ext?.reply?.nickname}
              replyMessage={item?.ext?.reply?.message}
              replyTypeMessage={item?.ext?.reply?.typeMessage}
            />
          ))}
        </Stack>

        <Divider />

        {/* TEXT EDITOR */}
        <TextEditor
          replyMessageData={replyMessage}
        />
      </Stack>

      <Menu
        anchorEl={menuHeaderEl}
        open={Boolean(menuHeaderEl)}
        onClose={() => setMenuHeaderEl(null)}
      >
        <MenuItem onClick={handleClearConversationByUserId}>Clear Messages</MenuItem>
        <MenuItem onClick={handleDeleteConversationByUserId}>Delete Conversation</MenuItem>
      </Menu>
    </>
  )
}

export default ChatRoom