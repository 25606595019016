import { useState, useContext } from 'react'

// COMPONENTS
import DialogChangeGroupAndDepartment from 'pages/DevicesMobileApps/DialogChangeGroupAndDepartment/DialogChangeGroupAndDepartment'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// SERVICES
import { updateStatus } from 'services/DeviceService'

// STYLES
import useStyles from './mobileAppDetailUseStyles'

const RenderIncomingMobileApp = (props) => {
  const { 
    deviceDetail,
    reloadData,
    closeFlyout,
  } = props
  
  const classes = useStyles()

  const { setSnackbarObject } = useContext(AllPagesContext)

  const [ isDialogChangeOpen, setIsDialogChangeOpen ] = useState(false)
  const [ dialogRejectMobileApp, setDialogRejectMobileApp ] = useState({})

  const handleRejectButtonClick = async () => {
    const resultRejectMobileApp = await updateStatus(deviceDetail?.deviceNo, { expectedStatus: 'DENIED' })
    
    if (resultRejectMobileApp?.status !== 'error') {
      closeFlyout()
      reloadData()
      
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Sukses menolak perangkat',
      })
      
      setDialogRejectMobileApp({})
    }
    else setSnackbarObject({
      open: true,
      severity: 'error',
      title: '',
      message: 'Gagal menolak perangkat',
    })
  }

  return (
    <Stack marginBottom='4px'>
      {/* LABEL */}
      <Typography 
        variant='body2'
        fontWeight={600}
        display='inline'
        marginBottom='4px'
      >
        <Typography 
          variant='body2'
          fontWeight={400}
          display='inline'
        >
          dengan nama&nbsp;
        </Typography>

        {deviceDetail?.label}
      </Typography>

      {/* ACTIONS */}
      <Stack 
        direction='row'
        spacing='8px'
      >
        {/* ACCEPTS BUTTON */}
        <Button 
          variant='contained'
          size='small'
          className={classes.button}
          disableElevation
          onClick={() => setIsDialogChangeOpen(true)}
        >
          Terima
        </Button>

        {/* REJECT BUTTON */}
        <Button 
          size='small'
          className={`${classes.button} ${classes.greyButton}`}
          onClick={() => setDialogRejectMobileApp({ 
            label: deviceDetail?.label, 
            deviceId: deviceDetail?.deviceNo, 
          })}
        >
          Tolak
        </Button>
      </Stack>

      {/* DIALOG CHANGE GROUP AND DEPARTMENT */}
      <DialogChangeGroupAndDepartment
        isDialogOpen={isDialogChangeOpen}
        setIsDialogOpen={setIsDialogChangeOpen}
        deviceDetail={deviceDetail}
        reloadData={reloadData}
      />

      {/* DIALOG REJECT MOBILE APP */}
      <DialogConfirmation
        title={`Tolak Perangkat “${deviceDetail?.label}”`}
        caption='Anda yakin ingin menolak perangkat ini? Tindakan ini tidak dapat dibatalkan, dan perangkat masih dapat mencoba bergabung kembali.'
        dialogConfirmationObject={dialogRejectMobileApp}
        setDialogConfirmationObject={setDialogRejectMobileApp}
        cancelButtonText='Batal'
        continueButtonText='Tolak'
        onContinueButtonClick={handleRejectButtonClick}
        onCancelButtonClick={() => setDialogRejectMobileApp({})}
      />
    </Stack>
  )
}

export default RenderIncomingMobileApp