const keyReferenceLocation = 'wuling-tracking-reference-location'

// REFERENCE LOCATION
export const setReferenceLocationToLocalStorage = (inputLocationObject) => {
  localStorage.setItem(keyReferenceLocation, JSON.stringify(inputLocationObject))
}

export const readReferenceLocationFromLocalStorage = () => {
  return localStorage.getItem(keyReferenceLocation)
    ? JSON.parse(localStorage.getItem(keyReferenceLocation))
    : []
}

export const removeReferenceLocationFromLocalStorage = () => {
  return localStorage.removeItem(keyReferenceLocation)
}