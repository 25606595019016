import React from 'react'
import { dateFormat } from 'utilities' 

// CUSTOM COMPONENTS
import CustomDialogActionButton from 'components/Customs/CustomDialogActionButton'

// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import Slide from '@mui/material/Slide'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconSettings from '@mui/icons-material/Settings'
import IconNotifications from '@mui/icons-material/Notifications'

// SERVICE
import { updateAlerts } from 'services/AlertService'

// STYLES
import useStyles from './panelNotificationUseStyles'

const PanelNotification = (props) => {
  const { 
    pageRef,
    isNotificationPanelShown, 
    notificationList,
    reloadData,
  } = props

  const classes = useStyles()

  const getTotalNotification = () => {
    const filteredNotification =  notificationList.filter((item) => item.status === 'UNREAD')

    if(filteredNotification.length === 0) return 'Tidak ada pemberitahuan'
    else if(filteredNotification.length < 100) return `${filteredNotification.length} pemberitahuan`
    else if(filteredNotification.length > 99 ) return '99+ pemberitahuan'
  } 

  const handleButtonClick = async () => {
    const filteredObjectList = [...notificationList]
      .filter((item) => item.status === 'UNREAD')
      .map((item) => item.alertNo)
    
    const bodyParams = { alertHistoryIds : filteredObjectList }

    if(filteredObjectList.length > 0) {
      const result = await updateAlerts(bodyParams)

      if(result.status !== 'error') reloadData()
    }
  }

  const handleListItemClick = async (inputItem) => {
    if(inputItem.status === 'UNREAD'){
      const bodyParams = { alertHistoryIds : [inputItem.alertNo] }

      const result = await updateAlerts(bodyParams)

      if(result.status !== 'error') reloadData()
    }
  }

  const getColorItemNotification = (item) => {
    if (item.eventType.includes('EVIDENCE') || item.eventType.includes('PATROL')) {
      return colors.carnation
    } else if (item.eventType.includes('CONFERENCE') || item.eventType.includes('LIVE') || item.eventType.includes('AGENT')) {
      return colors.atlantis
    } else if (item.eventType.includes('SOS')) {
      return colors.carnation
    }
  }

  return (
    <Slide 
      direction='right' 
      in={isNotificationPanelShown} 
      container={pageRef.current}
    >
      <Box className={classes.panelContainer}>
        {/* HEADER */}
        <Box className={classes.panelHeader}>
          {/* PANEL TITLE */}
          <Typography
            variant='h6'
            className={classes.panelTitle}
          >
            Pemberitahuan
          </Typography>
        </Box>

        <Divider/>

        {/* TOTAL NOTIFICATION */}
        <Stack className={classes.panelNotifTotal}>
          <Typography flex={1} className={classes.panelCaption}>
            {getTotalNotification()}
          </Typography>

          <IconSettings fontSize='small' className={classes.iconSettings} />
        </Stack>

        <Divider/>

        <List 
          disablePadding
          className={classes.objectList}
        >
          {notificationList.length > 0 && 
          notificationList.map((parentItem, parentIndex) => (
            <React.Fragment key={parentIndex}>
              <ListItem
                disablePadding
                className={classes.objectItem}
              >
                <ListItemButton 
                  className={classes.objectItemButton}
                  onClick={() => handleListItemClick(parentItem)}
                  sx={{
                    borderLeftColor: getColorItemNotification(parentItem)
                  }}
                >
                  <ListItemIcon 
                    className={classes.objectItemIcon}
                  >
                    <IconNotifications 
                      sx={(theme) => (
                        {color : parentItem?.status === 'UNREAD' ? 
                          theme.palette.text.primary : 
                          theme.palette.text.secondary
                        })}/>
                  </ListItemIcon>

                  <Typography 
                    className={classes.objectItemText}
                    sx={(theme) => (
                      {color : parentItem?.status === 'UNREAD' ?
                        theme.palette.text.primary 
                        : theme.palette.text.secondary
                      })}>
                    {parentItem?.eventType ?? '-'}
                    <br/>
                    {parentItem?.deviceName ?? '-'}
                    <br/>
                    {parentItem?.notificationTime ? dateFormat(parentItem?.notificationTime): '-'}
                  </Typography>
                </ListItemButton>
              </ListItem>
            </React.Fragment>
          ))}
        </List>

        <Box className={`${classes.buttonSection} ${classes.boxShadows}`}>
          <CustomDialogActionButton className={classes.CustomDialogActionButton} onClick={handleButtonClick}>
            Tandai Semua Telah Dibaca
          </CustomDialogActionButton>
        </Box>
      </Box>
    </Slide>
  )
}

export default PanelNotification