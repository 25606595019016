import PropTypes from 'prop-types'

// MUIS
import Checkbox from '@mui/material/Checkbox'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './conversationItemUseStyles'

// UTILS
import { getBackgroundAvatar } from 'utilities'

const ConversationItem = (props) => {
  const {
    name, title, description, date, rightComponent,
    avatarSize, onItemClick, selected,
    isWithCheckbox, isChecked, onCheckboxChange
  } = props
  const classes = useStyles()

  const getAvatarSize = () => ({
    avatar: avatarSize === 'large' ? 32 : 28,
    avatarText: avatarSize === 'large' ? 'subtitle2' : 'caption'
  })

  return (
    <ListItem className={classes.conversationItem}>
      <ListItemButton
        onClick={onItemClick}
        selected={selected}
        sx={{
          padding: isWithCheckbox ? '8px 4px' : '8px 16px'
        }}
      >
        {/* CHECKBOX */}
        {isWithCheckbox && <Checkbox
          checked={isChecked}
          onChange={onCheckboxChange}
        />}

        {/* AVATAR */}
        <ListItemAvatar
          sx={{
            backgroundColor: getBackgroundAvatar(name),
            height: getAvatarSize().avatar,
            width: getAvatarSize().avatar,
          }}
          className={classes.conversationAvatar}
        >
          <Typography fontWeight={600} variant={getAvatarSize().avatarText} color='white'>
            {name?.toUpperCase()?.split(' ')?.[0]?.[0]}
            {name?.toUpperCase()?.split(' ')?.[1]?.[0]}
          </Typography>
        </ListItemAvatar>

        {/* TITLE & DESCRIPTION */}
        <ListItemText
          className={classes.conversationText}
          disableTypography
          primary={title ? <Typography display='block' variant='caption' color='black' fontWeight={600} noWrap>
            {title}
          </Typography> : null}
          secondary={description ? <Typography display='block' variant='caption' color='text.secondary' noWrap>
            {description}
          </Typography> : null}
        />

        {/* DATE */}
        {date && <Stack alignSelf='flex-start' pl='8px' width='80px'>
          <Typography variant='caption' display='block' color='text.secondary' textAlign='right' fontSize='10px'>
            {date}
          </Typography>
        </Stack>}

        {/* RIGHT COMPONENT */}
        {rightComponent && rightComponent}
      </ListItemButton>
    </ListItem>
  )
}

ConversationItem.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  avatarSize: PropTypes.oneOf(['small', 'large']),
  onItemClick: PropTypes.func,
  selected: PropTypes.bool,
  isWithCheckbox: PropTypes.bool,
  isChecked: PropTypes.bool,
  onCheckboxChange: PropTypes.func
}

export default ConversationItem