// MUI STYLES
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { colors } from 'constants/colors'

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      minWidth: 800,
      padding: 0,
    }
  },
  dialogHeader: {
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  dialogHeaderTitle: {
    fontSize: 20,
    fontWeight: 600,
    flex: 1,
  },
  listItemButton: {
    height: 56,
    borderBottom: `1px solid ${theme.palette.divider}`,
    width: 240,
    '& .MuiListItemText-primary': {
      fontWeight: 600
    },
    '& .MuiListItemIcon-root': {
      minWidth: 40
    }
  },
  contentListTabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  formControl: {
    marginBottom: 16,
    borderRadius: '2px !important'
  },
  pockbaseStyles: {
    backgroundColor: '#e4e9ec',
    transition: 'background-color .4s',
    padding: '4px 0 4px 12px',
    borderRadius: 2,
    '& .MuiInput-input': {
      padding: '12px 16px'
    },
    '&:focus-within': {
      backgroundColor: '#D7DDE1'
    }
  },
  pockbaseStylesIcon: {
    fontSize: 16,
    color: theme.palette.text.secondary,
  },
  pockbaseStylesLabel: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginLeft: 8,
    fontWeight: 500,
  },
  listSelectedDevices: {
    backgroundColor: theme.palette.common.white,
    margin: '0 4px 4px -8px',
    borderRadius: 2,
    padding: 4,
  },
  chipSelectedDevice: {
    backgroundColor: colors.raven,
    color: theme.palette.common.white,
    height: 28,
    margin: 4,
    '& .MuiChip-deleteIcon': {
      color: alpha(theme.palette.common.white, 0.64),
      '&:hover': {
        color: alpha(theme.palette.common.white, 0.64)
      }
    },
    '& .MuiChip-label': {
      fontWeight: 500,
    },
    '&.Mui-disabled': {
      opacity: 0.52
    }
  },
  buttonDialog: {
    fontWeight: 700,
    width: 'fit-content'
  }
}))

export default useStyles