// MUIS
import { makeStyles } from '@mui/styles'

// STYLES
import { colors } from 'constants/colors'

const useStyles = makeStyles((theme) => ({
  groupHeaderInput: {
    '& .MuiInputLabel-root': {
      fontSize: 12,
    },
    '& .MuiInputBase-input': {
      fontSize: 12,
    },
  },
  iconWrapper: {
    position: 'absolute',
    right: -46,
    '& .objectItemInfo': {
      display: 'none !important',
      color: colors.boulder,
      marginLeft: 8,
      marginRight: 8,
    },
    '&:hover .objectItemInfo': {
      display: 'flex !important',
    },
    '& .objectChildrenStatus': {
      width: 14,
      height: 14,
      marginLeft: 17,
      marginRight: 17,
    },
    '&:hover .objectChildrenStatus': {
      display: 'none !important',
    },
  },
  iconTablet: {
    color: colors.spunPearl,
    fontSize: 16,
  }
}))

export default useStyles