// MUI STYLES
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    '& .circleRipple': {
      display: 'flex',
      backgroundColor: theme.palette.error.main,
      width: 36,
      height: 36,
      borderRadius: '50%',
      animation: '$ripple 2s linear infinite',
      '& svg': {
        color: theme.palette.common.white
      }
    },
    '& .popup-marker-alert': {
      '& .leaflet-popup-content': {
        margin: 0,
        width: '100% !important',
        padding: '16px'
      },
      '& .leaflet-popup-tip-container': {
        display: 'none'
      },
      '& .custom-chip': {
        backgroundColor: alpha(theme.palette.error.light, 0.24),
        padding: '0 8px',
        height: 24,
        borderRadius: 4,
        '& svg': {
          marginTop: 4,
          color: theme.palette.error.main,
          fontSize: 16
        },
        '&.conference': {
          backgroundColor: alpha(theme.palette.success.light, 0.24),
          '& svg': {
            color: theme.palette.success.main,
          },
        },
        '&.sosMessageType': {
          display: 'flex',
          alignItems: 'center',
          '& svg': {
            marginTop: 0
          }
        }
      }
    }
  },
  mapContainer: {
    position: 'relative',
    zIndex: 1,
    '&.leaflet-container': {
      width: '100%',
      height: '100%',
    },
  },
  panelContainer: {
    position: 'absolute',
    zIndex: 2,
    backgroundColor: 'transparent',
  },
  panelLeft: {
    left: 20,
    top: 20,
    bottom: 20,
    width: 328,
    display: 'flex',
    flexDirection: 'column',
  },
  panelMiddle: {
    left: 412,
    top: 20,
    bottom: 20,
    width: 372,
    display: 'flex',
    flexDirection: 'column',
  },
  dateRangeAndTimePickerDialog: {
    '& .MuiDialog-paper': {
      maxWidth: 'unset',
    },
  },
  panelZoomContainer: {
    position: 'absolute',
    zIndex: 2,
    right: 20,
    bottom: 20,
  },
  '@keyframes ripple': {
    '0%': {
      boxShadow: `0 0 0 0 ${alpha(theme.palette.error.light, 0.24)}`
    },
    '100%': {
      boxShadow: `0 0 0 10px ${alpha(theme.palette.error.light, 0.24)}`
    }
  }
}))

export default useStyles