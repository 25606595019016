import { useContext, useEffect, useState } from 'react'
import moment from 'moment'

// COMPONENTS
import DateRangeAndTimePicker from 'components/DateRangeAndTimePicker/DateRangeAndTimePicker'
import DialogMenuFilter from './DialogMenuFilter'

// CONTEXTS
import { iconListTagEvidences } from 'constants/icons'
import { PageAnalyticsContext } from 'contexts/PageAnalyticsContext'

// CONSTANTS
import valuesPageAnalytics from 'constants/valuesPageAnalytics'

// MUIS
import { alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
//import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'

// MUIS ICON
import IconAdd from '@mui/icons-material/Add'
import IconArrowCircleLeftOutlined from '@mui/icons-material/ArrowCircleLeftOutlined'
//import IconCheckCircle from '@mui/icons-material/CheckCircle'
import IconClose from '@mui/icons-material/Close'
import IconDateRange from '@mui/icons-material/DateRange'
import IconDelete from '@mui/icons-material/Delete'
import IconExpandMore from '@mui/icons-material/ExpandMore'
//import IconErrorOutline from '@mui/icons-material/ErrorOutline'
import IconInfo from '@mui/icons-material/Info'
import IconMyLocation from '@mui/icons-material/MyLocation'
import IconLocationSearching from '@mui/icons-material/LocationSearching'
import IconPhoneAndroid from '@mui/icons-material/PhoneAndroid'
import IconRoom from '@mui/icons-material/Room'
import IconSearch from '@mui/icons-material/Search'
//import IconWysiwyg from '@mui/icons-material/Wysiwyg'

// PATH ICONS
import IconDatabase from 'assets/images/pathIcons/IconDatabase'
import IconTagLabel from 'assets/images/pathIcons/IconTagLabel'

// SERVICES
import { getGeoByAddress } from 'services/TrackingServices'

// STYLES
import useStyles from './panelFilterUseStyles'

const PanelFilter = () => {
  const classes = useStyles()
  const {
    dataTagsList, dataDevicesList, setIsPanelFilterShown,
    viewType, paramsFilter, setParamsFilter, map, setViewType
  } = useContext(PageAnalyticsContext)

  // STATES
  const [isDateTimeRangeOpen, setIsDateTimeRangeOpen] = useState(false)
  const [inputLocationAnchorRef, setInputLocationAnchorRef] = useState(null)
  const [tagEvidencesAnchorRef, setTagEvidencesAnchorRef] = useState(null)
  const [devicesAnchorRef, setDevicesAnchorRef] = useState(null)
  const [searchTags, setSearchTags] = useState('')
  const [searchDevices, setSearchDevices] = useState('')
  const [searchLocation, setSearchLocation] = useState('')
  const [dataSuggestLocation, setDataSuggestLocation] = useState([])
  const [dataTempParamsFilter, setDataTempParamsFilter] = useState([])
  const [dataTempTagEvidences, setDataTempTagEvidences] = useState(paramsFilter.tagEvidences)
  const [dataTempDevices, setDataTempDevices] = useState(paramsFilter.devices)

  const handleSelectDateRangeButtonClick = (inputNewDateRange) => {
    setParamsFilter({
      ...paramsFilter,
      date: [inputNewDateRange[0], inputNewDateRange[1]]
    })
    setIsDateTimeRangeOpen(false)
  }

  const handleMenuClose = (inputSetSearch, inputSetAnchor) => {
    inputSetSearch('')
    inputSetAnchor(null)
  }

  const fetchSuggestAddress = async () => {
    const response = await getGeoByAddress({
      provider_type: 'google',
      q: searchLocation
    })
    setDataSuggestLocation(response.locations)
  }

  const getCurrentLocation = () => {
    navigator.permissions.query({ name: 'geolocation' }).then(function(result) {
      if (result.state === 'granted') {
        navigator.geolocation.getCurrentPosition((position) => {
          setParamsFilter({
            ...paramsFilter,
            location: {
              name: 'Lokasi Saat Ini',
              latLng: [position.coords.latitude, position.coords.longitude],
              type: 'myLocation'
            }
          })
            
          map.flyTo([position.coords.latitude, position.coords.longitude], 16)
          handleMenuClose(setSearchLocation, setInputLocationAnchorRef)
        })
      } 
      else if (result.state === 'prompt') getCurrentLocation()
      else if (result.state === 'denied') alert('Mohon izinkan akses lokasi')

      result.onchange = function() {
        getCurrentLocation()
      }
    })
  }

  const handleItemSuggestClick = (inputItem) => {
    setParamsFilter({
      ...paramsFilter,
      location: {
        name: inputItem.address,
        latLng: [inputItem.lat, inputItem.lng],
        type: 'suggest'
      }
    })

    map.flyTo([inputItem.lat, inputItem.lng], 16)
    handleMenuClose(setSearchLocation, setInputLocationAnchorRef)
  }

  const checkIsCheckedTags = (inputTagNo) => {
    const findTag = dataTempTagEvidences.find(item => item.tagNo === inputTagNo)
    return Boolean(findTag)
  }

  const handleCheckedTags = (inputItem) => {
    let tempParamsTagEvidences = [...dataTempTagEvidences]
    
    if(!checkIsCheckedTags(inputItem.tagNo)) {
      tempParamsTagEvidences.push(inputItem)
    } else {
      tempParamsTagEvidences = tempParamsTagEvidences.filter(item => item.tagNo !== inputItem.tagNo)
    }
    setDataTempTagEvidences([...tempParamsTagEvidences])
  }

  const handleDeleteTagsFilter = (inputTagNo) => {
    let tempParamsTagEvidences = paramsFilter.tagEvidences.filter(item => item.tagNo !== inputTagNo)

    setParamsFilter({
      ...paramsFilter,
      tagEvidences: [...tempParamsTagEvidences]
    })
    setDataTempTagEvidences([...tempParamsTagEvidences])
  }

  const checkIsCheckedDevices = (inputDeviceNo) => {
    const findDevices = dataTempDevices.find(item => item.deviceNo === inputDeviceNo)
    return Boolean(findDevices)
  }

  const handleCheckedDevices = (inputItem) => {
    let tempParamsDevices = [...dataTempDevices]
    if(!checkIsCheckedDevices(inputItem.deviceNo)) {
      tempParamsDevices.push(inputItem)
    } else {
      tempParamsDevices = tempParamsDevices.filter(item => item.deviceNo !== inputItem.deviceNo)
    }
    setDataTempDevices([...tempParamsDevices])
  }

  const handleDeleteDevicesFilter = (inputDeviceNo) => {
    let tempParamsDevices = paramsFilter.devices.filter(item => item.deviceNo !== inputDeviceNo)

    setParamsFilter({
      ...paramsFilter,
      devices: [...tempParamsDevices]
    })
    setDataTempDevices([...tempParamsDevices])
  }

  const handleButtonCancelClick = () => {
    setParamsFilter(dataTempParamsFilter)
    setIsPanelFilterShown(false)
  }

  const handleButtonSaveClick = () => {
    // a reload data
    setIsPanelFilterShown(false)
  }

  const handleAllAreaLocation = () => {
    setParamsFilter({
      ...paramsFilter,
      location: {
        name: 'Seluruh Wilayah',
        latLng: [],
        type: 'global'
      }
    })

    map.flyTo(valuesPageAnalytics.defaultMapCenter, valuesPageAnalytics.defaultMapZoom)
  }

  useEffect(() => {
    setDataTempParamsFilter(paramsFilter)
  }, [])

  useEffect(() => {
    if(searchLocation) {
      fetchSuggestAddress()
    }
  }, [searchLocation])

  return (
    <>
      <Stack className={classes.root}>
        {/* HEADER */}
        <Stack className={classes.headerWrapper}>
          <IconButton
            onClick={() => handleButtonCancelClick()}
          >
            <IconArrowCircleLeftOutlined className={classes.iconBtnBack}/>
          </IconButton>
          <Typography flex={1} className={classes.titleHeader} variant='h6'>Filter</Typography>
        </Stack>

        {/* CONTENT */}
        <Stack className={classes.content}>
          <Stack className={classes.boxFilter}>
            <Stack className={classes.boxFilterHeader}>
              <IconDatabase className={classes.boxFilterIcon} />

              <Typography className={classes.titleFilter} variant='subtitle2'>Sumber Data</Typography>

              {/* SELECT TYPE DATA */}
              <FormControl className={classes.formControlTypeData}>
                <Select
                  value={viewType}
                  className={classes.selectTypeData}
                  onChange={event => setViewType(event.target.value)}
                  MenuProps={{
                    PaperProps: {
                      className: `${classes.selectTypeDataMenu} no-zoom`
                    },
                  }}
                  IconComponent={IconExpandMore}
                >
                  <MenuItem value='internal' className='zoom'>
                    <Typography fontWeight={600} variant='subtitle2'>Internal</Typography>
                  </MenuItem>

                  <MenuItem value='citizen' className='zoom'>
                    <Typography fontWeight={600} variant='subtitle2'>Citizen</Typography>
                  </MenuItem>
                </Select>
              </FormControl>
            </Stack>

            <Stack padding='16px'>
              {/* SELECT DATE */}
              <FormControl fullWidth disabled>
                <InputLabel className={classes.formControlLabel} shrink>
                  <IconDateRange fontSize='small' />
                  <Typography className={classes.formControlTextLabel} variant='subtitle2'>Tanggal Kejadian</Typography>
                </InputLabel>

                <Select
                  defaultValue='date'
                  className={classes.formControlSelect}
                  onClick={() => setIsDateTimeRangeOpen(true)}
                >
                  <MenuItem value='date'>
                    <Stack direction='row' alignItems='center'>
                      <IconDateRange className={classes.formControlItemIcon}/>
                      <Typography className={classes.formControlItemText} variant='subtitle2'>
                        {moment(paramsFilter.date[0]).format('DD MMM yyyy HH:mm')} - {moment(paramsFilter.date[1]).format('DD MMM yyyy HH:mm')}
                      </Typography>
                    </Stack>
                  </MenuItem>
                </Select>
              </FormControl>

              <Divider className={classes.dividerFormControl} />

              {/* SELECT LOCATION */}
              <FormControl fullWidth disabled>
                <InputLabel className={classes.formControlLabel} shrink>
                  <IconRoom fontSize='small' />
                  <Typography className={classes.formControlTextLabel} variant='subtitle2'>Lokasi Kejadian</Typography>
                </InputLabel>

                <Select
                  defaultValue='location'
                  className={`${classes.formControlSelect} haveDialogMenu no-zoom`}
                  onClick={(event) => setInputLocationAnchorRef(inputLocationAnchorRef ? null : event.currentTarget)}
                >
                  <MenuItem value='location'>
                    <Stack className='zoom' direction='row' alignItems='center'>
                      <IconRoom className={classes.formControlItemIcon}/>
                      <Typography className={classes.formControlItemText} variant='subtitle2' noWrap>
                        {paramsFilter.location.name}
                      </Typography>
                    </Stack>
                  </MenuItem>
                </Select>
              </FormControl>

              {/* DIALOG MENU LOCATION */}
              <Menu
                open={Boolean(inputLocationAnchorRef)}
                anchorEl={inputLocationAnchorRef}
                className={`${classes.dialogMenu} no-zoom`}
                onClose={() => handleMenuClose(setSearchLocation, setInputLocationAnchorRef)}
                transformOrigin={{
                  horizontal: 'left',
                  vertical: 'top'
                }}
              >
                <DialogMenuFilter
                  className='zoom'
                  isHaveSearch
                  onInputSearchChange={event => setSearchLocation(event.target.value)}
                >
                  <List className={classes.listMenuLocation}>
                    <ListItemButton
                      selected={paramsFilter.location.type === 'global'}
                      onClick={() => handleAllAreaLocation()}
                    >
                      <ListItemIcon>
                        <IconLocationSearching />
                      </ListItemIcon>

                      <ListItemText primary='Seluruh Wilayah' />
                    </ListItemButton>

                    <ListItemButton
                      selected={paramsFilter.location.type === 'myLocation'}
                      onClick={() => getCurrentLocation()}
                    >
                      <ListItemIcon>
                        <IconMyLocation />
                      </ListItemIcon>

                      <ListItemText primary='Lokasi Saat Ini' />
                    </ListItemButton>

                    {paramsFilter.location.type === 'suggest' && (
                      <ListItemButton selected={true}>
                        <ListItemText primary={paramsFilter.location.name} />
                      </ListItemButton>
                    )}

                    {dataSuggestLocation
                      .filter(item => item.address !== paramsFilter.location.name)
                      .map((item, index) => (
                        <ListItemButton
                          key={index}
                          onClick={() => handleItemSuggestClick(item)}
                        >
                          <ListItemText primary={item.address} />
                        </ListItemButton>
                      ))}
                  </List>
                </DialogMenuFilter>
              </Menu>

              <Divider className={classes.dividerFormControl} />

              {/* CATEGORY */}
              {/* {viewType === 'internal' && (
                <FormControl fullWidth>
                  <InputLabel className={classes.formControlLabel} shrink>
                    <IconWysiwyg className={classes.formControlIconLabel} fontSize='small' />
                    <Typography className={classes.formControlTextLabel} variant='subtitle2'>Kategori</Typography>
                  </InputLabel> */}

              {/* SWITCH NEED HELP */}
              {/* <Stack marginBottom='16px' direction='row' flexWrap='nowrap' alignItems='center'>
                    <IconErrorOutline className={classes.formControlIconDangerCategory} fontSize='small'/>
                    <Typography flex={1} variant='subtitle2'>Butuh Bantuan</Typography>
                    <Switch
                      size='small'
                      checked={paramsFilter.isNeedHelp}
                      onClick={event => setParamsFilter({
                        ...paramsFilter, isNeedHelp: event.target.checked
                      })}
                    />
                  </Stack> */}

              {/* SWITCH COMPLETED */}
              {/* <Stack direction='row' flexWrap='nowrap' alignItems='center'>
                    <IconCheckCircle className={classes.formControlIconSuccessCategory} fontSize='small'/>
                    <Typography flex={1} variant='subtitle2'>Selesai</Typography>
                    <Switch
                      size='small'
                      checked={paramsFilter.isCompleted}
                      onClick={event => setParamsFilter({
                        ...paramsFilter, isCompleted: event.target.checked
                      })}
                    />
                  </Stack>
                </FormControl>
              )} */}

              {/* {viewType === 'internal' && <Divider className={classes.dividerFormControl} />} */}

              {/* TAG EVIDENCES */}
              <FormControl fullWidth>
                <InputLabel className={classes.formControlLabel} shrink>
                  <IconTagLabel className={classes.formControlIconLabel} fontSize='small' />
                  <Typography flex={1} className={classes.formControlTextLabel} variant='subtitle2'>Tag Kejadian</Typography>

                  <Box
                    className='no-zoom'
                    onClick={(event) => setTagEvidencesAnchorRef(tagEvidencesAnchorRef ? null : event.currentTarget)}
                    paddingRight='4px'
                  >
                    <IconButton
                      className={`${classes.iconButtonAdd} zoom`}
                      size='small'
                    >
                      <IconAdd className='zoom' />
                    </IconButton>
                  </Box>
                </InputLabel>

                {/* LIST SELECTED TAG EVIDENCES */}
                <List className={classes.listMenuSelected}>
                  {paramsFilter.tagEvidences?.map(item => {
                    const Icon = iconListTagEvidences[Number(item.iconId)-1]?.Icon
                    return (
                      <ListItem className={classes.listMenuItemSelectedTagEvidences} key={item.tagNo}>
                        <Chip
                          icon={<Icon fontSize='small' />}
                          className={classes.listMenuChipTagEvidences}
                          label={item.tagName}
                          variant='outlined'
                          sx={{
                            borderColor: item.tagColor,
                            color: item.tagColor,
                            backgroundColor: alpha(item.tagColor, 0.08),
                            '& .MuiSvgIcon-root': {
                              color: item.tagColor,
                            }
                          }}
                        />

                        <Stack flex={1} paddingLeft='8px' alignItems='flex-end'>
                          <IconButton
                            className={classes.iconDelete}
                            size='small'
                            onClick={() => handleDeleteTagsFilter(item.tagNo)}
                          >
                            <IconDelete />
                          </IconButton>
                        </Stack>
                      </ListItem>
                    )
                  })}
                </List>

                {!paramsFilter.tagEvidences.length && (
                  <Stack direction='row' alignItems='center' flexWrap='nowrap'>
                    <IconInfo className={classes.formControlIconInfo} fontSize='small' />
                    <Typography className={classes.formControlTextInfo} variant='caption'>
                      Data di tampilkan berdasarkan semua nilai yang tersedia. Pilih untuk menampilkan berdasarkan nilai tertentu
                    </Typography>
                  </Stack>
                )}
              </FormControl>

              {/* DIALOG MENU TAG EVIDENCES */}
              <Menu
                open={Boolean(tagEvidencesAnchorRef)}
                anchorEl={tagEvidencesAnchorRef}
                onClose={() => {
                  setDataTempTagEvidences([...paramsFilter.tagEvidences])
                  handleMenuClose(setSearchTags, setTagEvidencesAnchorRef)
                }}
                className={`${classes.dialogMenu} ${classes.dialogMenuBtnRight} no-zoom`}
                transformOrigin={{
                  horizontal: 'left',
                  vertical: 'top'
                }}
              >
                <DialogMenuFilter
                  isHaveSearch
                  className='zoom'
                  isHaveButtonAction
                  onButtonSaveClick={() => {
                    setParamsFilter({
                      ...paramsFilter,
                      tagEvidences: [...dataTempTagEvidences]
                    })
                    handleMenuClose(setSearchTags, setTagEvidencesAnchorRef)
                  }}
                  onButtonCancelClick={() => {
                    setDataTempTagEvidences([...paramsFilter.tagEvidences])
                    handleMenuClose(setSearchTags, setTagEvidencesAnchorRef)
                  }}
                  onInputSearchChange={event => setSearchTags(event.target.value)}
                >
                  <List className={classes.listMenuTagEvidences}>
                    {dataTagsList?.filter(item => item.tagName.includes(searchTags))?.map(item => {
                      const Icon = iconListTagEvidences[Number(item.iconId)-1]?.Icon
                      return (
                        <ListItem
                          className={classes.listMenuItemTagEvidences}
                          key={item.tagNo}
                          onClick={() => handleCheckedTags(item)}
                        >
                          <Checkbox checked={checkIsCheckedTags(item.tagNo)}/>
                          <Chip
                            icon={<Icon fontSize='small' />}
                            className={classes.listMenuChipTagEvidences}
                            label={item.tagName}
                            variant='outlined'
                            sx={{
                              borderColor: item.tagColor,
                              color: item.tagColor,
                              backgroundColor: alpha(item.tagColor, 0.08),
                              '& .MuiSvgIcon-root': {
                                color: item.tagColor,
                              }
                            }}
                          />
                        </ListItem>
                      )
                    })}
                  </List>
                </DialogMenuFilter>
              </Menu>

              {viewType === 'internal' && <Divider className={classes.dividerFormControl} />}

              {/* DEVICES */}
              {viewType === 'internal' && (
                <FormControl fullWidth>
                  <InputLabel className={classes.formControlLabel} shrink>
                    <IconPhoneAndroid className={classes.formControlIconLabel} fontSize='small' />
                    <Typography flex={1} className={classes.formControlTextLabel} variant='subtitle2'>Perangkat</Typography>

                    <Box
                      className='no-zoom'
                      onClick={(event) => setDevicesAnchorRef(devicesAnchorRef ? null : event.currentTarget)}
                      paddingRight='4px'
                    >
                      <IconButton
                        className={`${classes.iconButtonAdd} zoom`}
                        size='small'
                      >
                        <IconAdd className='zoom' />
                      </IconButton>
                    </Box>
                  </InputLabel>

                  {/* LIST SELECTED DEVICES */}
                  <List className={classes.listMenuSelected}>
                    {paramsFilter?.devices?.map(item => (
                      <ListItem className={classes.listMenuItemSelected} key={item.deviceNo}>
                        <IconPhoneAndroid className={classes.iconSelectedDevices} fontSize='large'/>
                        <Typography className={classes.textSelectedDevices} variant='subtitle2'>{item.label}</Typography>
                        <IconButton
                          size='small'
                          onClick={() => handleDeleteDevicesFilter(item.deviceNo)}
                        >
                          <IconClose className={classes.iconDeleteSelected} fontSize='small'/>
                        </IconButton>
                      </ListItem>
                    ))}
                  </List>

                  {!paramsFilter.devices.length && (
                    <Stack direction='row' alignItems='center' flexWrap='nowrap'>
                      <IconInfo className={classes.formControlIconInfo} fontSize='small' />
                      <Typography className={classes.formControlTextInfo} variant='caption'>
                        Data di tampilkan berdasarkan semua nilai yang tersedia. Pilih untuk menampilkan berdasarkan nilai tertentu
                      </Typography>
                    </Stack>
                  )}
                </FormControl>
              )}

              {/* DIALOG MENU DEVICES */}
              <Menu
                open={Boolean(devicesAnchorRef)}
                anchorEl={devicesAnchorRef}
                className={`${classes.dialogMenu} ${classes.dialogMenuBtnRight} no-zoom`}
                onClose={() => {
                  setDataTempDevices([...paramsFilter.devices])
                  handleMenuClose(setSearchDevices, setDevicesAnchorRef)
                }}
                transformOrigin={{
                  horizontal: 'left',
                  vertical: 'top'
                }}
              >
                <DialogMenuFilter
                  className='zoom'
                  isHaveButtonAction
                  onButtonSaveClick={() => {
                    setParamsFilter({
                      ...paramsFilter,
                      devices: [...dataTempDevices]
                    })
                    handleMenuClose(setSearchDevices, setDevicesAnchorRef)
                  }}
                  onButtonCancelClick={() => {
                    setDataTempDevices([...paramsFilter.devices])
                    handleMenuClose(setSearchDevices, setDevicesAnchorRef)
                  }}
                  customHeader={(
                    <Stack className={classes.dialogMenuHeader}>
                      <Typography variant='subtitle1' className={classes.dialogMenuTitleHeader}>Pilih Perangkat</Typography>
                      
                      <FormControl>
                        <OutlinedInput
                          className={classes.inputDevicesSearch}
                          placeholder='Pencarian'
                          endAdornment={
                            <InputAdornment position='end'>
                              <IconSearch />
                            </InputAdornment>
                          }
                          onChange={event => setSearchDevices(event.target.value)}
                        />
                      </FormControl>
                    </Stack>
                  )}
                >
                  <List className={classes.listMenuDevices}>
                    {dataDevicesList?.filter(item => item.label.includes(searchDevices))?.map(item => {
                      return (
                        <ListItem
                          className={classes.listMenuItemDevices}
                          key={item.id}
                          onClick={() => handleCheckedDevices(item)}
                        >
                          <Checkbox checked={checkIsCheckedDevices(item.deviceNo)}/>
                          <ListItemText primary={item.label}/>
                        </ListItem>
                      )
                    })}
                  </List>
                </DialogMenuFilter>
              </Menu>

            </Stack>
          </Stack>
        </Stack>

        {/* FOOTER */}
        <Stack className={classes.footer}>
          <Button
            variant='text'
            className={`${classes.buttonDialog} btnCancel`}
            onClick={() => handleButtonCancelClick()}
          >
            BATAL
          </Button>
          <Button
            variant='text'
            className={classes.buttonDialog}
            onClick={() => handleButtonSaveClick()}
          >
            TERAPKAN
          </Button>
        </Stack>
      </Stack>

      {/* DIALOG DATE RANGE PICKER */}
      <Dialog
        open={isDateTimeRangeOpen}
        onClose={() => setIsDateTimeRangeOpen(false)} 
        className={classes.dateRangeAndTimePickerDialog}
      >
        <DateRangeAndTimePicker
          value={paramsFilter.date}
          handleSelectButtonClick={handleSelectDateRangeButtonClick}
          handleCancelButtonClick={() => setIsDateTimeRangeOpen(false)}
        />
      </Dialog>
    </>
  )
}

export default PanelFilter