import { useState } from 'react'

// ASSETS
import PictureBrokenImage from 'assets/images/pictures/broken-image.svg'

// MUIS
import Box from '@mui/material/Box'

// STYLES
import useStyles from './carouselMediasUseStyles'

const CarouselMediaImage = (props) => {
  const { 
    carouselItem,
    variant, 
  } = props

  const classes = useStyles()

  let selectedMediaUrl = carouselItem.mediaUrl
  if (variant === 'small') selectedMediaUrl = carouselItem.mediaUrlSmall
  else if (variant === 'medium') selectedMediaUrl = carouselItem.mediaUrlMedium
  else if (variant === 'fullHeight') selectedMediaUrl = carouselItem.mediaUrlFullHeight

  const [ source, setSource ] = useState(selectedMediaUrl)

  return (
    <>
      {/* BLURRED BACKGROUND */}
      <Box
        sx={{ backgroundImage: `url(${selectedMediaUrl})` }}
        className={classes.carouselBlurredBackground}
      />
      
      {/* IMAGE */}
      <Box
        component='img'
        src={source ? source : PictureBrokenImage}
        alt=''
        onError={() => setSource(PictureBrokenImage)}
        className={classes.carouselItemVisual}
      />
    </>
  )
}

export default CarouselMediaImage