import { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'

// COMPONENTS
import DialogAddOrEdit from 'components/DialogAddOrEditNew/DialogAddOrEdit'

// CONSTANTS
import { basePaths } from 'constants/paths'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// CUSTOM COMPONENTS
import CustomFormControl from 'components/Customs/CustomFormControl'
import CustomInputNew from 'components/Customs/CustomInputNew'
import CustomInputLabelNew from 'components/Customs/CustomInputLabelNew'

// MUIS
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICON
import IconTextFields from '@mui/icons-material/TextFields'

// SERVICES
import { 
  postCreateNewChannel, 
  putEditChannel,
} from 'services/PushToTalkServices'

// STYLES
import useStyles from './dialogAddOrEditChannelUseStyles'

const DialogAddOrEditChannel = (props) => {
  const { 
    pageRef,
    mode,
    dialogAddOrEditChannel, 
    setMusReloadTableChannels,
  } = props

  const { setSnackbarObject } = useContext(AllPagesContext)

  const [ channelName, setChannelName ] = useState('')

  const classes = useStyles()

  const history = useHistory()

  const getDialogTitle = (dialogMode) => {
    if (dialogMode === 'add') return 'Tambah Kanal'
    else if (dialogMode === 'edit') return 'Ubah Kanal'
  }

  const handleSaveButtonClick = async () => {
    let result
    if (mode === 'add') result = await postCreateNewChannel({ groupName: channelName })  
    else if (mode === 'edit') result = await putEditChannel({
      groupId: dialogAddOrEditChannel.data.groupId,
      groupName: channelName,
    })
    
    if (result.status === 200 || result.status === 201) {
      setMusReloadTableChannels(true)
      
      let message
      if (mode === 'add') message = 'Sukses Membuat Kanal Baru'
      else if (mode === 'edit') message = 'Sukses Mengubah Data Kanal'

      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message,
      })

      history.push(basePaths.pushToTalks)
    }
  }

  useEffect(() => {
    if (mode === 'edit' && dialogAddOrEditChannel) setChannelName(dialogAddOrEditChannel.data.groupName)
  }, [dialogAddOrEditChannel, mode])

  return (
    <DialogAddOrEdit
      open={Boolean(dialogAddOrEditChannel)}
      title={getDialogTitle(mode)}
      isExpandButtonAvailable={false}
      onCloseIconClick={() => history.push(basePaths.pushToTalks)}
      containerRef={pageRef}
      className=''
      contents={[
        <Stack>
          {/* SECTION TITLE */}
          <Typography 
            variant='h6'
            className={classes.sectionTitle} 
          >
            Informasi
          </Typography>

          {/* CHANNEL NAME */}
          <CustomFormControl variant='filled' className={classes.formControl}>
            {/* INPUT LABEL */}
            <CustomInputLabelNew shrink={true}>
              {/* ICON */}
              <IconTextFields/>

              {/* TEXT */}
              <Typography variant='subtitle1'>
                Nama Kanal
              </Typography>
            </CustomInputLabelNew>

            {/* INPUT */}
            <CustomInputNew
              disableUnderline
              placeholder='Masukkan Nama Kanal'
              value={channelName}
              onChange={event => setChannelName(event.target.value)}
            />
          </CustomFormControl>
        </Stack>
      ]}
      onCancelButtonClick={() => history.push(basePaths.pushToTalks)}
      onSaveButtonClick={handleSaveButtonClick}
    />
  )
}

export default DialogAddOrEditChannel