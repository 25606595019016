import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

// AGORA
import AgoraRTC from 'agora-rtc-sdk-ng'
import AgoraRTM from 'agora-rtm-sdk'

// COMPONENTS
import SimpleBackdrop from 'components/Partials/SimpleBackdrop'
import VideoPlayer from './VideoPlayer/VideoPlayer'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'
import CustomInput from 'components/Customs/CustomInput'

// MUIS
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Slide from '@mui/material/Slide'
import Typography from '@mui/material/Typography'
import Grow from '@mui/material/Grow'
import Button from '@mui/material/Button'

// MUI ICONS
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import MicOffIcon from '@mui/icons-material/MicOff'
import MicIcon from '@mui/icons-material/Mic'
import VideocamIcon from '@mui/icons-material/Videocam'
import VideocamOffIcon from '@mui/icons-material/VideocamOff'
import GroupIcon from '@mui/icons-material/Group'
import CallEndIcon from '@mui/icons-material/CallEnd'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

// STYLES
import useStyles from './liveConferenceRoomUseStyles'

// UTILITIES
import { getCompanyLogo, guidGenerator } from 'utilities'

// SERVICE 
import {getConferenceList, postConferenceCreate, putConferenceLeave } from 'services/ConferenceService'

function useQuery() {
  const { search } = useLocation()
  
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const LiveConferenceRoom = () => {
  const classes = useStyles()
  const parseLocalStorage = JSON.parse(localStorage.getItem('user'))

  const [isMicOn,setIsMicon] = useState(true)
  const [isCameraOn, setIsCameraOn] = useState(true)
  const [search, setSearch] = useState('')
  const [isListViewExpand, setIsListViewExpand] = useState(true)
  const [isParticipantShown, setIsParticipantShown] = useState(true)
  const [triggerReplayVideo, setTriggerReplayVideo] = useState(true)
  const [hostConference, setHostConference] = useState([])
  const [duration, setDuration] = useState(0)
  const [listParticipant,setListParticipant] = useState([])
  const [listParticipantName, setListParticipantName] = useState([])
  const [mainScreen, setMainScreen] = useState([])
  const [isMainScreenPlayed, setIsMainScreenPlayed] = useState(false)
  const [localCLient, setLocalCLient] = useState([{
    uid : parseLocalStorage.userName
  }])
  const [message,setMessage] = useState(null)
  const [isConferenceStarted, setIsConferenceStarted] = useState(false)

  const urlQuery = useQuery()
  const channel = urlQuery.get('id')
  const myName = parseLocalStorage?.userName
  const mainScreenRef = useRef()
  const computerVideoRef = useRef()
  const computerVideoTrackRef = useRef()
  const computerMicTrackRef = useRef()
  const agoraAppId = process.env.REACT_APP_AGORA_APP_ID
  
  const closeTab = () => {
    window.opener = null
    window.open('', '_self')
    window.close()
  }

  const agoraRTCRef = useMemo(() => ({
    current: AgoraRTC.createClient({
      mode: 'rtc',
      codec: 'vp8',
    })
  }), [])

  const agoraRTM = useMemo(() => {
    return AgoraRTM.createInstance(agoraAppId)
  }, [])

  let rtmChannelRef = useRef()
  const rtmChannel = rtmChannelRef.current
  const setRtmChannel = value => {
    rtmChannelRef.current = value
  }

  // HANDLE SEARCH BAR FOR LIST PARTICIPANT
  const filterListParticipants = [...listParticipantName].filter((item)=>{
    if(search === '') return item 
    else return item.uid.toLowerCase().includes(search.toLowerCase())
  })
  
  // HANDLE SEARCH BAR FOR LOCAL CLIENT
  const filterLocalClient = [...localCLient].filter((item)=>{
    if(search === '') return item
    else return item.uid.toLowerCase().includes(search.toLowerCase())
  })

  // HANDLE LEFT PANEL LOCAL VIDEO CLICK
  const handleLocalVideoCLick = () => {
    setIsMainScreenPlayed(false)
    setListParticipant(agoraRTCRef.current.remoteUsers)
    setMainScreen([])
    setTriggerReplayVideo(current => !current)
  }

  // JOIN RTM CHANNEL
  const joinRtmChannel = async () => {
    let response = await fetch(`${process.env.REACT_APP_API_MOBILE_URL}/devices/generate-agora-rtm-token?userName=${myName}`, {
      method: 'GET',
      mode: 'cors',
      headers: {userName: myName}
    })
    let rtmToken = (await response.json()).token
    await agoraRTM.login({uid: myName, token: rtmToken})
    let rtmChannel = agoraRTM.createChannel(channel)
    setRtmChannel(rtmChannel)
    await rtmChannel.join()
    
    rtmChannel.on('ChannelMessage', (item) => {
      setMessage(item)
    })
  }

  // JOIN RTC CHANNEL
  const joinRtcChannel = async () => {
    setIsConferenceStarted(false)
  
    localStorage.setItem('isUserJoinRoomMeet', JSON.stringify(true))

    const rtcTokenResponse = await fetch(
      `${process.env.REACT_APP_API_MOBILE_URL}/devices/generate-agora-token?channelId=${channel}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: { deviceCode: 0 }, 
      }
    )

    const rtcTokenResponseJson = await rtcTokenResponse.json()
    const rtcToken = rtcTokenResponseJson.token

    agoraRTCRef.current
      .join(agoraAppId, channel, rtcToken, parseLocalStorage?.userName)
      .then(async () => {
        const [microphoneTrack, cameraTrack] = await AgoraRTC.createMicrophoneAndCameraTracks()
        await agoraRTCRef.current.publish([cameraTrack, microphoneTrack])
        cameraTrack.play(computerVideoRef.current)
        computerVideoTrackRef.current = cameraTrack
        computerMicTrackRef.current = microphoneTrack
        setIsConferenceStarted(true)
      })
    
    agoraRTCRef.current.on('user-published', async (user, mediaType) => {
      if (mediaType === 'video') {
        agoraRTCRef.current.subscribe(user, 'video').then((track) => {
          setTriggerReplayVideo(current => !current)
        })
      }
      if (mediaType === 'audio') {
        agoraRTCRef.current.subscribe(user, 'audio').then((track) => {
          track.play()
        })
      }
    })

    agoraRTCRef.current.on('user-unpublished', async (user, mediaType) => {
      if (mediaType === 'audio') {
        if(user.audioTrack) user.audioTrack.stop()
      }
    })
  }

  const handleBeforeUnloaded = (event) => {
    localStorage.setItem('isUserJoinRoomMeet', JSON.stringify(false))

    if(!JSON.parse(localStorage.getItem('meetRoomClosed'))) {
      const bodyParams = {
        ...JSON?.parse(localStorage.getItem('ownerRoomDetail')),
        totalParticipant: agoraRTCRef.current.remoteUsers?.length,
      }
      localStorage.setItem('ownerRoomDetail', JSON.stringify(bodyParams))
      localStorage.setItem('meetRoomClosed', JSON.stringify(true))
    }
    return ''
  }
  
  const handleUnloaded = () => {
    
  }

  // HANDLE LEAVE MEETING
  const handleLeaveStopConference = async () => {
    const bodyParams = {
      active: hostConference.active,
      channelId: hostConference.channelId ,
      hostUid:hostConference.hostUid,
      id: hostConference.id,
      type: hostConference.type
    }

    localStorage.setItem('ownerRoomDetail', JSON.stringify({
      ...JSON?.parse(localStorage.getItem('ownerRoomDetail')),
      totalParticipant: agoraRTCRef.current.remoteUsers?.length,
    }))
    localStorage.setItem('meetRoomClosed', JSON.stringify(true))

    const result = await putConferenceLeave(bodyParams)
    
    if(result) {
      agoraRTCRef.current.unpublish()
      computerVideoTrackRef?.current?.close()
      computerMicTrackRef?.current?.close()
      agoraRTCRef.current.removeAllListeners()
      agoraRTCRef.current.leave()
      closeTab()
    }
  }

  const createConference = async () => {
    const response = await postConferenceCreate({
      channelId: guidGenerator(),
      type: 'VIDEO_CONFERENCE',
      participantLimit: 100
    })

    const bodyParams = {
      active: response.data.active,
      channelId: response.data.channelId ,
      hostUid:response.data.hostUid,
      id: response.data.id,
      type: response.data.type,
      totalParticipant: 0,
    }

    localStorage.setItem('ownerRoomDetail', JSON.stringify(bodyParams))
    localStorage.setItem('isUserJoinRoomMeet', JSON.stringify(true))
    localStorage.setItem('meetRoomClosed', JSON.stringify(false))
  }

  const getHostConference = async () => {
    const response = await getConferenceList()
    const findHost = response.find((item) => item.channelId === urlQuery.get('id'))

    if (findHost) {
      setHostConference(findHost)
    } else {
      // auto re-create meeting when refresh tab (host user only)
      if (parseLocalStorage?.email === channel) {
        await createConference()
      } else {
        window.close()
      }
    }
  }

  // GET DETAIL CONFERENCE HOST TO LEAVE THE MEETING
  useEffect(() => {
    getHostConference()
  }, [])

  // JOIN RTM CHANNEL
  useEffect(() => {
    joinRtmChannel()

    return async () => {
      await rtmChannel.leave()
    }
  }, [])
  
  // JOIN RTC CHANNEL & HANDLE WHILE LEAVE THE MEETING
  useEffect(() => {
    joinRtcChannel()

    window.addEventListener('beforeunload', handleBeforeUnloaded)
    window.addEventListener('unload', handleUnloaded)

    return async () => {
      window.removeEventListener('beforeunload', handleBeforeUnloaded)
      window.removeEventListener('unload', handleUnloaded)
      await agoraRTCRef.current.unpublish()
      computerVideoTrackRef.current.close()
      computerMicTrackRef.current.close()
      await agoraRTCRef.current.removeAllListeners()
      await agoraRTCRef.current.leave()
      listParticipant.forEach((item) => {
        item.videoTrack?.stop()
        item.audioTrack?.stop()
      })
    }
  }, [])

  // HANDLE LOCAL CAMERA
  useEffect(()=>{
    return async () => {
      if(isCameraOn){
        computerVideoTrackRef.current?.setEnabled(false)
      } else computerVideoTrackRef.current?.setEnabled(true)
    }
  },[isCameraOn])
    
  // HANLDE LOCAL MICROFON
  useEffect(()=>{
    return async () => {
      if(isMicOn){
        computerMicTrackRef.current?.setEnabled(false)
      } else computerMicTrackRef.current?.setEnabled(true)
    }
  },[isMicOn])

  // HANDLE MUTE LOCAL AUDIO & CAMERA WITH RTM
  useEffect(()=>{
    if(message?.text === `[${myName}] MUTEVIDEO`){
      setIsCameraOn(false)
    } else if(message?.text === `[${myName}] TURNONVIDEO`) {
      setIsCameraOn(true)
    } else if(message?.text === `[${myName}] MUTEAUDIO`) {
      setIsMicon(false)
    } else if(message?.text === `[${myName}] TURNONAUDIO`) {
      setIsMicon(true) 
    } else if (message?.text === `[${myName}] OUT`) {
      handleLeaveStopConference()
    }
  },[message])

  // HANDLE MUTE PARTICIPANT MIC WITH RTM
  const handleMuteParticipantMic = async (message) => {
    await rtmChannel.sendMessage({text : message})
  }

  // HANDLE MUTE PARTICIPANT CAMERA WITH RTM
  const handleMuteParticipantCamera = async (message) => {
    await rtmChannel.sendMessage({text : message})
  }

  // HANDLE MUTE LOCAL AUDIO 
  const handleMuteLocalAUdio = async (message) => {
    if(message.includes('audio mute')) {
      setIsMicon(false)
      await rtmChannel.sendMessage({text : message})
    } else {
      setIsMicon(true)
      await rtmChannel.sendMessage({text : message})
    }
  }

  // HANDLE MUTE LOCAL CAMERA
  const handleMuteLocalCamera = async (message) => {
    if(message.includes('video mute')) {
      setIsCameraOn(false)
      await rtmChannel.sendMessage({text : message})
    } else {
      setIsCameraOn(true)
      await rtmChannel.sendMessage({text : message})
    }
  }

  // HANDLE KICK PARTICIPANT DIALOG
  const handleDialogKickParticipant = (inputItem) => {
    let newListParticipant = [...filterListParticipants]
    newListParticipant.map((item)=> {
      if(item.uid === inputItem.uid) item.isKick = true
      return item
    })
    setListParticipantName(newListParticipant)
  }

  // HANDLE CANCEL KICK PARTICIPANT
  const handleCancleKickParticipant = (inputItem) => {
    let newListParticipant = [...filterListParticipants]
    newListParticipant.map((item)=> {
      if(item.uid === inputItem.uid) item.isKick = false
      return item
    })
    setListParticipantName(newListParticipant)
  }

  // HANDLE KICK PARTICIPANT WITH RTM
  const handleKickParticipant = async (message) => {
    await rtmChannel.sendMessage({text : message})
    handleClientJoinAndLeave()
  }

  // GET ALL PARTICIPANTS
  useEffect(() => {
    setListParticipant(agoraRTCRef.current.remoteUsers)
    setListParticipantName(agoraRTCRef.current.remoteUsers)
  }, [agoraRTCRef.current.remoteUsers])

  // HANDLE PLAY VIDEO ON MAIN SCREEN
  useEffect(() => {
    computerVideoTrackRef?.current?.play(computerVideoRef.current)
    
    if(mainScreen && isMainScreenPlayed) {
      mainScreen?.videoTrack?.play(mainScreenRef.current)
    } 

  }, [mainScreen])
  
  // HANDLE BUG (IF isMainScreenPlayed === TRUE) CLIENT STUCK WHILE LEAVE THE MEETING AND HANDLE BUG CLIENT NOT SHOW WHILE JOINT THE MEETING 
  const handleClientJoinAndLeave = () => {
    const user = agoraRTCRef.current.remoteUsers
    setListParticipant([...user].filter((item) => item.uid !== mainScreen.uid))
    setListParticipantName(user)
  }

  // HANDLE BUG (IF isMainScreenPlayed === TRUE) CLIENT STUCK WHILE LEAVE THE MEETING AND HANDLE BUG CLIENT NOT SHOW WHILE JOINT THE MEETING 
  useEffect(() => {
    if(listParticipantName.length !== listParticipant.length){
      handleClientJoinAndLeave()
    }
    const user = agoraRTCRef.current.remoteUsers
    listParticipant.forEach((item) => {
      user.forEach((userItem) => {
        if(item.uid !== userItem.uid){
          handleClientJoinAndLeave()
        }
      })
    })
  }, [duration])

  useEffect(() => {
    let durationInterval
    if (isConferenceStarted) {
      // GET RTC STATS TO SHOW MEETING DURATION
      durationInterval = setInterval(() => {
        setDuration(agoraRTCRef.current.getRTCStats().Duration)
      }, 1000)
    }

    return () => clearInterval(durationInterval)
  }, [isConferenceStarted])

  return (
    <Box className={classes.pageRoot}>
      <Box className={classes.mainContainer}>

        {/* CONTENT LEFT */}
        <Box className={classes.contentLeft}
          sx={{width : isParticipantShown ? '80%' : '100%'}}
        >

          {/* HEADER */}
          <Box className={classes.header}>
            {/* LIST VIEW TEXT */}
            <Box className={classes.listView}>
              {/* TEXT */}
              <Typography className={classes.listViewTitle}>
                List View
              </Typography>
              {/* ICON */}
              <IconButton className={classes.arrowIconButton} onClick={()=> setIsListViewExpand(current => !current)}>
                {isListViewExpand ? 
                  <ArrowBackIosIcon className={classes.arrowIcon} sx={{marginLeft : '5px'}}/> : 
                  <ArrowForwardIosIcon className={classes.arrowIcon}/> }
              </IconButton>
            </Box>
            {/* ROOM NAME */}
            <Box className={classes.roomName}>
              {/* COMPANY LOGO */}
              <Box
                component='img'
                src={getCompanyLogo('light')}
                alt=''
                className={classes.logoCompany}
              />
              {/* ROOM TITLE */}
              <Box className={classes.roomTitleContainer}>
                <Box className={classes.roomTitleWrapper}>
                  <Typography className={classes.roomTitle}>
                    {channel}
                  </Typography>
                  <CustomTooltipBlack
                    title={`Room Name: ${channel}`}
                    placement='bottom'
                  >
                    <IconButton
                      size='medium'
                      sx={{ ml: 1 }}
                      className={classes.iconButtonArrowDropDown}
                    >
                      <ArrowDropDownIcon className={classes.arrowDropDown} />
                    </IconButton>
                  </CustomTooltipBlack>
                </Box>
                <Typography className={classes.timeDuration}>
                  {moment.utc(duration * 1000).format('HH:mm:ss')}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* CONTENT */}
          <Box className={classes.content}>
            {/* LEFT PANEL VIDEO PLAYER */}
            {isListViewExpand && 
            <Slide direction='right' in={isListViewExpand} mountOnEnter unmountOnExit>
              <Box className={classes.videoPlayer}>
                {/* LEFT PANEL LOCAL VIDEO PLAYER */}
                {isMainScreenPlayed && <Grow in={isMainScreenPlayed} timeout={800}>
                  <Box onClick={handleLocalVideoCLick} className={classes.localVideoPlayerPageRoot}>
                    <Box 
                      ref={(el) => (computerVideoRef.current = el)} 
                      className={classes.localVideoPlayerContentContainer}/>
                    <Box className={classes.localVideoPlayerUserName}>
                      <Typography className={classes.localVideoPlayerUserNameText}>
                        {parseLocalStorage.userName}
                      </Typography>
                    </Box>
                  </Box>
                </Grow>
                }

                {/* LEFT PANEL PATICIPANTS VIDEO PLAYER */}
                {listParticipant?.map((item,index)=>(
                  <Box key={index}>
                    <VideoPlayer video={item} 
                      triggerReplayVideo={triggerReplayVideo} 
                      setTriggerReplayVideo={setTriggerReplayVideo}
                      setMainScreen={setMainScreen} 
                      setListParticipant={setListParticipant}
                      isMainScreenPlayed={isMainScreenPlayed}
                      setIsMainScreenPlayed={setIsMainScreenPlayed}
                      remoteUsers={agoraRTCRef.current.remoteUsers} />
                  </Box>
                ))}
              </Box> 
            </Slide>}
            
            {/* MAIN SCREEN */}
            {isMainScreenPlayed && <Box className={classes.mainScreen}
              sx={{width : isListViewExpand ? '88%' : '100%'}}>
              <Box
                ref={(el) => (mainScreenRef.current = el)}
                className={classes.mainScreenSize}
              />
              <Box className={classes.mainScreenInfo}>
                <IconButton className={classes.micIconButon}>
                  {mainScreen?.audioTrack?.isPlaying ?
                    <MicIcon className={classes.micIcon}/> : 
                    <MicOffIcon className={classes.micIcon}/> }
                </IconButton>
                <Box className={classes.mainScreenTitleContianer}>
                  <Typography className={classes.mainScreenText}>
                    {mainScreen.uid}
                  </Typography>
                </Box>
              </Box>
            </Box>}
            
            {/* MAiN SCREEN LOCAL */}
            {!isMainScreenPlayed && <Box className={classes.mainScreen}
              sx={{width : isListViewExpand ? '88%' : '100%'}}>
              <Box
                ref={(el) => (computerVideoRef.current = el)}
                className={classes.mainScreenSize}
              />
              <Box className={classes.mainScreenInfo}>
                <IconButton className={classes.micIconButon}>
                  {isMicOn ?
                    <MicIcon className={classes.micIcon}/> : 
                    <MicOffIcon className={classes.micIcon}/> }
                </IconButton>
                <Box className={classes.mainScreenTitleContianer}>
                  <Typography className={classes.mainScreenText}>
                    {parseLocalStorage.userName}
                  </Typography>
                </Box>
              </Box>
            </Box>}

          </Box>

          {/* FOOTER */}
          <Box className={classes.footer}>
            <Box className={classes.footerIconContainer}>
              {/* MIC ICON */}
              {isMicOn ? 
                <IconButton 
                  className={classes.footerIconButton}
                  onClick={() => handleMuteLocalAUdio(`[${myName}] audio mute`)}>
                  <MicIcon className={classes.footerIconMic}/>
                </IconButton> :
                <IconButton 
                  className={classes.footerIconButton} 
                  onClick={() => handleMuteLocalAUdio(`[${myName}] audio turnon`)}>
                  <MicOffIcon className={classes.footerIconMic}/>
                </IconButton> }
              
              {/* CAMERA ICON */}
              {isCameraOn ? 
                <IconButton
                  className={classes.footerIconButton}
                  onClick={() => handleMuteLocalCamera(`[${myName}] video mute`)}>
                  <VideocamIcon className={classes.footerIconMic}/>
                </IconButton> : 
                <IconButton
                  className={classes.footerIconButton}
                  onClick={() => handleMuteLocalCamera(`[${myName}] video turnon`)}>
                  <VideocamOffIcon className={classes.footerIconMic}/>
                </IconButton> }
              
              {/* GROUP ICON */}
              <IconButton 
                className={classes.footerGroupIconButton} 
                onClick={()=> setIsParticipantShown(current => !current)}>
                <GroupIcon className={classes.footerIconMic}/>
              </IconButton>
              {/* CALL END BUTTON */}
              <IconButton 
                className={classes.footerCallIconButton} 
                onClick={() => handleLeaveStopConference()}>
                <CallEndIcon className={classes.footerIconMic}/>
              </IconButton>
            </Box>
          </Box>
        </Box>

        {/* CONTENT RIGHT */}
        {isParticipantShown &&
        <Slide direction='left' in={isParticipantShown} mountOnEnter unmountOnExit>
          <Box className={classes.contenRight}>
            {/* PARTICIPANT TITLE */}
            <Box className={classes.participant}>
              <Typography className={classes.listViewTitle}>
                Peserta
              </Typography>
              {/* ICON CLOSE */}
              <IconButton 
                className={classes.arrowIconButton} 
                onClick={() => setIsParticipantShown(current => !current)}>
                <CloseIcon/>
              </IconButton>
            </Box>
            {/* SEARCH BAR */}
            <Divider className={classes.didvider}/>
            <Box className={classes.searchBarContainer}>
              <CustomInput
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                className={classes.menuSearchInput}
                placeholder='Cari Peserta'
                disableUnderline
                startAdornment={
                  <InputAdornment position='start'>
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>}
              />
            </Box>
            <Divider className={classes.didvider}/>

            {/* LIST PARTICIPANTS */}
            <Box 
              className={classes.listParticipantContiner}>
              {/* LOCAL CLIENT */}
              {filterLocalClient.length > 0 && <Box className={classes.listParticipant}>
                <Typography className={classes.localCLient}>
                  {filterLocalClient[0]?.uid}
                </Typography>

                {/* ICON GROUP */}
                <Box className={classes.parcticipantIcongroup}>
                  {/* ICON MIC */}
                  {isMicOn ? 
                    <IconButton 
                      className={classes.participantIconButton} 
                      onClick={() => handleMuteLocalAUdio(`[${myName}] audio mute`)}>
                      <MicIcon className={classes.participantIcon}/>
                    </IconButton> :
                    <IconButton 
                      className={classes.participantIconButton} 
                      onClick={() => handleMuteLocalAUdio(`[${myName}] audio turnon`)}>
                      <MicOffIcon className={classes.participantIcon}/>
                    </IconButton>
                  }
                    
                  {/* ICON CAMERA */}
                  {isCameraOn ? 
                    <IconButton className={classes.participantIconButton}>
                      <VideocamIcon 
                        className={classes.participantIcon} 
                        onClick={() => handleMuteLocalCamera(`[${myName}] video mute`)}/>
                    </IconButton> :
                    <IconButton 
                      className={classes.participantIconButton} 
                      onClick={() => handleMuteLocalCamera(`[${myName}] video turnon`)}>
                      <VideocamOffIcon className={classes.participantIcon}/>
                    </IconButton>
                  }
                </Box>
              </Box>}
              
              {/* LIST PARTICPANTS */}
              {filterListParticipants.map((item,index)=>(
                <Box key={index}>
                  {/* LIST NAME */}
                  <Box className={classes.listParticipant} >
                    {/* PACRTICIPANT NAME */}
                    <Typography className={classes.participantName}>
                      {item.uid}
                    </Typography>
                    {/* ICON GROUP */}
                    <Box className={classes.parcticipantIcongroup}>
                      {/* ICON MIC */}
                      {item.audioTrack?.isPlaying ? 
                        <IconButton 
                          className={classes.participantIconButton} 
                          onClick={() => handleMuteParticipantMic(`[${item.uid}] MUTEAUDIO`)}>
                          <MicIcon className={classes.participantIcon}/>
                        </IconButton> :
                        <IconButton 
                          className={classes.participantIconButton} 
                          onClick={() => handleMuteParticipantMic(`[${item.uid}] TURNONAUDIO`)}>
                          <MicOffIcon className={classes.participantIcon}/>
                        </IconButton>
                      }
                    
                      {/* ICON CAMERA */}
                      {item.videoTrack?.isPlaying ? 
                        <IconButton 
                          className={classes.participantIconButton}
                          onClick={() => handleMuteParticipantCamera(`[${item.uid}] MUTEVIDEO`)}>
                          <VideocamIcon className={classes.participantIcon} />
                        </IconButton> :
                        <IconButton 
                          className={classes.participantIconButton} 
                          onClick={() => handleMuteParticipantCamera(`[${item.uid}] TURNONVIDEO`)}>
                          <VideocamOffIcon className={classes.participantIcon}/>
                        </IconButton>
                      }
                    
                      {/* PERSON REMOVE ICON */}
                      <IconButton 
                        className={classes.personRemoveIcon} 
                        onClick={() => handleDialogKickParticipant(item)}>
                        <PersonRemoveIcon className={classes.participantIcon}/>
                      </IconButton>
                    </Box>
                  </Box>

                  {/* DIALOG KICK PARTICIPANT */}
                  {item?.isKick && <Box className={classes.dialogKickContainer}>
                    <Typography className={classes.dialogKickTitle}>
                      Konfirmasi
                    </Typography>
                    <Typography className={classes.dialogKickValue}>
                      {`Apakah Anda yakin ingin mengeluarkan ${item.uid} ?`}
                    </Typography>

                    {/* CANCEL BUTTON */}
                    <Button 
                      className={classes.dialogKickCancelBtn} 
                      size='sm' 
                      variant='plain'
                      onClick={() => handleCancleKickParticipant(item)}
                    >
                      Batal
                    </Button>

                    {/* ACCEPT BUTTON */}
                    <Button 
                      className={classes.dialogKickOkeBtn} 
                      size='sm' 
                      variant='plain'
                      onClick={() => handleKickParticipant(`[${item.uid}] OUT`)}
                    >
                      Iya
                    </Button>
                  </Box>}
                </Box>
              ))}
            </Box>
          </Box>
        </Slide>}
      </Box>

      <SimpleBackdrop isLoading={!isConferenceStarted}/>
    </Box>
  )
}

export default LiveConferenceRoom