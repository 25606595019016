import React, { useEffect, useRef } from 'react'

// MUIS
import { Box, Typography, Grow } from '@mui/material'

// STYLES
import useStyles from './videoPlayerUseStyles'

const VideoPlayer = (props)=> {
  const {video,
    triggerReplayVideo, 
    setMainScreen, 
    setListParticipant,
    remoteUsers,
    setIsMainScreenPlayed,
    setTriggerReplayVideo} = props
  const classes = useStyles()
  const videoTrack = useRef()

  const handleCLickDetailVideo = () => {
    setMainScreen(video)
    setIsMainScreenPlayed(true)
    setListParticipant(
      [...remoteUsers].filter((item)=> item.uid !== video.uid)
    )
    setTriggerReplayVideo(current => !current)
  }

  const playVideo = () => {
    video?.videoTrack?.play(videoTrack.current)
  }

  useEffect(() => {
    playVideo()
  }, [triggerReplayVideo])
  
  return (
    <Grow timeout={800} in={video}>
      <Box className={classes.pageRoot} onClick={handleCLickDetailVideo}>
        <Box 
          ref={(el) => (videoTrack.current = el)} 
          className={classes.contentContainer}/>
        <Box className={classes.userName}>
          <Typography className={classes.userNameText}>
            {video.uid}
          </Typography>
        </Box>
      </Box>
    </Grow>
  )
}

export default VideoPlayer