import { useState, useContext } from 'react'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import { Alert } from '@mui/material'
import { setToken, setUser } from 'utilities/auth'
import axios from 'axios'
import { getLoggedUserInfo } from 'services/UserService'
import { toast } from 'utilities'
import { InputAdornment } from '@mui/material'
import { Email, Lock, RemoveRedEye, VisibilityOff } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { getCompanyLogo, getCompanyTitle } from 'utilities'
import { AllPagesContext } from 'contexts/AllPagesContext'
import { AuthLayoutContext } from 'contexts/AuthLayoutContext'

// function Copyright(props) {
//   return (
//     <Typography
//       variant='body2'
//       color='text.secondary'
//       align='center'
//       {...props}
//     >
//       {'Copyright 2021 Onedata. All right reserved. '}
//     </Typography>
//   )
// }

const theme = createTheme({
  palette: {
    primary: {
      main: '#EF6C00',
      darker: '#ed7512',
    },
    default: {
      main: '#FFF',
      darker: '#FFF',
    },
    action: {
      disabledBackground: 'rgb(239, 108, 0, 0.4)',
      disabled: '#FFFFFF',
    },
    typography: {
      fontFamily: '"Open Sans", "Helvetica Neue", "Helvetica", Arial, sans-serif',
    },
  },
  components: {
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: 14,
        },
      },
    },
    MuiInput: {
      defaultProps: {
        sx: {
          fontSize: 14,
        },
      },
    },
  },
})

export default function Login() {
  const history = useHistory()
  const [isError, setIsError] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const { setUserProfile } = useContext(AllPagesContext)
  const { setIsLoading } = useContext(AuthLayoutContext)

  const { handleSubmit, errors, handleChange, values } = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: object({
      // email: string().email('Invalid email').required('Email is required'),
      username: string().required('Wajib mengisi email'),
      password: string().required('Wajib mengisi kata sandi'),
    }),
    onSubmit: async (values) => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        }
        const body = {
          username: values.username.trim(),
          password: values.password
        }

        setIsLoading(true)

        const { data } = await axios.post(
          process.env.REACT_APP_KEYCLOAK_URL,
          body,
          config
        )
        setToken(data.access_token)

        const user = await getLoggedUserInfo()
        if (user.status === 'error') {
          toast({ title: 'Tidak dapat mengambil informasi pengguna', icon: 'error' }).then(
            () => {}
          )
          return
        }

        setUser(user)
        setUserProfile(user)

        const traccarConfig = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        }
        // Login traccar
        const paramsTraccar = new URLSearchParams()
        paramsTraccar.append('email', 'admin')
        paramsTraccar.append('password', 'valid123')

        await axios.post(
          'https://gps.voc.dev/api/session',
          paramsTraccar,
          traccarConfig
        )

        // reset meetRoomClosed and isUserJoinRoomMeet
        localStorage.setItem('meetRoomClosed', JSON.stringify(false))
        localStorage.setItem('isUserJoinRoomMeet', JSON.stringify(false))
        history.push('/')
      } catch (e) {
        setIsError(true)
      } finally {
        setIsLoading(false)
      }
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <Container
        component='main'
        maxWidth='sm'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          minHeight: '100vh',
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '1px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '30px',
            }}
          >
            <img
              src={getCompanyLogo('dark')}
              alt='logo'
              style={{ width: 57 }}
            />
            <Typography
              component='h1'
              variant='h5'
              sx={{
                marginTop: '13px',
                marginLeft: '10px',
                color: '#3F4254',
                fontWeight: 600,
              }}
            >
              {getCompanyTitle()}
            </Typography>
          </Box>

          {isError && (
            <Alert
              severity='error'
              icon={false}
              sx={{ marginBottom: '1rem', width: '100%' }}
            >
              Email atau password salah.
            </Alert>
          )}
          <form
            onSubmit={handleSubmit}
            style={{
              borderRadius: '4px',
              boxShadow:
                '-1px 10px 10px rgba(0, 0, 0, 0.05), 1px 12px 30px rgba(0, 0, 0, 0.1), 0px 2px 5px rgba(0, 0, 0, 0.1)',
              padding: '30px',
              width: '80%',
            }}
          >
            <Typography
              component='h5'
              variant='h5'
              sx={{
                marginBottom: '10px',
                textAlign: 'center',
                color: theme.palette.text.primary,
                fontWeight: '600',
                fontSize: '20px',
                lineHeight: '27.24px',
              }}
            >
              Selamat Datang
            </Typography>
            <Typography
              component='p'
              variant='p'
              sx={{
                marginBottom: '25px',
                textAlign: 'center',
                color: theme.palette.text.secondary,
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '19.07px',
              }}
            >
              Masukkan email dan kata sandi Anda untuk masuk
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <Email
                sx={{
                  color: '#0000008A',
                  mr: 2,
                  mt: !errors.username ? 3 : 0,
                  alignSelf: errors.username ? 'center' : '',
                }}
              />
              <TextField
                error={!!errors.username}
                margin='normal'
                required
                fullWidth
                id='email'
                label='Email'
                name='username'
                autoComplete='email'
                autoFocus
                helperText={errors.username}
                onChange={handleChange}
                variant='standard'
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <Lock
                sx={{
                  color: '#0000008A',
                  mr: 2,
                  mt: !errors.password ? 3 : 0,
                  alignSelf: errors.password ? 'center' : '',
                }}
              />
              <TextField
                error={!!errors.password}
                helperText={errors.password}
                margin='normal'
                required
                fullWidth
                name='password'
                label='Kata Sandi'
                type={showPassword ? 'text' : 'password'}
                id='password'
                autoComplete='current-password'
                onChange={handleChange}
                variant='standard'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <RemoveRedEye />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box sx={{display: 'flex', width:'100%', justifyContent: 'flex-end'}} onClick={() => history.push('/forgot-password')}>
              <Button
                style={{
                  color: '#EF6C00',
                  textTransform: 'capitalize',
                  fontSize: 14,
                  textAlign: 'right',
                  justifyContent: 'flex-end',
                  justifySelf: 'flex-end',
                  margin: '10px 0',
                  lineHeight: '19.07px'
                }}
                onClick={() => history.push('/forgot-password')}
              >
                Lupa Kata Sandi?
              </Button>
            </Box>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2, textTransform: 'none', height: '45px' }}
              disabled={!values.username || !values.password}
            >
              Masuk
            </Button>
          </form>
        </Box>
      </Container>
    </ThemeProvider>
  )
}
