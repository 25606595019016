// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconHome = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M22.1838 11.8359L12.5299 2.18907C12.4604 2.11942 12.3778 2.06417 12.2869 2.02647C12.196 1.98877 12.0986 1.96936 12.0002 1.96936C11.9018 1.96936 11.8044 1.98877 11.7135 2.02647C11.6226 2.06417 11.54 2.11942 11.4705 2.18907L1.8166 11.8359C1.53535 12.1172 1.37598 12.4992 1.37598 12.8977C1.37598 13.725 2.04863 14.3977 2.87598 14.3977H3.89316V21.2813C3.89316 21.6961 4.22832 22.0313 4.64316 22.0313H10.5002V16.7813H13.1252V22.0313H19.3572C19.7721 22.0313 20.1072 21.6961 20.1072 21.2813V14.3977H21.1244C21.5229 14.3977 21.9049 14.2406 22.1861 13.957C22.7697 13.3711 22.7697 12.4219 22.1838 11.8359Z' />
    </SvgIcon>
  )
}

export default IconHome