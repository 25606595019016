// MUIS
import InputLabel from '@mui/material/InputLabel'
import { styled } from '@mui/material/styles'

const CustomInputLabelNew = styled(({ className, ...props }) => (
  <InputLabel className={className} {...props} />
))(({ theme }) => ({
  display: 'flex !important',
  alignItems: 'center !important',
  '& .MuiSvgIcon-root': {
    marginRight: 8,
    color: theme.palette.textSecondary
  },
  '& .MuiInputLabel-asterisk': {
    color: '#FF4242 !important',
    fontSize: '16px !important'
  },
  '& .MuiTypography-root': {
    fontWeight: 600,
    fontSize: '18px !important',
    color: theme.palette.textSecondary
  }
}))

export default CustomInputLabelNew
