// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  content: {
    borderRadius: 4,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 12,
      backgroundColor: 'unset',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      border: '4px solid transparent',
      borderRadius: 8,
      backgroundClip: 'padding-box',
    },
  },
}))

export default useStyles