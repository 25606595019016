// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: alpha('#000000', 0.4),
    zIndex: theme.zIndex.modal - 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'absolute',
  },
  viewRoot: {
    backgroundColor: theme.palette.common.white,
    width: '100%',
    height: '100%',
  },
  viewHeader: {
    backgroundColor: alpha(theme.palette.common.black, 0.08),
    padding: '12px 16px',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  titleViewHeader: {
    fontSize: 14,
    fontWeight: 700,
    width: 'fit-content',
    paddingRight: 12
  },
  statusView: {
    backgroundColor: alpha(theme.palette.common.black, 0.08),
    padding: '4px 8px',
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      color: colors.raven,
    }
  },
  textStatusView: {
    color: colors.raven,
    fontWeight: 600,
    fontSize: 12,
    paddingLeft: 4,
  },
  btnIconCopy: {
    marginRight: 8,
  },
  bodyView: {
    padding: 16,
    '& p': {
      margin: '4px 0 !important',
      fontSize: 14,
    }
  },
  titleBodyView: {
    fontWeight: 700,
    marginBottom: 4,
  }
}))

export default useStyles