// UTILITIES
import { http } from 'utilities/http'
import { stringify } from 'query-string'

export const postSearchChannels = async (queryParams, bodyParams) => {
  try {
    const response = await http.post(`/ptt/channels/search?${stringify(queryParams)}`, bodyParams)
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const postCreateNewChannel = async (bodyParams) => {
  try {
    const response = await http.post('/ptt/channels', bodyParams)
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const putEditChannel = async (bodyParams) => {
  try {
    const response = await http.put('/ptt/channels', bodyParams)
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const deleteChannel = async (bodyParams) => {
  try {
    const response = await http.delete('/ptt/channels', { data: bodyParams })
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const postAssignChannelsToUser = async (bodyParams) => {
  try {
    const response = await http.post('/ptt/assign', bodyParams)
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const postSearchUser = async (queryParams, bodyParams) => {
  try {
    const response = await http.post(`/ptt/users/search?${stringify(queryParams)}`, bodyParams)
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}