import { useState, useEffect, useContext } from 'react'

// CONTEXTS
import { PageEvidencesContext } from 'contexts/PageEvidencesContext'

// CONSTANTS
import { 
  fieldList, 
  operatorList,
} from '../evidencesConstants'

// MUIS
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

// SERVICES
import { getDeviceList } from 'services/DeviceService'
import { getEvidenceStatistics } from 'services/EvidenceService'
import { getTagEvidencesList } from 'services/TagEvidencesService'

// STYLES
import useStyles from './addFilterMenuUseStyles'

// UTILITIES
import { capitalizeEachWord } from 'utilities'

const AddFilterMenu = (props) => {
  const { addFilterAnchorEl, setAddFilterAnchorEl } = props
  
  const classes = useStyles()

  const theme = useTheme()

  const { 
    setIsCreateNewFilterButtonShown,
    setIsSaveExistingFilterButtonShown,
    selectedSavedFilter,
    addFilterItemToSelectedFilterList,
  } = useContext(PageEvidencesContext)

  const initialFilterObject = {
    field: '',
    fieldLabel: '',
    operator: '',
    value: null,
  }

  const [ filterObject, setFilterObject ] = useState(initialFilterObject)
  const [ valueInput, setValueInput ] = useState('')
  const [ deviceList, setDeviceList ] = useState([])
  const [ placeList, setPlaceList ] = useState([])
  const [ tagList, setTagList ] = useState([])
  const [ mediaList, setMediaList ] = useState([])

  const selectedOperatorList = filterObject.field === fieldList[0].value
    ? [...operatorList].slice(0, 2)
    : [...operatorList]

  const mapListIntoNewValueList = (list) => {
    return list.map(item => {
      return {
        ...item,
        label: capitalizeEachWord(item.value),
        value: capitalizeEachWord(item.value),
      }
    })
  }

  let selectedValueList = []
  if (filterObject.field === fieldList[0].value) {
    selectedValueList = [
      {
        label: 'true',
        value: true,
      },
      {
        label: 'false',
        value: false,
      },
    ]
  }
  else if (filterObject.field === fieldList[1].value) {
    selectedValueList = mapListIntoNewValueList(tagList)
  }
  else if (filterObject.field === fieldList[2].value) {
    selectedValueList = mapListIntoNewValueList(placeList)
  }
  else if (filterObject.field === fieldList[3].value) {
    selectedValueList = mapListIntoNewValueList(deviceList)
  }
  else if (filterObject.field === fieldList[4].value) {
    selectedValueList = mapListIntoNewValueList(mediaList)
  }

  const handleFilterObjectChange = (event) => {
    setFilterObject(current => {
      let output = { 
        ...current, 
        [event.target.name]: event.target.value,
      }

      if (event.target.name === 'field') {
        output.fieldLabel = fieldList.find(item => item.value === event.target.value).label
      }

      return output
    })
  }

  const handleCloseAddFilterMenu = () => {
    setAddFilterAnchorEl(null)
    setFilterObject(initialFilterObject)
  }

  const handleSaveButtonClick = () => {
    addFilterItemToSelectedFilterList(
      filterObject.field, 
      filterObject.fieldLabel, 
      filterObject.operator, 
      filterObject.value.value,
    )
    
    handleCloseAddFilterMenu()
    
    setIsCreateNewFilterButtonShown(true)
    selectedSavedFilter && setIsSaveExistingFilterButtonShown(true)
  }

  const getValueOptionList = async () => {
    const resultDeviceList = await getDeviceList()
    const resultTagList = await getTagEvidencesList()
    const resultEvidenceStatistics = await getEvidenceStatistics({
      address: true,
      device: true,
      limit: 10000,
      media: true,
      tag: true,
    })

    if (resultDeviceList.status === 200) {
      const newDeviceList = resultDeviceList.data.map(item => {
        return {
          ...item,
          value: item.label,
        }
      })

      setDeviceList(newDeviceList)
    }

    if (resultTagList.rows.length > 0) {
      const newTagList = resultTagList.rows.map(item => {
        return {
          ...item,
          label: item.tagName,
          value: item.tagName,
        }
      })

      setTagList(newTagList)
    }

    if (resultEvidenceStatistics.status === 200) {
      setPlaceList(resultEvidenceStatistics.data.addresses)
      
      const newMediaList = resultEvidenceStatistics.data.medias.map(item => {
        let label = capitalizeEachWord(item.value)
        if (item.value === 'image') label = 'Foto'

        return {
          ...item,
          label,
        }
      })

      setMediaList(newMediaList)
    }
  }

  useEffect(() => {
    getValueOptionList()
  }, [])

  useEffect(() => {
    if (filterObject.operator === 'IS_EMPTY' || filterObject.operator === 'IS_NOT_EMPTY') {
      setFilterObject(current => {
        return {
          ...current,
          value: null,
        }
      })
    }
  }, [filterObject.operator])

  return (
    <Menu
      anchorEl={addFilterAnchorEl}
      open={Boolean(addFilterAnchorEl)}
      onClose={handleCloseAddFilterMenu}
      className={classes.root}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {/* TITLE */}
      <Typography
        variant='subtitle1'
        fontWeight={700}
        paddingBottom='8px'
        borderBottom={`1px solid ${theme.palette.divider}`}
      >
        Tambah Filter
      </Typography>

      {/* FIELD AND OPERATOR SELECTS */}
      <Stack
        direction='row'
        alignItems='center'
        spacing='8px'
        margin='16px 0px 16px'
      >
        {/* FIELD SELECT */}
        <FormControl fullWidth>
          {/* LABEL */}
          <InputLabel 
            shrink
            className={classes.inputLabel}
          >
            Field
          </InputLabel>

          {/* SELECT */}
          <Select
            name='field'
            label=''
            value={filterObject.field}
            onChange={handleFilterObjectChange}
            placeholder='Pilih Field'
            className={classes.input}
          >
            {fieldList.map((item, index) => (
              <MenuItem
                key={index} 
                value={item.value}
                className={classes.selectMenuItem}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* OPERATOR SELECT */}
        <FormControl fullWidth>
          {/* LABEL */}
          <InputLabel 
            shrink
            className={classes.inputLabel}
          >
            Operator
          </InputLabel>

          {/* SELECT */}
          <Select
            name='operator'
            label=''
            value={filterObject.operator}
            onChange={handleFilterObjectChange}
            placeholder='Pilih Operator'
            className={classes.input}
          >
            {selectedOperatorList.map((item, index) => (
              <MenuItem
                key={index} 
                value={item.value}
                className={classes.selectMenuItem}
              >
                {item.value.toLowerCase().replaceAll('_', ' ')}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>

      {/* VALUE AUTOCOMPLETE */}
      {!(filterObject.operator === 'IS_EMPTY' || filterObject.operator === 'IS_NOT_EMPTY') &&
      <FormControl fullWidth>
        {/* LABEL */}
        <InputLabel 
          shrink
          className={classes.inputLabel}
        >
          Value
        </InputLabel>

        {/* AUTOCOMPLETE */}
        <Autocomplete
          value={filterObject.value}
          onChange={(event, newValue) => setFilterObject(current => {
            return {
              ...current,
              value: newValue,
            }
          })}
          inputValue={valueInput}
          onInputChange={(event, newInputValue) => setValueInput(newInputValue)}
          options={selectedValueList}
          size='small'
          className={classes.input}
          MenuProps={{ classes: { paper: classes.selectMenuPaper } }}
          renderInput={(params) => (
            <TextField 
              {...params} 
              className={classes.input}
              label='' 
              onKeyDown={(event) => event.stopPropagation()}
            />
          )}
        />
      </FormControl>}

      {/* ACTIONS */}
      <Stack
        direction='row'
        spacing='8px'
        marginTop='8px'
      >
        {/* CANCEL BUTTON */}
        <Button 
          className={`${classes.actionButton} ${classes.actionButtonCancel}`}
          onClick={handleCloseAddFilterMenu}
        >
          Batal
        </Button>

        {/* SAVE BUTTON */}
        <Button 
          className={classes.actionButton}
          onClick={handleSaveButtonClick}
        >
          Simpan
        </Button>
      </Stack>
    </Menu>
  )
}

export default AddFilterMenu