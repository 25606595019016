// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    height: '100%',
    overflowY: 'scroll'
  },
  itemDetailWrapper: {
    padding: '16px 16px 16px 12px',
    borderLeftStyle: 'solid',
    borderLeftWidth: 3,
    borderColor: theme.palette.error.main,
    cursor: 'pointer',
    transition: 'background-color 0.4s',
    '&:hover': {
      backgroundColor: `${colors.linkWater} !important`
    },
    '&:active': {
      backgroundColor: `${colors.perwinkleGray} !important`
    }
  },
  itemDetail: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0,
    }
  },
  itemDetailIcon: {
    color: colors.raven,
    fontSize: 14,
  },
  chipStatus: {
    borderRadius: 4,
    height: 24,
    '& .MuiChip-label': {
      fontWeight: 700,
      fontSize: 12,
    }
  }
}))

export default useStyles