// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconCrashCar = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M21.4809 11.3423L20.2069 10.9803L19.3832 7.093C19.1193 5.45538 17.9932 3.40129 16.5812 3.13004L7.64627 1.41174C6.25872 1.41174 5.01827 2.49778 4.56065 4.11221L2.87892 8.74071C1.98086 9.46977 1.41211 10.7292 1.41211 12.1148V20.5711C1.41211 21.6854 2.14347 22.5879 3.04644 22.5879C3.94941 22.5879 4.68078 21.6854 4.68078 20.5711V17.9987C4.68078 17.9987 8.5664 19.1725 11.4714 19.6646C15.0612 20.2726 18.4852 19.8612 18.4852 19.8612L18.3258 20.3089C18.1861 21.3768 18.8178 22.3852 19.7363 22.5617C20.6547 22.7381 21.5128 22.0161 21.6525 20.9493L22.5498 14.8626C22.7238 13.5346 22.303 12.2166 21.4809 11.3423ZM5.16699 6.77939L6.10346 4.77875C6.33309 3.97002 6.95168 3.42852 7.64627 3.42852L16.3287 5.06312C17.0355 5.19925 17.5977 6.60998 17.7293 7.4298L18.4484 8.68827C18.5334 9.21465 18.1109 9.64725 17.6737 9.47683C16.0827 8.85768 14.8341 10.0163 12.2257 9.51414C9.6181 9.01297 7.4142 7.41468 5.81092 7.73333C5.37046 7.82206 5.0199 7.29871 5.16699 6.77939ZM5.08936 14.2284C4.41274 14.2284 3.86361 13.5507 3.86361 12.7158C3.86361 11.8808 4.41274 11.2032 5.08936 11.2032C5.76597 11.2032 6.31511 11.8808 6.31511 12.7158C6.31511 13.5507 5.76597 14.2284 5.08936 14.2284ZM14.0937 14.3221L9.58377 13.22C9.1327 13.22 8.76661 12.7682 8.76661 12.2116C8.76661 11.6549 9.1327 11.2032 9.58377 11.2032L14.347 12.3891C14.8063 12.4778 15.1225 12.982 15.0523 13.5154C14.982 14.0489 14.553 14.4099 14.0937 14.3221ZM18.294 15.9083C17.6942 15.3144 17.5781 14.2697 18.0341 13.5759L19.0833 14.7114L20.2069 15.7258C19.7501 16.4206 18.8938 16.5023 18.294 15.9083Z'/>
    </SvgIcon>
  )
}

export default IconCrashCar