import { useContext, useEffect, useRef } from 'react'
import ReactDOMServer from 'react-dom/server'
import { format } from 'date-fns'

// CONSTANTS
import { colors } from 'constants/colors'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// LEAFLET
import L from 'leaflet'

// MUIS
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconLocationOn from '@mui/icons-material/LocationOn'
import IconNavigation from '@mui/icons-material/Navigation'

// STYLES
import useStyles from './polylineHistoryUseStyles'

const MarkerHistory = ({ classes, Icon }) => {
  return <Stack className={classes.markerHistory}>
    <Icon />
  </Stack>
}

const PopupMarker = ({ classes, title, address, latLng, date, mileage, travelTime }) => {
  return <Stack className={classes.popupWrapper}>
    <Stack className={classes.popupRoot}>
      <Typography variant='caption' className={classes.popupTitle}>
        {title ?? '-'}
      </Typography>

      <Typography variant='caption' className={classes.popupAddress}>
        {address ?? '-'}
      </Typography>

      <Typography variant='caption' className={classes.popupCoordinate}>{latLng?.lat}, {latLng?.lng}</Typography>

      <Stack className={classes.popupItemInfo}>
        <Typography className={classes.popupLabelInfo}>Waktu</Typography>
        <Typography className={classes.popupDetailInfo}>{date ?? '-'}</Typography>
      </Stack>

      <Stack className={classes.popupItemInfo}>
        <Typography className={classes.popupLabelInfo}>Mileage</Typography>
        <Typography className={classes.popupDetailInfo}>{mileage ?? '0'} KM</Typography>
      </Stack>

      <Stack className={classes.popupItemInfo}>
        <Typography className={classes.popupLabelInfo}>Travel Time</Typography>
        <Typography className={classes.popupDetailInfo}>{travelTime ?? '0'} m</Typography>
      </Stack>
    </Stack>
  </Stack>
}

const PolylineHistory = () => {
  const { historyPolylineList, map, selectionModel } = useContext(PageTrackingContext)
  const historyMapRef = useRef(L.featureGroup())
  const classes = useStyles()

  const polylinePathOptions = {
    color: colors.clementine,
    weight: 5,
  }

  const popupOptions = {
    offset: L.point(0, -32),
    maxWidth: 308,
    minWidth: 308,
    maxHeight: 200,
    className: classes.popupMarker,
    closeButton: false,
  }

  const generateHistoryLine = () => {
    historyMapRef.current.clearLayers()
    if (historyPolylineList?.list?.length && selectionModel?.length) {
      const instancePolyline = L.polyline(historyPolylineList?.list, polylinePathOptions)
      const instanceMarkerStart = L.marker(historyPolylineList?.list[0], {
        icon: L.divIcon({
          className: 'marker-history',
          html: ReactDOMServer.renderToString(<MarkerHistory classes={classes} Icon={IconNavigation} />),
          iconSize: [32, 32]
        })
      })
      const instanceMarkerEnd = L.marker(historyPolylineList?.list[historyPolylineList?.list?.length-1], {
        icon: L.divIcon({
          className: 'marker-history',
          html: ReactDOMServer.renderToString(<MarkerHistory classes={classes} Icon={IconLocationOn} />),
          iconSize: [32, 32]
        })
      })

      /* START */
      instanceMarkerStart.bindPopup(ReactDOMServer.renderToString(<PopupMarker
        classes={classes}
        title='Track Start'
        address={historyPolylineList?.summary?.start?.address}
        latLng={{
          lat: Number(historyPolylineList?.summary?.start?.lat).toFixed(6),
          lng: Number(historyPolylineList?.summary?.start?.lng).toFixed(6)
        }}
        date={historyPolylineList?.summary?.start?.timestamp ? format(new Date(historyPolylineList?.summary?.start?.timestamp), 'MM/dd/yyyy hh:mm a') : null}
        mileage={historyPolylineList?.summary?.mileage > 0 ? Number(historyPolylineList?.summary?.mileage/1000).toFixed(2) : 0}
        travelTime={historyPolylineList?.summary?.travel_time ? historyPolylineList?.summary?.travel_time : 0}
      />), popupOptions)

      /* FINISH */
      instanceMarkerEnd.bindPopup(ReactDOMServer.renderToString(<PopupMarker
        classes={classes}
        title='Track Finish'
        address={historyPolylineList?.summary?.finish?.address}
        latLng={{
          lat: Number(historyPolylineList?.summary?.finish?.lat).toFixed(6),
          lng: Number(historyPolylineList?.summary?.finish?.lng).toFixed(6)
        }}
        date={historyPolylineList?.summary?.finish?.timestamp ? format(new Date(historyPolylineList?.summary?.finish?.timestamp), 'MM/dd/yyyy hh:mm a') : null}
        mileage={historyPolylineList?.summary?.mileage > 0 ? Number(historyPolylineList?.summary?.mileage/1000).toFixed(2) : 0}
        travelTime={historyPolylineList?.summary?.travel_time ? historyPolylineList?.summary?.travel_time : 0}
      />), popupOptions)

      instanceMarkerStart.on('mouseover mouseout', event => {
        if (event.type === 'mouseover') event.target.openPopup()
        else if (event.type === 'mouseout') event.target.closePopup()
      })

      instanceMarkerEnd.on('mouseover mouseout', event => {
        if (event.type === 'mouseover') event.target.openPopup()
        else if (event.type === 'mouseout') event.target.closePopup()
      })

      historyMapRef.current.addLayer(instancePolyline)
      historyMapRef.current.addLayer(instanceMarkerStart)
      historyMapRef.current.addLayer(instanceMarkerEnd)

      map.addLayer(historyMapRef.current)
    }
  }

  useEffect(() => {
    generateHistoryLine()

  }, [historyPolylineList, selectionModel])

  return null
}

export default PolylineHistory