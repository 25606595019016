import {http} from '../utilities/http'
import {stringify} from 'query-string'


export async function getVehicles(query) {
  try {
    const { data } = await http.get(`/vehicles?${stringify(query)}`)

    const newRows = data.rows.map((item) => {
      item.id = item.vehicleNo
      return item
    })

    return {
      ...data,
      rows: newRows
    }

  } catch (e) {
    return {'status': 'error', rows: []}
  }
}


export async function getVehicleTypes(query) {
  try {
    const { data } = await http.get(`/vehicle-types?${stringify(query)}`)

    const newRows = data.rows.map((item) => {
      item.id = item.vehicleTypeNo
      return item
    })

    return {
      ...data,
      rows: newRows
    }

  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function getOfficers(query) {
  try {
    const { data } = await http.get(`/officers?${stringify(query)}`)

    const newRows = data.rows.map((item) => {
      item.id = item.officerNo
      return item
    })

    return {
      ...data,
      rows: newRows
    }

  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function getActivityTypes(query) {
  try {
    const { data } = await http.get(`/activity-types?${stringify(query)}`)

    return data

  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function createActivityType(params) {
  try {
    return await http.post('/activity-types', params)
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function updateActivityType(id, params) {
  try {
    return await http.put(`/activity-types/${id}`, params)
  } catch (e) {
    return {'status': 'error', error: e?.response?.data?.error}
  }
}

export async function deleteActivityType(id) {
  try {
    return await http.delete(`/activity-types/${id}`)
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function getCrimeTypes(query) {
  try {
    const { data } = await http.get(`/crime-types?${stringify(query)}`)

    return data

  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function createCrimeType(params) {
  try {
    return await http.post('/crime-types', params)
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function updateCrimeType(id, params) {
  try {
    return await http.put(`/crime-types/${id}`, params)
  } catch (e) {
    return {'status': 'error', error: e?.response?.data?.error}
  }
}

export async function deleteCrimeType(id) {
  try {
    return await http.delete(`/crime-types/${id}`)
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function createGroup(params) {
  try {
    return await http.post('/voc-groups', params)
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function updateGroup(id, params) {
  try {
    return await http.put(`/voc-groups/${id}`, params)
  } catch (e) {
    return {'status': 'error', error: e?.response?.data?.error}
  }
}

export async function deleteGroup(id) {
  try {
    return await http.delete(`/voc-groups/${id}`)
  } catch (e) {
    return {'status': 'error', error: e?.response?.data?.error}
  }
}

export async function getSSDGroups(query) {
  try {
    const { data } = await http.get(`/ssd-groups?${stringify(query)}`)

    return data

  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function createSSDGroup(params) {
  try {
    return await http.post('/ssd-groups', params)
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export async function updateSSDGroup(id, params) {
  try {
    return await http.put(`/ssd-groups/${id}`, params)
  } catch (e) {
    return {'status': 'error', error: e?.response?.data?.error}
  }
}

export async function deleteSSDGroup(id) {
  try {
    return await http.delete(`/ssd-groups/${id}`)
  } catch (e) {
    return {'status': 'error', rows: []}
  }
}

export const getGpsTrackerDevices = async (bodyParams) => {
  try {
    const { data } = await http.post('/gps-tracker-devices/search', bodyParams)
        
    const newRows = data.rows.map((item) => {
      return {
        ...item,
        id: item.gpsTrackerNo,
      }
    })

    return {
      ...data,
      rows: newRows,
    }
  } catch (e) {
    return {
      status: 'error', 
      rows: [],
    }
  }
}

export const postANewGpsTrackerDevice = async (bodyParams) => {
  try {
    return await http.post('/gps-tracker-devices', bodyParams)
  } 
  catch (e) {
    return {
      status: 'error', 
      rows: [],
    }
  }
}

export const putEditAGpsTrackerDevice = async (id, bodyParams) => {
  try {
    return await http.put(`/gps-tracker-devices/${id}`, bodyParams)
  } 
  catch (e) {
    return {
      status: 'error', 
      rows: [],
    }
  }
}

export const deleteGpsTrackerDevice = async (id) => {
  try {
    return await http.delete(`/gps-tracker-devices/${id}`)
  } 
  catch (e) {
    return {
      status: 'error', 
      rows: [],
    }
  }
}

export const putSoftDeleteCrimeType = async (bodyParams) => {
  try {
    return await http.put('crime-types/soft-delete', bodyParams)
  } 
  catch (error) {
    return {
      status: 'error', 
      error: error,
    }
  }
}