export const fieldList = [
  { 
    label: 'SOS',
    value: 'SOS_STATUS', 
  },
  { 
    label: 'Tag Kejadian',
    value: 'TAG', 
  },
  { 
    label: 'Lokasi',
    value: 'LOCATION', 
  },
  { 
    label: 'Perangkat',
    value: 'DEVICE', 
  },
  { 
    label: 'Jenis File',
    value: 'MEDIA', 
  },
]

export const operatorList = [
  { value: 'IS' },
  { value: 'IS_NOT' },
  { value: 'IS_EMPTY' },
  { value: 'IS_NOT_EMPTY' },
  { value: 'CONTAINS' },
  { value: 'DOES_NOT_CONTAINS' },
  { value: 'ENDS_WITH' },
  { value: 'STARTS_WITH' },
]