import React from 'react'
import PropTypes from 'prop-types'

// CUSTOM COMPONENTS
import CustomTab from 'components/Customs/CustomTab'
import CustomTabs from 'components/Customs/CustomTabs'

// MUIS
import Box from '@mui/material/Box'
import Badge from '@mui/material/Badge'

// STYLES
import useStyles from './contentTabsUseStyles'

const ContentTabs = (props) => {
  const {
    tabItemList,
    selectedTab,
    setSelectedTab,
    isExtraComponentAvailable,
    extraComponent,
    classNameTab,
    tabsHeight,
    disabledPaddingTabs,
  } = props

  const classes = useStyles()

  return (
    <Box
      className={classes.root}
      sx={{
        height: tabsHeight,
        padding: disabledPaddingTabs ? 0 : '0px 16px',
      }}
    >
      {/* TABS */}
      <CustomTabs
        value={selectedTab}
        onChange={(event, newValue) => setSelectedTab(newValue)}
      >
        {tabItemList.map((item, index) => (
          <CustomTab
            key={index}
            className={`${classes.tabItem} ${classNameTab}`}
            value={item.value}
            label={item.hasBadge ? 
              <Badge className={classes.badge} variant='dot' color='error'>{item.label}</Badge> : 
              item.label}
          />
        ))}
      </CustomTabs>

      {/* LAUNCH ICON */}
      {isExtraComponentAvailable && extraComponent}
    </Box>
  )
}

ContentTabs.defaultProps = {
  tabItemList: [],
  selectedTab: '',
  isExtraComponentAvailable: false,
  extraComponent: null,
  classNameTab: '',
  tabsHeight: 70,
  disabledPaddingTabs: false,
}

ContentTabs.propTypes = {
  tabItemList: PropTypes.array.isRequired,
  selectedTab: PropTypes.string.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  isExtraComponentAvailable: PropTypes.bool.isRequired,
  extraComponent: PropTypes.node,
  classNameTab: PropTypes.string.isRequired,
  tabsHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabledPaddingTabs: PropTypes.bool,
}

export default ContentTabs
