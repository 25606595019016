import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

// CONFIGURATIONS
import { 
  circleOptions,
  initialMapCenter,
  mapContainerStyle,
} from './mapSettings'

// CUSTOM COMPONENTS
import CustomInput from 'components/Customs/CustomInput'
import CustomInputLabel from 'components/Customs/CustomInputLabel'
import CustomMenuItem from 'components/Customs/CustomMenuItem'
import CustomSlider from 'components/Customs/CustomSlider'

// GOOGLE MAPS
import {
  Circle,
  GoogleMap,
} from '@react-google-maps/api'

// MUIS
import Box from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import Menu from '@mui/material/Menu'
import Popper from '@mui/material/Popper'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconAdjust from '@mui/icons-material/Adjust'
import IconArrowDropDown from '@mui/icons-material/ArrowDropDown'
import IconMap from '@mui/icons-material/Map'

// STYLES
import useStyles from './inputLocationUseStyles'

// SERVICES
import { getAddressByLatLong, getGeoByAddress } from 'services/TrackingServices'

const InputLocationNew = (props) => {
  const { 
    location, setLocation, circle, setCircle,
    latitudeProp, longitudeProp, isDarkTheme,
    radius, setRadius, customPlaceholder, getLatLng,
    isButtonExpandMapShown, isExpandMapShown,
    suggestContainerWidth, radiusMenuWidth,
    isRadiusInputShown, mapHeight
  } = props

  const classes = useStyles()

  const radiusOptions = [ 50, 150, 500, 1500 ]
  const minRadius = 50
  const maxRadius = 1500

  const [inputLocationAnchor, setInputLocationAnchor] = useState(null)
  const [isMapShown, setIsMapShown] = useState(false)
  const [map, setMap] = useState(null)
  const [mapCenter, setMapCenter] = useState(initialMapCenter)
  const [radiusMenuAnchor, setRadiusMenuAnchor] = useState(false)
  const [source, setSource] = useState(null) // 'input' or 'circle'
  const [addressOptions, setAddressOptions] = useState([])

  const getAddressFromLocation = async (inputLatitude, inputLongitude) => {
    const result = await getAddressByLatLong({
      lat: inputLatitude,
      lng: inputLongitude
    })

    setLocation(result.value, false)
  }

  const onMapLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds()
    map.fitBounds(bounds)
    setMap(map)
  }, [])

  const onMapUnmount = useCallback((map) => {
    setMap(null)
  }, [])

  const circleOnLoad = useCallback((circle) => {
    setCircle(circle)
  }, [])

  const circleOnUnMount = useCallback((circle) => {
    setCircle(null)
  }, [])

  const circleCenterChanged = () => {
    (circle && source === 'circle') && getAddressFromLocation(circle.center.lat(), circle.center.lng())
    setSource('circle')
  }
  
  const circleRadiusChanged = () => {
    circle && setRadius(parseInt(circle['radius']))
  }

  const onRadiusMenuItemIsClicked = (inputValue) => {
    setRadius(inputValue)
    setRadiusMenuAnchor(null)
  }

  const onSuggestionItemClicked = (inputItem, inputIndex) => {
    setLocation(inputItem.address, false)
    setIsMapShown(true)
    setMapCenter({lat: inputItem.lat, lng: inputItem.lng})
    getLatLng({lat: inputItem.lat, lng: inputItem.lng})
    setSource('input')
    setInputLocationAnchor(null)
  }

  useEffect(() => {
    if(latitudeProp && longitudeProp){
      getAddressFromLocation(latitudeProp, longitudeProp)
      setIsMapShown(true)
      setMapCenter({ lat: latitudeProp, lng: longitudeProp })
    }
  }, [ latitudeProp, longitudeProp ])

  useEffect(() => {
    if(map && circle) {
      const bounds = new window.google.maps.LatLngBounds()
      bounds.union(circle.getBounds())
      map.fitBounds(bounds)
      setMap(map)
    }

    const fetchSuggestAddress = async () => {
      if(!location) return null
      const gets = await getGeoByAddress({
        provider_type: 'google',
        q: location
      })

      if(gets?.success) setAddressOptions(gets.locations)
    }

    fetchSuggestAddress()

    return () => {}
  }, [circle, radius, mapCenter, location])

  useEffect(() => {
    if(!isButtonExpandMapShown) {
      setIsMapShown(isExpandMapShown)
    }
  }, [isExpandMapShown])

  return (
    <>
      {/* INPUT */}
      <Box
        className={classes.iconAndFormControlContainerNew}
        sx={{
          paddingTop: '32px',
        }}
      >
        <ClickAwayListener onClickAway={() => setInputLocationAnchor(null)}>
          <CustomInput
            placeholder={customPlaceholder || 'Pilih lokasi'}
            disableUnderline
            type='text'
            value={location}
            onChange={(event) => setLocation(event.target.value)}
            onClick={(event) => setInputLocationAnchor(inputLocationAnchor ? null : event.currentTarget)}
            endAdornment={
              isButtonExpandMapShown && <InputAdornment 
                position='end'
                className={classes.formControlLocationAdorment}
                onClick={() => setIsMapShown(current => !current)}
              >
                <IconMap className={classes.iconMap}/>
              </InputAdornment>
            }
            className={classes.inputCustomNew}
            sx={{
              backgroundColor: '#FFFFFF'
            }}
          />
        </ClickAwayListener>
      </Box>

      {/* LOCATION SUGGESTIONS */}
      <Popper 
        className={isDarkTheme ? classes.locationSuggestionsContainerDark : classes.locationSuggestionsContainer}
        open={Boolean(inputLocationAnchor) && addressOptions.length > 0} 
        anchorEl={inputLocationAnchor}
        sx={{
          width: suggestContainerWidth ? suggestContainerWidth : '456px'
        }}
      >
        {addressOptions.map((item, index) => (
          <CustomMenuItem
            key={index}
            className={isDarkTheme ? classes.locationSuggestionsItemDark : classes.locationSuggestionsItem}
            onClick={() => onSuggestionItemClicked(item, index)}
          >
            <Typography
              variant='inherit'
              noWrap
            >
              {item.address}
            </Typography>
          </CustomMenuItem>
        ))}
      </Popper>

      {/* GOOGLE MAPS */}
      {isMapShown &&
      <Box className={classes.googleMapWrap}>
        <GoogleMap
          mapContainerStyle={{
            ...mapContainerStyle,
            height: mapHeight ? mapHeight : 412
          }}
          center={mapCenter}
          zoom={5}
          onLoad={onMapLoad}
          onUnmount={onMapUnmount}
          options={{
          // SOURCE: https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
            streetViewControl: false,
            scaleControl: false,
            mapTypeControl: false,
          // zoomControl: false,
          }}
        >
          {location && 
          <Circle
            center={mapCenter}
            radius={radius}
            options={circleOptions}
            onLoad={circleOnLoad}
            onUnmount={circleOnUnMount}
            // TODO: ON CENTER CHANGED SHOULD BE REPLACED BY ON DRAG OR ON DRAG END
            onCenterChanged={circleCenterChanged}
            onRadiusChanged={circleRadiusChanged}
          />}
        </GoogleMap>
      </Box>}

      {/* RADIUS INPUT AND SLIDER */}
      {(isMapShown && isRadiusInputShown) &&
      <Box className={`${classes.iconAndFormControlContainer} ${classes.radiusWrapper}`}>
        {/* INPUT */}
        <FormControl 
          variant='filled' 
          className={classes.locationRadius}
        >
          <CustomInputLabel className={classes.labelRadius}>
            <IconAdjust /> Radius
          </CustomInputLabel>
          <CustomInput
            disableUnderline
            type='string'
            value={`${radius} meter`}
            onChange={(event) => Number(event.target.value.replace(' meter', '')) < 0 ? setRadius(0) : setRadius(Number(event.target.value.replace(' meter', '')))}
            onClick={(event) => setRadiusMenuAnchor(event.currentTarget)}
            endAdornment={
              <InputAdornment 
                position='end' 
                className={radiusMenuAnchor ? classes.iconRadiusDropUp : classes.iconRadiusDropDown}
                onClick={(event) => setRadiusMenuAnchor(event.currentTarget)}
              >
                <IconArrowDropDown/>
              </InputAdornment>
            }
          />
        </FormControl>

        {/* RADIUS OPTIONS */}
        <Menu
          anchorEl={radiusMenuAnchor}
          open={Boolean(radiusMenuAnchor)}
          onClose={() => setRadiusMenuAnchor(null)}
          className={classes.patrolLocationRadiusMenu}
          sx={{
            '& .MuiMenu-list': {
              width: radiusMenuWidth ? radiusMenuWidth : 212,
            },
          }}
        >
          {radiusOptions.map((item, index) => (
            <CustomMenuItem
              key={index}
              onClick={() => onRadiusMenuItemIsClicked(item)}
            >
              {`${item} meter`}
            </CustomMenuItem>
          ))}
        </Menu>

        {/* RADIUS SLIDER */}
        <CustomSlider
          className={`${classes.locationSlider} no-zoom`}
          valueLabelDisplay='on'
          value={radius}
          onChange={(event, newValue) => setRadius(newValue)}
          min={minRadius}
          max={maxRadius}
        />
      </Box>}
    </>
  )
}

InputLocationNew.defaultProps = {
  location: '',
  isDarkTheme: false,
  radius: 50,
  inputName: '',
  isButtonExpandMapShown: true,
  isRadiusInputShown: true,
  mapHeight: 412,
}

InputLocationNew.propTypes = {
  location: PropTypes.string.isRequired,
  setLocation: PropTypes.func.isRequired,
  circle: PropTypes.object,
  setCircle: PropTypes.func.isRequired,
  latitudeProp: PropTypes.number,
  longitudeProp: PropTypes.number,
  isDarkTheme: PropTypes.bool,
  radius: PropTypes.number.isRequired,
  setRadius: PropTypes.func.isRequired,
  inputName: PropTypes.string.isRequired,
  isButtonExpandMapShown: PropTypes.bool,
  isExpandMapShown: PropTypes.bool,
  suggestContainerWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  radiusMenuWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isRadiusInputShown: PropTypes.bool,
  mapHeight: PropTypes.number
}

export default InputLocationNew