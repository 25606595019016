import AC from 'agora-chat'

export const agoraSendMessage = async (conn, options) => {
  const option = AC.message.create(options)
  return await conn?.send(option)
}

export const agoraCreateGroup = async (conn, options) => {
  await conn.createGroup({
    data: {
      public: true,
      approval: false,
      allowinvites: true,
      inviteNeedConfirm: false,
      maxusers: 500,
      ...options
    },
  })
}