// MUI STYLES
import { makeStyles } from '@mui/styles'
import { colors } from 'constants/colors'

const useStyles = makeStyles((theme) => ({
  marker: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    border: '2px solid white',
  },
  itemTooltip: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0,
    },
    '&.description': {
      alignItems: 'flex-start'
    }
  },
  itemTooltipIcon: {
    color: colors.raven,
    fontSize: 16,
  },
  itemTooltipText: {
    color: `${theme.palette.common.black} !important`,
    fontWeight: '600 !important',
    flex: 1,
    margin: '0 !important',
    paddingLeft: 4,
    textWrap: 'wrap',
    '&.description': {
      fontWeight: '400 !important',
    }
  },
  chipSOS: {
    backgroundColor: theme.palette.error.main,
    height: '24px !important',
    borderRadius: '32px',
    paddingTop: 5,
    '& .MuiChip-label': {
      color: theme.palette.common.white,
      paddingLeft: '10px !important',
      paddingRight: '10px !important',
      '& .MuiSvgIcon-root': {
        fontSize: 14,
      }
    }
  },
  chipCar: {
    backgroundColor: colors.pictonBlue,
    height: '20px !important',
    width: '20px !important',
    borderRadius: '100%',
    paddingTop: 3,
    '& .MuiChip-label': {
      color: theme.palette.common.white,
      paddingLeft: '3px !important',
      paddingRight: '3px !important',
      '& .MuiSvgIcon-root': {
        fontSize: 14,
      }
    }
  },
  iconMarkerCenter: {
    color: theme.palette.common.white,
    fontSize: 12,
    marginTop: 2,
    marginLeft: 2
  },
  chipStatus: {
    borderRadius: 4,
    height: 24,
    paddingTop: '2px !important',
    border: '1px solid #CBCBCB',
    '& .MuiChip-label': {
      fontWeight: '700 !important',
      fontSize: '12px !important',
    }
  },
  circle: {
    borderRadius: '50%',
    position: 'absolute',
    opacity: 0,
    border: `1px solid ${theme.palette.error.main}`,
    animation: '$markerClusterScaleIn 5s infinite',
  },
  '@keyframes markerClusterScaleIn': {
    'from': {
      transform: 'scale(1, 1)',
      opacity: 1
    },
    'to': {
      transform: 'scale(2.8, 2.8)',
      opacity: 0,
    }
  },
}))

export default useStyles