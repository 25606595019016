// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const menuWidth = 500 - 20 - 20 // 20px is from the horizontal padding of the Dialog Add a New Vehicle component

const useStyles = makeStyles((theme) => ({
  dialogHeaderTitle: {
    fontWeight: 600,
    marginBottom: 16,
  },
  formControl: {
    marginBottom: 16,
    borderRadius: '2px !important',
    '& .formControlDatePicker': {
      cursor: 'pointer'
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  dropDownIcon: {
    color: colors.boulder,
  },
  objectMenu: {
    zIndex: theme.zIndex.modal - 1,
    '& .MuiPaper-root': {
      width: menuWidth,
      maxHeight: 500,
    },
    '& .MuiMenu-list': {
      padding: 0,
    },
  },
  objectMenuItemSearch: {
    padding: 16,
  },
  objectSearchInput: {
    width: '100%',
    fontWeight: 600,
    paddingBottom: 4,
    '&::-webkit-input-placeholder': {
      color: colors.boulder,
    },
  },
  objectSearchIcon: {
    marginRight: 4,
  },
  objectMenuItemContainer: {
    width: '100%',
    overflow: 'auto',
    maxHeight: 'calc(500px - 65px)', // 65 is from the height of the search Input container component
  },
  objectMenuItem: {
    height: 48,
    paddingLeft: 20,
  },
  objectMenuItemSelected: {
    backgroundColor: alpha(colors.clementine, 0.1),
  },
  objectMenuItemText: {
    fontWeight: 400,
  },
  vehicleMenu: {
    zIndex: theme.zIndex.modal - 1,
    '& .MuiPaper-root': {
      width: menuWidth,
    },
  },
  vehicleTypeMenuItemSelected: {
    color: colors.clementine,
  },
  menu: {
    '& .MuiPaper-root': {
      width: menuWidth,
      maxHeight: 500,
    },
    '& .MuiMenu-list': {
      padding: 0,
    },
  },
  menuSearch: {
    padding: 16,
  },
  menuSearchInput: {
    width: '100%',
    fontWeight: 600,
    paddingBottom: 4,
    '&::-webkit-input-placeholder': {
      color: colors.boulder,
    },
  },
  menuSearchIcon: {
    marginRight: 4,
  },
  menuItemContainer: {
    width: '100%',
    overflow: 'auto',
    maxHeight: 'calc(500px - 65px)', // 65 is from the height of the search Input container component
  },
  menuItem: {
    height: 48,
    paddingLeft: 20,
  },
  menuItemSelected: {
    backgroundColor: alpha(colors.clementine, 0.1),
  },
  cancelButton: {
    color: colors.raven,
    '&:hover': {
      backgroundColor: alpha(colors.raven, 0.1),
    },
  },
  '@media only screen and (max-height: 820px)': {
    inputDepartment: {
      '& .MuiInput-input': {
        zoom: 0.85,
      },
    },
    objectMenu: {
      '& .MuiPaper-root': {
        maxWidth: 360,
      }
    },
    menu: {
      '& .MuiPaper-root': {
        maxWidth: 360,
      }
    }
  }
}))

export default useStyles