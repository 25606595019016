import { useState } from 'react'

// COMPONENTS
import CustomFormControl from 'components/Customs/CustomFormControl'
import CustomInputLabelNew from 'components/Customs/CustomInputLabelNew'
import CustomInputNew from 'components/Customs/CustomInputNew'
import DialogAddDevice from '../DialogAddDevice/DialogAddDevice'

// MUIS
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Dialog from '@mui/material/Dialog'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconAddCircle from '@mui/icons-material/AddCircle'
import IconTextFields from '@mui/icons-material/TextFields'
import IconPhoneAndroid from '@mui/icons-material/PhoneAndroid'

// STYLES
import useStyles from './dialogDiscussionGroupUseStyles'

const TabNewGroup = (props) => {
  const {
    selectedDevices, setSelectedDevices,
    paramsCreateGroup, setParamsCreateGroup
  } = props
  const classes = useStyles()

  // STATES
  const [isDialogAddDeviceShown, setIsDialogAddDeviceShown] = useState(false)

  return (
    <>
      <Stack padding='16px'>
        {/* NAME GROUP */}
        <CustomFormControl variant='filled' className={classes.formControl}>
          <CustomInputLabelNew shrink={true}>
            <IconTextFields />
            <Typography variant='subtitle1'>Nama Grup</Typography>
          </CustomInputLabelNew>

          <CustomInputNew
            disableUnderline
            placeholder='Nama Group'
            value={paramsCreateGroup.groupName}
            onChange={event => setParamsCreateGroup({ groupName: event.target.value })}
          />
        </CustomFormControl>

        {/* DEVICE */}
        <Stack className={`${classes.pockbaseStyles} ${classes.formControl}`}>
          <Stack direction='row' flexWrap='nowrap' alignItems='center'>
            <Stack direction='row' flexWrap='nowrap' flex={1} alignItems='center'>
              <IconPhoneAndroid className={classes.pockbaseStylesIcon}/>
              <Typography className={classes.pockbaseStylesLabel}>Perangkat</Typography>
            </Stack>

            {/* DEVICE BUTTON */}
            <Button
              startIcon={<IconAddCircle fontSize='small' />}
              onClick={event => setIsDialogAddDeviceShown(true)}
            >Tambahkan</Button>
          </Stack>

          {selectedDevices?.length ? (<Stack direction='row' flexWrap='wrap' className={classes.listSelectedDevices}>
            {selectedDevices?.map(item => (
              <Chip
                key={item.id}
                className={classes.chipSelectedDevice}
                label={item?.nickname}
                onDelete={() => setSelectedDevices(selectedDevices.filter(filterItem => filterItem.id !== item.id))}
              />
            ))}
          </Stack>) : ''}
        </Stack>
      </Stack>

      <Dialog
        open={isDialogAddDeviceShown}
        slotProps={{
          backdrop: {
            invisible: true
          }
        }}
      >
        <DialogAddDevice
          selectedDevices={selectedDevices}
          setSelectedDevices={setSelectedDevices}
          setDialogOpen={setIsDialogAddDeviceShown}
          onPage='newGroup'
        />
      </Dialog>
    </>
  )
}

export default TabNewGroup