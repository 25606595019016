// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'auto',
    padding: 24,
  },
  itemCardList: {
    marginBottom: 16,
    '&:last-child': {
      marginBottom: 0,
    }
  }
}))

export default useStyles